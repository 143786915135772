import { Auth } from "domain/model/Auth"
import { ICPStudentRepository } from "domain/repository/Admin/ICPStudentRepository"

export interface GetICPStudentsOfAllBatchUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetICPStudentsOfAllBatch implements GetICPStudentsOfAllBatchUseCase {
  private repository: ICPStudentRepository

  constructor(repository: ICPStudentRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getICPStudentsOfAllBatch(auth)
  }
}
