import { useState } from "react"
interface Schedule {
  [date: string]: "Yes" | "No"
}
export default function HomeViewModel() {
  const today = new Date()

  const generateDates = (): string[] => {
    return Array.from({ length: 8 }, (_, i) => {
      const date = new Date(today)
      date.setDate(today.getDate() + i)
      return date.toISOString().split("T")[0]
    })
  }

  const [schedule, setSchedule] = useState<Schedule>(
    generateDates().reduce((acc, date) => {
      acc[date] = "Yes"
      return acc
    }, {} as Schedule)
  )

  const handleChange = (date: string, value: "Yes" | "No") => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [date]: value,
    }))
  }
  return {
    handleChange,
    schedule,
    setSchedule,
    generateDates,
  }
}
