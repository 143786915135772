import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import Editor, { OnMount } from "@monaco-editor/react"
import Button from "core/components/editor/Button"
import { cn } from "core/lib/utils"
import useEditorViewModel from "./EditorViewModel"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import { capitalize } from "core/utils/string"
import { useAuth } from "core/context/auth"
import { CheckboxEmptyIcon, CheckboxFilledIcon, MainLogo } from "core/constants/svgs"
import {
  STR_CODING_PROBLEMS,
  STR_CASE_STUDY_PROBLEMS,
  STR_SUBJECTIVE_PROBLEMS,
  STR_DESCRIPTION,
  STR_MCQS,
  STR_SUBMISSIONS,
  STR_SOLUTION,
  STR_ACCEPTED,
} from "core/constants/strings"
import SubmissionDisclosure from "core/components/editor/SubmissionDisclosure"
import RunCodeDetails from "core/components/editor/RunCodeDetails"
import Spinner from "core/components/Spinner"
import { isEmpty } from "core/utils/misc"
import useTimer, { contestTimerFormatter } from "core/hooks/useTimer"
import ConfirmExitPopup from "./components/ConfirmExitPopup"
import SolutionCard from "presentation/Student/Assignments/Components/SolutionCard"
import QuestionView from "./components/QuestionView"
import ResponseView from "./components/ResponseView"
import ContestProctoringModal from "../components/ContestProctoringModal"
import { useApp } from "core/context/app"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "core/components/v2/ui/Resizable"
import { useNavigate, useParams } from "react-router-dom"
import SubmitMcqQuestion from "domain/useCase/User/Contest/SubmitMcqQuestion"
import useLocalStorage from "core/hooks/useLocalStorage"
import McqNavigation from "../components/McqNavigation"
import CodingProblemView from "./components/CodingProblemView"
export default function EditorView() {
  const {
    id,
    code,
    sizes,
    toast,
    question,
    questionWithAnswer,
    templates,
    language,
    isPast,
    isApproved,
    submissions,
    mcqQuestions,
    setMcqQuestions,
    isCodeRunning,
    exitPopupOpen,
    runCodeDetails,
    activeTabIndex,
    submittedDueToProctoring,
    codingProblems,
    isTemplateLoaded,
    studentContestDetails,
    isQuestionLoaded,
    isCodeSubmitting,
    batchMcqSolution,
    resetingTemplate,
    submitCodeDetails,
    showRunCodeDetails,
    isAllMcqSubmitting,
    activeCodingTabIndex,
    codingProblemSolution,
    isMcqSubmittingWithId,
    activeMcqQuestionIndex,
    isAllSubmissionsLoading,
    activeMcqQuestionDetails,
    activeCodingProblemIndex,
    isFetchingActiveMcqQuestion,
    isContestSubmitting,
    contestData,
    blurScreen,
    endTimestamp,
    flag,
    activeSubjectiveQuestionIndex,
    activeCasestudyQuestionIndex,
    activeCasestudyResponseIndex,
    subjectiveQuestions,
    casestudyQuestions,
    subjectiveQuestion,
    casestudyQuestion,
    isFetchingSubjectiveQuestion,
    isFetchingCasestudyQuestion,
    subjectiveQuestionSolutions,
    casestudyQuestionSolutions,
    isSubmittingSubjectiveQuestion,
    isSubmittingCasestudyQuestion,
    recordedContest,
    recEndTimestamp,
    submitCodingQuestion,
    STR_FAILURE,
    fetchAllQuestions,
    setActiveMcqQuestionDetails,
    setActiveTabIndex,
    setIsCodeRunning,
    checkForContest,
    handleSubmitContest,
    handleOpenPopup,
    handleSubmitBatchMcqQuestion,
    handlePreviousCodingProblem,
    fetchStudentContestDetails,
    handleCloseRunCodeDetails,
    handlePreviousMcqQuestion,
    handleCodingProblemChange,
    fetchDefaultTemplateCode,
    handleSubmitMcqQuestion,
    handleNextCodingProblem,
    fetchMcqQuestionDetails,
    handleActiveMcqQuestion,
    handleNextMcqQuestion,
    fetchAllMcqQuestions,
    handleCodingTabChange,
    changeToastDetails,
    setIsCodeSubmitting,
    changeToastVisibility,
    handleLanguageChange,
    fetchContestDetails,
    handleSubmitCode,
    handleClosePopup,
    fetchSubmissions,
    fetchQuestionWithAnswer,
    handleSizeChange,
    mcqQuestionsLocally,
    fetchProblemData,
    setQuestion,
    handleCodeChange,
    handleTabChange,
    handleRunCode,
    handleSubmitCodingQuestion,
    handleSubjectiveProblemChange,
    handleCasestudyProblemChange,
    handleCasestudyProblemResponseChange,
    fetchSubjectiveProblemDetails,
    fetchCasestudyProblemDetails,
    handleSubjectiveQuestionSolutionChange,
    handleCasestudyQuestionSolutionChange,
    handleSubmitBatchSubjectiveQuestion,
    handleSubmitBatchCasestudyQuestion,
    markSubmitProctored,
    questionsLocally,
    reattemptedContest,
    handleOptionSelect,
    selectedOption,
    fetchExamQuestions,
    allQuestions,
    setActiveMcqQuestionIndex,
    setActiveCodingProblemIndex,
  } = useEditorViewModel()

  const TABS = [STR_CODING_PROBLEMS, STR_MCQS, STR_SUBJECTIVE_PROBLEMS, STR_CASE_STUDY_PROBLEMS]
  const CODING_TABS = [STR_DESCRIPTION, STR_SUBMISSIONS, STR_SOLUTION]
  const { contest_id } = useParams()
  const [savedContest, setSavedContest] = useLocalStorage<any>(`contest-${contest_id}`, {})
  const LANGUAGE_LIST = {
    cpp: "C++",
    java: "Java",
    python: "Python",
    javascript: "JavaScript",
  }

  const [, timer] = useTimer(endTimestamp, 1000, [endTimestamp, recEndTimestamp, contestData, studentContestDetails])
  const [, recTimer] = useTimer(recEndTimestamp, 1000, [endTimestamp, recEndTimestamp, studentContestDetails])
  const [errorLine, setErrorLine] = useState<number | null>(null)
  const editorRef = useRef(null)
  const monacoRef = useRef(null)
  const decorationRef = useRef([])

  const { auth } = useAuth()
  const { student } = useApp()
  const handleEditorDidMount: OnMount = (editor: any, monacoInstance: any) => {
    editorRef.current = editor
    monacoRef.current = monacoInstance

    editor.onKeyDown((event: KeyboardEvent) => {
      if (event.code === monacoInstance.KeyCode.Enter) {
        editor.deltaDecorations(decorationRef.current, [])
      }
    })
  }

  const [showSolution, setShowSolution] = useState(false)

  const handleToggleSolution = () => {
    setShowSolution((prev) => !prev)
  }
  const handleErrorInfo = (info: { lineNumber?: number }) => {
    setErrorLine(info?.lineNumber!)
  }

  const highlightErrorLine = (errorLine: number) => {
    if (!editorRef.current || !monacoRef.current || !errorLine) return

    const editor = editorRef.current as any
    const monacoInstance = monacoRef.current as any

    if (decorationRef.current) {
      editor.deltaDecorations(decorationRef.current, [])
    }

    const highlightDecoration = editor.deltaDecorations(
      [],
      [
        {
          range: new monacoInstance.Range(
            language === "cpp" ? errorLine - 3 : language === "java" ? errorLine - 2 : errorLine,
            1,
            language === "cpp" ? errorLine - 3 : language === "java" ? errorLine - 2 : errorLine,
            1
          ),
          options: {
            isWholeLine: true,
            className: "myLineHighlight",
          },
        },
      ]
    )
    decorationRef.current = highlightDecoration
  }

  const [alertCount, setAlertCount] = useState<number>(() => {
    const storedCount = localStorage.getItem("alertCount")
    return storedCount ? parseInt(storedCount, 10) : 0
  })

  const [hasIncremented, setHasIncremented] = useState(false)
  const [timerr, setTimerr] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [nextAction, setNextAction] = useState("")

  const [targetQuestionIndex, setTargetQuestionIndex] = useState(-1)
  const handleNewFlowNextMcqQuestion = () => {
    const currentQuestionId = mcqQuestions[activeMcqQuestionIndex]?.key

    const userSolution = studentContestDetails?.mcq_question_status[currentQuestionId]?.user_solution
    if (activeMcqQuestionIndex + 1 < mcqQuestions.length) {
      if (userSolution === null) {
        setNextAction("next")

        setShowModal(true)
      } else {
        setActiveMcqQuestionIndex(activeMcqQuestionIndex + 1)
      }
    }
  }
  const handleNewFlowPreviousMcqQuestion = () => {
    const currentQuestionId = mcqQuestions[activeMcqQuestionIndex]?.key
    const userSolution = studentContestDetails?.mcq_question_status[currentQuestionId]?.user_solution
    if (activeMcqQuestionIndex - 1 >= 0) {
      if (userSolution === null) {
        setNextAction("previous")
        setShowModal(true)
      } else {
        setActiveMcqQuestionIndex(activeMcqQuestionIndex - 1)
      }
    }
  }
  const handleMcqQuestionChange = (index: number) => {
    if (index === activeMcqQuestionIndex) {
      return
    }
    const currentQuestionId = mcqQuestions[activeMcqQuestionIndex]?.key
    const userSolution = studentContestDetails?.mcq_question_status[currentQuestionId]?.user_solution

    if (userSolution === null) {
      setNextAction("change")
      setTargetQuestionIndex(index)
      setShowModal(true)
    } else {
      setActiveMcqQuestionIndex(index)
    }
  }
  const handleModalAction = (actionType: string) => {
    setShowModal(false)

    if (actionType === "save") {
      return
    }

    if (nextAction === "change" && targetQuestionIndex !== null) {
      setActiveMcqQuestionIndex(targetQuestionIndex)
      setActiveMcqQuestionDetails(mcqQuestionsLocally[targetQuestionIndex])
    } else if (nextAction === "next" && activeMcqQuestionIndex + 1 < mcqQuestions.length) {
      setActiveMcqQuestionIndex(activeMcqQuestionIndex + 1)
    } else if (nextAction === "previous" && activeMcqQuestionIndex - 1 >= 0) {
      setActiveMcqQuestionIndex(activeMcqQuestionIndex - 1)
    }
  }

  const handleModal = (visible: boolean) => {
    setIsModalVisible(visible)
  }
  const handleMcqNavigation = (visible: boolean) => {
    setShowModal(visible)
  }
  useEffect(() => {
    fetchContestDetails()
    if (savedContest?.flowType !== "newFlow") {
      fetchAllQuestions()
      fetchAllMcqQuestions()
    }
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    if (!isPast) return
    // if (!isApproved && !reattemptedContest) {
    //   navigate(`/weekly-test-series`)
    // }
  }, [isPast, isApproved, reattemptedContest])
  useEffect(() => {
    const style = document.createElement("style")
    style.innerHTML = `
      .myLineHighlight {
        background-color: rgba(255, 0, 0, 0.3);
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  useEffect(() => {
    if (isCodeSubmitting) {
      const timer = setTimeout(() => {
        changeToastDetails(STR_FAILURE, "Poor Internet Connection! Please refresh and try again.")
        changeToastVisibility(true)
        setIsCodeSubmitting(false)
      }, 25000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isCodeSubmitting])

  useEffect(() => {
    if (isCodeRunning) {
      const timeout = setTimeout(() => {
        changeToastDetails(STR_FAILURE, "Poor Internet Connection! Please refresh and try again.")
        changeToastVisibility(true)
        setIsCodeRunning(false)
      }, 25000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isCodeRunning])

  useEffect(() => {
    // Check if the active tab is for coding problems

    if (activeTabIndex === 0 && codingProblems.length > 0) {
      // Call the handleCodingProblemChange with the current active coding problem index
      setActiveCodingProblemIndex(0)
      handleCodingProblemChange(activeCodingProblemIndex)
    }
  }, [activeTabIndex, activeCodingProblemIndex, codingProblems])

  useEffect(() => {
    if (student?.proctoring_status === false || contestData?.isProctored === "No") {
      return
    }
    const debounceIncrementAlertCount = () => {
      if (timerr) clearTimeout(timerr)
      setTimerr(
        setTimeout(() => {
          setAlertCount((prevCount) => {
            const newCount = prevCount + 1
            localStorage.setItem("alertCount", newCount.toString())
            if (newCount > 3) {
              handleSubmitContest()
              markSubmitProctored()
              localStorage.setItem("alertCount", "0")
            } else {
              setIsModalVisible(true) // Show the modal
            }
            return newCount
          })
          setHasIncremented(false)
        }, 10)
      )
    }

    const handleVisibilityChange = () => {
      if (document.hidden && !hasIncremented) {
        setHasIncremented(true)
        debounceIncrementAlertCount()
      }
    }

    const handleBlur = () => {
      if (!document.hidden && !hasIncremented) {
        setHasIncremented(true)
        debounceIncrementAlertCount()
      }
    }

    const handleFocus = () => {
      setHasIncremented(false)
      if (timerr) clearTimeout(timerr)
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)
    window.addEventListener("blur", handleBlur)
    window.addEventListener("focus", handleFocus)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
      window.removeEventListener("blur", handleBlur)
      window.removeEventListener("focus", handleFocus)
      if (timerr) clearTimeout(timerr)
    }
  }, [hasIncremented, timerr])

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault()
        changeToastDetails(STR_ACCEPTED, "Code is being saved locally. No need to save it manually.")
        changeToastVisibility(true)
        console.log("Ctrl+S was pressed, but default action is prevented.")
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  useEffect(() => {
    highlightErrorLine(errorLine!)
  }, [errorLine])
  useEffect(() => {
    setQuestion(questionsLocally?.[0])
    setActiveMcqQuestionDetails(mcqQuestionsLocally?.[0])
  }, [])
  useEffect(() => {
    const secondsDifference = Math.floor((Date.now() - endTimestamp) / 1000)
    if (secondsDifference > 0 && secondsDifference < 5) handleSubmitContest()
    if (!isEmpty(studentContestDetails || contestData)) checkForContest()
  }, [contestData, studentContestDetails, timer, recTimer])

  useEffect(() => {
    if (isTemplateLoaded) {
      handleCodeChange(templates[language])
    }
  }, [language])

  useEffect(() => {
    if (!isEmpty(id) && !isCodeSubmitting) {
      fetchSubmissions(id)
    }
  }, [isCodeSubmitting])

  useEffect(() => {
    if (!isEmpty(submissions) && flag > 0 && contestData?.flowType != "newFlow") {
      handleSubmitCodingQuestion()
    }
  }, [submissions, flag])

  useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })
  useEffect(() => {
    if (CODING_TABS[activeCodingTabIndex] === STR_SOLUTION) {
      fetchQuestionWithAnswer()
    }
  }, [activeCodingTabIndex])

  useEffect(() => {
    if (allQuestions && allQuestions?.mcq_questions) {
      fetchMcqQuestionDetails()
    }
  }, [activeMcqQuestionIndex, allQuestions])

  useEffect(() => {
    if (!isEmpty(id) && allQuestions && allQuestions?.coding_questions) {
      fetchProblemData()
    }
  }, [id, activeCodingProblemIndex, allQuestions])

  useEffect(() => {
    if (allQuestions.length === 0) {
      fetchExamQuestions()
    }
  }, [id])

  useEffect(() => {
    if (!isCodeSubmitting || !isMcqSubmittingWithId) {
      fetchStudentContestDetails()
    }
  }, [
    isCodeSubmitting,
    isMcqSubmittingWithId,
    isAllMcqSubmitting,
    subjectiveQuestions,
    casestudyQuestions,
    submitCodingQuestion,
  ])

  useEffect(() => {
    if (!isEmpty(codingProblems)) {
      setActiveTabIndex(0)
    } else if (!isEmpty(mcqQuestions)) {
      setActiveTabIndex(1)
    } else if (!isEmpty(subjectiveQuestions)) {
      setActiveTabIndex(2)
    } else {
      setActiveTabIndex(3)
    }
  }, [codingProblems])

  // useEffect(() => {
  //   console.log("subdxub")
  // }, [handleTabChange])

  useEffect(() => {
    if (allQuestions && allQuestions?.subjective_questions) {
      fetchSubjectiveProblemDetails()
    }
  }, [activeSubjectiveQuestionIndex, allQuestions])

  useEffect(() => {
    if (allQuestions && allQuestions?.casestudy_questions) {
      fetchCasestudyProblemDetails()
    }
  }, [activeCasestudyQuestionIndex, casestudyQuestions, allQuestions])

  useEffect(() => {
    const disablePaste = (e: ClipboardEvent) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const container = document.getElementById("editor-container") as HTMLElement
    if (container) {
      container.addEventListener("paste", disablePaste, true)
      return () => container.removeEventListener("paste", disablePaste, true)
    }
  }, [])
  const ProblemHeader = useCallback(
    () => (
      <div className="space-y-6">
        <div className="flex items-center gap-2 text-new-neutral-light">
          {codingProblemSolution[codingProblems[activeCodingProblemIndex]?.key] ? (
            <CheckboxFilledIcon className="h-6 w-6 text-new-solid-white" />
          ) : (
            <CheckboxEmptyIcon className="h-6 w-6" />
          )}
          <h3 className="text-new-solid-white">
            {("0" + (Number(activeCodingProblemIndex) + 1)).slice(-2)}. {question?.problem_name}
          </h3>
        </div>
        <div className="item-center flex gap-4">
          {question?.tags?.map((tag: string, i: number) => (
            <h5 key={i} className="rounded-sm bg-new-editor-dark-500 px-3 py-1 text-new-neutral-light">
              {capitalize(tag)}
            </h5>
          ))}
        </div>
      </div>
    ),
    [activeCodingProblemIndex, codingProblemSolution, codingProblems, question]
  )

  return (
    <div className="h-screen">
      {isModalVisible ? (
        <ContestProctoringModal show={isModalVisible} isAlertPopup={true} onSubmit={() => handleModal(false)} />
      ) : (
        <></>
      )}
      {showModal && (
        <McqNavigation
          isMcqSubmitted={true}
          handleMcqNavigation={handleMcqNavigation}
          handleModalAction={handleModalAction}
          className="z-[999]"
        />
      )}
      <div
        className={cn(
          "absolute top-0 left-0 z-40 h-screen w-full bg-new-editor-dark-700/80 backdrop-blur-md",
          !blurScreen && "hidden"
        )}
      />
      <ConfirmExitPopup
        data={{
          coding: {
            done: Object.keys(codingProblemSolution).reduce(
              (acc, key) => acc + (!isEmpty(codingProblemSolution[key]) ? 1 : 0),
              0
            ),
            total: codingProblems?.length,
          },
          mcq: {
            done: Object.keys(batchMcqSolution).reduce(
              (acc, key) => acc + (!isEmpty(batchMcqSolution[key]) ? 1 : 0),
              0
            ),
            total: mcqQuestions?.length,
          },
          subjective: {
            done: subjectiveQuestionSolutions.reduce(
              (acc, solution) => acc + (typeof solution === "string" && solution.length > 0 ? 1 : 0),
              0
            ),
            total: subjectiveQuestions?.length,
          },
          casestudy: {
            done: casestudyQuestionSolutions.reduce(
              (acc, obj) => acc + (obj && obj != "undefined" && Object.keys(obj).length > 0 ? 1 : 0),
              0
            ),
            total: casestudyQuestions?.length,
          },
        }}
        loading={isContestSubmitting}
        open={exitPopupOpen}
        onClose={handleClosePopup}
        onClick={() => {
          handleSubmitContest()
          localStorage.setItem("alertCount", "0")
        }}
      />
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel defaultSize={40} minSize={20}>
          <div className="editor relative flex h-full w-full flex-col overflow-y-scroll bg-new-editor-dark-700">
            <div className="no-scrollbar sticky top-0 z-20 flex shrink-0 overflow-x-auto">
              {TABS.map((tab, i) => {
                let shouldRenderTab = true
                if (tab === STR_CODING_PROBLEMS) {
                  shouldRenderTab = !isEmpty(codingProblems)
                } else if (tab === STR_MCQS) {
                  shouldRenderTab = !isEmpty(mcqQuestions)
                } else if (tab === STR_SUBJECTIVE_PROBLEMS) {
                  shouldRenderTab = !isEmpty(subjectiveQuestions)
                } else {
                  shouldRenderTab = !isEmpty(casestudyQuestions)
                }
                return (
                  shouldRenderTab && (
                    <button
                      key={i}
                      className={cn(
                        "flex-1 px-4 py-2 text-sm font-medium leading-[20px]",
                        TABS[activeTabIndex] === tab
                          ? "bg-new-editor-dark-700 text-new-solid-white"
                          : "bg-new-editor-dark-500 text-new-neutral-light"
                      )}
                      onClick={() => handleTabChange(i)}
                    >
                      {tab}
                    </button>
                  )
                )
              })}
            </div>

            <div className="flex-1 space-y-6 p-6">
              {TABS[activeTabIndex] === STR_CODING_PROBLEMS ? (
                <div className="space-y-6">
                  {isPast ? (
                    <div className="flex gap-2">
                      {CODING_TABS.map((tab, i) => (
                        <Button
                          key={i}
                          className={cn(
                            "flex-1",
                            CODING_TABS[activeCodingTabIndex] === tab
                              ? "bg-new-editor-dark-150 text-new-solid-white"
                              : "bg-new-editor-dark-200 text-new-neutral-light"
                          )}
                          onClick={() => handleCodingTabChange(i)}
                        >
                          <h4>{tab}</h4>
                        </Button>
                      ))}
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      {CODING_TABS.slice(0, 2).map((tab, i) => (
                        <Button
                          key={i}
                          className={cn(
                            "flex-1",
                            CODING_TABS[activeCodingTabIndex] === tab
                              ? "bg-new-editor-dark-150 text-new-solid-white"
                              : "bg-new-editor-dark-200 text-new-neutral-light"
                          )}
                          onClick={() => handleCodingTabChange(i)}
                        >
                          <h4>{tab}</h4>
                        </Button>
                      ))}
                    </div>
                  )}
                  {isEmpty(codingProblems) ? (
                    <></>
                  ) : (
                    <CodingProblemView
                      key={activeTabIndex}
                      codingProblems={codingProblems}
                      studentContestDetails={studentContestDetails}
                      activeCodingProblemIndex={activeCodingProblemIndex}
                      handleCodingProblemChange={handleCodingProblemChange}
                      setQuestion={setQuestion}
                      activeTabIndex={activeTabIndex}
                      questionsLocally={questionsLocally}
                    />
                  )}

                  {isQuestionLoaded ? (
                    <>
                      <ProblemHeader />
                      {CODING_TABS[activeCodingTabIndex] === STR_DESCRIPTION ? (
                        <div className="space-y-6 text-new-neutral-light">
                          <div
                            className="text-new-neutral-light"
                            dangerouslySetInnerHTML={{
                              __html: question?.question_details,
                            }}
                          />
                          {question?.input &&
                            question?.input?.map((eachInput: { value: string }, i: number) => (
                              <div
                                key={i}
                                className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                                dangerouslySetInnerHTML={{
                                  __html: eachInput.value,
                                }}
                              />
                            ))}
                          {question?.constraints && (
                            <div
                              className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                              dangerouslySetInnerHTML={{
                                __html: question?.constraints,
                              }}
                            />
                          )}
                        </div>
                      ) : CODING_TABS[activeCodingTabIndex] === STR_SOLUTION ? (
                        <div className="flex flex-col gap-6 text-new-neutral-light">
                          {questionWithAnswer?.solution &&
                            questionWithAnswer?.solution?.map(
                              (sol: { heading: string; description: string }, i: number) => (
                                <>
                                  <h4>{sol.heading}</h4>
                                  <pre
                                    key={i}
                                    className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50 whitespace-pre-wrap text-sm"
                                    dangerouslySetInnerHTML={{
                                      __html: sol.description,
                                    }}
                                  />
                                </>
                              )
                            )}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-6 text-new-neutral-light">
                          <div className="flex items-center gap-4 rounded-sm bg-new-editor-dark-500 px-6 py-3">
                            <h4 className="text-new-solid-white">
                              {submissions?.length > 0
                                ? Math.max(...submissions?.map((s: { score?: number }) => s?.score))
                                : 0}
                              /
                              {submissions?.length > 0
                                ? Math.max(
                                    ...submissions?.map((s: { total_testcase: string }) => parseInt(s?.total_testcase))
                                  )
                                : 0}
                            </h4>
                            <p>Your Best score</p>
                          </div>
                          <h4 className="mt-2 text-new-solid-white">Your submissions :</h4>
                          {isAllSubmissionsLoading && isEmpty(codingProblems) ? (
                            <Loader dark />
                          ) : (
                            <div className="flex flex-col gap-6">
                              {isCodeSubmitting && (
                                <div className="mb-6 flex flex-col items-center gap-2 rounded-sm bg-new-editor-dark-500 px-6 py-3">
                                  <Spinner dark small />
                                  <h4 className="text-new-solid-white">
                                    {submitCodeDetails?.description?.length > 0
                                      ? submitCodeDetails?.description
                                      : "Submitting..."}
                                  </h4>
                                </div>
                              )}
                              {submissions?.map((submission: any, i: number) => {
                                const isAccepted =
                                  submission?.total_testcase > 0 && submission?.score === submission?.total_testcase

                                const isPartiallyAccepted = !isAccepted && submission?.score > 0

                                const isTimeLimitExceeded = submission?.description === "Time Limit Exceeded"

                                const isError = submission?.total_testcase === 0 && submission?.stderr?.length > 0

                                return (
                                  <div
                                    key={i}
                                    className="space-y-2 rounded-sm bg-new-editor-dark-500 px-6 py-3 text-new-editor-dark-50"
                                  >
                                    <div className="flex justify-between gap-1 flex-wrap">
                                      <h5>
                                        Language:{" "}
                                        {submission?.language?.length > 0
                                          ? LANGUAGE_LIST?.[submission?.language as keyof typeof LANGUAGE_LIST]
                                          : "Not Specified"}
                                      </h5>
                                      <h5 className="text-new-editor-dark-100">{submission?.time}</h5>
                                    </div>
                                    {submission?.compile_output?.length > 0 || isError ? (
                                      <SubmissionDisclosure label="Error">
                                        <p>{isError ? submission?.stderr[0] : submission?.compile_output}</p>
                                      </SubmissionDisclosure>
                                    ) : (
                                      <div className="space-y-1">
                                        <h4
                                          className={cn(
                                            isAccepted
                                              ? "text-new-success"
                                              : isPartiallyAccepted
                                                ? "text-new-misc-yellow"
                                                : "text-new-failure"
                                          )}
                                        >
                                          {isAccepted
                                            ? "Accepted"
                                            : isPartiallyAccepted
                                              ? "Partially Accepted"
                                              : isTimeLimitExceeded
                                                ? "Time Limit Exceeded"
                                                : "Wrong Answer"}
                                        </h4>
                                        {!isTimeLimitExceeded && (
                                          <p>
                                            Test cases passed {submission?.score}/{submission?.total_testcase}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      (
                      {!isEmpty(codingProblems) ? (
                        <Loader dark />
                      ) : (
                        <div className="flex h-full w-full items-center justify-center">
                          <h3 className="h-6 text-center text-white">No Coding Problem Available!</h3>
                        </div>
                      )}
                      )
                    </>
                  )}
                </div>
              ) : TABS[activeTabIndex] === STR_MCQS ? (
                <div className="flex flex-col gap-3 text-new-editor-dark-50">
                  {isEmpty(mcqQuestions) ? (
                    <h3 className="h-6 text-center">No MCQ Questions Available.</h3>
                  ) : (
                    mcqQuestions?.map((mcq, i) => (
                      <div
                        key={i}
                        className={cn(
                          "flex cursor-pointer items-center gap-2 rounded-sm border bg-new-editor-dark-500 px-6 py-3",
                          activeMcqQuestionIndex === i ? "border-new-neutral-light" : "border-transparent"
                        )}
                        onClick={() => {
                          if (studentContestDetails?.flowType === "newFlow") {
                            handleMcqQuestionChange(i)
                            setQuestion(questionsLocally?.[i])
                          } else {
                            handleActiveMcqQuestion(i)
                            setActiveMcqQuestionDetails(mcqQuestionsLocally?.[i])
                          }
                          // setMcqQuestions(mcqQuestionsLocally?.[i])
                        }}
                      >
                        {batchMcqSolution[mcq?.key] ? (
                          <CheckboxFilledIcon className="h-4 w-4 text-new-solid-white" />
                        ) : (
                          <CheckboxEmptyIcon className="h-4 w-4" />
                        )}
                        <div className="flex flex-1 items-center justify-between">
                          <h4 className="line-clamp-1">Question {i + 1}</h4>
                          <h5 className="shrink-0">Score: {mcq?.score}</h5>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ) : TABS[activeTabIndex] === STR_SUBJECTIVE_PROBLEMS ? (
                <QuestionView
                  questions={subjectiveQuestions}
                  question={subjectiveQuestion}
                  isPast={isPast}
                  activeQuestionIndex={activeSubjectiveQuestionIndex}
                  handleQuestionProblemChange={handleSubjectiveProblemChange}
                  isFetchingQuestion={isFetchingSubjectiveQuestion}
                />
              ) : (
                <QuestionView
                  questions={casestudyQuestions}
                  question={casestudyQuestion}
                  isPast={isPast}
                  activeQuestionIndex={activeCasestudyQuestionIndex}
                  handleQuestionProblemChange={handleCasestudyProblemChange}
                  isFetchingQuestion={isFetchingCasestudyQuestion}
                  response={casestudyQuestion?.response}
                  activeResposeTab={activeCasestudyResponseIndex}
                />
              )}
            </div>
            <div className="no-scrollbar sticky bottom-0 z-20 mt-auto flex shrink-0 items-center justify-between overflow-x-auto border-t border-new-neutral-light bg-new-editor-dark-700 px-6 py-4 text-new-solid-white">
              <h4>End your Exam:</h4>
              <Button
                className="w-[180px] bg-new-misc-red text-current"
                onClick={() => {
                  handleOpenPopup()
                  fetchStudentContestDetails()
                }}
              >
                End Exam
              </Button>
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={60} minSize={20}>
          <div className="editor flex-1 flex h-full flex-col overflow-y-auto bg-new-editor-dark-700">
            <div className="flex shrink-0 items-center gap-4 p-6">
              <div className="flex w-[110px] items-center justify-center rounded-sm bg-new-editor-dark-150 px-3 py-2 text-new-solid-white">
                <h4>
                  {TABS[activeTabIndex] === STR_CODING_PROBLEMS
                    ? `${Object.keys(codingProblemSolution).reduce(
                        (acc, key) => acc + (!isEmpty(codingProblemSolution[key]) ? 0 : 1),
                        0
                      )}/${codingProblems?.length}`
                    : TABS[activeTabIndex] === STR_MCQS
                      ? `${Object.keys(batchMcqSolution).reduce(
                          (acc, key) => acc + (!isEmpty(batchMcqSolution[key]) ? 0 : 1),
                          0
                        )}/${mcqQuestions?.length}`
                      : TABS[activeTabIndex] === STR_SUBJECTIVE_PROBLEMS
                        ? `${Object.keys(subjectiveQuestionSolutions).reduce(
                            (acc, key) => acc + (!isEmpty(subjectiveQuestionSolutions[key as any]) ? 0 : 1),
                            0
                          )}/${subjectiveQuestions.length}`
                        : `${Object.keys(casestudyQuestionSolutions).reduce(
                            (acc, key) => acc + (!isEmpty(casestudyQuestionSolutions[key as any]) ? 0 : 1),
                            0
                          )}/${casestudyQuestions.length}`}
                </h4>
              </div>
              <h4 className="text-new-neutral-light">Total Questions Remaining</h4>
              <div className="ml-auto flex gap-3">
                {!isEmpty(studentContestDetails) && (
                  <h3 className="text-red-500">
                    {recordedContest === false ? contestTimerFormatter(timer) : contestTimerFormatter(recTimer)}
                  </h3>
                )}
                <MainLogo className="h-7" />
              </div>
            </div>
            {TABS[activeTabIndex] === STR_CODING_PROBLEMS ? (
              <>
                <div className="flex-1 px-6 pb-6">
                  <div
                    id="editor-container"
                    className="flex h-full flex-col divide-y divide-new-neutral-light rounded-sm border border-new-neutral-dark"
                  >
                    <div className="flex items-center gap-4 bg-new-editor-dark-600 py-2 px-4">
                      <Button
                        className="ml-auto min-w-[120px] bg-new-editor-dark-200 text-new-neutral-light hover:text-new-solid-white"
                        onClick={() => fetchDefaultTemplateCode("reset")}
                      >
                        Reset Code
                        {resetingTemplate && <Spinner dark xs />}
                      </Button>
                      <select
                        className="w-28 rounded-sm bg-new-editor-dark-700 py-1 px-4 text-sm font-medium leading-normal text-new-neutral-light"
                        value={language}
                        onChange={handleLanguageChange}
                      >
                        {Object.entries(LANGUAGE_LIST).map(([langId, langName]) => (
                          <option key={langId} value={langId}>
                            {langName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {isTemplateLoaded ? (
                      <div className="h-auto flex-1">
                        <Editor
                          language={language}
                          value={code}
                          onChange={handleCodeChange}
                          theme="vs-dark"
                          onMount={handleEditorDidMount}
                          options={{
                            minimap: {
                              enabled: false,
                            },
                            contextmenu: false,
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex h-full w-full items-center justify-center">
                        (
                        {!isEmpty(codingProblems) ? (
                          <Loader dark />
                        ) : (
                          <h3 className="h-6 text-center text-white">No Coding Problem Available!</h3>
                        )}
                        )
                      </div>
                    )}
                  </div>
                </div>
                {showRunCodeDetails && (
                  <RunCodeDetails
                    language={language}
                    data={runCodeDetails}
                    running={isCodeRunning}
                    onClose={handleCloseRunCodeDetails}
                    onErrorInfo={handleErrorInfo}
                  />
                )}
                <div className="sticky bottom-0 mt-auto flex items-center justify-between gap-4 border-t border-new-neutral-light bg-new-editor-dark-500 px-6 py-4">
                  <div className="flex gap-2">
                    <Button
                      className={cn(
                        "w-[120px] bg-new-editor-dark-200",
                        activeCodingProblemIndex === 0 ? "text-new-neutral" : "text-new-solid-white"
                      )}
                      onClick={handlePreviousCodingProblem}
                    >
                      Previous
                    </Button>
                    <Button
                      className={cn(
                        "w-[120px] bg-new-editor-dark-200",
                        activeCodingProblemIndex === codingProblems?.length - 1
                          ? "text-new-neutral"
                          : "text-new-solid-white"
                      )}
                      onClick={handleNextCodingProblem}
                    >
                      Next
                    </Button>
                  </div>
                  <div className="flex gap-2">
                    <Button
                      className="w-[120px] bg-new-gradient text-new-solid-white hover:bg-new-accent hover:bg-none"
                      onClick={() => handleSubmitCode(language, code, id)}
                      disabled={isCodeRunning || isCodeSubmitting}
                    >
                      Submit
                      {isCodeSubmitting && <Spinner dark xs />}
                    </Button>
                    <Button
                      className="w-[120px] bg-[#02D731] text-new-solid-white"
                      onClick={() => handleRunCode(language, code, id)}
                      disabled={isCodeRunning || isCodeSubmitting}
                    >
                      Run
                      {isCodeRunning && <Spinner dark xs />}
                    </Button>
                  </div>
                </div>
              </>
            ) : TABS[activeTabIndex] === STR_MCQS ? (
              <>
                <div className="flex px-6 gap-6 flex-col">
                  {isFetchingActiveMcqQuestion ? (
                    <>
                      (
                      {!isEmpty(codingProblems) ? (
                        <Loader dark />
                      ) : (
                        <div className="flex h-full w-full items-center justify-center">
                          <h3 className="h-6 text-center">No Coding Questions Available.</h3>
                        </div>
                      )}
                      )
                    </>
                  ) : (
                    <div className="w-full space-y-4 rounded-sm border border-new-neutral-light bg-new-editor-dark-500 px-6 py-4 text-new-editor-dark-50">
                      {isEmpty(mcqQuestions) ? (
                        <h3 className="h-6 text-center">No MCQ Questions Available.</h3>
                      ) : (
                        <div className="flex gap-2 text-new-solid-white">
                          <h3 className="h-6 w-6 shrink-0">{activeMcqQuestionIndex + 1}.</h3>
                          <div className="flex w-full max-w-[calc(100%-32px)] flex-col gap-4">
                            {!isEmpty(activeMcqQuestionDetails?.question) &&
                              activeMcqQuestionDetails?.question?.map((content: any, i: number) => (
                                <Fragment key={i}>
                                  {content?.type === "text" ? (
                                    <h3>{content?.content}</h3>
                                  ) : content?.type === "image" ? (
                                    <img
                                      src={content?.content}
                                      alt="question banner"
                                      className="w-fit max-w-full"
                                      key={i}
                                    />
                                  ) : (
                                    <div
                                      className="w-full overflow-x-auto whitespace-pre border bg-new-editor-dark-700 p-2 font-mono"
                                      key={i}
                                    >
                                      <p>{content?.content}</p>
                                    </div>
                                  )}
                                </Fragment>
                              ))}
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col gap-2">
                        {!isEmpty(activeMcqQuestionDetails?.options) &&
                          Object.keys(activeMcqQuestionDetails?.options)?.length > 0 &&
                          Object.keys(activeMcqQuestionDetails?.options)?.map((optionKey, i) => (
                            <div key={i} className="flex w-full items-center gap-2">
                              <span className="h-6 w-6 shrink-0 self-start">{i + 1})</span>
                              <div className="flex w-full max-w-[calc(100%-64px)] flex-col gap-2">
                                {activeMcqQuestionDetails?.options[optionKey]?.map((option: any) =>
                                  option?.type === "text" ? (
                                    <p key={option?.id} className="text-sm font-medium">
                                      {option?.content}
                                    </p>
                                  ) : option?.type === "image" ? (
                                    <img
                                      key={option?.id}
                                      src={option?.content}
                                      alt="question banner"
                                      className="w-fit max-w-full"
                                    />
                                  ) : (
                                    <div
                                      key={option?.id}
                                      className="w-full overflow-x-auto whitespace-pre border bg-new-editor-dark-700 p-2 font-mono"
                                    >
                                      <p className="">{option?.content}</p>
                                    </div>
                                  )
                                )}
                              </div>

                              <button
                                className="h-6 w-6 shrink-0 hover:text-new-solid-white cursor-pointer"
                                onClick={() => handleOptionSelect(optionKey)}
                              >
                                {selectedOption[mcqQuestions[activeMcqQuestionIndex]?.key] === optionKey ? (
                                  <CheckboxFilledIcon className="h-6 w-6 text-new-solid-white" />
                                ) : (
                                  <CheckboxEmptyIcon className="h-6 w-6" />
                                )}
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  <div className="relative flex flex-col">
                    {isPast ? (
                      <div className="w-full space-y-4 rounded-sm border border-new-neutral-light bg-new-editor-dark-500 px-6 py-4 text-new-editor-dark-50">
                        <div className="w-full">
                          <button
                            onClick={handleToggleSolution}
                            className="bg-blue-500 text-white p-2 rounded-sm hover:bg-blue-600 w-full"
                          >
                            {showSolution ? "Hide Solution" : "Show Solution"}
                          </button>
                        </div>
                        {showSolution && activeMcqQuestionDetails && (
                          <div className="flex flex-col gap-2 mt-4">
                            <h3 className="text-new-solid-white">Solution:</h3>
                            <p className="text-sm font-medium">
                              {`${activeMcqQuestionDetails.solution}) `}
                              {activeMcqQuestionDetails.options[activeMcqQuestionDetails.solution]?.[0]?.content}
                            </p>
                            <h3 className="text-new-solid-white">
                              {activeMcqQuestionDetails?.solution_description &&
                              activeMcqQuestionDetails?.solution_description?.length > 0
                                ? "Solution Description:"
                                : ""}
                            </h3>
                            {activeMcqQuestionDetails?.solution_description &&
                              activeMcqQuestionDetails?.solution_description?.length > 0 && (
                                <p className="text-sm font-medium">{`${activeMcqQuestionDetails.solution_description?.map(
                                  (a: any) => {
                                    a
                                  }
                                )}`}</p>
                              )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="sticky bottom-0 mt-auto flex items-center justify-between gap-4 border-t border-new-neutral-light bg-new-editor-dark-500 px-6 py-4">
                  <div className="flex gap-2">
                    {!isEmpty(studentContestDetails) &&
                    studentContestDetails?.flowType &&
                    studentContestDetails?.flowType === "newFlow" ? (
                      // showModal ? (
                      //   <McqNavigation
                      //     isMcqSubmitted={true}
                      //     handleMcqNavigation={handleMcqNavigation}
                      //     handleModalAction={handleModalAction}
                      //     className="z-[999]"
                      //   />
                      // ) : (
                      <>
                        <Button
                          className={cn(
                            "w-[120px] bg-new-editor-dark-200",
                            activeMcqQuestionIndex === 0 ? "text-new-neutral" : "text-new-solid-white"
                          )}
                          onClick={handleNewFlowPreviousMcqQuestion}
                        >
                          Previous
                        </Button>
                        <Button
                          className={cn(
                            "w-[120px] bg-new-editor-dark-200",
                            activeMcqQuestionIndex === mcqQuestions?.length - 1
                              ? "text-new-neutral"
                              : "text-new-solid-white"
                          )}
                          onClick={handleNewFlowNextMcqQuestion}
                        >
                          Next
                        </Button>
                      </>
                    ) : (
                      // )
                      <>
                        <Button
                          className={cn(
                            "w-[120px] bg-new-editor-dark-200",
                            activeMcqQuestionIndex === 0 ? "text-new-neutral" : "text-new-solid-white"
                          )}
                          onClick={handlePreviousMcqQuestion}
                        >
                          Previous
                        </Button>
                        <Button
                          className={cn(
                            "w-[120px] bg-new-editor-dark-200",
                            activeMcqQuestionIndex === mcqQuestions?.length - 1
                              ? "text-new-neutral"
                              : "text-new-solid-white"
                          )}
                          onClick={handleNextMcqQuestion}
                        >
                          Next
                        </Button>
                      </>
                    )}
                  </div>

                  <Button
                    className="w-auto bg-new-gradient text-new-solid-white hover:bg-new-accent hover:bg-none"
                    onClick={() => handleSubmitMcqQuestion()}
                    disabled={isMcqSubmittingWithId === activeMcqQuestionIndex}
                  >
                    Submit MCQ Question
                    {isMcqSubmittingWithId === activeMcqQuestionIndex && <Spinner dark xs />}
                  </Button>
                </div>
              </>
            ) : TABS[activeTabIndex] === STR_SUBJECTIVE_PROBLEMS ? (
              <div className="w-full h-full flex flex-col justify-between text-new-neutral-light">
                <div className="w-full p-8 flex flex-col gap-4 font-medium">
                  <SolutionCard
                    solution={subjectiveQuestionSolutions?.[activeSubjectiveQuestionIndex] ?? ""}
                    handleSolutionChange={handleSubjectiveQuestionSolutionChange}
                  />
                </div>
                <div className="sticky bottom-0 mt-auto flex items-center flex-row-reverse px-[33px] py-[10px] justify-between gap-4 border-t border-new-neutral-light bg-new-editor-dark-500">
                  <Button
                    className="w-auto bg-new-gradient text-new-solid-white hover:bg-new-accent hover:bg-none"
                    onClick={() => handleSubmitBatchSubjectiveQuestion()}
                  >
                    {isSubmittingSubjectiveQuestion ? (
                      <Loader xs={true} height={"100%"} />
                    ) : (
                      "Submit All Subjective Questions"
                    )}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="w-full h-full flex flex-col justify-between text-white">
                <h2 className="pl-8">Questions:</h2>
                <div className="p-8 pb-0 flex flex-wrap justify-start items-center gap-4">
                  {casestudyQuestion?.response?.map((_: any, index: number) => (
                    <div
                      key={index}
                      className={cn(
                        "py-1 px-6 bg-new-editor-dark-500 border border-new-neutral-light cursor-pointer",
                        activeCasestudyResponseIndex === index ? "border-success text-success" : ""
                      )}
                      onClick={() => handleCasestudyProblemResponseChange(index)}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
                <div>
                  <ResponseView
                    response={casestudyQuestion?.response}
                    solution={casestudyQuestionSolutions}
                    activeCasestudyQuestionIndex={activeCasestudyQuestionIndex}
                    activeResposeTab={activeCasestudyResponseIndex}
                    handleSolutionChange={handleCasestudyQuestionSolutionChange}
                  />
                </div>
                <div className="sticky bottom-0 mt-auto flex items-center flex-row-reverse px-[33px] py-[10px] justify-between gap-4 border-t border-new-neutral-light bg-new-editor-dark-500">
                  <Button
                    className="w-auto bg-new-gradient text-new-solid-white hover:bg-new-accent hover:bg-none"
                    onClick={() => handleSubmitBatchCasestudyQuestion()}
                  >
                    {isSubmittingCasestudyQuestion ? (
                      <Loader xs={true} height={"100%"} />
                    ) : (
                      "Submit All Casestudy Questions"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
