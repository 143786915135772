import ConditionalLoader from "core/components/ConditionalLoader"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import Toast from "core/components/Toast"
import { STR_NUMBER_FILED, STR_TEXT_FIELD } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import DashboardLayout from "core/layouts/DashboardLayout"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import React from "react"
import useCreateBatchViewModel from "./CreateBatchViewModel"

export default function CreateBatchView() {
  const {
    toast,
    _currentSelectContest,
    _placementBatchName,
    _bridgeBatchName,
    loading,
    _setCurrentSelectContest,
    handlePlacementCourseName,
    handleBridgeCourseName,
    handleContestChange,
    changeToastVisibility,
    fetchAllContests,
    allContestsList,
    createBatches,
    passingScore,
    setPassingScore,
  } = useCreateBatchViewModel()

  React.useEffect(() => {
    fetchAllContests()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">Create Placement Cohort</h2>
        </div>
        <form onSubmit={(e) => createBatches(e)}>
          <div className="flex w-full max-w-[400px] flex-col gap-4">
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Placement course name"
              onChange={handlePlacementCourseName}
              value={_placementBatchName}
              containerClass="w-full"
              required
            />
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Bridge course name"
              onChange={handleBridgeCourseName}
              value={_bridgeBatchName}
              containerClass="w-full"
              required
            />
            <Input
              type={STR_NUMBER_FILED}
              placeholder="Passing Score"
              onChange={(e) => setPassingScore(Number(e.target.value))}
              value={passingScore}
              containerClass="w-full"
              required
            />

            <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
              <ConditionalLoader
                isLoading={isEmpty(_currentSelectContest)}
                loader={<em className="text-xs text-new-neutral-light">No Selected Contest</em>}
              >
                {_currentSelectContest.map((contest: any, i: number) => (
                  <li key={i} className="inline-flex items-center gap-2">
                    {contest.name}
                    <button
                      type="button"
                      className="rounded bg-new-solid-black p-1 text-new-solid-white"
                      onClick={() => {
                        _setCurrentSelectContest(removeAtIndex(_currentSelectContest, i))
                      }}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </button>
                  </li>
                ))}
              </ConditionalLoader>
            </ul>
            <StandaredSelect
              required
              options={allContestsList.map((contest: { id: string; name: string }) => contest.name)}
              placeholder="Select Contests"
              onChange={handleContestChange}
              value={
                _currentSelectContest.length > 0 ? _currentSelectContest[_currentSelectContest.length - 1].name : ""
              }
            />

            <Button type="submit" disabled={loading} loading={loading}>
              Create Placement Cohort
            </Button>
          </div>
        </form>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
