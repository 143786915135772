import { Auth } from "domain/model/Auth"
import { ActiveBatchRepository } from "domain/repository/Admin/ActiveBatchRepository"

export interface GetActiveBatchesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetActiveBatches implements GetActiveBatchesUseCase {
  private repository: ActiveBatchRepository

  constructor(repository: ActiveBatchRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getActiveBatches(auth)
  }
}
