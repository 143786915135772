import GetAllClassesDataSource from "data/dataSource/Admin/GetAllClassesDataSource"
import MentorSessionsDataSource from "data/dataSource/Admin/MentorSessionsDataSource"
import { DuplicateClass } from "domain/model/AllClasses"
import { Auth } from "domain/model/Auth"
import { GroupMentorSession } from "domain/model/GroupMentorSession"

export class MentorSessionsRepositoryImpl implements MentorSessionsRepositoryImpl {
  private dataSource: MentorSessionsDataSource

  constructor(dataSource: MentorSessionsDataSource) {
    this.dataSource = dataSource
  }

  async getMentorList(auth: Auth, mentor_email: string): Promise<any> {
    return this.dataSource.getMentorList(auth, mentor_email)
  }

  async getMentorRating(auth: Auth, mentor_id: string): Promise<any> {
    return this.dataSource.getMentorRating(auth, mentor_id)
  }

  async getMentorSessions(auth: Auth, mentor_id: string, batch: string, month: string, year: any): Promise<any> {
    return this.dataSource.getMentorSessions(auth, mentor_id, batch, month, year)
  }

  async updateMentorSession(
    auth: Auth,
    session_id: string,
    status: string,
    actual_duration: number,
    considered_duration: number
  ): Promise<any> {
    return this.dataSource.updateMentorSession(auth, session_id, status, actual_duration, considered_duration)
  }

  async mentorSessionInvoicing(auth: Auth, mentor_id: string, year_month: string): Promise<any> {
    return this.dataSource.mentorSessionInvoicing(auth, mentor_id, year_month)
  }

  async finalizeMentorSessionInvoicing(auth: Auth, mentor_id: string, year_month: string): Promise<any> {
    return this.dataSource.finalizeMentorSessionInvoicing(auth, mentor_id, year_month)
  }

  async createGroupMentorSession(auth: Auth, group_session: GroupMentorSession): Promise<any> {
    return this.dataSource.createGroupMentorSession(auth, group_session)
  }

  async updateGroupMentorSession(auth: Auth, session_id: string, group_session: GroupMentorSession): Promise<any> {
    return this.dataSource.updateGroupMentorSession(auth, session_id, group_session)
  }

  async deleteGroupMentorSession(auth: Auth, session_id: string): Promise<any> {
    return this.dataSource.deleteGroupMentorSession(auth, session_id)
  }

  async getGroupMentorSession(auth: Auth, mentor_id: string, batch: string, month: string, year: any): Promise<any> {
    return this.dataSource.getGroupMentorSession(auth, mentor_id, batch, month, year)
  }
}
