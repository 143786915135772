import useStudentProgressViewModel from "./StudentProgressViewModel"
import { CrossIcon } from "core/constants/svgs"
import BarGraph from "assets/svgs/BarGraph"
import Book from "assets/svgs/Book"
import Crown from "assets/svgs/Crown"
import Recording from "assets/svgs/Recording"
import DetailedProgressDataView from "./components/DetailedProgressDataView"
import DetailedProgressGraphView from "./components/DetailedProgressGraphView"
import BriefProgressView from "./components/BriefProgressView"

const StudentProgressView = () => {
  const {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    fromDateMs,
    toDateMs,
    progress,
    seeDetailedReport,
    setSeeDetailedReport,
    showFirst6Months,
    setShowFirst6Months,
    detailedReportHandler,
    activeButton,
    setActiveButton,
    graphYAxisString,
    setGraphYAxisString,
    isWeekly,
    setIsWeekly,
    year,
    setYear,
    month,
    setMonth,
    getDetailedProgressReport,
    detailedProgress,
    increaseTimelineHandler,
    decreaseTimelineHandler,
    briefProgressHandler,
  } = useStudentProgressViewModel()

  return (
    <div className="relative">
      {seeDetailedReport && (
        <div className="absolute w-[100%] h-[100vh] left-0 top-0 z-10">
          <div
            className="left-0 top-0 w-[100%] h-[100vh] opacity-90 bg-[#0b0b0b]"
            onClick={() => {
              setSeeDetailedReport(false)
            }}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-8 w-[811px] border border-[#d3d3d3] bg-white rounded-lg opacity-100">
            <div className="mb-5 h-[43px] justify-between items-start inline-flex w-[100%]">
              <div className="self-stretch flex-col justify-start items-start inline-flex">
                <div className="text-[#333232] text-lg font-semibold font-['Inter']">Detailed Progress Report</div>
                <div className="text-[#727272] text-sm font-medium font-['Inter']">View detailed progress report</div>
              </div>
              <button
                onClick={() => {
                  setSeeDetailedReport(false)
                }}
              >
                <CrossIcon className="size-[24px]" />
              </button>
            </div>
            <div className="mb-5 gap-[15px] inline-flex w-[100%]">
              <div
                onClick={() => {
                  setActiveButton("Classes Scheduled")
                  setGraphYAxisString("Number of Classes scheduled")
                }}
                className={`w-28 cursor-pointer py-3 ${activeButton === "Classes Scheduled" ? "bg-[#152356]" : "border border-[#d3d3d3]"} rounded shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] flex-col justify-center items-center gap-1 inline-flex`}
              >
                <div>
                  <Recording color={activeButton === "Classes Scheduled" ? "#fff" : "#001664"} />
                </div>
                <div
                  className={`text-center ${activeButton === "Classes Scheduled" ? "text-white" : "text-[#001664]"} text-[10px] font-semibold`}
                >
                  Classes
                </div>
              </div>
              <div
                onClick={() => {
                  setActiveButton("Mentor Sessions")
                  setGraphYAxisString("N/A")
                }}
                className={`w-28 cursor-pointer py-3 ${activeButton === "Mentor Sessions" ? "bg-[#152356]" : "border border-[#d3d3d3]"} rounded shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] flex-col justify-center items-center gap-1 inline-flex`}
              >
                <div>
                  <Crown color={activeButton === "Mentor Sessions" ? "#fff" : "#001664"} />
                </div>
                <div
                  className={`text-center ${activeButton === "Mentor Sessions" ? "text-white" : "text-[#001664]"} text-[10px] font-semibold`}
                >
                  Mentor Sessions
                </div>
              </div>
              <div
                onClick={() => {
                  setActiveButton("Exams")
                  setGraphYAxisString("Exams")
                }}
                className={`w-28 cursor-pointer py-3 ${activeButton === "Exams" ? "bg-[#152356]" : "border border-[#d3d3d3]"} rounded shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] flex-col justify-center items-center gap-1 inline-flex`}
              >
                <div>
                  <Book color={activeButton === "Exams" ? "#fff" : "#001664"} />
                </div>
                <div
                  className={`text-center ${activeButton === "Exams" ? "text-white" : "text-[#001664]"} text-[10px] font-semibold`}
                >
                  Exams
                </div>
              </div>
              <div
                onClick={() => {
                  setActiveButton("Problems Solved")
                  setGraphYAxisString("Problems Solved")
                }}
                className={`w-28 cursor-pointer py-3 ${activeButton === "Problems Solved" ? "bg-[#152356]" : "border border-[#d3d3d3]"} rounded shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] flex-col justify-center items-center gap-1 inline-flex`}
              >
                <div>
                  <BarGraph color={activeButton === "Problems Solved" ? "#fff" : "#001664"} />
                </div>
                <div
                  className={`text-center ${activeButton === "Problems Solved" ? "text-white" : "text-[#001664]"} text-[10px] font-semibold`}
                >
                  Problem Solving
                </div>
              </div>
            </div>
            <div className="mb-5 gap-4 inline-flex w-[100%]">
              <button
                onClick={() => {
                  setIsWeekly(true)
                  setMonth(new Date().getMonth())
                  setYear(new Date().getFullYear())
                  getDetailedProgressReport(new Date().getFullYear(), new Date().getMonth(), showFirst6Months)
                }}
                className={`w-[90px] py-1.5 ${isWeekly ? "bg-[#001664] text-white" : "bg-white text-[#838383] border border-[#d3d3d3]"} rounded  text-sm font-medium text-center`}
              >
                Weekly
              </button>
              <button
                onClick={() => {
                  setIsWeekly(false)
                  setMonth(null)
                  setYear(new Date().getFullYear())
                  setShowFirst6Months(new Date().getMonth() < 6)
                  getDetailedProgressReport(new Date().getFullYear(), null, new Date().getMonth() < 6)
                }}
                className={`w-[90px] py-1.5 ${!isWeekly ? "bg-[#001664] text-white" : "bg-white text-[#838383] border border-[#d3d3d3]"} rounded  text-sm font-medium text-center`}
              >
                Monthly
              </button>
            </div>
            <div className="p-3 h-[418px] bg-white rounded-lg shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] border border-[#d3d3d3] flex">
              <div className="w-[50%] mb-3 flex flex-col justify-end">
                <DetailedProgressGraphView
                  detailedProgress={detailedProgress}
                  activeButton={activeButton}
                  graphYAxisString={graphYAxisString}
                />
                <div className="flex justify-between align-middle pr-3">
                  <button
                    onClick={decreaseTimelineHandler}
                    className="px-2 h-6 flex align-middle text-sm rounded justify-center relative bg-[#152356] text-white"
                  >
                    <div>&lt;</div>
                  </button>
                  <p className="text-[#636363] font-medium text-sm">
                    {isWeekly &&
                      month !== null &&
                      new Date(year, month, 1).toLocaleString("default", { month: "long" })}{" "}
                    {year}
                  </p>
                  <button
                    onClick={increaseTimelineHandler}
                    className="px-2 h-6 flex align-middle text-sm rounded justify-center relative bg-[#152356] text-white"
                  >
                    <div>&gt;</div>
                  </button>
                </div>
              </div>
              <DetailedProgressDataView
                detailedProgress={detailedProgress}
                activeButton={activeButton}
                month={month}
                year={year}
                showFirst6Months={showFirst6Months}
              />
            </div>
          </div>
        </div>
      )}
      <BriefProgressView
        briefProgressHandler={briefProgressHandler}
        fromDate={fromDate}
        toDate={toDate}
        progress={progress}
        detailedReportHandler={detailedReportHandler}
        setToDate={setToDate}
        setFromDate={setFromDate}
        fromDateMs={fromDateMs}
        toDateMs={toDateMs}
      />
    </div>
  )
}

export default StudentProgressView
