import { Auth } from "domain/model/Auth"
import { Company } from "domain/model/Questions"
import { AdminLoginRepository } from "domain/repository/Admin/AdminLoginRepository"

export interface getAdminLoginUseCase {
  invoke(email: string): Promise<any>
}

export class GetAdminLogin implements getAdminLoginUseCase {
  private repository: AdminLoginRepository

  constructor(repository: AdminLoginRepository) {
    this.repository = repository
  }

  async invoke(email: string) {
    return await this.repository.getAdminLogin(email)
  }
}
