export default function Footer() {
  return (
    <div className="flex text-[#FFF] px-53 py-[48px] justify-center items-center gap-2.5 bg-[#162456]  rounded-[8px] h-[93px]">
      <div className="w-full h-auto text-center text-sm leading-[22.4px]">
        <div className="font-bold text-[16px]">Best Wishes !!</div>
        <div className="text-[16px] text-[#D3D3D3]  font-light ">
          <div>On your journey from the Bosscoder placement team!</div>
          <div>May you achieve your dream offer.</div>
        </div>
      </div>
    </div>
  )
}
