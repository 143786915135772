import DashboardLayout from "core/layouts/DashboardLayout"
import { Server } from "core/utils/axios"
import React, { useEffect } from "react"
import SuperAdminLoggerDashboardViewModel from "./SuperAdminLoggerViewModel"
import SuperAdminLoggerDashboardTable from "./Components/SuperAdminLoggerDashboard"
import Input from "core/components/Input"
import DividerButton from "core/components/DividerButton"
import Skeleton from "core/components/Skeleton"
const SuperAdminLoggerDashboardView = () => {
  const {
    getAllLogs,
    adminLogs,
    superAdminLoggerTableHeaders,
    showLoggerModal,
    setShowLoggerModal,
    loggerDetails,
    setLoggerDetails,
    showPayload,
    convertTimestamp,
    handleDateChange,
    fromDate,
    toDate,
    pageNo,
    setPageNo,
    loadMoreHandler,
    loading,
    exhausted,
    setAdminLogs,
    clearFilterHandler,
    setExhausted,
  } = SuperAdminLoggerDashboardViewModel()
  useEffect(() => {
    getAllLogs()
  }, [pageNo])

  useEffect(() => {
    if (toDate && fromDate) {
      setPageNo(1)
      setExhausted(false)
      setAdminLogs([])
      getAllLogs()
    }
  }, [toDate, fromDate])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Admin Portal Activity Logs</h2>
          <p>View Detailed Logs of Actions Performed by Admin </p>
        </div>

        <div className="flex space-x-2 mb-5">
          <div className="space-y-2">
            <label>From Date : </label>
            <Input
              type="date"
              placeholder="From Date"
              onChange={handleDateChange}
              value={fromDate !== null ? new Date(fromDate * 1000).toISOString().split("T")[0] : ""}
              className="w-[200px] h-[40px]"
              id="fromDate"
            />
          </div>
          <div className="space-y-2">
            <label>To Date : </label>
            <Input
              type="date"
              placeholder="To Date"
              className="w-[200px] h-[40px]"
              onChange={handleDateChange}
              id="toDate"
              value={toDate !== null ? new Date(toDate * 1000).toISOString().split("T")[0] : ""}
            />
          </div>

          <button
            onClick={clearFilterHandler}
            className="border-[1px] px-[12px] h-[40px] my-2 rounded-md p-1 ml-2 hover:bg-new-accent hover:text-white hover:cursor-pointer"
            id="button"
          >
            Clear Date Filter
          </button>
        </div>
        <SuperAdminLoggerDashboardTable
          tableHeaders={superAdminLoggerTableHeaders}
          adminLogs={adminLogs}
          showLoggerModal={showLoggerModal}
          setShowLoggerModal={setShowLoggerModal}
          loggerDetails={loggerDetails}
          setLoggerDetails={setLoggerDetails}
          showPayload={showPayload}
          convertTimestamp={convertTimestamp}
        />
        <Skeleton loading={loading} handler={loadMoreHandler} exhausted={exhausted} />
      </div>
    </DashboardLayout>
  )
}

export default SuperAdminLoggerDashboardView
