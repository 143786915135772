import { Auth } from "domain/model/Auth"
import { GoalSettingSession } from "domain/model/GoalSettingSession"
import GoalSettingRepository from "domain/repository/Admin/GoalSettingRepository"

export interface CreateGroupMentorSessionUseCase {
  invoke(auth: Auth, group_session: GoalSettingSession): Promise<any>
}

export default class CreateGroupMentorSession implements CreateGroupMentorSessionUseCase {
  private repository: GoalSettingRepository

  constructor(repository: GoalSettingRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, group_session: GoalSettingSession) {
    return await this.repository.createGoalSettingSession(auth, group_session)
  }
}
