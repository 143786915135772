import { Auth } from "domain/model/Auth"
// import { AllStudentsReqType } from "domain/model/PlacementDashboard"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface GetJourneysByIdUsecase {
  invoke(auth: Auth, docId: String): Promise<any>
}

export class GetJourneysById implements GetJourneysByIdUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: string): Promise<any> {
    return await this.repository.getJourneysById(auth, docId)
  }
}
