import { useAuth } from "core/context/auth"
import { SuperAdminDashboardLoggerAPIDataSourceImpl } from "data/API/SuperAdmin/SuperAdminLoggerDashboardAPIDataSourceImpl"
import { SuperAdminLoggerDashboardRepositoryImpl } from "data/repository/SuperAdmin/SuperAdminLoggerDashboardRepositoryImpl"
import { getAdminLogs } from "domain/useCase/SuperAdmin/SuperAdminLoggerDashboard/GetAdminLogs"
import { useState } from "react"

export default function SuperAdminLoggerDashboardViewModel() {
  const getAdminLogsUseCase = new getAdminLogs(
    new SuperAdminLoggerDashboardRepositoryImpl(new SuperAdminDashboardLoggerAPIDataSourceImpl())
  )

  const superAdminLoggerTableHeaders = [
    "Action",
    "API Endpoint",
    "Method",
    "User ID",
    "Status",
    "Date & Time",
    "Details",
  ]

  const { auth } = useAuth()
  const [adminLogs, setAdminLogs] = useState([])
  const [showLoggerModal, setShowLoggerModal] = useState(false)
  const [loggerDetails, setLoggerDetails] = useState("")
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)
  const [pageNo, setPageNo] = useState(1)
  const [loading, setLoading] = useState(false)
  const [exhausted, setExhausted] = useState(false)
  const [maxPages, setMaxPages] = useState(1)
  const getAllLogs = async () => {
    const details = {
      pageNo: pageNo,
      startDate: fromDate,
      endDate: toDate,
    }
    const response = await getAdminLogsUseCase.invoke(auth, details)
    if (response) {
      if (response.data.logs) {
        setAdminLogs((prevAdminLogs) => [...prevAdminLogs, ...response.data.logs] as any)
        setMaxPages(response.data.totalPages)
        setLoading(false)
      }
    }
  }
  const showPayload = (requestData: any) => {
    const data = requestData
    const formattedData = JSON.stringify(data, null, 2)
    return formattedData
  }

  function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const dateValue = e.target.value
    const timestamp = dateValue ? new Date(dateValue).getTime() / 1000 : null
    if (e.target.id === "fromDate") {
      setFromDate(timestamp)
    } else if (e.target.id === "toDate") {
      setToDate(timestamp)
    }
  }

  const convertTimestamp = (timestamp: any) => {
    const { _seconds } = timestamp
    const date = new Date(_seconds * 1000)
    const day = String(date.getDate()).padStart(2, "0") // Ensure 2-digit day
    const month = String(date.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
  }

  const loadMoreHandler = () => {
    if (pageNo <= maxPages) {
      setLoading(true)
      setPageNo(pageNo + 1)
    } else {
      setExhausted(true)
    }
  }

  const clearFilterHandler = () => {
    setFromDate(null)
    setToDate(null)
    setPageNo(1)
    setExhausted(false)
    setLoading(false)
    setAdminLogs([])
    getAllLogs()
  }
  return {
    adminLogs,
    setAdminLogs,
    getAllLogs,
    superAdminLoggerTableHeaders,
    showLoggerModal,
    setShowLoggerModal,
    setLoggerDetails,
    loggerDetails,
    showPayload,
    convertTimestamp,
    toDate,
    fromDate,
    handleDateChange,
    pageNo,
    setPageNo,
    loadMoreHandler,
    exhausted,
    loading,
    setExhausted,
    clearFilterHandler,
  }
}
