import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface GetGoalSettingStatusUpdateUseCase {
  invoke(auth: Auth, id: string, status: string): Promise<any>
}

export default class GetGoalSettingStatusUpdate implements GetGoalSettingStatusUpdateUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, id: string, status: string): Promise<any> {
    return await this.repository.getGoalSettingStatusUpdate(auth, id, status)
  }
}
