import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Student/ContestRepository"

export interface GetAllExamsUseCase {
  invoke(auth: Auth, page: number, limit: number): Promise<any>
}

export default class GetAllExams implements GetAllExamsUseCase {
  private repository: ContestRepository
  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, page: number, limit: number): Promise<any> {
    return await this.repository.getAllExams(auth, page, limit)
  }
}
