import { format } from "date-fns"

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return `${day}th`
  switch (day % 10) {
    case 1:
      return `${day}st`
    case 2:
      return `${day}nd`
    case 3:
      return `${day}rd`
    default:
      return `${day}th`
  }
}

const formatCustomDate = (date: Date): string => {
  const day = format(date, "d")
  const ordinalDay = getOrdinalSuffix(parseInt(day))
  const monthYear = format(date, "MMM, yyyy")
  return `${ordinalDay} ${monthYear}`
}

const RoadmapCard = ({ data, type, roadMap, batch }: any) => {
  const timestamp = data?.["timestamp"] || 0

  const formattedDate = timestamp !== 0 ? formatCustomDate(new Date(timestamp * 1000)) : ""

  return (
    <div className="relative mb-[5px] min-w-[150px] space-y-[5px] p-4">
      <input
        type="checkbox"
        className="rounded-[2px] border-[#D3D3D3] checked:bg-[#10b133]"
        checked={data?.value}
        readOnly
      />

      <div className="border-t-1 absolute top-6 -z-10 h-0 w-full border border-dashed border-[#d3d3d3]"></div>
      <p className="h-[20px] w-[118px] text-[10px] font-medium text-[#646464]">
        {["Offer Received", "Goal Setting Session", batch?.includes("Accelerator") && "Batch Started"]?.includes(type)
          ? "DD/MM/YYYY"
          : formattedDate || "DD/MM/YYYY"}
      </p>
      <p className="w-4/5 break-words text-xs font-medium text-[#333]">{type}</p>
    </div>
  )
}

export default RoadmapCard
