import { SuperAdminDashboardDataSource } from "data/dataSource/SuperAdmin/SuperAdminDashboardDataSource"
import { Auth } from "domain/model/Auth"

import { deleteAdminDetails, getAdminDetails, updateRoleAdminDetails } from "domain/model/SuperAdminDashboard"
export class SuperAdminDashboardRepositoryImpl implements SuperAdminDashboardRepositoryImpl {
  private dataSource: SuperAdminDashboardDataSource
  constructor(dataSource: SuperAdminDashboardDataSource) {
    this.dataSource = dataSource
  }
  getAllAdmins(request: getAdminDetails, auth: Auth): Promise<any> {
    return this.dataSource.getAllAdmins(request, auth)
  }
  deleteAdmin(request: deleteAdminDetails, auth: Auth): Promise<any> {
    return this.dataSource.deleteAdmin(request, auth)
  }
  updateRoleAdmin(request: updateRoleAdminDetails, auth: Auth): Promise<any> {
    return this.dataSource.updateRoleAdmin(request, auth)
  }
}
