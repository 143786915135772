import { useAuth } from "core/context/auth"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { GetJourneysById } from "domain/useCase/Admin/Placement/GetJourneysById"
import { useEffect, useState } from "react"

export default function GeneralInfoSection(docId: any) {
  const { auth } = useAuth()
  const [journeys, setJourneys] = useState<any>([])

  const GetJourneysByIdUsecase = new GetJourneysById(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  useEffect(() => {
    fetchJourneysById()
  }, [])

  async function fetchJourneysById() {
    if (!docId) {
      console.error("docId is undefined")
      return
    }
    const response = await GetJourneysByIdUsecase.invoke(auth, docId)
    if (response?.success) {
      const data = response?.data
      setJourneys(data?.journeys || [])
    }
  }

  return (
    <div className="rounded-[2px] w-full border-[0.4px] p-6 flex justify-between items-center">
      <div className="w-full">
        <p className="text-[#312E81] font-semibold">Journey</p>
        <hr className="w-full border-t-2 border-dashed border-gray-300 my-4"></hr>
        {journeys.length > 0 ? (
          <div className="pt-4 flex flex-col gap-4">
            {journeys.map((journey: any, index: number) => (
              <div key={index} className="flex justify-between items-center">
                <div className="flex items-center pr-8 gap-6">
                  <p>
                    {journey?.time?._seconds
                      ? new Date(journey.time._seconds * 1000).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
                      : "N/A"}
                  </p>
                  <p className="min-w-32 text-[18px] font-semibold font-[#414141]">{journey.message}</p>
                  <p>{journey.status}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No journey history available.</p>
        )}
      </div>
    </div>
  )
}
