import { Button } from "core/components/v2/Button"
import { CrossIcon } from "core/constants/svgs"

const LogoutConfirm = ({ onClose, handleLogout }: any) => {
  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => {}} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="font-montserrat m-auto flex h-[160px] w-[304px] transform flex-col gap-3 rounded border bg-new-solid-white p-4">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-[#333] text-[16px] font-[500] ">Logout Confirmation</h3>
              </div>
              <div>
                <button onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <p className="text-[#414141] text-[14px] font-[400]">Are you sure you want to logout?</p>

            <div className="flex gap-4 my-[10px] justify-end">
              <Button onClick={onClose} variant={"cancel"} border={"thin"}>
                No
              </Button>
              <Button
                variant={"primary"}
                onClick={() => {
                  handleLogout()
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoutConfirm
