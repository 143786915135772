import IconButton from "core/components/IconButton"
import Spinner from "core/components/Spinner"
import { ToggleButton } from "core/components/v2/ToggleButton"
import { cn } from "core/lib/utils"
import { STR_PAID } from "core/constants/strings"
import { InfoIcon } from "core/constants/svgs"
import { isEmpty } from "core/utils/misc"
import { capitalize } from "core/utils/string"
import React, { FormEvent } from "react"
import { MyDrawer } from "core/components/v2/MyDrawer"
import { Checkbox } from "core/components/v2/CheckBox"
import { IconHolder } from "core/components/v2/IconHolder"
import Mail from "assets/svgs/v2/Mail"
import SendMailIcon from "assets/svgs/v2/SendMailIcon"
import EditButtonSvg from "assets/svgs/v2/EditButtonSvg"
import { AdminInterface } from "domain/model/SuperAdminDashboard"

import Modal from "core/components/Modal"
import Button from "core/components/Button"
import Eye from "assets/svgs/Eye"
import { EyeIcon } from "lucide-react"

export default function SuperAdminLoggerDashboardTable(props: any) {
  const {
    showLoggerModal,
    setShowLoggerModal,
    loggerDetails,
    setLoggerDetails,
    adminLogs,
    convertTimestamp,
    showPayload,
  } = props

  return (
    <div className="overflow-x-auto relative rounded-md shadow-md mt-5 h-full" style={{ overflow: "auto" }}>
      <table className="border-separate rounded-xl w-full text-left text-sm text-new-neutral-dark border-spacing-y-2">
        <thead className="bg-new-neutral-dark font-bold-100 text-new-solid-white ">
          <tr>
            {props?.tableHeaders?.map((title: any, index: any) => (
              <th
                key={index}
                className={`whitespace-nowrap px-4 py-4 font-light ${
                  index === 0 ? "rounded-l-md" : ""
                } ${index === props.tableHeaders.length - 1 ? "rounded-r-md" : ""}`}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {adminLogs &&
            adminLogs?.map((data: any, index: any) => {
              return (
                <tr
                  className="relative bg-new-solid-white border-[1px] row-border hover:cursor-pointer hover:bg-slate-50 h-[65px]"
                  key={data.id}
                >
                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p
                        className={`text-[14px] ${data.httpMethod === "GET" ? "text-green-500" : data.httpMethod === "POST" ? "text-blue-500" : data.httpMethod === "PUT" ? "text-yellow-500" : "text-red-500"} font-semibold`}
                      >
                        {data.action}
                      </p>
                    </div>
                  </td>
                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p className="text-[14px] font-semibold">{data.apiEndpoint}</p>
                    </div>
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p
                        className={`text-[14px] ${data.httpMethod === "GET" ? "text-green-500" : data.httpMethod === "POST" ? "text-blue-500" : data.httpMethod === "PUT" ? "text-yellow-500" : "text-red-500"} font-semibold`}
                      >
                        {data.httpMethod}
                      </p>
                    </div>
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p className="text-[14px] font-semibold">{data.userId}</p>
                    </div>
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p
                        className={`text-[14px] ${data.status === "success" ? "text-green-500" : "text-red-500"} font-semibold`}
                      >
                        {data.status}
                      </p>
                    </div>
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p className="text-[14px] font-semibold">{convertTimestamp(data.timestamp)}</p>
                    </div>
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div
                      className="flex items-center gap-2 text-new-accent hover:text-white  "
                      onClick={() => {
                        setLoggerDetails(showPayload(data.requestData))
                        setShowLoggerModal(true)
                      }}
                    >
                      <Button outlined className="mr-2 h-fit w-fit hover:bg-[#312e81] ">
                        <EyeIcon className="w-5 " />
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <Modal
        open={showLoggerModal}
        onClose={() => {
          setShowLoggerModal(false)
        }}
      >
        <pre className="overflow-x-auto w-full h-fit">{loggerDetails}</pre>
      </Modal>
    </div>
  )
}
