import { useAuth } from "core/context/auth"
import { TCreateQuestion, TQuestion, TQuestionSolution, TUpdateQuestion } from "domain/model/Questions"
import React, { ChangeEvent, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { STR_DESCRIPTION, STR_FAILURE, STR_SOLUTION, STR_SUCCESS } from "core/constants/strings"
import GetQuestionByTopic from "domain/useCase/Admin/Questions/GetQuestionByTopic"
import QuestionsRepositoryImpl from "data/repository/Admin/QuestionsRepository"
import QuestionsAPIDataSourceImpl from "data/API/Admin/QuestionsAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { genError, parseTexts } from "core/utils/string"
import CreateQuestion from "domain/useCase/Admin/Questions/CreateQuestion"
import UpdateQuestion from "domain/useCase/Admin/Questions/UpdateQuestion"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { GetModuleClasses } from "domain/useCase/Admin/CreateClass/GetModuleClasses"
import { companyLogos } from "presentation/Student/Placement/utils/Company"
import { CompanyRepositoryImpl } from "data/repository/Admin/CompanyRepositoryImpl"
import { CompanyAPIDataSourceImpl } from "data/API/Admin/CompanyAPIDataSourceImpl"
import { GetCompany } from "domain/useCase/Admin/Company/GetCompany"

export default function QuestionViewModel() {
  const tabs = [STR_DESCRIPTION, STR_SOLUTION]
  const optionsDifficulty = [
    {
      label: "Easy",
      value: "easy",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Hard",
      value: "hard",
    },
  ]

  const optionsSolutionHeading = [
    {
      label: "Code for C++",
      value: "Code for C++",
    },
    {
      label: "Code for Java",
      value: "Code for Java",
    },
    {
      label: "Code for Python",
      value: "Code for Python",
    },
    {
      label: "Code for JavaScript",
      value: "Code for JavaScript",
    },
    {
      label: "Time Complexity",
      value: "Time Complexity",
    },
  ]

  const { id } = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  type TOption = {
    label: string | number
    value: string | number
  }

  const getQuestionByTopicUseCase = new GetQuestionByTopic(
    new QuestionsRepositoryImpl(new QuestionsAPIDataSourceImpl())
  )

  const createQuestionUseCase = new CreateQuestion(new QuestionsRepositoryImpl(new QuestionsAPIDataSourceImpl()))

  const getCompanyUseCase = new GetCompany(new CompanyRepositoryImpl(new CompanyAPIDataSourceImpl()))

  const updateQuestionUseCase = new UpdateQuestion(new QuestionsRepositoryImpl(new QuestionsAPIDataSourceImpl()))

  const getAllCoursesUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllModulesUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  const getAllTopicsUseCase = new GetModuleClasses(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const [activeTab, setActiveTab] = useState<number>(0)
  const [question, setQuestion] = useState<TQuestion>({
    input: [{ value: "" }],
    difficulty_level: "",
    company_tags: [""],
    solution: [{ heading: "", description: "" }],
  } as TQuestion)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [isNewQuestion, setIsNewQuestion] = useState<boolean | null>(null)
  const [tags, setTags] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [allCourses, setAllCourses] = useState<any[]>([])
  const [allModules, setAllModules] = useState<any[]>([])
  const [allTopics, setAllTopics] = useState<any[]>([])
  const [requiredFields, setRequiredFields] = useState<boolean>(false)
  const [selectedModules, setSelectedModules] = useState<Array<string>>([])
  const [company, setCompany] = useState<string>("")
  const [companysTagsList, setCompanysTagsList] = useState<TOption[]>([{ label: "Others", value: "Others" }])
  const [companyTags, setCompanyTags] = useState<string[]>([])
  const [selectedTopics, setSelectedTopics] = useState<{
    [key: string]: Array<string>
  }>({})
  const [currentSelectedModule, setCurrentSelectedModule] = useState<string>("")
  const [currentSelectedTopic, setCurrentSelectedTopic] = useState<string>("")

  /**
   * Fetch all the courses or tracks
   */
  const fetchAllCourses = async () => {
    setLoading(true)
    const response = await getAllCoursesUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the courses"))
      changeToastVisibility(true)
      return
    }

    let _allCourse = []
    for (let course of response.data) {
      _allCourse.push({ label: course, value: course })
    }
    setAllCourses(_allCourse)
  }

  /**
   * Fetch all the Modules based on the selected course
   * @param course
   */
  const fetchAllModules = async (course: string) => {
    if (!course) {
      return
    }

    setLoading(true)
    const response = await getAllModulesUseCase.invoke(auth, course)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the modules"))
      changeToastVisibility(true)
      return
    }

    let _allModules = []
    for (let module of response.data?.modules) {
      _allModules.push({ label: module, value: module })
    }
    setAllModules(_allModules)
  }

  /**
   * Fetch all the topics based on selected course
   * @param module
   */
  const fetchAllTopics = async (module: string) => {
    if (!module) {
      return
    }

    setLoading(true)
    const response = await getAllTopicsUseCase.invoke({
      id_token: auth.id_token,
      module: module,
    })
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the topics"))
      changeToastVisibility(true)
      setLoading(false)
      return
    }

    let _allTopics: any = []
    for (let topic of response.data) {
      _allTopics.push({ label: topic.topic, value: topic.topic })
    }
    setAllTopics(_allTopics)
  }

  const fetchQuestion = async (topic: string) => {
    const addQuestion = topic === "add"
    setIsNewQuestion(addQuestion)

    if (addQuestion) {
      setPageLoading(false)
      return
    }

    const response = await getQuestionByTopicUseCase.invoke(auth, topic)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      setPageLoading(false)
      return
    }

    const data = response?.data
    const questionData = data?.question as TQuestion

    await fetchAllModules(questionData?.course)
    await fetchAllTopics(questionData?.modules?.[0])
    setPageLoading(false)

    setQuestion(questionData)
    setSelectedModules(questionData?.modules ?? [])
    setSelectedTopics(questionData?.topics ?? {})
    setCurrentSelectedModule(questionData?.modules?.[0] ?? "")
    setCurrentSelectedTopic(questionData?.topics?.[currentSelectedModule]?.[0] ?? "")
    setCompanyTags(questionData?.company_tags ?? [])
    const tags = questionData?.tags?.join(",")
    if (tags) setTags(tags)
  }

  const handleTabChange = (index: number) => {
    setActiveTab(index)
  }

  const handleTagsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tags = e.target.value
    setTags(tags)
    setQuestion((q) => ({ ...q, tags: parseTexts(tags) }))
  }

  const handleQuestionChange = async (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const name = e.target.name
    setQuestion((q) => ({ ...q, [name]: e.target.value }))
    if (name === "course") {
      await fetchAllModules(e.target.value)
      setCurrentSelectedModule("")
    }
  }

  const addModule = async (e: any) => {
    const module: string = e.target.value

    setCurrentSelectedModule(module)

    setSelectedModules((prevModules: any) => {
      let exists = false
      prevModules.forEach((mod: string) => {
        if (mod === module) {
          exists = true
        }
      })
      if (exists) {
        return prevModules
      }
      return [...prevModules, module]
    })

    await fetchAllTopics(module)
    setCurrentSelectedTopic("")
  }

  const removeModule = (id: number) => {
    const module = selectedModules?.[id]
    setSelectedModules(selectedModules.filter((_, i) => i !== id))
    setSelectedTopics((prevTopics: any) => {
      const allTopics = { ...prevTopics }
      delete allTopics[module]
      return allTopics
    })
    setCurrentSelectedModule("")
    setCurrentSelectedTopic("")
  }

  const addTopic = (e: any) => {
    const topic: string = e.target.value

    setCurrentSelectedTopic(topic)

    setSelectedTopics((prevTopics) => {
      const newTopics = { ...prevTopics }
      const topics = newTopics?.[currentSelectedModule] ?? []

      let exists = false
      topics.forEach((top: string) => {
        if (top === topic) {
          exists = true
        }
      })

      if (exists) {
        return prevTopics
      }

      newTopics[currentSelectedModule] = [...topics, topic]
      return newTopics
    })
  }

  const removeTopic = (id: number) => {
    setSelectedTopics((prevTopics: any) => {
      const _prevTopics = { ...prevTopics }
      const topics = _prevTopics?.[currentSelectedModule] ?? []
      _prevTopics[currentSelectedModule] = topics.filter((_: any, i: number) => i !== id)
      return _prevTopics
    })
    setCurrentSelectedTopic("")
  }

  const handleAddInput = () => {
    setQuestion((q) => ({ ...q, input: [...q.input, { value: "" }] }))
  }

  const handleNthInputChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
    setQuestion((q) => ({
      ...q,
      input: q.input.map(({ value }, i) => ({
        value: i === index ? e.target.value : value,
      })),
    }))
  }

  const handleRemoveLastInput = () => {
    setQuestion((q) => ({
      ...q,
      input: q.input.slice(0, -1),
    }))
  }

  const checkAllRequiredFields = () => {
    const fields: (keyof TCreateQuestion)[] = [
      "course",
      "modules",
      "topics",
      "topic",
      "question_details",
      "difficulty_level",
      "problem_name",
      "leetcode_link",
      "tags",
      // "input",
      // "constraints",
      // "solution",
    ]

    for (const field of fields) {
      if (field === "input") {
        if (!question[field] || question[field].some((content) => content.value === "")) {
          setRequiredFields(false)
          return
        }
      } else if (field === "solution") {
        if (
          !question[field] ||
          question[field]?.some((content) => content.heading === "" || content.description === "")
        ) {
          setRequiredFields(false)
          return
        }
      } else if (field === "modules") {
        if (!selectedModules || selectedModules?.length === 0) {
          setRequiredFields(false)
          return
        }
      } else if (field === "topics") {
        if (!selectedTopics) {
          setRequiredFields(false)
          return
        }
        for (let module of selectedModules) {
          if (!selectedTopics[module]) {
            setRequiredFields(false)
            return
          }
        }
      } else {
        if (!question[field]) {
          setRequiredFields(false)
          return
        }
      }
    }
    setRequiredFields(true)
  }

  const createQuestion = async () => {
    const fields: (keyof TCreateQuestion)[] = [
      "course",
      "modules",
      "topics",
      "topic",
      "question_details",
      "difficulty_level",
      "problem_name",
      "leetcode_link",
      "tags",
      "input",
      "constraints",
      "solution",
      "company_tags",
    ]

    let bodyData = {} as TCreateQuestion
    question["modules"] = selectedModules
    question["topics"] = selectedTopics
    question["company_tags"] = companyTags
    for (const field of fields) {
      bodyData = {
        ...bodyData,
        [field]: question[field] as TCreateQuestion[keyof TCreateQuestion],
      }
    }

    setLoading(true)

    const response = await createQuestionUseCase.invoke(auth, bodyData)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, response?.data as string)
    changeToastVisibility(true)
  }

  const updateQuestion = async () => {
    const fields: (keyof TUpdateQuestion)[] = [
      "course",
      "modules",
      "topics",
      "topic",
      "question_details",
      "difficulty_level",
      "problem_name",
      "leetcode_link",
      "tags",
      "input",
      "constraints",
      "solution",
      "company_tags",
    ]
    let bodyData = {} as TUpdateQuestion
    question["modules"] = selectedModules
    question["topics"] = selectedTopics
    question["company_tags"] = companyTags
    for (const field of fields) {
      if (question[field]) {
        bodyData = {
          ...bodyData,
          [field]: question[field] as TQuestionSolution[],
        }
      }
    }
    setLoading(true)

    const response = await updateQuestionUseCase.invoke(auth, bodyData)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, response?.data as string)
    changeToastVisibility(true)
  }

  const handleAddSolution = () => {
    const newEmptySolution = { heading: "", description: "" }
    setQuestion((q) => ({
      ...q,
      solution: q.solution ? [...q.solution, newEmptySolution] : [newEmptySolution],
    }))
  }

  const handleRemoveLastSolution = () => {
    setQuestion((q) => ({
      ...q,
      solution: q.solution && q.solution.length > 1 ? q.solution.slice(0, -1) : [{ heading: "", description: "" }],
    }))
  }

  const handleNthSolutionChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>, index: number) => {
    const newSolution = {
      [e.target.name]: e.target.value,
    } as TQuestionSolution

    setQuestion((q) => ({
      ...q,
      solution: q.solution
        ? q.solution.map((solution, i) => (i === index ? { ...solution, ...newSolution } : solution))
        : [newSolution],
    }))
  }

  const handleCompany = (e: ChangeEvent<HTMLSelectElement>) => {
    setCompany(e.target.value)
    if (e.target.value === "") return

    if (e.target.value === "Others") {
      return
    }
    if (companyTags?.some((tag) => tag === e.target.value)) return
    if (companyTags == undefined) {
      setCompanyTags([e.target.value])
      setCompany("")
      return
    }
    setCompanyTags([...companyTags, e.target.value])
    setCompany("")
  }

  const handleRemoveCompany = (index: number) => {
    const newCompanyList = companyTags?.filter((_, i) => i !== index)
    setCompanyTags(newCompanyList)
  }

  const fetchAllCompanies = async () => {
    const response = await getCompanyUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error Occurred")
      changeToastVisibility(true)
      return
    }
    if (response?.data) {
      const newTagsList = response.data.map((company: { name: string }) => ({
        label: company.name, // Label will be the name of the company
        value: company.name, // Value will be the name of the company
        // value: company.name.toLowerCase().replace(/\s+/g, "_"), // Value will be a lowercase version with underscores
      }))

      // Assuming you want to append these to an existing list
      setCompanysTagsList(newTagsList)
    }
  }

  return {
    id,
    tabs,
    activeTab,
    question,
    pageLoading,
    auth,

    optionsDifficulty,
    toast,
    isNewQuestion,
    tags,
    loading,
    company,
    companyTags,
    companysTagsList,
    optionsSolutionHeading,
    handleTabChange,
    navigate,
    changeToastVisibility,
    fetchQuestion,
    handleTagsChange,
    handleQuestionChange,
    handleAddInput,
    handleNthInputChange,
    handleRemoveLastInput,
    createQuestion,
    updateQuestion,
    handleAddSolution,
    handleRemoveLastSolution,
    handleNthSolutionChange,
    handleCompany,
    handleRemoveCompany,
    fetchAllCourses,
    allCourses,
    allModules,
    allTopics,
    requiredFields,
    checkAllRequiredFields,
    selectedModules,
    selectedTopics,
    addModule,
    removeModule,
    addTopic,
    removeTopic,
    currentSelectedModule,
    fetchAllCompanies,
    currentSelectedTopic,
  }
}
