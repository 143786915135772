import SubmissionDataSource from "data/dataSource/Student/SubmissionDataSource"
import { Auth } from "domain/model/Auth"
import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import {
  API_V4_STUDENT,
  API_SUBMIT_ASSIGNMENT_QUESTION,
  API_V4_GET_STUDENT_SUBMISSION,
  SUBMIT_ASSIGNMENT_QUESTION,
  GET_AI_FEEDBACK,
  STUDENT_AI_FEEDBACK,
} from "core/constants/strings"

const server = new Server()
export default class SubmissionAPIDataSourceImpl implements SubmissionDataSource {
  async submitAssignmentQuestion(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_ASSIGNMENT_QUESTION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitSubjectiveQuestion(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn("student", SUBMIT_ASSIGNMENT_QUESTION), data, {
        Authorization: `Bearer ${auth.id_token}`,
        "Content-Type": "application/json",
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAIFeedback(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn("student", GET_AI_FEEDBACK), data, {
        Authorization: `Bearer ${auth.id_token}`,
        "Content-Type": "application/json",
      })
      return response
    } catch (error) {
      return error
    }
  }

  async studentAIFeedback(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn("student", STUDENT_AI_FEEDBACK), data, {
        Authorization: `Bearer ${auth.id_token}`,
        "Content-Type": "application/json",
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSubmission(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_STUDENT_SUBMISSION), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
