import React from "react"

export default function Download({ className }: any) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.66688 10.781V2.66634H7.33355L7.33355 10.781L3.75755 7.20501L2.81488 8.14768L8.00021 13.333L13.1855 8.14768L12.2429 7.20501L8.66688 10.781Z"
        fill="#646464"
      />
      <rect
        width="1.33105"
        height="10"
        transform="matrix(8.74228e-08 1 1 -8.74228e-08 3.00586 12.0029)"
        fill="#646464"
      />
    </svg>
  )
}
