import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import DoubtSupportDataSourceImpl from "data/API/Admin/DoubtSupportDataSourceImpl"
import { DoubtSupportRepositoryImpl } from "data/repository/Admin/DoubtSupportRepositoryImpl"
import GetAllDevelopers from "domain/useCase/Admin/DoubtSupport/GetAllDevelopers"
import GetDoubtSupportSchedule from "domain/useCase/Admin/DoubtSupport/GetDoubtSupportSchedule"
import UpdateDoubtSupportSchedule from "domain/useCase/Admin/DoubtSupport/UpdateDoubtSupportSchedule"
import { useState } from "react"
type Developer = {
  email: string
  phone: string
}
export default function DoubtSupportViewModel() {
  const { auth } = useAuth()
  const [developers, setDevelopers] = useState([])
  const [schedule, setSchedule] = useState<{ [date: string]: Developer | null }>({})
  const [dates, setDates] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getAllDevelopersUseCase = new GetAllDevelopers(new DoubtSupportRepositoryImpl(new DoubtSupportDataSourceImpl()))
  const updateDoubtSupportScheduleUseCase = new UpdateDoubtSupportSchedule(
    new DoubtSupportRepositoryImpl(new DoubtSupportDataSourceImpl())
  )
  const getDoubtSupportScheduleUseCase = new GetDoubtSupportSchedule(
    new DoubtSupportRepositoryImpl(new DoubtSupportDataSourceImpl())
  )

  async function getAllDevelopers() {
    const response = await getAllDevelopersUseCase.invoke(auth)
    console.log(auth, "auth hai")

    if (response?.success) {
      setDevelopers(response?.data)
    }
  }
  async function updateDoubtSupportSchedule(data: any) {
    setLoading(true)
    const response = await updateDoubtSupportScheduleUseCase.invoke(auth, data)
    if (response?.success) {
      setSchedule(response?.data)
      changeToastDetails(STR_SUCCESS, "Schedule updated successfully!")
      changeToastVisibility(true)
    } else {
      changeToastDetails(STR_FAILURE, "Error updating schedule!")
      changeToastVisibility(true)
    }
    setLoading(false)

    // if (response?.success) {

    // }
  }

  async function getDoubtSupportSchedule() {
    const response = await getDoubtSupportScheduleUseCase.invoke(auth)
    console.log(response?.data)
    setSchedule(response?.data)

    // if (response?.success) {

    // }
  }
  return {
    getDoubtSupportSchedule,
    getAllDevelopers,
    schedule,
    toast,
    changeToastDetails,
    changeToastVisibility,
    setSchedule,
    dates,
    setDates,
    loading,
    developers,
    updateDoubtSupportSchedule,
  }
}
