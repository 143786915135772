export default function UploadIcon({ className }: any) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none">
      <path
        d="M2.81055 11.9443V13.3495C2.81055 13.7222 2.95859 14.0796 3.22212 14.3431C3.48564 14.6066 3.84306 14.7547 4.21574 14.7547H12.6469C13.0196 14.7547 13.377 14.6066 13.6405 14.3431C13.904 14.0796 14.0521 13.7222 14.0521 13.3495V11.9443M4.91833 6.32353L8.43131 2.81055M8.43131 2.81055L11.9443 6.32353M8.43131 2.81055V11.2417"
        stroke="#202658"
        strokeWidth="1.40519"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
