import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetTempMentorAnalysisUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}
export class GetTempMentorAnalysis implements GetTempMentorAnalysisUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.getTempMentorAnalysis(auth, data)
  }
}
