import { Auth } from "domain/model/Auth"
import { deleteAdminDetails, getAdminDetails } from "domain/model/SuperAdminDashboard"
import { SuperAdminDashboardRepository } from "domain/repository/SuperAdmin/SuperAdminDashboardRepository"

export interface deleteAdminUseCase {
  invoke(details: deleteAdminDetails, auth: Auth): Promise<any>
}
export class deleteAdmin implements deleteAdminUseCase {
  private repository: SuperAdminDashboardRepository

  constructor(repository: SuperAdminDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: deleteAdminDetails, auth: Auth): Promise<any> {
    return await this.repository.deleteAdmin(details, auth)
  }
}
