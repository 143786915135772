import React from "react"

interface FeatureFeedbackCardProps {
  studentName: string
  pageName: string
  feedbackText: string
  pageRating: number
}

const FeatureFeedbackCard: React.FC<FeatureFeedbackCardProps> = ({
  studentName,
  pageName,
  feedbackText,
  pageRating,
}) => {
  const options = ["Not at all useful", "Slightly useful", "Moderately useful", "Very useful", "Extremely useful"]

  return (
    <div className="bg-white shadow-md rounded-lg p-[24px] h-[303px] w-[672px]">
      <h3
        className="text-2xl text-gray-600 leading-normal"
        style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "600" }}
      >
        Feature Feedback
      </h3>
      <p className="text-gray-500 font-sans text-sm font-normal leading-normal">
        Feedback of {studentName} for {pageName}.
      </p>
      <div className="bg-white flex items-start gap-6 h-[191px] w-[624px] mt-4 mb-4">
        <div className="flex flex-col space-y-1 w-[258px] h-[191px]">
          {options.map((option, index) => (
            <div
              key={index}
              className={`p-2.5 rounded-md border-transparent mt-1 h-[35px] ${
                pageRating === index + 1 ? "bg-[#FFEDED] font-bold" : "bg-transparent"
              }`}
            >
              <p className="[color:var(--Black-and-white-Gray-600,#333)] font-inter text-xs font-normal leading-normal">
                {option}
              </p>
            </div>
          ))}
        </div>
        <div className="w-[342px] h-[191px]">
          <p className="text-gray-500 font-sans text-sm font-semibold leading-normal">
            Custom feedback or Suggestions<span className="text-red-600">*</span>
          </p>
          <div className="w-[342px] h-[171px] rounded bg-[#F9F9F9] overflow-y-auto" style={{ maxHeight: "171px" }}>
            <p className="p-[10px]">{feedbackText}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureFeedbackCard
