import React from "react"
import CopyIcon from "assets/svgs/CopyIcon"
import Button from "core/components/new/Button"
import { format } from "date-fns"
import { useLocation } from "react-router-dom"
import { Tooltip } from "@mui/material"

const BatchAssessmentCard = ({
  studentData,
  batch,
  classData,
  handleSubmissionClick,
  handleSSMFeedback,
  handleCopyToClipboard,
  buttonStatus,
  fetchSSMAndSubmissionStatus,
  index,
}: any) => {
  const location = useLocation()

  React.useEffect(() => {
    fetchSSMAndSubmissionStatus(location.pathname.split("/").at(-2), studentData["email_id"], index)
  }, [studentData["email_id"]])

  return (
    <div className="p-6 text-new-neutral-dark shadow">
      <div className="flex items-center">
        <div className="cursor-pointer">
          <button
            className="flex items-center justify-center"
            title={studentData["email_id"]}
            onClick={() => handleCopyToClipboard(studentData["email_id"])}
          >
            <CopyIcon />
          </button>
        </div>
        <h3 className="ml-2">{studentData["name"]}</h3>
      </div>
      <div className="mt-2">
        <h4>{batch}</h4>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex flex-wrap items-center">
          <p className="text-[#ADADAD] font-semibold">{classData.chapter_name}</p>
          <span className="mx-1 text-[#ADADAD]">|</span>
          <p className="text-[#ADADAD] font-semibold">
            {format(parseInt(classData.session_timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}
          </p>
        </div>
        <div className="flex space-x-4">
          {buttonStatus?.["ssmEnabled"] ? (
            <Button
              outlined
              className="w-fit"
              onClick={() => handleSSMFeedback(studentData["email_id"])}
              disabled={!buttonStatus?.["ssmEnabled"]}
            >
              SSM Feedback
            </Button>
          ) : (
            <Tooltip title="No Feedback Exists" placement="bottom">
              <span>
                <Button
                  outlined
                  className="w-fit"
                  onClick={() => handleSSMFeedback(studentData["email_id"])}
                  disabled={!buttonStatus?.["ssmEnabled"]}
                >
                  SSM Feedback
                </Button>
              </span>
            </Tooltip>
          )}
          {buttonStatus?.["enableSubmission"] ? (
            <Button
              outlined
              className="w-fit"
              onClick={() => handleSubmissionClick(location.pathname, studentData["email_id"])}
              disabled={!buttonStatus?.["enableSubmission"]}
            >
              View Submissions
            </Button>
          ) : (
            <Tooltip title="No Submission Exists" placement="bottom">
              <span>
                <Button
                  outlined
                  className="w-fit"
                  onClick={() => handleSubmissionClick(location.pathname, studentData["email_id"])}
                  disabled={!buttonStatus?.["enableSubmission"]}
                >
                  View Submissions
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default BatchAssessmentCard
