import React from "react"
import StandaredSelect from "core/components/StandaredSelect"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import { CrossIcon } from "core/constants/svgs"
import CreateGoalSettingSession from "domain/useCase/Admin/MentorSessions/CreateGoalSettingSession"
import { GoalSettingSessionsRepositoryImpl } from "data/repository/Admin/GoalSettingSessionRepositoryImpl"
import GoalSettingDataSourceImpl from "data/API/Admin/GoalSettingDataSourceImpl"
import { GoalSettingSession } from "domain/model/GoalSettingSession"
import { useAuth } from "core/context/auth"
import { useParams, useNavigate } from "react-router-dom"
import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import MentorsViewModel from "presentation/Admin/MentorSessions/MentorsViewModel"

export default function CreateGoalSettingSessionForm({ reloadData, setReloadData }: any) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const { getBatchList, allMentorsList, fetchAllMentorList, getBatches } = MentorsViewModel()

  const [mentorForSession, setMentorForSession] = React.useState<string>("")
  const [batchForSession, setBatchForSession] = React.useState<string>("")
  const [startTime, setStartTime] = React.useState<number>(0)
  const [endTime, setEndTime] = React.useState<number>(0)
  const [sessionStatus, setSessionStatus] = React.useState<string>("Not Completed")
  const [date, setDate] = React.useState<any>()
  const [agenda, setAgenda] = React.useState<string>("")
  const [duration, setDuration] = React.useState<number>(60)
  const [studentId, setStudentId] = React.useState<string>("")

  const CreateGoalSettingSessionUseCase = new CreateGoalSettingSession(
    new GoalSettingSessionsRepositoryImpl(new GoalSettingDataSourceImpl())
  )

  async function createGoalSettingSession() {
    setLoading(true)
    const goalSettingSession: GoalSettingSession = {
      auth,
      student_id: studentId,
      mentor_id: mentorForSession,
      start_time: startTime.toString(),
      end_time: endTime.toString(),
      date: date.toString(),
      batch: batchForSession,
      duration: "30",
      agenda: agenda,
      session_status: "Completed",
      room_id: null,
      mentor_feedback: {},
      student_review: {},
      meet_link: null,
      meet_link_teacher: null,
    }

    const response = await CreateGoalSettingSessionUseCase.invoke(auth, goalSettingSession)
    setLoading(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Goal Setting Session Created!")

    setTimeout(() => {
      setReloadData(!reloadData)
    }, 2000)
  }

  React.useEffect(() => {
    fetchAllMentorList()
    getBatches()
    setMentorForSession(id as string)
  }, [auth])

  function handleStartTime(e: any) {
    setStartTime(e.target.value)
  }

  function handleEndTime(e: any) {
    setEndTime(e.target.value)
  }

  function handleDuration(e: any) {
    setDuration(e.target.value)
  }

  return (
    <div className="">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col">
          <h4>Date Of Session</h4>
          <Input placeholder="Date" type="date" value={date} onChange={(e) => setDate(e.target.value)} />
        </div>

        <div className="flex flex-col">
          <h4>Start Time</h4>
          <Input
            placeholder="Start Time in 24 hours format"
            type="number"
            value={startTime}
            onChange={handleStartTime}
          />
        </div>

        <div className="flex flex-col">
          <h4>End Time</h4>
          <Input placeholder="End Time in 24 hours format" type="number" value={endTime} onChange={handleEndTime} />
        </div>

        <div className="flex flex-col">
          <h4>Batch</h4>
          <select value={batchForSession} onChange={(e) => setBatchForSession(e.target.value)}>
            <option value="">Select Batch</option>
            {getBatchList?.map((batch: string, index: number) => (
              <option key={index} value={batch}>
                {batch}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <h4>Student Email</h4>
          <Input placeholder="Student Email" value={studentId} onChange={(e) => setStudentId(e.target.value)} />
        </div>

        <div className="flex flex-col">
          <h4>Agenda</h4>
          <Input placeholder="Agenda" value={agenda} onChange={(e) => setAgenda(e.target.value)} />
        </div>

        <Button loading={loading} onClick={createGoalSettingSession}>
          Add Goal Setting Session
        </Button>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
