import AwardEmpty from "assets/svgs/AwardEmpty"
import DottedCircleLoder from "assets/svgs/DottedCircleLoder"
import { Button } from "core/components/v2/Button"
import StandardSelect from "core/components/v2/StandardSelect"
import { AwardIcon, CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { useState } from "react"

const AttendanceFailureModal = ({
  isOpen,
  onClose,
  waitingTime,
  sessionName,
  handleNewSubmitFeedback,
  onMenteeAbsent,
  onTakenElseWhere,
}: any) => {
  if (!isOpen) return null

  const [rating, setRating] = useState([
    {
      title: "Confidence",
      star: 0,
    },
    {
      title: "Naming Convention",
      star: 0,
    },
    {
      title: "Reaction to Hints",
      star: 0,
    },
    {
      title: "Time Complexity Analysis",
      star: 0,
    },
    {
      title: "Thinking Process",
      star: 0,
    },
    {
      title: "Implementation Speed",
      star: 0,
    },
    {
      title: "Explanation Clarity",
      star: 0,
    },
    {
      title: "Overall Rating",
      star: 0,
    },
  ])

  const [status, setStatus] = useState("")
  const [newComment, setNewComment] = useState("")

  const handleClick = (index: number, i: number) => {
    const newRating = rating
    newRating[i].star = index + 1

    setRating([...newRating])
  }
  return (
    <div className="relative z-40 ">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0 "></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 flex min-h-screen items-center justify-center">
        <div className="relative bg-white w-[450px] overflow-y-auto rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="p-[24px]">
            <div className="flex justify-between">
              <p className="text-[#333] text-[16px] font-semibold">Waiting Lobby</p>
              <button
                className={cn("hover:bg-slate-200 rounded-[4px]", waitingTime !== 0 && "hidden")}
                onClick={onClose}
              >
                <CrossIcon className="size-[24px]" />
              </button>
            </div>

            <p className="text-[#646464] text-[14px] font-[500]">{sessionName}</p>

            <div className="flex my-[16px] gap-[8px] items-center">
              <div className="rounded-[4px] bg-[#F5F5F5] px-[12px] py-[12px]">
                <p className="text-[15px]">It's taking longer than expected, we will redirect you in...</p>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between">
              <div className="flex my-[16px] gap-[8px] items-center">
                <DottedCircleLoder />
                <div className="rounded-[4px] bg-[#F5F5F5] px-[6px] py-[4px]">
                  <span className="text-[#DC2626] text-[14px] font-[600]">
                    {waitingTime > 0 ? <>00:{String(waitingTime).padStart(2, "0")} Secs</> : <>00:00 Sec</>}
                  </span>
                </div>
              </div>
              <Button onClick={handleNewSubmitFeedback} variant={"primary"} disabled={waitingTime !== 0}>
                Click Here
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AttendanceFailureModal
