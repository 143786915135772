import { Auth } from "domain/model/Auth"
import { AllStudentsReqType } from "domain/model/PlacementDashboard"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface GetStudentListUsecase {
  invoke(auth: Auth, data: AllStudentsReqType): Promise<any>
}

export class GetStudentList implements GetStudentListUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: AllStudentsReqType): Promise<any> {
    return await this.repository.getStudentList(auth, data)
  }
}
