import IconButton from "core/components/IconButton"
import Spinner from "core/components/Spinner"
import Toggle from "core/components/Toggle"
import { cn } from "core/lib/utils"
import { STR_PAID } from "core/constants/strings"
import { InfoIcon } from "core/constants/svgs"
import { isEmpty } from "core/utils/misc"
import { capitalize } from "core/utils/string"
import { Link } from "react-router-dom"
import ConfirmationPopUpDeleteStudent from "./ConfirmationPopUpDeleteStudent"
import { useAuth } from "core/context/auth"

export default function DashboardTable(props: any) {
  const { auth, user } = useAuth()
  const handleToggleDisableStudent = (student: any) => {
    if (!student?.disabled) {
      props.openModal(student?.email)
    } else {
      props.toggleDisableStudent(student?.email)
    }
  }

  const handlePermanentToggleDisableStudent = (student: any) => {
    if (!student?.pemanent_disable) {
      props.openPermanentDisableStudentModal(student?.email)
    } else {
      props.togglePermanentDisableStudent(student?.email)
    }
  }

  const handleViewDisableStudentReason = (student: any) => {
    props.viewDisableReason()
    props.openModal(student?.email)
  }

  const handleViewPermanentDisableStudentReason = (student: any) => {
    props.viewPermanentDisableReason()
    props.openPermanentDisableStudentModal(student?.email)
  }

  const handleDeleteStudent = (email: string) => {
    props.setDeleteEmail(email)
    props.setDeleteModel(true)
  }

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      {props.deleteModel && (
        <ConfirmationPopUpDeleteStudent
          setDeleteModel={props.setDeleteModel}
          heading="Are you sure you want to delete this student?"
          email={props.deleteEmail}
          deleteStudent={props.deleteStudent}
        />
      )}
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.students?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={data.email}>
              <td className="px-6 py-4 ">
                <p>{data.name}</p>
                <p className="font-medium">{data.email}</p>
                <p> {data.phone}</p>
                <p className="font-medium">{capitalize(data.course_type)}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.batch}</td>
              <td className="px-6 py-4 text-[16px]">
                {" "}
                {data.last_week_count} / {data.leetcode_count}
              </td>
              <td className="px-6 py-4">
                <Toggle checked={data.payment_status === STR_PAID} onChange={() => props.paidToUnpaid(data.email)} />
              </td>

              {/* disable student toggle */}
              <td className="px-6 py-4">
                <div className="flex gap-2 items-center">
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      className="peer sr-only"
                      checked={data.disabled}
                      onChange={() => handleToggleDisableStudent(data)}
                    />
                    <div
                      className={cn(
                        "peer h-6 w-11 rounded-full bg-gray-200 dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-red-300 after:content-[''] after: after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-new-solid-white after:transition-all dark:border-gray-600",
                        data.disabled && "peer-checked:after:translate-x-full peer-checked:bg-red-600",
                        data.disabled && "peer-checked:border-white"
                      )}
                    />
                  </label>
                  <button disabled={!data.disabled} onClick={() => handleViewDisableStudentReason(data)}>
                    <InfoIcon
                      className={cn(
                        "w-6 h-6",
                        !data.disabled ? "text-new-neutral-light cursor-not-allowed" : "text-new-neutral-dark"
                      )}
                    />
                  </button>
                </div>
              </td>

              <td className="px-6 py-4">
                <div className="flex gap-2 items-center">
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      className="peer sr-only"
                      checked={data?.pemanent_disable}
                      onChange={() => handlePermanentToggleDisableStudent(data)}
                    />
                    <div
                      className={cn(
                        "peer h-6 w-11 rounded-full bg-gray-200 dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-red-300 after:content-[''] after: after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-new-solid-white after:transition-all dark:border-gray-600",
                        data.pemanent_disable && "peer-checked:after:translate-x-full peer-checked:bg-red-600",
                        data.pemanent_disable && "peer-checked:border-white"
                      )}
                    />
                  </label>
                  <button
                    disabled={!data.pemanent_disable}
                    onClick={() => handleViewPermanentDisableStudentReason(data)}
                  >
                    <InfoIcon
                      className={cn(
                        "w-6 h-6",
                        !data.pemanent_disable ? "text-new-neutral-light cursor-not-allowed" : "text-new-neutral-dark"
                      )}
                    />
                  </button>
                </div>
              </td>
              <td className="px-6 py-4 text-[16px] ">
                <button
                  className="grid h-6 w-6 place-items-center"
                  onClick={async () => {
                    props.setMailSendLoading(index)
                    await props.sendConfirmationMail(data.email)
                    props.setMailSendLoading(-1)
                  }}
                >
                  {props.mailSendLoading === index ? (
                    <Spinner small />
                  ) : data.mail_sent ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#4ADE80"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#F87171"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-mail"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                  )}
                </button>
              </td>
              <td className="px-6 py-4 text-[16px]">
                <Link to={`/admin/students/${data.email}`} onClick={props.handleEditClick}>
                  <IconButton
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    }
                  />
                </Link>
              </td>
              {user?.is_superadmin && (
                <td className="px-6 py-4 text-[16px]">
                  <IconButton
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="red"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit"
                      >
                        <path d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    }
                    onClick={() => handleDeleteStudent(data.email)}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
