import { FeedbackRepository } from "domain/repository/Student/NewFeedbackRepository"
import { Auth } from "domain/model/Auth"
import { FeedbackPage } from "domain/model/NewFeedback"
import NewFeedbackDataSource from "data/dataSource/Student/NewFeedbackDataSource"

export class FeedbackRepositoryImpl implements FeedbackRepository {
  private dataSource: NewFeedbackDataSource

  constructor(dataSource: NewFeedbackDataSource) {
    this.dataSource = dataSource
  }

  async getLastFeedback(auth: Auth, page: string): Promise<{ last_feedback_time: string }> {
    return this.dataSource.getLastFeedback(auth, page)
  }

  async saveFeedback(auth: Auth, pages: Record<string, FeedbackPage[]>): Promise<void> {
    return this.dataSource.saveFeedback(auth, pages)
  }

  async getJoiningDate(auth: Auth): Promise<{ joining_date: string }> {
    return this.dataSource.getJoiningDate(auth)
  }
}
