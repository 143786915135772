import Avatar from "react-avatar"
import { cn } from "core/lib/utils"
import { companyLogos } from "../utils/Company"

export default function PlacementCard({ name, company_name, bg, student_pic }: any) {
  // console.log(bg)
  return (
    <div className=" relative bg-white overflow-hidden flex w-[226px] h-[115px]  shrink-0 flex-col items-center gap-2 rounded-[8px]  text-new-accent  shadow-card  mr-[16px] my-6">
      <div
        className={` z-10  top-10 absolute w-[143.23px] h-[120px]  bg-gradient-to-t from-white  left-3  opacity-20 ${bg} transform rotate-[200deg]`}
      ></div>
      <div
        className={` z-10  bottom-16 right-2 absolute w-[143.23px] h-[120px]  bg-gradient-to-b from-white  ${bg} opacity-20 transform rotate-[210deg]`}
      ></div>

      <div className="w-full z-40 flex items-center  justify-around h-[50%] ">
        <Avatar src={student_pic} name={name} round size="30" />

        <div className=" w-[142px] h-[35px] flex items-center">
          <p className=" font-semibold">{name}</p>
        </div>
      </div>

      <div className=" w-full h-[50%] z-50 flex items-center justify-center">
        <img className=" h-10 ml-3" src={companyLogos[company_name]} />
      </div>
      {/* <div className={cn(" rounded relative h-[66px] w-full", bg)}>
        <div className="absolute -bottom-[calc(50%+4px)] left-1/2 w-fit -translate-x-1/2 rounded-full border border-new-accent-light bg-new-solid-white p-1">
          <Avatar src={student_pic} name={name} round size="70" />
        </div>
      </div>
      <div className="mt-[46px] min-h-[44px] w-full px-6 text-center">
        <h3 className="text-center">{name}</h3>
      </div>
    
      <img className="w-[193px] h-[73px] object-contain" src={companyLogos[company_name]} /> */}
    </div>
  )
}
