export default function HyperLinkIcon({ className }: any) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none">
      <rect x="0.453125" y="0.5" width="16" height="16" rx="2" fill="#F9F9F9" />
      <path
        d="M7.78662 7.83398V3.83398H9.11995V7.83398H13.12V9.16732H9.11995V13.1673H7.78662V9.16732H3.78662V7.83398H7.78662Z"
        fill="#001018"
      />
    </svg>
  )
}
