function CopyIconV2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.70493C0 2.33368 0.283279 2.03271 0.632727 2.03271H7.44C7.78945 2.03271 8.07273 2.33368 8.07273 2.70493V11.3278C8.07273 11.6991 7.78945 12 7.44 12H0.632727C0.283281 12 0 11.6991 0 11.3278V2.70493ZM1.09091 3.1917V10.841H6.98182V3.1917H1.09091Z"
        fill="#646464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.56047 0C2.21102 0 1.92773 0.300964 1.92773 0.672214V2.47374H3.01865V1.15899H8.90962V8.80831H7.437V9.9673H9.36781C9.71726 9.9673 10.0005 9.66634 10.0005 9.29509V0.672213C10.0005 0.300961 9.71726 0 9.36781 0H2.56047Z"
        fill="#646464"
      />
    </svg>
  )
}

export default CopyIconV2
