import Loader from "core/components/Loader"

// FeedbackQuestion Component to display each question in the feedback modal
const FeedbackQuestion = ({ question, maxMarks }: any) => (
  <div className="w-4/5 flex flex-col flex-wrap">
    <h3 className="flex text-base font-semibold w-full text-new-accent capitalize">{question}</h3>
    <div className="flex items-baseline">
      <p className=" text-xs font-[500]">Max Marks: </p>
      <p className=" text-xs pl-2 ml-1">{maxMarks}</p>
    </div>
  </div>
)

const SubmissionResponse = ({
  feedbackData,
  order,
  isLoading,
  questionType,
  questionName,
  selectedQuestion,
  handleSelectedQuestionBtnClick,
}: any) => {
  if (isLoading) {
    return (
      <div className="h-[200px] w-full grid place-items-center">
        <Loader small />
      </div>
    )
  }

  const caseStudyQuestions = Object.keys(feedbackData)
  return questionType === "casestudy" && caseStudyQuestions.length > 0 ? (
    <div className="flex">
      {/* Left side */}
      <div className="w-[245px] space-y-4 p-2">
        <h3 className="text-lg text-[#312E81] font-semibold">Case Study Questions</h3>
        <div className="flex flex-wrap gap-2 w-full">
          {caseStudyQuestions.map((key, index) => (
            <button
              key={index}
              className={`flex items-center justify-center w-[60px] h-[40px] font-semibold px-6 py-2 text-sm rounded-[4px] ${selectedQuestion === index ? "text-white bg-new-accent" : "text-black bg-[#FAFAFA]"}`}
              onClick={() => handleSelectedQuestionBtnClick(index)}
            >
              {parseInt(feedbackData[key]?.questionName.split(" ")[1])}
            </button>
          ))}
        </div>
      </div>
      {/* Right side */}
      <div className="flex flex-col space-y-4 w-[579px] p-3 border-l-2 overflow-auto h-full custom-scrollbar-2">
        {/* Top part */}
        <div className="flex justify-between">
          <div className="space-y-2 w-full">
            {caseStudyQuestions.map((key, index) => {
              if (selectedQuestion === index) {
                return (
                  <FeedbackQuestion
                    key={index}
                    question={feedbackData[key]?.questionName}
                    maxMarks={feedbackData[key]?.max_marks || 0}
                  />
                )
              }
              return null
            })}
          </div>
        </div>
        {/* Bottom part */}
        <div className="h-full overflow-y-auto my-4 custom-scrollbar-2">
          <div className="space-y-2 pb-2">
            {caseStudyQuestions.map((key, index) => {
              if (selectedQuestion === index) {
                const feedback_Data = feedbackData[key]
                return Object.keys(order).map((key2, index2) => {
                  const fieldValue = feedback_Data[key2]
                  if (fieldValue === undefined || fieldValue === null) return null

                  const keyWidth = "w-1/3"
                  const valueWidth = key2 === "code_snippet" ? "w-2/3 overflow-x-auto" : "w-2/3"

                  return (
                    <div key={index2} className={`flex`}>
                      {(key2 !== "code_snippet" || (key2 === "code_snippet" && feedback_Data[key2].length > 0)) && (
                        <p className={`${keyWidth} text-xs font-normal text-[#414141]`}>{order[key2]}:</p>
                      )}
                      {key2 === "code_snippet" && (
                        <pre
                          className={`${valueWidth} text-base text-[#414141] bg-[#F7F7F7] px-3 py-[6px] rounded`}
                          dangerouslySetInnerHTML={{ __html: fieldValue }}
                        ></pre>
                      )}
                      {key2 === "rating" && (
                        <div className={`${valueWidth} text-[#FBBF24] text-sm font-semibold`}>{fieldValue} / 5</div>
                      )}
                      {key2 === "marks" && (
                        <div className={`${valueWidth} text-sm text-[#414141] font-semibold`}>
                          {fieldValue} / {feedback_Data["max_marks"] || 0}
                        </div>
                      )}
                      {key2 === "feedback_response" && (
                        <div
                          className={`${valueWidth} h-[200px] text-sm font-semibold text-[#414141] bg-[#F7F7F7] px-3 py-[6px] break-words rounded`}
                        >
                          {fieldValue}
                        </div>
                      )}
                      {key2 === "files" && Array.isArray(fieldValue) && fieldValue.length > 0 && (
                        <div className={`${valueWidth} bg-[#F7F7F7] p-2 rounded`}>
                          {fieldValue.map((file, idx) => (
                            <ul className="list-disc list-inside space-y-2" key={idx}>
                              <li>
                                <a
                                  className="px-4 font-semibold text-sm underline text-blue-500"
                                  href={file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Reference {idx + 1}
                                </a>
                              </li>
                            </ul>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                })
              }
              return null
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col space-y-4 w-full p-3 overflow-auto h-full custom-scrollbar-2">
      <div className="flex justify-between">
        <div className="space-y-2 w-full">
          <FeedbackQuestion
            key={feedbackData?.["questionName"]}
            question={feedbackData?.["questionName"]}
            maxMarks={feedbackData?.max_marks || 0}
          />
        </div>
      </div>
      {/* Bottom part */}
      <div className="h-full overflow-y-auto my-4 custom-scrollbar-2">
        <div className="space-y-4" key={feedbackData["questionName"]}>
          {Object.keys(order).map((key, index) => {
            const fieldValue = feedbackData[key]
            if (fieldValue === undefined || fieldValue === null) return null
            const keyWidth = "w-1/3"
            const valueWidth = key === "code_snippet" ? "w-2/3 overflow-x-auto" : "w-2/3"
            return (
              <div
                key={index}
                className={`flex ${key !== "code_snippet" && key !== "feedback_response" && key !== "files" ? "items-center" : ""}`}
              >
                {(key !== "code_snippet" || (key === "code_snippet" && feedbackData["code_snippet"].length > 0)) && (
                  <p className={`${keyWidth} text-xs font-normal text-[#414141]`}>{order[key]}:</p>
                )}
                {key === "code_snippet" && feedbackData["code_snippet"].length > 0 && (
                  <pre
                    className={`${valueWidth} text-sm text-[#414141] bg-[#F7F7F7] px-3 py-[6px] rounded`}
                    dangerouslySetInnerHTML={{ __html: fieldValue }}
                  ></pre>
                )}
                {key === "rating" && (
                  <div className={`${valueWidth} text-[#FBBF24] text-sm font-semibold`}>{fieldValue} / 5</div>
                )}
                {key === "marks" && (
                  <div className={`${valueWidth} text-sm text-[#414141] font-semibold`}>
                    {fieldValue} / {feedbackData["max_marks"] || 0}
                  </div>
                )}
                {key === "feedback_response" && (
                  <div
                    className={`${valueWidth} h-[200px] text-sm font-semibold text-[#414141] bg-[#F7F7F7] px-3 py-[6px] break-words rounded`}
                  >
                    {fieldValue}
                  </div>
                )}
                {key === "files" && Array.isArray(fieldValue) && fieldValue.length > 0 && (
                  <div className={`${valueWidth} bg-[#F7F7F7] p-2 rounded`}>
                    {fieldValue.map((file, idx) => (
                      <ul className="list-disc list-inside space-y-2" key={idx}>
                        <li className="list-disc">
                          <a
                            className="px-4 font-semibold text-sm underline text-blue-500"
                            href={file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Reference {idx + 1}
                          </a>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SubmissionResponse
