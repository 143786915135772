import { Auth } from "domain/model/Auth"
import { Server } from "core/utils/axios"
import StudentProgressDataSource from "data/dataSource/Student/StudentProgressDataSource"
import { STUDENT } from "core/constants/strings"
import pn from "core/utils/pn"

const server = new Server()
export default class StudentProgressAPIDataSourceImpl implements StudentProgressDataSource {
  async getAllProgressData(auth: Auth, start?: number, end?: number): Promise<any> {
    const response = await server.post(
      pn(STUDENT, "student_progress", "all_progress"),
      { start, end },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }
}
