import { useState } from "react"
import { EndBatch } from "domain/useCase/Admin/Batch/EndBatch"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { GetAllActiveBatches } from "domain/useCase/Admin/Batch/GetAllActiveBatch"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export const EndBatchViewModel = () => {
  const [batch, setBatch] = useState<string>("")
  const [batches, setBatches] = useState<string[]>([])
  const [isEnding, setIsEnding] = useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const getAllActiveBatchUseCase = new GetAllActiveBatches(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))
  const getActiveBatches = async () => {
    const response = await getAllActiveBatchUseCase.invoke(auth)
    if (response.success) {
      setBatches(response.data)
    }
  }

  const handleChange = (e: any) => {
    const selectedBatch = e.target.value
    setBatch(selectedBatch)
  }

  const endBatchUseCase = new EndBatch(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))
  const handleClick = async () => {
    setIsEnding(true)
    if (batch == "") return
    try {
      const response = await endBatchUseCase.invoke({
        batchName: batch,
        id_token: auth.id_token.trim(),
      })
      if (response?.success) {
        changeToastDetails(STR_SUCCESS, "Batch ended successfully")
        changeToastVisibility(true)
      } else {
        changeToastDetails(STR_FAILURE, "Unable to end batch")
        changeToastVisibility(true)
      }
    } catch (err) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unable to end batch")
    } finally {
      setBatch("")
    }
    setIsEnding(false)
  }

  return {
    isEnding,
    setIsEnding,
    batch,
    setBatch,
    batches,
    setBatches,
    toast,
    handleChange,
    handleClick,
    getActiveBatches,
    changeToastVisibility,
  }
}
