import { Auth } from "domain/model/Auth"
import { email } from "domain/model/ContestApprove"
import AIFeedbackRepository from "domain/repository/Admin/AIFeedbackRepository"

export interface getFeedbackByEmailUseCase {
  invoke(auth: Auth, data: email): Promise<any>
}
export class GetFeedbackByEmail implements getFeedbackByEmailUseCase {
  private repository: AIFeedbackRepository

  constructor(repository: AIFeedbackRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: email): Promise<any> {
    return await this.repository.getFeedbackByEmail(auth, data)
  }
}
