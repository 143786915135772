import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { useState } from "react"
import { useAuth } from "core/context/auth"
import { SendEmail } from "domain/useCase/Admin/CommunityAnnouncements/SendEmail"
import { SendEmailRepositoryImpl } from "data/repository/Admin/SendEmailRepositoryImpl"
import SendEmailAPIDataSourceImpl from "data/API/Admin/SendEmailAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { STR_FAILURE } from "core/constants/strings"
import { STR_SUCCESS } from "core/constants/strings"
import sign from "assets/images/sign.png"
import { SendEmailToProgramme } from "domain/useCase/Admin/CommunityAnnouncements/SendEmailToProgramme"
import { SendEmailToBatch } from "domain/useCase/Admin/CommunityAnnouncements/SendEmailToBatch"
import { SendEmailToGroupOfStudents } from "domain/useCase/Admin/CommunityAnnouncements/SendEmailToGroupOfStudents"

export default function CommunityAnnouncementsViewModel() {
  const [batch, setBatch] = useState<any>([])
  const { auth } = useAuth()
  const [isCheckedForDisabled, setIsCheckedForDisbaled] = useState(false)
  const [isCheckedForBatch, setIsCheckedForBatch] = useState(false)
  const [imageFile, setImageFile] = useState("")
  const [isLogoVisible, setLogoVisible] = useState(true)
  const [fromAdd, setFromAdd] = useState("")
  const [password, setPassword] = useState("")
  const [emailData, setEmailData] = useState({
    subject: "",
    emailBody: "",
    program: "",
    batch: "",
    emailAddresses: "",
    includeDisabled: isCheckedForDisabled,
    includeCoursePaused: isCheckedForBatch,
    attachments: [] as File[],
  })
  const [content, setContent] = useState<string>("")
  const [attachments, setAttachments] = useState<File[]>([])
  const [messageData, setMessageData] = useState({
    messageBody: "",
    program: "",
    batch: "",
    phoneNumbers: "",
  })
  const [error, setError] = useState("")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [sending, setSending] = useState(Boolean)
  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const sendEmailUseCase = new SendEmail(new SendEmailRepositoryImpl(new SendEmailAPIDataSourceImpl()))
  const sendEmaiToProgrammelUseCase = new SendEmailToProgramme(
    new SendEmailRepositoryImpl(new SendEmailAPIDataSourceImpl())
  )
  const sendEmailToBatchUseCase = new SendEmailToBatch(new SendEmailRepositoryImpl(new SendEmailAPIDataSourceImpl()))
  const sendEmailToGroupOfStudentsUseCase = new SendEmailToGroupOfStudents(
    new SendEmailRepositoryImpl(new SendEmailAPIDataSourceImpl())
  )
  const substring = "{{Student Name}}"
  let textBefore = ""
  const index = emailData.emailBody.indexOf(substring)
  if (emailData.emailBody !== "") {
    textBefore = index !== -1 ? emailData.emailBody.substring(0, index) : ""
  }
  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)
    if (response.success) {
      setBatch(response.data)
    }
  }
  async function getImageFile(url: any) {
    const response = await fetch(url)
    const blob = await response.blob()
    const file = new File([blob], "logo.png", { type: "image/png" }) // Create a File object
    return file
  }

  async function sendEmailToAll(sign: any) {
    setSending(true)
    const formData = new FormData()
    formData.append("subject", emailData.subject)
    formData.append("salutation", textBefore.replaceAll("<p>", ""))
    formData.append("emailBody", `${emailData.emailBody}\n ${content}`)
    formData.append("program", emailData.program)
    formData.append("batch", emailData.batch)
    formData.append("emailAddresses", emailData.emailAddresses)
    formData.append("includeDisabled", JSON.stringify(emailData.includeDisabled))
    formData.append("includeCoursePaused", JSON.stringify(emailData.includeCoursePaused))
    if (attachments) {
      emailData.attachments.forEach((file: File) => {
        formData.append("attachments[]", file)
      })
    }
    const logoFile = await getImageFile(sign)
    if (isLogoVisible) {
      formData.append("logo", logoFile)
    }
    formData.append("fromadd", fromAdd)
    formData.append("password", password)
    const response2 = await sendEmailUseCase.invoke(auth, formData)
    if (!response2?.success) {
      changeToastDetails(STR_FAILURE, "Email not sent")
      setSending(false)
      changeToastVisibility(true)
      return
    } else {
      setSending(false)
      changeToastDetails(STR_SUCCESS, "Email sent!")
      changeToastVisibility(true)
    }
  }

  async function sendEmailToProgramme(sign: any) {
    setSending(true)
    const formData = new FormData()
    formData.append("subject", emailData.subject)
    formData.append("salutation", textBefore.replaceAll("<p>", ""))
    formData.append("emailBody", `${emailData.emailBody}\n ${content}`)
    formData.append("program", emailData.program)
    formData.append("batch", emailData.batch)
    formData.append("emailAddresses", emailData.emailAddresses)
    formData.append("includeDisabled", JSON.stringify(emailData.includeDisabled))
    formData.append("includeCoursePaused", JSON.stringify(emailData.includeCoursePaused))
    if (attachments) {
      emailData.attachments.forEach((file: File) => {
        formData.append("attachments[]", file)
      })
    }
    formData.append("fromadd", fromAdd)
    formData.append("password", password)
    const logoFile = await getImageFile(sign)
    if (isLogoVisible) {
      formData.append("logo", logoFile)
    }
    const response = await sendEmaiToProgrammelUseCase.invoke(auth, formData)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Email not sent")
      setSending(false)
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Email sent!")
      setSending(false)
      changeToastVisibility(true)
    }
  }

  async function sendEmailToBatch(sign: any) {
    setSending(true)
    const formData = new FormData()
    formData.append("subject", emailData.subject)
    formData.append("salutation", textBefore.replaceAll("<p>", ""))
    formData.append("emailBody", `${emailData.emailBody}\n ${content}`)
    formData.append("program", emailData.program)
    formData.append("batch", emailData.batch)
    formData.append("emailAddresses", emailData.emailAddresses)
    formData.append("includeDisabled", JSON.stringify(emailData.includeDisabled))
    formData.append("includeCoursePaused", JSON.stringify(emailData.includeCoursePaused))
    if (attachments) {
      emailData.attachments.forEach((file: File) => {
        formData.append("attachments[]", file)
      })
    }
    formData.append("fromadd", fromAdd)
    formData.append("password", password)
    const logoFile = await getImageFile(sign)
    if (isLogoVisible) {
      formData.append("logo", logoFile)
    }
    const response = await sendEmailToBatchUseCase.invoke(auth, formData)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Email not sent")
      setSending(false)
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Email sent!")
      setSending(false)
      changeToastVisibility(true)
    }
  }

  async function sendEmailToGroupOfStudents(sign: any) {
    setSending(true)
    const formData = new FormData()
    formData.append("subject", emailData.subject)
    formData.append("salutation", textBefore.replaceAll("<p>", ""))
    formData.append("emailBody", `${emailData.emailBody}\n ${content}`)
    formData.append("program", emailData.program)
    formData.append("batch", emailData.batch)
    formData.append("emailAddresses", emailData.emailAddresses)
    formData.append("includeDisabled", JSON.stringify(emailData.includeDisabled))
    formData.append("includeCoursePaused", JSON.stringify(emailData.includeCoursePaused))
    if (attachments) {
      emailData.attachments.forEach((file: File) => {
        formData.append("attachments[]", file)
      })
    }
    console.log(textBefore)
    const logoFile = await getImageFile(sign)
    if (isLogoVisible) {
      formData.append("logo", logoFile)
    }
    formData.append("fromadd", fromAdd)
    formData.append("password", password)
    const response = await sendEmailToGroupOfStudentsUseCase.invoke(auth, formData)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Email not sent")
      setSending(false)
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Email sent!")
      setSending(false)
      changeToastVisibility(true)
    }
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const handleInputChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    if (typeof value === "string") {
      setEmailData((prevData) => ({
        ...prevData,
        emailBody: value,
      }))
    } else {
      // Handle normal input events
      const { name, value: inputValue } = value.target
      setEmailData((prevData) => ({
        ...prevData,
        [name]: inputValue,
      }))
    }
  }

  const handleCheckboxChangeForDisabled = (event: any) => {
    setEmailData({ ...emailData, includeDisabled: event.target.checked })
    setIsCheckedForDisbaled(event.target.checked)
  }
  const handleCheckboxChangeFoBatch = (event: any) => {
    setEmailData({ ...emailData, includeCoursePaused: event.target.checked })
    setIsCheckedForBatch(event.target.checked)
  }
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || [])
    setAttachments(selectedFiles)
    setEmailData({ ...emailData, attachments: selectedFiles })
  }

  return {
    getBatches,
    batch,
    handleInputChange,
    emailData,
    sendEmailToAll,
    sendEmailToBatch,
    sendEmailToGroupOfStudents,
    setEmailData,
    toast,
    changeToastVisibility,
    sendEmailToProgramme,
    messageData,
    setMessageData,
    setAttachments,
    attachments,
    isCheckedForDisabled,
    handleFileChange,
    setImageFile,
    handleCheckboxChangeFoBatch,
    handleCheckboxChangeForDisabled,
    isCheckedForBatch,
    isLogoVisible,
    sending,
    setLogoVisible,
    error,
    setPassword,
    password,
    fromAdd,
    setFromAdd,
    changeToastDetails,
    sign,
    content,
    setContent,
  }
}
