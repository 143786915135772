import { StudentProgressRepositoryImpl } from "data/repository/Student/StudentProgressRepositoryImpl"
import StudentProgressAPIDataSourceImpl from "data/API/Student/StudentProgressAPIDataSouceImpl"
import { useEffect, useState } from "react"
import { useAuth } from "core/context/auth"
import { GetAllProgressData } from "domain/useCase/Student/StudentProgress/GetAllProgressData"

export default function StudentProgressViewModel() {
  const { auth } = useAuth()
  const [allProgress, setAllProgress] = useState<any>(null)
  const [fromDate, setFromDate] = useState<string | undefined>(undefined)
  const [toDate, setToDate] = useState<string | undefined>(undefined)
  const [fromDateMs, setFromDateMs] = useState<number | null>(null)
  const [toDateMs, setToDateMs] = useState<number | null>(null)
  const [progress, setProgress] = useState<any>(null)
  const [detailedProgress, setDetailedProgress] = useState<any>([])
  const [seeDetailedReport, setSeeDetailedReport] = useState<boolean>(false)
  const [isWeekly, setIsWeekly] = useState<boolean>(true)
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [showFirst6Months, setShowFirst6Months] = useState<boolean>(new Date().getMonth() < 6)
  const [month, setMonth] = useState<number | null>(new Date().getMonth())
  const [activeButton, setActiveButton] = useState<string>("Classes Scheduled")
  const [graphYAxisString, setGraphYAxisString] = useState<string>("Number of Classes Scheduled")

  useEffect(() => {
    fetchAllProgress()
  }, [])

  useEffect(() => {
    if (fromDate) {
      const dateSplit = fromDate.split("/")
      setFromDateMs(new Date(dateSplit.reverse().join("/")).getTime() / 1000)
    }
    if (toDate) {
      const dateSplit = toDate.split("/")
      setToDateMs(new Date(dateSplit.reverse().join("/")).getTime() / 1000 + (60 * 60 * 24 - 1))
    }
  }, [fromDate, toDate])

  const getAllProgressUseCase = new GetAllProgressData(
    new StudentProgressRepositoryImpl(new StudentProgressAPIDataSourceImpl())
  )

  const briefProgressHandler = () => {
    if (toDateMs && fromDateMs && allProgress) {
      setProgress(setStudentProgress(fromDateMs, toDateMs))
    }
  }

  const fetchAllProgress = async () => {
    const response = await getAllProgressUseCase.invoke(auth)
    if (response?.success) {
      setAllProgress(response?.data)
    }
  }

  const increaseTimelineHandler = () => {
    if (!isWeekly) {
      if (!showFirst6Months) {
        getDetailedProgressReport(year + 1, month, !showFirst6Months)
        setYear((state) => state + 1)
        setShowFirst6Months((state) => !state)
        return
      }
      getDetailedProgressReport(year, month, !showFirst6Months)
      setShowFirst6Months((state) => !state)
    } else {
      if (month === 11) {
        getDetailedProgressReport(year + 1, 0, showFirst6Months)
        setYear((state) => state + 1)
        setMonth(0)
      } else if (month !== null) {
        getDetailedProgressReport(year, month + 1, showFirst6Months)
        setMonth(month + 1)
      }
    }
  }

  const decreaseTimelineHandler = () => {
    if (!isWeekly) {
      if (showFirst6Months) {
        getDetailedProgressReport(year - 1, month, !showFirst6Months)
        setYear((state) => state - 1)
        setShowFirst6Months((state) => !state)
        return
      }
      getDetailedProgressReport(year, month, !showFirst6Months)
      setShowFirst6Months((state) => !state)
    } else {
      if (month === 0) {
        getDetailedProgressReport(year - 1, 11, showFirst6Months)
        setYear((state) => state - 1)
        setMonth(11)
      } else if (month !== null) {
        getDetailedProgressReport(year, month - 1, showFirst6Months)
        setMonth(month - 1)
      }
    }
  }

  const detailedReportHandler = () => {
    setSeeDetailedReport(true)
    getDetailedProgressReport(year, month, showFirst6Months)
  }

  const filterInTimeRange = (progress: any[], fromDateMs: number, toDateMs: number) => {
    const currDate = Date.now() / 1000

    return progress.filter((time: any) => time >= fromDateMs && time <= toDateMs && time <= currDate)
  }

  const setStudentProgress = (fromDateMs: number, toDateMs: number) => {
    const studentProgress: any = {}
    const currDate = Date.now() / 1000

    studentProgress.classes = {}
    studentProgress.classes.present = filterInTimeRange(
      allProgress.classes.classSessionsAttendedDataTimestamps,
      fromDateMs,
      toDateMs
    ).length
    studentProgress.classes.absent =
      filterInTimeRange(allProgress.classes.allClassSessionsDataTimestamps, fromDateMs, toDateMs).length -
      filterInTimeRange(allProgress.classes.classSessionsAttendedDataTimestamps, fromDateMs, toDateMs).length

    studentProgress.mentorSessions = {}
    studentProgress.mentorSessions.completed = filterInTimeRange(
      allProgress.mentorSessions.completed,
      fromDateMs,
      toDateMs
    ).length
    studentProgress.mentorSessions.mentorAbsent = filterInTimeRange(
      allProgress.mentorSessions.mentorAbsent,
      fromDateMs,
      toDateMs
    ).length
    studentProgress.mentorSessions.menteeAbsent = filterInTimeRange(
      allProgress.mentorSessions.menteeAbsent,
      fromDateMs,
      toDateMs
    ).length
    studentProgress.mentorSessions.cancelledByMentor = filterInTimeRange(
      allProgress.mentorSessions.cancelledByMentor,
      fromDateMs,
      toDateMs
    ).length
    studentProgress.mentorSessions.cancelledByMentee = filterInTimeRange(
      allProgress.mentorSessions.cancelledByMentee,
      fromDateMs,
      toDateMs
    ).length

    studentProgress.attempted = (() => {
      let minIndex = 10e9,
        maxIndex = -1
      allProgress.problemsSolved.forEach((doc: any, index: number) => {
        if (doc.time >= fromDateMs && doc.time <= toDateMs && doc.time <= currDate) {
          if (index > maxIndex) {
            maxIndex = index
          }
          if (index < minIndex) {
            minIndex = index
          }
        }
      })
      return (
        allProgress.problemsSolved[maxIndex]?.solved -
          (allProgress.problemsSolved[minIndex - 1]?.solved || allProgress.problemsSolved[0]?.solved || 0) || 0
      )
    })()
    studentProgress.exams = {}
    studentProgress.exams.present = allProgress.exams.allExamsAttended.filter(
      (doc: any) => doc.time >= fromDateMs && doc.time <= toDateMs && doc.time <= currDate
    ).length
    studentProgress.exams.absent =
      filterInTimeRange(allProgress.exams.allExamsDataTimestamps, fromDateMs, toDateMs).length -
      allProgress.exams.allExamsAttended.filter(
        (doc: any) => doc.time >= fromDateMs && doc.time <= toDateMs && doc.time <= currDate
      ).length
    studentProgress.exams.pass = allProgress.exams.allExamsAttended.filter(
      (doc: any) =>
        doc.passingMarks &&
        doc.obtainedScore >= doc.passingMarks &&
        doc.time >= fromDateMs &&
        doc.time <= toDateMs &&
        doc.time <= currDate
    ).length
    studentProgress.exams.fail = allProgress.exams.allExamsAttended.filter(
      (doc: any) =>
        doc.passingMarks &&
        doc.obtainedScore < doc.passingMarks &&
        doc.time >= fromDateMs &&
        doc.time <= toDateMs &&
        doc.time <= currDate
    ).length
    studentProgress.exams.averageScore =
      studentProgress.exams.present === 0
        ? 0
        : allProgress.exams.allExamsAttended
            .filter((doc: any) => doc.time >= fromDateMs && doc.time <= toDateMs && doc.time <= currDate)
            .reduce((total: number, doc: any) => total + doc.obtainedScore, 0) / studentProgress.exams.present
    studentProgress.exams.averageTotalScore =
      studentProgress.exams.present === 0
        ? 0
        : allProgress.exams.allExamsAttended
            .filter((doc: any) => doc.time >= fromDateMs && doc.time <= toDateMs)
            .reduce((total: number, doc: any) => total + doc.maxScore, 0) / studentProgress.exams.present

    return studentProgress
  }

  const getDetailedProgressReport = (year: number, month: number | null = null, showFirst6Months: boolean) => {
    let studentProgress: any[] = []
    if (month !== null) {
      studentProgress = getWeeksOfMonth(year, month)
    } else {
      studentProgress = getMonthsOfYear(year, showFirst6Months)
    }
    studentProgress.forEach((doc) => {
      doc.progress = setStudentProgress(doc.firstDay, doc.lastDay)
    })
    setDetailedProgress(studentProgress)
  }

  const getWeeksOfMonth = (year: number, month: number) => {
    const firstDayOfMonth = new Date(year, month, 1)
    const lastDayOfMonth = new Date(year, month + 1, 0)

    const weeks = []
    let currentWeekStart = firstDayOfMonth
    let weekNumber = 1

    while (currentWeekStart <= lastDayOfMonth) {
      let currentWeekEnd = new Date(currentWeekStart)
      currentWeekEnd.setDate(currentWeekStart.getDate() + 6)

      if (currentWeekEnd > lastDayOfMonth) {
        currentWeekEnd = lastDayOfMonth
      }

      weeks.push({
        name: `Week${weekNumber}`,
        firstDay: currentWeekStart.getTime() / 1000,
        lastDay: currentWeekEnd.getTime() / 1000 + (60 * 60 * 24 - 1),
      })

      currentWeekStart = new Date(currentWeekEnd)
      currentWeekStart.setDate(currentWeekStart.getDate() + 1)
      weekNumber++
    }

    return weeks
  }

  const getMonthsOfYear = (year: number, showFirst6Months: boolean) => {
    const months = []
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    for (let month = 1; month <= 12; month++) {
      const firstDayOfMonth = new Date(year, month - 1, 1)
      const lastDayOfMonth = new Date(year, month, 0)

      months.push({
        name: monthNames[month - 1],
        firstDay: firstDayOfMonth.getTime() / 1000,
        lastDay: lastDayOfMonth.getTime() / 1000 + (60 * 60 * 24 - 1),
      })
    }
    return showFirst6Months ? months.slice(0, 6) : months.slice(6, 12)
  }

  return {
    briefProgressHandler,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    fromDateMs,
    toDateMs,
    progress,
    seeDetailedReport,
    setSeeDetailedReport,
    showFirst6Months,
    setShowFirst6Months,
    detailedReportHandler,
    activeButton,
    setActiveButton,
    graphYAxisString,
    setGraphYAxisString,
    isWeekly,
    setIsWeekly,
    year,
    setYear,
    month,
    setMonth,
    getDetailedProgressReport,
    detailedProgress,
    increaseTimelineHandler,
    decreaseTimelineHandler,
  }
}
