import Input from "core/components/new/Input"
export default function FilterSection({
  filters,
  setFilters,
  selectBatch,
  programs,
}: {
  filters: any
  setFilters: (value: any) => void
  selectBatch: string[]
  programs: string[]
}) {
  const handelFiltersChange = (value: string | number, key: string) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [key]: value,
    }))
  }
  return (
    <div className="flex gap-4">
      <Input
        id="search-email"
        type="email"
        placeholder="Search with email"
        value={filters.queryEmail}
        onChange={(e) => handelFiltersChange(e.target.value, "queryEmail")}
        containerClass="w-60"
        search
      />
      <Input
        id="search-name"
        type="text"
        placeholder="Search with Skills"
        value={filters.querySkills}
        onChange={(e) => handelFiltersChange(e.target.value, "querySkills")}
        containerClass="w-60"
        search
      />
      <div className="flex items-center justify-center">
        <select
          className="w-[130px] rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
          value={filters.queryBatch || "Select Batch"}
          onChange={(e) => handelFiltersChange(e.target.value, "queryBatch")}
        >
          <option value="Select Batch" disabled>
            Select Batch
          </option>
          {selectBatch.map((option: any, index: number) => (
            <option key={index} value={option} disabled={option === "Select Batch"}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="flex items-center justify-center">
        <select
          className="w-[150px] rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
          value={filters.queryProgram || "Select Program"}
          onChange={(e) => handelFiltersChange(e.target.value, "queryProgram")}
        >
          <option value="Select Program" disabled>
            Select Program
          </option>
          {programs.map((option: any, index: number) => (
            <option key={index} value={option} disabled={option === "Select Program"}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <Input
        id="yoe"
        type="number"
        placeholder="YOE"
        value={filters.queryYoe}
        onChange={(e) => handelFiltersChange(e.target.value, "queryYoe")}
        containerClass="w-20"
      />
      <Input
        id="rating"
        type="number"
        placeholder="Rating"
        value={filters.queryRating}
        onChange={(e) => handelFiltersChange(e.target.value, "queryRating")}
        containerClass="w-32"
      />
    </div>
  )
}
