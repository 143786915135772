import { AuthRepository } from "domain/repository/Admin/AuthRepository"

export interface forgotPasswordUseCase {
  invoke(email: string): Promise<any>
}

export class ForgotPassword implements forgotPasswordUseCase {
  private repository: AuthRepository

  constructor(repository: AuthRepository) {
    this.repository = repository
  }

  async invoke(email: string) {
    return this.repository.forgotPassword(email)
  }
}
