import React, { useEffect, useState, useRef } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import CommunityAnnouncementsViewModel from "./CommunityAnnouncementViewModel"
import Toast from "core/components/Toast"
import Spinner from "core/components/Spinner"
import { STR_FAILURE } from "core/constants/strings"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import bosscoder from "assets/images/sign.png"

export default function CommunityAnnouncements() {
  const {
    getBatches,
    batch,
    handleInputChange,
    emailData,
    sendEmailToAll,
    setEmailData,
    toast,
    changeToastDetails,
    error,
    content,
    setContent,
    sending,
    sendEmailToProgramme,
    sendEmailToBatch,
    sendEmailToGroupOfStudents,
    changeToastVisibility,
    messageData,
    handleCheckboxChangeForDisabled,
    isCheckedForDisabled,
    handleCheckboxChangeFoBatch,
    isCheckedForBatch,
    setAttachments,
    attachments,
    password,
    handleFileChange,
    isLogoVisible,
    setLogoVisible,
    setImageFile,
    fromAdd,
    setPassword,
    setFromAdd,
    sign,
  } = CommunityAnnouncementsViewModel()

  useEffect(() => {
    getBatches()
  }, [])
  const [isPopupVisible, setPopupVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState("")
  const [askAddress, setAskAddress] = useState(false)
  const [isPreviewVisible, setPreviewVisible] = useState(false)
  const [isMobileView, setIsMobileView] = useState(false)
  const togglePopup = () => {
    setPopupVisible(!isPopupVisible)
    setEmailData({
      ...emailData,
      emailBody: `Hi {{Student Name}} \n ${emailData.emailBody}`,
    })
  }
  const removeLogo = () => {
    setLogoVisible(false)
  }
  const handlePreview = () => {
    setPreviewVisible(true)
  }

  const handleOptionSelection = (option: string) => {
    setSelectedOption(option)
    togglePopup()
  }

  const handleSubmittoAll = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToAll(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
    setContent("")
  }
  const inputRef = useRef<HTMLDivElement | null>(null)
  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    const clipboardData = e.clipboardData
    const pastedData = clipboardData.getData("text/html")
    if (pastedData) {
      document.execCommand("insertHTML", false, pastedData)
    }
  }
  const handleInput = () => {
    if (inputRef.current) {
      setContent(inputRef.current.innerHTML)
    }
  }
  const handleSubmitToBatch = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToBatch(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
    setContent("")
  }
  const handleSubmitToGroupOfStudents = () => {
    if (emailData.emailAddresses.trim() == "") {
      changeToastDetails(STR_FAILURE, "Enter the Email Addresses")
      changeToastVisibility(true)
      return
    }

    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToGroupOfStudents(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
    setContent("")
  }
  const handleSubmittoProgramme = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToProgramme(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
    setContent("")
  }
  function handleCancel() {
    setSelectedOption("")
    setEmailData({
      ...emailData,
      emailBody: "",
    })
    setPopupVisible(false)
  }
  function handleAddressChange() {
    if (fromAdd.trim() === "") {
      changeToastDetails(STR_FAILURE, "Enter Address")
      return
    }
    if (password.trim() === "") {
      changeToastDetails(STR_FAILURE, "Enter Password")
      return
    }

    setPopupVisible(true)
    setAskAddress(false)
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  }
  return (
    <DashboardLayout>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="p-6">
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h3 className="text-lg font-semibold mb-4">Send Email to Students</h3>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setAskAddress(true)}
          >
            Compose Your Email
          </button>
        </div>

        {askAddress && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md mx-4 sm:mx-auto">
              <h2 className="text-xl font-semibold mb-4 text-center">Enter Your Details</h2>

              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  From Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Enter your email"
                  onChange={(e) => setFromAdd(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <label htmlFor="pas" className="block text-gray-700 mb-2">
                  Password
                </label>
                <input
                  type="password"
                  id="pas"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="flex justify-between items-center">
                <button
                  onClick={() => setAskAddress(false)}
                  className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg hover:bg-gray-400 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddressChange}
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {isPopupVisible && !selectedOption && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/3">
              <h3 className="text-xl font-semibold mb-4">Send Email to:</h3>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("All Students")}
              >
                All Students
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("Particular Programme")}
              >
                Particular Programme
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("Particular Batch")}
              >
                Particular Batch
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("Group of Students")}
              >
                Group of Students
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 w-full rounded hover:bg-gray-400"
                onClick={() => {
                  setPopupVisible(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {selectedOption && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center  ">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/2 my-[16px] h-[650px] overflow-y-auto">
              <h3 className="text-xl font-semibold mb-4">Compose Your Email - {selectedOption}</h3>

              <label className="block mb-2 font-medium">Subject</label>
              <input
                type="text"
                name="subject"
                value={emailData.subject}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                placeholder="Enter the subject"
              />

              <label className="block mb-2 font-medium">Email Body</label>
              <ReactQuill value={emailData.emailBody} onChange={handleInputChange} modules={modules} theme="snow" />
              <label>Any Table or Styled Data</label>
              <div
                ref={inputRef}
                contentEditable
                onPaste={handlePaste}
                onInput={handleInput}
                className="w-full p-2 mb-4 border border-gray-300 rounded overflow-scroll"
              />
              {selectedOption === "Particular Programme" && (
                <div>
                  <label htmlFor="file">Attachment</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  <label className="block mb-2 font-medium">Select Programme</label>
                  <select
                    name="program"
                    value={emailData.program}
                    onChange={handleInputChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                  >
                    <option value="">Select a Programme</option>
                    <option value="Accelerator">Accelerator</option>
                    <option value="Transformer">Transformer</option>
                    <option value="Data Engineering">Data Engineering</option>
                    <option value="Evolve">Evolve</option>
                    <option value="Data Analytics">Data Analytics</option>
                    <option value="Data Science">Data Science</option>
                  </select>
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <label htmlFor="except disabled">Include Disabled</label>
                  <input
                    type="checkbox"
                    id="include disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForDisabled}
                    onChange={handleCheckboxChangeForDisabled}
                  />{" "}
                  <br />
                  <label htmlFor="except disabled">Include Course Paused</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForBatch}
                    onChange={handleCheckboxChangeFoBatch}
                  />
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handlePreview}
                    >
                      Preview Email
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmittoProgramme}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {selectedOption === "All Students" && (
                <div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <label htmlFor="except disabled">Include Disabled</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForDisabled}
                    onChange={handleCheckboxChangeForDisabled}
                  />{" "}
                  <br />
                  <label htmlFor="except disabled">Include Course Paused</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForBatch}
                    onChange={handleCheckboxChangeFoBatch}
                  />
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handlePreview}
                    >
                      Preview Email
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmittoAll}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {selectedOption === "Particular Batch" && (
                <div>
                  <label htmlFor="file">Attachment</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  <label className="block mb-2 font-medium">Select Batch</label>
                  <select
                    name="batch"
                    value={emailData.batch}
                    onChange={handleInputChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                  >
                    <option value="">Select a Batch</option>
                    {batch?.map((batchOption: any, index: number) => (
                      <option value={batchOption} key={index}>
                        {batchOption}
                      </option>
                    ))}
                  </select>
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <label htmlFor="except disabled">Include Disabled</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForDisabled}
                    onChange={handleCheckboxChangeForDisabled}
                  />{" "}
                  <br />
                  <label htmlFor="except disabled">Include Course Paused</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForBatch}
                    onChange={handleCheckboxChangeFoBatch}
                  />
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handlePreview}
                    >
                      Preview Email
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmitToBatch}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {selectedOption === "Group of Students" && (
                <div>
                  <label className="block mb-2 font-medium">Email Addresses</label>
                  <input
                    type="email"
                    name="emailAddresses"
                    onChange={handleInputChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    placeholder="Enter email addresses"
                  />
                  {error && <p className="text-red-500">{error}</p>} {/* Show error message */}
                  <label htmlFor="file">Attachement</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handlePreview}
                    >
                      Preview Email
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmitToGroupOfStudents}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {isPreviewVisible && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-60 flex justify-center items-center z-50">
            <div
              className={`bg-white p-8 rounded-lg shadow-lg max-h-[650px] overflow-y-scroll ${
                isMobileView ? "w-1/3 h-3/4" : "w-[90vw] h-[100vh]"
              }`}
              style={{
                transform: isMobileView ? "scale(1)" : "scale(1)",
                transformOrigin: "top center",
              }}
            >
              <div className="flex justify-between mb-4">
                <h3 className="text-xl font-semibold">Email Preview</h3>
                <div className="space-x-2">
                  <button
                    onClick={() => setIsMobileView(false)}
                    className={`px-4 py-2 rounded ${
                      !isMobileView ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
                    } hover:bg-blue-600`}
                  >
                    Desktop View
                  </button>
                  <button
                    onClick={() => setIsMobileView(true)}
                    className={`px-4 py-2 rounded ${
                      isMobileView ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
                    } hover:bg-blue-600`}
                  >
                    Mobile View
                  </button>
                </div>
              </div>
              <div className={`border p-4 mb-4 ${isMobileView ? "" : "overflow-x-auto"}`}>
                <h4 className="font-bold">Subject:</h4>
                <p>{emailData.subject}</p>

                <h4 className="font-bold mt-4">Email Body:</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${
                      emailData.emailBody.includes("Hi {{Student Name}}") ? "Hi {{Student Name}}" : ""
                    }, \n\n${emailData.emailBody.replace(
                      "Hi {{Student Name}}",
                      ""
                    )}\n <span class="dynamic-content">${content}</span> \n\n 
    <img src=${bosscoder} alt="Logo" style="width: 180px; height: 60px;">`,
                  }}
                  className={`${isMobileView ? "scale(1)" : ""}`}
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => setPreviewVisible(false)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}
