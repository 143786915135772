import React from "react"
import { Auth } from "domain/model/Auth"
import GetAllClassesAPIDataSourceImpl from "data/API/Admin/GetAllClassesAPIDataSourceImpl"
import { GetAllClassesRepositoryImpl } from "data/repository/Admin/GetAllClassesRepositoryImpl"
import GetSubmission from "domain/useCase/Admin/AllClasses/GetSubmission"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import CreateFeedbackResponse from "domain/useCase/Admin/AllClasses/CreateFeedbackResponse"
import GetFeedbackResponseSubmission from "domain/useCase/Admin/AllClasses/GetFeedbackResponseSubmission"
import DeleteFeedbackFile from "domain/useCase/Admin/AllClasses/DeleteFeedbackFile"
import useToast from "core/hooks/useToast"
import { useNavigate } from "react-router-dom"
import { set } from "date-fns"

interface Field {
  type: string
  content: string
  file?: Blob
}

export default function SubmissionViewModel() {
  const TABS = ["Coding", "MCQs", "Subjective", "Case Study"]
  const BUTTON = ["Description", "Solution"]

  const [tab, setTab] = React.useState<number>(0)
  const [sizes, setSizes] = React.useState<(string | number)[]>(["50%", "80%"])
  const [horizontalSizes, setHorizontalSizes] = React.useState<(string | number)[]>(["80%", "50%"])
  const [activeTab, setActiveTab] = React.useState(0)
  const [activeTabContent, setActiveTabContent] = React.useState(0)
  const [allQuestions, setAllQuestions] = React.useState<any>({})
  const [selectedQuestion, setSelectedQuestion] = React.useState<any>(0) // question selected in particular section
  const [selectedQuestionId, setSelectedQuestionId] = React.useState<any>("")
  const [questionSize, setQuestionSize] = React.useState<number>(0) // change when section changes and provide length of section
  const [openSubmissionTab, setOpenSubmissionTab] = React.useState<boolean>(false)
  const [feedbackResponses, setFeedbackResponses] = React.useState<any>({})
  const [questionFields, setQuestionFields] = React.useState<{
    [type: string]: { [key: string]: Field[] }
  }>({})
  const [questionName, setQuestionName] = React.useState<string>("")
  const [openViewQuestion, setOpenViewQuestion] = React.useState<boolean>(false)
  const [addQuestion, setAddQuestion] = React.useState<boolean>(false)

  const [fileResponse, setFileResponse] = React.useState<any>()

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const navigate = useNavigate()

  const GetSubmissionUseCase = new GetSubmission(new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl()))

  const CreateFeedbackResponseUseCase = new CreateFeedbackResponse(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )
  const GetFeedbackResponseSubmissionUseCase = new GetFeedbackResponseSubmission(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const DeleteFeedbackFileUseCase = new DeleteFeedbackFile(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  async function fetchFeedbackResponseSubmission(session_id: string, student_email: string) {
    const response = await GetFeedbackResponseSubmissionUseCase.invoke(auth, session_id, student_email)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.message)
      changeToastVisibility(true)
      return
    }
    setFeedbackResponses(response?.data?.feedback)
    setFileResponse(response?.data?.files)
  }

  async function fetchSubmissionDetails(session_id: string, student_email: string) {
    const response = await GetSubmissionUseCase.invoke(auth, session_id, student_email)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.message)
      changeToastVisibility(true)
      return
    }
    setAllQuestions(response?.data?.question_list)
    setQuestionSize(response?.data?.question_list?.[TABS[0]]?.length)
  }
  const handleQuestionFields: Function = (op: string, question_type: string, selectedQuestion: string, id?: number) => {
    if (op === "Add") {
      setQuestionFields((prevFields) => {
        const fields: any = prevFields[question_type] || {}
        const updatedQuestions = fields[selectedQuestion]
          ? {
              ...fields,
              [selectedQuestion]: [...(fields[selectedQuestion] || []), { type: "image", content: "" }],
            }
          : {
              ...fields,
              [selectedQuestion]: [{ type: "image", content: "" }],
            }

        return {
          ...prevFields,
          [question_type]: updatedQuestions,
        }
      })
    } else {
      setQuestionFields((prevFields) => {
        const fields: any = prevFields[question_type] || {}
        const updatedQuestions = fields[selectedQuestion]
          ? {
              ...fields,
              [selectedQuestion]: fields[selectedQuestion].filter((_: any, index: number) => index !== id),
            }
          : fields

        return {
          ...prevFields,
          [question_type]: updatedQuestions,
        }
      })
    }
  }

  const getQuestionName = (activeTab: number) => {
    switch (activeTab) {
      case 0:
        return allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["problem_name"]
      case 1:
        return allQuestions[TABS[activeTab]]?.[selectedQuestion]?.["name"]
      case 2:
        return allQuestions[TABS[activeTab]]?.[selectedQuestion]?.questionTitle
      case 3:
        return allQuestions[TABS[activeTab]]?.[0]?.["response"]?.[selectedQuestion]?.subTitle
    }
  }

  const handleDeleteFile = async (
    questionName: string,
    fileurl: string,
    activeTab: number,
    type: string,
    session_id: string,
    student_email: string
  ) => {
    if (type === "image" || type === "document") {
      type = "file"
    }

    const response = await DeleteFeedbackFileUseCase.invoke(
      auth,
      session_id,
      student_email,
      fileurl,
      questionName,
      type
    )

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error || "An error occurred while deleting file")
      changeToastVisibility(true)
      return
    }
    setFileResponse((prevFiles: any) => {
      const newFiles = { ...prevFiles }

      Object.keys(newFiles).forEach((category) => {
        Object.keys(newFiles[category]).forEach((subcategory) => {
          newFiles[category][subcategory] = newFiles[category][subcategory].filter((file: any) => file.File !== fileurl)
        })
      })

      return newFiles
    })

    changeToastDetails(STR_SUCCESS, "File deleted successfully")
    changeToastVisibility(true)
  }

  const handleFeedbackChange = (
    questionName: any,
    tab: any,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFeedbackResponses((prevResponses: any) => ({
      ...prevResponses,
      [questionName]: {
        ...prevResponses[questionName],
        [name]: value,
        type: tab,
      },
    }))
  }

  const handleRatingChange = (questionName: any, rating: number) => {
    setFeedbackResponses((prevResponses: any) => ({
      ...prevResponses,
      [questionName]: {
        ...prevResponses[questionName],
        rating,
      },
    }))
  }

  const handleQuestionName = (name: string) => {
    if (TABS[activeTab] === "Case Study") {
      name.toLowerCase()
      setQuestionName(name)
    } else {
      setQuestionName(name)
    }
  }

  const handleOpenSubmissionTab = () => {
    setOpenSubmissionTab((prev) => !prev)
  }

  const handleTabChange = (tab: number) => {
    setTab(tab)
    setActiveTab(tab)
    setSelectedQuestion(0)
    if (tab === 0 || tab === 1 || tab === 2) {
      setQuestionSize(allQuestions[TABS[tab]].length)
    } else if (tab === 3) {
      setSelectedQuestionId(allQuestions[TABS[tab]]?.[0]?.["response"][0]?.id)
      setQuestionSize(allQuestions[TABS[tab]]?.[0]?.["response"].length)
    }
  }

  const handlePreviousBtnClick = () => {
    if (selectedQuestion > 0) {
      setSelectedQuestion(selectedQuestion - 1)
    }
  }

  const handleNextBtnClick = () => {
    if (selectedQuestion < questionSize - 1) {
      setSelectedQuestion(selectedQuestion + 1)
    }
  }

  const handleSizeChange = (newSizes: number[]) => {
    setSizes(newSizes)
  }

  const handleTabContentChange = (tabContent: number) => {
    setActiveTabContent(tabContent)
  }

  const handleHorizontalSizeChange = (newSizes: number[]) => {
    setHorizontalSizes(newSizes)
  }

  const handleSelectedQuestion = (index: number, id: string) => {
    setSelectedQuestion(index)
    setSelectedQuestionId(id)
  }

  const handleQuestionFieldChange: Function = (
    e: any,
    question_type: string,
    selectedQuestion: string,
    id: number,
    name: string
  ) => {
    setQuestionFields((prevFields) => {
      const fields: any = { ...prevFields[question_type] }

      if (name === "type") {
        fields[selectedQuestion] = fields[selectedQuestion].map((question: any, index: number) =>
          index === id
            ? {
                ...question,
                type: e.target.value,
                content: "",
              }
            : question
        )
      } else if (name === "content") {
        fields[selectedQuestion] = fields[selectedQuestion].map((question: any, index: number) =>
          index === id
            ? {
                ...question,
                content: e.target.value,
              }
            : question
        )
      } else if (name === "image" || name === "document") {
        const file = e.target.files[0]
        const filename = file.name

        let allowedTypes: string[] = []
        let fileTypeError: string = ""

        if (name === "image") {
          allowedTypes = ["image/png", "image/jpg", "image/jpeg"]
          fileTypeError = "Only .png, .jpg and .jpeg are allowed"
        } else if (name === "document") {
          allowedTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]
          fileTypeError = "Only .doc, .xls, .xlsx and .pdf are allowed"
        }

        if (!allowedTypes.includes(file.type)) {
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, fileTypeError)
          return prevFields // Return previous fields if file type not allowed
        }

        fields[selectedQuestion] = fields[selectedQuestion].map((question: any, index: number) =>
          index === id
            ? {
                ...question,
                content: filename,
                file,
              }
            : question
        )
      }

      return {
        ...prevFields,
        [question_type]: fields,
      }
    })
  }

  const handleAddQuestion = () => {
    setAddQuestion(true)
  }
  const handleOpenViewQuestion = () => {
    setOpenViewQuestion((prev) => !prev)
  }

  // ===================================

  const processFormData = () => {
    const formData = new FormData()
    Object.keys(questionFields).forEach((questionType) => {
      Object.keys(questionFields[questionType]).forEach((questionName) => {
        const fields = questionFields[questionType][questionName]
        fields.forEach((field, index) => {
          let question_type = questionType === "Case Study" ? "casestudy" : questionType.toLowerCase()
          let filename = `${question_type}_${questionName}_${index}_${field.type}`

          if (field.type === "code") {
            formData.append(filename, field.content)
          } else if (field.file) {
            formData.append(filename, field.file)
          }
        })
      })
    })

    return formData
  }

  const handleSaveChanges = async (session_id: string, student_email: string) => {
    try {
      const formData = processFormData()
      const response = await CreateFeedbackResponseUseCase.invoke(
        auth,
        session_id,
        student_email,
        formData,
        feedbackResponses
      )

      if (!response?.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, response?.error || "Unknown error occurred")
        return
      }

      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, response?.data || "Data saved successfully")
      // navigate(-1);
    } catch (error) {
      // console.error("Error while saving data:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "An error occurred while saving data")
    }
  }

  const handleDiscardChanges = () => {
    setQuestionFields({})
    setFeedbackResponses({})
    navigate(-1)
    // navigate(`/admin/all-classes/${session_id}/submission`);
  }

  return {
    TABS,
    BUTTON,
    tab,
    handleTabChange,
    handleSizeChange,
    sizes,
    toast,
    changeToastVisibility,
    activeTab,
    activeTabContent,
    handleTabContentChange,
    horizontalSizes,
    handleHorizontalSizeChange,
    fetchSubmissionDetails,
    allQuestions,
    selectedQuestion,
    handleSelectedQuestion,
    handleNextBtnClick,
    handlePreviousBtnClick,
    questionSize,
    handleOpenSubmissionTab,
    openSubmissionTab,
    feedbackResponses,
    handleFeedbackChange,
    handleQuestionName,
    setQuestionName,
    questionName,
    questionFields,
    handleQuestionFields,
    handleQuestionFieldChange,
    addQuestion,
    handleAddQuestion,
    openViewQuestion,
    handleOpenViewQuestion,
    handleRatingChange,
    handleSaveChanges,
    handleDiscardChanges,
    fetchFeedbackResponseSubmission,
    fileResponse,
    handleDeleteFile,
    selectedQuestionId,
  }
}
