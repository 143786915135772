import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface ReedemCoinUseCase {
  invoke(auth: Auth, data: number): Promise<any>
}

export class ReedemCoin implements ReedemCoinUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return this.repository.reedemCoin(auth, data)
  }
}
