import Button from "core/components/new/Button"
import { CheckBlankIcon, SuccessCheckboxIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react"
import IsStudentAttended from "domain/useCase/Student/Assessment/IsStudentAttendedContest"
import SubmitContestApproval from "domain/useCase/User/Contest/SubmitContestApproval"
import ContestResult from "domain/useCase/Student/Assessment/StudentContestResult"
import ContestAPIDataSourceImpl from "data/API/Student/ContestAPIDataSourceImpl"
import ContestRepositoryImpl from "data/repository/Student/ContestRepositoryImpl"

import { ContestAPIDataSourceImpl as UserContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { ContestRepositoryImpl as UserContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"

import { useAuth } from "core/context/auth"
import ResultModal from "./ResultModal"
import { GetProfileDetails } from "domain/useCase/Student/Profile/GetProfileDetails"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import Loader from "core/components/Loader"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import Toast from "core/components/Toast"
import CustomModel from "./CustomModel"
import useEditorViewModel from "presentation/User/Contest/Editor/EditorViewModel"
import GetAllContest from "domain/useCase/Student/Assessment/GetAllContest"
import { genError } from "core/utils/string"
import GetAllExams from "domain/useCase/Student/Assessment/GetAllExams"
import ExamResult from "domain/useCase/Student/Assessment/ExamResult"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function ContestTable({ data, buttonType, page }: any) {
  const { submittedDueToProctoring } = useEditorViewModel()
  const { auth } = useAuth()
  const [selectedContest, setSelectedContest] = useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [student, setStudent] = useState<any>()
  const [attendances, setAttendances] = useState<{
    [key: string]: boolean | null
  }>({})

  const navigate = useNavigate()
  const [result, setResult] = useState(null)
  const [reason, setReason] = useState("")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const handleCloseModal = () => {
    setSelectedContest(null)
    setResult(null)
  }
  const GetAllContestUseCase = new GetAllContest(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const GetAllExamsUseCase = new GetAllExams(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const limit = 20
  const [allContest, setAllContest] = useLocalStorage<any>("contest", {})

  // const IsAttendedUseCase = new IsStudentAttended(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const sendApprovalUseCase = new SubmitContestApproval(
    new UserContestRepositoryImpl(new UserContestAPIDataSourceImpl())
  )

  const ContestResultUseCase = new ContestResult(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const GetExamResultUseCase = new ExamResult(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getProfileDetailsUseCase = new GetProfileDetails(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))
  async function fetchUserDetails() {
    const response = await getProfileDetailsUseCase.invoke(auth)

    if (!response?.success) {
      navigate("/login")
      return
    }

    setStudent(response?.data)
  }

  useEffect(() => {
    if (true) {
      fetchUserDetails()
    }
  }, [])

  const handleResultClick = async (contestId: string) => {
    setSelectedContest(contestId)
    setLoading(true)
    await fetchResultData(contestId)
    setLoading(false)
  }
  const fetchAllAssessments = async (currentPage = 1) => {
    if (!allContest?.[page].isEmpty) {
      return allContest?.[page]
    }
    setLoading(true)
    const response = await GetAllExamsUseCase.invoke(auth, currentPage, limit)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }
    setAllContest(response?.data)
    return response?.data
  }
  const initializeAssessments = async () => {
    try {
      const assessments = await fetchAllAssessments()
      if (assessments?.upcoming || assessments?.recorded) {
        const updatedAttendances: any = {}
        const contests = [...assessments?.upcoming, ...assessments?.recorded]
        contests.forEach((contest) => {
          updatedAttendances[contest.contest_id] = contest?.attempted_live || false
          updatedAttendances[contest.contest_id + "viewResult"] = contest?.allow_result || false
          if (contest.hasOwnProperty("attempted_recorded")) {
            updatedAttendances[contest.contest_id + "attempted_recorded"] = contest.attempted_recorded
          }
          if (contest.hasOwnProperty("is_approved")) {
            updatedAttendances[contest.contest_id + "is_approved"] = contest.is_approved
          }
          if (contest.hasOwnProperty("approval_reason")) {
            updatedAttendances[contest.contest_id + "approval_reason"] = contest.approval_reason
          }
        })
        setAttendances(updatedAttendances)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }
  useEffect(() => {
    if (data) {
      initializeAssessments()
    }
  }, [data])

  async function fetchResultData(contestId: any) {
    try {
      // const resultData = await ContestResultUseCase.invoke(auth, contestId)
      const resultData = await GetExamResultUseCase.invoke(auth, contestId)
      if (!resultData?.success) {
        return
      }
      setResult(resultData.response)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const approveModalClose = () => setApproveModal(!approveModal)
  const [approveModal, setApproveModal] = useState(false)

  const checkExamEligibility = (contest_id: string) => {
    // this is upcomming contest dont show popup
    if (buttonType === 0) return navigate(`/contests/${contest_id}`)
    if (buttonType === 1) {
      setApproveModal(true)
    }
    // already attempted just reattempt
    // if (attendances[contest_id]) {
    // return navigate(`/contests/${contest_id}`)
    // }

    // if approved by admin then redirect
    // if (attendances.hasOwnProperty(contest_id + "is_approved") && attendances[contest_id + "is_approved"]) {
    //   return navigate(`/contests/${contest_id}`)
    // }

    // Not approved yet
    const approval_reason = attendances[contest_id + "approval_reason"]
      ? String(attendances[contest_id + "approval_reason"])
      : ""
    setReason(approval_reason)
    setCurrentContestId(contest_id)
  }
  const [currentContestId, setCurrentContestId] = useState("")
  const sendApproval = async (contest_id: any) => {
    return navigate(`/contests/${contest_id}`)
    //already sended
    // if (
    //   attendances.hasOwnProperty(currentContestId + "is_approved") &&
    //   !attendances[currentContestId + "is_approved"]
    // ) {
    //   return
    // }

    // if (!reason) {
    //   changeToastDetails(STR_FAILURE, "Reason is required")
    //   changeToastVisibility(true)
    //   return
    // }

    //send request to backend
    // try {
    //   setLoading(true)
    //   const resultData = await sendApprovalUseCase.invoke(auth, {
    //     contest_id: currentContestId,
    //     reason,
    //   })
    //   if (resultData?.data) {
    //     setAttendances((prevAttendances) => ({
    //       ...prevAttendances,
    //       ...(resultData.data.hasOwnProperty("is_approved") && {
    //         [resultData.data.contest_id + "is_approved"]: resultData?.data?.is_approved,
    //       }),
    //     }))
    //     changeToastDetails(STR_SUCCESS, "Request sent! Please wait for Admin approval.")
    //     changeToastVisibility(true)
    //     setReason("")
    //   }
    // } catch (error) {
    //   changeToastDetails(STR_FAILURE, "Something went wrong")
    //   changeToastVisibility(true)
    // }
    // setLoading(false)
    // setApproveModal(false)
  }

  return (
    <>
      <div className="relative my-4 overflow-x-auto shadow-md">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="h-[40px] bg-new-neutral-dark text-[16px] text-white">
            <tr>
              {data?.header?.map((title: any, index: any) => (
                <th
                  key={index}
                  className={cn(
                    "px-4 py-2 text-[16px] font-medium",
                    index <= 1
                      ? "text-left"
                      : index === data?.header?.length - 1
                        ? "pl-2 pr-6 text-right"
                        : "text-center"
                  )}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <CustomModel heading={"Important Notice"} isOpen={approveModal} onClose={approveModalClose}>
              <div className="text-lg text-slate-600">
                <p className=" my-2">
                  This exam is now being attempted as a practice assessment. This attempt will not be counted in case of
                  Milestone Exam.
                </p>
              </div>

              <div className="flex gap-2 justify-end mt-4">
                <Tooltip
                  className="d-block"
                  title={
                    !attendances.hasOwnProperty(currentContestId + "is_approved")
                      ? ""
                      : buttonType == 1
                        ? "A request to attempt the exam has been sent. Please wait for approval."
                        : ""
                  }
                >
                  <span>
                    <Button
                      className="m-auto w-[100%]"
                      onClick={() => {
                        sendApproval(currentContestId)
                      }}
                      loading={loading}
                      disabled={attendances.hasOwnProperty(currentContestId + "is_approved") || loading}
                    >
                      Got it!
                    </Button>
                  </span>
                </Tooltip>
                <Button onClick={() => setApproveModal(false)} className="w-1/5 bg-red-500 hover:bg-red-700">
                  Cancel
                </Button>
              </div>
            </CustomModel>
            {!isEmpty(data?.rows) ? (
              data?.rows?.map((row: any) => {
                if (row?.isICPContest === true && student?.isICP === false) return null

                const ts = parseInt(row?.start_time) * 1000
                const isLinkDisabled = ts - Date.now() > 30 * 60 * 1000

                return (
                  <tr key={row?.contest_id} className="bg-new-solid-white even:bg-[#F5F5F5]">
                    <td className="px-4 py-5 text-left">
                      <span>
                        {attendances[row?.contest_id] === true ? (
                          <SuccessCheckboxIcon className="h-4 w-4 text-[#22C55E]" />
                        ) : (
                          <CheckBlankIcon className="h-4 w-4" />
                        )}
                      </span>
                    </td>
                    <td className="px-4 py-5 font-medium text-new-neutral w-[25%]">{row?.name}</td>
                    <td className="px-4 py-5 text-center font-medium text-new-neutral w-[15%]">
                      {format(ts, "dd MMMM yyyy")}
                    </td>
                    <td className="px-4 py-5 text-center text-[14px] font-medium text-new-neutral w-[15%]">
                      {format(new Date(ts), "h:mm a")}
                    </td>
                    <td className="d-block w-[15%]">
                      <Tooltip
                        title={
                          row?.["submitted_due_to_proctoring"]
                            ? "Exam submitted automatically due to tab switching"
                            : row?.submitted === true && buttonType === 0
                              ? "Response Already Submitted"
                              : isLinkDisabled
                                ? "Contest has not started yet"
                                : "Attempt now"
                        }
                      >
                        <span>
                          <Button
                            small
                            className="m-auto w-[80%]"
                            disabled={
                              isLinkDisabled ||
                              (row?.start_time < row?.last_submission_timestamp && buttonType === 0) ||
                              loading
                            }
                            onClick={() => checkExamEligibility(row?.contest_id)}
                          >
                            {buttonType === 0 && row?.submitted === true
                              ? "Reattempt"
                              : buttonType === 0 && !row?.submitted
                                ? "Start Exam"
                                : "Practice"}
                            {/* {buttonType === 0 && row?.submitted === true
                              ? "Reattempt"
                              : buttonType === 0 && !row?.submitted
                                ? "Start Exam"
                                : row?.attempted_recorded
                                  ? "Reattempt"
                                  : !attendances[row?.contest_id]
                                    ? "Start Exam"
                                    : "Reattempt"} */}
                          </Button>
                        </span>
                      </Tooltip>
                    </td>
                    <td className="w-[15%] d-block">
                      <Tooltip
                        className="d-block"
                        title={
                          attendances[row?.contest_id + "viewResult"]
                            ? ""
                            : buttonType == 1
                              ? "You have not participated in this exam at the scheduled time"
                              : "This link will be activated after the exam"
                        }
                      >
                        <span>
                          <Button
                            disabled={
                              (buttonType === 0 && row?.submitted !== true) ||
                              !attendances[row?.contest_id + "viewResult"]
                            }
                            className="m-auto w-[80%] h-4 cursor-pointer"
                            onClick={() => handleResultClick(row?.contest_id)}
                          >
                            {selectedContest === row?.contest_id && loading ? (
                              <Loader xs={true} height={"100%"} />
                            ) : (
                              "Result"
                            )}
                          </Button>
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={data?.header?.length} className="py-2 text-center font-medium leading-6 text-gray-500">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center">
        <ResultModal result={result} onClose={handleCloseModal} />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </>
  )
}
