import { Auth } from "domain/model/Auth"
import { CompensationRepository } from "domain/repository/Mentor/CompensationRepository"

export interface GetMentorCompensationDetailsUseCase {
  invoke(auth: Auth, date: string, start: string, end: string): Promise<any>
}

export default class GetMentorCompensationDetails implements GetMentorCompensationDetailsUseCase {
  private repository: CompensationRepository

  constructor(repository: CompensationRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getMentorCompensationDetails(auth)
  }
}
