export default function DashboardViewModel() {
  const tableHead = [
    "General Information",
    "Placement Batch",
    "Mock Status",
    "LinkedIn Status",
    "Resume",
    "Resume Status",
    "Working or Fresher",
    "Skills",
    "Review",
    "YOE",
    "BCA Rating",
    "Notice Period",
    "Placed",
    "Edit Profile",
    "More options",
  ]

  const programs = ["Transformer", "Evolve", "Data Engineering", "Accelerator", "Data Science", "Data Analytics"]

  const statusOptions = {
    mockStatus: ["Select Status", "Cleared", "Not cleared"],
    linkedinStatus: ["Select Status", "Approved", "Not Approved", "Not Uploaded"],
    resumeStatus: ["Select Status", "Approved", "Not Approved", "Not Uploaded"],
  }

  return {
    tableHead,
    statusOptions,
    programs,
    // rows,
  }
}
