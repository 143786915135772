import UploadIcon from "assets/svgs/placement/Upload"
import Toggle from "core/components/Toggle"
import { LinkedInIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { cn } from "core/lib/utils"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { EditPlacementStatus } from "domain/useCase/Admin/Placement/EditPlacementStatus"
import usePlacementListViewModel from "presentation/Admin/PlacementDashboard/components/PlacementListViewModel"
import { useState } from "react"
import UpdateLinkedInCard from "./popUpCards/UpdateLinkedInCard"

export default function PlacementInfoSection({ studentDetails, setStudentDetails }: any) {
  const { auth } = useAuth()
  const [linkedInCardPopup, setLinkedInCardPopup] = useState<boolean>(false)
  const EditPlacementStatusUsecase = new EditPlacementStatus(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )
  const { handleUploadResume } = usePlacementListViewModel()

  const handleLog = () => {
    console.log("PlacementInfoSection.tsx:", studentDetails)
  }

  async function handleToggle(checked: boolean) {
    try {
      const newStatus = checked ? "Approved" : "Not Approved"

      const response = await EditPlacementStatusUsecase.invoke(
        auth,
        studentDetails?.email,
        "linkedin_status",
        newStatus
      )

      if (response?.success) {
        setStudentDetails((prevDetails: any) => ({
          ...prevDetails,
          linkedin_status: newStatus,
        }))
      } else {
        console.error("Failed to update LinkedIn status:", response?.message || "Unknown error")
        alert(`Failed to update LinkedIn status. Please try again.`)
      }
    } catch (error) {
      console.error("Error toggling LinkedIn status:", error)
      alert(`Error toggling LinkedIn status.`)
    }
  }

  return (
    <div className="rounded-[2px] w-full border-[0.4px] p-6 flex justify-between items-center">
      <div className="w-full">
        <p className="text-[#312E81] font-semibold">Placement Info</p>
        <hr className="w-full border-t-2 border-dashed border-gray-300 my-4"></hr>
        <div className="rounded-[2px] w-full border-[0.4px] p-6 flex items-center">
          <div className="flex items-center justify-center gap-24">
            <div className={cn("py-4 px-6 bg-[#EFEFEF]")}>
              {studentDetails?.linkedin_profile ? (
                <a href={studentDetails?.linkedin_profile} target="_blank" rel="noopener noreferrer">
                  <div className="flex gap-2 items-center">
                    <LinkedInIcon className="h-6 w-6" />
                    <p className="text-[#979797] font-semibold text-[18px] underline">LinkedIn</p>
                  </div>
                </a>
              ) : (
                <div className="flex gap-2 items-center">
                  <LinkedInIcon className="h-6 w-6 fill-[#9F9F9F]" />
                  <p className="text-[#979797] font-semibold text-[18px] underline">LinkedIn</p>
                </div>
              )}
            </div>
            <div className="flex gap-2 items-center">
              <Toggle checked={studentDetails?.linkedin_status === "Approved"} onChange={handleToggle} />
              <p className="">Approve</p>
            </div>
            <button className="" onClick={() => setLinkedInCardPopup(true)}>
              <div className="border border-black flex items-center justify-center gap-1 py-2 px-4">
                <UploadIcon className={cn("h-5 w-5")} />
                <p className="text-[#312E81]">
                  {studentDetails?.linkedin_profile ? "Update LinkedIn" : "Upload LinkedIn"}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
      {linkedInCardPopup && (
        <div className="fixed inset-0 bg-[rgba(0,0,0,0.6)] flex items-center justify-center z-50">
          <div className="rounded-lg shadow-lg">
            <UpdateLinkedInCard
              studentDetails={studentDetails}
              setStudentDetails={setStudentDetails}
              onCancel={() => setLinkedInCardPopup(false)}
            />
          </div>
        </div>
      )}
    </div>
  )
}
