import React, { useState } from "react"
import { Modal } from "@mui/material"
import Button from "core/components/new/Button"
import { CrossIcon, InfoButton } from "core/constants/svgs"

type Result = {
  [key: string]: string | number
}

type ResultModalProps = {
  result: Result | null
  onClose: () => void
}

const isQuestion = (key: string): boolean => {
  return !key.includes("Score")
}

const findMaxMarks = (key: string): number => {
  const match = key.match(/Out of (\d+)/)
  if (match) {
    return parseInt(match[1])
  }
  return 0
}

const removeMaxMarks = (key: string): string => {
  return key.replace(/\s\(Out of \d+\)/, "")
}

const renderQuestion = (result: Record<string, any>, currentKey: string): string | undefined => {
  const filteredKeys = Object.keys(result).filter((key) => key.startsWith("$"))
  const current = currentKey.replace(/\s\(Out of \d+\)/, "").split(" Q")
  const type = current[0]
  const number = current[1]
  let matchingKey = ""
  if (type === "MCQ") {
    matchingKey = `$mcqQuestion-${number}`
  } else {
    matchingKey = `$coding-${number}`
  }
  return matchingKey
}
const ResultModal: React.FC<ResultModalProps> = ({ result, onClose }) => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null)

  const handleQuestionButtonHover = (index: any) => {
    setOpenQuestionIndex(index)
  }

  const handleQuestionButtonLeave = () => {
    setOpenQuestionIndex(null)
  }

  return (
    <Modal open={!!result} onClose={onClose}>
      <div className="fixed inset-0 flex justify-center items-center">
        <div className="bg-white p-8 rounded shadow-md w-auto">
          <h2 className="text-xl font-bold mb-4 text-center">Your Result</h2>

          <div className="relative" style={{ maxHeight: "400px", overflow: "auto" }}>
            <table className="w-full border border-gray-200" style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200" style={{ borderRight: "1px solid #000" }}>
                    Question
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200" style={{ borderRight: "1px solid #000" }}>
                    Maximum Marks
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200">Marks Obtained</th>
                </tr>
              </thead>
              <tbody>
                {result &&
                  Object.entries(result)
                    .filter(([key, value], index) => key[0] !== "$")
                    .map(([key, value], index) => (
                      <tr key={key} className="border-b border-gray-200">
                        <td className="py-2 px-4" style={{ borderRight: "1px solid #000" }}>
                          <span className="py-2">{removeMaxMarks(key)} </span>{" "}
                          {openQuestionIndex === index &&
                            renderQuestion(result, key) &&
                            (renderQuestion(result, key)?.length ?? 0) > 0 && (
                              <div className="absolute bg-white border border-gray-300 p-4 z-10">
                                {result[renderQuestion(result, key) as keyof typeof result]}
                              </div>
                            )}
                          {isQuestion(key) && (
                            <span>
                              <button
                                onMouseEnter={() => handleQuestionButtonHover(index)}
                                onMouseLeave={handleQuestionButtonLeave}
                                className="pt-[3px]"
                              >
                                {/* <InfoButton width={14} height={14} /> */}

                                <InfoButton
                                  width={14}
                                  height={14}
                                  setInfoHover={(hover: any) => handleQuestionButtonHover(hover ? index : null)}
                                />
                              </button>
                            </span>
                          )}
                        </td>
                        <td className="py-2 px-4" style={{ borderRight: "1px solid #000" }}>
                          {findMaxMarks(key)}
                        </td>
                        <td className="py-2 px-4">{value}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <div className="mt-8 flex justify-end">
            <Button className="m-auto w-[30%] h-4" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ResultModal
