import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface GetClassNameUseCase {
  invoke(data: any): Promise<boolean>
}

export class GetClassName implements GetClassNameUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(data: any) {
    return this.repository.getClassName(data)
  }
}
