import { Auth } from "domain/model/Auth"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface UploadResumeUsecase {
  invoke(auth: Auth, docId: string, resumeFile: any): Promise<any>
}

export class UploadResume implements UploadResumeUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: string, resumeFile: any): Promise<any> {
    return await this.repository.uploadResume(auth, docId, resumeFile)
  }
}
