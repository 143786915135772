import { Auth } from "domain/model/Auth"
import MentorSessionsRepository from "domain/repository/Admin/MentorSessionsRepository"

export interface GetMentorSessionsUseCase {
  invoke(auth: Auth, mentor_id: string, batch: string, month: string, year: any): Promise<any>
}

export default class GetMentorSessions implements GetMentorSessionsUseCase {
  private repository: MentorSessionsRepository

  constructor(repository: MentorSessionsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, batch: string, month: string, year: any) {
    return await this.repository.getMentorSessions(auth, mentor_id, batch, month, year)
  }
}
