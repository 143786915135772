import { Auth } from "domain/model/Auth"
import { PlacementBatchRepository } from "domain/repository/Admin/PlacementBatchRepository"

export interface AddCohortStudentListUseCase {
  invoke(
    auth: Auth,
    contestId: string,
    placementBatchName: string,
    bridgeBatchName: string,
    passingScore: number,
    created_on: number
  ): Promise<any>
}

export class AddCohortStudentList implements AddCohortStudentListUseCase {
  private repository: PlacementBatchRepository

  constructor(repository: PlacementBatchRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    contestId: string,
    placementBatchName: string,
    bridgeBatchName: string,
    passingScore: number,
    created_on: number
  ) {
    return await this.repository.addCohortStudent(
      auth,
      contestId,
      placementBatchName,
      bridgeBatchName,
      passingScore,
      created_on
    )
  }
}
