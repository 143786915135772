import Popup from "core/components/PremiumPopup"
import Toast from "core/components/Toast"
import { STR_PLACEMENT, STR_PLACEMENT_SUBTITLE } from "core/constants/strings"
import DashboardLayoutV2 from "core/layouts/DashboardLayoutv2"
import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Footer from "./components/Footer"
import JoinTelegramComponent from "./components/JoinTelegramComponent"
import PartnerCompanyList from "./components/PartnerCompanyList"
import PlacementCard from "./components/PlacementCard"
import PlacementDetails from "./components/PlacementDetails"
import PlacementPolicyCard from "./components/PlacementPolicyCard"
import NewFeedbackModal from "core/components/NewFeedbackModal"
import PlacementProcess from "./components/PlacementProcess"
import PlacementUpdate from "./components/PlacementUpdate"
import usePlacementViewModel from "./PlacementViewModel"

export default function PlacementView() {
  const {
    toast,
    allPlacementsList,
    newPlacementList,
    isPremiumPopupVisible,
    student,
    containerRef,
    studentFourMonth,
    getRecentPlacements,
    getAllJobsList,
    getStudentJobs,
    getNewPlacement,
    changeToastVisibility,
    handleClosePopup,
    fetchLastFeedback,
    shouldShowFeedbackAfter5Months,
    getJoiningDate,
    saveFeedback,
    showPlacementPopup,
    getPlacementDetails,
  } = usePlacementViewModel()

  useEffect(() => {
    getRecentPlacements()
    getAllJobsList()
    getStudentJobs()
    getNewPlacement()
    getPlacementDetails()
  }, [])

  let sliderRef = useRef<Slider>(null)

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots: any) => (
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          padding: "0px",
        }}
      >
        <div className=" -mx-4">{dots}</div>
      </div>
    ),
  }

  const backgroundColors = ["bg-[#E0E7FF]", "bg-[#BBF7D0]", "bg-[#FEF3C7]", "bg-[#FEE2E2]"]
  const backgroundColors2 = ["#FFDA8C", "#F0F477", "#EDC8FB", "#97FDB3", "#FFBEBE", "#99D9FA"]

  const backgroundColorsGradint = [
    "to-[#FFDA8C]",
    "to-[#F0F477]",
    "to-[#EDC8FB]",
    "to-[#97FDB3]",
    "to-[#FFBEBE]",
    "to-[#99D9FA]",
  ]
  const isDSML = student?.batch?.includes("DSML") || student?.tracks.includes("data_engineer")
  const isEvolve = student?.batch?.includes("EV")
  const [showModal, setShowModal] = React.useState(false)

  useEffect(() => {
    const loadFeedback = async () => {
      const lastFeedbackDate = await fetchLastFeedback("Placement")
      const joining_date = await getJoiningDate()
      setShowModal(shouldShowFeedbackAfter5Months(joining_date, lastFeedbackDate))
    }

    loadFeedback()
  }, [])

  return studentFourMonth ? (
    isDSML ? (
      <DashboardLayoutV2>
        <JoinTelegramComponent />
        <div className="space-y-6 p-6 text-new-neutral-dark">
          <div className="space-y-6">
            {showPlacementPopup && <PlacementDetails student={student} />}
            <PlacementUpdate />
            <PlacementProcess isEvolve={isEvolve} />
            <div className="flex flex-col px-8  rounded">
              <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
            </div>
            <PartnerCompanyList />
            <Footer />
          </div>{" "}
        </div>
        {showModal && <NewFeedbackModal saveFeedback={saveFeedback} message="Placement" />}
      </DashboardLayoutV2>
    ) : isEvolve ? (
      <DashboardLayoutV2>
        <JoinTelegramComponent />
        <div className="space-y-6 p-6 text-new-neutral-dark">
          <div className="space-y-6">
            {showPlacementPopup && <PlacementDetails student={student} />}
            <PlacementUpdate />
            <PlacementProcess isEvolve={isEvolve} />
            <div className="flex flex-col px-8  rounded">
              <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
            </div>
            <PartnerCompanyList />
            <Footer />
          </div>
        </div>
        {showModal && <NewFeedbackModal saveFeedback={saveFeedback} message="Placement" />}
      </DashboardLayoutV2>
    ) : (
      <DashboardLayoutV2 isLoading={!allPlacementsList || allPlacementsList.length === 0}>
        {isPremiumPopupVisible && <Popup onClose={handleClosePopup} />}

        <JoinTelegramComponent />
        {showPlacementPopup && <PlacementDetails student={student} />}

        <div className="space-y-6 p-6 text-new-neutral-dark">
          <div className="space-y-6">
            {newPlacementList.length > 0 && (
              <>
                <div className=" relative flex space-y-2 justify-between ">
                  <div>
                    <h2 className="text-black font-semibold text-[16px]">{STR_PLACEMENT}</h2>
                    <p className=" text-[#646464]">{STR_PLACEMENT_SUBTITLE}</p>
                  </div>
                  <div className=" flex">
                    <button
                      onClick={previous}
                      className=" mx-1 w-[32px] h-[32px] border-[1px] rounded-[4px] flex items-center justify-center hover:bg-[#EBEBEB] "
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" rx="2" fill="none" />
                        <path
                          d="M5.21875 7.33312H13.3334V8.66645H5.21875L8.79475 12.2425L7.85208 13.1851L2.66675 7.99979L7.85208 2.81445L8.79475 3.75712L5.21875 7.33312Z"
                          fill="#001018"
                        />
                      </svg>
                    </button>

                    <button
                      onClick={() => {
                        next()
                      }}
                      className=" mx-1 w-[32px] h-[32px] border-[0.5px] border-[#D3D3D3] rounded-[4px]  hover:bg-[#EBEBEB] flex items-center justify-center"
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="arrow">
                          <rect
                            x="16"
                            y="16"
                            width="16"
                            height="16"
                            rx="2"
                            transform="rotate(-180 16 16)"
                            fill="none"
                          />
                          <path
                            id="shape"
                            d="M10.7813 8.66688L2.66659 8.66688L2.66659 7.33355L10.7813 7.33355L7.20525 3.75755L8.14792 2.81488L13.3333 8.00021L8.14792 13.1855L7.20525 12.2429L10.7813 8.66688Z"
                            fill="#001018"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>

                <div className=" w-full h-[165px] ">
                  <button onClick={previous}>Previous</button>
                  <button onClick={next}>Next</button>
                  <Slider ref={sliderRef} {...settings}>
                    {newPlacementList.map((data: any, index: any) => {
                      return (
                        <div className=" w-full h-full p-[4px] ">
                          {" "}
                          <div
                            key={index}
                            className="w-full rounded-[8px]  p-[16px] flex items-center justify-center shadow-card h-[147px] "
                          >
                            <div className="  w-full h-[115px]  flex justify-between">
                              <div className=" min-w-[226px] h-[115px] bg-white   relative overflow-hidden rounded-[8px] shadow-card  ">
                                <div
                                  className={` z-10  top-10 absolute w-[143.23px] h-[120px]  bg-gradient-to-t from-white  left-3  opacity-30  ${backgroundColorsGradint[index % 6]} transform rotate-[200deg]`}
                                ></div>
                                <div
                                  className={` z-10  bottom-16 right-2 absolute w-[143.23px] h-[120px]  bg-gradient-to-b from-white  ${backgroundColorsGradint[index % 6]}  opacity-30 transform rotate-[210deg]`}
                                ></div>

                                <div className="w-full  z-50 flex items-center  justify-around h-[50%] ">
                                  <img
                                    src={data.profile_picture}
                                    alt=""
                                    className=" z-40   object-cover rounded-full w-[36px] h-[36px] border-[1px]"
                                  />

                                  <div className=" z-40 w-[142px] h-[35px] flex items-center">
                                    <p className=" font-semibold">{data?.name}</p>
                                  </div>
                                </div>

                                <div className=" w-full z-40 h-[50%] flex items-center justify-center">
                                  <img className=" h-8 ml-3" src={data?.company_logo} />
                                </div>
                              </div>

                              <div className="  ml-[16px] bg-white h-full flex justify-between w-[473px]">
                                <svg
                                  className="  w-[46px] h-[43px] opacity-40 mb-5"
                                  viewBox="0 0 46 35"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 0V10.3989C0 13.3517 0.579832 16.3686 1.7395 19.4498C2.89916 22.5309 4.42927 25.4356 6.32983 28.1637C8.23039 30.8918 10.292 33.1706 12.5147 35L21.5987 29.6561C19.7948 26.8317 18.313 23.879 17.1534 20.7978C16.0259 17.7166 15.4622 14.2824 15.4622 10.4952V0H0ZM24.4013 0V10.3989C24.4013 13.3517 24.9811 16.3686 26.1408 19.4498C27.3004 22.5309 28.8305 25.4356 30.7311 28.1637C32.6316 30.8918 34.6933 33.1706 36.916 35L46 29.6561C44.1961 26.8317 42.7143 23.879 41.5546 20.7978C40.4272 17.7166 39.8634 14.2824 39.8634 10.4952V0H24.4013Z"
                                    fill={backgroundColors2[index % 6]}
                                  />
                                </svg>

                                <div className=" w-[345px] h-[115px] flex items-center justify-center p-[10px] ">
                                  <div className=" w-[247px] h-[115px] flex items-center justify-center ">
                                    <p className="text-xs  font-light">{data?.testimonial} </p>
                                  </div>
                                </div>

                                <svg
                                  className=" w-[46px]   opacity-40"
                                  viewBox="0 0 46 115"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M46 115V104.601C46 101.648 45.4202 98.6314 44.2605 95.5502C43.1008 92.4691 41.5707 89.5644 39.6702 86.8363C37.7696 84.1082 35.708 81.8294 33.4853 80L24.4013 85.3439C26.2052 88.1683 27.687 91.121 28.8466 94.2022C29.9741 97.2834 30.5378 100.718 30.5378 104.505V115H46ZM21.5987 115V104.601C21.5987 101.648 21.0189 98.6314 19.8592 95.5502C18.6996 92.4691 17.1695 89.5644 15.2689 86.8363C13.3684 84.1082 11.3067 81.8294 9.08404 80L-8.16062e-08 85.3439C1.80392 88.1683 3.28572 91.121 4.44538 94.2022C5.57283 97.2834 6.13656 100.718 6.13656 104.505V115H21.5987Z"
                                    fill={backgroundColors2[index % 6]}
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </>
            )}
            <PlacementUpdate />
            <PlacementProcess isEvolve={isEvolve} />
            <div className="flex flex-col    rounded">
              <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
            </div>

            <PartnerCompanyList />
            <div className=" my-[24px] ">
              <p className="  text-[16px] font-semibold text-[#333333] ">Previous Placements</p>
              <p className=" text-[14px] text-[#646464]">
                {"Bosscoder students who secured placements at leading companies."}
              </p>
            </div>
            <div className="overflow-x gap-2 rounded-[8px] pb-6  bg-[#F9F9F9]">
              <div
                ref={containerRef}
                id="placementContainer"
                className="custom-scrollbar-2 mx-6 flex overflow-x-scroll "
              >
                {allPlacementsList?.map((data: any, index: any) => (
                  <PlacementCard
                    key={index}
                    bg={backgroundColorsGradint[index % 4]}
                    name={data.name}
                    student_pic={data?.student}
                    company_name={data.company_name}
                  />
                ))}
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
        {showModal && <NewFeedbackModal saveFeedback={saveFeedback} message="Placement" />}
      </DashboardLayoutV2>
    )
  ) : isDSML ? (
    <DashboardLayoutV2>
      <JoinTelegramComponent />

      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-6">
          <PlacementProcess isEvolve={isEvolve} />
          <div className="flex flex-col px-8  rounded">
            <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
          </div>
          <PlacementUpdate />
          <PartnerCompanyList />
          <Footer />
        </div>
      </div>
    </DashboardLayoutV2>
  ) : isEvolve ? (
    <DashboardLayoutV2>
      <JoinTelegramComponent />
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-6">
          <PlacementProcess isEvolve={isEvolve} />
          <div className="flex flex-col   px-8 rounded">
            <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
          </div>

          <PlacementUpdate />
          <PartnerCompanyList />
          <Footer />
        </div>
      </div>
    </DashboardLayoutV2>
  ) : (
    <DashboardLayoutV2 isLoading={!allPlacementsList || allPlacementsList.length === 0}>
      {isPremiumPopupVisible && <Popup onClose={handleClosePopup} />}
      <JoinTelegramComponent />
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-6">
          {newPlacementList.length > 0 && (
            <>
              <div className=" relative flex space-y-2 justify-between ">
                <div>
                  <h2 className="text-black font-semibold text-[16px]">{STR_PLACEMENT}</h2>
                  <p className=" text-[#646464]">{STR_PLACEMENT_SUBTITLE}</p>
                </div>
                <div className=" flex">
                  <button
                    onClick={previous}
                    className=" hover:bg-[#EBEBEB] mx-1 w-[32px] h-[32px] border-[1px] rounded-[4px] flex items-center justify-center "
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="16" height="16" rx="2" fill="" />
                      <path
                        d="M5.21875 7.33312H13.3334V8.66645H5.21875L8.79475 12.2425L7.85208 13.1851L2.66675 7.99979L7.85208 2.81445L8.79475 3.75712L5.21875 7.33312Z"
                        fill="#001018"
                      />
                    </svg>
                  </button>

                  <button
                    onClick={next}
                    className=" mx-1 w-[32px] h-[32px] border-[0.5px] border-[#D3D3D3] rounded-[4px] hover:bg-[#EBEBEB]  flex items-center justify-center"
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="arrow">
                        <rect x="16" y="16" width="16" height="16" rx="2" transform="rotate(-180 16 16)" fill="" />
                        <path
                          id="shape"
                          d="M10.7813 8.66688L2.66659 8.66688L2.66659 7.33355L10.7813 7.33355L7.20525 3.75755L8.14792 2.81488L13.3333 8.00021L8.14792 13.1855L7.20525 12.2429L10.7813 8.66688Z"
                          fill="#001018"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>

              <div className=" w-full h-[165px] ">
                <Slider ref={sliderRef} {...settings}>
                  {/* Slider content here */}

                  {newPlacementList.map((data: any, index: any) => {
                    return (
                      <div className=" w-full h-full p-[4px] ">
                        {" "}
                        <div
                          key={index}
                          className="w-full rounded-[8px]  p-[16px] flex items-center justify-center shadow-card h-[147px] "
                        >
                          <div className="  w-full h-[115px]  flex justify-between">
                            <div className=" min-w-[226px] h-[115px] bg-white   relative overflow-hidden rounded-[8px] shadow-card  ">
                              <div
                                className={` z-10  top-10 absolute w-[143.23px] h-[120px]  bg-gradient-to-t from-white  left-3  opacity-30  ${backgroundColorsGradint[index % 6]} transform rotate-[200deg]`}
                              ></div>
                              <div
                                className={` z-10  bottom-16 right-2 absolute w-[143.23px] h-[120px]  bg-gradient-to-b from-white  ${backgroundColorsGradint[index % 6]}  opacity-30 transform rotate-[210deg]`}
                              ></div>

                              <div className="w-full  z-50 flex items-center  justify-around h-[50%] ">
                                <img
                                  src={data.profile_picture}
                                  alt=""
                                  className=" z-40   object-cover rounded-full w-[36px] h-[36px] border-[1px]"
                                />

                                <div className=" z-40 w-[142px] h-[35px] flex items-center">
                                  <p className=" font-semibold">{data?.name}</p>
                                </div>
                              </div>

                              <div className=" w-full z-40 h-[50%] flex items-center justify-center">
                                <img className=" h-8 ml-3" src={data?.company_logo} />
                              </div>
                            </div>

                            <div className="  ml-[16px] bg-white h-full flex justify-between w-[473px]">
                              <svg
                                className="  w-[46px] h-[43px] opacity-40 mb-5"
                                viewBox="0 0 46 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 0V10.3989C0 13.3517 0.579832 16.3686 1.7395 19.4498C2.89916 22.5309 4.42927 25.4356 6.32983 28.1637C8.23039 30.8918 10.292 33.1706 12.5147 35L21.5987 29.6561C19.7948 26.8317 18.313 23.879 17.1534 20.7978C16.0259 17.7166 15.4622 14.2824 15.4622 10.4952V0H0ZM24.4013 0V10.3989C24.4013 13.3517 24.9811 16.3686 26.1408 19.4498C27.3004 22.5309 28.8305 25.4356 30.7311 28.1637C32.6316 30.8918 34.6933 33.1706 36.916 35L46 29.6561C44.1961 26.8317 42.7143 23.879 41.5546 20.7978C40.4272 17.7166 39.8634 14.2824 39.8634 10.4952V0H24.4013Z"
                                  fill={backgroundColors2[index % 6]}
                                />
                              </svg>

                              <div className=" w-[345px] h-[115px] flex items-center justify-center p-[10px] ">
                                <div className=" w-[247px] h-[115px] flex items-center justify-center ">
                                  <p className="text-xs  font-light">{data?.testimonial} </p>
                                </div>
                              </div>

                              <svg
                                className=" w-[46px]   opacity-40"
                                viewBox="0 0 46 115"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M46 115V104.601C46 101.648 45.4202 98.6314 44.2605 95.5502C43.1008 92.4691 41.5707 89.5644 39.6702 86.8363C37.7696 84.1082 35.708 81.8294 33.4853 80L24.4013 85.3439C26.2052 88.1683 27.687 91.121 28.8466 94.2022C29.9741 97.2834 30.5378 100.718 30.5378 104.505V115H46ZM21.5987 115V104.601C21.5987 101.648 21.0189 98.6314 19.8592 95.5502C18.6996 92.4691 17.1695 89.5644 15.2689 86.8363C13.3684 84.1082 11.3067 81.8294 9.08404 80L-8.16062e-08 85.3439C1.80392 88.1683 3.28572 91.121 4.44538 94.2022C5.57283 97.2834 6.13656 100.718 6.13656 104.505V115H21.5987Z"
                                  fill={backgroundColors2[index % 6]}
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </>
          )}
          <PlacementProcess isEvolve={isEvolve} />
          <div className="flex flex-col p-8 gap-6 rounded">
            <PlacementPolicyCard course_name="d" courseCompleted={true} certificate_url="" />
          </div>
          <PlacementUpdate />

          <PartnerCompanyList />

          <div className=" my-[24px] ">
            <p className="  text-[16px] font-semibold text-[#333333] ">Previous Placements</p>
            <p className=" text-[14px] text-[#646464]">
              {"Bosscoder students who secured placements at leading companies."}
            </p>
          </div>
          <div className="overflow-x gap-2 rounded-[8px] pb-6  bg-[#F9F9F9]">
            <div ref={containerRef} id="placementContainer" className="custom-scrollbar-2 mx-6 flex overflow-x-scroll ">
              {allPlacementsList?.map((data: any, index: any) => (
                <PlacementCard
                  key={index}
                  bg={backgroundColorsGradint[index % 4]}
                  name={data.name}
                  student_pic={data?.student}
                  company_name={data.company_name}
                />
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutV2>
  )
}
