import { cn } from "core/lib/utils"
import { useEffect, useRef, useState } from "react"

export default function StandardSelect({
  id,
  className,
  value,
  onChange,
  placeholder,
  options,
  required = false,
  dimensions,
  dropdownClass,
  disabled,
}: any) {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectOption = (option: string) => {
    onChange(option)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div ref={selectRef} className={cn("relative w-[300px] rounded-[4px] flex-row", dimensions)}>
      <div>
        <button
          type="button"
          className={cn(
            className,
            "pl-[16px] h-[35px] w-full cursor-pointer appearance-none border border-gray-300 bg-white text-left text-sm text-[#646464] font-inter font-normal leading-normal focus:none outline-none"
          )}
          onClick={toggleDropdown}
          disabled={disabled}
        >
          {value || placeholder}
        </button>
      </div>
      <div className="bg-[#f9f9f9]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="border left-[111px] border-[#d3d3d3] rounded-tr-md rounded-br-md absolute top-[0px] h-[35px] bg-[#f9f9f9] text-gray-600 cursor-pointer"
          viewBox="0 0 16 16"
          fill="none"
          onClick={() => !disabled && toggleDropdown()}
        >
          <path
            d="M7.99993 8.78145L11.2999 5.48145L12.2426 6.42411L7.99993 10.6668L3.75726 6.42411L4.69993 5.48145L7.99993 8.78145Z"
            fill="#646464"
          />
        </svg>
        {isOpen && (
          <ul
            className={cn(
              "absolute z-10 mt-1 w-full bg-white border border-gray-300 shadow-md p-1 rounded-[4px]",
              dropdownClass
            )}
          >
            {options?.map((option: string, i: number) => (
              <li
                key={i}
                className="px-4 py-2  leading-4  cursor-pointer hover:bg-gray-100 rounded-[4px]
              text-gray-500 font-inter text-base font-medium"
                onClick={() => handleSelectOption(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
