import { Auth } from "domain/model/Auth"
import { StudentProgressRepository } from "domain/repository/Student/StudentProgressRepository"

export interface GetAllProgressDataUseCase {
  invoke(auth: Auth, start: number, end: number): Promise<any>
}

export class GetAllProgressData implements GetAllProgressDataUseCase {
  private repository: StudentProgressRepository

  constructor(repository: StudentProgressRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getAllProgressData(auth)
  }
}
