import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useEffect, useState } from "react"
import SessionsCard from "./components/SessionsCard"
import GenerateInvoiceViewModel from "./GenerateInvoiceViewModel"
import { isEmpty } from "core/utils/misc"
import { useParams } from "react-router-dom"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import { STR_SUCCESS, STR_FAILURE } from "core/constants/strings"
import Modal from "core/components/Modal"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import useToast from "core/hooks/useToast"
import Spinner from "core/components/Spinner"

export default function MonthSessionsView() {
  const {
    auth,
    batch,
    allSessionMonthYear,
    confirmStatus,
    getBatchList,
    sessionName,
    sessionDate,
    originalData,
    updateClassBool,
    setUpdateClassBool,
    getBatches,
    setBatch,
    setSessionDate,
    setSessionName,
    handleSessionName,
    handleSessionsDate,
    handleSearchByBatch,
    fetchInvoiceOfMentor,
    fetchUpdatedInstructorDetails,
    fetchConfirmedInvoiceOfMentor,
    fetchAllSessionMonthyear,
    setAllSessionMonthYear,
    provideExtraIncentive,
    handleIncentiveChange,
    loadingAmount,
    clearFilter,
    setClearFiler,
    invoiceGenerationLoading,
    setInvoiceGenerationLoading,
  } = GenerateInvoiceViewModel()
  const { id, yearMonth } = useParams()
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false)
  const [status, setStatus] = React.useState("")
  const [monthName, setMonthName] = React.useState<string>("")
  const [apiData, setData] = React.useState<any>()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  function handleConfirmModal() {
    setConfirmModal(!confirmModal)
  }
  async function handleInvoice() {
    setConfirmModal(!confirmModal)
    await fetchConfirmedInvoiceOfMentor(auth, id as string, yearMonth as string)
  }

  const STATUSARRAY: string[] = [
    "Scheduled Class",
    "Class Taken",
    "Cancelled On Time",
    "Substituted Class",
    "Cancelled Post Time",
    "Cancelled Last Min",
  ]

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const [selectedOption, setSelectedOption] = useState(1)
  function radioFunction(i: number) {
    setSelectedOption(i)
    let module = ""
    if (i === 2) {
      module = "Hourly Class"
    }
    if (i == 3) {
      module = "Module Based Class"
    }
    const filter_data = (originalData?.v4_session_classes || []).filter(
      (item: any, key: number) => module === "" || item.compensation_status === module
    )

    const obj = {
      v4_session_classes: filter_data,
    }
    setAllSessionMonthYear(obj)
  }
  function handleSearchByStatus(e: any) {
    setStatus(e.target.value)
  }

  async function hanldeFilter() {
    const filter_data = (originalData?.v4_session_classes || []).filter(
      (item: any, key: number) =>
        (sessionName === "" || item.chatper_name === sessionName) &&
        (batch === "" || item.batches.includes(batch)) &&
        (status === "" || item.status === status) &&
        (sessionDate === "" || timestampToDate(item.session_timestamp) === sessionDate)
    )

    const obj = {
      v4_session_classes: filter_data,
    }
    setBatch("")
    setSessionName("")
    setSessionDate("")
    setStatus("")
    setAllSessionMonthYear(obj)
  }

  function timestampToDate(timestamp: any) {
    const date = new Date(timestamp * 1000)
    const formattedDate = date.toISOString().split("T")[0]
    return formattedDate
  }
  async function previousData() {
    const rspns = await fetchUpdatedInstructorDetails(auth, id as string, yearMonth as string)
    setData(rspns)
    return rspns
  }

  async function handleInvoiceSubmit() {
    setInvoiceGenerationLoading(true)
    const value = await fetchInvoiceOfMentor(auth, id as string, yearMonth as string)
    setConfirmModal(!confirmModal)

    if (value === "Invoice Not generated") {
      changeToastDetails(STR_FAILURE, "Invoice Not generated")
    } else {
      changeToastDetails(STR_SUCCESS, `Invoice Generation ${value}`)
    }

    changeToastVisibility(true)
    setInvoiceGenerationLoading(false)
  }

  React.useEffect(() => {
    const yearMonthString = yearMonth as string
    const year = yearMonthString.slice(0, 4)
    const month = yearMonthString.slice(4)
    setMonthName(months[parseInt(month, 10) - 1])
    fetchAllSessionMonthyear(auth, id as string, year, months[parseInt(month, 10) - 1])
    previousData()
    getBatches()
  }, [id, yearMonth, updateClassBool, clearFilter])

  const [statusCounts, setStatusCounts] = useState<Record<string, number>>({
    "Scheduled Class": 0,
    "Class Taken": 0,
    "Cancelled On Time": 0,
    "Substituted Class": 0,
    "Cancelled Post Time": 0,
    "Cancelled Last Min": 0,
  })

  // Function to count the status
  const countStatuses = () => {
    const counts = STATUSARRAY.reduce(
      (acc, status) => {
        acc[status] = (allSessionMonthYear?.v4_session_classes || []).filter(
          (item: any) => item.status === status
        ).length
        return acc
      },
      {} as Record<string, number>
    )
    setStatusCounts(counts)
  }

  // Update counts when data changes
  useEffect(() => {
    countStatuses()
  }, [allSessionMonthYear])
  return (
    <DashboardLayout>
      <div className="flex flex-col gap-y-6">
        <div className="">
          <h1>{`${monthName} ${(yearMonth as string).slice(0, 4)}  All Sessions`}</h1>
        </div>
        <div className="mx-4 flex items-end space-x-6">
          <div className="flex flex-col space-y-1">
            <h4>Session Name</h4>
            <Input type="text" placeholder="Session Name" onChange={handleSessionName} value={sessionName}></Input>
          </div>
          <div className="flex flex-col space-y-1">
            <h4>Session Status</h4>
            <StandaredSelect
              required
              id="Batch"
              className="w-[209px]"
              options={STATUSARRAY}
              placeholder="Select Status"
              onChange={handleSearchByStatus}
              value={status}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <h4>Date</h4>
            <Input type="date" value={sessionDate} onChange={handleSessionsDate}></Input>
          </div>
          <div className="flex flex-col space-y-1">
            <h4>Batch</h4>
            <StandaredSelect
              required
              id="Batch"
              className="w-[209px]"
              options={getBatchList}
              placeholder="Select Batch"
              onChange={handleSearchByBatch}
              value={batch}
            />
          </div>
          <Button className="h-[37px] w-fit" onClick={hanldeFilter}>
            Submit
          </Button>
          <Button className="h-[37px] w-fit" onClick={() => setClearFiler((prev) => !prev)}>
            Clear
          </Button>
        </div>
        {!isEmpty(allSessionMonthYear) ? (
          <div className="mx-4 flex flex-col space-y-6">
            <div className="flex space-x-2">
              <div className="me-4 flex items-center">
                <input
                  id="radio-1"
                  checked={selectedOption === 1}
                  type="radio"
                  name="radio-group"
                  className=""
                  onChange={() => radioFunction(1)}
                />
                <label htmlFor="radio-1" className="">
                  All
                </label>
              </div>
              <div className="me-4 flex items-center">
                <input
                  id="radio-2"
                  checked={selectedOption === 2}
                  type="radio"
                  name="radio-group"
                  className=""
                  onChange={() => radioFunction(2)}
                />
                <label htmlFor="radio-2" className="">
                  Hourly
                </label>
              </div>
              <div className="me-4 flex items-center">
                <input
                  id="radio-3"
                  type="radio"
                  name="radio-group"
                  checked={selectedOption === 3}
                  className=""
                  onChange={() => radioFunction(3)}
                />
                <label htmlFor="radio-3" className="">
                  Module
                </label>
              </div>
            </div>

            <div className="space-y-6">
              {!isEmpty(allSessionMonthYear?.v4_session_classes) ? (
                allSessionMonthYear?.v4_session_classes?.map((data: any, i: number) => (
                  <SessionsCard
                    toast={toast}
                    changeToastDetails={changeToastDetails}
                    changeToastVisibility={changeToastVisibility}
                    updateClassBool={updateClassBool}
                    setUpdateClassBool={setUpdateClassBool}
                    key={i}
                    index={i}
                    data={data}
                    onChange={setAllSessionMonthYear}
                    sessions={allSessionMonthYear}
                  />
                ))
              ) : (
                <div>
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
        <div className="flex flex-row mb-2 p-4 items-center justify-center gap-10">
          <div className="mt-2 flex flex-row items-center justify-center">
            <input
              type="checkbox"
              id="provideExtraIncentive"
              className="mr-2"
              checked={provideExtraIncentive}
              onChange={handleIncentiveChange}
            />
            <label htmlFor="provideExtraIncentive" className="font-medium text-gray-700 text-xl">
              Provide Incentive
            </label>
          </div>
          <Button onClick={handleInvoice} className="w-[200px]">
            Generate Invoice
          </Button>
        </div>
      </div>
      <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-6">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-new-accent">Generate Invoice</h3>
              <p>
                Are you sure you want to generate the invoice for the month of{" "}
                <span className="text-xl text-new-solid-black">{monthName}</span>?
              </p>

              <div className="flex flex-row justify-between">
                <div>
                  <h4 className="text-lg font-semibold text-gray-700">Compensation Details:</h4>
                  <p className="text-sm">
                    Hour Based Compensation:{" "}
                    <span className="font-medium">Rs {confirmStatus?.compensation_amount}</span>
                  </p>
                  <p className="text-sm">
                    Module Sessions: <span className="font-medium">Rs {confirmStatus?.module_amount}</span>
                  </p>
                  <p className="text-sm">
                    Incentive Amount:{" "}
                    {provideExtraIncentive ? (
                      <span className="font-medium">
                        Rs {Math.round((confirmStatus?.compensation_amount || 0) * 0.1 * 100) / 100}
                      </span>
                    ) : (
                      <span className="font-medium">Rs 0</span>
                    )}
                  </p>
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-700">Session Details</h4>
                  <p className="text-sm">
                    Scheduled: <span className="font-medium">{statusCounts["Scheduled Class"]}</span>
                  </p>
                  <p className="text-sm">
                    Taken: <span className="font-medium">{statusCounts["Class Taken"]}</span>
                  </p>
                  <p className="text-sm">
                    Substituted: <span className="font-medium">{statusCounts["Substituted Class"]}</span>
                  </p>
                  <p className="text-sm">
                    Cancelled On Time: <span className="font-medium">{statusCounts["Cancelled On Time"]}</span>
                  </p>
                  <p className="text-sm">
                    Cancelled Post Time: <span className="font-medium">{statusCounts["Cancelled Post Time"]}</span>
                  </p>
                  <p className="text-sm">
                    Cancelled Last Min: <span className="font-medium">{statusCounts["Cancelled Last Min"]}</span>
                  </p>
                </div>
              </div>

              <div className="text-center border-t border-gray-300 pt-4 flex flex-row justify-end">
                <p className="text-lg font-semibold text-gray-800">Total Amount {" :  "}</p>
                <p className="text-xl font-bold text-green-600">
                  {loadingAmount ? "Loading ..." : "Rs " + confirmStatus?.total_amount}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={() => setConfirmModal(false)}>
              No
            </Button>
            <Button outlined onClick={handleInvoiceSubmit}>
              {invoiceGenerationLoading ? <Spinner xs /> : "Yes"}
            </Button>
          </div>
        </div>
      </Modal>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
