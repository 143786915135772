import { Auth } from "domain/model/Auth"
import { ICPStudentRepository } from "domain/repository/Admin/ICPStudentRepository"

export interface GetICPStudentsOfNonactiveBatchUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetICPStudentsOfNonactiveBatch implements GetICPStudentsOfNonactiveBatchUseCase {
  private repository: ICPStudentRepository

  constructor(repository: ICPStudentRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getICPStudentsOfNonactiveBatch(auth)
  }
}
