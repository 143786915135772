import { loadNextData } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"
import { Auth } from "domain/model/Auth"

export interface GetNext30StudentsByCoinsUseCase {
  invoke(auth: Auth, data: any, coinType: any): Promise<any>
}

export class GetNext30StudentsByCoins implements GetNext30StudentsByCoinsUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any, coinType: any): Promise<any> {
    return this.repository.getNext30StudentsByCoins(auth, data, coinType)
  }
}
