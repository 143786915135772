import { ToggleProctoring } from "domain/useCase/Admin/Contest/ToggleProctoring"
import useStudentDashboardViewModel from "../StudentDashboard/StudentDashboardViewModel"
import { useAuth } from "core/context/auth"
import ContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import ContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"

export default function ContestProctoringViewModel() {
  const { auth } = useAuth()
  const { students, setStudents } = useStudentDashboardViewModel()

  const toggleProctoringUseCase = new ToggleProctoring(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  async function toggleProctoringStatus(student: any) {
    const response = await toggleProctoringUseCase.invoke(auth, student)

    return response
  }

  function proctoringStatusToggle(id: any) {
    const updatedStudents = students.map((student: any) => {
      if (student.email === id) {
        if (student.proctoring_status === true) {
          return { ...student, proctoring_status: false }
        } else {
          return { ...student, proctoring_status: false }
        }
      }
      return student
    })

    setStudents(updatedStudents)

    toggleProctoringStatus(id)
  }
  const tableHeaders = ["General Info", "Batch", "Proctoring Status"]
  return {
    tableHeaders,
    proctoringStatusToggle,
  }
}
