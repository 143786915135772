import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { isEmpty } from "core/utils/misc"
import GetAllClassesAPIDataSourceImpl from "data/API/Admin/GetAllClassesAPIDataSourceImpl"
import { GetAllClassesRepositoryImpl } from "data/repository/Admin/GetAllClassesRepositoryImpl"
import GetAllClasses from "domain/useCase/Admin/AllClasses/GetAllClasses"
import GetClassAllRating from "domain/useCase/Admin/AllClasses/GetClassAllRating"
import GetClassAverageRating from "domain/useCase/Admin/AllClasses/GetClassAverageRating"
import GetUpcomingClasses from "domain/useCase/Admin/AllClasses/GetUpcomingClasses"
import { useState } from "react"
import GetClassReminderData from "domain/useCase/Admin/AllClasses/GetClassReminderData"
import { STR_FAILURE } from "core/constants/strings"
import GetClassFeedbackAdminNew from "domain/useCase/Admin/AllClasses/GetClassFeedbackAdminNew"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function GetAllClassesViewModel() {
  const { auth } = useAuth()
  const [batch, setBatch] = useState<string>("")
  const [month, setMonth] = useState<string>("")
  const [year, setYear] = useState<number>(2023)
  const [moduleSearch, setModuleSearch] = useState<string>("")
  const [instructor, setInstructor] = useState<string>("")
  const [AllClasses, setAllClasses] = useLocalStorage<any>("all-classes", {})
  const [class_id, setClassId] = useState<any>("")
  const [ClassAllRating, setClassAllRating] = useState<any>({})
  const [ClassAverageRating, setClassAverageRating] = useState<any>({})
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [activeButtonType, setActiveButtonType] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [upcomingClasses, setUpcomingClasses] = useLocalStorage<any>("upcoming-classes", {})
  const [updateClassBool, setUpdateClassBool] = useState(false)
  const [duplicateClassBool, setDulplicateClassBool] = useState(false)
  const [upcomingClassBool, setUpcomingClassBool] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const MONTHARRAY = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const MODULEARRAY = [
    "01_beginner_course",
    "50_DSA",
    "40_project",
    "40_advanced_ml",
    "30_system_design",
    "30_foundational_ml",
    "20_data_visualisation",
    "20_cs_fundamentals",
    "10_DSA",
    "07_advanced_dsa",
    "06_mldl_specialisation",
    "05_introduction_to_mldl",
    "04_maths_for_datascience",
    "04_maths_for_data_science",
    "04_advanced_maths_for_datascience",
    "04_advanced_maths_for_data_science",
    "03_python",
    "03_advanced_python",
    "02_product_analytics",
    "01_data_fundamentals",
    "01_beginner_course",
    "ev_dsa",
    "dsml_beginner",
    "master_class",
  ]

  async function handleSearch(batch: string, month: string, instructor: string) {
    setLoading(true)
    await fetchAllClassesDetails(batch, month, instructor)
    await fetchUpcomingCLasses(batch, month, instructor)
    setLoading(false)
  }

  function handleSearchByInstructor(e: any) {
    if (e.target.value) setInstructor(e.target.value)
    else setInstructor("")
  }
  function handleSearchByBatch(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }

  function handleSearchByYear(e: any) {
    setYear(e.target.value)
  }

  function handleSearchByMonth(e: any) {
    if (e.target.value) setMonth(e.target.value)
    else setMonth("")
  }

  function handleSearchByModule(e: any) {
    if (e.target.value) {
      setModuleSearch(e.target.value)
    } else setModuleSearch("")
  }

  async function handleClearFilter() {
    setLoadingFilter(true)
    setInstructor("")
    setMonth("")
    setBatch("")
    await handleSearch("", "", "")
    setLoadingFilter(false)
  }

  async function changeActiveButtonType(index: number) {
    const i = index
    setActiveButtonType(index)
    if (i == 0) {
      if (isEmpty(upcomingClasses)) {
        await fetchUpcomingCLasses(batch, month, instructor)
      }
    } else {
      if (isEmpty(AllClasses)) {
        await fetchAllClassesDetails(batch, month, instructor)
      }
    }
  }

  const GetAllClassesUseCase = new GetAllClasses(new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl()))

  const GetClassAllRatingUseCase = new GetClassAllRating(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const GetClassAverageRatingUseCase = new GetClassAverageRating(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const GetUpcomingClassesUseCase = new GetUpcomingClasses(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const GetClassReminderDataUseCase = new GetClassReminderData(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const GetClassFeedbackAdmniNewUseCase = new GetClassFeedbackAdminNew(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  const fetchAllClassesDetails = async (batch: string, month: string, instructor: string) => {
    // if (!isEmpty(AllClasses.v4_session_classes)) return;
    const response = await GetAllClassesUseCase.invoke(auth, batch, month, instructor)

    if (!response?.success) {
      return
    }
    if (moduleSearch != "") {
      const filtered = response?.data.v4_session_classes.filter(
        (classItem: any) => classItem.module_id === moduleSearch
      )
      setAllClasses({ v4_session_classes: filtered })
    } else {
      setAllClasses(response?.data)
    }
  }

  const fetchUpcomingCLasses = async (batch: string, month: string, instructor: string) => {
    // if (!isEmpty(upcomingClasses.v4_session_classes)) return;
    const response = await GetUpcomingClassesUseCase.invoke(auth, batch, month, instructor)

    if (!response?.success) {
      return
    }
    if (moduleSearch != "") {
      const filtered = response?.data.v4_session_classes.filter(
        (classItem: any) => classItem.module_id === moduleSearch
      )
      setUpcomingClasses({ v4_session_classes: filtered })
    } else {
      setUpcomingClasses(response?.data)
    }
  }

  const fetchAllClassRatingDetails = async (class_id: any) => {
    const response = await GetClassAllRatingUseCase.invoke(auth, class_id)
    if (!response?.success) {
      return
    }

    setClassAllRating(response?.data)
  }

  const getClassReminderData = async (id: any) => {
    setIsLoading(true)
    const response = await GetClassReminderDataUseCase.invoke(auth, id)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "No reminder data available for this class")
      changeToastVisibility(true)
      setIsLoading(false)
      setTimeout(() => {
        changeToastVisibility(false)
      }, 5000)
      return
    }
    setIsLoading(false)
    const counts = response?.data?.map((column: any[]) => column?.length || 0)

    const headers = [
      `Students joined before first reminder (${counts[0]})`,
      `Students joined between first and second reminders (${counts[1]})`,
      `Students joined after second reminder (${counts[2]})`,
      `Students not joined even after two reminders (${counts[3]})`,
    ]

    let csvContent = `data:text/csv;charset=utf-8,${headers.join(",")}\n`
    const maxLength = Math.max(
      response?.data?.[0]?.length || 0,
      response?.data?.[1]?.length || 0,
      response?.data?.[2]?.length || 0,
      response?.data?.[3]?.length || 0
    )

    for (let i = 0; i < maxLength; i++) {
      const column1 = response?.data?.[0]?.[i] || ""
      const column2 = response?.data?.[1]?.[i] || ""
      const column3 = response?.data?.[2]?.[i] || ""
      const column4 = response?.data?.[3]?.[i] || ""

      const row = [column1, column2, column3, column4].join(",")
      csvContent += `${row}\n`
    }

    // Encode and trigger download
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "Class_Reminder_Data.csv")
    document.body.appendChild(link) // Required for Firefox
    link.click()
  }

  const getClassFeedbackNew = async (id: any) => {
    const response = await GetClassFeedbackAdmniNewUseCase.invoke(auth, id)
    console.log(response?.data)

    if (!response?.success) {
      return
    }
    const ratingTitles = response?.data[0].rating.map((item: any) => item.title)
    const headers = ["Student ID", "Batch", "Comment", "Average Rating", ...ratingTitles]

    let csvContent = `data:text/csv;charset=utf-8,${headers.join(",")}\n`

    response?.data.forEach((item: any) => {
      const stars = item.rating.map((rating: any) => rating.star)
      const row = [item?.student_id, item?.batch, item?.comment, item?.avg_rating, ...stars].join(",")
      csvContent += `${row}\n`
    })
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "Ratings_Data.csv")
    document.body.appendChild(link)
    link.click()
  }

  const fetchClassAverageRatingDetails = async () => {
    const response = await GetClassAverageRatingUseCase.invoke(auth, class_id)

    if (!response?.success) {
      return
    }

    setClassAverageRating(response?.data)
  }

  return {
    auth,
    batch,
    month,
    instructor,
    year,
    class_id,
    AllClasses,
    ClassAllRating,
    ClassAverageRating,
    toast,
    loading,
    activeButtonType,
    upcomingClasses,
    loadingFilter,
    updateClassBool,
    upcomingClassBool,
    duplicateClassBool,
    moduleSearch,
    MONTHARRAY,
    MODULEARRAY,
    isLoading,
    setIsLoading,
    setUpcomingClassBool,
    setUpdateClassBool,
    setDulplicateClassBool,
    handleClearFilter,
    setClassId,
    setLoading,
    setClassAllRating,
    handleSearch,
    fetchAllClassesDetails,
    fetchUpcomingCLasses,
    fetchAllClassRatingDetails,
    fetchClassAverageRatingDetails,
    handleSearchByBatch,
    handleSearchByModule,
    handleSearchByInstructor,
    handleSearchByMonth,
    handleSearchByYear,
    changeToastVisibility,
    changeActiveButtonType,
    changeToastDetails,
    getClassReminderData,
    getClassFeedbackNew,
  }
}
