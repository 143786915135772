import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import AdminDocDataSource from "data/dataSource/Admin/DocDataSource"
import { postAPIForFiles } from "core/utils/axios"
import { API_UPLOAD_IMAGE, API_UPLOAD_DOCUMNET, API_V4_ADMIN, API_UPLOAD_IMAGE_MCQ } from "core/constants/strings"

export default class AdminDocDataSourceImpl implements AdminDocDataSource {
  async upload_image(auth: Auth, data: any) {
    data.append("id_token", auth?.id_token)
    try {
      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_UPLOAD_IMAGE), data)
      return response
    } catch (error) {
      return error
    }
  }

  async upload_doc(auth: Auth, data: any): Promise<any> {
    data.append("id_token", auth?.id_token)
    try {
      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_UPLOAD_DOCUMNET), data)
      return response
    } catch (error) {
      return error
    }
  }

  async uploadImageMCQ(auth: Auth, data: any) {
    data.append("id_token", auth?.id_token)
    try {
      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_UPLOAD_IMAGE_MCQ), data)
      return response
    } catch (error) {
      return error
    }
  }
}
