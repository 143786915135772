import { API_ADMIN } from "core/constants/strings"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { SuperAdminLoggerDashboardDataSource } from "data/dataSource/SuperAdmin/SuperAdminLoggerDashboardDataSource"
import { AdminLogsDetails } from "domain/model/AdminLogs"
import { Auth } from "domain/model/Auth"

const server = new Server()

export class SuperAdminDashboardLoggerAPIDataSourceImpl implements SuperAdminLoggerDashboardDataSource {
  async getAdminLogs(auth: Auth, details: AdminLogsDetails): Promise<any> {
    try {
      const response = await server.get(
        pn(
          API_ADMIN,
          "super",
          `get_admin_logs?pageNo=${details.pageNo}&startDate=${details.startDate}&endDate=${details.endDate}`
        ),
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
