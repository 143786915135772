import CopyIcon from "assets/svgs/Copy"
import Button from "core/components/new/Button"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { TTableData } from "core/constants/types"
import { useAuth } from "core/context/auth"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { isEmpty } from "core/utils/misc"
import { capitalize, genError } from "core/utils/string"
import QuestionAPIDataSourceImpl from "data/API/Admin/QuestionsAPIDataSourceImpl"
import QuestionsRepositoryImpl from "data/repository/Admin/QuestionsRepository"
import FetchQuestions from "domain/useCase/Admin/Questions/FetchQuestions"
import { useState, useRef, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"

export default function QuestionsViewModel() {
  const options_rows = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
  ]
  const [questions, setQuestions] = useLocalStorage<any[]>("questions", [])
  const [tagList, setTagList] = useState<string[]>([])
  const [selectedTag, setSelectedTag] = useState<string>("")
  const [searchQuestion, setSearchQuestion] = useState<string>("")
  const [tableData, setTableData] = useState<TTableData>({
    header: ["#", "Name", "Likes", "Difficulty", "Tags", "Action"],
  } as TTableData)
  const [pageLoading, setPageLoading] = useState<boolean>(() => isEmpty(questions))
  const [rowsVisible, setRowsVisible] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const inputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const fetchQuestionsUseCase = new FetchQuestions(new QuestionsRepositoryImpl(new QuestionAPIDataSourceImpl()))

  const getQuestions = async () => {
    setPageLoading(isEmpty(questions))

    const response = await fetchQuestionsUseCase.invoke(auth?.id_token)

    setPageLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    const data = response?.data
    setQuestions(data?.question_list)

    const tagsSet = new Set<string>()
    const tagsArr: string[] = []

    // Iterate over the question list
    data?.question_list?.forEach((question: any) => {
      question.tags.forEach((tag: string) => {
        if (!tagsSet.has(tag)) {
          tagsSet.add(tag)
          tagsArr.push(tag) // Add to the array the first time
        }
      })
    })
    tagsArr.unshift("All")

    setTagList(tagsArr)
  }

  const pageFilter = () => {
    return function (_: any, i: number) {
      return searchQuestion.length > 0 || (i >= rowsVisible * (pageNumber - 1) && i < rowsVisible * pageNumber)
    }
  }

  const searchFilter = () => {
    return function (question: any) {
      return searchQuestion === "" || question?.problem_name?.toLowerCase().includes(searchQuestion?.toLowerCase())
    }
  }
  const tagFilter = () => {
    return (question: any) =>
      selectedTag === "" || selectedTag === "All" || question?.tags?.filter((tag: string) => tag === selectedTag).length
  }

  const copyQuestionToClipboard = (topic: any) => {
    if (topic) {
      try {
        const origin = "https://platform.bosscoderacademy.com"
        changeToastDetails(STR_SUCCESS, `Question URL Copied To Clipboard!`)
        changeToastVisibility(true)
        const str = `${origin}/editor/${topic}`
        navigator.clipboard.writeText(str)
      } catch (error) {
        changeToastDetails(STR_FAILURE, genError(error, "Unable to copy text to clipboard"))
        changeToastVisibility(true)
      }
    }
  }

  const handleTableData = () => {
    const rows = questions
      ?.filter(searchFilter())
      ?.filter(tagFilter())
      ?.filter(pageFilter())
      ?.map((q: any) => [
        q?.question_number,
        <div className="flex gap-2 items-center">
          <button className="ml-2" onClick={() => copyQuestionToClipboard(q?.topic)}>
            <CopyIcon className="w-5 h-5 text-gray-400" />
          </button>
          <p className="max-w-xs whitespace-pre-wrap">{q?.problem_name}</p>
        </div>,
        q?.likes,
        capitalize(q?.difficulty_level),
        capitalize(q?.tags?.join(", ")),
        <Button outlined onClick={() => navigate(q?.topic)}>
          Edit
        </Button>,
      ])

    setTableData((t) => ({ ...t, rows }))
  }

  const handleChangeSearchQuestion = (event: any) => {
    setSearchQuestion(event.target.value)
  }

  const handleChangeVisibleRows = (e: ChangeEvent<HTMLSelectElement>) => {
    setRowsVisible(parseInt(e.target.value))
    setPageNumber(1)
  }

  const handleChangePageNumber = (page: number) => {
    setPageNumber(page)
  }

  return {
    questions,
    setQuestions,
    searchQuestion,

    inputRef,
    tableData,
    toast,
    pageLoading,
    rowsVisible,
    pageNumber,
    options_rows,
    changeToastVisibility,
    handleChangeSearchQuestion,
    getQuestions,
    handleTableData,
    navigate,
    handleChangeVisibleRows,
    handleChangePageNumber,
    tagList,
    selectedTag,
    setSelectedTag,
  }
}
