import { Auth } from "domain/model/Auth"
import { MentorSession } from "domain/model/MentorSession"
import MentorSessionRepository from "domain/repository/Admin/MentorSessionRepository"

export interface CreateMentorSessionUseCase {
  invoke(auth: Auth, mentorSession: MentorSession): Promise<any>
}

export default class CreateMentorSession implements CreateMentorSessionUseCase {
  private repository: MentorSessionRepository

  constructor(repository: MentorSessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentorSession: MentorSession) {
    return await this.repository.createMentorSession(auth, mentorSession)
  }
}
