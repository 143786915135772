import {
  API_ADMIN,
  API_V4_CREATE_COMPANY,
  API_GET_COMPANY,
  API_UPDATE_COMPANY,
  API_DELETE_COMPANY,
  API_COMPANY,
} from "core/constants/strings"
import pn from "core/utils/pn"
import { Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { Company, EditCompany } from "domain/model/Questions"
import CompanyDataSource from "data/dataSource/Admin/CompanyDataSource"
import AdminLoginDataSource from "data/dataSource/Admin/AdminLoginDataSource"
import { email } from "domain/model/AdminLogin"
const server = new Server()

export class AdminLoginAPIDataSourceImpl implements AdminLoginDataSource {
  async getAdminLogin(email: string | null): Promise<any> {
    try {
      const response = await server.post(pn("verify_admin"), {
        email: email,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
