import React from "react"
import { STR_CREDENTIALS_SEND, STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { AuthRepositoryImpl } from "data/repository/Common/AuthRepositoryImpl"
import AuthAPIDataSourceImpl from "data/API/Common/AuthAPIDataSourceImpl"
import { useNavigate } from "react-router-dom"
import delay from "core/utils/delay"
import useToast from "core/hooks/useToast"
import { ChangeEvent, useRef, useState } from "react"
import { isEmail } from "core/utils/validator"
import { useAuth } from "core/context/auth"
import { redirectPath } from "core/lib/utils"
import { useApp } from "core/context/app"
import { ForgotPassword } from "domain/useCase/Admin/Auth/ForgotPassword"

export default function ForgotPasswordViewModel() {
  const { auth, login } = useAuth()
  const { student } = useApp()
  const [email, setEmail] = React.useState<string>("")
  const [isResetting, setIsResetting] = React.useState<boolean>(false)

  const navigate = useNavigate()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [user, setUser] = useState<{
    email: string
    oldPassword: string
    newPassword: string
    reTypePassword: string
  }>({ email: student?.email, oldPassword: "", newPassword: "", reTypePassword: "" })
  const [loading, setLoading] = useState<boolean>(false)
  const [emailValidity, setEmailValidity] = useState({ status: false, message: "" })
  const [passwordPageView, setPasswordPageView] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const forgotPasswordUseCase = new ForgotPassword(new AuthRepositoryImpl(new AuthAPIDataSourceImpl()))

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUser = { ...user, [e.target.name]: e.target.value }
    setUser(newUser)
  }

  const checkEmailValidity = () => {
    const isValid = isEmail(email)
    setEmailValidity({
      status: isValid,
      message: isValid ? "Good to go!" : "Please enter a valid email address!",
    })
  }

  const handleEmailBlur = () => {
    checkEmailValidity()
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value.trim())
  }

  async function forgotPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsResetting(true)
    if (!email) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please enter your email")
      return
    }
    const isValid = isEmail(email)
    try {
      if (isValid) {
        const response = await forgotPasswordUseCase.invoke(email)
        if (response?.success) {
          changeToastVisibility(true)
          changeToastDetails(STR_SUCCESS, STR_CREDENTIALS_SEND)
        } else {
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, "Something went wrong")
        }
        setIsResetting(false)
        await delay(3000)
        navigate("/login")
      } else {
        setIsResetting(false)
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Please enter a valid Email")
      }
    } catch (error) {
      return error
    }
  }

  return {
    email,
    toast,
    isResetting,
    handleEmailChange,
    forgotPassword,
    changeToastVisibility,
    auth,
    user,
    loading,
    emailValidity,
    inputRef,
    handleInputChange,
    changeToastDetails,
    redirectToPath: redirectPath,
    handleEmailBlur,
    navigate,
    passwordPageView,
    setPasswordPageView,
    checkEmailValidity,
  }
}
