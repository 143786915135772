import { Auth } from "domain/model/Auth"
import { API_V3_ADMIN, API_GET_ALL_FEEDBACK } from "core/constants/strings"
import FeatureFeedbackDataSource from "data/dataSource/Admin/FeatureFeedbackDataSource"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { Feedback } from "domain/model/NewFeedback"
const server = new Server()

export class FeatureFeedbackAPIDataSourceImpl implements FeatureFeedbackDataSource {
  async getAllFeedback(auth: Auth): Promise<Feedback[]> {
    try {
      const response = await server.get(pn(API_V3_ADMIN, API_GET_ALL_FEEDBACK), {
        Authorization: `Bearer ${auth.id_token}`,
      })

      if (response.data?.feedback) {
        return response.data.feedback
      } else {
        return []
      }
    } catch (error) {
      console.error("Error fetching all feedback:", error)
      return []
    }
  }
}
