import { Route, Routes } from "react-router-dom"
import ProtectedRoute from "./ProtectedRoute"
import routes from "./routes"

export default function RouteSwitcher() {
  const protectedRoutes = routes.filter((route) => !("isProtected" in route))
  const otherRoutes = routes.filter((route) => "isProtected" in route && !route.isProtected)

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        {protectedRoutes.map(({ path, component: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      {otherRoutes.map(({ path, component: Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
    </Routes>
  )
}
