import { getAPI, postAPI, Server } from "core/utils/axios"
import SheetsDataSource from "data/dataSource/Admin/SheetsDataSource"
import pn, { query } from "core/utils/pn"
import {
  API_GET_BATCH_STUDENT_DETAILS,
  API_GET_BATCH_YEAR_DATA,
  API_GET_DOUBT_RAISED_STATS,
  API_GET_MENTOR_LIST_BATCH,
  API_GET_MENTOR_INVOICES,
  API_GET_INSTRUCTOR_INVOICES,
  API_GET_MENTORINSTRUCTOR_INVOICES,
  API_GET_BATCH_CERTIFICATES,
  API_UPDATE_BATCH_DATA,
  API_V4_ADMIN,
  API_ALLOT_MENTORS,
  API_GET_CLASS_FEEDBACK_BY_INSTRUCTOR,
  API_GET_TEMP_MENTOR_ANALYSIS,
} from "core/constants/strings"
import { Auth } from "domain/model/Auth"

const server = new Server()

export class SheetsAPIDataSourceImpl implements SheetsDataSource {
  async getBatchStudentDetails(data: any): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_BATCH_STUDENT_DETAILS), {
          batch: data.batch,
          from_date: data.fromDate,
          to_date: data.toDate,
          activityStatus: data.activityStatus,
        })
      )

      return response
    } catch (error) {
      return error
    }
  }
  async getBatchYearData(auth: Auth, batch: string, month: any, year: string): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_BATCH_YEAR_DATA), {
          id_token: auth?.id_token,
          batch: batch,
          month: month,
          year: year,
        })
      )
      return response
    } catch (error) {
      return error
    }
  }
  async getMentorListData(auth: Auth, batch: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_LIST_BATCH), {
        id_token: auth?.id_token,
        batch: batch,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getBatchCertificates(auth: Auth, batch: string, courseName: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_BATCH_CERTIFICATES), {
        id_token: auth?.id_token,
        batch: batch,
        course_name: courseName,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorInvoices(auth: Auth, year: string, month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTOR_INVOICES), {
        id_token: auth?.id_token,
        year: year,
        month: month,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getInstructorInvoices(auth: Auth, year: string, month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_INSTRUCTOR_INVOICES), {
        id_token: auth?.id_token,
        year: year,
        month: month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorInstructorInvoices(auth: Auth, year: string, month: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MENTORINSTRUCTOR_INVOICES), {
        id_token: auth?.id_token,
        year: year,
        month: month,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getDoubtRaisedStats(data: any): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_DOUBT_RAISED_STATS), {
          from_date: data.fromDate2,
          to_date: data.toDate2,
        })
      )
      return response
    } catch (error) {
      return error
    }
  }
  async updateBatchData(auth: Auth, batch: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_BATCH_DATA), {
        id_token: auth?.id_token,
        batch: batch,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async allotMentors(auth: Auth, batch: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_ALLOT_MENTORS), {
        id_token: auth?.id_token,
        batch: batch,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getClassFeedbackByInstructor(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_CLASS_FEEDBACK_BY_INSTRUCTOR), {
        id_token: auth?.id_token,
        data: data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getTempMentorAnalysis(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_TEMP_MENTOR_ANALYSIS), {
        id_token: auth?.id_token,
        data: data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
