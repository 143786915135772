import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import { useEffect } from "react"
import Toast from "core/components/Toast"
import useUpdateClassFormViewModel from "./UpdateClassFormViewModel"
import { format } from "date-fns"
import { AddIcon, CrossIcon } from "core/constants/svgs"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import CustomModel from "./CustomModel"
import { XIcon } from "lucide-react"

export default function UpdateClassForm(props: any) {
  const {
    loading2,
    reloadClass,
    selectBatch,
    currentSelectAssignmentsQuestions,
    currentSelectBatch,
    classLink,
    selectInstructor,
    selectModuleId,
    notes_link,
    selectAssigmentsQuestions,
    selectHomeworkQuestions,
    currentSelectHomeworkQuestions,
    selectPracticalQuestions,
    currentSelectPracticalQuestions,
    selectWarmupQuestions,
    currentSelectWarmupQuestions,
    session_timestamp,
    selectTrack,
    videoId,
    notesSummaryLink,
    videoSecret,
    loading,
    toast,
    compensationStatus,
    moduleId,
    TrackId,
    othersClassName,
    moduleClassesList,
    selectClassName,
    enableOthersClass,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    assignmentSolutionActivationTime,
    assignmentSolutionLink,
    assignmentCoins,
    model,
    status,
    updateClassWithoutIcs,
    setReloadClass,
    setcurrentSelectBatch,
    setClassLink,
    setSelectInstructor,
    setSelectModuleId,
    setNotesLink,
    setSelectAssigmentsQuestions,
    setSelectHomeworkQuestions,
    setSelectPracticalQuestions,
    setselectWarmupQuestions,
    setSessionTimestamp,
    setSelectTrack,
    setVideoId,
    setNotesSummaryLink,
    setVideoSecret,
    getAllTracks,
    setMasterClassType,
    handleMasterClassType,
    handleNewDuration,
    handleSessionDuration,
    handleOtherSessionDuration,
    setSelectClassName,
    getModuleClasses,
    getModuleBasedTrack,
    setCompensationStatus,
    handleOthersClass,
    handleCompensationStatus,
    handleRemoveBatch,
    setSessionId,
    setSelectBatch,
    handleAssignmentChange,
    handleAddAssignmentQuestions,
    handleSelectHomeworkChange,
    handleAddHomeworkQuestions,
    handlePracticalChange,
    handleAddPracticalQuestions,
    handleWarmupChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeSessionTimestamp,
    handleChangeTrack,
    handleChangeVideoId,
    handleChangeVideoSecreat,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    setAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    setAssignmentSolutionLink,
    removeAssignmentQuestion,
    handleAddWarmupQuestions,
    updateClass,
    setPreviousTimestamp,
    changeToastVisibility,
    setAssignmentCoins,
    handleChangeAssignmentCoins,
    handleStatusChange,
    setModel,
    handleModalClose,
    setStatus,
    firstReminderMessage,
    secondReminderMessage,
    setFirstReminderMessage,
    setSecondReminderMessage,
    removeHomeworkQuestion,
    removePracticeQuestion,
    removeWarmupQestions,
  } = useUpdateClassFormViewModel()

  useEffect(() => {
    setcurrentSelectBatch(props.currentSelectBatch)
    setSelectBatch(props.currentSelectBatch)
    setSelectClassName(props.chapterNames)
    setClassLink(props.classLink)
    setSelectInstructor(props.selectInstructor)
    getModuleBasedTrack(props.selectTrack, 0)
    getModuleClasses(props.selectModuleId, props.chapterNames)
    setSelectModuleId(props.selectModuleId)
    setNotesLink(props.notes_link)
    setSelectAssigmentsQuestions(props.selectAssigmentsQuestions)
    setSelectHomeworkQuestions(props.selectHomeworkQuestions)
    setSelectPracticalQuestions(props.selectPracticalQuestions)
    setselectWarmupQuestions(props.selectWarmupQuestions)
    setMasterClassType(props.master_class_type)
    setSessionTimestamp(format(props.session_timestamp * 1000, "yyyy-MM-dd'T'HH:mm"))
    handleNewDuration(props.session_duration.toString(), DurationArray)
    setSelectTrack(props.selectTrack)
    setVideoId(props.videoId)
    setVideoSecret(props.videoSecret)
    setNotesSummaryLink(props.notesSummaryLink)
    setSessionId(props.session_id)
    setPreviousTimestamp(props.session_timestamp)
    setAssignmentSolutionLink(props.assignmentSolutionLink)
    setAssignmentSolutionActivationTime(props.assignmentSolutionActivationTime)
    setCompensationStatus(props.compensation_status ?? "Hourly Class")
    getAllTracks()
    setAssignmentCoins(props.assignmentCoins)
    setStatus(props.status)
    setFirstReminderMessage(props.first_reminder)
    setSecondReminderMessage(props.second_reminder)
  }, [
    props.currentSelectBatch,
    props.chapterNames,
    props.classLink,
    props.selectInstructor,
    props.selectModuleId,
    props.notes_link,
    props.selectAssigmentsQuestions,
    props.selectHomeworkQuestions,
    props.selectPracticalQuestions,
    props.selectWarmupQuestions,
    props.session_timestamp,
    props.selectTrack,
    props.videoId,
    props.videoSecret,
    props.notesSummaryLink,
    props.session_id,
    props.assignmentCoins,
    props.first_reminder,
    props.second_reminder,
  ])

  useEffect(() => {
    if (reloadClass) {
      props.setUpcomingClassBool(!props.upcomingClassBool)
      setReloadClass(!reloadClass)
    }
  }, [reloadClass])

  const compensationStatusArray = ["Hourly Class", "Module Based Class"]
  const masterClassTypeArray = ["Software Engineering", "Data Scientist", "Both"]
  const DurationArray = [1, 1.5, 2, 2.5, 3, "other"]

  const STATUSARRAY: string[] = [
    "Scheduled Class",
    "Class Taken",
    "Cancelled On Time",
    "Substituted Class",
    "Cancelled Post Time",
    "Cancelled Last Min",
  ]

  return (
    <div className="space-y-4">
      <CustomModel isOpen={model} heading={"Warning!"} onClose={handleModalClose}>
        <div className="flex flex-col gap-16">
          <p>
            Editing the details of this session may impact the instructor's compensation. To make updates without
            affecting their compensation, please delete this session and create a new one with the desired details.
          </p>
          <div className="flex flex-row gap-8">
            <Button onClick={handleModalClose} failure>
              Discard Changes
            </Button>
            <Button onClick={updateClass}>Edit Class</Button>
          </div>
        </div>
      </CustomModel>
      <p className="text-xl font-medium">Edit - {props.chapterNames}</p>
      <ul className="flex w-full flex-col border p-2">
        {selectBatch?.map((batch: string, i: number) => (
          <li key={batch + i} className="flex items-center gap-1 p-0.5">
            {batch}
            <button
              className="rounded-sm bg-new-solid-black text-new-solid-white"
              onClick={() => handleRemoveBatch(batch)}
            >
              <CrossIcon className="h-4 w-4" />
            </button>
          </li>
        ))}
        {selectBatch?.length === 0 && <li className="flex items-center gap-1 p-0.5">No Batch Selected</li>}
      </ul>
      <StandaredSelect
        required
        options={props.batch}
        placeholder="Select Batch"
        onChange={handleBatchChange}
        value={currentSelectBatch}
      />
      <StandaredSelect
        required
        options={TrackId}
        placeholder="Track"
        onChange={handleChangeTrack}
        value={selectTrack}
      />
      {selectTrack === "master_class" && (
        <StandaredSelect
          required
          options={masterClassTypeArray}
          placeholder="Master Class Type"
          onChange={handleMasterClassType}
          value={masterClassType}
        />
      )}
      <StandaredSelect
        required
        options={moduleId}
        placeholder="Module ID"
        onChange={handleChangeModuleId}
        value={selectModuleId}
      />
      <select
        className="w-full cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent"
        onChange={handleChangeChapterName}
        value={selectClassName}
      >
        {!selectClassName && (
          <option value="" className="bg-neutral-50 text-neutral-500">
            Class Name
          </option>
        )}
        {moduleClassesList?.map((header: any, i: number) => (
          <option key={header.topic + i} className="bg-neutral-50 text-neutral-500" value={header.topic}>
            {header.topic}
          </option>
        ))}
        <option value="others">others</option>
      </select>
      {enableOthersClass && (
        <Input type={STR_TEXT_FIELD} value={othersClassName} onChange={handleOthersClass} placeholder="Class Name" />
      )}
      <Input type={STR_TEXT_FIELD} value={classLink} onChange={handleChangeClassLink} placeholder="Class Link" />
      <StandaredSelect
        required
        options={props.instructor?.map((c: { email: string }) => c.email)}
        placeholder="Instructor"
        onChange={handleChangeInstructor}
        value={selectInstructor}
      />
      <StandaredSelect
        required
        options={compensationStatusArray}
        value={compensationStatus}
        onChange={handleCompensationStatus}
        placeholder="Compensation Status"
      />

      {/* Assignment */}
      <SelectedProblems type="assignment" list={selectAssigmentsQuestions} removeFn={removeAssignmentQuestion} />
      <form className=" flex w-full justify-between gap-4" onSubmit={handleAddAssignmentQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Assignments"
          onChange={handleAssignmentChange}
          value={currentSelectAssignmentsQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>

      {/* Homework */}
      <SelectedProblems type="homework" list={selectHomeworkQuestions} removeFn={removeHomeworkQuestion} />
      <form className="flex w-full justify-between gap-4" onSubmit={handleAddHomeworkQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Homework"
          onChange={handleSelectHomeworkChange}
          value={currentSelectHomeworkQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>

      {/* Practice */}
      <SelectedProblems type="practice" list={selectPracticalQuestions} removeFn={removePracticeQuestion} />
      <form className="flex w-full justify-between gap-4" onSubmit={handleAddPracticalQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Practice Questions"
          onChange={handlePracticalChange}
          value={currentSelectPracticalQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>

      {/* Warmup */}
      <SelectedProblems type="warmup" list={selectWarmupQuestions} removeFn={removeWarmupQestions} />
      <form className="flex w-full justify-between gap-4" onSubmit={handleAddWarmupQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Warmup Questions"
          onChange={handleWarmupChange}
          value={currentSelectWarmupQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>

      <Input
        type={STR_DATE_AND_TIME_FIELD}
        value={session_timestamp}
        onChange={handleChangeSessionTimestamp}
        placeholder="Date && Time"
      />
      <StandaredSelect
        required
        options={DurationArray}
        value={sessionDuration}
        onChange={handleSessionDuration}
        placeholder="Session Duration (in hrs)"
      />
      {sessionDuration == "other" && (
        <Input
          required
          type="number"
          value={otherSessionDuration}
          onChange={handleOtherSessionDuration}
          placeholder="Session Duration (in hrs)"
        />
      )}
      <Input type={STR_TEXT_FIELD} value={videoId} onChange={handleChangeVideoId} placeholder="Video ID" />
      <Input type={STR_TEXT_FIELD} value={videoSecret} onChange={handleChangeVideoSecreat} placeholder="Video Secret" />
      <Input type={STR_TEXT_FIELD} value={notes_link} onChange={handleChangeNotesLink} placeholder="Notes Link" />
      <Input
        type={STR_TEXT_FIELD}
        value={notesSummaryLink}
        onChange={handleChangeNotesSummaryLink}
        placeholder="Notes Summary Link"
      />
      <Input
        type={STR_TEXT_FIELD}
        value={assignmentSolutionLink}
        onChange={handleChangeAssignmentSolutionLink}
        placeholder="Assignment Solution Link"
      />
      <Input
        type="number"
        max="100"
        min="0"
        placeholder="Assignment Solution Activation Time (in Days)"
        value={assignmentSolutionActivationTime}
        onChange={handleChangeAssignmentSolutionActivationTime}
      />
      <StandaredSelect
        required
        options={STATUSARRAY}
        value={status}
        onChange={handleStatusChange}
        placeholder="Status"
      />
      <Input
        type="number"
        max="100"
        min="0"
        placeholder="Assignment Coins"
        value={assignmentCoins}
        onChange={handleChangeAssignmentCoins}
      />
      <Input
        type="text"
        value={firstReminderMessage}
        placeholder="No first reminder message"
        onChange={(e) => setFirstReminderMessage(e.target.value)}
      />
      <Input
        type="text"
        value={secondReminderMessage}
        placeholder="No second reminder message"
        onChange={(e) => setSecondReminderMessage(e.target.value)}
      />
      <div className="flex flex-row gap-8">
        <Button failure onClick={() => setModel(true)}>
          {loading ? "Updating..." : "Update Class"}
        </Button>
        <Button onClick={updateClassWithoutIcs}>{loading2 ? "Editing..." : "Edit Details"}</Button>
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}

const SelectedProblems = ({
  type,
  list,
  removeFn,
}: {
  type: string
  list: string[]
  removeFn: (i: number) => void
}) => {
  return (
    <ul className="flex w-full flex-wrap gap-2 border p-2">
      <ConditionalLoader
        isLoading={isEmpty(list)}
        loader={<em className="text-xs text-new-neutral-light">No {type} question added</em>}
      >
        {list?.map((item: string, i: number) => (
          <li key={item + i} className="bg-neutral-100 leading-[14px] p-1 pl-2 pr-1 rounded flex items-center gap-2">
            {item}
            <button className="p-px rounded bg-black text-white" onClick={() => removeFn(i)}>
              <XIcon size={14} />
            </button>
          </li>
        ))}
      </ConditionalLoader>
    </ul>
  )
}
