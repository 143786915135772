import { cn } from "core/lib/utils"
import React from "react"

export default function ConfirmationPopUpDelete({ isOpen, children, width, height }: any) {
  if (!isOpen) return null
  return (
    <div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.75)]">
          <div
            className={cn(
              "relative mx-auto w-full rounded-lg bg-new-solid-white shadow-lg",
              width ? width : "max-w-2xl"
            )}
          >
            <div className="relative">
              <div className="relative h-[166px]">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
