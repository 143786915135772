import { useEffect, useState } from "react"
import { Button } from "core/components/v2/Button"
import { STR_CANCEL } from "core/constants/strings"
import { CrossIcon, AwardIcon } from "core/constants/svgs"
import StandardSelect from "./FeedbackStandardSelect"
import AwardEmpty from "assets/svgs/AwardEmpty"
import Input from "core/components/Input"
import { LoaderIcon } from "lucide-react"
import delay from "core/utils/delay"

const questions = [
  "Was the content of the lecture clear and easy to understand?",
  "Did the lecture cover the necessary topics adequately?",
  "How engaging was the speaker's presentation style?",
  "Were the key points and concepts explained clearly?",
  "How satisfied are you with the recorded lecture?",
]

export default function CourseSessionFeedback({
  onSubmit,
  className,
  classDetails,
  isExternal,
  isRecordedFeedbackVisible,
  setIsRecordedFeedbackVisible,
  feedbackData,
  feedbackGiven,
}: any) {
  const [rating, setRating] = useState<{ title: string; star: number }[]>([
    {
      title: "Was the content of the lecture clear and easy to understand?",
      star: 0,
    },
    {
      title: "Did the lecture cover the necessary topics adequately?",
      star: 0,
    },
    {
      title: "How engaging was the speaker's presentation style?",
      star: 0,
    },
    {
      title: "Were the key points and concepts explained clearly?",
      star: 0,
    },
    {
      title: "How satisfied are you with the recorded lecture?",
      star: 0,
    },
  ])
  const [comment, setComment] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")

  const handleSubmit = async () => {
    setLoading(true)

    await onSubmit({ rating, status, comment, email })
    await delay(1000)

    setLoading(false)
  }

  const handleClose = () => {
    setIsRecordedFeedbackVisible(false)
  }

  const handleClick = (questionIndex: number, starIndex: number) => {
    const newRating = [...rating]
    newRating[questionIndex].star = starIndex + 1
    setRating(newRating)
  }

  useEffect(() => {
    if (status === "Yes") {
      setRating([
        {
          title: "Did the class start on time?",
          star: 0,
        },
        {
          title: "Was the session interactive?",
          star: 0,
        },
        {
          title: "Were all your questions addressed?",
          star: 0,
        },
        {
          title: "Please rate the speaker on knowledge",
          star: 0,
        },
        {
          title: "Did you find the content relevant?",
          star: 0,
        },
        {
          title: "Please rate the instructor on teaching skills",
          star: 0,
        },
      ])
    } else {
      setRating([
        {
          title: "Please rate the speaker on knowledge",
          star: 0,
        },
        {
          title: "Did you find the content relevant?",
          star: 0,
        },
        {
          title: "How would you rate the pace of the lecture?",
          star: 0,
        },
        {
          title: "How helpful were the lecture notes or accompanying materials?",
          star: 0,
        },
        {
          title: "Please rate the instructor on teaching skills",
          star: 0,
        },
        {
          title: "Did the lecture effectively meet your learning objectives for this topic?",
          star: 0,
        },
      ])
    }
  }, [status])

  useEffect(() => {
    if (feedbackData) {
      setComment(feedbackData?.comment || "")
      setStatus(feedbackData?.is_attended_live)
    }
  }, [feedbackData])

  return (
    <div className={`${isRecordedFeedbackVisible ? "" : "hidden"} ${className || ""}`}>
      <div className={`pt-6 pr-6 pl-6 flex flex-col gap-[24px] ${isExternal ? "max-w-[500px]" : ""}`}>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h2 className="text-[#03249A] font-inter text-[16px] font-semibold leading-normal">User Class Feedback</h2>
            {!isExternal ? (
              <p className="text-[16px] max-w-[500px] font-inter font-medium">
                Provide your valuable feedback to increase class quality.
              </p>
            ) : (
              <p className="text-[16px] font-inter font-medium">
                {classDetails?.name} | {classDetails?.instructor_name}
              </p>
            )}
          </div>
          {!isExternal && (
            <div>
              <button onClick={handleClose}>
                <CrossIcon className="h-6 w-6 text-new-neutral-dark cursor-pointer" />
              </button>
            </div>
          )}
        </div>
        {isExternal && (
          <div className="flex flex-row items-center justify-between bg-[#f9f9f9] px-[12px] py-[16px] rounded-[8px]">
            <div className="after-star text-[14px] font-inter font-medium">User Email ID?</div>
            <div>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="bg-white h-[33px]"
                placeholder="Type your email here!"
                type="email"
              ></Input>
            </div>
          </div>
        )}

        <div className="flex flex-row justify-between items-center">
          <p className="text-[#333] font-inter text-[14px] font-semibold leading-normal after-star">
            Did you attend the live class?
          </p>
          <StandardSelect
            disabled={feedbackGiven}
            dimensions="w-[146px]"
            className="rounded-md border border-[#d3d3d3] "
            placeholder={"Yes / No"}
            name="status"
            value={status}
            onChange={setStatus}
            options={["Yes", "No"]}
            required
          />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
          className="font-medium text-new-neutral-dark mt-[8px]"
        >
          <div className="flex flex-col gap-4">
            {rating.map((question, questionIndex) => (
              <div key={questionIndex} className="flex items-center justify-between">
                <p className="text-[#646464] font-inter text-[14px] font-medium leading-normal after-star max-w-[328px]">
                  {question.title}
                </p>
                <div className="flex gap-[8px] mt-[8px]">
                  {Array.from({ length: 5 }, (_, starIndex) => (
                    <div
                      key={starIndex}
                      onClick={() => {
                        if (!feedbackGiven) handleClick(questionIndex, starIndex)
                      }}
                      className={`cursor-pointer ${feedbackGiven ? "cursor-default" : ""}`}
                    >
                      {feedbackGiven ? (
                        starIndex < feedbackData?.rating?.[questionIndex]?.star ? (
                          <AwardIcon className="size-[16px]" />
                        ) : (
                          <AwardEmpty className="size-[16px]" />
                        )
                      ) : starIndex < question.star ? (
                        <AwardIcon className="size-[16px]" />
                      ) : (
                        <AwardEmpty className="size-[16px]" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#333] font-inter text-[14px] font-semibold leading-normal mt-[24px]">
              Customized Feedback
            </p>
            {feedbackGiven === true ? (
              <div className="bg-[#f9f9f9] p-[10px]">{comment}</div>
            ) : (
              <div className="w-full">
                <textarea
                  value={comment}
                  onChange={(e) => {
                    if (e.target.value.length <= 400) setComment(e.target.value)
                  }}
                  className="h-32 w-full rounded border border-new-neutral-light bg-transparent p-2 focus:border-new-neutral-light focus:outline-none"
                  placeholder="Type your suggestion here..."
                />
              </div>
            )}
          </div>
        </form>
      </div>
      {feedbackGiven ? (
        <div className="bg-[#f9f9f9] flex gap-[16px] justify-end px-[24px] py-[12px] mt-[40px] rounded-b-[4px]">
          <Button onClick={handleClose} variant={"primary"} className="w-[100px]">
            Close
          </Button>
        </div>
      ) : (
        <div className="bg-[#f9f9f9] flex gap-[16px] justify-end px-[24px] py-[12px] mt-[40px] rounded-b-[4px]">
          {!isExternal && (
            <Button
              onClick={handleClose}
              variant={"ternary"}
              className="w-[100px] rounded-md border border-[#D3D3D3] bg-white shadow-[inset_-1px_1px_2px_rgba(34,41,48,0.05)]
"
            >
              {STR_CANCEL}
            </Button>
          )}

          <Button
            disabled={
              !status ||
              !rating ||
              (isExternal && !email) ||
              rating.some((item) => item.star === 0) ||
              !comment ||
              loading
            }
            onClick={handleSubmit}
            variant={"primary"}
            className="w-fit inline-flex items-center gap-2"
          >
            {loading && <LoaderIcon className="animate-spin" />} Submit Feedback
          </Button>
        </div>
      )}
    </div>
  )
}
