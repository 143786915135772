import axios from "axios" // Import axios if not globally available
import { Auth } from "domain/model/Auth"
import { InvoiceDetailRepository } from "domain/repository/Mentor/InvoiceDetailRepository"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_MENTOR, INSTRUCTOR, MONTH_INVOICE_DATA } from "core/constants/strings"

const server = new Server()

export class GetInvoiceDetailImpl implements InvoiceDetailRepository {
  async getMentorInvoiceDetails(auth: Auth, time_given: string): Promise<any> {
    // try {
    //   const response = await axios.get("http://localhost:8000/mentor/month-invoice-data", {
    //     headers: {
    //       Authorization: `Bearer ${auth.id_token}`,
    //     },
    //     params: {
    //       time_given,
    //     },
    //   })
    //   const responseData = response.data
    //   console.log(responseData)
    //   return responseData
    // } catch (error) {
    //   console.error("Error fetching mentor details:", error)
    //   throw error
    // }
  }

  async getInstructorInvoiceDetails(auth: Auth, time_given: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_MENTOR, INSTRUCTOR, MONTH_INVOICE_DATA),
        { time_given },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      const responseData = response.data
      return responseData
    } catch (error) {
      console.error("Error fetching instructor invoice details:", error)
      throw error
    }
  }
}
