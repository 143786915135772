import { useEffect, useState } from "react"
import usePaymentMethodsViewModel from "./PaymentMethodsViewModel"
import { isEmpty } from "core/utils/misc"
import Discount from "assets/svgs/payments/Discount"
import Priority from "assets/svgs/payments/Priority"
import Session from "assets/svgs/payments/Session"
import Goodies from "assets/svgs/payments/Goodies"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import EarlyPaymentModel from "presentation/Student/Payment/component/EarlyPaymentModel"
import TabGroup from "core/components/payment-method/TabGroup"
import MonthGroupTab from "core/components/payment-method/MonthTabGroup"
import LineBottomDeep from "core/components/payment-method/LineBottomDeep"
import ScratchDiv from "presentation/Student/Payment/component/ScratchDiv"
import card_1 from "assets/images/payment-method/card_1.png"
import card_2 from "assets/images/payment-method/card_2.png"
import card_3 from "assets/images/payment-method/card_3.png"
import scratch1 from "assets/images/payment-method/scratch1.png"
import scratch2 from "assets/images/payment-method/scratch2.png"
import scratch3 from "assets/images/payment-method/scratch3.png"
import card_popup1 from "assets/images/payment-method/card-popup1.png"
import card_popup2 from "assets/images/payment-method/card-popup2.png"
import card_popup3 from "assets/images/payment-method/card-popup3.png"
import expired_card1 from "assets/images/payment-method/expired_card1.png"
import expired_card2 from "assets/images/payment-method/expired_card2.png"
import expired_card3 from "assets/images/payment-method/expired_card3.png"
import { Head } from "core/components/seo"
import Checkbox from "assets/svgs/v2/Checkbox"
import { cn } from "core/lib/utils"
import { Button } from "core/components/v2/Button"
import ResultModal from "../Assessment/components/FeesBreakdownModal"
import FeesBreakdownModal from "../Assessment/components/FeesBreakdownModal"
import UploadDocumentModal from "../Assessment/components/UploadDocumentModal"
import AdminTable2 from "core/components/new/AdminTable2"
import TickCircle from "../PaymentOptions/assets/TickCircle"
import CrossCircle from "../PaymentOptions/assets/CrossCircle"
import BlankCircle from "../PaymentOptions/assets/BlankCircle"
import { STR_PAY_NOW } from "core/constants/strings"

interface ProgressItem {
  name: string
  status: number
  note: string
}

export default function PaymentMethodsView() {
  const {
    student,
    paymentDiscount,
    isEV,
    isDSML,
    isAccelerator,
    payableMonth,
    isAC2,
    isAC,
    scratchCardDetails,
    loading,
    setIsAC2,
    setIsAC,
    setIsAccelerator,
    setIsEV,
    setIsDSML,
    setPayableMonth,
    fetchPaymentDetails,
    parseDate,
    fetchScratchCardDetails,
    setScratchCardDetails,
    fetchPaymentOptionDetails,
    studentEMI,
    paymentOptionDiscount,
    payDataAll,
    getStudentPaymentDetailsAll,
    calculateOneshotDiscount,
    payData,
    getStudentPaymentDetails,
    isCompletelyPaid,
    paymentDocuments,
    getPaymentDocuments,
    uploadPaymentDocument,
    deletePaymentDocument,
    getStudentEMI,
    studentTransactionTableData,
    toast,
    changeToastDetails,
    changeToastVisibility,
    createStudentEMI,
    activeInstallment,
    formatDueDateWithOutGMT,
    handleGeneratePaylink,
    showPayNow,
    paymentLink,
    activeDirectPaymentButtonType,
    setActiveDirectPaymentButtonType,
    disableInitiateNow,
    // fetchPaymentMethod,
    // calculateAmountPaid
  } = usePaymentMethodsViewModel()

  useEffect(() => {
    if (payData && payData.length >= 3) {
      setActiveDirectPaymentButtonType("Direct Installments")
    }
  }, [payData])

  useEffect(() => {
    getPaymentDocuments()

    if (studentEMI?.is_submitted === true) {
      setSelectedEMIOptionToChoose(studentEMI.duration_index)
    }
  }, [studentEMI])

  const amount = paymentOptionDiscount?.fees_after_discount
  const [activeButtonType, setActiveButtonType] = useState<any>("Direct payment")

  const [paymentBreakdownresult, setPaymentBreakdownResult] = useState<{} | null>(null)
  const [uploadDocumentsResult, setUploadDocumentsResult] = useState<{} | null>(null)
  const [activeMonthType, setActiveMonthType] = useState<string>("")
  const [detailModel, setDetailModel] = useState(false)
  function changeActiveButtonType(index: number) {
    const tabType = ["Direct payment", "NBFC EMI"].filter(Boolean)[index]
    setActiveButtonType(tabType)
  }
  function changeActiveMonthType(button: string, i: number) {
    setActiveMonthType(button)
    // setSelectedEMIOptionToChoose(i)
    handleEMIOptionToChoose(i)
    handleEMIOptionToView(i)
  }

  const [interest, setInterest] = useState<number>(0)
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false)

  const [selectedEMIOptionToChoose, setSelectedEMIOptionToChoose] = useState<number>(0)
  const [selectedEMIOptionToView, setSelectedEMIOptionToView] = useState<number>(0)

  const handleEMIOptionToView = (index: number) => {
    if (selectedEMIOptionToChoose === -1) setSelectedEMIOptionToView(index)
  }

  const handleEMIOptionToChoose = (index: number) => {
    if (selectedEMIOptionToChoose === index) {
      setSelectedEMIOptionToChoose(-1)
      setAllowSubmit(false)
    } else {
      getPaymentDocuments()
      setSelectedEMIOptionToChoose(index)
    }
    setInterest(index)
    setSelectedEMIOptionToView(index)
  }

  const [allFilesStatus, setAllFilesStatus] = useState<boolean>(false)

  useEffect(() => {
    if (selectedEMIOptionToChoose !== -1) {
      setAllowSubmit(paymentDocuments?.some((file: any) => file.is_uploaded))

      if (studentEMI?.is_submitted === true) {
        setAllowSubmit(false)
      }
    } else setAllowSubmit(false)
    setAllFilesStatus(paymentDocuments?.every((file: any) => file.is_uploaded))
  }, [paymentDocuments])

  const months = [
    (isAC || isAccelerator) && 6,
    (isAC || isAccelerator) && 9,
    !(isAC || isAccelerator) && (isDSML ? [6, 9] : 6),
    isEV && 9,
    !(isAC || isAccelerator) && (isDSML || isEV ? 12 : 9),
    !(isDSML || isEV) && 12,
    !(isAC || isAccelerator) && !(isDSML || isEV || isAccelerator) && 15,
    (isDSML || isEV) && 15,
    (isDSML || isEV) && 18,
    (isDSML || isEV) && 24,
  ]
    .flat()
    .filter(Boolean)

  const ADDITIONAL_BENEFITS = [
    {
      icon: <Discount className="h-8 w-8" />,
      title: "Special Discount",
      description: `Receive an extra discount of <strong>${paymentDiscount?.discount_on_direct_pay}%</strong>.`,
    },
    {
      icon: <Priority className="h-8 w-8" />,
      title: "Priority mentor allotment",
      description: "Immediate/Priority mentor allotment — Limited mentor availability.",
    },
    {
      icon: <Session className="h-8 w-8" />,
      title: "Additional mentor session",
      description: "Recommended : This session helps in early goal setting.",
    },
    {
      icon: <Goodies className="h-8 w-8" />,
      title: "Exclusive Goodies",
      description: "Don't miss out on the chance for exclusive treats.",
    },
  ]

  const passButtons = [
    (isAC || isAccelerator) && "6 Months",
    (isAC || isAccelerator) && "9 Months",
    !(isAC || isAccelerator) && (isDSML ? "6 & 9 Months" : "6 Months"), // tab will always display
    isEV && "9 Months",
    !(isAC || isAccelerator) && (isDSML || isEV ? "12 Months" : "9 Months"),
    !(isDSML || isEV) && "12 Months",
    !(isAC || isAccelerator) && !(isDSML || isEV) && "15 Months",
    (isDSML || isEV) && "15 Months",
    (isDSML || isEV) && "18 Months",
    (isDSML || isEV) && "24 Months",
  ].filter(Boolean)
  const activeButtons = ["Direct payment", "NBFC EMI"].filter(Boolean)

  const activeDirectPaymentButtons = ["One Shot", "3 Months EMI"].filter(Boolean)

  useEffect(() => {
    getStudentEMI()
    fetchPaymentOptionDetails()
    getStudentPaymentDetails()
    getStudentPaymentDetailsAll()
    // fetchPaymentMethod()
    // calculateAmountPaid()
  }, [])

  useEffect(() => {
    if (true) {
      fetchPaymentDetails()
      fetchScratchCardDetails()
    }
  }, [])

  useEffect(() => {
    setActiveMonthType(passButtons[0] || "")
  }, [isDSML, isEV, isAccelerator, isAC2])

  useEffect(() => {
    if (student?.payable_start_month !== "") {
      setPayableMonth(parseDate(student?.payable_start_month))
    }
    const batch = student?.batch

    setIsDSML(batch?.includes("DSML") || student?.tracks.includes("data_engineer"))
    setIsEV(batch?.includes("EV"))
    setIsAccelerator(batch?.includes("Accelerator"))
    setIsAC2(batch?.includes("AC2"))
    setIsAC(batch?.startsWith("AC"))
    if (batch?.includes("B21") || (batch?.includes("DSML-Dec-") && payableMonth.length === 0)) {
      setPayableMonth("January")
    } else if (batch?.includes("B22")) {
      setPayableMonth("March")
    }

    if (batch?.includes("DSML-Jan")) {
      setPayableMonth("February")
    }
  }, [student?.email])

  function calculatePayableAmount() {
    if (payDataAll?.instalments?.length <= 2) {
      return paymentOptionDiscount?.fees_after_discount - calculateOneshotDiscount()
    } else {
      return paymentOptionDiscount?.fees_after_discount
    }
  }

  const [remaining_time, setRemainingTime] = useState({ hours: 0, minutes: 0, seconds: 0 })
  const [expired, setExpired] = useState(true)
  const [offerDate, setOfferDate] = useState("")

  const interestRate =
    isAC || isAccelerator
      ? [0, 6.9, 8.9]
      : student?.batch[0] === "B"
        ? student?.batch?.includes("Accelerator")
          ? [0, 1.9, 8.9]
          : [0, 1.9, 8.9, 11.9]
        : student?.batch[0] === "D"
          ? [0, 0, 8.9, 11.9, 14.9, 16.9]
          : [0, 1.9, 8.9, 11.9, 14.9, 16.9]

  useEffect(() => {
    let intervalId: any

    const updateRemainingTime = () => {
      if (!student?.is_payment_done && student?.walk_through_date) {
        const walk_through_date = student?.walk_through_date

        const walk_through_date_in_millis = walk_through_date * 1000

        const current_date = new Date()

        const future_date_in_millis = walk_through_date_in_millis + 66.5 * 60 * 60 * 1000

        if (current_date.getTime() > future_date_in_millis) {
          setExpired(true)
          setRemainingTime({ hours: 0, minutes: 0, seconds: 0 })
          return true
        } else {
          if (expired) setExpired(false)
          const remaining_time_in_millis = future_date_in_millis - current_date.getTime()
          const hours = Math.floor(remaining_time_in_millis / (1000 * 60 * 60))
          const minutes = Math.floor((remaining_time_in_millis % (1000 * 60 * 60)) / (1000 * 60))
          const seconds = Math.floor((remaining_time_in_millis % (1000 * 60)) / 1000)
          setRemainingTime({ hours, minutes, seconds })
          return false
        }
      }
    }

    updateRemainingTime()

    intervalId = setInterval(() => {
      if (updateRemainingTime()) {
        clearInterval(intervalId)
      }
    }, 1000) // 1 seconds

    return () => clearInterval(intervalId)
  }, [student?.email])

  const handleClickPay = (e: any) => {
    e.preventDefault()
    const url = paymentLink

    if (url) {
      window.open(url, "_blank")
    } else {
      console.error("Invalid URL")
    }
  }

  // Function to format the date as "15th Aug 2024"
  const formatDate = (date: Date) => {
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "short" })
    const year = date.getFullYear()

    // Get the correct suffix for the day
    const daySuffix = (day: number) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }

    return `${day}${daySuffix(day)} ${month} ${year}`
  }

  useEffect(() => {
    if (!student?.is_payment_done && student?.walk_through_date) {
      // Declare intervalId here so it can be accessed within the checkExpiration function
      let intervalId: any

      const checkExpiration = () => {
        const walk_through_date = student?.walk_through_date

        // Convert to milliseconds
        const walk_through_date_in_millis = walk_through_date * 1000

        // // Create a new Date object
        // const walkThroughDateObj = new Date(walk_through_date_in_millis)

        // Get the current date and time
        const current_date = new Date()

        // Add two days to the current date
        const future_date = walk_through_date_in_millis + 66.5 * 60 * 60 * 1000

        setOfferDate(formatDate(new Date(future_date - 1)))

        // Compare the future date with the walk-through date
        if (current_date.getTime() > future_date) {
          setExpired(true)
          // Stop the interval when the offer is expired
          clearInterval(intervalId)
        } else {
          if (expired) setExpired(false)
        }
      }

      // Initial check
      checkExpiration()

      // Set interval to check every minute (60000 ms)
      intervalId = setInterval(checkExpiration, 60000)

      // Clear interval on component unmount or if offer is expired
      return () => clearInterval(intervalId)
    }
  }, [student?.email])

  const calculateAmount = (): any | any => {
    if (activeDirectPaymentButtonType === "One Shot") {
      return paymentOptionDiscount?.fees_after_discount - calculateOneshotDiscount()
    } else {
      const createdInstallments = payData.filter(
        (installment: { status: string; due_date: string }) => installment.status === "created"
      )

      if (createdInstallments.length > 0) {
        return createdInstallments[0].amount
      } else {
        return 0
      }
    }
  }

  useEffect(() => {
    if (studentEMI.is_submitted && studentEMI.duration_index && studentEMI.duration_index != -1) {
      setActiveMonthType(passButtons[studentEMI.duration_index] as any)
    }
  }, [studentEMI])

  return (
    <DashboardLayoutv2>
      <Head title="Payments" />

      <div className="w-full max-xl:overflow-x-scroll p-[24px] bg-white rounded-[8px] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
        <EarlyPaymentModel offerDate={offerDate} isOpen={detailModel} onClose={() => setDetailModel(false)} />
        <div className="flex gap-[16px] justify-between">
          {/* payment details */}
          <div className="border-[0.5px] border-[#DEDEDE] max-xl:w-[320px] max-xl:h-[1000px] w-[650px] h-[677px] bg-white rounded-[8px] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] flex flex-col justify-between">
            <div className="p-[24px] pb-0 h-full">
              <p className="text-[16px] font-[Inter] font-[600]">Available Payment Methods</p>
              <div className="mt-[19px]">
                <TabGroup
                  buttons={activeButtons}
                  active={activeButtons.indexOf(activeButtonType)}
                  onChange={changeActiveButtonType}
                />
              </div>

              <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />

              {activeButtonType === "Direct payment" && (
                <div className="my-4 flex flex-col space-y-4 font-medium text-new-neutral pb-[24px]">
                  <div className="flex gap-[8px]">
                    {payData.length < 3 && (
                      <button
                        className={` py-[6px] px-[16px] text-[12px] rounded-[4px] ${activeDirectPaymentButtonType === "One Shot" ? "bg-[#162456] text-[#FFFFFF] " : "bg-[#F9F9F9] hover:bg-v2-gray-200 text-v2-gray-500  text-[#646464]"}`}
                        onClick={() => {
                          setActiveDirectPaymentButtonType("One Shot")
                        }}
                      >
                        One Shot
                      </button>
                    )}

                    {payData.length >= 3 && (
                      <button
                        className={`py-[6px] px-[16px] text-[12px] rounded-[4px] ${activeDirectPaymentButtonType === "Direct Installments" ? "bg-[#162456] text-[#FFFFFF] " : "bg-[#F9F9F9] hover:bg-v2-gray-200 text-v2-gray-500  text-[#646464]"}`}
                        onClick={() => {
                          setActiveDirectPaymentButtonType("Direct Installments")
                        }}
                      >
                        {payData.length - 1} Installments
                      </button>
                    )}
                  </div>

                  <div className="w-[226px]">
                    <h5 className="text-[16px] leading-[1.3] text-[#333333] font-semibold">
                      Direct to Bosscoder (
                      {activeDirectPaymentButtonType == "One Shot"
                        ? "One Shot Payment"
                        : `${payData.length - 1} Installments`}
                      ):
                    </h5>
                  </div>

                  <span className="text-[14px]">
                    Pay directly to Bosscoder in{" "}
                    {activeDirectPaymentButtonType == "One Shot" ? "one go" : `${payData.length - 1} Installments`}.
                  </span>

                  {activeDirectPaymentButtonType == "One Shot" && (
                    <span className="text-[14px]">
                      {payDataAll?.one_shot_discount}% discount will be provided on this payment method.
                    </span>
                  )}

                  <h6 className="text-[14px] text-[#333333] font-semibold">View payment breakdown</h6>

                  <button
                    className={`text-v2-gray-500  text-[#646464] py-[6px] px-[16px] text-[12px] rounded-[4px] w-[92px] bg-[#F9F9F9] hover:bg-v2-gray-200 border-solid border-[#D3D3D3] border-[0.5px] active:bg-[#162456] active:text-[#FFFFFF] `}
                    onClick={() => {
                      // setActiveDirectPaymentButtonType("3 Months EMI")
                      setPaymentBreakdownResult({})
                    }}
                  >
                    Click here
                  </button>
                </div>
              )}

              {activeButtonType === "2 Installments" && (
                <div className="my-4 flex flex-col space-y-4 font-medium text-new-neutral">
                  <span className="text-[14px]">
                    Pay your Course Fee directly to Bosscoder in Two Equal Installments
                  </span>
                </div>
              )}

              {activeButtonType === "NBFC EMI" && (
                <>
                  <MonthGroupTab
                    buttons={
                      studentEMI.is_submitted && studentEMI.duration_index && studentEMI.duration_index != -1
                        ? [passButtons[studentEMI.duration_index]]
                        : passButtons
                    }
                    active={activeMonthType}
                    onChange={changeActiveMonthType}
                  />

                  {(isAC || isAccelerator) && activeMonthType === "6 Months" && (
                    <div className="py-[26px] px-[24px] mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">6 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          <div>
                            {" "}
                            <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                          </div>
                          <div>
                            <span className=" ">Interest: </span> <span className="text-[#22C55E]">0%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">6 equal EMIs</span> (Payable {payableMonth}{" "}
                              onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />

                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                              {index === 0 &&
                                (allFilesStatus === true ? (
                                  <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                ) : (
                                  <div className="ml-auto text-[10px] font-medium text-[#DC2626]">
                                    Upload Not Completed
                                  </div>
                                ))}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {(isAC || isAccelerator) && activeMonthType === "9 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">9 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          <div>
                            {" "}
                            <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                          </div>
                          <div>
                            <span className=" ">Interest: </span> <span className="text-failure">6.9%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">9 equal EMIs</span> (Payable {payableMonth}{" "}
                              onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                              {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {!(isAC || isAccelerator) && isDSML
                    ? activeMonthType === "6 & 9 Months" && (
                        <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                          <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">
                            {isDSML ? "6 & 9 Months" : "6 Months"} - EMI
                          </p>
                          <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                            <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                              {!isDSML && !isEV && (
                                <div>
                                  {" "}
                                  <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                                </div>
                              )}
                              <div>
                                <span className=" ">Interest: </span> <span className="text-[#22C55E]">0%</span>
                              </div>
                              <div>
                                <span>
                                  Rest to be paid in <span className=" ">{isDSML ? "6 & 9" : "6"} equal EMIs</span>{" "}
                                  (Payable {payableMonth} onwards){" "}
                                </span>
                              </div>
                            </ul>
                          </div>

                          <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />

                          {studentEMI?.is_submitted ? (
                            <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                              <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                              {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                                <div className="flex items-center gap-2" key={index}>
                                  <div>
                                    {progressItem.status === 1 ? (
                                      <TickCircle />
                                    ) : progressItem.status === -1 ? (
                                      <CrossCircle />
                                    ) : (
                                      <BlankCircle />
                                    )}
                                  </div>
                                  <div className="leading-normal">
                                    {progressItem.name}
                                    {progressItem.note && (
                                      <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                              <ul className="list-disc pl-0">
                                <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                                <li key={1}>Upload your documents as required to complete the loan process.</li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )
                    : !(isAC || isAccelerator) &&
                      activeMonthType === "6 Months" && (
                        <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                          <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">
                            {isDSML ? "6 & 9 Months" : "6 Months"} - EMI
                          </p>
                          <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                            <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                              {!isDSML && !isEV && (
                                <div>
                                  {" "}
                                  <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                                </div>
                              )}
                              <div>
                                <span className=" ">Interest: </span> <span className="text-[#22C55E]">0%</span>
                              </div>
                              <div>
                                <span>
                                  Rest to be paid in <span className=" ">{isDSML ? "6 & 9" : "6"} equal EMIs</span>{" "}
                                  (Payable {payableMonth} onwards){" "}
                                </span>
                              </div>
                            </ul>
                          </div>
                          <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />

                          {studentEMI?.is_submitted ? (
                            <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                              <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                              {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                                <div className="flex items-center gap-2" key={index}>
                                  <div>
                                    {progressItem.status === 1 ? (
                                      <TickCircle />
                                    ) : progressItem.status === -1 ? (
                                      <CrossCircle />
                                    ) : (
                                      <BlankCircle />
                                    )}
                                  </div>
                                  <div className="leading-normal">
                                    {progressItem.name}
                                    {progressItem.note && (
                                      <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                    )}
                                  </div>
                                  {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                              <ul className="list-disc pl-0">
                                <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                                <li key={1}>Upload your documents as required to complete the loan process.</li>
                              </ul>
                            </div>
                          )}
                          {/* <p className="text-[14px] text-[#333] font-[600] font-[Inter] mb-[16px]">
                            Document approval status
                          </p>
                          <div className="flex items-center gap-[16px] mb-[8px]">
                          <Checkbox buttonType="checkbox" className="border-v2-gray-300 text-[#E3E3E3]" checked={true} />
                          <p className="text-[14px] text-[#333] font-[500] font-[Inter]">
                            Application Created
                          </p>
                          </div>

                          <div className="flex items-start gap-[16px] mb-[8px] text-[#E3E3E3]">
                          <Checkbox buttonType="checkbox" className="border-v2-gray-300" checked={true} />

                          <div className="">
                          <p className="text-[14px] text-[#333] font-[500] font-[Inter]">
                            Application in Review
                          </p>
                          <p className="text-[10px] text-v2-gray-500 font-[500] font-[Inter]">
                            ** Note: You will get a call from team soon.
                          </p>
                          </div>
                          </div>

                          <div className="flex items-center gap-[16px] mb-[8px] text-[#E3E3E3]">
                          <Checkbox buttonType="checkbox" className="border-v2-gray-300" checked={true} />
                          <p className="text-[14px] text-[#333] font-[500] font-[Inter]">
                            Application Approved
                          </p>
                          </div>

                          <div className="flex items-center gap-[16px] text-[#E3E3E3]">
                          <Checkbox buttonType="checkbox" className="border-v2-gray-300" checked={true} />
                          <p className="text-[14px] text-[#333] font-[500] font-[Inter]">
                            Loan Approved
                          </p>
                          </div> */}
                        </div>
                      )}

                  {isEV && activeMonthType === "9 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">9 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          <div>
                            <span className=" ">Interest: </span> <span className="text-failure">1.9%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">9 equal EMIs</span> (Payable {payableMonth}{" "}
                              onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                              {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-4 pl-0 pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {!(isAC || isAccelerator) && (isDSML || isEV)
                    ? activeMonthType === "12 Months" && (
                        <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                          <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">
                            {isDSML || isEV ? "12 Months" : "9 Months"} - EMI
                          </p>
                          <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                            <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral">
                              {!isDSML && !isEV && (
                                <div>
                                  {" "}
                                  <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                                </div>
                              )}

                              <div>
                                <span className=" ">Interest: </span>{" "}
                                <span className="text-failure">{isDSML || isEV ? 8.9 : 1.9}%</span>
                              </div>
                              <div>
                                <span>
                                  Rest to be paid in <span className=" ">{isDSML || isEV ? "12" : "9"} equal EMIs</span>{" "}
                                  (Payable {payableMonth} onwards){" "}
                                </span>
                              </div>
                            </ul>
                          </div>
                          <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                          {studentEMI?.is_submitted ? (
                            <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                              <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                              {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                                <div className="flex items-center gap-2" key={index}>
                                  <div>
                                    {progressItem.status === 1 ? (
                                      <TickCircle />
                                    ) : progressItem.status === -1 ? (
                                      <CrossCircle />
                                    ) : (
                                      <BlankCircle />
                                    )}
                                  </div>
                                  <div className="leading-normal">
                                    {progressItem.name}
                                    {progressItem.note && (
                                      <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                    )}
                                  </div>
                                  {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                              <ul className="list-disc pl-4">
                                <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                                <li key={1}>Upload your documents as required to complete the loan process.</li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )
                    : !(isAC || isAccelerator) &&
                      activeMonthType === "9 Months" && (
                        <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                          <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">
                            {isDSML || isEV ? "12 Months" : "9 Months"} - EMI
                          </p>
                          <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                            <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                              {!isDSML && !isEV && (
                                <div>
                                  {" "}
                                  <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                                </div>
                              )}

                              <div>
                                <span className=" ">Interest: </span>{" "}
                                <span className="text-failure">{isDSML || isEV ? 8.9 : 1.9}%</span>
                              </div>
                              <div>
                                <span>
                                  Rest to be paid in <span className=" ">{isDSML || isEV ? "12" : "9"} equal EMIs</span>{" "}
                                  (Payable {payableMonth} onwards){" "}
                                </span>
                              </div>
                            </ul>
                          </div>
                          <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                          {studentEMI?.is_submitted ? (
                            <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                              <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                              {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                                <div className="flex items-center gap-2" key={index}>
                                  <div>
                                    {progressItem.status === 1 ? (
                                      <TickCircle />
                                    ) : progressItem.status === -1 ? (
                                      <CrossCircle />
                                    ) : (
                                      <BlankCircle />
                                    )}
                                  </div>
                                  <div className="leading-normal">
                                    {progressItem.name}
                                    {progressItem.note && (
                                      <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                    )}
                                  </div>
                                  {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                              <ul className="list-disc pl-4 pl-0">
                                <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                                <li key={1}>Upload your documents as required to complete the loan process.</li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )}

                  {!(isDSML || isEV) && activeMonthType === "12 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">12 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          <div>
                            {" "}
                            <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                          </div>
                          <div>
                            <span className=" ">Interest: </span>{" "}
                            <span className="text-[#DC2626]">{isDSML || isEV ? 14.9 : 8.9}%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">12 equal EMIs</span>
                              (Payable {payableMonth} onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-4 pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {!(isDSML || isEV || isAccelerator) && activeMonthType === "15 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">15 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          <div>
                            {" "}
                            <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                          </div>
                          <div>
                            <span className=" ">Interest: </span> <span className="text-[#DC2626]">11.9%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">15 equal EMIs</span>
                              (Payable {payableMonth} onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                              {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-4 pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {(isDSML || isEV) && activeMonthType === "15 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">15 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          {!isDSML && !isEV && (
                            <div>
                              {" "}
                              <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                            </div>
                          )}
                          <div>
                            <span className=" ">Interest: </span> <span className="text-[#DC2626]">11.9%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">15 equal EMIs</span> (Payable {payableMonth}{" "}
                              onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px] " />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-4 pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {(isDSML || isEV) && activeMonthType === "18 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">18 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          {!isDSML && !isEV && (
                            <div>
                              {" "}
                              <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                            </div>
                          )}
                          <div>
                            <span className=" ">Interest: </span> <span className="text-[#DC2626]">14.9%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">18 equal EMIs</span> (Payable {payableMonth}{" "}
                              onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                              {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-4 pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  {(isDSML || isEV) && activeMonthType === "24 Months" && (
                    <div className="py-[26px] px-[24px]  mt-[16px] pt-[16px] h-[410px] w-full max-xl:w-[250px] max-xl: bg-[#F9F9F9] rounded-[4px]">
                      <p className="text-[16px] font-[600] font-[Inter] text-[#333333]">24 Months - EMI</p>
                      <div className="my-4 flex flex-col space-y-4 font-semibold text-[#202658]">
                        <ul className="list-disc space-y-2 px-6 text-[14px] text-new-neutral pl-0">
                          {!isDSML && !isEV && (
                            <div>
                              {" "}
                              <span className=" ">Advance:</span> <span> Rs. 5000 (already paid)</span>
                            </div>
                          )}
                          <div>
                            <span className=" ">Interest: </span> <span className="text-[#DC2626]">16.9%</span>
                          </div>
                          <div>
                            <span>
                              Rest to be paid in <span className=" ">24 equal EMIs</span> (Payable {payableMonth}{" "}
                              onwards){" "}
                            </span>
                          </div>
                        </ul>
                      </div>
                      <hr className="w-full max-xl:w-[250px] max-xl: my-[16px]" />
                      {studentEMI?.is_submitted ? (
                        <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                          <div className="leading-normal font-[600] mb-[8px]">Document approval status</div>
                          {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                            <div className="flex items-center gap-2" key={index}>
                              <div>
                                {progressItem.status === 1 ? (
                                  <TickCircle />
                                ) : progressItem.status === -1 ? (
                                  <CrossCircle />
                                ) : (
                                  <BlankCircle />
                                )}
                              </div>
                              <div className="leading-normal">
                                {progressItem.name}
                                {progressItem.note && (
                                  <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                                )}
                              </div>
                              {/* {index === 0 &&
                                            (allFilesStatus === true ? (
                                              <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                                            ) : (
                                              <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                                            ))} */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                          <ul className="list-disc pl-4 pl-0">
                            <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                            <li key={1}>Upload your documents as required to complete the loan process.</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            {activeButtonType == "NBFC EMI" && (
              <div className="flex justify-between p-4 bg-[#F9F9F9]">
                <div className="flex flex-col gap-[6px]">
                  <div className="text-[#414141] text-[10px] font-semibold">Total Payment :</div>
                  <div className="text-[#312E81] text-[14px] font-semibold">
                    Rs.{" "}
                    {Math.ceil(
                      amount +
                        (amount *
                          interestRate[studentEMI?.duration_index !== -1 ? studentEMI?.duration_index : interest]) /
                          100
                    )
                      ? Math.ceil(
                          amount +
                            (amount *
                              interestRate[studentEMI?.duration_index !== -1 ? studentEMI?.duration_index : interest]) /
                              100
                        )
                      : amount}{" "}
                  </div>
                </div>
                <button
                  className={`py-[9px] px-[16px] text-[14px] rounded-[4px] bg-[#162456] text-[#FFFFFF] hover:bg-new-accent hover:text-new-solid-white`}
                  onClick={() => {
                    setUploadDocumentsResult({})
                  }}
                >
                  Upload Document
                </button>
              </div>
            )}
            {/* Direct Payments  */}
            {activeButtonType == "Direct payment" && (
              <>
                {activeDirectPaymentButtonType === "Direct Installments" ? (
                  <div className="flex p-[16px] justify-between w-full bg-[#F9F9F9]">
                    {/* <div className="flex flex-col gap-[6px]"> */}
                    {/* Total amount */}
                    <div className="flex flex-col justify-evenly">
                      {payData?.length > 2 && (
                        <div className="flex flex-col">
                          <div className="mb-1 flex h-[12px] w-[292px] text-[10px]">
                            <span className="text-[#414141] font-[600]">{`${activeInstallment?.number ? "Next Installment:" : ""} `}</span>
                            {/* <span className="text-[#DC2626]">{`( Pay by- ${formatDueDateWithOutGMT(
                                                activeInstallment?.due_time
                                              )} )`}</span> */}
                          </div>
                          <h3 className="text-[#312E81] text-[14px] font-semibold">{`Rs ${calculateAmount()}`}</h3>
                        </div>
                      )}
                      {payData.length <= 2 && (
                        <div className="flex flex-col">
                          <span className="text-[#414141] text-[10px] font-semibold">Total Amount</span>
                          <h3 className="text-[#312E81] text-[14px] font-semibold">{`Rs ${calculateAmount()}`}</h3>
                        </div>
                      )}
                    </div>

                    {(showPayNow || payData.length >= 3 || student?.payment_method === "ccavenue") && (
                      <Button
                        className={`py-[9px] px-[16px] text-[14px] rounded-[4px] bg-[#162456] text-[#FFFFFF]`}
                        onClick={handleClickPay}
                      >
                        Pay Now
                      </Button>
                    )}
                  </div>
                ) : (
                  // One Shot
                  <>
                    <div className="flex p-[16px] justify-between bg-[#F9F9F9] w-full">
                      {!isCompletelyPaid && (
                        // <div className="absolute bottom-0 w-full justify-between">
                        <div className="flex justify-between w-full">
                          {disableInitiateNow ? (
                            <>
                              {payData.length <= 2 &&
                                !showPayNow &&
                                student?.payment_method !== "ccavenue" &&
                                disableInitiateNow && (
                                  <Button className="w-fit border-[#312E81] text-[#fff]" disabled>
                                    Initiate Payment
                                  </Button>
                                )}
                            </>
                          ) : (
                            <>
                              {payData.length <= 2 &&
                                !showPayNow &&
                                student?.payment_method !== "ccavenue" &&
                                !disableInitiateNow && (
                                  <Button
                                    className="w-fit border-[#312E81] text-[#fff]"
                                    onClick={handleGeneratePaylink}
                                  >
                                    Initiate Payment
                                  </Button>
                                )}
                            </>
                          )}

                          <div className="flex flex-col justify-evenly">
                            {payData?.length > 2 && (
                              <div className="flex flex-col">
                                <div className="mb-1 flex h-[12px] w-[292px] text-[10px]">
                                  <span className="text-[#414141]">{`Installment ${
                                    activeInstallment?.number - 1
                                  } `}</span>
                                  <span className="text-[#DC2626]">{`( Pay by- ${formatDueDateWithOutGMT(
                                    activeInstallment?.due_time
                                  )} )`}</span>
                                </div>
                                <h3>{`Rs ${calculateAmount()}`}</h3>
                              </div>
                            )}
                            {payData.length <= 2 && (
                              <div className="flex flex-col">
                                <span className="text-[#414141] text-[10px] font-semibold">Total Amount</span>
                                <h3 className="text-[#312E81] text-[14px] font-semibold">{`Rs ${calculateAmount()}`}</h3>
                              </div>
                            )}
                          </div>

                          {(showPayNow || payData.length >= 3 || student?.payment_method === "ccavenue") && (
                            <Button className="w-fit border-[#312E81] text-[14px] text-[#fff]" onClick={handleClickPay}>
                              {STR_PAY_NOW}
                            </Button>
                          )}
                        </div>
                        // </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {/* banner */}
          {!student?.is_payment_done && student?.walk_through_date && (
            <div className=" border-[0.5px] border-[#DEDEDE] relative max-xl:w-[300px] 2xl:w-[500px] w-[380px] h-[677px] bg-[#333] py-[35px] rounded-[8px] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] max-xl:h-[1000px] unique">
              <div className="flex flex-col justify-center">
                <div className="px-[18px] mt-[80px] mx-auto">
                  <div className="absolute bottom-0 -right-30">
                    <LineBottomDeep />
                  </div>
                  <div className="absolute bottom-0 -right-10">
                    <LineBottomDeep />
                  </div>
                  <span className="flex justify-center items-center gap-[10px] rounded-[4px]  bg-[#ffffff4f] w-[121px] h-[29px] px-[8px] py-[4px] text-[17px] text-[#FFF] font-[500] font-[Inter] shadow-[4px_4px_0px_0px_rgba(0,0,0,0.25)] ml-[6px]">
                    Off Upto
                  </span>

                  <div className=" text-white">
                    {expired ? (
                      <span className="text-[70px] font-[800] font-[Inter]">10,000</span>
                    ) : (
                      <span
                        style={{ textShadow: "-3px 5px 0px #2AA469" }}
                        className="text-[70px] font-[800] font-[Inter]"
                      >
                        10,000
                      </span>
                    )}

                    <span className="text-[17px] text-[#FFF] font-[500] font-[Inter] size[21px]">Rs</span>
                  </div>

                  <div className="grid grid-cols-2 gap-[15px] my-[15px] ">
                    <div className="h-[176px] w-[145px] max-xl:w-[125px] ml-[6px] px-[16px] rounded-[8px] border-[0.5px] border-[#3D3D3D] bg-[linear-gradient(139deg,rgba(255,255,255,0.47)_-1.48%,rgba(30,30,30,0.51)_139.69%)] shadow-[inset_0px_0.5px_5.5px_0px_rgba(255,255,255,0.18),-1px_1px_6.6px_#222930]">
                      <p className="mt-[22px] max-xl:mt-[10px] text-[#FFF] font-[400] text-[11px]">
                        Hi {student?.name?.length > 15 ? <>{student?.name?.slice(0, 12)}...</> : student?.name},
                      </p>
                      <p className="mt-[8px] text-[#FFF] font-[400] text-[10px] leading-3">
                        We are pleased to offer you an exclusive discount of upto{" "}
                        <span className="font-[600]">Rs 10,000</span> on early fee payment.
                      </p>

                      <button
                        onClick={() => setDetailModel(true)}
                        className="relative z-20 w-[115px] max-xl:w-[90px]  h-[28px] rounded-[4px] flex items-center justify-center bg-white mt-[18px] hover:bg-v2-gray-200  focus:bg-v2-gray-200"
                      >
                        <span className="  text-[#646464] text-[12px] font-[400] font-[Inter]">View Detail</span>
                      </button>
                    </div>
                    {expired ? (
                      <>
                        <img
                          src={expired_card1}
                          className="relative z-[10] w-[154px] h-[176px] max-xl:w-[125px]"
                          alt=""
                        />
                        <img
                          src={expired_card2}
                          className="relative z-[10] w-[154px] h-[176px] max-xl:w-[125px]"
                          alt=""
                        />
                        <img
                          src={expired_card3}
                          className="relative z-[10] w-[154px] h-[176px] max-xl:w-[125px]"
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        {loading ? (
                          <>
                            <img src={card_1} className="relative z-10 w-[154px] h-[176px] max-xl:w-[125px]" alt="" />
                            <img src={card_2} className="relative z-10 w-[154px] h-[176px] max-xl:w-[125px]" alt="" />
                            <img src={card_3} className="relative z-10 w-[154px] h-[176px] max-xl:w-[125px]" alt="" />
                          </>
                        ) : (
                          <>
                            <ScratchDiv
                              src={scratch1}
                              img={card_popup1}
                              scratched={scratchCardDetails?.hasOwnProperty("card1")}
                              heading={`Rs. ${scratchCardDetails?.card1?.amount} Off`}
                              details={`Congratulations! You have won a Discount of Rs ${scratchCardDetails?.card1?.amount} `}
                              size={"sm"}
                              card_key={"card1"}
                              setScratchCardDetails={setScratchCardDetails}
                              amount={scratchCardDetails?.card1?.amount}
                              scratch_card={card_1}
                            />
                            <ScratchDiv
                              img={card_popup2}
                              scratched={scratchCardDetails?.hasOwnProperty("card2")}
                              heading={"Goal Setting Session"}
                              details={
                                "Book a goal-setting session with our expert mentor for better guidance and customized trajectory for success."
                              }
                              size={"lg"}
                              src={scratch2}
                              card_key={"card2"}
                              setScratchCardDetails={setScratchCardDetails}
                              scratch_card={card_2}
                            />
                            <ScratchDiv
                              img={card_popup3}
                              scratched={scratchCardDetails?.hasOwnProperty("card3")}
                              heading={"Mentor Selection"}
                              details={"Now you can select a mentor of your choice from the recommended list"}
                              size={"lg"}
                              src={scratch3}
                              card_key={"card3"}
                              setScratchCardDetails={setScratchCardDetails}
                              scratch_card={card_3}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="rounded-t-[7px] flex justify-center items-center absolute top-0 w-full h-[78px] bg-[#FFF]">
                  <div className="">
                    {!expired && (
                      <p className="text-[16px] font-[700] text-center text-[#333] font-[Inter] my-2">Offer Ends in</p>
                    )}
                    <p className="text-center text-[18px] font-[700] text-[#DC2626] font-[Inter]">
                      {expired ? (
                        "Offer Expired"
                      ) : (
                        <div>
                          {remaining_time.hours} Hrs : {remaining_time.minutes} Mins : {remaining_time.seconds} Secs
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* transactions table  */}
        </div>
        <div className="flex">
          <div className="mt-6 w-full">
            <h2 className="text-[24px] text-new-accent">Your Transactions</h2>
            <p>Track all your transactions here.</p>
            <div className="flex w-full items-center justify p-1 pt-4">
              <AdminTable2
                data={studentTransactionTableData}
                outlined={true} // Set to true if you want an outlined table
                headerWidths={["[25%]", "[150px]", "[200px]", "[200px]", "[200px]", "[200px]", "full"]}
                rowWidths={["[50px]", "[150px]", "[200px]", "[200px]", "[200px]", "[200px]", "full"]}
                paddingInfo={["24px", "16px", "24px", "16px"]}
              />
            </div>
          </div>

          <FeesBreakdownModal
            type={activeDirectPaymentButtonType}
            isCompletelyPaid={isCompletelyPaid}
            payData={payData}
            payDataAll={payDataAll}
            result={paymentBreakdownresult}
            setResult={setPaymentBreakdownResult}
            onClose={() => {
              setPaymentBreakdownResult(null)
            }}
            paymentOptionDiscount={paymentOptionDiscount}
            calculateOneshotDiscount={calculateOneshotDiscount}
            offerDate={offerDate}
            expired={expired}
          />

          <UploadDocumentModal
            getPaymentDocuments={getPaymentDocuments}
            studentEMI={studentEMI}
            deletePaymentDocument={deletePaymentDocument}
            uploadPaymentDocument={uploadPaymentDocument}
            paymentDocuments={paymentDocuments}
            type={activeDirectPaymentButtonType}
            isCompletelyPaid={isCompletelyPaid}
            payData={payData}
            payDataAll={payDataAll}
            result={uploadDocumentsResult}
            setResult={setUploadDocumentsResult}
            onClose={() => {
              setUploadDocumentsResult(null)
            }}
            paymentOptionDiscount={paymentOptionDiscount}
            calculateOneshotDiscount={calculateOneshotDiscount}
            offerDate={offerDate}
            months={months}
            selectedEMIOptionToChoose={selectedEMIOptionToChoose}
            toast={toast}
            changeToastDetails={changeToastDetails}
            changeToastVisibility={changeToastVisibility}
            amount={paymentOptionDiscount?.fees_after_discount}
            isAc={isAC}
            isAC2={isAC2}
            isAccelerator={isAccelerator}
            interestRate={interestRate}
            interest={interest}
            allowSubmit={allowSubmit}
            createStudentEMI={createStudentEMI}
            allFilesStatus={allFilesStatus}
          />
        </div>
      </div>
    </DashboardLayoutv2>
  )
}
