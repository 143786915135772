import { CrossIcon, StarIcon } from "core/constants/svgs"
import { differenceInMinutes, format, set } from "date-fns"
import getOrdinal from "core/utils/ordinal"
import { isEmpty } from "core/utils/misc"
import { Close } from "@mui/icons-material"
import React, { useEffect, useState } from "react"
import { Button } from "core/components/v2/Button"
import Spinner from "core/components/Spinner"

export default function NewConfirmPopUp({
  session,
  mentor,
  confirmModal,
  onBooking,
  mentorSessionDetails,
  setConfirmSessionIndex,
  setBookingIndex,
  isRescheduleEnabled,
  setIsRescheduleEnabled,
  onCancel,
  onClose,
  setBookSlotModal,
  isTimerRunning,
  timer,
}: any) {
  // const { isTimerRunning } = useNewMentorshipCard()
  const [isBookingLoading, setIsBookingLoading] = React.useState(false)
  async function handleBookSession() {
    setIsBookingLoading(true)
    if (isRescheduleEnabled) {
      await onCancel(mentorSessionDetails?.id, "reschedule")
      setIsRescheduleEnabled(false)
      await onBooking(session?.date, session?.start, session?.end, mentorSessionDetails?.id, "")
    } else {
      await onBooking(
        session?.date,
        session?.start,
        session?.end,
        mentorSessionDetails?.id,
        typeof confirmModal === "string" && confirmModal ? confirmModal : ""
      )
    }
    setIsBookingLoading(false)
    onClose()
    setBookSlotModal(false)
  }
  const startTimestamp = new Date(session?.start_timestamp)
  const endTimestamp = new Date(session?.end_timestamp)
  const durationInMinutes = differenceInMinutes(endTimestamp, startTimestamp)
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes} minutes ${seconds < 10 ? `0${seconds}` : seconds} seconds`
  }
  function formatDate(dateString: any) {
    // Create a new Date object from the input string
    const date = new Date(dateString)

    // Array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    // Get the day and month
    const day = date.getDate()
    const month = monthNames[date.getMonth()]
    const year = date.getFullYear()

    // Determine the appropriate suffix for the day
    const suffix = (day: any) => {
      if (day > 3 && day < 21) return "th" // Handle 11th to 13th cases
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }

    // Format the date
    return `${day}${suffix(day)} of ${month}, ${year}`
  }

  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => setConfirmSessionIndex(-1)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="font-montserrat m-auto flex h-[249px] w-[304px] transform flex-col gap-3 rounded border bg-new-solid-white p-4">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-[#333] text-[16px] font-[500] w-[158px]">Mentor session confirmation</h3>
              </div>
              <div>
                <button onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <p className="text-[#414141] text-[12px] font-[400]">You have scheduled your mentor session on </p>

            <h4 className="text-[#414141] text-[14px] font-[600]">
              {formatDate(session?.date)} | {format(new Date(session?.start_timestamp), "h:mm a")}
            </h4>
            <h5 className="text-[10px] font-[400] w-[235px] text-new-failure">
              {isTimerRunning && (
                <div style={{ marginTop: "10px", fontWeight: "bold" }}>Book session within: {formatTime(timer)}</div>
              )}
              {/* Note ** Session rescheduling is not allowed before 12 Hr of mentor session. */}
            </h5>
            {timer === 0 ? (
              <h5 className="text-[10px] font-[400] w-[235px] text-new-failure">
                Timer expired! Please refresh the page and try again
              </h5>
            ) : (
              <></>
            )}

            <div className="flex gap-4 my-[16px] justify-end">
              <Button
                // className="h-9 w-[134px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                onClick={onClose}
                variant={"cancel"}
                border={"thin"}
              >
                Cancel
              </Button>
              <Button
                variant={"primary"}
                // className="h-9 w-[134px] whitespace-nowrap rounded-sm bg-transparent px-4 py-2"
                onClick={() => {
                  handleBookSession()
                }}
                disabled={isBookingLoading || mentorSessionDetails?.status === "booked" || timer === 0}
                // loading={isBookingLoading}
              >
                {isBookingLoading ? <Spinner small={true} /> : "Confirm"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
