import { PlacementBatch } from "domain/model/PlacementBatch"
import { PlacementBatchRepository } from "domain/repository/Admin/PlacementBatchRepository"

export interface CreatePlacementBatchUseCase {
  invoke(createBatch: PlacementBatch): Promise<any>
}

export class CreatePlacementBatches implements CreatePlacementBatchUseCase {
  private repository: PlacementBatchRepository

  constructor(repository: PlacementBatchRepository) {
    this.repository = repository
  }

  async invoke(createBatch: PlacementBatch) {
    return await this.repository.createBatch(createBatch)
  }
}
