import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Disclosure from "./components/Disclosure"
import CompensationTable from "./components/CompensationTable"
import Input from "core/components/Input"
import MonthlySessionTable from "./components/MonthlySessionTable"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import GenerateMentorInvoiceViewModel from "./GenerateMentorInvoiceViewModel"
import MentorsViewModel from "../MentorSessions/MentorsViewModel"
import { isEmpty } from "core/utils/misc"
import Spinner from "core/components/Spinner"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { STR_SUCCESS } from "core/constants/strings"
import axios from "axios"
import GetGoalSettingSessionAmountUpdate from "domain/useCase/Mentor/Session/GetGoalSettingSessionAmountUpdate"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"
import GetExtraGoalSettingSessionAmountUpdate from "domain/useCase/Mentor/Session/GetExtraGoalSettingSessionAmountUpdate"

export default function MentorDetailsView() {
  const {
    yearMonth,
    compensationAmount,
    goalSettingCompensationAmount,
    instructorDetails,
    Loading,
    instructor_id,
    allMentorsList,
    setCompensationAmount,
    setYearMonth,
    setLoading,
    fetchInstructorDetails,
    fetchUpdatedMentorCompensationDetails,
    fetchAllMentorList,
    extragoalSettingAmount,
    setGoalSettingCompensationAmount,
    setextraGoalSettingAmount,
  } = GenerateMentorInvoiceViewModel()
  const { mentorRating, fetchMentorRating } = MentorsViewModel()
  const [mentorEmail, setMentorEmail] = React.useState<string>("")
  const { auth } = useAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [reloadData, setReloadData] = React.useState(false)

  const GoalSettingSessionAmountUpdateUseCase = new GetGoalSettingSessionAmountUpdate(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )
  const ExtraGoalSettingSessionAmountUpdateUseCase = new GetExtraGoalSettingSessionAmountUpdate(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )

  const updateCompensation = async () => {
    const yearMonth1 = yearMonth.replace(/-/g, "")
    setLoading(true)
    await fetchUpdatedMentorCompensationDetails(auth, id as string, parseInt(compensationAmount), yearMonth1)
    changeToastDetails(STR_SUCCESS, "Compensation Addded")
    changeToastVisibility(true)
    setReloadData(!reloadData)
    await fetchInstructorDetails(auth, id as string)
    setLoading(false)
  }
  function handleAmount(e: any) {
    if (e.target.value) setCompensationAmount(e.target.value)
    else setCompensationAmount("")
  }
  function handleYearMonth(e: any) {
    if (e.target.value) setYearMonth(e.target.value as string)
    else setYearMonth("")
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const TableHeaders: string[] = ["Serial Number", "Compensation", "Month And Year", "Edit", "Delete"]
  const MonthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const MonthTableHead: string[] = [
    "Month",
    "Total Sessions",
    "Average Rating",
    "Amount",
    "View All Sessions",
    "View Invoice",
  ]
  function handleSearchByMentor(e: any) {
    navigate(`/admin/mentor-invoice/${e.target.value}`)
  }
  React.useEffect(() => {
    fetchInstructorDetails(auth, id as string)
    fetchAllMentorList()
    setMentorEmail(id as string)
    fetchMentorRating(id as string)
  }, [auth, id as string, reloadData])
  const handleGoalSettingCompensationChange = async (e: any) => {
    setGoalSettingCompensationAmount(Number(e.target.value))
  }

  const handleExtraGoalSettingAmountChange = async (e: any) => {
    setextraGoalSettingAmount(Number(e.target.value))
  }

  const GoalSettingSessionAmountUpdate = async (mentor_id: string = "", amount: number) => {
    const response = await GoalSettingSessionAmountUpdateUseCase.invoke(auth, mentor_id, amount)
    return response
  }

  const ExtraGoalSettingSessionAmountUpdate = async (mentor_id: string = "", extra_amount: number) => {
    const response = await ExtraGoalSettingSessionAmountUpdateUseCase.invoke(auth, mentor_id, extra_amount)
    return response
  }

  const handleGoalSettingCompensationSubmit = async () => {
    try {
      const response = await GoalSettingSessionAmountUpdate(id, goalSettingCompensationAmount.valueOf())

      if (response.success) {
        changeToastDetails("true", "Goal Setting Session Compensation Updated.")
        changeToastVisibility(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleExtraGoalSettingAmountSubmit = async () => {
    try {
      const response = await ExtraGoalSettingSessionAmountUpdate(id, extragoalSettingAmount.valueOf())

      if (response.data.success) {
        changeToastDetails("true", "Extra Goal Setting Amount Updated.")
        changeToastVisibility(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DashboardLayout>
      <div className="mx-4 my-4 flex flex-col gap-y-4">
        <div className="">
          <select className="h-9 w-[209px]" onChange={handleSearchByMentor} value={mentorEmail}>
            <option value="">Mentor</option>
            {allMentorsList?.map((instructor: any, i: number) => (
              <option value={instructor?.email} key={i}>
                {instructor?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mx-2rem my-3rem flex justify-between px-6 py-4 text-new-neutral-dark shadow">
          <div className="flex items-end gap-x-6">
            <div className="space-y-1">
              <h4 className="mb-1">{instructorDetails.name}</h4>
              <p className="">
                {instructorDetails.current_company} | {instructorDetails.experience} years
              </p>
              <p className="">
                {instructorDetails.email} | +91 {instructorDetails.phone}
              </p>
            </div>
          </div>
          <div className="flex gap-x-12">
            <div className="">
              <p className="">{instructorDetails.status}</p>
            </div>
            <div className="flex flex-col gap-y-5">
              <p className="text-new-success">
                {!isEmpty(mentorRating) ? (
                  mentorRating.rating != 0 ? (
                    <div className="">
                      {" "}
                      <h3 className="text-new-success">{mentorRating.rating}/5</h3>
                      <p className="">Rating</p>
                    </div>
                  ) : (
                    <div className="">Not Rated</div>
                  )
                ) : (
                  <Spinner small />
                )}
              </p>
            </div>
          </div>
        </div>

        <Disclosure label="General Information">
          <div className="flex gap-x-6">
            <div className="flex-col gap-y-2">
              <div className="flex-col gap-y-2">
                <h4 className="">Instructor Name</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.name}</p>
                </div>
              </div>
              <div className="flex-col gap-y-2">
                <h4 className="">Phone Number</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.phone}</p>
                </div>
              </div>
            </div>
            <div className="flex-col gap-y-2">
              <div className="flex-col gap-y-2">
                <h4 className="">Company</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.current_company}</p>
                </div>
              </div>
              <div className="flex-col gap-y-2">
                <h4 className="">Years Of Experience</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.experience}</p>
                </div>
              </div>
            </div>
            <div className="flex-col gap-y-2">
              <div className="flex-col gap-y-2">
                <h4 className="">Email</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.email}</p>
                </div>
              </div>
              <div className="flex-col gap-y-2">
                <h4 className="">LinkedIn ID</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">
                    <a href={instructorDetails.linkedin_profile} className="">
                      LinkedIn Profile
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Disclosure>
        <Disclosure label="Mentor Compensation Details">
          <div className="flex flex-col gap-y-4">
            <div className="flex gap-x-6">
              <Input
                id="Amount"
                type="Amount"
                placeholder="Mentor Compensation Per Hour"
                value={compensationAmount}
                onChange={handleAmount}
              />
              <Input
                id="yearMonth"
                type="text"
                onBlur={(e) => (e.target.type = "text")}
                onFocus={(e) => (e.target.type = "month")}
                placeholder="Enter Starting YearMonth"
                value={yearMonth}
                onChange={handleYearMonth}
              />
              <Button loading={Loading} onClick={updateCompensation}>
                Update Compensation
              </Button>
            </div>
            <CompensationTable
              reloadData={reloadData}
              setReloadData={setReloadData}
              changeToastDetails={changeToastDetails}
              changeToastVisibility={changeToastVisibility}
              tableHeader={TableHeaders}
              Compensation={instructorDetails.mentor_session_compensation}
            />
            {/* new changes */}
            <div className="flex flex-row justify-start gap-2">
              <Input
                id="goalSettingCompensation"
                type="number"
                placeholder="Goal Setting Session Compensation"
                value={goalSettingCompensationAmount}
                onChange={(e) => handleGoalSettingCompensationChange(e)}
              />
              <Button loading={Loading} onClick={handleGoalSettingCompensationSubmit}>
                Update Goal Setting Session compensation
              </Button>
            </div>

            <div className="flex flex-row justify-start gap-2">
              <Input
                id="extragoalSettingAmount"
                type="number"
                placeholder="Any Extra Amount "
                value={extragoalSettingAmount}
                onChange={(e) => handleExtraGoalSettingAmountChange(e)}
              />
              <Button loading={Loading} onClick={handleExtraGoalSettingAmountSubmit}>
                Any Extra Amount
              </Button>
            </div>
          </div>
        </Disclosure>
        <MonthlySessionTable
          reloadData={reloadData}
          TableHeader={MonthTableHead}
          MonthName={MonthName}
          mentorName={instructorDetails.name}
          mentor={instructorDetails}
        />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
