import { Auth } from "domain/model/Auth"
import DoubtSupportRepository from "domain/repository/Admin/DoubtSupportRepository"

export interface UpdateDoubtSupportScheduleUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export default class UpdateDoubtSupportSchedule implements UpdateDoubtSupportScheduleUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.updateDoubtSupportSchedule(auth, data)
  }
}
