import { STR_FAILURE, STR_STUDENT_INFO_SAVED, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { PaymentAdmin } from "domain/useCase/Admin/Payment/CreateCCAvPayment"
import CCAvPaymentAdminAPIDataSourceImpl from "data/API/Admin/CCAvAdminAPIDataSourceImpl"
import CCAvPaymentAdminRepositoryImpl from "data/repository/Admin/CCAvPaymentAdminRepositoryImpl"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GetCCAvPaymentDetailAdmin } from "domain/useCase/Admin/Payment/GetCCAvPaymentAdmin"

export default function PaymentBreakdownViewModel() {
  const [studentGetPayDetail, setStudentGetPayDetail] = useState<any>({})
  const [payableAmountBreakdown, setPayableAmountBreakdown] = useState<any>(0)
  const [installmentSet, setInstallmentSet] = useState<any>([])
  const [disableSaveChanges, setDisableSaveChanges] = useState<any>(false)

  const today = new Date().toISOString().split("T")[0]
  const [paymentBreakdownTableData, setPaymentBreakdownTableData] = useState<any>({
    header: [],
    rows: [],
  })
  const { id } = useParams()

  const [paymentDetails, setPaymentDetails] = useState<any>({
    student_email: "",
    instalments: [{ amount: 0, due_time: 0 }],
    course_fees: 0,
    discount: 0,
    seat_booking_amount: 0,
  })
  const [editablePaymentBreakValues, setEditablePaymentBreakValues] = useState<any>({
    student_email: id,
    course_fees: studentGetPayDetail?.fees || 0,
    seat_booking_amount: studentGetPayDetail?.seat_booking_amount,
    standard_discount: studentGetPayDetail?.standard_discount,
    instalments: [],
    link: "",
  })
  const [installmentNumber, setInstallmentNumber] = useState<any>({
    number_of_installments: 1,
    start_date: today,
  })

  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()

  const getCCAvPaymentDetailAdminUseCase = new GetCCAvPaymentDetailAdmin(
    new CCAvPaymentAdminRepositoryImpl(new CCAvPaymentAdminAPIDataSourceImpl())
  )
  const createAdminCCAvPaymentUseCase = new PaymentAdmin(
    new CCAvPaymentAdminRepositoryImpl(new CCAvPaymentAdminAPIDataSourceImpl())
  )

  const [paymentBreakData, setPaymentBreakData] = useState<any>({
    student_email: id,
    instalments: [],
    course_fees: studentGetPayDetail?.fees || 0,
    standard_discount: studentGetPayDetail?.standard_discount,
    seat_booking_amount: studentGetPayDetail?.seat_booking_amount || 0,
  })

  const handlePaymentBreakdownFieldChange = (field: any, value: any) => {
    setEditablePaymentBreakValues((prev: any) => ({
      ...prev,
      [field]: value.toString(),
    }))
    getPayableAmount()
  }

  const handleInstallmentNumberChange = (field: any, value: any) => {
    setInstallmentNumber((prev: any) => ({ ...prev, [field]: value }))
  }
  const calculateOfferDiscount = () => {
    const discountAmount = studentGetPayDetail?.offer_discount
    return Math.max(0, Math.round(discountAmount)) // Ensure non-negative integer
  }
  const calculateOneshotDiscount = () => {
    const discountAmount =
      (editablePaymentBreakValues?.course_fees -
        editablePaymentBreakValues?.seat_booking_amount -
        editablePaymentBreakValues?.standard_discount) *
      (studentGetPayDetail?.one_shot_discount / 100)

    return Math.max(0, Math.round(discountAmount)) // Ensure non-negative integer
  }

  const getPaymentBreakDownDetailAdmin = async (email: any) => {
    const response = await getCCAvPaymentDetailAdminUseCase.invoke(auth, {
      paymentData: { student_email: email },
    })
    setStudentGetPayDetail(response.data)

    setInstallmentSet(response.data.instalments)

    setEditablePaymentBreakValues({
      student_email: id,
      course_fees: (response?.data.fees).toString(),
      seat_booking_amount: response?.data.seat_booking_amount,
      standard_discount: response?.data.standard_discount,
      payment_method: response?.data?.payment_method,
      instalments: response?.data?.instalments
        .filter((instalment: any) => instalment.payment_type !== "seat_booking")
        .map((instalment: any) => ({
          amount: instalment.amount.toString(),
          due_time: instalment.due_time,
          status: instalment.status,
          payment_type: instalment.payment_type,
          paid_at: instalment.paid_at,

          link: instalment?.link || "",
        })),
    })
  }

  const getPayableAmount = () => {
    let amount = editablePaymentBreakValues?.course_fees
    amount = amount - editablePaymentBreakValues?.standard_discount
    amount = amount - editablePaymentBreakValues?.seat_booking_amount
    setPayableAmountBreakdown(amount)
  }
  const formatDate = (dueTime: any) => {
    const dueDate = new Date(dueTime)

    const year = dueDate.getFullYear()
    const month = String(dueDate.getMonth() + 1).padStart(2, "0") // Months are zero-indexed
    const day = String(dueDate.getDate()).padStart(2, "0")

    // Format the date in "YYYY-MM-DD" form
    return `${year}-${month}-${day}`
  }

  const setInitailInstallmentAuto = () => {
    let InstallmentNumber = installmentNumber.number_of_installments
    let arrayOf_instalments = []
    let paybleAmountForTable = Math.floor(payableAmountBreakdown / InstallmentNumber)
    let currentStartDate = new Date(installmentNumber.start_date)

    studentGetPayDetail?.instalments?.map((item: any, index: any) => {
      if (item.status === "paid" && item.payment_type !== "seat_booking") {
        arrayOf_instalments.push({
          amount: (item?.amount).toString(),
          due_time: formatDate(item?.due_time),
          status: item?.status,
          payment_type: item.payment_type,
          paid_at: item?.paid_at,
          payment_method: item?.payment_method,
          link: item?.link,
        })
      }
    })

    for (let i = 0; i < installmentNumber.number_of_installments; i++) {
      const year = currentStartDate.getFullYear()
      const month = currentStartDate.getMonth() + 1

      let temp_due_date = `${year}-${String(month).padStart(2, "0")}-${String(currentStartDate.getDate()).padStart(
        2,
        "0"
      )}`
      arrayOf_instalments.push({
        amount: paybleAmountForTable.toString(),
        due_time: temp_due_date,
        status: "created",
        payment_type: "instalment",
      })
      currentStartDate.setMonth(currentStartDate.getMonth() + 1)
    }
    setEditablePaymentBreakValues({
      ...editablePaymentBreakValues,
      instalments: arrayOf_instalments,
    })
  }
  const setInitailInstallmentAutoCCAv = () => {
    let InstallmentNumber = installmentNumber.number_of_installments
    let paidAmout = 0
    studentGetPayDetail?.instalments?.forEach((item: any, index: any) => {
      if (item.status === "paid" && item.payment_type !== "seat_booking") {
        paidAmout += item?.amount
      }
    })
    let arrayOf_instalments = []
    let paybleAmountForTable = Math.floor((payableAmountBreakdown - paidAmout) / InstallmentNumber)
    let currentStartDate = new Date(installmentNumber.start_date)

    studentGetPayDetail?.instalments?.map((item: any, index: any) => {
      if (item.status === "paid" && item.payment_type !== "seat_booking") {
        arrayOf_instalments.push({
          amount: (item?.amount).toString(),
          due_time: item?.due_time,
          status: item?.status,
          payment_type: item.payment_type,
          paid_at: item?.paid_at,
          payment_method: item?.payment_method,
          link: item?.link,
        })
      }
    })
    for (let i = 0; i < installmentNumber.number_of_installments; i++) {
      const year = currentStartDate.getFullYear()
      const month = currentStartDate.getMonth() + 1

      let temp_due_date = `${year}-${String(month).padStart(2, "0")}-${String(currentStartDate.getDate()).padStart(
        2,
        "0"
      )}`
      arrayOf_instalments.push({
        amount: paybleAmountForTable.toString(),
        due_time: temp_due_date,
        status: "created",
        payment_type: "instalment",
        payment_method: "ccavenue",
        link: "",
      })
      currentStartDate.setMonth(currentStartDate.getMonth() + 1)
    }
    setEditablePaymentBreakValues({
      ...editablePaymentBreakValues,
      instalments: arrayOf_instalments,
    })
  }

  const createPaymentBreakdownTable = () => {
    const header = ["No", "Fee Type", "Status", "Payment Due Date", "Amount", "Payment Link"]
    const rows: { [key: string]: any }[] = []

    let rownumber = 1
    let srnumber = 1
    editablePaymentBreakValues?.instalments?.map((item: any, index: any) => {
      if (item.status === "paid") {
        const feeType = item.payment_type === "seat_booking" ? "Seat Booking" : `Installment ${rownumber}`
        rows.push({
          No: srnumber,
          "Fee Type": feeType,
          Status: (
            <span className={`items-center ${item.status === "paid" ? "text-500 text-[#00C22B]" : ""}`}>
              {` ${item.status === "paid" ? "Paid" : "Due"}`}
            </span>
          ),
          "Payment Due Date": (
            <span>
              <input
                type="date"
                value={formatDate(editablePaymentBreakValues?.instalments[index]?.due_time)}
                className="border-300 h-[40px] w-[163px] appearance-none rounded-[2px] border-[1px] border-[#414141] p-2 px-[16px] py-[8px] text-center"
                placeholder="Select Date"
              />
            </span>
          ),
          Amount: (
            <span>
              <input
                type="text"
                value={editablePaymentBreakValues?.instalments[index]?.amount}
                // onChange={(e) =>
                //   handleInstalmentChange(rownumber, 'amount', e.target.value)
                // }
                className="border-300 h-[40px] w-[163px]  appearance-none rounded-[2px] border border-[#414141] p-2 text-center"
                placeholder="Amount"
              />
            </span>
          ),
          Link: (
            <span>
              <input
                type="text"
                value={editablePaymentBreakValues?.instalments[index]?.link}
                className="border-300 h-[40px] w-[163px]  appearance-none rounded-[2px] border border-[#414141] p-2 text-center"
                placeholder="Amount"
              />
            </span>
          ),
        })
        if (item.payment_type !== "seat_booking") {
          rownumber++
        }
        srnumber++
      }
    })

    // let paybleAmountForTable=Math.floor(payableAmountBreakdown/(installmentNumber.number_of_installments));
    // let currentStartDate = new Date(installmentNumber.start_date);

    editablePaymentBreakValues?.instalments?.map((item: any, index: any) => {
      if (item.status !== "paid") {
        rows.push({
          NO: srnumber,
          "Installment No": `Installment ${rownumber}`,
          Status: "Due",
          "Payment Due Date": (
            <span>
              <input
                type="date"
                value={formatDate(editablePaymentBreakValues?.instalments[index]?.due_time)}
                onChange={(e) =>
                  // handleInstalmentChange(rownumber, 'due_time', e.target.value)
                  setEditablePaymentBreakValues({
                    ...editablePaymentBreakValues,
                    instalments: editablePaymentBreakValues.instalments.map((item: any, i: number) => {
                      if (i === index) {
                        return {
                          ...item,
                          amount: item?.amount,
                          due_time: e.target.value,
                          link: item?.link || "",
                        }
                      }
                      return item
                    }),
                  })
                }
                className="border-300 h-[40px] w-[163px] appearance-none rounded-[2px]  border-[1px] border-[#414141] p-2 px-[16px] py-[8px] text-center"
                placeholder="Select Date"
              />
            </span>
          ),
          Amount: (
            <span>
              <input
                type="text"
                value={editablePaymentBreakValues?.instalments[index]?.amount}
                onChange={(e) =>
                  // handleInstalmentChange(rownumber, 'due_time', e.target.value)
                  setEditablePaymentBreakValues({
                    ...editablePaymentBreakValues,
                    instalments: editablePaymentBreakValues.instalments.map((item: any, i: number) => {
                      if (i === index) {
                        return {
                          ...item,
                          amount: e.target.value,
                          due_time: item.due_time,
                        }
                      }
                      return item
                    }),
                  })
                }
                // onChange={(e) => handleInstallmentNumberChange('number_of_installments', e.target.value)}
                className="border-300 h-[40px] w-[163px]  appearance-none rounded-[2px] border border-[#414141] p-2 text-center"
                placeholder="Amount"
              />
            </span>
          ),
          Link: (
            <span>
              <input
                type="text"
                value={editablePaymentBreakValues?.instalments[index]?.link}
                onChange={(e) =>
                  // handleInstalmentChange(rownumber, 'due_time', e.target.value)
                  setEditablePaymentBreakValues({
                    ...editablePaymentBreakValues,
                    instalments: editablePaymentBreakValues.instalments.map((item: any, i: number) => {
                      if (i === index) {
                        return {
                          ...item,
                          link: e.target.value,
                        }
                      }
                      return item
                    }),
                  })
                }
                // onChange={(e) => handleInstallmentNumberChange('number_of_installments', e.target.value)}
                className="border-300 h-[40px] w-[163px]  appearance-none rounded-[2px] border border-[#414141] p-2 text-center"
                placeholder="Payment Link"
              />
            </span>
          ),
        })
        rownumber++
        srnumber++
      }
    })
    setPaymentBreakdownTableData({ header, rows })
  }

  const submitPaymentBreakdown = async () => {
    setDisableSaveChanges(true)
    const response = await createAdminCCAvPaymentUseCase.invoke(auth, {
      paymentData: editablePaymentBreakValues,
    })
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response))
      setDisableSaveChanges(false)
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, response?.data)
    setDisableSaveChanges(false)
  }
  const submitCCAvenuePaymentBreakdown = async () => {
    setDisableSaveChanges(true)
    const response = await createAdminCCAvPaymentUseCase.invoke(auth, {
      paymentData: editablePaymentBreakValues,
    })
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response))
      setDisableSaveChanges(false)
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, response?.data)
    setDisableSaveChanges(false)
    if (response?.success) {
      window.location.reload()
    }
  }
  const handleDiscardPaymentBreakdown = () => {
    setPaymentBreakdownTableData({
      header: ["NO", "FEE TYPE", "STATUS", "PAYMENT DUE DATE", "AMOUNT", "PAYMENT LINK"],
      rows: [],
    })
  }

  return {
    getPaymentBreakDownDetailAdmin,
    submitCCAvenuePaymentBreakdown,
    studentGetPayDetail,
    getPayableAmount,
    editablePaymentBreakValues,
    handlePaymentBreakdownFieldChange,
    payableAmountBreakdown,
    handleInstallmentNumberChange,
    installmentNumber,
    createPaymentBreakdownTable,
    paymentBreakdownTableData,
    paymentBreakData,
    toast,
    disableSaveChanges,
    changeToastVisibility,
    calculateOfferDiscount,
    calculateOneshotDiscount,
    submitPaymentBreakdown,
    handleDiscardPaymentBreakdown,
    setInitailInstallmentAuto,
    setInitailInstallmentAutoCCAv,
  }
}
