import React from "react"
import { MentorDashboardAPIDataSourceImpl } from "data/API/Admin/MentorDashboardAPIDataSourceImpl"
import { MentorDashboardRepositoryImpl } from "data/repository/Admin/MentorDashboardRepositoryImpl"
import { GetMentorsDetails } from "domain/useCase/Admin/MentorDashboard/GetAllMentorDetails"
import { EnableInstructors } from "domain/useCase/Admin/MentorDashboard/EnableInstructor"
import { DisableMentors } from "domain/useCase/Admin/MentorDashboard/DisableMentor"
import { useAuth } from "core/context/auth"
import { ToggleGoalSettingMentor } from "domain/useCase/Admin/MentorDashboard/ToggleGoalSettingMentor"
import { Auth } from "domain/model/Auth"
import { ToggleLockMentor } from "domain/useCase/Admin/MentorDashboard/ToggleLockMentor"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function MentorDashboardViewModel() {
  const [mentorData, setMentorData] = React.useState<any>([])
  const [searchByEmail, setSearchByEmail] = React.useState<string>("")
  const [searchByCourse, setSearchByCourse] = React.useState<string>("")
  const [loading, setLoading] = React.useState(false)
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const { auth } = useAuth()

  const GetAllMentorsDetailsUseCase = new GetMentorsDetails(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const assignInstructorUseCase = new EnableInstructors(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const disableMentorUseCase = new DisableMentors(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const toggleGoalSettingMentorUseCase = new ToggleGoalSettingMentor(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const toggleLockMentorUseCase = new ToggleLockMentor(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  async function getMentorsData() {
    const response = await GetAllMentorsDetailsUseCase.invoke({
      admin_uid: auth.local_id,
    })

    if (response) {
      const filteredMentorData = response.response.mentors_list.filter((mentor: any) => mentor.bandwidth !== undefined)
      const isMentorTrueArray = filteredMentorData.filter((mentor: any) => mentor.is_mentor === true)
      const isMentorFalseArray = filteredMentorData.filter((mentor: any) => mentor.is_mentor === false)
      const isMentorAbsentArray = filteredMentorData.filter((mentor: any) => mentor.is_mentor === undefined)
      isMentorTrueArray.sort((a: any, b: any) => a.bandwidth - b.bandwidth)
      isMentorFalseArray.sort((a: any, b: any) => a.bandwidth - b.bandwidth)
      isMentorAbsentArray.sort((a: any, b: any) => a.bandwidth - b.bandwidth)
      const sortedMentorData = isMentorTrueArray.concat(isMentorFalseArray, isMentorAbsentArray)
      // Set the sorted and filtered data using setMentorData
      setMentorData(sortedMentorData)
    }
  }
  function downloadMentorsCSV(data: any[]) {
    setLoading(true)
    if (!data || data.length === 0) {
      setLoading(false)
      changeToastDetails(STR_FAILURE, "Error downloading data!")
      return
    }

    // Specify the desired headers
    const headers = [
      "email",
      "name",
      "course_type",
      "mentees_count", // Derived from mentees array length
      "current_company",
      "experience",
      "goal_setting_mentor",
      "program_type",
    ]

    // Convert rows to CSV format
    const csvContent = [
      headers.join(","), // Header row
      ...data.map((row) =>
        headers
          .map((header) => {
            if (header === "mentees_count") {
              // Get the length of the mentees array
              return Array.isArray(row.mentees) ? row.mentees.length : 0
            }
            return row[header] !== undefined ? row[header] : ""
          })
          .join(",")
      ), // Data rows
    ].join("\n")

    // Create a downloadable link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)
    link.setAttribute("download", "MentorsData.csv")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
    setLoading(false)
    changeToastDetails(STR_SUCCESS, "Data Downloaded successfully!")
    changeToastVisibility(true)
  }

  async function isInstructor(id: any, status: any) {
    const response = await assignInstructorUseCase.invoke({
      email: id,
      is_instructor: status,
    })

    return response
  }

  async function toggleGoalSettingMentor(id: any) {
    const response = await toggleGoalSettingMentorUseCase.invoke(auth, id)

    return response
  }

  async function handleLockMentor(id: any) {
    const response = await toggleLockMentorUseCase.invoke(auth, id)

    return response
  }

  async function disbaleMentor(id: string, status: any) {
    const response = await disableMentorUseCase.invoke({
      email: id,
      disable_user: status,
    })
    return response
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }

  function handleSearchByCourse(e: any) {
    if (e.target.value) setSearchByCourse(e.target.value)
    else setSearchByCourse("")
  }

  function searchingforEmail(email: string | null | undefined) {
    return function (obj: any) {
      return !email || obj.email.toLowerCase().includes(email.toLowerCase())
    }
  }

  function searchingByCourse(course_type: string | null | undefined) {
    return function (obj: any) {
      return !course_type || obj.course_type.toLowerCase().includes(course_type.toLowerCase())
    }
  }

  function handleInstructor(id: any) {
    const ind = mentorData.findIndex((mentor: any) => mentor.email === id)
    const togglestate = !mentorData[ind]?.is_instructor
    isInstructor(id, togglestate)
    const updatedMentorsList = [...mentorData]
    updatedMentorsList[ind].is_instructor = togglestate
    setMentorData(updatedMentorsList)
  }

  function handleDisableMentor(id: any) {
    const ind = mentorData.findIndex((mentor: any) => mentor.email === id)
    const togglestate = !mentorData[ind]?.disabled
    disbaleMentor(id, togglestate)
    const updatedMentorsList = [...mentorData]
    updatedMentorsList[ind].disabled = togglestate
    setMentorData(updatedMentorsList)
  }

  return {
    mentorData,
    searchByEmail,
    searchByCourse,
    getMentorsData,
    handleSearchByEmail,
    searchingforEmail,
    searchingByCourse,
    handleInstructor,
    handleSearchByCourse,
    handleDisableMentor,
    toggleGoalSettingMentor,
    handleLockMentor,
    downloadMentorsCSV,
    loading,
    toast,
    changeToastVisibility,
  }
}
