import { API_V4_ADMIN, API_V4_CONTEST_DELETE_FEEDBACK_FILE } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import ContestDataSource from "data/dataSource/Admin/ContestDataSource"
import { Auth } from "domain/model/Auth"
import { FeedbackResponse } from "domain/model/FeedbackResponse"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export default class ContestRepositoryImpl implements ContestRepository {
  private dataSource: ContestDataSource

  constructor(dataSource: ContestDataSource) {
    this.dataSource = dataSource
  }

  async getQuestionList(auth: Auth): Promise<any> {
    return await this.dataSource.getQuestionList(auth)
  }

  async getcaseStudyQuestionList(auth: Auth): Promise<any> {
    return await this.dataSource.getcaseStudyQuestionList(auth)
  }

  async getsubjectiveQuestionList(auth: Auth): Promise<any> {
    return await this.dataSource.getsubjectiveQuestionList(auth)
  }

  async createContest(auth: Auth, data: any): Promise<any> {
    return await this.dataSource.createContest(auth, data)
  }

  async updateContest(auth: Auth, data: any): Promise<any> {
    return await this.dataSource.updateContest(auth, data)
  }

  async deleteContest(auth: Auth, contestId: string): Promise<any> {
    return await this.dataSource.deleteContest(auth, contestId)
  }

  async getMcqNameFromId(auth: Auth, mcq_id: any): Promise<any> {
    return await this.dataSource.getMcqNameFromId(auth, mcq_id)
  }
  async getContestStudentList(
    auth: Auth,
    contest_id: string,
    // batch: string,
    page: number,
    pageLimit: number
  ): Promise<any> {
    return await this.dataSource.getContestStudentList(
      auth,
      contest_id,
      // batch,
      page,
      pageLimit
    )
  }
  async getStudentContestSubmissions(auth: Auth, student_email: string, contest_id: string): Promise<any> {
    return await this.dataSource.getStudentContestSubmissions(auth, student_email, contest_id)
  }
  async createContestFeedbackResponse(
    auth: Auth,
    contest_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any> {
    return await this.dataSource.createContestFeedbackResponse(
      auth,
      contest_id,
      student_email,
      formData,
      feedbackResponse
    )
  }
  async getStudentContestFeedbackResponse(auth: Auth, student_email: string, contest_id: string): Promise<any> {
    return await this.dataSource.getStudentContestFeedbackResponse(auth, student_email, contest_id)
  }
  async getContestFeedbackDetails(auth: Auth, student_email: string, contest_id: string): Promise<any> {
    return await this.dataSource.getContestFeedbackDetails(auth, student_email, contest_id)
  }
  async getContestSSMSubmissionEnableStatus(auth: Auth, student_email: string, contest_id: string): Promise<any> {
    return await this.dataSource.getContestSSMSubmissionEnableStatus(auth, student_email, contest_id)
  }
  async deleteContestFeedbackFile(
    auth: Auth,
    contest_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any> {
    return this.dataSource.deleteContestFeedbackFile(auth, contest_id, student_email, fileurl, questionName, type)
  }

  async toggleProctoring(auth: Auth, student: any): Promise<any> {
    return await this.dataSource.toggleProctoring(auth, student)
  }

  async validateCodingQuestion(auth: Auth, id: any): Promise<any> {
    return await this.dataSource.validateCodingQuestion(auth, id)
  }

  async validateMcqQuestion(auth: Auth, id: any): Promise<any> {
    return await this.dataSource.validateMcqQuestion(auth, id)
  }

  async validateSubjectiveQuestion(auth: Auth, id: any): Promise<any> {
    return await this.dataSource.validateSubjectiveQuestion(auth, id)
  }
  async createExam(auth: Auth, data: any): Promise<any> {
    return await this.dataSource.createExam(auth, data)
  }
  async updateExam(auth: Auth, data: any): Promise<any> {
    return await this.dataSource.updateExam(auth, data)
  }
  async getExamDetails(auth: Auth, exam_id: string): Promise<any> {
    return await this.dataSource.getExamDetails(auth, exam_id)
  }
  async deleteExam(auth: Auth, exam_id: string): Promise<any> {
    return await this.dataSource.deleteExam(auth, exam_id)
  }
  async getContestResult(auth: Auth, data: any): Promise<any> {
    return await this.dataSource.getContestResult(auth, data)
  }
}
