import Input from "core/components/Input"
import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import StoreTable from "../Store/components/StoreTable"
import Skeleton from "core/components/Skeleton"
import CoinsTrackerViewModel from "./CoinsTrackerViewModel"
import Loader from "core/components/Loader"
export default function CoinsTracker() {
  const {
    batch,
    students,
    searchByEmail,
    searchByBatch,
    searchByName,
    selectedCoinType,
    setSelectedCoinType,
    emailInputRef,
    isLoading,
    tableData,
    showLoadMore,
    nextStudentsLoading,
    setIsLoading,
    fetchStudentsByName,
    fetchStudentByEmail,
    handleSearchByEmail,
    handleSearchByBatch,
    handleSearchByName,
    getBatches,
    getTop100StudentsByCoins,
    loadNextDataByCoins,
    fetchStudentsByBatchByCoins,
  } = CoinsTrackerViewModel()
  React.useEffect(() => {
    if (true) {
      getBatches()
    }
  }, [])
  React.useEffect(() => {
    setIsLoading(true)

    if (true) {
      getTop100StudentsByCoins(selectedCoinType)
      emailInputRef.current?.focus()
    }
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    const getData = setTimeout(() => {
      setIsLoading(true)

      if (searchByEmail) {
        fetchStudentByEmail(searchByEmail)
      } else {
        getTop100StudentsByCoins(selectedCoinType)
      }
      setIsLoading(false)
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByEmail])

  React.useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByName) fetchStudentsByName(searchByName)
      else getTop100StudentsByCoins(selectedCoinType)
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByName])

  React.useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByBatch) fetchStudentsByBatchByCoins(searchByBatch)
      else getTop100StudentsByCoins(selectedCoinType)
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByBatch])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Coins Tracker</h2>
          <p>Track the coins earned by students here</p>
        </div>
        <div className="flex w-full flex-wrap gap-4">
          <Input
            className="w-60 h-10 text-sm focus:border-new-accent"
            id="search-name"
            placeholder="Search by name"
            value={searchByName}
            onChange={handleSearchByName}
          />
          <Input
            className="w-60 h-10 text-sm focus:border-new-accent"
            id="search-email"
            type="email"
            placeholder="Search by email"
            value={searchByEmail}
            onChange={handleSearchByEmail}
          />

          <div className="w-60">
            <StandaredSelect
              className="w-60 h-10 text-sm"
              id="search-batch"
              required
              options={batch}
              placeholder="Select Batch"
              onChange={handleSearchByBatch}
              value={searchByBatch}
            />
          </div>
        </div>
        <div className="flex items-center gap-4 pt-4">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="coinType"
              value="coins"
              checked={selectedCoinType === "coins"}
              onChange={(e) => {
                setSelectedCoinType(e.target.value)
                getTop100StudentsByCoins(e.target.value)
              }}
            />
            <span>Coins</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="coinType"
              value="classCoins"
              checked={selectedCoinType === "classCoins"}
              onChange={(e) => {
                setSelectedCoinType(e.target.value)
                getTop100StudentsByCoins(e.target.value)
              }}
            />
            <span>Class Coins</span>
          </label>
        </div>
        {isLoading ? <Loader dark={true} small={false} xs={false} height="100px" /> : <StoreTable data={tableData} />}
        {showLoadMore ? (
          <Skeleton loading={nextStudentsLoading} exhausted={false} handler={loadNextDataByCoins} />
        ) : (
          <></>
        )}
      </div>
    </DashboardLayout>
  )
}
