import React, { useEffect, useState } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import useDoubtSupportScheduleViewModel from "./DoubtSupportScheduleViewModel"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"

export default function DoubtSupportView() {
  const {
    getAllDevelopers,
    developers,
    schedule,
    setSchedule,
    dates,
    toast,
    changeToastVisibility,
    getDoubtSupportSchedule,
    setDates,
    updateDoubtSupportSchedule,
    loading,
  } = useDoubtSupportScheduleViewModel()

  useEffect(() => {
    // Fetch developers on component load
    getAllDevelopers()
    generateDates()
    getDoubtSupportSchedule()
  }, [])

  console.log(schedule)
  const generateDates = () => {
    // Generate today and the next 7 days
    const today = new Date()
    const nextSevenDays = Array.from({ length: 8 }, (_, i) => {
      const date = new Date()
      date.setDate(today.getDate() + i)
      return date.toISOString().split("T")[0]
    })
    setDates(nextSevenDays)
  }
  console.log(dates, "sett")

  const handleDeveloperSelect = (date: string, developerId: string) => {
    const selectedDeveloper = developers.find((dev: any) => dev?.email === developerId) || null
    setSchedule((prev) => ({
      ...prev,
      [date]: selectedDeveloper,
    }))
  }

  return (
    <DashboardLayout>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "2rem" }}>
        <h3>Schedule</h3>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left", padding: "0.5rem" }}>Date</th>
              <th style={{ textAlign: "left", padding: "0.5rem" }}>Developer</th>
            </tr>
          </thead>
          <tbody>
            {dates.map((date) => (
              <tr key={date} style={{ borderBottom: "1px solid #ccc" }}>
                <td style={{ padding: "0.5rem" }}>{date}</td>
                <td style={{ padding: "0.5rem" }}>
                  <select
                    value={schedule[date]?.email || ""}
                    onChange={(e) => handleDeveloperSelect(date, e.target.value)}
                    style={{
                      padding: "0.4rem",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      width: "100%",
                    }}
                  >
                    <option value="">Select Developer</option>
                    {developers.map((dev: any) => (
                      <option key={dev?.email} value={dev?.email}>
                        {dev.email}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center">
          <Button
            disabled={loading}
            onClick={() => updateDoubtSupportSchedule(schedule)}
            className="w-[300px] max-h-[50px]"
          >
            {!loading ? "Save Changes" : <Loader />}
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
