import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import OrderStatusCard from "./OrderStatusCard"
import useStoreViewModel from "../StoreViewModel"
import React, { useEffect } from "react"
import Button from "core/components/new/Button"
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "core/components/Loader"
import ButtonGroup from "core/components/ButtonGroup"
import MentorSessionCouponCard from "./MentorSessionCouponCard"
import { useAuth } from "core/context/auth"
import TabGroup from "core/components/payment-method/TabGroup"

export default function OrderHistory({
  changeToastVisibility,
  changeToastDetails,
  orderHistory,
  activeButtonType,
  allMentorCoupons,
  fetchOrderHistory,
  FetchMentorSessionCoupon,
  changeActiveButtonType,
}: any) {
  const auth = useAuth()
  const location = useLocation()

  React.useEffect(() => {
    fetchOrderHistory()
    FetchMentorSessionCoupon()
    if (location.state) {
      let tab = parseInt(location.state?.tab)
      if (tab === 0 || tab === 1) {
        changeActiveButtonType(tab)
      }
    }
  }, [auth, location])

  return (
    <>
      {/* <DashboardLayoutv2> */}
      <div className="my-[16px] divide-y divide-new-neutral-light text-new-neutral-dark">
        {
          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <div className="">
                <h2 className="text-[#333] text-[16px] font-[600]">Order History</h2>
                <p className="text-[#646464] text-[14px] font-[400]">
                  You can view the history of your previous orders here.
                </p>
              </div>
              {/* <Button outlined failure className="h-fit w-fit" onClick={() => navigate("/redeem-coins")}>
                Back
              </Button> */}
            </div>
            <div className="mt-10">
              {/* <ButtonGroup
                buttons={["Amazon Coupons", "Extra Mentor Session"]}
                active={activeButtonType}
                onChange={changeActiveButtonType}
              /> */}
              <TabGroup
                buttons={["Amazon Coupons", "Extra Mentor Session"]}
                active={activeButtonType}
                onChange={changeActiveButtonType}
              />
            </div>
            {activeButtonType == 0 ? (
              <div className="flex flex-col gap-6">
                {orderHistory && orderHistory.length != 0 ? (
                  orderHistory.map((data: any, i: number) => {
                    return (
                      <OrderStatusCard
                        key={i}
                        order={data}
                        changeToastVisibility={changeToastVisibility}
                        changeToastDetails={changeToastDetails}
                      />
                    )
                  })
                ) : orderHistory && orderHistory.length == 0 ? (
                  <div className="text-lg text-black">No Orders Placed</div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              activeButtonType == 1 && (
                <div className="flex flex-col gap-6">
                  {allMentorCoupons && allMentorCoupons.length != 0 ? (
                    allMentorCoupons.map((data: any, i: number) => <MentorSessionCouponCard order={data} />)
                  ) : allMentorCoupons && allMentorCoupons.length == 0 ? (
                    <div className="text-lg text-black">No Mentor Session</div>
                  ) : (
                    <Loader />
                  )}
                </div>
              )
            )}
          </div>
        }
      </div>
      {/* </DashboardLayoutv2> */}
    </>
  )
}
