import { Auth } from "domain/model/Auth"
import GetAllClassesRepository from "domain/repository/Admin/GetAllClassesRepository"

export interface GetClassFeedbackAdminNewUseCase {
  invoke(auth: Auth, id: any): Promise<any>
}

export default class GetClassFeedbackAdminNew implements GetClassFeedbackAdminNewUseCase {
  private repository: GetAllClassesRepository

  constructor(repository: GetAllClassesRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.getClassFeedbackAdminNew(auth, data)
  }
}
