import React, { useState, useEffect, useRef } from "react"
import { Modal } from "@mui/material"
import ArrowGoBack from "assets/svgs/ArrowGoBack"
import { CustomScroll } from "react-custom-scroll"
import Bin from "presentation/Student/PaymentOptions/assets/Bin"
import BinActive from "presentation/Student/PaymentOptions/assets/BinActive"
import { cn } from "core/lib/utils"
import RemoveFilePopup2 from "presentation/Student/PaymentOptions/components/RemoveFilePopup2"
import ConfirmPaymentPopup2 from "presentation/Student/PaymentOptions/components/ConfirmPaymentPopup2"
import CrossCircle from "presentation/Student/PaymentOptions/assets/CrossCircle"
import Spinner from "core/components/Spinner"
import TickCircle from "presentation/Student/PaymentOptions/assets/TickCircle"
import BlankCircle from "presentation/Student/PaymentOptions/assets/BlankCircle"
import CheckBoxTick from "presentation/Student/PaymentOptions/assets/CheckBoxTick"
import CheckBox from "presentation/Student/PaymentOptions/assets/CheckBox"

type Result = {
  [key: string]: string | number
}

type ResultModalProps = {
  result: Result | null
  setResult?: any
  payDataAll?: any
  onClose: () => void
  paymentOptionDiscount?: any
  calculateOneshotDiscount?: any
  offerDate?: any
  payData?: any
  isCompletelyPaid?: any
  type?: any
  paymentDocuments?: any
  studentEMI?: any
  deletePaymentDocument?: any
  uploadPaymentDocument?: any
  getPaymentDocuments?: any
  months?: any
  toast?: any
  changeToastDetails?: any
  changeToastVisibility?: any
  STR_SUCCESS?: any
  selectedEMIOptionToChoose?: any
  createStudentEMI?: any
  amount?: any
  isAc?: any
  isAC2?: any
  isAccelerator?: any
  interestRate?: any
  interest?: any
  allowSubmit?: any
  allFilesStatus?: any
}

const UploadDocumentModal: React.FC<ResultModalProps> = ({
  result,
  getPaymentDocuments,
  studentEMI,
  deletePaymentDocument,
  uploadPaymentDocument,
  paymentDocuments,
  setResult,
  onClose,
  months,
  changeToastDetails,
  changeToastVisibility,
  STR_SUCCESS,
  selectedEMIOptionToChoose,
  createStudentEMI,
  amount,
  interestRate,
  interest,
  allowSubmit,
  allFilesStatus,
}) => {
  const [isActive, setIsActive] = useState("live")
  const [selectedType, setSelectedType] = useState("Coding Questions")
  const [loading, setLoading] = useState<number>(-1)
  const handleChangeFile = async (e: any, index: number) => {
    setLoading(index)
    await uploadPaymentDocument(e.target.files[0], paymentDocuments[index].filename, index)
    await getPaymentDocuments()
    setLoading(-1)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const scrollableRef = useRef<HTMLDivElement>(null)

  const [fileIndexToRemove, setFileIndexToRemove] = useState<number>(-1)

  useEffect(() => {
    if (result) {
      setIsModalVisible(true)
    }
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight
    }
  }, [result])

  const [showRemovePopup, setShowRemovePopup] = useState<boolean>(false)
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false)

  const resetState = () => {
    setIsActive("live")
    setSelectedType("Coding Questions")
  }

  const handleGoBack = () => {
    setResult(null)
    setIsModalVisible(false)
    setTimeout(() => {
      resetState()
      onClose()
    }, 500)
  }

  const handleRemoveFile = async (index: number) => {
    setFileIndexToRemove(index)
    setShowRemovePopup(true)
    await getPaymentDocuments()
  }

  const handleCancelRemove = () => {
    setShowRemovePopup(false)
    setFileIndexToRemove(-1)
  }

  const handleCancelPaymentConfirmation = () => {
    setShowPaymentPopup(false)
  }

  const handleConfirmRemove = async () => {
    setLoading(fileIndexToRemove)
    setShowRemovePopup(false)
    await deletePaymentDocument(fileIndexToRemove)
    await getPaymentDocuments()
    setFileIndexToRemove(-1)
    setLoading(-1)
  }

  const handleConfirmPaymentConfirmation = async () => {
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Documents uploaded successfully")
    const newStudentEMI = studentEMI
    newStudentEMI.is_submitted = true
    newStudentEMI.duration_index = selectedEMIOptionToChoose
    newStudentEMI.progress[0].status = 1
    newStudentEMI.progress[1].status = 1
    await createStudentEMI(newStudentEMI)
    setShowPaymentPopup(false)
  }

  const handleSubmitDocuments = () => {
    setShowPaymentPopup(true)
  }

  return (
    <>
      <Modal open={!!result} onClose={handleGoBack}>
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-all duration-500 ease-in-out">
          <div className="fixed inset-0 h-screen flex justify-end items-center overflow-y-auto">
            <div
              className={`bg-white w-[636px] h-full flex flex-col justify-between overflow-hidden transition-transform duration-500 ease-in-out ${
                isModalVisible ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                <div className="bg-white w-[636px] h-full  flex flex-col justify-start  ">
                  <div className="flex justify-between w-[636px] px-[24px] p-6 bg-[#F9F9F9]">
                    <div className="w-[588px] h-[43px] flex items-center justify-between">
                      <div>
                        <h2 className="text-[18px] font-semibold text-[#333333]">Submit Documents</h2>
                        <p className="text-[14px] text-gray-500">
                          In order to proceed please upload the necessary documents.
                        </p>
                      </div>
                      <button
                        className="flex h-[35px] w-[108px] items-center justify-center text-[14px] gap-1 text-gray-700 border px-2 rounded bg-white hover:bg-gray-100"
                        onClick={handleGoBack}
                      >
                        <ArrowGoBack className="w-4 h-[1.5rem]" />
                        Go Back
                      </button>
                    </div>
                  </div>
                  <div className="pl-6  w-full mt-4">
                    {result && (
                      <>
                        <div className="w-[587px]">
                          <div className=" w-fill overflow-hidden rounded-[4px] border-[#ADADAD] shadow-[0_0_0_0.5px_rgba(211,211,211,1)]">
                            <table className="w-full relative rounded-[4px]">
                              <thead className="h-[31px] bg-[#303030] sticky top-0 z-50">
                                <tr>
                                  <th className="text-left p-0 text-[12px] font-medium text-[#FFF] w-[30px] h-[15px] gap-[16px]"></th>
                                  <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[15px] w-[92px] h-[15px]">
                                    Payment Type
                                  </th>
                                  <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[70px] h-[15px]">
                                    Delete File
                                  </th>
                                  <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[100px] h-[15px]">
                                    Admin Approval
                                  </th>
                                  <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[55px] h-[15px]"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentDocuments.map((file: any, index: number) => (
                                  <tr className="hover:bg-gray-100">
                                    <td className="py-2 px-4 text-[12px] font-normal text-center">
                                      {loading === index ? (
                                        <Spinner xs />
                                      ) : file.is_uploaded === 1 ? (
                                        <TickCircle />
                                      ) : studentEMI.is_submitted === true ? (
                                        <CrossCircle />
                                      ) : (
                                        <BlankCircle />
                                      )}
                                    </td>
                                    <td className="py-2 px-4 text-[12px] font-normal">
                                      <span className="py-2">
                                        {file.filename} <span className="text-[#DC2626]">*</span>{" "}
                                      </span>{" "}
                                    </td>
                                    <td className="py-2 px-4 text-[12px] font-normal h-[44px] flex justify-center items-center">
                                      <button
                                        onClick={() => handleRemoveFile(index)}
                                        disabled={
                                          file.is_verified !== 1 || file.is_uploaded !== 0
                                            ? studentEMI.is_submitted === true &&
                                              (studentEMI.progress[2].status !== -1 || file.is_verified === 1)
                                            : false
                                        }
                                      >
                                        {file.is_verified !== 1 && file.is_uploaded !== 0 ? (
                                          studentEMI.is_submitted === true && studentEMI.progress[2].status !== -1 ? (
                                            <Bin />
                                          ) : (
                                            <BinActive />
                                          )
                                        ) : (
                                          <Bin />
                                        )}
                                      </button>
                                    </td>
                                    <td className="py-2 px-4 text-[12px] font-normal text-center">
                                      {file.is_verified === 1 ? (
                                        <CheckBoxTick />
                                      ) : file.is_verified === 0 ? (
                                        <CheckBox />
                                      ) : (
                                        <CrossCircle />
                                      )}
                                    </td>
                                    <td className="py-2 px-4 text-[12px] font-normal text-center">
                                      <label
                                        className={cn(
                                          `font-[400] leading-normal cursor-pointer text-[14px] gap-1 border px-[16px] py-[6px] rounded bg-white hover:bg-gray-100`,
                                          (
                                            file.is_verified === 1 || file.is_uploaded !== 0
                                              ? true
                                              : studentEMI.is_submitted === true && studentEMI.progress[2].status === 1
                                          )
                                            ? "text-[#D3D3D3] cursor-not-allowed hover:bg-white"
                                            : "text-[#646464]"
                                        )}
                                      >
                                        Upload
                                        <input
                                          type="file"
                                          className="hidden"
                                          accept="application/pdf"
                                          disabled={
                                            file.is_verified === 1 || file.is_uploaded !== 0
                                              ? true
                                              : studentEMI.is_submitted === true && studentEMI.progress[2].status === 1
                                          }
                                          onChange={(e) => handleChangeFile(e, index)}
                                        />
                                      </label>
                                    </td>
                                  </tr>
                                ))}
                                <td colSpan={3} className="py-2"></td>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="w-[587px] flex flex-col gap-8px">
                          <div className="grid grid-cols-2 pt-6">
                            <div className="text-[10px] font-medium">
                              Total Payment:
                              {allFilesStatus ? (
                                <span className="text-[#00C22B]"> (Upload Completed) </span>
                              ) : (
                                <span className="text-[#DC2626]"> (Upload Pending) </span>
                              )}
                              <div className="text-base font-semibold text-[#312E81]">
                                Rs.
                                {Math.ceil(
                                  amount +
                                    (amount *
                                      interestRate[
                                        studentEMI?.duration_index !== -1 ? studentEMI?.duration_index : interest
                                      ]) /
                                      100
                                )
                                  ? Math.ceil(
                                      amount +
                                        (amount *
                                          interestRate[
                                            studentEMI?.duration_index !== -1 ? studentEMI?.duration_index : interest
                                          ]) /
                                          100
                                    )
                                  : amount}
                              </div>
                            </div>
                            <div className="flex justify-end">
                              {studentEMI?.progress?.length > 0 &&
                                (studentEMI?.progress?.[2]?.status !== 1 ? (
                                  <button
                                    className={cn(
                                      "py-[9px] px-[16px] text-[14px] rounded-[4px] bg-[#162456] text-[#FFFFFF]",
                                      allowSubmit ? "hover:bg-new-accent hover:text-new-solid-white" : "opacity-[0.3]"
                                    )}
                                    disabled={!allowSubmit}
                                    onClick={handleSubmitDocuments}
                                  >
                                    Submit Documents
                                  </button>
                                ) : (
                                  <div className="ml-auto block w-fit text-sm font-semibold leading-normal text-[#00C22B]">
                                    You have successfully selected {months?.[selectedEMIOptionToChoose]} month EMI
                                    payment option.
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </CustomScroll>
            </div>
          </div>
          {showRemovePopup && (
            <RemoveFilePopup2
              paymentDocuments={paymentDocuments}
              index={fileIndexToRemove}
              onCancel={handleCancelRemove}
              onConfirm={handleConfirmRemove}
            />
          )}

          {showPaymentPopup && (
            <ConfirmPaymentPopup2
              months={months[selectedEMIOptionToChoose]}
              onCancel={handleCancelPaymentConfirmation}
              onConfirm={handleConfirmPaymentConfirmation}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
export default UploadDocumentModal
