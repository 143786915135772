import { Auth } from "domain/model/Auth"
import { ICPStudentRepository } from "domain/repository/Admin/ICPStudentRepository"

export interface GetICPStudentsOfBatchUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetICPStudentsOfBatch implements GetICPStudentsOfBatchUseCase {
  private repository: ICPStudentRepository

  constructor(repository: ICPStudentRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getICPStudentsOfBatch(auth)
  }
}
