import { SuperAdminDashboardAPIDataSourceImpl } from "data/API/SuperAdmin/SuperAdminDashboardAPIDataSourceImpl"
import { SuperAdminDashboardRepositoryImpl } from "data/repository/SuperAdmin/SuperAdminDashboardRepositoryImpl"
import { AdminInterface } from "domain/model/SuperAdminDashboard"
import { deleteAdmin } from "domain/useCase/SuperAdmin/SuperAdminDashboard/DeleteAdmin"
import { getAllAdmins } from "domain/useCase/SuperAdmin/SuperAdminDashboard/GetAllAdmins"
import { useAuth } from "core/context/auth"
import { updateRoleAdmin } from "domain/useCase/SuperAdmin/SuperAdminDashboard/UpdateRoleAdmin"

import { useState } from "react"

export default function SuperAdminDashboardViewModel() {
  const superAdminTableHeaders = ["General Info", "Admin To Super Admin", "Delete Admin"]

  const [searchByEmail, setSearchByEmail] = useState<string>("")
  const [searchByName, setSearchByName] = useState<string>("")
  const [adminsPersist, setAdminPersist] = useState<AdminInterface[]>([])
  const [admins, setAdmins] = useState<AdminInterface[]>([])
  const [refresh, setRefresh] = useState(false)
  const [deleteEmail, setDeleteEmail] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const { auth } = useAuth()

  const getAllAdminsUseCase = new getAllAdmins(
    new SuperAdminDashboardRepositoryImpl(new SuperAdminDashboardAPIDataSourceImpl())
  )

  const deleteAdminUseCase = new deleteAdmin(
    new SuperAdminDashboardRepositoryImpl(new SuperAdminDashboardAPIDataSourceImpl())
  )

  const updateRoleAdminUseCase = new updateRoleAdmin(
    new SuperAdminDashboardRepositoryImpl(new SuperAdminDashboardAPIDataSourceImpl())
  )

  async function getAllAdmin() {
    const details = {
      email: searchByEmail,
      name: searchByName,
    }

    // Fetch all admins if no search criteria are provided

    const response = await getAllAdminsUseCase.invoke(details, auth)
    if (response) {
      setAdminPersist(response.data)
      setAdmins(response.data)
    }
    return // Exit after fetching all data

    // Local filtering logic with case-insensitive matching
  }

  function searchByNameAndEmailHandle() {
    if (searchByEmail.length !== 0 || searchByName.length !== 0) {
      const filteredAdmins = adminsPersist.filter((admin: any) => {
        const emailMatch = searchByEmail ? admin.email.toLowerCase().includes(searchByEmail.toLowerCase()) : true
        const nameMatch = searchByName ? admin.name.toLowerCase().includes(searchByName.toLowerCase()) : true

        return emailMatch && nameMatch
      })

      // Update state with filtered results
      setAdmins(filteredAdmins)
    } else {
      setAdmins(adminsPersist)
    }
  }

  async function deleteAdminHandle(email: string) {
    console.log(email)
    const details = {
      email: email,
    }
    const response = await deleteAdminUseCase.invoke(details, auth)

    if (response) {
      setRefresh(!refresh)
    }
  }

  async function updateRoleAdminHandle(email: string, isSuperAdmin: boolean) {
    const details = {
      email: email,
      isSuperAdmin: isSuperAdmin,
    }
    const response = await updateRoleAdminUseCase.invoke(details, auth)
  }

  const handleSearchByEmail = (e: any) => {
    setSearchByEmail(e.target.value)
  }

  const handleSearchByName = (e: any) => {
    setSearchByName(e.target.value)
  }
  return {
    searchByEmail,
    handleSearchByEmail,
    searchByName,
    handleSearchByName,
    getAllAdmin,
    superAdminTableHeaders,
    deleteAdminHandle,
    updateRoleAdminHandle,
    searchByNameAndEmailHandle,
    admins,
    refresh,
    setDeleteModal,
    deleteModal,
    deleteEmail,
    setDeleteEmail,
  }
}
