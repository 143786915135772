import pn from "core/utils/pn"
import { API_GET_LAST_FEEDBACK, API_SAVE_FEEDBACK, API_STUDENT, API_GET_JOINING_DATE } from "core/constants/strings"
import FeedbackDataSource from "data/dataSource/Student/NewFeedbackDataSource"
import { Auth } from "domain/model/Auth"
import { FeedbackPage } from "domain/model/NewFeedback"
import { Server } from "core/utils/axios"
const server = new Server()

export class FeedbackAPIDataSourceImpl implements FeedbackDataSource {
  async getLastFeedback(auth: Auth, page: string): Promise<{ last_feedback_time: string }> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_GET_LAST_FEEDBACK),
        {
          page: page,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      if (response.data && response.data.last_feedback_time) {
        return { last_feedback_time: response.data.last_feedback_time }
      } else {
        return { last_feedback_time: "" }
      }
    } catch (error) {
      console.error("Error fetching feedback:", error)
      return { last_feedback_time: "" }
    }
  }

  async getJoiningDate(auth: Auth): Promise<{ joining_date: string }> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_GET_JOINING_DATE),
        {},
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      if (response.data && response.data.joining_date) {
        return { joining_date: response.data.joining_date }
      } else {
        return { joining_date: "" }
      }
    } catch (error) {
      console.error("Error fetching joining date:", error)
      return { joining_date: "" }
    }
  }

  async saveFeedback(auth: Auth, pages: Record<string, FeedbackPage[]>): Promise<void> {
    try {
      await server.post(
        pn(API_STUDENT, API_SAVE_FEEDBACK),
        {
          ...pages,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
    } catch (error) {
      console.error("Error saving feedback:", error)
      throw new Error("Error saving feedback")
    }
  }
}
