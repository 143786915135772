import React, { useState, useEffect } from "react"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import { useAuth } from "core/context/auth"
import { useParams } from "react-router-dom"
import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import CreateMentorSession from "domain/useCase/Admin/MentorSessions/CreateMentorSession"
import { MentorSessionRepositoryImpl } from "data/repository/Admin/MentorSessionRepositoryImpl"
import { MentorSession } from "domain/model/MentorSession"
import MentorSessionDataSourceImpl from "data/API/Admin/MentorSessionDataSourceImpl"
import MentorsViewModel from "presentation/Admin/MentorSessions/MentorsViewModel"

export default function CreateMentorSessionForm({ reloadData, setReloadData }: any) {
  const { id } = useParams()
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<any>()
  const [startTime, setStartTime] = useState<number>(0)
  const [endTime, setEndTime] = useState<number>(0)
  const [duration, setDuration] = useState<number>(60)
  const [mentorForSession, setMentorForSession] = React.useState<string>("")
  const [studentId, setStudentId] = useState<string>("")
  const [batchForSession, setBatchForSession] = useState<string>("")
  const { getBatchList, allMentorsList, fetchAllMentorList, getBatches } = MentorsViewModel()
  const [mentorSessions, setMentorSessions] = useState<MentorSession[]>([])

  const CreateMentorSessionUseCase = new CreateMentorSession(
    new MentorSessionRepositoryImpl(new MentorSessionDataSourceImpl())
  )

  async function createMentorSession() {
    setLoading(true)
    const mentorSession: MentorSession = {
      auth,
      student_id: studentId,
      mentor_id: mentorForSession,
      start_time: startTime.toString(),
      end_time: endTime.toString(),
      date: date.toString(),
      duration: duration.toString(),
      batch: batchForSession,
      session_status: "completed",
    }

    const response = await CreateMentorSessionUseCase.invoke(auth, mentorSession)
    setLoading(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Mentor Session Created!")

    setMentorSessions([...mentorSessions, mentorSession])
    setReloadData(!reloadData)

    setDate("")
    setStartTime(0)
    setEndTime(0)
    setDuration(60)
    setStudentId("")
    setBatchForSession("")
  }

  useEffect(() => {
    fetchAllMentorList()
    getBatches()
    setMentorForSession(id as string)
  }, [auth])

  function handleStartTime(e: any) {
    setStartTime(e.target.value)
  }

  function handleEndTime(e: any) {
    setEndTime(e.target.value)
  }

  function handleDateChange(e: any) {
    setDate(e.target.value)
  }

  function handleDuration(e: any) {
    setDuration(e.target.value)
  }

  return (
    <div className="">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col">
          <h4>Date Of Session</h4>
          <Input placeholder="Date" type="date" value={date} onChange={handleDateChange} />
        </div>

        <div className="flex flex-col">
          <h4>Start Time</h4>
          <Input
            placeholder="Start Time in 24 hours format"
            type="number"
            value={startTime}
            onChange={handleStartTime}
          />
        </div>

        <div className="flex flex-col">
          <h4>End Time</h4>
          <Input placeholder="End Time in 24 hours format" type="number" value={endTime} onChange={handleEndTime} />
        </div>

        <div className="flex flex-col">
          <h4>Duration</h4>
          <Input placeholder="Duration in minutes" type="number" onChange={handleDuration} value={duration} />
        </div>

        <div className="flex flex-col">
          <h4>Batch</h4>
          <select value={batchForSession} onChange={(e) => setBatchForSession(e.target.value)} className="py-1">
            <option value="">Select Batch</option>
            {getBatchList?.map((item: string, index: number) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <h4>Student Email</h4>
          <Input
            type="text"
            value={studentId}
            placeholder="Student Email"
            onChange={(e) => setStudentId(e.target.value)}
          />
        </div>

        <Button loading={loading} onClick={createMentorSession}>
          Add Mentor Session
        </Button>
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
