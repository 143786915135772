import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { API_CREATE_EXTRA_MENTOR_SESSION, API_V4_ADMIN } from "core/constants/strings"
import MentorSessionDataSource from "data/dataSource/Admin/MentorSessionDataSource"
import { MentorSession } from "domain/model/MentorSession"

export default class MentorSessionDataSourceImpl implements MentorSessionDataSource {
  async createExtraMentorSession(auth: Auth, mentor_session: MentorSession): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_EXTRA_MENTOR_SESSION), {
        id_token: auth?.id_token,
        mentor_session,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
