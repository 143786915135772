import { Auth } from "domain/model/Auth"
import AuthDataSource from "data/dataSource/Common/AuthDataSource"
import {
  API_FORGOT_PASSWORD,
  API_FREE_SIGNUP,
  API_RESET_PASSWORD_BY_STUDENT,
  API_V4_RESET,
  LOGIN,
  TOKEN_REFRESH,
} from "core/constants/strings"
import { User } from "domain/model/User"
import pn from "core/utils/pn"
import { Server, postAPI } from "core/utils/axios"
import { ResetPassword } from "domain/model/ResetPassword"

const server = new Server()

export default class AuthAPIDataSourceImpl implements AuthDataSource {
  async login(user: User): Promise<any> {
    try {
      const response = await server.post(pn(LOGIN), user)
      return response
    } catch (error) {
      return error
    }
  }

  async forgotPassword(email: string) {
    try {
      const response = await server.post(pn(API_FORGOT_PASSWORD), { email: email })
      return response
    } catch (error) {
      return error
    }
  }

  async resetPasswordByStudent(auth: Auth, user: ResetPassword) {
    try {
      const response = await server.post(
        pn(API_RESET_PASSWORD_BY_STUDENT),
        { email: user?.email, old_password: user?.oldPassword, new_password: user?.newPassword },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async resetPassword(email: string) {
    try {
      const response = await postAPI(pn(API_V4_RESET), { email: email })
      return response
    } catch (error) {
      return error
    }
  }

  async signUp(user: User) {
    try {
      const response = await postAPI(pn(API_FREE_SIGNUP), user)
      return response
    } catch (error) {
      return error
    }
  }
}
