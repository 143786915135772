import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetBatchCertificatesUseCase {
  invoke(auth: Auth, batch: string, courseName: string): Promise<any>
}

export class GetBatchCertificates implements GetBatchCertificatesUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch: string, courseName: string) {
    return this.repository.getBatchCertificates(auth, batch, courseName)
  }
}
