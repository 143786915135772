import Loader from "core/components/Loader"
import { CrossIcon } from "core/constants/svgs"

interface FeedbackModalProps {
  feedbackData: {
    ai_feedbck: string
    ai_marks: number
    question_id: string
    student_ai_feedback: {
      ai_model_rating: string
      expected_marks: string
      student_feedback: string
    }
    student_answer: string
    student_batch: string
    student_email: string
    student_name: string
    student_phone: string
    timestamp: Date
  }
  isLoading: boolean
  onClose: () => void
  rateAIModal: () => void
}
const AISubmissionResponse = ({ feedbackData, isLoading, onClose, rateAIModal }: FeedbackModalProps) => {
  if (isLoading) {
    return (
      <div className=" h-full w-full grid place-items-center">
        <Loader small />
      </div>
    )
  }
  function formatDate(timestamp: Date): string {
    const date = new Date(timestamp)

    const day = date.getDate()
    const year = date.getFullYear()
    const month = date.toLocaleString("default", { month: "long" })
    const getOrdinalSuffix = (day: number) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`

    return `${dayWithSuffix} ${month}, ${year}`
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[452px]">
        <div className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-[#02239a] text-base font-semibold font-['Inter']">Assignment Feedback</h3>
              <p className="text-[#636363] text-sm font-medium font-['Inter']">{formatDate(feedbackData?.timestamp)}</p>
            </div>
            <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
              <CrossIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-4">
            <h4 className="w-[402px] text-[#333232] text-sm font-semibold font-['Inter']">AI Feedback</h4>
            <p className="w-[136px] my-[11px] h-[25px] text-[#07b32d] text-xs font-semibold font-['Inter']">
              Marks: {feedbackData?.ai_marks} / 100
            </p>
          </div>
          <div className="mb-[11px] p-2.5 bg-[#f8f8f8] rounded justify-center items-center gap-2.5 inline-flex">
            <p className="text-[#646464] text-sm font-normal font-['Inter']">{feedbackData?.ai_feedbck}</p>
          </div>
          <div className="h-[93px] p-2.5 rounded flex-col justify-center items-center gap-1 inline-flex">
            <div className="w-[382px] text-red-600 text-sm font-medium font-['Inter'] leading-[21px]">** Note</div>
            <p className="w-[382px] text-[#636363] text-xs font-medium font-['Inter'] leading-4">
              This feedback has been provided by an AI model. If you are not satisfied with this feedback, feel free to
              reach out to us and rate this feedback.
            </p>
          </div>
          <div className="mt-6 w-full flex justify-end ">
            <button
              className="w-1/3 py-2 hover:bg-[#D3D3D3] bg-white rounded border border-[#d3d3d3] justify-center items-center text-[#333232] text-sm font-medium font-['Inter']"
              onClick={rateAIModal}
            >
              Rate AI Model
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AISubmissionResponse
