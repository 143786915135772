import DashboardLayout from "core/layouts/DashboardLayout"
import useHomeViewModel from "./HomeViewModel"

export default function HomeView() {
  const { schedule, setSchedule, handleChange, generateDates } = useHomeViewModel()

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <h2>Set Your Availability</h2>
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Date</th>
              <th className="px-4 py-2 text-left">Availability</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(schedule).map((date) => (
              <tr key={date} className="border-b">
                <td className="px-4 py-2">{date}</td>
                <td className="px-4 py-2">
                  <select
                    className="border w-[100px] rounded px-2 py-1"
                    value={schedule[date]}
                    onChange={(e) => handleChange(date, e.target.value as "Yes" | "No")}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  )
}
