import {
  API_ADD_STUDENT_DETAILS,
  API_GET_STUDENT_DETAILS,
  API_V4_STUDENT,
  PLACEMENT,
  STR_APPLY_FOR_JOB,
  STR_GET_ALL_JOBS,
  STR_GET_APPLICATION_STATUS,
  STR_GET_JOB_DETAILS,
  STR_GET_NEW_PLACEMENT,
  STR_GET_RECENT_PLACEMENTS,
  STR_GET_STUDENT_JOBS,
  STUDENT,
} from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { PlacementDataSource } from "data/dataSource/Student/PlacementDataSource"
import { Auth } from "domain/model/Auth"
import { jobDetails } from "domain/model/Placement"
const server = new Server()

export class PlacementAPIDataSourceImpl implements PlacementDataSource {
  async getRecentPlacement(auth: Auth): Promise<any> {
    const response = await postAPI(pn(STR_GET_RECENT_PLACEMENTS), {
      id_token: auth.id_token,
    })
    return response
  }
  async getNewPlacement(auth: Auth): Promise<any> {
    const response = await postAPI(STR_GET_NEW_PLACEMENT, {
      id_token: auth.id_token,
    })
    return response
  }
  async getAllJobs(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, STR_GET_ALL_JOBS), {
      id_token: auth.id_token,
    })
    return response
  }

  async getJobDetails(request: jobDetails): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, STR_GET_JOB_DETAILS), {
      id_token: request.id_token,
      job_id: request.job_id,
    })
    return response
  }

  async getApplicationDetails(request: jobDetails): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, STR_GET_APPLICATION_STATUS), {
      id_token: request.id_token,
      job_id: request.job_id,
    })
    return response
  }

  async getStudentsJob(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, STR_GET_STUDENT_JOBS), {
      id_token: auth.id_token,
    })
    return response
  }

  async applyForJob(request: jobDetails): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, STR_APPLY_FOR_JOB), {
      id_token: request.id_token,
      job_id: request.job_id,
    })
    return response
  }

  async getStudentDetails(auth: Auth, docId: String): Promise<any> {
    try {
      const response = await server.post(
        pn(STUDENT, PLACEMENT, API_GET_STUDENT_DETAILS),
        {
          docId: docId,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async updateStudentDetails(auth: Auth, docId: string, updatedDetails: any, resumeFile: any): Promise<any> {
    try {
      const formData = new FormData()
      formData.append("resumeFile", resumeFile)
      formData.append("docId", docId)
      formData.append("updatedDetails", JSON.stringify(updatedDetails))
      const response = await server.postFile(pn(STUDENT, PLACEMENT, API_ADD_STUDENT_DETAILS), formData, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
