import React from "react"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import ClassAPIDataSourceImpl from "data/API/Mentor/ClassAPIDataSourceImpl"
import { ClassRepositoryImpl } from "data/repository/Mentor/ClassRepositoryImpl"
import { GetAllClasses } from "domain/useCase/Mentor/Class/GetAllClasses"
import { ClassReminderScheduling } from "domain/useCase/Mentor/Class/ClassReminderScheduling"
import GetClassFeedbackAdminNew from "domain/useCase/Admin/AllClasses/GetClassFeedbackAdminNew"
import { GetAllClassesRepositoryImpl } from "data/repository/Admin/GetAllClassesRepositoryImpl"
import GetAllClassesAPIDataSourceImpl from "data/API/Admin/GetAllClassesAPIDataSourceImpl"

export default function LiveClassViewModel() {
  const { auth } = useAuth()
  const { mentor } = useApp()
  const { toast, changeToastVisibility } = useToast()
  const [sessions, setSessions] = React.useState<any[]>([])

  const getAllClassesUseCase = new GetAllClasses(new ClassRepositoryImpl(new ClassAPIDataSourceImpl()))
  const classReminderSchedulingUseCase = new ClassReminderScheduling(
    new ClassRepositoryImpl(new ClassAPIDataSourceImpl())
  )
  const GetClassFeedbackAdmniNewUseCase = new GetClassFeedbackAdminNew(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )

  async function fetchAllClasses() {
    const response = await getAllClassesUseCase.invoke(auth)
    setSessions(response?.data)
  }

  async function classReminderScheduling(sessionId: any) {
    await classReminderSchedulingUseCase.invoke(auth, sessionId)
  }

  async function getDetailedRating(sessionId: any) {
    const response = await GetClassFeedbackAdmniNewUseCase.invoke(auth, sessionId)
    if (!response?.success) {
      return
    }
    const ratingTitles = response?.data[0].rating.map((item: any) => item.title)
    const headers = ["Student ID", "Batch", "Comment", "Average Rating", ...ratingTitles]

    let csvContent = `data:text/csv;charset=utf-8,${headers.join(",")}\n`

    response?.data.forEach((item: any) => {
      const stars = item.rating.map((rating: any) => rating.star)
      const row = [item?.student_id, item?.batch, item?.comment, item?.avg_rating, ...stars].join(",")
      csvContent += `${row}\n`
    })
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "Ratings_Data.csv")
    document.body.appendChild(link)
    link.click()
  }

  return {
    toast,
    mentor,
    sessions,
    getDetailedRating,
    fetchAllClasses,
    changeToastVisibility,
    classReminderScheduling,
  }
}
