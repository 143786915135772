import { Auth } from "domain/model/Auth"
import { PlacementRepository } from "domain/repository/Student/PlacementRepository"

export interface UpdateStudentDetailsuseCase {
  invoke(auth: Auth, docId: string, updatedDetails: any, resumeFile: any): Promise<any>
}

export class UpdateStudentDetails implements UpdateStudentDetailsuseCase {
  private repository: PlacementRepository

  constructor(repository: PlacementRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: string, updatedDetails: any, resumeFile: any): Promise<any> {
    return await this.repository.updateStudentDetails(auth, docId, updatedDetails, resumeFile)
  }
}
