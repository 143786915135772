import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface GetGoalSettingSessionsUseCase {
  invoke(auth: Auth, mentor_id: string, month: number): Promise<any>
}

export default class GetGoalSettingSessions implements GetGoalSettingSessionsUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, month: number): Promise<any> {
    return await this.repository.getGoalSettingSessionsMentor(auth, mentor_id, month)
  }
}
