import React, { useState, useEffect, useRef } from "react"
import { Modal } from "@mui/material"
import ArrowGoBack from "assets/svgs/ArrowGoBack"
import { CustomScroll } from "react-custom-scroll"
import Information from "assets/svgs/Information"

type Result = {
  [key: string]: string | number
}

type ResultModalProps = {
  result: Result | null
  setResult?: any
  payDataAll?: any
  onClose: () => void
  paymentOptionDiscount?: any
  calculateOneshotDiscount?: any
  offerDate?: any
  payData?: any
  isCompletelyPaid?: any
  type?: any
  expired?: any
}

const FeesBreakdownModal: React.FC<ResultModalProps> = ({
  result,
  setResult,
  onClose,
  payDataAll,
  paymentOptionDiscount,
  calculateOneshotDiscount,
  offerDate,
  payData,
  isCompletelyPaid,
  type,
  expired,
}) => {
  function calculatePayableAmount() {
    if (payDataAll?.instalments?.length <= 2) {
      return paymentOptionDiscount?.fees_after_discount - calculateOneshotDiscount()
    } else {
      return paymentOptionDiscount?.fees_after_discount
    }
  }

  function formatDate(dateString: string) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const scrollableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (result) {
      setIsModalVisible(true)
    }
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight
    }
  }, [result])

  const handleGoBack = () => {
    setResult(null)
    setIsModalVisible(false)
    setTimeout(() => {
      onClose()
    }, 500)
  }

  function calculateAmountAndInstalment() {
    const createdInstallments = payData.filter(
      (installment: { status: string; due_date: string }) => installment.status === "created"
    )

    if (createdInstallments.length > 0) {
      return [createdInstallments[0].amount, payData.length - createdInstallments.length + 1]
    } else {
      return [0, 0]
    }
  }

  return (
    <Modal open={!!result} onClose={handleGoBack}>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-all duration-500 ease-in-out">
        <div className="fixed inset-0 h-screen flex justify-end items-center overflow-y-auto">
          <div
            className={`bg-white w-[636px] h-full flex flex-col justify-between overflow-hidden transition-transform duration-500 ease-in-out ${
              isModalVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
              <div className="bg-white w-[636px] h-full  flex flex-col justify-start  ">
                <div className="flex justify-between w-[636px] px-[24px] p-6 bg-[#F9F9F9]">
                  <div className="w-[588px] h-[43px] flex items-center justify-between">
                    <div>
                      <h2 className="text-[18px] font-semibold text-[#333333]">Fees Breakdown</h2>
                      <p className="text-[14px] text-gray-500">The payment breakdown is shown here.</p>
                    </div>
                    <button
                      className="flex h-[35px] w-[108px] items-center justify-center text-[14px] gap-1 text-gray-700 border px-2 rounded bg-white hover:bg-gray-100"
                      onClick={handleGoBack}
                    >
                      <ArrowGoBack className="w-4 h-[1.5rem]" />
                      Go Back
                    </button>
                  </div>
                </div>
                <div className="pl-6  w-full mt-4">
                  {result && (
                    <>
                      <div className="w-[587px]">
                        <div className=" w-fill overflow-hidden rounded-[4px] border-[#ADADAD] shadow-[0_0_0_0.5px_rgba(211,211,211,1)]">
                          <table className="w-full relative rounded-[4px]">
                            <thead className="h-[31px] bg-[#303030] sticky top-0 z-50">
                              <tr>
                                <th className="text-left p-0 text-[12px] font-medium text-[#FFF] px-[16px] w-[20px] h-[15px] gap-[16px]">
                                  Sr. No.
                                </th>
                                <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[15px] w-[130px] h-[15px]">
                                  Payment Type
                                </th>
                                <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[90px] h-[15px]">
                                  ₹ Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="hover:bg-gray-100">
                                <td className="py-2 px-4 text-[12px] font-normal">{`1`}</td>
                                <td className="py-2 px-4 text-[12px] font-normal">
                                  <span className="py-2">Total Course Fees </span>{" "}
                                </td>
                                <td className="py-2 px-4 text-[12px] font-normal">{`${payDataAll?.fees} ₹`}</td>
                              </tr>

                              <tr className="hover:bg-gray-100">
                                <td className="py-2 px-4 text-[12px] font-normal">{`2`}</td>
                                <td className="py-2 px-4 text-[12px] font-normal">
                                  <span className="py-2">Seat Booking Amount </span>{" "}
                                </td>
                                <td className="py-2 px-4 text-[12px] font-normal">
                                  {`${payDataAll?.seat_booking_amount} ₹`}
                                </td>
                              </tr>

                              <tr className="hover:bg-gray-100">
                                <td className="py-2 px-4 text-[12px] font-normal">{`3`}</td>
                                <td className="py-2 px-4 text-[12px] font-normal">
                                  <span className="py-2">Standard Discount </span>{" "}
                                </td>
                                <td className="py-2 px-4 text-[12px] font-normal text-[#DC2626]">
                                  {` ${payDataAll?.standard_discount} ₹`}
                                </td>
                              </tr>

                              <tr className={`hover:bg-gray-100 ${expired ? "opacity-20" : "opacity-100"}`}>
                                <td className="py-2 px-4 text-[12px] font-normal">{`4`}</td>
                                <td className="py-2 px-4 text-[12px] font-normal">
                                  <span className="py-2">{`Offer Period Discount  `}</span>{" "}
                                  <span className="text-[#DC2626]">(Applicable till {offerDate})</span>
                                </td>
                                <td className="py-2 px-4 text-[12px] font-normal text-[#DC2626]">
                                  {`${payDataAll?.offer_discount} ₹ `}
                                </td>
                              </tr>

                              {type != "Direct Installments" && (
                                <tr className="hover:bg-gray-100">
                                  <td className="py-2 px-4 text-[12px] font-normal">{`5`}</td>
                                  <td className="py-2 px-4 text-[12px] font-normal">
                                    {`One Shot Payment Discount `}
                                    <span className="text-[#DC2626]">( {payDataAll?.one_shot_discount}% ) </span>
                                  </td>
                                  <td className="py-2 px-4 text-[12px] font-normal text-[#DC2626]">
                                    {`${calculateOneshotDiscount()} ₹`}
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td colSpan={3} className="py-2"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="w-[587px] flex flex-col gap-8px mt-4  ">
                        {type == "Direct Installments" && (
                          <div className="flex items-center justify-between rounded-[0.25rem] bg-[#333333] p-[16px] h-[49px]">
                            <div className="font-sans text-[14px] font-semibold leading-normal  text-[#FFFFFF]">
                              {isCompletelyPaid === false ? (
                                <>
                                  <div className="flex gap-[8px] justify-center items-center">
                                    <p className="">Balance Amount</p>
                                    <div>
                                      <Information className="w-4 h-[1.5rem]" />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                "Payable Amount :"
                              )}
                            </div>
                            <button className="inline-flex w-[15vw] items-center justify-end rounded-sm p-2 text-[14px] font-medium text-[#FFFFFF]">
                              {`${calculatePayableAmount()} ₹`}
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {type == "Direct Installments" && (
                  <>
                    <div className="flex justify-between w-[636px] px-[24px] p-6 bg-[#F9F9F9] pb-0">
                      <div className="w-[588px] h-[43px] flex items-center justify-between">
                        <div>
                          <h2 className="text-[18px] font-semibold">Installment Breakdown</h2>
                          <p className="text-[14px] text-gray-500">
                            All your installment breakdown has been shown here.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow pl-6  w-full mt-4">
                      {result && (
                        <>
                          <div className="w-[587px]">
                            <div className=" w-fill overflow-hidden rounded-[4px] border-[#ADADAD] shadow-[0_0_0_0.5px_rgba(211,211,211,1)]">
                              <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                                <table className="w-full relative rounded-[4px]">
                                  <thead className="h-[31px] bg-[#303030] sticky top-0 z-50">
                                    <tr>
                                      <th className="text-left p-0 text-[12px] font-medium text-[#FFF] px-[16px] w-[40px] h-[15px] gap-[16px] pr-0"></th>
                                      <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[15px] w-[50px] h-[15px] gap-[16px]">
                                        Sr. No.
                                      </th>
                                      <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[15px] w-[75px] h-[15px]">
                                        Payment Type
                                      </th>
                                      <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[45px] h-[15px]">
                                        Date
                                      </th>
                                      <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[80px] h-[15px]">
                                        ₹ Amount
                                      </th>
                                      <th className="text-left p-0 text-[12px] font-medium text-[#FFF] px-[14px] w-[110px] h-[15px]">
                                        Payment Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {payDataAll?.instalments?.map((instalment: any, index: any) => (
                                      <tr className="hover:bg-gray-100">
                                        <td className="py-2 px-4 text-[12px] font-normal pr-0">
                                          <input
                                            type="checkbox"
                                            className="rounded-[2px] border-[#D3D3D3] checked:bg-[#10b133]"
                                            checked={instalment.status === "paid" ? true : false}
                                            readOnly
                                          />
                                        </td>
                                        <td className="py-2 px-4 text-[12px] font-normal">{`${index + 1}`}</td>

                                        <td className="py-2 px-4 text-[12px] font-normal">
                                          {`Instalment ${index + 1}`}
                                        </td>

                                        <td className="py-2 px-4 text-[12px] font-normal">
                                          {formatDate(instalment?.due_time)}
                                        </td>

                                        <td className="py-2 px-4 text-[12px] font-normal">
                                          {`${payData[index]?.amount} ₹`}
                                        </td>

                                        <td
                                          className={`${instalment.status != "paid" ? "text-[#DC2626] " : ""}py-2 px-4 text-[12px] font-normal`}
                                        >
                                          {instalment.status === "paid" ? "Paid" : "Due"}{" "}
                                        </td>
                                      </tr>
                                    ))}

                                    <td colSpan={3} className="py-2"></td>
                                  </tbody>
                                </table>
                              </CustomScroll>
                            </div>
                          </div>

                          <div className="w-[587px] flex flex-col gap-8px mt-4 mb-4">
                            <div className="flex items-center justify-between rounded-[0.25rem] bg-[#07B42D] p-[16px] h-[49px]">
                              <div className="font-sans text-[14px] font-semibold leading-normal  text-[#FFFFFF]">
                                {isCompletelyPaid === false ? (
                                  <>
                                    <div className="flex gap-[8px] justify-center items-center">
                                      <p className="">Payable Amount</p>
                                      <div>
                                        <Information className="w-4 h-[1.5rem]" />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  "Payable Amount :"
                                )}
                              </div>
                              <button className="inline-flex w-[15vw] items-center justify-end rounded-sm p-2 text-[14px] font-medium text-[#FFFFFF]">
                                {`${calculateAmountAndInstalment()[0]} ₹`}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

                {type == "One Shot" && (
                  <div className="flex-grow pl-6 w-full">
                    <div className="w-[587px] flex flex-col gap-8px">
                      <div className="flex items-center justify-between rounded-[0.25rem] bg-[#07B42D] p-[16px] h-[49px]">
                        <div className="font-sans text-[14px] font-semibold leading-normal  text-[#FFFFFF]">
                          {isCompletelyPaid === false ? (
                            <>
                              <div className="flex gap-[8px] justify-center items-center">
                                <p className="">Payable Amount</p>
                                <div>
                                  <Information className="w-4 h-[1.5rem]" />
                                </div>
                              </div>
                            </>
                          ) : (
                            "Payable Amount :"
                          )}
                        </div>
                        <button className="inline-flex w-[15vw] items-center justify-end rounded-sm p-2 text-[14px] font-medium text-[#FFFFFF]">
                          {`${calculatePayableAmount()} ₹`}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default FeesBreakdownModal
