import ProcessCard from "./ProcessCard"
export default function Placementstage({ isEvolve }: { isEvolve: boolean }) {
  const bgcolors = ["bg-[#FFDA8C]", "bg-[#F0F477]", "bg-[#EDC8FB]", "bg-[#97FDB3]", "bg-[#FFBEBE]", "bg-[#99D9FA]"]
  const fontcolors = [
    "text-[#A67E2A]",
    "text-[#A7AC1D]",
    "text-[#7A15A0]",
    "text-[#1B9D3F]",
    "text-[#BA2626]",
    "text-[#217DAC]",
  ]
  const bordercolors = [
    "border-[#A67E2A]",
    "border-[#A7AC1D]",
    "border-[#7A15A0]",
    "border-[#1B9D3F]",
    "border-[#BA2626]",
    "border-[#217DAC]",
  ]

  const stages: any = [
    {
      title: "Your Batch Eligibility",
      description: `After ${isEvolve ? "3" : "4"} months of your course begins, your batch will be eligible for process`,
      number: 1,
    },
    {
      title: "Placement Assessment",
      description: "An assessment will be conducted to check the skills of the candidate.",
      number: 2,
    },
    {
      title: "Mock Interview",
      description: "Candidates who have passed the exams need to clear a mock interview conducted by the team.",
      number: 3,
    },
    {
      title: "Admission into placement cohort ",
      description: "Selected students will be added to the cohort for further processes.",
      number: 4,
    },
    {
      title: "Profile building",
      description: "We will assist our students with services like Resume Building, LinkedIn Optimisation etc.",
      number: 5,
    },
    {
      title: "Final Placements",
      description: "Placement drives conducted regularly will help the candidates to get relevant opportunities.",
      number: 6,
      // boldText: ["a) Referrals into top companies", "b) Direct tie ups"],
    },
  ]

  return (
    <div className="bg-white  w-full  flex-col  text-4xl leading-normal ">
      <div className=" ">
        <div>
          <p className="   text-[16px] font-bold">Our Placement Process</p>
        </div>
      </div>

      <div className=" w-full flex justify-center items-center">
        <div className=" flex flex-wrap w-full   justify-center    ">
          {stages.map((stage: { number: number; title: string; description: string; boldText: string[] }) => (
            <ProcessCard
              index={stage.number}
              bg={bgcolors[stage.number - 1]}
              fontcolor={fontcolors[stage.number - 1]}
              bordercolor={bordercolors[stage.number - 1]}
              title={stage.title}
              description={stage.description}
              boldText={stage.boldText ? stage.boldText : []}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
