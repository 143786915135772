import { API_V4_ADMIN, API_V4_CREATE_ONBOARDING_MANAGER, GET_ONBOARDING_DATA_FOR_ADMIN } from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import CreateOnboardingMeetDataSource from "data/dataSource/Admin/CreateOnboardingMeetDataSource"
import Email from "data/dataSource/Admin/Email"
import { Auth } from "domain/model/Auth"
import { ManagerFeedback, OnboardingData } from "domain/model/OnboardingMeet"

const server = new Server()

export class CreateOnboardingMeetAPIDataSourceImpl implements CreateOnboardingMeetDataSource {
  async createOnboardingMeet(createOnboardingMeet: OnboardingData, auth: Auth): Promise<any> {
    try {
      const response = await server.post(pn("admin/onboarding/create_session"), createOnboardingMeet, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (e) {
      console.error(e)
    }
  }

  async getOnboardingMeet(auth: Auth, email: Email): Promise<any> {
    try {
      const response = await server.get(pn(`${GET_ONBOARDING_DATA_FOR_ADMIN}?email=${email}`), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  async postOnboardingFeedback(postOnboardingFeedback: ManagerFeedback, auth: Auth): Promise<any> {
    try {
      const response = await server.post(pn("admin/onboarding/feedback"), postOnboardingFeedback, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (e) {
      console.error(e)
    }
  }
}
