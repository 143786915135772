import Cross from "assets/svgs/Cross"

export default function PaymentPopUpBreakdown(props: any) {
  function handleCross() {
    props.handlePopUp()
  }
  //Data time obj to without 00:00:00 GMT
  function formatDueDateWithOutGMT(dueTime: string): string {
    const dueDateParts = dueTime.split(" ")
    return dueDateParts.slice(0, 4).join(" ")
  }
  // 13/01/2020 -> text
  const formatDateTotext = (dateString: string): string => {
    const [day, month, year] = dateString.split("-").map(Number)
    const date = new Date(year, month - 1, day) // Month in JavaScript starts from 0

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    }

    return new Intl.DateTimeFormat("en-US", options).format(date)
  }
  function calculateAmountAndInstalment() {
    // Filter installments with "created" status and sort by due date
    const createdInstallments = props.payData.filter(
      (installment: { status: string; due_date: string }) => installment.status === "created"
    )

    if (createdInstallments.length > 0) {
      return [createdInstallments[0].amount, props.payData.length - createdInstallments.length + 1]
    } else {
      return [0, 0]
    }
  }
  const calculateOfferDiscount = () => {
    if (!props?.expired) return props.payDataAll?.offer_discount
    return 0
  }
  const calculateOneshotDiscount = () => {
    const discountAmount =
      (props.payDataAll?.fees - props.payDataAll?.standard_discount - props.payDataAll?.seat_booking_amount) *
      (props.payDataAll?.one_shot_discount / 100)

    return Math.max(0, Math.round(discountAmount)) // Ensure non-negative integer
  }

  function calculatePayableAmount() {
    if (props?.payDataAll?.instalments?.length <= 2) {
      return props?.paymentOptionDiscount?.fees_after_discount - calculateOneshotDiscount()
    } else {
      return props?.paymentOptionDiscount?.fees_after_discount
    }
  }
  const firstRemainingIndex = props?.payDataAll?.instalments?.findIndex(
    (instalment: any) => instalment.status === "created"
  )

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto p-8">
          <div className="m-auto w-[926px] transform space-y-8 rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="flex  flex-col items-center justify-center p-3">
              {/* Title and Subtitle */}
              <div className="flex w-full justify-between">
                <div className="flex flex-col">
                  {/* <div className="text-[18px] font-bold text-[#312E81]">Fee Payment Breakdown</div> */}
                </div>

                <button
                  className="h-[24px] w-[24px] text-gray-500 hover:text-gray-600 focus:outline-none"
                  onClick={() => handleCross()}
                >
                  <Cross />
                </button>
              </div>

              {/* Fee Payment Breakdown */}
              <div className="w-full">
                <div className="mt-3 w-full">
                  <div className="mb-4 flex items-center justify-between">
                    <div className="font-sans text-base font-semibold leading-normal  text-[#414141]">
                      Total Course Fee
                    </div>
                    <input
                      type="text"
                      value={`${props.payDataAll?.fees} Rs`}
                      className="border-300 h-[40px] w-40 appearance-none rounded-sm border-[1px] border-[#414141] p-2 text-center"
                      placeholder="Amount"
                    />
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <div className="font-sans text-base font-semibold leading-normal  text-[#414141]">
                      Seat Booking Amount
                    </div>
                    <input
                      type="text"
                      value={`${props.payDataAll?.seat_booking_amount} Rs`}
                      className="border-300 [#414141] h-[40px] w-40 appearance-none rounded-sm border-[1px] p-2 text-center"
                      placeholder="Amount"
                    />
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <div className="font-sans text-base font-semibold leading-normal text-[#DC2626]">
                      Standard Discount :
                    </div>
                    <input
                      type="text"
                      value={` ${props.payDataAll?.standard_discount} Rs`}
                      className="border-300 h-[40px] w-40 appearance-none rounded-sm  border border-[#DC2626] p-2 text-center"
                      placeholder="Amount"
                    />
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <div
                      className={`${
                        props?.expired ? "opacity-20" : "opacity-100"
                      } font-sans text-base font-semibold leading-normal text-[#DC2626]`}
                    >
                      <>
                        {`Offer Period Discount  `}
                        <span className="font-sans text-base font-semibold leading-normal text-[#414141]">
                          (Applicable till {props?.offerDate}):
                        </span>
                      </>
                    </div>

                    <input
                      type="text"
                      value={`${props.payDataAll?.offer_discount} Rs`}
                      className={` ${
                        props?.expired
                          ? "opacity-20" // Adjust opacity for the first 'remaining' installment or when installment length > 1
                          : "opacity-100" // Default opacity for other cases
                      } h-[40px] w-40 appearance-none rounded-sm border-none bg-[#f5f5f5] p-2 text-center outline-none`}
                      placeholder="Amount"
                    />
                  </div>

                  <div className="mb-4 flex items-center justify-between ">
                    <div
                      className={`${
                        props.payDataAll?.instalments?.length > 2
                          ? "opacity-20" // Adjust opacity for the first 'remaining' instalment
                          : "opacity-100" // Default opacity for other cases
                      } font-sans text-base font-semibold leading-normal text-[#DC2626]`}
                    >
                      {`One Shot Payment Discount ( ${props.payDataAll?.one_shot_discount}% ) :`}
                    </div>
                    <input
                      type="text"
                      // value={studentGetPayDetail?.one_shot_discount}
                      value={`${calculateOneshotDiscount()} Rs`}
                      className={`${
                        props.payDataAll.instalments?.length > 2
                          ? "opacity-20" // Adjust opacity for the first 'remaining' instalment
                          : "opacity-100" // Default opacity for other cases
                      } h-[40px] w-40 appearance-none rounded-sm border-none bg-[#f5f5f5] p-2 text-center focus:outline-none`}
                      placeholder="Amount"
                    />
                  </div>

                  <hr className="my-3 border-t-2 border-dotted border-gray-300" />

                  {/* Payable Amount */}
                  <div className="flex items-center justify-between rounded-[0.25rem] bg-[#F5F5F5] p-[1rem]">
                    <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                      Payable Amount :{" "}
                    </div>
                    <div className="inline-flex w-[15vw] items-center justify-center rounded-sm border border-gray-500 bg-white p-2 text-[14px] font-medium">
                      {/* Add your amount here */}
                      {`${calculatePayableAmount()} Rs`}
                    </div>
                  </div>
                </div>
                {props?.payDataAll?.instalments?.length > 2 && (
                  <>
                    <div className="mt-6 w-full">
                      <div className="flex pb-3 text-[18px] font-semibold text-[#312E81]">Instalments Breakdown</div>
                      {/* Iterate over instalments data from API */}
                      {props?.payDataAll?.instalments?.map((instalment: any, index: any) => (
                        <div key={index} className="mb-4 flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <div
                              className={`font-sans text-base font-semibold leading-normal text-[#414141] ${
                                instalment.status === "created" && index !== firstRemainingIndex
                                  ? "opacity-20" // Adjust opacity for the first 'remaining' instalment
                                  : "opacity-200" // Default opacity for other cases
                              }`}
                            >
                              Instalment {index + 1}
                            </div>
                            <div
                              className={`text-base ${
                                instalment.status === "paid" ? "text-green-500" : "text-[#414141]"
                              } ${
                                instalment.status === "created" && index !== firstRemainingIndex
                                  ? "opacity-20" // Adjust opacity for the first 'remaining' instalment
                                  : "opacity-200" // Default opacity for other cases
                              }`}
                            >
                              {instalment.status === "paid" ? "Paid" : "Due"}:{" "}
                            </div>
                            <div
                              className={`text-base ${
                                instalment.status === "paid" ? "text-green-500" : "text-[#414141]"
                              } ${
                                instalment.status === "created" && index !== firstRemainingIndex
                                  ? "opacity-20" // Adjust opacity for the first 'remaining' instalment
                                  : "opacity-200" // Default opacity for other cases
                              }`}
                            >
                              {formatDueDateWithOutGMT(instalment?.due_time)}
                            </div>
                          </div>

                          <input
                            type="text"
                            className={`border-300 h-[40px] w-40 appearance-none items-center rounded-[1px] border-[1px] border-[#414141] p-2 text-center text-base ${
                              instalment.status === "paid" ? "text-green-500" : "text-[#414141]"
                            } ${
                              instalment.status === "created" && index !== firstRemainingIndex
                                ? "opacity-20" // Adjust opacity for the first 'remaining' instalment
                                : "opacity-200" // Default opacity for other cases
                            }`}
                            placeholder="Amount"
                            // value={`${instalment.amount} Rs`}
                            value={`${props?.payData[index]?.amount} Rs`}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="flex items-center justify-between rounded-[0.25rem] bg-[#F5F5F5] p-[1rem]">
                      <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                        {props.isCompletelyPaid === false ? (
                          <>{`Instalment ${calculateAmountAndInstalment()[1]}`} (Payable Amount) :</>
                        ) : (
                          "Payable Amount :"
                        )}
                      </div>
                      <button className="inline-flex w-[15vw] items-center justify-center rounded-sm border border-gray-500 bg-white p-2 text-[14px] font-medium">
                        {/* Add your amount here */}
                        {`${calculateAmountAndInstalment()[0]} Rs`}
                        {/* {1000} */}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
