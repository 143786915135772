import StarRating from "core/components/v2/RatingStars"
import { cn } from "core/lib/utils"

export default function StudentFeedbackModal({ isOpen, onClose, children, width, height, heading }: any) {
  if (!isOpen) return null
  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center bg-[rgba(0,0,0,0.75)]">
          <div
            className={cn(
              "relative mx-auto w-full rounded-lg bg-new-solid-white shadow-lg",
              width ? width : "max-w-2xl"
            )}
          >
            <div className="relative p-6">
              <div className="flex justify-between items-center mb-2">
                <h1 className="text-[24px] text-slate-700">{heading}</h1>
                <button className="text-gray-500 hover:text-gray-600 focus:outline-none" onClick={onClose}>
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="mt-[11px]">
                <StarRating starRating={Number(children?.feedback?.ai_model_rating)} />
              </div>
              <p className=" mt-[11px] h-[25px] text-[#07b32d] text-xs font-semibold font-['Inter']">
                Expected Marks:{" "}
                {children?.feedback?.expected_marks ? `${children?.feedback?.expected_marks}/100` : "NA"}
              </p>
              <div className="mt-[11px]">
                {children?.feedback?.student_feedback || "No Feedback Given By the Student."}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
