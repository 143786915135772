// studentplacementdashboard
import {
  API_CASE_STUDIES_QUESTION_LIST,
  API_CREATE_CONTEST,
  API_DELETE_CONTEST,
  API_GET_MCQ_NAME_FROM_ID,
  API_GET_QUESTION_LIST,
  API_SUBJECTIVE_QUESTION_LIST,
  API_UPDATE_CONTEST,
  API_V4_ADMIN,
  API_V4_CONTEST_DELETE_FEEDBACK_FILE,
  API_V4_CREATE_CONTEST_FEEDBACK_RESPONSE,
  API_V4_GET_CONTEST_FEEDBACK_DETAILS,
  API_V4_GET_CONTEST_SSM_SUBMISSION_ENABLE_STATUS,
  API_V4_GET_CONTEST_STUDENT_LIST,
  API_V4_GET_STUDENT_CONTEST_FEEDBACK_RESPONSE,
  API_V4_GET_STUDENT_CONTEST_SUBMISSIONS,
  API_V4_TOGGLE_PROCTORING,
  API_V4_VALIDATE_CODING_QUESTION,
  API_V4_VALIDATE_MCQ_QUESTION,
  API_V4_VALIDATE_SUBJECTIVE_QUESTION,
  API_CREATE_EXAM,
  API_UPDATE_EXAM,
  API_GET_EXAM_DETAILS,
  API_DELETE_EXAM,
  API_ADMIN_GET_CONTEST_RESULT,
} from "core/constants/strings"
import { postAPI, postAPIForFiles } from "core/utils/axios"
import pn from "core/utils/pn"
import ContestDataSource from "data/dataSource/Admin/ContestDataSource"
import { Auth } from "domain/model/Auth"
import { FeedbackResponse } from "domain/model/FeedbackResponse"

export default class ContestAPIDataSourceImpl implements ContestDataSource {
  async getQuestionList(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_QUESTION_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getcaseStudyQuestionList(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CASE_STUDIES_QUESTION_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getsubjectiveQuestionList(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_SUBJECTIVE_QUESTION_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createContest(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_CONTEST), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateContest(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_CONTEST), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteContest(auth: Auth, contestId: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_CONTEST), {
        id_token: auth?.id_token,
        contest_id: contestId, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }

  async getMcqNameFromId(auth: Auth, mcq_id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MCQ_NAME_FROM_ID), {
        id_token: auth?.id_token,
        mcq_id: mcq_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }

  async toggleProctoring(auth: Auth, student: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_TOGGLE_PROCTORING), {
        id_token: auth?.id_token,
        student: student,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async validateCodingQuestion(auth: Auth, id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_VALIDATE_CODING_QUESTION), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async validateMcqQuestion(auth: Auth, id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_VALIDATE_MCQ_QUESTION), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async validateSubjectiveQuestion(auth: Auth, id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_VALIDATE_SUBJECTIVE_QUESTION), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestStudentList(
    auth: Auth,
    contest_id: string,
    // batch: string,
    page: number,
    pageLimit: number
  ) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CONTEST_STUDENT_LIST), {
        id_token: auth?.id_token,
        contest_id: contest_id, // Pass the contest_id to the backend
        // batch: batch,
        page: page,
        pageLimit: pageLimit,
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getStudentContestSubmissions(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_STUDENT_CONTEST_SUBMISSIONS), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async createContestFeedbackResponse(
    auth: Auth,
    contest_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any> {
    try {
      // Append additional fields directly to the existing formData
      formData.append("id_token", auth?.id_token)
      formData.append("contest_id", contest_id)
      formData.append("student_email", student_email)
      formData.append("feedbackResponse", JSON.stringify(feedbackResponse))

      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_V4_CREATE_CONTEST_FEEDBACK_RESPONSE), formData)

      return response // Assuming response structure is { data: any }
    } catch (error) {
      console.error("Error creating feedback response:", error)
      throw new Error("An error occurred while creating feedback response")
    }
  }
  async getStudentContestFeedbackResponse(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_STUDENT_CONTEST_FEEDBACK_RESPONSE), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestFeedbackDetails(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CONTEST_FEEDBACK_DETAILS), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestSSMSubmissionEnableStatus(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CONTEST_SSM_SUBMISSION_ENABLE_STATUS), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteContestFeedbackFile(
    auth: Auth,
    contest_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_CONTEST_DELETE_FEEDBACK_FILE), {
        id_token: auth?.id_token,
        contest_id,
        student_email,
        fileurl,
        questionName,
        type,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async createExam(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_EXAM), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateExam(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_EXAM), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamDetails(auth: Auth, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_EXAM_DETAILS), {
        id_token: auth?.id_token,
        exam_id: exam_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteExam(auth: Auth, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_EXAM), {
        id_token: auth?.id_token,
        exam_id: exam_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestResult(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_ADMIN_GET_CONTEST_RESULT), {
        id_token: auth?.id_token,
        data: data, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
}
