import { AIFeedbackDataSource } from "data/dataSource/Admin/AIFeedbackDataSource"
import { Auth } from "domain/model/Auth"
import { approveData, date, email } from "domain/model/ContestApprove"
import AIFeedbackRepository from "domain/repository/Admin/AIFeedbackRepository"

export class AIFeedbackRepositoryImpl implements AIFeedbackRepository {
  private dataSource: AIFeedbackDataSource

  constructor(dataSource: AIFeedbackDataSource) {
    this.dataSource = dataSource
  }

  getTop100Feedbacks(auth: Auth, data: any) {
    return this.dataSource.getTop100Feedbacks(auth, data)
  }
  getFeedbackByEmail(auth: Auth, data: email) {
    return this.dataSource.getFeedbackByEmail(auth, data)
  }
}
