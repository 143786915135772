import CloseIcon from "assets/svgs/placement/Close"
import { EyeIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { EditPlacementStatus } from "domain/useCase/Admin/Placement/EditPlacementStatus"
import React, { useState } from "react"

interface EditLinkedInCardProps {
  studentDetails: any
  setStudentDetails: (details: any) => void
  onCancel: () => void
}

const EditLinkedInCard: React.FC<EditLinkedInCardProps> = ({ studentDetails, setStudentDetails, onCancel }) => {
  const [newLinkedInURL, setNewLinkedInURL] = useState<string>("")
  const { auth } = useAuth()
  const EditPlacementStatusUsecase = new EditPlacementStatus(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  const handleSave = async () => {
    if (!newLinkedInURL) {
      alert("Please provide a valid LinkedIn URL.")
      return
    }

    try {
      const response = await EditPlacementStatusUsecase.invoke(
        auth,
        studentDetails?.email,
        "linkedin_profile",
        newLinkedInURL
      )

      if (response?.success) {
        setStudentDetails((prevDetails: any) => ({
          ...prevDetails,
          linkedin_profile: newLinkedInURL,
        }))
        alert("LinkedIn profile updated successfully.")
        onCancel()
      } else {
        console.error("Failed to update LinkedIn profile:", response?.message || "Unknown error")
        alert("Failed to update LinkedIn profile. Please try again.")
      }
    } catch (error) {
      console.error("Error updating LinkedIn profile:", error)
      alert("An error occurred. Please try again.")
    }
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-[24px] h-auto w-[400px] relative">
      <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onCancel}>
        <CloseIcon className="h-6 w-6" />
      </button>
      <p className="text-[16px] font-semibold text-[#333333]">
        {studentDetails?.linkedin_profile ? "Update" : "Upload"} LinkedIn Profile
      </p>
      <p className="pt-1 text-[14px] text-[#646464]">Profile: {studentDetails?.name || "N/A"}</p>
      <div className="py-6 flex flex-col gap-4">
        {studentDetails?.linkedin_profile && (
          <label className="flex flex-col gap-1">
            <span className="font-semibold text-[#333333]">Current LinkedIn URL</span>
            <div className="w-full flex items-center justify-between gap-3 pr-3">
              <input
                type="url"
                name="currentUrl"
                value={studentDetails.linkedin_profile}
                readOnly
                className="w-full border-[0.5px] border-[#D3D3D3] rounded-md p-2 text-[#646464]"
                placeholder="No LinkedIn URL available"
              />
              <a href={studentDetails.linkedin_profile} target="_blank" rel="noopener noreferrer">
                <EyeIcon className="h-6 w-6" />
              </a>
            </div>
          </label>
        )}
        <label className="block mt-4 text-[#333333] font-semibold">
          {studentDetails?.linkedin_profile ? "Update LinkedIn URL" : "Add LinkedIn URL"}
        </label>
        <input
          type="url"
          name="newUrl"
          value={newLinkedInURL}
          onChange={(e) => setNewLinkedInURL(e.target.value)}
          className="w-full border-[0.5px] border-[#D3D3D3] rounded-md p-2"
          placeholder="Enter new LinkedIn URL"
        />
      </div>
      <div className="flex justify-end gap-4 mt-16">
        <button className="border-[0.5px] border-[#D3D3D3] text-[#333333] rounded-md px-4 py-2" onClick={onCancel}>
          Cancel
        </button>
        <button className="bg-[#001665] text-white rounded-md px-4 py-2" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  )
}

export default EditLinkedInCard
