import pn from "core/utils/pn"
import { STUDENT, LEADERBOARD } from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { LeaderboardDataSource } from "data/dataSource/Student/LeaderboardDataSource"

const server = new Server()
export class LeaderboardAPIDataSource implements LeaderboardDataSource {
  async getLeaderboard(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(STUDENT, LEADERBOARD), {
        Authorization: `Bearer ${auth?.id_token}`,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getExamLeaderboard(auth: Auth): Promise<any> {
    // try {
    //   const response = await postAPI(pn(STUDENT, LEADERBOARD), {
    //     // Authorization: `Bearer ${auth?.id_token}`,
    //     id_token: auth.id_token,
    //   })
    //   return response.data
    // } catch ({ response: { data: error } }: any) {
    //   return error
    // }
    try {
      const response = await server.get(pn(STUDENT, LEADERBOARD), {
        Authorization: `Bearer ${auth?.id_token}`,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
