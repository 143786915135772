import {
  API_CANCEL_SCHEDULE_CLASS,
  API_GET_BATCH_CLASSES,
  API_V4_UPDATE_CLASS,
  API_V4_ADMIN,
  API_V4_UPDATE_CLASS_AND_TIMING,
  API_V4_UPDATE_CLASS_WITHOUT_ICS,
} from "core/constants/strings"
import { deleteAPI, postAPI, putAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { CancelScheduledClass, ScheduledClass, UpdateClass, UpdateClassAndTiming } from "domain/model/ScheduledClass"
import { ScheduledClassDataSource } from "data/dataSource/Admin/ScheduledClassDataSource"

export class ScheduleClassAPIDataSource implements ScheduledClassDataSource {
  async scheduleClass(scheduleclass: ScheduledClass): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_BATCH_CLASSES), {
        id_token: scheduleclass.id_token,
        batch: scheduleclass.batch,
      })

      const data = response
      return data
    } catch (error) {
      console.error("Error:", error)
    }
  }

  async cancelScheduledClass(cancelScheduledClass: CancelScheduledClass): Promise<any> {
    try {
      const response = await deleteAPI(pn(API_V4_ADMIN, API_CANCEL_SCHEDULE_CLASS), {
        id_token: cancelScheduledClass.id_token,
        session_id: cancelScheduledClass.session_id,
      })
      return response
    } catch (error) {
      console.error("Error:", error)
      return error
    }
  }
  async updateScheduledClass(update_class: UpdateClass): Promise<any> {
    const response = await putAPI(pn(API_V4_ADMIN, API_V4_UPDATE_CLASS), update_class)
    return response
  }

  async updateScheduledClassWithoutIcs(update_class: UpdateClass): Promise<any> {
    const response = await putAPI(pn(API_V4_ADMIN, API_V4_UPDATE_CLASS_WITHOUT_ICS), update_class)
    return response
  }

  async updatePastClassAndTiming(updateClassAndTiming: UpdateClassAndTiming): Promise<any> {
    const response = await putAPI(pn(API_V4_ADMIN, API_V4_UPDATE_CLASS_AND_TIMING), updateClassAndTiming)
    return response
  }
}
