import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import RedeemCoinsAPIDataSourceImpl from "data/API/Student/RedeemCoinsAPIDataSourceImpl"
import { RedeemCoinsRepositoryImpl } from "data/repository/Student/RedeemCoinsRepositoryImpl"
import BookMentorSessionCoupon from "domain/useCase/Student/RedeemCoins/BookMentorSessionCoupon"
import GetExtraSession from "domain/useCase/Student/RedeemCoins/GetExtraSession"
import GetOrderHistory from "domain/useCase/Student/RedeemCoins/GetOrderHistory"
import RedeemCoins from "domain/useCase/Student/RedeemCoins/RedeemCoins"
import { useState } from "react"

export default function StoreViewModel() {
  const { auth } = useAuth()
  const [storeItemsLocally, setStoreItemsLocally] = useLocalStorage<any>("store-items", [])
  const [userDetailsLocally, setUserDetailsLocally] = useLocalStorage<any>("user-details", {})
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const currentDate = new Date()
  const [student] = useLocalStorage<any>("student", {} as any)
  const allCoupons = ["50 Rupees"]
  const [amount, setAmount] = useState<string>("")
  const [activeButtonType, setActiveButtonType] = useState<number>(0)
  const [redeemModal, setRedeemModal] = useState(false)
  const [allMentorCoupons, setAllMentorCoupons] = useState<any>()
  const [redeemCouponLoader, setRedeemCouponLoader] = useState<any>(false)
  const [mentorCouponLoader, setMentorCouponLoader] = useState<any>(false)
  const [mentorSessionRedeemModal, setMentorSessionRedeemModal] = useState(false)
  const amountToCoinsMap: { [key: string]: string } = {
    "": "N/A",
    "50 Rupees": "10000",
    // "75 Rupees": "15000",
    // "100 Rupees": "19000",
    // "200 Rupees": "35000",
    // "500 Rupees": "90000",
  }
  const [storeItems, setStoreItems] = useState(() => storeItemsLocally)
  const [userDetails, setUserDetails] = useState<any>(() => userDetailsLocally)
  const [orderHistory, setOrderHistory] = useState<any>()
  const [activeAmount, setActiveAmount] = useState<number>(-1)

  const redeemCoinsUseCase = new RedeemCoins(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  const getOrderHistoryUseCase = new GetOrderHistory(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  const bookMentorSessionCouponUseCase = new BookMentorSessionCoupon(
    new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl())
  )

  const getExtraSessionUseCase = new GetExtraSession(new RedeemCoinsRepositoryImpl(new RedeemCoinsAPIDataSourceImpl()))

  function handleMentorSessionModal() {
    setMentorSessionRedeemModal(!mentorSessionRedeemModal)
  }

  async function changeActiveButtonType(index: number) {
    const i = index
    setActiveButtonType(index)
  }

  function mailToAdmin() {
    let emailtemplate = `
    I am writing to inform you that I currently have ${student?.points} coins in my account. 
    I recently attempted to redeem these coins for an Amazon gift card valued at Rs ${amount} from the Bosscoder portal. 
    However, I discovered that there are no gift cards of this denomination available in the collection. 
    Could you please add new gift cards to the collection so that I can redeem my coins?
    
    Thank you for your attention to this matter. 
    Kindly inform me once new gift cards have been added to the collection.
    
    Best regards,

    ${student?.name}
    ${student?.batch}
    `
    let mailLink = `mailto:help@bosscoderacademy.com?subject=Request%20for%20Addition%20of%20Amazon%20Gift%20Cards%20in%20Bosscoder%20Portal&body=${encodeURIComponent(
      `Dear Team,\n\n${emailtemplate}`
    )}`

    window.open(mailLink, "_blank")
  }

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value)
  }

  const handleRedeemCoinsModal = () => {
    setRedeemModal(!redeemModal)
  }

  const fetchOrderHistory = async () => {
    const response = await getOrderHistoryUseCase.invoke(auth, student?.email)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    setOrderHistory([...response?.data])
  }

  const RedeemCoupon = async () => {
    const amountMatch = amount.match(/\d+/)
    if (!amountMatch) {
      console.error("Invalid amount format.")
      return
    }

    const amountValue = parseInt(amountMatch[0], 10)

    if (activeAmount) {
      if ((student?.coins || 0) < amountValue) {
        changeToastDetails(STR_FAILURE, "You Do Not have Sufficient Coins")
        changeToastVisibility(true)
        return
      }
    } else {
      if ((student?.points || 0) < parseInt(amountToCoinsMap[amount])) {
        changeToastDetails(STR_FAILURE, "You Do Not have Sufficient Points")
        changeToastVisibility(true)
        return
      }
    }

    setRedeemCouponLoader(true)

    const response = await redeemCoinsUseCase.invoke({
      id_token: auth.id_token,
      coin_type: activeAmount === 1 ? "freeze" : "points",
      amount: amountValue,
      coins: activeAmount === 1 ? student?.coins : student?.points,
      order_date: Date.parse(currentDate.toString()) / 1000,
      email: student.email,
    })
    setRedeemCouponLoader(false)
    setRedeemModal(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    if (response?.data?.status == false) {
      changeToastDetails(STR_FAILURE, "Error Occured Contact Admin")
      changeToastVisibility(true)
      await mailToAdmin()
      return
    }
    if (response.data == "Not Eligible") {
      changeToastDetails(STR_FAILURE, "You Don't Have Sufficient Coins")
      changeToastVisibility(true)
      return
    }
    changeToastDetails(STR_SUCCESS, "Coupon Redeemed")
    changeToastVisibility(true)

    setStoreItems(response?.data)
    setStoreItemsLocally(response?.data)
    return await fetchOrderHistory()
  }

  async function RedeemMentorCoupon() {
    setMentorCouponLoader(true)
    setActiveButtonType(1)
    const response = await bookMentorSessionCouponUseCase.invoke({
      id_token: auth?.id_token,
    })
    setMentorCouponLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    if (response.data == "Less Coins Than Needed") {
      changeToastDetails(STR_FAILURE, "You Don't Have Sufficient Coins")
      changeToastVisibility(true)
      return
    }
    handleMentorSessionModal()
    changeToastDetails(STR_SUCCESS, "Coins Redeemed Successfully")
    changeToastVisibility(true)
  }

  async function FetchMentorSessionCoupon() {
    const response = await getExtraSessionUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    setAllMentorCoupons(response?.data)
  }

  return {
    auth,
    toast,
    storeItems,
    userDetails,
    orderHistory,
    student,
    allCoupons,
    amount,
    redeemModal,
    amountToCoinsMap,
    mentorSessionRedeemModal,
    allMentorCoupons,
    activeButtonType,
    redeemCouponLoader,
    mentorCouponLoader,
    changeActiveButtonType,
    setAmount,
    RedeemCoupon,
    setRedeemModal,
    RedeemMentorCoupon,
    FetchMentorSessionCoupon,
    handleMentorSessionModal,
    changeToastVisibility,
    changeToastDetails,
    handleRedeemCoinsModal,
    handleAmountChange,
    fetchOrderHistory,
    activeAmount,
    setActiveAmount,
  }
}
