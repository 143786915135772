import { ALL_FEEDBACKS, API_ADMIN, AI, AI_FEEDBACK_ON_STUDENT } from "core/constants/strings"
import pn from "core/utils/pn"
import { Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { email } from "domain/model/ContestApprove"
import { AIFeedbackDataSource } from "data/dataSource/Admin/AIFeedbackDataSource"
const server = new Server()

export class AIFeedbackAPIDataSourceImpl implements AIFeedbackDataSource {
  async getTop100Feedbacks(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, AI, ALL_FEEDBACKS),
        {
          pageNumber: data,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getFeedbackByEmail(auth: Auth, data: email): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, AI, AI_FEEDBACK_ON_STUDENT),
        { data },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
