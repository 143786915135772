import StudentProgressDataSource from "data/dataSource/Student/StudentProgressDataSource"
import { Auth } from "domain/model/Auth"
import { StudentProgressRepository } from "domain/repository/Student/StudentProgressRepository"

export class StudentProgressRepositoryImpl implements StudentProgressRepository {
  private dataSource: StudentProgressDataSource
  constructor(dataSource: StudentProgressDataSource) {
    this.dataSource = dataSource
  }
  async getAllProgressData(auth: Auth): Promise<any> {
    return this.dataSource.getAllProgressData(auth)
  }
}
