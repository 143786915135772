import { AdminLogsDetails } from "domain/model/AdminLogs"
import { Auth } from "domain/model/Auth"
import { getAdminDetails } from "domain/model/SuperAdminDashboard"
import { SuperAdminDashboardRepository } from "domain/repository/SuperAdmin/SuperAdminDashboardRepository"
import { SuperAdminLoggerDashboardRepository } from "domain/repository/SuperAdmin/SuperAdminLoggerDashboardRepository"

export interface getAdminLogsUseCase {
  invoke(auth: Auth, details: AdminLogsDetails): Promise<any>
}
export class getAdminLogs implements getAdminLogsUseCase {
  private repository: SuperAdminLoggerDashboardRepository

  constructor(repository: SuperAdminLoggerDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: AdminLogsDetails): Promise<any> {
    return await this.repository.getAdminLogs(auth, details)
  }
}
