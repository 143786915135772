import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { PlacementAPIDataSourceImpl } from "data/API/Student/PlacementAPIDataSourceImpl"
import { PlacementRepositoryImpl } from "data/repository/Student/PlacementRepositoryImpl"
import { GetStudentDetails } from "domain/useCase/Student/Placement/GetStudentDetails"
import { UpdateStudentDetails } from "domain/useCase/Student/Placement/UpdateStudentDetails"
import { useState } from "react"

export default function PlacementDetailsFormModel() {
  const courseTypeOptions = ["College Student", "Working Professional", "Graduated but not working"]
  const [loading, setLoading] = useState(false)
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [resumeFile, setResumeFile] = useState<File | null>(null)
  const [Id, setId] = useState("")

  const GetStudentDetailsUsecase = new GetStudentDetails(new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl()))
  const UpdateStudentDetailsUsecase = new UpdateStudentDetails(
    new PlacementRepositoryImpl(new PlacementAPIDataSourceImpl())
  )

  const [formData, setFormData] = useState<any>({
    name: "",
    email: "",
    linkedin: "",
    contact: "",
    batch: "",
    working: "",
    yearOfGraduation: "",
    collegeCurrentCompany: "",
    dreamCompany: "",
    jobProfileAspiration: "",
    currentSalary: "",
    desiredLocation: "",
    noticePeriod: "",
    negotiable: "",
    yearsOfExperience: "",
    skills: "",
    // resume: "",
  })

  const fetchStudentDetails = async (docId: string) => {
    setLoading(true)
    try {
      const response = await GetStudentDetailsUsecase.invoke(auth, docId)
      if (response?.success) {
        const data = response?.data
        setFormData((prev: any) => ({
          ...prev,
          name: data?.name || "",
          email: data?.email || "",
          linkedin: data?.linkedin_profile || "",
          contact: data?.phone || "",
          batch: data?.batch || "",
          working: data?.course_type || "", //->course_type
          yearOfGraduation: data?.college_passing_year || "", // ->default 0 in DB
          collegeName: data?.college || "",
          currentCompany: data?.current_company || "",
          dreamCompany: data?.dream_company || "",
          jobProfileAspiration: data?.dream_profile || "",
          currentSalary: data?.current_ctc || "",
          desiredLocation: data?.desired_location || "",
          noticePeriod: data?.notice_period?.replace(/\D/g, "") || "", // ->number
          negotiable: data?.notice_period?.includes("Negotiable") || "", // ->Negotiable

          yearsOfExperience: data?.yoe || "",
          skills: data?.skills || "", // -> comma separated string
          // resume: data?.resume_link || "",
        }))
      } else {
        console.error("Failed to fetch student details.")
      }
    } catch (error) {
      console.error("Error fetching student details:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    const updatedDetails = {
      name: formData.name,
      email: formData.email,
      linkedin_profile: formData.linkedin,
      phone: formData.contact,
      batch: formData.batch,
      course_type: formData.working,
      college_passing_year: formData.yearOfGraduation,
      college: formData.collegeName,
      current_company: formData.currentCompany,
      dream_company: formData.dreamCompany,
      dream_profile: formData.jobProfileAspiration,
      current_ctc: formData.currentSalary,
      desired_location: formData.desiredLocation,
      notice_period: formData.noticePeriod + (formData.negotiable ? " (Negotiable)" : ""),
      yoe: formData.yearsOfExperience,
      skills: formData.skills.split(",").map((skill: string) => skill.trim()),
    }

    if (!Id) {
      changeToastDetails(STR_FAILURE, "Document ID is missing. Please try again.")
      changeToastVisibility(true)
      return
    }

    try {
      setLoading(true)
      const response = await UpdateStudentDetailsUsecase.invoke(auth, Id, updatedDetails, resumeFile)

      if (response?.success) {
        changeToastDetails(STR_SUCCESS, "Student's Details Edited Successfully")
        changeToastVisibility(true)
      }
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Unknown Error Occured")
      changeToastVisibility(true)
      console.error("Error Adding Placement:", error)
    } finally {
      setLoading(false)
    }
  }

  return {
    courseTypeOptions,
    fetchStudentDetails,
    formData,
    setFormData,
    handleSubmit,
    setId,
    resumeFile,
    setResumeFile,
  }
}
