import ICPStudentDataSource from "data/dataSource/Admin/ICPStudentDataSource"
import { ICPStudentRepository } from "domain/repository/Admin/ICPStudentRepository"
import { Auth } from "domain/model/Auth"

export class ICPStudentRepositoryImpl implements ICPStudentRepository {
  private dataSource: ICPStudentDataSource

  constructor(dataSource: ICPStudentDataSource) {
    this.dataSource = dataSource
  }

  async getICPStudentsOfBatch(auth: Auth): Promise<any> {
    return this.dataSource.getICPStudentsOfBatch(auth)
  }
  async getICPStudentsOfNonactiveBatch(auth: Auth): Promise<any> {
    return this.dataSource.getICPStudentsOfNonactiveBatch(auth)
  }

  async getICPStudentsOfAllBatch(auth: Auth): Promise<any> {
    return this.dataSource.getICPStudentsOfAllBatch(auth)
  }
}
