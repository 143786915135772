import { Button } from "core/components/v2/Button"
import { Checkbox } from "core/components/v2/CheckBox"
import { IconHolder } from "core/components/v2/IconHolder"
import { Input } from "core/components/v2/Input"
import { ToggleButton } from "core/components/v2/ToggleButton"
import { Tooltip } from "core/components/v2/Tooltip"
import { AddIcon, DeleteIcon, CrossIcon } from "core/constants/svgs"
import { Select, SelectItem } from "core/components/v2/DropdownInput"
import { Textarea } from "core/components/v2/Textarea"
import SidePanelButton from "core/components/v2/SidePanelButton"
import Home from "assets/svgs/Home"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "core/components/v2/AlertDialog"
import { Headings } from "core/components/v2/Headings"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "core/components/v2/Table"
// import {
//   UserIcon,
//   UserGroupIcon,
//   EmotionIcon,
//   EmotionLaughIcon,
//   EmotionNormalIcon,
//   EmotionSadIcon,
// } from "assets/svgs/v2/User/index";

import React from "react"
import SideBar from "core/components/v2/Sidebar"

export default function ComponentView() {
  const [activeButton, setActiveButton] = React.useState("")

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName)
  }

  return (
    <div className="w-full h-screen p-10 bg-v2-gray-100">
      <center>
        <h1>All Components</h1>
      </center>
      {/* Buttons */}
      <div className="w-full flex flex-col gap-4 pb-28">
        <div className="w-full flex flex-col">
          <h3>Buttons</h3>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-2 p-4">
              <p>Primary Buttons</p>
              <Button variant="primary" className="w-[180px]">
                Primary Button
              </Button>
              <Button variant="primary" className="w-[180px]" disabled>
                Primary Button
              </Button>
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Seconday Buttons</p>
              <Button variant="secondary" border="thin" className="w-[180px]">
                Secondary Button
              </Button>
              <Button variant="secondary" border="thin" className="w-[180px]" disabled>
                Secondary Button
              </Button>
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Ternary Buttons</p>
              <Button variant="ternary" border="thin" className="w-[180px]">
                Ternary Button
              </Button>
              <Button variant="ternary" border="thin" className="w-[180px]" disabled>
                Ternary Button
              </Button>
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Cancel Buttons</p>
              <Button variant="cancel" border="thin" className="w-[180px]">
                Cancel Button
              </Button>
              <Button variant="cancel" border="thin" className="w-[180px]" disabled>
                Cancel Button
              </Button>
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Side Bar Buttons</p>
              <SidePanelButton
                name="Home"
                url="https://www.youtube.com/"
                Icon={Home}
                active={activeButton == "Home" ? true : false}
                onClick={() => handleButtonClick("Home")}
              />
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Icon Buttons</p>
              <div className="flex flex-wrap columns-2 w-[400px] gap-4">
                <Button variant="icon" border="thin" className="w-[180px]" icon={<AddIcon />} iconPosition="left">
                  Icon Button
                </Button>
                <Button variant="icon" border="none" className="w-[180px]" icon={<AddIcon />} iconPosition="left">
                  Icon Button
                </Button>
                <Button variant="icon" border="thin" className="w-[180px]" icon={<AddIcon />} iconPosition="right">
                  Icon Button
                </Button>
                <Button variant="icon" className="w-[180px]" icon={<AddIcon />} iconPosition="right">
                  Icon Button
                </Button>
                <Button
                  variant="icon"
                  border="thin"
                  className="w-[180px]"
                  icon={<AddIcon />}
                  iconPosition="left"
                  disabled
                >
                  Icon Button
                </Button>
                <Button
                  variant="icon"
                  border="thin"
                  className="w-[180px]"
                  icon={<AddIcon />}
                  iconPosition="right"
                  disabled
                >
                  Icon Button
                </Button>
                <Button variant="icon" className="w-[180px]" icon={<AddIcon />} iconPosition="left" disabled>
                  Icon Button
                </Button>
                <Button
                  variant="icon"
                  border="none"
                  className="w-[180px]"
                  icon={<AddIcon />}
                  iconPosition="right"
                  disabled
                >
                  Icon Button
                </Button>
              </div>
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Icon Holder</p>
              <IconHolder border />
              <IconHolder />
              <IconHolder />
            </div>

            <div className="flex flex-col gap-2 p-4">
              <p>Toogle Buttons</p>
              <ToggleButton />
              <ToggleButton checked={true} />
              <ToggleButton disabled={true} />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col">
          <h3>Input fields</h3>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-4">
              <Input placeholder="Placeholder Text" />
              <Input placeholder="Placeholder Text" label="Heading" />
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Tootip</p>
              <Tooltip info="I am button!">
                <Button variant="primary">Hover me</Button>
              </Tooltip>
              <Tooltip info="Click to delete!">
                <IconHolder>
                  <DeleteIcon className="text-failure" />
                </IconHolder>
              </Tooltip>
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Checkbox</p>
              <Checkbox buttonType="checkbox" />
              <Checkbox buttonType="checkbox" checked={true} />
              <Checkbox buttonType="checkbox" className="border-v2-gray-500" />
              <Checkbox buttonType="checkbox" className="border-v2-gray-500" checked={true} />
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Radio</p>
              <Checkbox buttonType="radio" />
              <Checkbox buttonType="radio" checked={true} />
              <Checkbox buttonType="radio" className="border-v2-gray-500" />
              <Checkbox buttonType="radio" className="border-v2-gray-500" checked={true} />
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Close</p>
              <IconHolder>
                <CrossIcon className="text-v2-gray-500" />
              </IconHolder>
              <IconHolder>
                <CrossIcon />
              </IconHolder>
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Text Area</p>
              <Textarea />
            </div>
          </div>
        </div>

        {/* Text fields */}
        <div className="w-full flex flex-col">
          <h3>Text fields</h3>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Dropdown fields</p>
              <Select label="Heading" placeholder="Placeholder Text" defaultValue="Theme">
                {["first", "second first", "last second"].map((val) => (
                  <SelectItem value={val}>{val}</SelectItem>
                ))}
              </Select>

              <Select label="Heading" placeholder="Placeholder Text" defaultValue="Theme" subInfo="General Information">
                {["first", "second first", "last second"].map((val) => (
                  <SelectItem value={val}>{val}</SelectItem>
                ))}
              </Select>
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Text fields</p>
              <Input
                label="Heading"
                placeholder="Placeholder Text"
                className="border border-v2-gray-400 focus-visible:ring-0"
              />
              <Input
                label="Heading"
                placeholder="Placeholder Text"
                subInfo="General Information"
                className="border border-v2-gray-400 focus-visible:ring-0"
              />
            </div>
          </div>
        </div>

        {/* Popups */}
        <div className="w-full flex flex-col">
          <h3>Text fields</h3>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-4 items-start justify-start">
              <p>Dropdown fields</p>
              <AlertDialog>
                <AlertDialogTrigger>Open</AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently delete your account and remove your data from
                      our servers.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction>Continue</AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>
        </div>

        {/* Headings */}
        <div className="w-full flex flex-col">
          <p>Headings</p>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-2">
              <p>Heading 1</p>
              <Headings variant="h1" fontStyle="semiBold">
                Heading 1
              </Headings>
              <Headings variant="h1" fontStyle="medium">
                Heading 1
              </Headings>
              <Headings variant="h1" fontStyle="regular">
                Heading 1
              </Headings>
            </div>
            <div className="flex flex-col gap-2">
              <p>Heading 2</p>
              <Headings variant="h2" fontStyle="semiBold">
                Heading 2
              </Headings>
              <Headings variant="h2" fontStyle="medium">
                Heading 2
              </Headings>
              <Headings variant="h2" fontStyle="regular">
                Heading 2
              </Headings>
            </div>
            <div className="flex flex-col gap-2">
              <p>Heading 3</p>
              <Headings variant="h3" fontStyle="semiBold">
                Heading 3
              </Headings>
              <Headings variant="h3" fontStyle="medium">
                Heading 3
              </Headings>
              <Headings variant="h3" fontStyle="regular">
                Heading 3
              </Headings>
            </div>
            <div className="flex flex-col gap-2">
              <p>Heading 4</p>
              <Headings variant="h4" fontStyle="semiBold">
                Heading 4
              </Headings>
              <Headings variant="h4" fontStyle="medium">
                Heading 4
              </Headings>
              <Headings variant="h4" fontStyle="regular">
                Heading 4
              </Headings>
            </div>
            <div className="flex flex-col gap-2">
              <p>Heading 5</p>
              <Headings variant="h5" fontStyle="semiBold">
                Heading 5
              </Headings>
              <Headings variant="h5" fontStyle="medium">
                Heading 5
              </Headings>
              <Headings variant="h5" fontStyle="regular">
                Heading 5
              </Headings>
            </div>
            <div className="flex flex-col gap-2">
              <p>Heading 6</p>
              <Headings variant="h6" fontStyle="semiBold">
                Heading 6
              </Headings>
              <Headings variant="h6" fontStyle="medium">
                Heading 6
              </Headings>
              <Headings variant="h6" fontStyle="regular">
                Heading 6
              </Headings>
            </div>
          </div>
        </div>

        {/* Paragraphs */}
        <div className="w-full flex flex-col">
          <h2>Paragraphs</h2>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-2">
              <p>Heading 1</p>
              <Headings variant="h1" fontStyle="semiBold">
                Heading 1
              </Headings>
              <Headings variant="h1" fontStyle="medium">
                Heading 1
              </Headings>
              <Headings variant="h1" fontStyle="regular">
                Heading 1
              </Headings>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="w-full flex flex-col">
          <h2>Table</h2>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-2">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="font-medium">INV001</TableCell>
                    <TableCell>Paid</TableCell>
                    <TableCell>Credit Card</TableCell>
                    <TableCell className="text-right">$250.00</TableCell>
                    <TableCell>
                      <Button>Button</Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="font-medium">INV001</TableCell>
                    <TableCell>Paid</TableCell>
                    <TableCell>Credit Card</TableCell>
                    <TableCell className="text-right">$250.00</TableCell>
                    <TableCell>
                      <Button>Button</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        {/* Icons */}
        <div className="w-full flex flex-col">
          <h2>Icons</h2>
          <div className="flex flex-row flex-wrap gap-8 p-10 border border-dashed border-new-neutral-light bg-v2-gray-200 rounded-md">
            <div className="flex flex-col gap-2">
              <p>User</p>
              <Headings variant="h1" fontStyle="semiBold">
                Heading 1
              </Headings>
              <Headings variant="h1" fontStyle="medium">
                Heading 1
              </Headings>
              <Headings variant="h1" fontStyle="regular">
                Heading 1
              </Headings>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col">
          <SideBar />
        </div>
      </div>
    </div>
  )
}
