import CloseIcon from "assets/svgs/placement/Close"
import React, { useState } from "react"

interface EditReviewCardProps {
  name: string
  review: any
  onSave: (updatedReview: any) => void
  onCancel: () => void
}

const EditReviewCard: React.FC<EditReviewCardProps> = ({ name, review, onSave, onCancel }) => {
  const [editedReview, setEditedReview] = useState({ ...review })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEditedReview((prev: any) => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    onSave(editedReview)
  }
  return (
    <div className="bg-white shadow-md rounded-lg p-[24px] h-[580px] w-[500px] relative">
      <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onCancel}>
        <CloseIcon className="h-6 w-6" />
      </button>
      <p className="text-[16px] font-semibold text-[#333333]">Edit Review</p>
      <p className="pt-1 text-[14px] text-[#646464]">Profile : {name}</p>
      <div className="py-6 flex flex-col gap-4">
        <label className="flex flex-col gap-1">
          <span className="font-semibold text-[#333333]">Platform</span>
          <input
            type="text"
            name="platform"
            value={editedReview.platform}
            onChange={handleChange}
            className="border-[0.5px] border-[#D3D3D3] rounded-md p-2 text-[#646464]"
            placeholder="Quora"
          />
        </label>
        <label className="flex flex-col gap-1">
          <span className="font-semibold text-[#333333]">Date</span>
          <input
            type="date"
            name="date"
            value={editedReview.date}
            onChange={handleChange}
            className="border-[0.5px] border-[#D3D3D3] rounded-md p-2 text-[#646464]"
            placeholder="Select Date"
          />
        </label>
        <label className="flex flex-col gap-1">
          <span className="font-semibold text-[#333333]">URL</span>
          <input
            type="url"
            name="url"
            value={editedReview.url}
            onChange={handleChange}
            className="border-[0.5px] border-[#D3D3D3] rounded-md p-2 text-[#646464]"
            placeholder="Type here"
          />
        </label>
        <label className="flex flex-col gap-1">
          <span className="font-semibold text-[#333333]">Review</span>
          <textarea
            name="review"
            value={editedReview.review}
            onChange={handleChange}
            className="border-[0.5px] border-[#D3D3D3] rounded-md p-2 text-[#646464] h-24 resize-none"
            placeholder="Type the review here"
          />
        </label>
      </div>

      <div className="flex justify-end gap-4 mt-4">
        <button className="border-[0.5px] border-[#D3D3D3] text-[#333333] rounded-md px-4 py-2" onClick={onCancel}>
          Cancel
        </button>
        <button className="bg-[#001665] text-white rounded-md px-4 py-2" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  )
}

export default EditReviewCard
