import { SuperAdminLoggerDashboardDataSource } from "data/dataSource/SuperAdmin/SuperAdminLoggerDashboardDataSource"
import { AdminLogsDetails } from "domain/model/AdminLogs"
import { Auth } from "domain/model/Auth"

export class SuperAdminLoggerDashboardRepositoryImpl implements SuperAdminLoggerDashboardRepositoryImpl {
  private dataSource: SuperAdminLoggerDashboardDataSource
  constructor(dataSource: SuperAdminLoggerDashboardDataSource) {
    this.dataSource = dataSource
  }
  getAdminLogs(auth: Auth, details: AdminLogsDetails): Promise<any> {
    return this.dataSource.getAdminLogs(auth, details)
  }
}
