import { useEffect } from "react"
import {
  STR_LOGIN,
  STR_LOGIN_SUBTITLE,
  STR_LOGIN_TITLE,
  STR_PASSWORD,
  STR_EMAIL,
  STR_EMAIL_FIELD,
  STR_PASSWORD_FIELD,
  STR_FORGOT_PASSWORD,
  STR_LOGIN_SUBTITLE_2,
  STR_LOGIN_TITLE_2,
} from "core/constants/strings"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import LoginLayout from "core/layouts/LoginLayout"
import useLoginViewModel from "./LoginViewModel"
import { cn } from "core/lib/utils"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import { useNavigate } from "react-router-dom"
import { Head } from "core/components/seo"
import { isEmpty } from "core/utils/misc"
import { useAuth } from "core/context/auth"

export default function LoginView() {
  const {
    user,
    toast,
    loading,
    emailValidity,
    inputRef,
    handleInputChange,
    handleLogin,
    changeToastVisibility,
    redirectToPath,
    handleEmailBlur,
  } = useLoginViewModel()

  const navigate = useNavigate()
  const { user: userAuth } = useAuth()

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (!isEmpty(userAuth)) {
      navigate(redirectToPath(userAuth.role))
    }
  }, [userAuth])

  return (
    <>
      <Head title="Login" />
      <div className="fixed top-0 left-0 translate-x-[-50%] translate-y-[-50%] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>

        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="absolute flex justify-center left-[60%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} className="w-[370px]" />
      <LoginLayout className="bg-new-solid-white z-10 gap-0">
        <form className="flex w-full flex-col gap-8" onSubmit={handleLogin}>
          <div className="w-full">
            <h1 className="text-[#162456] text-[24px]">{STR_LOGIN_TITLE_2}</h1>
            <p className="text-[14px] text-[#414141] top-0">{STR_LOGIN_SUBTITLE_2}</p>
          </div>
          <div className="relative">
            <Input
              ref={inputRef}
              type={STR_EMAIL_FIELD}
              name={STR_EMAIL_FIELD}
              value={user.email}
              onChange={handleInputChange}
              placeholder={STR_EMAIL}
              label="Email"
              id="login-email"
              onBlur={handleEmailBlur}
              className="text-[#333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
              containerClass="text-sm gap-0 text-[#333]"
            />
            <span
              className={cn(
                "absolute top-[calc(100%+4px)] text-xs",
                emailValidity.status ? "text-new-success" : "text-new-failure"
              )}
            >
              {emailValidity.message}
            </span>
          </div>
          <Input
            type={STR_PASSWORD_FIELD}
            name={STR_PASSWORD_FIELD}
            value={user.password}
            onChange={handleInputChange}
            placeholder={STR_PASSWORD}
            label="Password"
            id="login-password"
            className="text-[#333333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
            containerClass="text-sm gap-0 text-[#333333]"
          />
          <div className="flex flex-col">
            <Button loading={loading} className="rounded-md text-sm bg-[#1369EB] mb-[8px]">
              {STR_LOGIN}
            </Button>
            <div className="flex flex-row justify-between">
              <div>{STR_FORGOT_PASSWORD}</div>
              <button onClick={() => navigate("/forgot_password")} className="text-sm text-new-accent ">
                Click Here!
              </button>
            </div>
          </div>
        </form>
      </LoginLayout>
      <div className="flex justify-center absolute right-[60%] transform scale-y-[-1] scale-x-[-1]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 translate-x-[50%] translate-y-[-50%] scale-y-[-1] scale-x-[-1] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
    </>
  )
}
