import React from "react"

type Props = {
  tableHeader: string[]
  tableRow: object[]
  title: string
  isModuleWise: boolean
  compensationDetails: any[]
}

const fixedWidthStyle = {
  width: "150px",
  maxWidth: "150px",
  minWidth: "150px",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const formatDate = (date: number) => {
  const year = Math.floor(date / 100)
  const month = date % 100
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return `${monthNames[month - 1]} ${year}`
}

const CompensationDetailTable: React.FC<Props> = ({
  tableHeader,
  tableRow,
  title,
  isModuleWise,
  compensationDetails,
}) => {
  let flattenedDetails: any[] = Array.isArray(compensationDetails) ? compensationDetails.flat() : []
  let currentCompensation: number[] = []

  console.log(flattenedDetails)

  if (Array.isArray(flattenedDetails)) {
    const tempDetails = flattenedDetails.filter((detail) => Number(detail.end_time) === 9999999999999)
    currentCompensation = tempDetails.map((detail) => Number(detail.amount))
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="text-[#414141] text-[22px]">
        {title}
        {": "}
        {!isModuleWise && <span className="text-[#00C22B]">Rs. {currentCompensation} </span>}
      </div>
      <div className="relative overflow-x-auto rounded-md shadow-md">
        <table className="w-full text-left text-sm text-new-neutral-dark">
          <thead className="bg-new-neutral-dark text-new-solid-white">
            <tr>
              {tableHeader?.map((headerTitle: string, index: number) => (
                <th key={index} style={fixedWidthStyle} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">
                  {headerTitle}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {flattenedDetails?.map((data: any, rowIndex: number) => (
              <tr className={`bg-new-solid-white ${rowIndex % 2 === 0 ? "even:bg-[#F5F5F5]" : ""}`} key={rowIndex}>
                <td style={fixedWidthStyle} className="px-4 py-4 font-montserrat text-sm font-normal leading-[22.4px]">
                  00{rowIndex + 1}
                </td>
                <td style={fixedWidthStyle} className="px-4 py-4 text-[#312E81] text-sm font-semibold">
                  {data.amount || "N/A"} Rs.
                </td>
                <td style={fixedWidthStyle} className="px-4 py-4 font-montserrat text-sm font-normal leading-[22.4px]">
                  {!isModuleWise
                    ? formatDate(data.start_time)
                    : formatDate(data.start_date.substr(0, 4) + data.start_date.substr(5, 2))}
                </td>
                {isModuleWise && (
                  <td style={fixedWidthStyle} className="px-4 py-4">
                    {data.module || "N/A"}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CompensationDetailTable
