import { Auth } from "domain/model/Auth"
import { FeatureFeedbackRepository } from "domain/repository/Admin/FeatureFeedback"

export interface GetAllFeedbackUseCase {
  invoke(auth: Auth): Promise<any[]>
}

export class GetAllFeedback implements GetAllFeedbackUseCase {
  private readonly featurefeedbackRepository: FeatureFeedbackRepository

  constructor(feedbackRepository: FeatureFeedbackRepository) {
    this.featurefeedbackRepository = feedbackRepository
  }

  async invoke(auth: Auth): Promise<any> {
    const feedbackData = await this.featurefeedbackRepository.getAllFeedback(auth)

    if (!feedbackData || feedbackData.length === 0) {
      return []
    }

    return feedbackData
  }
}
