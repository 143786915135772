import { cn } from "core/lib/utils"
import { useEffect, useRef, useState } from "react"

export default function Table({ data, outlined, student_email, empty = "No data available" }: any) {
  let studentRow = data?.lbUser
  data?.rows?.forEach((row: any, i: any) => {
    if (data?.lbUser?.email === row?.email) {
      studentRow = []
    }
  })
  return (
    <div
      className={cn(
        "relative overflow-x-auto rounded-[10px]",
        outlined ? "border border-new-neutral-light" : "shadow-table"
      )}
    >
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-editor-dark-500 text-new-solid-white">
          <tr>
            {data?.header?.map((title: any, i: any) => (
              <th
                key={i}
                className={cn(
                  "px-4 py-[10px] text-[12px] font-[500] leading-[15px]",
                  title === "Last 7 days Problems Solved" ? "text-right" : "text-left"
                )}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.rows?.length > 0 ? (
            data?.rows?.map((row: any, i: any) => (
              <tr
                className={cn(
                  "group relative bg-new-solid-white text-left",
                  row.email === student_email && "bg-yellow-400"
                )}
                key={i}
              >
                {row?.code?.map((cell: any, j: any) => (
                  <td
                    key={j}
                    className={cn(
                      "whitespace-nowrap px-6 py-[10px] font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark",
                      j == row.code.length - 1 && "flex justify-end mr-[60px]",
                      j > 0 && j == row.code.length - 2 && "text-right"
                    )}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-new-misc-gray">
              <td
                colSpan={data.header.length}
                className="py-2 text-center font-medium leading-6 text-new-neutral-light"
              >
                {empty}
              </td>
            </tr>
          )}

          {/* Render the student row at the bottom if not in view */}
          {studentRow && (
            <tr className="group sticky bottom-0 z-20 bg-yellow-400 text-left">
              {studentRow?.leaderboardUserCode?.map((cell: any, j: any) => (
                <td
                  key={j}
                  className={cn(
                    "whitespace-nowrap px-6 py-[10px] font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark",
                    j === 4 && "flex justify-end mr-[60px]",
                    j > 0 && j >= studentRow?.leaderboardUserCode?.length - 2 && "text-right"
                  )}
                >
                  {cell}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
