import React from "react"

type StatusSelectDropdownProps = {
  options: string[]
  value: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const StatusSelectDropdown: React.FC<StatusSelectDropdownProps> = ({ options, value, onChange }) => {
  return (
    <select className="text-sm" value={value} onChange={onChange}>
      {options.map((option, index) => (
        <option
          key={index}
          value={option}
          disabled={
            option === "Select Status" ||
            option === "Mock Status" ||
            option === "LinkedIn Status" ||
            option === "Resume Status"
          }
          className="bg-neutral-50 text-sm text-neutral-500"
        >
          {option}
        </option>
      ))}
    </select>
  )
}

export default StatusSelectDropdown
