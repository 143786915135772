import IconButton from "core/components/IconButton"

import { ToggleButton } from "core/components/v2/ToggleButton"

import { AdminInterface } from "domain/model/SuperAdminDashboard"
import ConfirmationPopUpDeleteAdmin from "./ConfirmationPopUPDeleteAdmin"

export default function SuperAdminDashboardTable(props: any) {
  const { admins } = props

  return (
    <div className="overflow-x-auto relative rounded-md shadow-md mt-5 h-full" style={{ overflow: "auto" }}>
      <table className="border-separate rounded-xl w-full text-left text-sm text-new-neutral-dark border-spacing-y-2">
        <thead className="bg-new-neutral-dark font-bold-100 text-new-solid-white ">
          <tr>
            {props?.tableHeaders?.map((title: any, index: any) => (
              <th
                key={index}
                className={`whitespace-nowrap px-4 py-4 font-light ${
                  index === 0 ? "rounded-l-md" : ""
                } ${index === props.tableHeaders.length - 1 ? "rounded-r-md" : ""}`}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {admins &&
            admins?.map((data: AdminInterface, index: any) => {
              return (
                <tr
                  className="relative bg-new-solid-white border-[1px] row-border hover:cursor-pointer hover:bg-slate-50 h-[65px]"
                  key={data.email}
                >
                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <div className="flex items-center gap-2">
                      <p className="text-[14px] font-semibold">{data.name}</p>
                    </div>

                    <div className="flex items-center gap-2">
                      <p className="text-[14px] font-semibold">{data.email}</p>
                    </div>
                    <div className="flex text-[12px]">
                      <p>+ 91 {data.phone}</p>
                    </div>
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <ToggleButton
                      checked={data?.is_superadmin}
                      onChange={() => {
                        props?.updateRoleAdminHandle(data.email, data.is_superadmin ? false : true)
                      }}
                    />
                  </td>

                  <td className="items-center px-6 py-4 border-t-2 border-b-2">
                    <IconButton
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="red"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit"
                        >
                          <path d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                      }
                      onClick={() => {
                        props.setDeleteEmail(data.email)
                        props.setDeleteModal(true)
                      }}
                    />

                    {props.deleteModal && (
                      <ConfirmationPopUpDeleteAdmin
                        setDeleteModal={props.setDeleteModal}
                        heading="Are you sure you want to delete this admin?"
                        email={props.deleteEmail}
                        deleteAdminHandle={props?.deleteAdminHandle}
                      />
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
// onClick={() => props?.deleteAdminHandle(data.email)}
