import HomePageGradient from "core/components/new/HomePageGradient"
import { Button } from "core/components/v2/Button"
import { cn } from "core/lib/utils"
import React from "react"
import Avatar from "react-avatar"

const TempMentorCard = ({ temp_mentor, isTemp }: any) => {
  return (
    <div className="relative my-[14px] bg-white h-[115px] border-[0.5px] border-[#D3D3D3] rounded-[8px] p-[16px]">
      <div className="absolute top-0 left-[80px]">
        <HomePageGradient className={"#E0E8ED"} />
      </div>
      <div className="absolute top-0 left-[160px]">
        <HomePageGradient className={"#E0E8ED"} />
      </div>
      <div className="flex gap-[16px]">
        <Avatar
          className="shrink-0  rounded object-cover"
          src={temp_mentor?.photo}
          name={temp_mentor?.name}
          size="80"
        />
        <div className="flex justify-between w-full">
          <div className="flex flex-col justify-between">
            <div>
              <p className="text-[14px] text-[#333] font-[600]">{temp_mentor?.name}</p>
              <p className="text-[12px] font-[400]">
                {temp_mentor?.current_company} | {temp_mentor?.experience} Years of Exp.
              </p>
            </div>
            <p className="text-[#FBBF24] text-[14px] font-[500]">
              {temp_mentor?.rating ? temp_mentor.rating.toFixed(2) : "0.00"} Rating
            </p>
          </div>

          <div className={cn("flex flex-col items-end", isTemp ? "justify-end" : "justify-between")}>
            {!isTemp && <p className="text-[#07B42D] text-[14px] font-[600]">Temp. Mentor Booked</p>}
            <Button
              variant={"ternary"}
              size={"sm"}
              border={"thin"}
              className="px-[16px] py-[9px]"
              onClick={() => {
                const url = temp_mentor?.linkedin
                if (url) {
                  window.open(url, "_blank", "noopener,noreferrer")
                }
              }}
              aria-label="View LinkedIn profile of the mentor"
            >
              Linkedin Id
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TempMentorCard
