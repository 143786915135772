import { PlacementDataSource } from "data/dataSource/Student/PlacementDataSource"
import { Auth } from "domain/model/Auth"
import { jobDetails } from "domain/model/Placement"

export class PlacementRepositoryImpl {
  private dataSource: PlacementDataSource

  constructor(dataSource: PlacementDataSource) {
    this.dataSource = dataSource
  }

  getRecentPlacement(auth: Auth) {
    return this.dataSource.getRecentPlacement(auth)
  }
  getNewPlacement(auth: Auth) {
    return this.dataSource.getNewPlacement(auth)
  }
  getAllJobs(auth: Auth) {
    return this.dataSource.getAllJobs(auth)
  }

  getJobDetails(request: jobDetails) {
    return this.dataSource.getJobDetails(request)
  }

  getApplicationStatus(request: jobDetails) {
    return this.dataSource.getApplicationDetails(request)
  }
  getStudentsJob(auth: Auth) {
    return this.dataSource.getStudentsJob(auth)
  }
  applyForJob(request: jobDetails) {
    return this.dataSource.applyForJob(request)
  }

  getStudentDetails(auth: Auth, docId: string) {
    return this.dataSource.getStudentDetails(auth, docId)
  }

  updateStudentDetails(auth: Auth, docId: string, updatedDetails: any, resumeFile: any) {
    return this.dataSource.updateStudentDetails(auth, docId, updatedDetails, resumeFile)
  }
}
