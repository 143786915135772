import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface GetExamDetailsUseCase {
  invoke(auth: Auth, exam_id: string): Promise<any>
}

export default class GetExamDetails implements GetExamDetailsUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, exam_id: string) {
    return this.repository.getExamDetails(auth, exam_id)
  }
}
