import { useAuth } from "core/context/auth"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import GetAllInstructorDetails from "domain/useCase/Admin/GenerateInvoice/GetAllInstructorDetails"
import GetInstructorDetails from "domain/useCase/Admin/GenerateInvoice/GetInstructorDetails"
import updateMentorDetails from "domain/useCase/Admin/GenerateInvoice/UpdateMentorDetails"
import React, { useState } from "react"
import { Auth } from "domain/model/Auth"
import GetAllSessionOfMonthYear from "domain/useCase/Admin/GenerateInvoice/GetAllSessionOfMonthYear"
import UpdateMentorDetails from "domain/useCase/Admin/GenerateInvoice/UpdateMentorDetails"
import UpdateMentorCompensationDetails from "domain/useCase/Admin/GenerateInvoice/UpdateMentorCompensationDetails"
import GetInstructorAverageRating from "domain/useCase/Admin/GenerateInvoice/GetInstructorAverageRating"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { genError } from "core/utils/string"
import GetMentorSessionOfMonthYear from "domain/useCase/Admin/GenerateInvoice/GetMentorSessionOfMonthYear"
import GetAllMentorsList from "domain/useCase/Admin/GenerateInvoice/GetAllMentorsList"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { MentorSessionsRepositoryImpl } from "data/repository/Admin/MentorSessionsRepositoryImpl"
import MentorSessionsAPIDataSourceImpl from "data/API/Admin/MentorSessionsAPIDataSourceImpl"
import MentorSessionInvoicing from "domain/useCase/Admin/MentorSessions/MentorSessionInvoicing"
import FinalizeMentorSessionInvoicing from "domain/useCase/Admin/MentorSessions/FinalizeMentorSessionInvoicing"
import GetGroupMentorSessionOfMonthYear from "domain/useCase/Admin/GenerateInvoice/GetGroupMentorSessionOfMonthYear"

export default function GenerateMentorInvoiceViewModel() {
  const { auth } = useAuth()
  const [instructor_id, setInstructorId] = React.useState<string>("")
  const [yearMonth, setYearMonth] = React.useState<string>("")
  const [time_given, setTimeGiven] = React.useState<string>("")
  const [year, setYear] = React.useState<string>("")
  const [month, setMonth] = React.useState<string>("")
  const [getBatchList, setBatchList] = React.useState<any>()
  const [compensationAmount, setCompensationAmount] = React.useState<string>("")
  const [goalSettingCompensationAmount, setGoalSettingCompensationAmount] = useState<Number>(0)
  const [extragoalSettingAmount, setextraGoalSettingAmount] = useState<Number>(0)
  const [allInstructorDetails, setAllInstructorDetails] = React.useState<any>("")
  const [instructorDetails, setInstructorDetails] = React.useState<any>("")
  const [updatedMentorDetails, setUpdatedMentorDetails] = React.useState<any>()
  const [finalInvoice, setFinalInvoice] = React.useState<any>()
  const [Loading, setLoading] = React.useState<boolean>(false)
  const [invoiceAmountLoading, setInvoiceAmountLoading] = useState<boolean>(false)
  const [updatedMentorCompensationDetails, setUpdatedMentorCompensationDetails] = React.useState<any>({})
  const [confirmInvoice, setConfirmInvoice] = React.useState<any>()
  const [allSessionMonthYear, setAllSessionMonthYear] = React.useState<any>({})
  const [groupSessionMonthYear, setGroupSessionMonthYear] = React.useState<any>({})
  const [mentor_id, setMentorId] = React.useState<string>("")
  const [batch, setBatch] = React.useState<string>("")
  const [instructorAverageRating, setInstructorAverageRating] = React.useState<any>()
  const [allMentorsList, setAllMentorsList] = React.useState<any>()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  function handleSearchByInstructor(e: any) {
    if (e.target.value) setInstructorId(e.target.value)
    else setInstructorId("")
  }
  function handleSearchByCompensationAmount(e: any) {
    if (e.target.value) setCompensationAmount(e.target.value)
    else setCompensationAmount("")
  }

  function handleSearchByYear_month(e: any) {
    if (e.target.value) setYearMonth(e.target.value)
    else setYearMonth("")
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }
  const GetAllInstructorDetailsUseCase = new GetAllInstructorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const GetInstructorAverageRatingUseCase = new GetInstructorAverageRating(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const UpdateMentorDetailsUseCase = new UpdateMentorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetInstructorDetailsUseCase = new GetInstructorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const UpdateMentorCompensationDetailsUseCase = new UpdateMentorCompensationDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetMentorSessionOfMOnthYearUseCase = new GetMentorSessionOfMonthYear(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetGroupMentorSessionOfMOnthYearUseCase = new GetGroupMentorSessionOfMonthYear(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const MentorSessionInvoicingUseCase = new MentorSessionInvoicing(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const GetAllMentorsListUseCase = new GetAllMentorsList(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const FinalizeMentorSessionInvoicingUseCase = new FinalizeMentorSessionInvoicing(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const fetchAllInstructorDetails = async () => {
    const response = await GetAllInstructorDetailsUseCase.invoke(auth)

    if (!response?.success) {
      return
    }
    setAllInstructorDetails(response?.data)
  }

  const fetchInstructorAverageRating = async (auth: Auth, instructor_id: string) => {
    const response = await GetInstructorAverageRatingUseCase.invoke(auth, instructor_id)

    if (!response?.success) {
      return
    }
    setInstructorAverageRating(response?.data)
  }
  const fetchInstructorDetails = async (auth: Auth, instructor_id: string) => {
    const response = await GetInstructorDetailsUseCase.invoke(auth, instructor_id)
    if (!response?.success) {
      return
    }

    setInstructorDetails(response?.data)
    setGoalSettingCompensationAmount(response?.data.goal_setting_session_compensation)
    setextraGoalSettingAmount(response?.data.extra_goal_setting_amount)
  }

  const fetchUpdatedMentorDetails = async (auth: Auth, instructor_id: string, time_given: string) => {
    const response = await UpdateMentorDetailsUseCase.invoke(auth, instructor_id, time_given)

    if (!response?.success) {
      return
    }

    setUpdatedMentorDetails(response?.data)
    return response?.data
  }

  const fetchUpdatedMentorCompensationDetails = async (
    auth: Auth,
    instructor_id: string,
    compensationAmount: number,
    yearMonth: string
  ) => {
    const response = await UpdateMentorCompensationDetailsUseCase.invoke(
      auth,
      instructor_id,
      compensationAmount,
      yearMonth
    )
    if (!response?.success) {
      return
    }

    setUpdatedMentorCompensationDetails(response?.data)
  }

  const fetchMentorSessionMonthyear = async (
    auth: Auth,
    mentor_id: string,
    batch: string,
    year: string,
    month: string
  ) => {
    const response = await GetMentorSessionOfMOnthYearUseCase.invoke(auth, mentor_id, batch, year, month)

    if (!response?.success) {
      return
    }

    setAllSessionMonthYear(response?.data)
  }

  const fetchGroupMentorSessionMonthyear = async (
    auth: Auth,
    mentor_id: string,
    batch: string,
    year: string,
    month: string
  ) => {
    const response = await GetGroupMentorSessionOfMOnthYearUseCase.invoke(auth, mentor_id, batch, year, month)
    if (!response?.success) {
      return
    }

    setGroupSessionMonthYear(response?.data)
  }
  const fetchInvoiceOfMentor = async (auth: Auth, instructor_id: string, year_month: string) => {
    const response = await MentorSessionInvoicingUseCase.invoke(auth, instructor_id, year_month)
    setInvoiceAmountLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      changeToastVisibility(true)
      return "Invoice Not generated"
    }

    changeToastDetails(STR_SUCCESS, ":)")
    changeToastVisibility(true)
    setFinalInvoice(response?.data)
    return response?.data
  }

  const fetchAllMentorList = async () => {
    const response = await GetAllMentorsListUseCase.invoke(auth)

    if (!response?.success) {
      return
    }
    setAllMentorsList(response?.data)
  }

  const confirmInvoiceOfMentor = async (auth: Auth, instructor_id: string, year_month: string) => {
    const response = await FinalizeMentorSessionInvoicingUseCase.invoke(auth, instructor_id, year_month)

    if (!response?.success) {
      return
    }
    setConfirmInvoice(response?.data)
    return response?.data
  }

  return {
    auth,
    instructor_id,
    yearMonth,
    compensationAmount,
    time_given,
    year,
    month,
    Loading,
    toast,
    batch,
    instructorAverageRating,
    allInstructorDetails,
    instructorDetails,
    updatedMentorDetails,
    updatedMentorCompensationDetails,
    allSessionMonthYear,
    finalInvoice,
    allMentorsList,
    getBatchList,
    confirmInvoice,
    groupSessionMonthYear,
    getBatches,
    confirmInvoiceOfMentor,
    changeToastDetails,
    changeToastVisibility,
    setFinalInvoice,
    setLoading,
    setBatch,
    fetchAllMentorList,
    handleSearchByInstructor,
    handleSearchByYear_month,
    setYearMonth,
    setCompensationAmount,
    fetchInstructorAverageRating,
    handleSearchByCompensationAmount,
    fetchAllInstructorDetails,
    fetchInvoiceOfMentor,
    fetchInstructorDetails,
    fetchUpdatedMentorDetails,
    fetchUpdatedMentorCompensationDetails,
    fetchMentorSessionMonthyear,
    fetchGroupMentorSessionMonthyear,
    setAllSessionMonthYear,
    goalSettingCompensationAmount,
    setGoalSettingCompensationAmount,
    extragoalSettingAmount,
    setextraGoalSettingAmount,
    invoiceAmountLoading,
    setInvoiceAmountLoading,
  }
}
