import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import React from "react"

export default function AdminTable2({
  data,
  emiAmount,
  outlined,
  headerWidths,
  rowWidths,
  paddingInfo,
  OperationalIssue,
  futureBatchEligiblity,
}: any) {
  const [infoHover, setInfoHover] = React.useState<any>(false)
  const [index, setIndex] = React.useState<any>()
  const [rowIndex, setRowIndex] = React.useState<any>()
  const [hoverContent, setHoverContent] = React.useState<any>("")
  return (
    <div
      className={cn(
        "relative w-full overflow-x-auto rounded ",
        outlined ? "border border-new-neutral-light" : "shadow-[0px_4px_16px_rgba(134,134,134,0.2)]"
      )}
    >
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-editor-dark-500 text-new-solid-white">
          <tr>
            {data?.header?.map((title: any, i: any) => (
              <th
                key={i}
                className={cn(
                  ` relative whitespace-nowrap   py-[16px] font-medium text-new-solid-white group-hover:text-new-neutral-dark`,
                  i > 0 && i === data.header.length - 1,
                  futureBatchEligiblity &&
                    futureBatchEligiblity[i]["is_batch_open_to_choose"] === false &&
                    "opacity-50",
                  // i > 0 && i === data.header.length - 1 && `w-full  text-right`,
                  // i === data.header.length - 2 && 'text-right ',
                  i === 0 && "w-[100px] pl-[24px]",
                  i === 1 && "w-[150px]",
                  i === 2 && "w-[150px]",

                  i === 3 && "w-[150px]",
                  i === 4 && "w-[100px]",
                  i === 5 && "w-[150px] pl-[16px]",
                  i === 6 && "pr-[24px]"
                )}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmpty(data?.rows) ? (
            data?.rows?.map((row: any, ind: any) => (
              <tr className="group relative bg-new-solid-white hover:bg-new-misc-gray" key={ind}>
                {Object.keys(row)?.map((key: any, i: any) => (
                  <td
                    key={key}
                    className={cn(
                      ` relative  whitespace-nowrap font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark`,
                      i > 0 && i === Object.keys(row)?.length - 1 && "flex justify-start ",
                      i === Object.keys(row)?.length - 2 && "text-left ",
                      futureBatchEligiblity &&
                        futureBatchEligiblity[ind]["is_batch_open_to_choose"] === false &&
                        "opacity-50",

                      // i > 0 &&
                      //   i === data.rows?.length - 1 &&
                      //   `w-full text-right`,
                      // i === 0 && 'w-[100px]',
                      // ` text-left`
                      i === 0 && "w-[70px] pl-[24px]",
                      i === 1 && "w-[150px]",
                      i === 2 && "w-[150px]",

                      i === 3 && "w-[150px]",
                      i === 4 && "w-[100px]",
                      i === 5 && "w-[150px] pl-0",
                      i === 6 && "pr-[24px]"
                    )}
                    onMouseEnter={() => {
                      setIndex(i)
                      setRowIndex(ind)
                      setInfoHover(true)
                      setHoverContent(row[key])
                    }}
                    onMouseLeave={() => setInfoHover(false)}
                  >
                    {OperationalIssue ? (row[key].length > 30 ? row[key].slice(0, 30) + "..." : row[key]) : row[key]}
                  </td>
                ))}
                {ind === rowIndex &&
                  index === 2 &&
                  futureBatchEligiblity &&
                  !futureBatchEligiblity[rowIndex]["is_batch_open_to_choose"] &&
                  infoHover && (
                    <td className="absolute right-[45px] top-[-28px] z-50 flex items-center justify-center rounded-sm bg-[#312E81] py-1  opacity-100">
                      <div className="text-[12px] font-medium text-white">
                        Selection for this batch is only possible once it commences
                      </div>
                    </td>
                  )}
                {ind === rowIndex && hoverContent.length > 30 && index === 1 && OperationalIssue && infoHover && (
                  <td className="absolute left-[250px] top-[-28px] z-50 flex items-center justify-center rounded-sm bg-[#312E81] py-1 px-2 opacity-100">
                    <div className="text-[12px] font-medium text-white">{data?.rows[rowIndex]["status"]}</div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-new-misc-gray">
              <td
                colSpan={data?.header?.length}
                className="py-2 text-center font-medium leading-6 text-new-neutral-light"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
