import { STR_FAILURE, STR_SUCCESS, STR_UNKNOWN_ERROR_OCCURRED } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { isEmpty } from "core/utils/misc"
import { genError } from "core/utils/string"
import AvailabilityAPIDataSourceImpl from "data/API/Mentor/AvailabilityAPIDataSourceImpl"
import { AvailabilityRepositoryImpl } from "data/repository/Mentor/AvailabilityRepositoryImpl"
import { GetAvailability } from "domain/useCase/Mentor/Availability/GetAvailability"
import { UpdateAvailability } from "domain/useCase/Mentor/Availability/UpdateAvailability"
import { BlockDates } from "domain/useCase/Mentor/Availability/BlockDates"
import React from "react"
import { GetGoalSettingAvailability } from "domain/useCase/Mentor/Availability/GetGoalSettingAvailability"

export default function AvailabilityViewModel() {
  const OPTION_HOUR24_LIST = [...Array(24)].map((_, i) => ({
    label: i,
    value: i,
  }))
  const [optionHour24List, setOptionHour24List] = React.useState<any>({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  })
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [availability, setAvailability] = React.useState<any>(null)
  const [goalSettingAvailability, setGoalSettingAvailability] = React.useState<any>([])
  const [sunday, setSunday] = React.useState<any>([])
  const [monday, setMonday] = React.useState<any>([])
  const [tuesday, setTuesday] = React.useState<any>([])
  const [wednesday, setWednesday] = React.useState<any>([])
  const [thursday, setThursday] = React.useState<any>([])
  const [friday, setFriday] = React.useState<any>([])
  const [saturday, setSaturday] = React.useState<any>([])
  const [updateLoading, setUpdateLoading] = React.useState<any>(false)
  const [BDLoading, setBDLoading] = React.useState<any>(false)
  const [BDList, setBDList] = React.useState<any>([])
  const [date, setDate] = React.useState<any>([])
  const initialWeekMap = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  }
  const [newStart, setNewStart] = React.useState<any>(initialWeekMap)

  const [newEnd, setNewEnd] = React.useState<any>(initialWeekMap)

  const dayFunctions: any = {
    monday: setMonday,
    tuesday: setTuesday,
    wednesday: setWednesday,
    thursday: setThursday,
    friday: setFriday,
    saturday: setSaturday,
    sunday: setSunday,
  }

  const dayValues: any = {
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
  }

  const getAvailabilityUseCase = new GetAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const updateAvailabilityUseCase = new UpdateAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const getGoalSettingAvailabilityUseCase = new GetGoalSettingAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const blockDatesUseCase = new BlockDates(new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl()))
  const fetchGoalSettingAvailability = async () => {
    setIsLoading(true)

    const response = await getGoalSettingAvailabilityUseCase.invoke(auth)

    setIsLoading(false)
    setGoalSettingAvailability(response?.data?.date_obj)
  }
  const fetchAvailability = async () => {
    setIsLoading(true)

    const response = await getAvailabilityUseCase.invoke(auth)

    setIsLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    const { availability } = response
    setAvailability(availability)
    Object.keys(dayFunctions).forEach(
      (dayKey) => !isEmpty(availability[dayKey]) && dayFunctions[dayKey](availability[dayKey])
    )
    setBDList(availability?.mentor_blocked_dates)
  }

  const handleNewSlotChange = (e: any, dayKey: string) => {
    const { value } = e.target
    const newStartCopy = { ...newStart }
    const newEndCopy = { ...newEnd }
    newStartCopy[dayKey] = value
    newEndCopy[dayKey] = ((parseInt(value) + 1) % 24).toString()
    setNewStart(newStartCopy)
    setNewEnd(newEndCopy)
  }

  interface Slot {
    start: number
    end: number
  }

  const handleSlotChange = (e: React.ChangeEvent<HTMLSelectElement>, dayKey: string, index: number) => {
    const start = parseFloat(e.target.value)

    // Calculate the end value with half-hour increments and ensure it wraps correctly
    const end = ((start + 1) % 24).toString()

    // Create the updated slot object
    const updatedSlot: Slot = { start, end: parseFloat(end) }

    // Get current slots for the day
    const currentSlots = dayValues[dayKey] as Slot[]

    // Update the slot at the specified index
    const updatedSlots = currentSlots.map((slot, i) => (i === index ? updatedSlot : slot))

    // Get the function for updating the specific day
    const setDay = dayFunctions[dayKey]

    // Check if the updated slot overlaps with existing slots in the same day's availability
    const availabilitySlots = availability[dayKey] || []
    const normalAvailabilitySlots = goalSettingAvailability[dayKey] || []
    const skipNormalAvailabilityCheck = !availabilitySlots || Object.keys(availabilitySlots).length === 0

    const isOverlappingWithAvailability =
      !skipNormalAvailabilityCheck && availabilitySlots.some((slot: Slot) => isOverlapping(updatedSlot, slot))

    const isOverlappingWithNormalAvailability = normalAvailabilitySlots.some((slot: Slot) =>
      isOverlapping(updatedSlot, slot)
    )

    if (isOverlappingWithAvailability || isOverlappingWithNormalAvailability) {
      changeToastDetails(STR_FAILURE, "The particular slot is being overlapped with mentor session availability.")
      changeToastVisibility(true)
      console.warn("The updated slot overlaps with existing availability or normal availability on the same day")
      return
    }

    // If no overlap, update the slot
    setDay(updatedSlots)
  }

  // Utility function to check if two slots overlap
  const isOverlapping = (slot1: { start: number; end: number }, slot2: { start: number; end: number }): boolean => {
    return slot1.start < slot2.end && slot1.end > slot2.start
  }

  // List of valid days (adjust this according to your use case)
  const validDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

  const handleAddSlot = (dayKey: string) => {
    // Check if the dayKey is a valid day
    if (!validDays.includes(dayKey.toLowerCase())) {
      console.warn("Invalid day selected")
      return
    }

    const setDay = dayFunctions[dayKey]
    const day = dayValues[dayKey]
    const start = newStart[dayKey]
    const end = start === 0 ? 1 : newEnd[dayKey]

    // Create the new slot
    const newSlot = { start, end }

    // Reset start and end input values
    const newStartCopy = { ...newStart }
    const newEndCopy = { ...newEnd }
    newStartCopy[dayKey] = 0
    newEndCopy[dayKey] = newStartCopy[dayKey] + 1
    setNewStart(newStartCopy)
    setNewEnd(newEndCopy)

    // Check if the new slot already exists in the current day
    const slotExists = day.some((slot: any) => slot.start === start && slot.end === end)
    if (slotExists) {
      return
    }

    // Check if the new slot overlaps with existing slots in the same day's availability
    const availabilitySlots = availability[dayKey] || [] // Ensuring availabilitySlots is an array even if undefined
    const normalAvailabilitySlots = goalSettingAvailability[dayKey] || [] // Same here
    const skipNormalAvailabilityCheck = !availabilitySlots || Object.keys(availabilitySlots).length === 0

    const isOverlappingWithAvailability =
      !skipNormalAvailabilityCheck && availabilitySlots.some((slot: any) => isOverlapping(newSlot, slot))

    const isOverlappingWithNormalAvailability = normalAvailabilitySlots.some((slot: any) =>
      isOverlapping(newSlot, slot)
    )

    if (isOverlappingWithAvailability || isOverlappingWithNormalAvailability) {
      changeToastDetails(STR_FAILURE, "The slot is overlapping")
      changeToastVisibility(true)
      console.warn("The new slot overlaps with existing availability or normal availability on the same day")
      return
    }

    // If no overlap, add the new slot
    setDay([...day, newSlot])
  }

  const handleRemoveSlot = (dayKey: string, index: number) => {
    const setDay = dayFunctions[dayKey]
    const day = dayValues[dayKey]
    const newDay = day?.filter((s: any, i: number) => i !== index)
    setDay(newDay)
  }
  const handleToggleDay = (e: any, dayKey: string) => {
    const setDay = dayFunctions[dayKey]
    if (e.target.checked) {
      setDay([{ end: "18", start: "17" }])
    } else {
      setDay([])
    }
  }

  const updateAvailabilitySlots = async () => {
    setUpdateLoading(true)

    const response = await updateAvailabilityUseCase.invoke(auth, dayValues)

    setUpdateLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Updated successfully")
    changeToastVisibility(true)
  }

  const submitBlockDates = async (e: any) => {
    e.preventDefault()
    setBDLoading(true)

    const response = await blockDatesUseCase.invoke(auth, BDList)

    setBDLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Dates blocked successfully")
    changeToastVisibility(true)
  }
  const handleDateChange = (e: any) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const dateString = e.target.value
    const dateParts = dateString.split("-") // Split the date string by "-"
    const year = parseInt(dateParts[0]) // Extract the year and parse it to an integer
    const month = parseInt(dateParts[1]) - 1 // Extract the month and parse it to an integer
    const day = parseInt(dateParts[2]) // Extract the day and parse it to an integer
    const selectedDate = new Date(year, month, day) // Create a Date object from the parsed parts
    if (selectedDate > yesterday) {
      setDate(e.target.value)
      if (!BDList.includes(e.target.value)) setBDList([...BDList, e.target.value])
    }
  }

  const handleRemoveDate = (index: number) => {
    setBDList(BDList.filter((_: any, i: number) => i !== index))
  }

  function getCurrentDate() {
    const today_curr = new Date()
    const year_curr = today_curr.getFullYear().toString()
    let month_curr = (today_curr.getMonth() + 1).toString()
    let day_curr = today_curr.getDate().toString()

    if (month_curr < "10") {
      month_curr = "0" + month_curr
    }
    if (day_curr < "10") {
      day_curr = "0" + day_curr
    }

    return `${year_curr}-${month_curr}-${day_curr}`
  }

  return {
    toast,

    availability,
    isLoading,
    dayValues,
    dayFunctions,
    updateLoading,
    BDLoading,
    BDList,
    date,
    OPTION_HOUR24_LIST,
    newStart,
    newEnd,
    getCurrentDate,
    changeToastVisibility,
    fetchAvailability,
    handleSlotChange,
    handleNewSlotChange,
    handleAddSlot,
    handleRemoveSlot,
    handleToggleDay,
    updateAvailabilitySlots,
    submitBlockDates,
    handleDateChange,
    handleRemoveDate,
    fetchGoalSettingAvailability,
  }
}
