import { Auth } from "domain/model/Auth"
import CourseDataSource from "data/dataSource/Student/CourseDataSource"
import {
  API_V4_STUDENT,
  API_V4_TOGGLE_LEETCODE_PROBLEM_COMPLETE_STATUS,
  API_V4_TOGGLE_PLATFORM_PROBLEM_STATUS,
  API_V4_TOGGLE_SESSION_COMPLETE_STATUS,
  STR_GET_CLASS_DETAILS,
  STR_GET_COURSE,
  STR_GET_FULL_COURSE_CONTENT,
  STR_GET_FEEDBACK_DETAILS,
  STR_SUBMIT_CLASS_FEEDBACK,
  STR_SUBMIT_RECORDING_CLASS_FEEDBACK,
  STR_VERIFY_LEETCODE,
  API_V4_GET_SSM_FEEDBACK_RESPONSE,
  STUDENT,
  ATTENDANCE_AND_ASSIGNMENTS,
  MASTERCLASS,
  API_GET_DSML_BEGINNER_MODULES_SESSIONS,
  CAPTURE_ATTENDANCE,
  MARK_ATTENDANCE,
  LEETCODE_VERIFIED,
  API_SUBMIT_CLASS_FEEDBACK_NEW,
  API_GET_CLASS_FEEDBACK_NEW,
  API_SUBMIT_CLASS_FEEDBACK_EXTERNAL,
  API_GET_CLASS_NAME,
} from "core/constants/strings"
import { toDate, toTime } from "core/utils/date"
import pn from "core/utils/pn"
import { postAPI, Server } from "core/utils/axios"

const server = new Server()
export class CourseAPIDataSourceImpl implements CourseDataSource {
  async getCourses(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_COURSE), {
        id_token: auth.id_token,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async getSessionDetails(auth: Auth, session_id: string) {
    const response = (await postAPI(pn(API_V4_STUDENT, STR_GET_CLASS_DETAILS), {
      id_token: auth.id_token,
      session_id: session_id,
    })) as unknown as any
    const data = response?.data
    return {
      date: toDate(data?.timestamp * 1000),
      time: toTime(data?.timestamp * 1000),
      timestamp: data?.timestamp * 1000,
      instructor_name: data?.instructor_name,
      assignments: data?.assignments,
      warmup: data?.warmup,
      homework: data?.homework,
      duration: data?.duration,
      practice: data?.practice,
      module_name: data?.module_name,
      session_name: data?.session_name,
      video_link: `https://player.vimeo.com/video/${data?.video_id}?h=${data?.video_secret ?? "a1b2c3d4e5f"}`,
      video_id: data?.video_id,
      video_secret: data?.video_secret,
      notes_link: data?.notes_link,
      notes_summary_link: data?.notes_summary_link,
      is_feedback_submitted: data?.is_feedback_submitted,
      is_attended: data?.is_attended,
      assignment_solution_activation_time: data?.assignment_solution_activation_time,
      assignment_solution_link: data?.assignment_solution_link,
      assignment_points: data?.assignment_points,
    }
  }

  async getFullCourseContent(auth: Auth, module_id: string): Promise<any> {
    const response = await postAPI(pn(API_V4_STUDENT, STR_GET_FULL_COURSE_CONTENT), {
      id_token: auth.id_token,
      module_id,
    })

    return response
  }

  async markAttendance(auth: Auth, session_id: string) {
    try {
      const response = await server.post(
        pn(STUDENT, CAPTURE_ATTENDANCE),
        { session_id },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
  async reedemCoin(auth: Auth, data: any) {
    try {
      const response = await server.post(pn(STUDENT, "reedem_coin"), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async fetchLeetCodeDetails(auth: Auth) {
    try {
      const response = await server.get(pn(STUDENT, LEETCODE_VERIFIED), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async setLeetCodeId(auth: Auth, leetcodeId: string) {
    const response = await postAPI(pn(API_V4_STUDENT, STR_VERIFY_LEETCODE), {
      id_token: auth.id_token,
      username: leetcodeId,
    })
    return response
  }

  async toggleRecordedComplete(auth: Auth, session_id: string, status: boolean) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_TOGGLE_SESSION_COMPLETE_STATUS), {
        id_token: auth.id_token,
        session_id: session_id,
        status: status,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async toggleLeetCodeProblemComplete(auth: Auth, problem_id: string, status: boolean, coins: number) {
    await postAPI(pn(API_V4_STUDENT, API_V4_TOGGLE_LEETCODE_PROBLEM_COMPLETE_STATUS), {
      id_token: auth.id_token,
      problem_id,
      status,
      coins,
    })
  }

  async submitFeedback(auth: Auth, data: any) {
    const response = await postAPI(pn(API_V4_STUDENT, STR_SUBMIT_CLASS_FEEDBACK), {
      id_token: auth.id_token,
      ...data,
    })
    return response
  }

  async submitRecordingFeedback(auth: Auth, data: any) {
    const response = await postAPI(pn(API_V4_STUDENT, STR_SUBMIT_RECORDING_CLASS_FEEDBACK), {
      id_token: auth.id_token,
      ...data,
    })
    return response
  }
  async getFeedbackDetails(auth: Auth, class_id: string) {
    const response = await postAPI(pn(API_V4_STUDENT, STR_GET_FEEDBACK_DETAILS), {
      id_token: auth.id_token,
      class_id: class_id,
    })
    return response
  }
  async getMasterClass(auth: Auth) {
    try {
      const response = await server.get(pn(STUDENT, MASTERCLASS), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }

  async getAttendanceAndAssignments(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(STUDENT, ATTENDANCE_AND_ASSIGNMENTS), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }

  async togglePlatformProblemStatus(auth: Auth, problem_id: string, status: boolean) {
    await postAPI(pn(API_V4_STUDENT, API_V4_TOGGLE_PLATFORM_PROBLEM_STATUS), {
      id_token: auth.id_token,
      problem_id,
      status,
    })
  }

  async markAttendanceManually(auth: Auth, session_id: string, status: boolean): Promise<any> {
    try {
      const response = await server.post(
        pn(STUDENT, MARK_ATTENDANCE),
        { session_id, status },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getSSMFeedbackResponse(
    auth: Auth,
    session_id: string,
    questionName: string,
    questionType: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_SSM_FEEDBACK_RESPONSE), {
        id_token: auth.id_token,
        session_id,
        questionName,
        questionType,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getDsmlBeginnerModuleSessions(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_DSML_BEGINNER_MODULES_SESSIONS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitClassFeedbackNew(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_CLASS_FEEDBACK_NEW), {
        id_token: auth.id_token,
        data: data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getClassFeedbackNew(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_CLASS_FEEDBACK_NEW), {
        id_token: auth.id_token,
        data: data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitClassFeedbackExternal(data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_CLASS_FEEDBACK_EXTERNAL), { data })
      return response
    } catch (error) {
      return error
    }
  }

  async getClassName(data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_CLASS_NAME), { data })
      return response
    } catch (error) {
      return error
    }
  }
}
