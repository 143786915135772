import GoalSettingSessionsDataSource from "data/dataSource/Admin/GoalSettingDataSourse"
import { Auth } from "domain/model/Auth"
import { GoalSettingSession } from "domain/model/GoalSettingSession"

export class GoalSettingSessionsRepositoryImpl implements GoalSettingSessionsRepositoryImpl {
  private dataSource: GoalSettingSessionsDataSource

  constructor(dataSource: GoalSettingSessionsDataSource) {
    this.dataSource = dataSource
  }
  async createGoalSettingSession(auth: Auth, session: GoalSettingSession): Promise<any> {
    return this.dataSource.createGoalSettingSession(auth, session)
  }
}
