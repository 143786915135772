import React from "react"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { AuthRepositoryImpl } from "data/repository/Common/AuthRepositoryImpl"
import AuthAPIDataSourceImpl from "data/API/Common/AuthAPIDataSourceImpl"
import { useNavigate } from "react-router-dom"
import delay from "core/utils/delay"
import useToast from "core/hooks/useToast"
import { ChangeEvent, useRef, useState } from "react"
import { STR_FILL_ALL_FIELDS, STR_LOGIN_SUCCESSFUL } from "core/constants/strings"
import { isEmail } from "core/utils/validator"
import { isEmpty } from "core/utils/misc"
import { useAuth } from "core/context/auth"
import { redirectPath } from "core/lib/utils"
import { ResetPasswordByStudent } from "domain/useCase/Admin/Auth/ResetPasswordByStudent"
import { useApp } from "core/context/app"

export default function LoginResetViewModel() {
  const { auth, logout } = useAuth()
  const { student } = useApp()
  const [email, setEmail] = React.useState<string>(student?.email)
  const [isResetting, setIsResetting] = React.useState<boolean>(false)

  const navigate = useNavigate()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [user, setUser] = useState<{
    email: string
    oldPassword: string
    newPassword: string
    reTypePassword: string
  }>({ email: student?.email, oldPassword: "", newPassword: "", reTypePassword: "" })
  const [loading, setLoading] = useState<boolean>(false)
  const [emailValidity, setEmailValidity] = useState({ status: false, message: "" })
  const [newPasswordValidity, setNewPasswordValidity] = useState({ status: false, message: "" })
  const [reTypePasswordValidity, setReTypePasswordValidity] = useState({ status: false, message: "" })

  const [passwordPageView, setPasswordPageView] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const resetPasswordByStudentUseCase = new ResetPasswordByStudent(new AuthRepositoryImpl(new AuthAPIDataSourceImpl())) //chinmay

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUser = { ...user, [e.target.name]: e.target.value }
    setUser(newUser)
    if (e.target.name === "newPassword") {
      handleNewPasswordBlur()
    }
    if (e.target.name === "reTypePassword") {
      handleReTypeNewPasswordBlur()
    }
  }

  const checkEmailValidity = () => {
    const isValid = isEmail(user.email)
    setEmailValidity({
      status: isValid,
      message: isValid ? "Good to go!" : "Sorry, there might be something wrong!",
    })
  }

  const handleEmailBlur = () => {
    checkEmailValidity()
  }

  const isPassword = (password: string) => {
    if (password.length < 6) {
      return false
    }
    return true
  }

  const isRetypePassword = (newPassword: string, reTypePassword: string) => {
    if (newPassword === reTypePassword) {
      return true
    }
    return false
  }

  const checkNewPasswordValidity = () => {
    user.newPassword = user.newPassword.trim()
    const isValid = isPassword(user.newPassword)
    setNewPasswordValidity({
      status: isValid,
      message: isValid ? "Good to go!" : "Password should be atleast 6 characters long",
    })
  }

  const checkReTypePasswordValidity = () => {
    user.reTypePassword = user.reTypePassword.trim()
    const isValid = isRetypePassword(user.newPassword, user.reTypePassword)
    setReTypePasswordValidity({
      status: isValid,
      message: isValid ? "Good to go!" : "Password doesn't not match",
    })
  }

  const handleNewPasswordBlur = () => {
    checkNewPasswordValidity()
  }

  const handleReTypeNewPasswordBlur = () => {
    checkReTypePasswordValidity()
  }

  const handleResetPassword = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    if (isEmpty(user.email) || isEmpty(user.oldPassword) || isEmpty(user.newPassword) || isEmpty(user.reTypePassword)) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, STR_FILL_ALL_FIELDS)
      return
    }

    if (
      !isEmail(user.email) ||
      !isPassword(user.newPassword) ||
      !isRetypePassword(user.newPassword, user.reTypePassword)
    )
      return

    const email = user.email.trim().toLowerCase()
    const oldPassword = user.oldPassword.trim()
    const newPassword = user.newPassword.trim()

    try {
      const response = await resetPasswordByStudentUseCase.invoke(auth, { email, oldPassword, newPassword })
      if (response?.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Password changed Successfully")
        setLoading(false)
        await logout()
        await delay(3000)
        navigate("/login")
      } else {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Please enter the correct password")
        setLoading(false)
        await delay(3000)
        navigate("/home")
      }
    } catch (e: unknown) {
      let toastMessage = "Unknown Error Occurred"

      if (e instanceof Error) {
        const { message } = e
        toastMessage = message
        if (message.includes("wrong")) {
          toastMessage = "Wrong Password"
        } else if (message.includes("too-many-requests")) {
          toastMessage = "Due to too many wrong attempts, your account is temporarily disabled. Try again after 5 mins."
        } else if (message.includes("user-not-found")) {
          toastMessage = "User Not Found"
        }
      }

      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, toastMessage)
    }
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value.trim())
  }

  async function checkUserExist(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!user.email) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please enter your email")
      return
    }
    try {
      setPasswordPageView(true)
    } catch (error) {
      return error
    }
  }

  return {
    email,
    toast,
    isResetting,
    handleEmailChange,
    checkUserExist,
    changeToastVisibility,
    auth,
    user,
    loading,
    emailValidity,
    inputRef,
    handleInputChange,
    handleResetPassword,
    changeToastDetails,
    redirectToPath: redirectPath,
    handleEmailBlur,
    navigate,
    passwordPageView,
    setPasswordPageView,
    handleReTypeNewPasswordBlur,
    handleNewPasswordBlur,
    reTypePasswordValidity,
    newPasswordValidity,
    checkNewPasswordValidity,
    checkReTypePasswordValidity,
  }
}
