import AssessmentDataSource from "data/dataSource/Student/AssessmentDataSource"
import ContestRepository from "domain/repository/Student/ContestRepository"
import { Auth } from "domain/model/Auth"

export default class ContestRepositoryImpl implements ContestRepository {
  private dataSource: AssessmentDataSource

  constructor(dataSource: AssessmentDataSource) {
    this.dataSource = dataSource
  }

  async getAllContest(auth: Auth): Promise<any> {
    return await this.dataSource.getAllContest(auth)
  }

  async isStudentAttended(auth: Auth, contestId: any): Promise<any> {
    return await this.dataSource.isStudentAttended(auth, contestId)
  }

  async contestResult(auth: Auth, contestId: any): Promise<any> {
    return await this.dataSource.contestResult(auth, contestId)
  }
  async getAllExams(auth: Auth, page: number, limit: number): Promise<any> {
    return await this.dataSource.getAllExams(auth, page, limit)
  }
  async getExamResult(auth: Auth, contest_id: string): Promise<any> {
    return await this.dataSource.getExamResult(auth, contest_id)
  }
  async getUpcomingExams(auth: Auth, page: number, limit: number): Promise<any> {
    return await this.dataSource.getUpcomingExams(auth, page, limit)
  }
}
