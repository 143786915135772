import Input from "core/components/Input"
import Loader from "core/components/Loader"
import { Textarea } from "core/components/v2/Textarea"
import { CrossIcon, StarFilled } from "core/constants/svgs"
import { cn } from "core/lib/utils"

import React, { useState } from "react"

interface FeedbackFormProps {
  questionName: string
  onClose: () => void
  isLoading: Boolean
  onSubmit: (questionId: string, student_rating: string, student_feedback: string, expected_marks: string) => void
}

const StudentFeedback = ({ questionName, onClose, isLoading, onSubmit }: FeedbackFormProps) => {
  const [rating, setRating] = useState<number>(0)
  const [message, setMessage] = useState<string>("")
  const [expectedMarks, setExpectedMarks] = useState<string>("")

  const handleRatingChange = (newRating: number) => {
    setRating(newRating)
  }

  const handleSubmit = async () => {
    await onSubmit(questionName, rating.toString(), message, expectedMarks)
    onClose()
  }

  function formatDate(timestamp: Date): string {
    const date = new Date(timestamp)

    const day = date.getDate()
    const year = date.getFullYear()
    const month = date.toLocaleString("default", { month: "long" })
    const getOrdinalSuffix = (day: number) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`

    return `${dayWithSuffix} ${month}, ${year}`
  }

  if (isLoading) {
    return (
      <div className="h-[200px] w-full grid place-items-center">
        <Loader small />
      </div>
    )
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[452px]">
        <div className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-[#02239a] text-base font-semibold font-['Inter']">Assignment Feedback</h3>
              <p className="text-[#636363] text-sm font-medium font-['Inter']">{formatDate(new Date())}</p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <CrossIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-4 flex flex-col gap-[11px]">
            <div className="text-[#333232] text-sm font-semibold">Rate AI Feedback</div>

            {/* Rating Stars */}
            <div className="mt-[5px] mb-[4px] h-4 flex items-center space-x-1">
              {[1, 2, 3, 4, 5].map((star) => (
                <StarFilled
                  key={star}
                  onClick={() => handleRatingChange(star)}
                  className={cn("h-5", star <= rating ? "text-[#FBBF24]" : "text-[#D3D3D3]")}
                />
              ))}
            </div>

            {/* Feedback Message */}
            <textarea
              className="w-full h-40 p-3 bg-white rounded border border-[#dedede] text-sm focus:outline-none focus:ring-0 focus:border-[#d3d3d3] text-[#636363] resize-none overflow-auto"
              placeholder="Type your message here!"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            {/* Expected Marks */}
            <div className="flex flex-col gap-1">
              <label className="text-[#333232] text-sm font-semibold">How much marks do you expect?</label>
              <input
                type="text"
                className="w-[300px] px-4 py-2 bg-white rounded border border-[#dedede] text-sm text-[#636363] focus:outline-1  focus:ring-0 focus:border-[#d3d3d3]"
                placeholder="Type Here"
                value={expectedMarks}
                onChange={(e) => setExpectedMarks(e.target.value)}
              />
            </div>
          </div>

          {/* Footer */}
        </div>
        <div className="mb-0 pb-6 w-full h-[71px] rounded-b-lg flex items-center justify-end gap-4 px-6 py-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-white rounded border border-[#d3d3d3] text-[#333232] text-sm font-medium"
          >
            Cancel
          </button>
          <button onClick={handleSubmit} className="px-4 py-2 bg-[#001664] rounded text-white text-sm font-medium">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  )
}

export default StudentFeedback
