import { Auth } from "domain/model/Auth"
import { updateRoleAdminDetails } from "domain/model/SuperAdminDashboard"
import { SuperAdminDashboardRepository } from "domain/repository/SuperAdmin/SuperAdminDashboardRepository"

export interface updateRoleAdminUseCase {
  invoke(details: updateRoleAdminDetails, auth: Auth): Promise<any>
}
export class updateRoleAdmin implements updateRoleAdminUseCase {
  private repository: SuperAdminDashboardRepository

  constructor(repository: SuperAdminDashboardRepository) {
    this.repository = repository
  }

  async invoke(details: updateRoleAdminDetails, auth: Auth): Promise<any> {
    return await this.repository.updateRoleAdmin(details, auth)
  }
}
