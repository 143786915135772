import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useEffect, useState } from "react"
import SessionsCard from "./components/SessionsCard"
import GenerateInvoiceViewModel from "./GenerateMentorInvoiceViewModel"
import { isEmpty } from "core/utils/misc"
import { useParams } from "react-router-dom"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import { STR_SUCCESS, STR_FAILURE } from "core/constants/strings"
import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import Modal from "core/components/Modal"
import ButtonGroup from "core/components/ButtonGroup"
import GroupMentorSessionCard from "./components/GroupMentorSessionCard"
import CreateGroupSessionForm from "./components/CreateGroupSessionForm"
import CreateMentorSessionForm from "./components/CreateMentorSessionForm"
import Model from "core/components/Model"
import GenerateMentorInvoiceViewModel from "./GenerateMentorInvoiceViewModel"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { setISODay, setMonth } from "date-fns"
import axios from "axios"
import GoalSettingSessionCard from "core/components/GoalSettingSessionCard"
import CreateGoalSettingForm from "./components/CreateGoalSettingForm"
import Spinner from "core/components/Spinner"
import getMentorGoalSettingSession from "domain/useCase/Mentor/Session/GetMentorGoalSettingSession"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"

export default function MonthSessionsView() {
  const {
    auth,
    allSessionMonthYear,
    batch,
    getBatchList,
    confirmInvoice,
    groupSessionMonthYear,
    getBatches,
    setBatch,
    confirmInvoiceOfMentor,
    fetchInvoiceOfMentor,
    fetchGroupMentorSessionMonthyear,
    fetchMentorSessionMonthyear,
    setAllSessionMonthYear,
    invoiceAmountLoading,
    setInvoiceAmountLoading,
  } = GenerateInvoiceViewModel()

  const { fetchUpdatedMentorDetails } = GenerateMentorInvoiceViewModel()
  const { id, yearMonth } = useParams()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [loading, setLoading] = React.useState<boolean>(false)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const [apiData, setData] = React.useState<any>()
  const [reloadData, setReloadData] = React.useState(false)
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [confirmModal, setConfirmModal] = React.useState<boolean>(false)
  const [isCreateGroupSessionOpen, setIsCreateGroupSessionOpen] = React.useState<boolean>(false)
  const [isCreateMentorSessionOpen, setIsCreateMentorSessionOpen] = React.useState<boolean>(false)
  const [isCreateGoalSettingSessionOpen, setIsCreateGoalSettingSessionOpen] = React.useState<boolean>(false)
  const yearMonthString = yearMonth as string
  const year = yearMonthString.slice(0, 4)
  const month = yearMonthString.slice(4)

  const getMentorGoalSettingSessionUseCase = new getMentorGoalSettingSession(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )

  function handleSearchByBatch(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }
  async function handleConfirmModal() {
    setConfirmModal(!confirmModal)
    await confirmInvoiceOfMentor(auth, id as string, yearMonth as string)
  }
  async function changeActiveButtonType(index: number) {
    const i = index
    setActiveButtonType(index)
  }

  function handleCreateGroupSession() {
    setIsCreateGroupSessionOpen(!isCreateGroupSessionOpen)
  }

  function handleCreateMentorSession() {
    setIsCreateMentorSessionOpen(!isCreateMentorSessionOpen)
  }

  function handleCreateGoalSettingSession() {
    setIsCreateGoalSettingSessionOpen(!isCreateGoalSettingSessionOpen)
  }

  async function handleBatchSearch() {
    setLoading(true)
    await fetchMentorSessionMonthyear(auth, id as string, batch, year, months[parseInt(month, 10) - 1])
    setLoading(false)
  }
  async function previousData() {
    const response = await fetchUpdatedMentorDetails(auth, id as string, yearMonth as string)
    setData(response)
  }

  async function handleInvoiceSubmit() {
    setInvoiceAmountLoading(true)
    const value = await fetchInvoiceOfMentor(auth, id as string, yearMonth as string)
    setInvoiceAmountLoading(false)
    setConfirmModal(false)

    if (value === "Invoice Not generated") {
      changeToastDetails(STR_FAILURE, "Invoice cannot generated for the current month.")
    } else {
      changeToastDetails(STR_SUCCESS, `Invoice Generated Successfully`)
    }
    changeToastVisibility(true)
  }

  const [monthName, setMonthName] = React.useState<string>("")

  React.useEffect(() => {
    setMonthName(months[parseInt(month, 10) - 1])
    fetchMentorSessionMonthyear(auth, id as string, batch, year, months[parseInt(month, 10) - 1])
    fetchGroupMentorSessionMonthyear(auth, id as string, batch, year, months[parseInt(month, 10) - 1])
    previousData()
    getBatches()
  }, [id, yearMonth, reloadData])

  const fetchMentorGoalSettingSessions = async (mentor_id: string = "", month: number) => {
    const response = await getMentorGoalSettingSessionUseCase.invoke(auth, mentor_id, month)
    return response
  }
  const [statusCounts, setStatusCounts] = React.useState({
    scheduled: 0,
    completed: 0,
    canceledByMentor: 0,
    canceledByMentee: 0,
    takenElsewhere: 0,
    mentorNotJoined: 0,
    menteeNotJoined: 0,
    goalSettingSessions: 0,
    totalGroupSessions: 0,
    completedGoalSetting: 0,
  })
  const [goalSettingSessions, setGoalSettingSessions] = useState([])
  const fetchGoalSettingSessions = async () => {
    try {
      const month = yearMonth?.slice(4, 6)
      const response = await fetchMentorGoalSettingSessions(id, parseInt(month ?? "0", 10))

      console.log(response, "tickdong")

      const data = response.data
      setGoalSettingSessions(data)
      console.log(data, goalSettingSessions, "pk")
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchGoalSettingSessions()
  }, [])

  React.useEffect(() => {
    if (allSessionMonthYear?.mentor_sessions || allSessionMonthYear?.group_mentor_sessions) {
      let counts = {
        scheduled: 0,
        completed: 0,
        canceledByMentor: 0,
        canceledByMentee: 0,
        takenElsewhere: 0,
        mentorNotJoined: 0,
        menteeNotJoined: 0,
        goalSettingSessions: 0,
        totalGroupSessions: 0,
        completedGoalSetting: 0,
      }

      allSessionMonthYear?.mentor_sessions?.forEach((session: any) => {
        switch (session.status) {
          case "Completed":
          case "Taken":
          case "Session Taken":
            counts.completed += 1
            counts.scheduled += 1
            break
          case "Cancelled by Mentor":
            counts.canceledByMentor += 1
            counts.scheduled += 1
            break
          case "Cancelled by Mentee":
            counts.canceledByMentee += 1
            counts.scheduled += 1
            break
          case "Taken Elsewhere":
            counts.takenElsewhere += 1
            counts.scheduled += 1
            break
          case "Mentor Not Joined":
          case "Mentor Absent":
            counts.mentorNotJoined += 1
            counts.scheduled += 1
            break
          case "Mentee Not Joined":
          case "Mentee Absent":
            counts.menteeNotJoined += 1
            counts.scheduled += 1
            break
          default:
            break
        }
      })

      groupSessionMonthYear?.group_mentor_sessions?.forEach((session: any) => {
        counts.totalGroupSessions += 1
      })

      goalSettingSessions.forEach((session: any) => {
        if (session.session_status === "Completed") {
          counts.completedGoalSetting += 1
        }
      })

      counts = { ...counts, goalSettingSessions: goalSettingSessions.length }
      setStatusCounts(counts)
    }
  }, [allSessionMonthYear, groupSessionMonthYear])

  return (
    <DashboardLayout>
      <div className="flex flex-col gap-y-6">
        <div className="px-6">
          <h1>{`${monthName} ${(yearMonth as string).slice(0, 4)}  All Sessions`}</h1>
        </div>
        <div className="flex px-2 w-[500px]">
          <StandaredSelect
            required
            id="Batch"
            className="mx-4"
            options={getBatchList}
            placeholder="Select Batch"
            onChange={handleSearchByBatch}
            value={batch}
          />
          <Button loading={loading} className="h-[37px] w-fit" onClick={handleBatchSearch}>
            Search
          </Button>
        </div>
        <div className="mt-3 px-5">
          <ButtonGroup
            buttons={["Mentor Sessions", "Group Mentor Sessions", "Goal Setting Session"]}
            active={activeButtonType}
            onChange={changeActiveButtonType}
          />
        </div>

        {activeButtonType === 0 ? (
          isEmpty(allSessionMonthYear) ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mx-4 my-4 flex flex-col space-y-4">
              <div className="space-y-4">
                <Button className="h-[37px] w-fit" onClick={handleCreateMentorSession}>
                  Add Mentor Session
                </Button>
                {!isEmpty(allSessionMonthYear?.mentor_sessions) ? (
                  allSessionMonthYear?.mentor_sessions?.map((data: any, i: number) => (
                    <SessionsCard
                      key={i}
                      index={i}
                      data={data}
                      reloadData={reloadData}
                      setReloadData={setReloadData}
                      changeToastDetails={changeToastDetails}
                      changeToastVisibility={changeToastVisibility}
                      onChange={setAllSessionMonthYear}
                      sessions={allSessionMonthYear}
                    />
                  ))
                ) : (
                  <div>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
            </div>
          )
        ) : activeButtonType === 1 ? (
          isEmpty(groupSessionMonthYear) ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mx-4 my-4 flex flex-col space-y-4">
              <div className="space-y-4">
                <Button className="h-[37px] w-fit" onClick={handleCreateGroupSession}>
                  Add Group Session
                </Button>
                {!isEmpty(groupSessionMonthYear?.group_mentor_sessions) ? (
                  groupSessionMonthYear?.group_mentor_sessions?.map((data: any, i: number) => (
                    <GroupMentorSessionCard
                      reloadData={reloadData}
                      setReloadData={setReloadData}
                      changeToastDetails={changeToastDetails}
                      changeToastVisibility={changeToastVisibility}
                      key={i}
                      data={data}
                    />
                  ))
                ) : (
                  <div>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <div className="mx-4 my-4 flex flex-col space-y-4">
            <div className="space-y-4">
              <Button className="h-[37px] w-fit" onClick={handleCreateGoalSettingSession}>
                Add Goal Setting Session
              </Button>
              <GoalSettingSessionCard
                goalSettingSessions={goalSettingSessions}
                reloadData={reloadData}
                setReloadData={setReloadData}
                changeToastDetails={changeToastDetails}
                changeToastVisibility={changeToastVisibility}
              />
            </div>
          </div>
        )}

        <div className="justify-end">
          <div className="max flex w-full py-2 justify-center items-center">
            <Button className="max w-[214px]" onClick={handleConfirmModal}>
              Generate Invoice
            </Button>
          </div>
        </div>
      </div>
      <Modal open={confirmModal} onClose={handleConfirmModal}>
        <div className="space-y-2">
          <h3 className="text-3xl font-semibold text-new-accent">Generate Invoice</h3>
          <p className="text-gray-600 text-lg">
            Confirm invoice generation for the month of{" "}
            <span className="text-[16px] font-bold text-new-solid-black">{monthName}?</span>
          </p>
        </div>

        <div className="space-y-4 mt-4 p-4 rounded-lg bg-gray-50 shadow-inner">
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-new-solid-black">Total Mentor Sessions:</span>
            <span className="text-xl font-bold text-green-400">{statusCounts.scheduled}</span>
          </div>
          <div className="ml-4 border-l-2 border-dotted border-new-accent pl-4 space-y-3">
            <p>
              Completed Mentor Sessions: <span className="font-semibold">{statusCounts.completed}</span>
            </p>
            <p>
              Canceled by Mentor: <span className="font-semibold">{statusCounts.canceledByMentor}</span>
            </p>
            <p>
              Canceled by Mentee: <span className="font-semibold">{statusCounts.canceledByMentee}</span>
            </p>
            <p>
              Taken Elsewhere: <span className="font-semibold">{statusCounts.takenElsewhere}</span>
            </p>
            <p>
              Mentor Not Joined: <span className="font-semibold">{statusCounts.mentorNotJoined}</span>
            </p>
            <p>
              Mentee Not Joined: <span className="font-semibold">{statusCounts.menteeNotJoined}</span>
            </p>
          </div>
        </div>

        <div className="space-y-8 mt-4 p-4 rounded-lg bg-blue-50 shadow-inner">
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-new-solid-black">Total Group Mentor Sessions:</span>
            <span className="text-xl font-bold text-blue-500">{statusCounts.totalGroupSessions}</span>
            <div className="ml-4 border-l-2 border-dotted border-new-accent pl-4 space-y-3">
              <p>All Group Mentor Sessions are Completed</p>
            </div>
          </div>
        </div>

        <div className="space-y-4 mt-4 p-4 rounded-lg bg-purple-50 shadow-inner">
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-new-solid-black">Total Goal Setting Sessions:</span>
            <span className="text-xl font-bold text-purple-500">{statusCounts.goalSettingSessions}</span>
          </div>
          <div className="ml-4 border-l-2 border-dotted border-new-accent pl-4 space-y-3">
            <p>
              Completed Goal Setting Sessions:{" "}
              <span className="font-semibold">{statusCounts.completedGoalSetting}</span>
            </p>
          </div>
        </div>

        <div className="text-right mt-6 text-xl font-semibold">
          Total Amount: Rs{" "}
          <span className="text-2xl text-green-400 font-bold">{confirmInvoice?.total_amount || 0}</span>
        </div>

        <div className="flex items-center gap-4 mt-8 self-end">
          <Button outlined failure onClick={handleConfirmModal}>
            No
          </Button>
          <Button outlined onClick={handleInvoiceSubmit}>
            {invoiceAmountLoading ? <Spinner xs /> : "Yes"}
          </Button>
        </div>
      </Modal>
      <Model isOpen={isCreateGroupSessionOpen} onClose={handleCreateGroupSession}>
        <CreateGroupSessionForm reloadData={reloadData} setReloadData={setReloadData} />
      </Model>
      <Model isOpen={isCreateMentorSessionOpen} onClose={handleCreateMentorSession}>
        <CreateMentorSessionForm reloadData={reloadData} setReloadData={setReloadData} />
      </Model>
      <Model isOpen={isCreateGoalSettingSessionOpen} onClose={handleCreateGoalSettingSession}>
        <CreateGoalSettingForm reloadData={reloadData} setReloadData={setReloadData} />
      </Model>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
