import {
  ADD_NEW_PLACEMENT,
  API_V4_ADMIN,
  STR_DELETE_NEW_PLACEMENT,
  STR_GET_ALL_NEW_COMPANIES,
  STR_GET_NEW_PLACEMENT,
  STR_GET_STUDENT_PLACEMENT,
  UPLOAD_COMPANY_LOGO,
  UPLOAD_STUDENT_PICTURE,
} from "core/constants/strings"
import { postAPI, postAPIForFiles } from "core/utils/axios"
import pn from "core/utils/pn"
import { AddPlacementDataSource } from "data/dataSource/Admin/AddPlacementDataSource"
import { Auth } from "domain/model/Auth"
import {
  companiesList,
  companyDetails,
  newCompanyDetials,
  placementDetail,
  studentDetails,
} from "domain/model/Placement"

export class AddPlacementAPIDataSourceImpl implements AddPlacementDataSource {
  async getCompaniesList(request: companiesList): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_ALL_NEW_COMPANIES), request)
    return response
  }
  async uploadCompanyLogo(request: companyDetails): Promise<any> {
    const response = await postAPIForFiles(pn(API_V4_ADMIN, UPLOAD_COMPANY_LOGO), request)
    return response
  }
  async getNewPlacement(auth: Auth): Promise<any> {
    const response = await postAPI(STR_GET_NEW_PLACEMENT, {
      id_token: auth.id_token,
    })
    return response
  }
  async deletePlacement(request: placementDetail): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_DELETE_NEW_PLACEMENT), request)
    return response
  }
  async getStudentPlacement(request: placementDetail): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, STR_GET_STUDENT_PLACEMENT), request)
    return response
  }
  async uploadStudentPicture(request: studentDetails): Promise<any> {
    const response = await postAPIForFiles(pn(API_V4_ADMIN, UPLOAD_STUDENT_PICTURE), request)
    return response
  }
  async addNewPlacement(request: newCompanyDetials): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, ADD_NEW_PLACEMENT), request)
    return response
  }
}
