import useToast from "core/hooks/useToast"
import { useState } from "react"
import { useAuth } from "core/context/auth"
import { useNavigate } from "react-router-dom"
import { STR_ACCEPTED, STR_FAILURE } from "core/constants/strings"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import GetSubCaseQuestionDetails from "domain/useCase/User/Contest/GetSubCaseQuestionDetails"
import SubmissionAPIDataSourceImpl from "data/API/Student/SubmissionAPIDataSourceImpl"
import { SubmissionRepositoryImpl } from "data/repository/Student/SubmissionRepositoryImpl"
import SubmitAssignmentQuestion from "domain/useCase/Student/Assignment/SubmitAssignmentQuestion"
import GetSubmission from "domain/useCase/Student/Assignment/GetSubmission"
import GetAIFeedback from "domain/useCase/Student/Assignment/GetAIFeedback"
import StudentAIFeedback from "domain/useCase/Student/Assignment/StudentAIFeedback"
import SubmitSubjectiveQuestion from "domain/useCase/Student/Assignment/SubmitSubjectiveQuestion"

export default function ViewModel() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [question, setQuestion] = useState<any>({})
  const [solution, setSolution] = useState<any>("")
  const TABS: string[] = ["Question", "Solution"]
  const [activeTab, setActiveTab] = useState<number>(0)
  const [viewSolution, setViewSolution] = useState<boolean>(false)
  const [isSolutionViewed, setIsSolutionViewed] = useState<boolean>(false)

  const getSubCaseQuestionDetailsUseCase = new GetSubCaseQuestionDetails(new ContestAPIDataSourceImpl())

  const submitSubjectiveQuestionUseCase = new SubmitSubjectiveQuestion(
    new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl())
  )

  const getAIFeedbackUseCase = new GetAIFeedback(new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl()))

  const studentAIFeedbackUseCase = new StudentAIFeedback(
    new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl())
  )

  const getSubmissionUseCase = new GetSubmission(new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl()))

  async function fetchQuestion(id: string) {
    setPageLoading(true)
    const response = await getSubCaseQuestionDetailsUseCase.invoke(auth, id)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Failed to load the question")
      changeToastVisibility(true)
      return
    }

    setQuestion(response?.data)
    setPageLoading(false)
  }

  async function fetchSubmission(id: string) {
    setPageLoading(true)
    const response = await getSubmissionUseCase.invoke(auth, { question_id: id })
    setPageLoading(false)

    if (!response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Failed to load the submission")
    } else {
      const answer = response?.data?.answer != -1 ? response?.data?.answer : ""
      const isSolutionViewed = response?.data?.isSolutionViewed || false
      setSolution(answer)
      setViewSolution(isSolutionViewed)
      setIsSolutionViewed(isSolutionViewed)
    }
  }

  function confirmViewSoluton() {
    setActiveTab(1)
    setViewSolution(false)
    setIsSolutionViewed(true)
  }

  function denyViewSolution() {
    setActiveTab(0)
    setViewSolution(false)
  }

  function handelTabChange(index: number) {
    if (index === 0) {
      setActiveTab(0)
    } else {
      if (isSolutionViewed) {
        setActiveTab(1)
      } else {
        setViewSolution(true)
      }
    }
  }

  function handleSolutionChange(e: any) {
    const value = e.target.value
    setSolution(value)
  }

  async function handleSolutionSubmit(id: string) {
    if (!solution) {
      changeToastDetails(STR_FAILURE, "Please write the solution")
      changeToastVisibility(true)
      return
    }
    setLoading(true)
    const response = await submitSubjectiveQuestionUseCase.invoke(auth, {
      question_id: id,
      question_type: "subjective",
      is_solution_viewed: isSolutionViewed,
      answer: solution,
    })

    if (!response.success) {
      const err = response?.details?.error_name ?? response?.error ?? "Error in submitting the question"
      changeToastDetails(STR_FAILURE, err)
      changeToastVisibility(true)
      setLoading(false)
      return
    }

    changeToastDetails(STR_ACCEPTED, "Question submitted")
    changeToastVisibility(true)

    setLoading(false)
    setTimeout(() => {
      navigate(-1)
    }, 2000)
  }

  return {
    toast,
    changeToastVisibility,
    pageLoading,
    loading,
    question,
    fetchQuestion,
    TABS,
    activeTab,
    setActiveTab,
    viewSolution,
    setViewSolution,
    isSolutionViewed,
    setIsSolutionViewed,
    confirmViewSoluton,
    denyViewSolution,
    handelTabChange,
    solution,
    handleSolutionChange,
    handleSolutionSubmit,
    fetchSubmission,
  }
}
