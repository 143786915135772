import { Auth } from "domain/model/Auth"
import { FeedbackRepository } from "domain/repository/Student/NewFeedbackRepository"

export interface GetLastFeedbackUseCase {
  invoke(auth: Auth, page: string): Promise<string>
}

export class GetLastFeedback implements GetLastFeedbackUseCase {
  private readonly feedbackRepository: FeedbackRepository

  constructor(feedbackRepository: FeedbackRepository) {
    this.feedbackRepository = feedbackRepository
  }

  async invoke(auth: Auth, page: string): Promise<string> {
    const feedbackData = await this.feedbackRepository.getLastFeedback(auth, page)

    if (!feedbackData || !feedbackData.last_feedback_time) {
      return ""
    }

    return feedbackData.last_feedback_time
  }
}
