import { DatePickerWithProps } from "core/components/v2/ui/DatePickerWithProps"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import BriefProgressDataView from "./BriefProgressDataView"

const BriefProgressView = ({
  briefProgressHandler,
  fromDate,
  toDate,
  progress,
  detailedReportHandler,
  setToDate,
  setFromDate,
  fromDateMs,
  toDateMs,
}: {
  briefProgressHandler: any
  fromDate: string | undefined
  toDate: string | undefined
  progress: any
  detailedReportHandler: any
  setToDate: any
  setFromDate: any
  fromDateMs: number | null
  toDateMs: number | null
}) => {
  return (
    <DashboardLayoutv2>
      <div className="px-[24px] py-[16px] ">
        <p className="text-[#333] text-[18px] font-[600]">Student Progress Report</p>
        <p className="text-[#737373] text-[14px] font-[400] mt-[2px] mb-6">You can track your progress here.</p>
        <div className="flex gap-[16px]">
          <DatePickerWithProps
            placeholder="From Date"
            className="w-[223px] border-[#D3D3D3] rounded-[4px] focus:bg-transparent focus:text-inherit"
            date={fromDate}
            setDate={setFromDate}
          />
          <DatePickerWithProps
            placeholder="To Date"
            className="w-[223px] border-[#D3D3D3] rounded-[4px] focus:bg-transparent focus:text-inherit"
            date={toDate}
            setDate={setToDate}
          />
          <button
            className="px-4  rounded border border-[#d3d3d3] text-[#636363] font-medium"
            onClick={briefProgressHandler}
          >
            Search
          </button>
        </div>
        {progress ? (
          <BriefProgressDataView
            fromDate={fromDate}
            toDate={toDate}
            progress={progress}
            detailedReportHandler={detailedReportHandler}
          />
        ) : (
          <div className="py-[16px] px-[19px] h-[40vh] rounded-[8px] border-[0.5px] border-[#D3D3D3] my-[24px] flex justify-center items-center">
            <div className="w-[236px] text-center text-[#636363] text-sm font-normal font-['Inter']">
              Please select date range first!
            </div>
          </div>
        )}
      </div>
    </DashboardLayoutv2>
  )
}

export default BriefProgressView
