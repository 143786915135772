import React from "react"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { FeatureFeedbackRepositoryImpl } from "data/repository/Admin/FeatureFeedbackRepositoryImpls"
import { FeatureFeedbackAPIDataSourceImpl } from "data/API/Admin/FeatureFeedbackAPIDataSourceImpls"
import { GetAllFeedback } from "domain/useCase/Admin/FeatureFeedback/GetAllFeedback"
import Papa from "papaparse"
import { saveAs } from "file-saver"

export default function FeatureFeedbackViewModel() {
  const [batch, setBatch] = React.useState<any>([])
  const [studentBatch, setStudentBatch] = React.useState("")
  const [featureFeedback, setFeatureFeedback] = React.useState<any[]>([])
  const [allFeatureFeedback, setAllFeatureFeedback] = React.useState<any[]>([])
  const [first100, setfirst100] = React.useState<any>([])
  const [firstFiltered100, setfirstFiltered100] = React.useState<any>([])
  const [average, setAverage] = React.useState<number>(0)
  const [median, setMedian] = React.useState<number>(0)
  const [searchByEmail, setSearchByEmail] = React.useState("")
  const [minRating, setMinRating] = React.useState(1)
  const [maxRating, setMaxRating] = React.useState(5)
  const [fromDate, setFromDate] = React.useState<any>(null)
  const [toDate, setToDate] = React.useState<any>(null)
  const { auth } = useAuth()
  const [selectedPageTab, setSelectedPageTab] = React.useState("Curriculum")
  const [filteredData, setFilteredData] = React.useState<any[]>([])
  const [promoters, setPromoters] = React.useState(0)
  const [detractors, setDetractors] = React.useState(0)
  const [totalresponses, setTotalresponses] = React.useState(0)
  const [npsScore, setNpsScore] = React.useState(0)

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const GetAllFeedbackUseCase = new GetAllFeedback(
    new FeatureFeedbackRepositoryImpl(new FeatureFeedbackAPIDataSourceImpl())
  )
  const pageNameMapping = {
    Curriculum: "Curriculum/Classes",
    Mentorship: "Mentor",
    Exam: "Exam",
    "Doubt Support": "Doubt Support",
    Placement: "Placement",
  }
  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)
    if (response.success) {
      setBatch(response.data)
    }
  }

  function handleBatchChange(e: any) {
    if (e.target.value === "Select Batch") {
      setStudentBatch("")
    } else setStudentBatch(e.target.value)
  }

  function handleChange(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }

  async function fetchAllFeedback() {
    try {
      const response = await GetAllFeedbackUseCase.invoke(auth)

      if (!response || response.length === 0) {
        console.error("No feedback data found or response is empty.")
        return
      }

      const feedbackList = response
      setFeatureFeedback(feedbackList)
      setAllFeatureFeedback(feedbackList)
      setfirst100(feedbackList)
      setfirstFiltered100(feedbackList.slice(0, 100))
      setAverage(response?.data?.average_rating || 0)
      setMedian(response?.data?.median_rating || 0)
    } catch (error) {
      console.error("Error fetching feedback:", error)
    }
  }

  const [curIdx, setCurIdx] = React.useState(100)
  function appendNext100() {
    if (searchByEmail || studentBatch || (toDate && fromDate) || (minRating > 0 && maxRating > 0)) {
      const next100: any = first100.slice(curIdx, curIdx + 100)
      setfirstFiltered100([...firstFiltered100, ...next100])

      setCurIdx(curIdx + 100)
    } else {
      const next100: any = allFeatureFeedback.slice(curIdx, curIdx + 100)
      setfirstFiltered100([...firstFiltered100, ...next100])

      setCurIdx(curIdx + 100)
    }
  }

  function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const dateValue = e.target.value
    const timestamp = dateValue ? new Date(dateValue).getTime() / 1000 : null
    if (e.target.id === "fromDate") {
      setFromDate(timestamp)
    } else if (e.target.id === "toDate") {
      setToDate(timestamp)
    }
  }

  React.useEffect(() => {
    let temp: any = []

    temp = allFeatureFeedback.map((student: any) => ({
      ...student,
      pages: { ...student.pages },
    }))

    temp = temp.filter((student: any) => {
      let check: boolean = true

      check =
        check &&
        (!searchByEmail || student.email === searchByEmail) &&
        (!studentBatch || student.batch === studentBatch)

      const pages = Object.keys(student.pages)
      pages.forEach((page) => {
        if (student.pages[page]) {
          student.pages[page] = student.pages[page].filter((pageFeedback: any) => {
            if (fromDate && toDate) {
              const tfromDate = new Date(fromDate * 1000)
              const ttoDate = new Date(toDate * 1000)
              const feedbackDate = new Date(pageFeedback.last_feedback_time)

              return feedbackDate >= tfromDate && feedbackDate <= ttoDate
            }
            return true
          })

          if (minRating || maxRating) {
            student.pages[page] = student.pages[page].filter((pageFeedback: any) => {
              const feedbackRating = parseInt(pageFeedback.feedback, 10)
              return feedbackRating >= minRating && feedbackRating <= maxRating
            })
          }
        }
      })

      const hasValidFeedback = pages.some((page) => student.pages[page] && student.pages[page].length > 0)

      return check && hasValidFeedback
    })

    if (searchByEmail || studentBatch || (toDate && fromDate) || selectedPageTab || minRating || maxRating) {
      setfirst100(temp)
      setfirstFiltered100(temp.slice(0, 100))
      setCurIdx(100)

      const pageName = pageNameMapping[selectedPageTab as keyof typeof pageNameMapping]
      const values = temp.flatMap((student: any) =>
        Object.keys(student.pages)
          .filter((page: string) => page === pageName)
          .flatMap((page: string) =>
            student.pages[page].map((pageFeedback: any) => parseInt(pageFeedback.feedback, 10))
          )
      )

      const totalResponses = values.length
      setTotalresponses(totalResponses)

      const promoters = values.filter((value: any) => value > 3).length
      setPromoters(promoters)

      const detractors = values.filter((value: any) => value <= 3).length
      setDetractors(detractors)

      const npsScore = totalResponses > 0 ? ((promoters - detractors) / totalResponses) * 100 : 0
      setNpsScore(npsScore)
    } else {
      setfirst100(allFeatureFeedback)
      setfirstFiltered100(allFeatureFeedback.slice(0, 100))
      setCurIdx(100)

      setTotalresponses(0)
      setPromoters(0)
      setDetractors(0)
      setNpsScore(0)
    }
  }, [allFeatureFeedback, searchByEmail, studentBatch, fromDate, toDate, selectedPageTab, minRating, maxRating])

  const DownloadCSV = () => {
    const csvData = first100.flatMap((student: any) => {
      const feedbackForPage = student.pages[pageNameMapping[selectedPageTab as keyof typeof pageNameMapping]] || []

      return feedbackForPage.map((feedback: any) => ({
        Date: new Date(feedback.last_feedback_time).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
        Name: student.name || "N/A",
        Email: student.email || "N/A",
        Batch: student.batch || "N/A",
        Page: pageNameMapping[selectedPageTab as keyof typeof pageNameMapping] || "N/A",
        Rating: feedback.feedback || "N/A",
        suggestion: feedback.feedback_text || "N/A",
      }))
    })

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    saveAs(blob, "Feature_Feedback_Data.csv")
  }

  return {
    getBatches,
    batch,
    handleBatchChange,
    studentBatch,
    fetchAllFeedback,
    setFeatureFeedback,
    setAllFeatureFeedback,
    setfirst100,
    setfirstFiltered100,
    setAverage,
    setMedian,
    featureFeedback,
    firstFiltered100,
    handleChange,
    searchByEmail,
    setSearchByEmail,
    setMinRating,
    setMaxRating,
    minRating,
    maxRating,
    setStudentBatch,
    appendNext100,
    handleDateChange,
    fromDate,
    toDate,
    first100,
    setToDate,
    setFromDate,
    curIdx,
    setCurIdx,
    setSelectedPageTab,
    selectedPageTab,
    allFeatureFeedback,
    filteredData,
    promoters,
    detractors,
    npsScore,
    totalresponses,
    setPromoters,
    setDetractors,
    setNpsScore,
    setTotalresponses,
    DownloadCSV,
  }
}
