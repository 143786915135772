import Button from "core/components/new/Button"
import Toggle from "core/components/Toggle"
import { CopyIcon, EyeIcon } from "core/constants/svgs"
import { useState } from "react"
import CustomModel from "./CustomModel"
import AIFeedbackModal from "./AIFeedbackModal"
import StudentFeedbackModal from "./StudentFeedbackModal"
import { Link, Navigate } from "react-router-dom"
import useToast from "core/hooks/useToast"
import { STR_SUCCESS } from "core/constants/strings"
export default function ApproveTable({ tableHeaders, studentFeedbacks, loading, handleCopy }: any) {
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [questionModal, setQuestionModal] = useState(false)
  const [question, setQuestion] = useState("")
  const [answerModal, setAnswerModal] = useState(false)
  const [answer, setAnswer] = useState("")
  const [aiModal, setAIModal] = useState(false)
  const [aiFeedback, setAIFeedback] = useState({})
  const [studentFeedbackModal, setStudentFeedbackModal] = useState(false)
  const [studentFeedback, setStudentFeedback] = useState({})
  const handleQuestionModalClose = () => setQuestionModal(!questionModal)
  const handleAnswerModalClose = () => setAnswerModal(!answerModal)
  const handleAIModalClose = () => setAIModal(!aiModal)
  const handleStudentFeedbackModalClose = () => setStudentFeedbackModal(!studentFeedbackModal)

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <CustomModel isOpen={questionModal} heading={"Question"} onClose={handleQuestionModalClose}>
        <p>{question}</p>
      </CustomModel>
      <CustomModel isOpen={answerModal} heading={"Student Response"} onClose={handleAnswerModalClose}>
        <p>{answer}</p>
      </CustomModel>
      <AIFeedbackModal isOpen={aiModal} heading={"AI Feedback"} onClose={handleAIModalClose}>
        {aiFeedback}
      </AIFeedbackModal>
      <StudentFeedbackModal
        isOpen={studentFeedbackModal}
        heading={"Student Feedback"}
        onClose={handleStudentFeedbackModalClose}
        children={studentFeedback}
      />
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {!studentFeedbacks?.length && !loading && <div className="m-2">No data found</div>}
        <tbody>
          {studentFeedbacks?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4 ">
                <p>{data.student_name}</p>
                <p className="font-medium">{data.student_email}</p>
                <p> {data.student_phone}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.student_batch}</td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent flex-row">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit flex-row"
                  onClick={() =>
                    handleCopy(`https://platform.bosscoderacademy.com/subjective/${data?.question_id}`, "Question Link")
                  }
                >
                  Copy
                </Button>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setAnswer(data?.student_answer)
                    setAnswerModal(true)
                  }}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setAIFeedback({ feedback: data?.ai_feedbck, points: data?.ai_marks })
                    setAIModal(true)
                  }}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setStudentFeedback({ feedback: data?.student_ai_feedback })
                    setStudentFeedbackModal(true)
                  }}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
