import { useEffect, useState } from "react"
import useToast from "core/hooks/useToast"
import Button from "core/components/new/Button"
import Spinner from "core/components/Spinner"
import useLocalStorage from "core/hooks/useLocalStorage"
import { Link, useNavigate } from "react-router-dom"
import { GetCourses } from "domain/useCase/Student/Course/GetCourses"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"
import { GetFullCourseContent } from "domain/useCase/Student/Course/GetFullCourseContent"
import { minsDiff, toDate, toTime } from "core/utils/date"
import { MarkAttendance } from "domain/useCase/Student/Course/MarkAttendance"
import { FetchLeetCodeDetails } from "domain/useCase/Student/Course/FetchLeetCodeDetails"
import { SetLeetCodeId } from "domain/useCase/Student/Course/SetLeetCodeId"
import { ToggleRecordedComplete } from "domain/useCase/Student/Course/ToggleRecordedComplete"
import { STR_DSML_BEGINNER, STR_FAILURE, STR_SUCCESS, STR_UNKNOWN_ERROR_OCCURRED } from "core/constants/strings"
import { Tooltip } from "@mui/material"
import { CheckBlankIcon, CheckSuccessIcon, InfoIcon } from "core/constants/svgs"
import { genError } from "core/utils/string"
import { GetMasterClass } from "domain/useCase/Student/Course/GetMasterClass"
import { GetAttendanceAndAssignments } from "domain/useCase/Student/Course/GetAttendanceAndAssignments"
import { isEmpty } from "core/utils/misc"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import delay from "core/utils/delay"
import { SubmitFeedback } from "domain/useCase/Student/Course/SubmitFeedback"
import { GetSessionDetails } from "domain/useCase/Student/Course/GetSessionDetails"
import { MarkAttendanceManually } from "domain/useCase/Student/Course/MarkAttendanceManually"
import { TTableData } from "core/constants/types"
import UnlockIcon from "assets/svgs/UnlockIcon"
import LockIcon from "assets/svgs/LockIcon"
import TableButton from "core/components/v2/TableButton"
import Checkbox from "assets/svgs/v2/Checkbox"
import CheckboxSuccess from "assets/svgs/v2/checkboxSuccess"
import { GetDSMLBEGINNERMODULESESSION } from "domain/useCase/Student/Course/GetDsmlBeginnerModuleSessions"
import { ReedemCoin } from "domain/useCase/Student/Course/ReedemCoin"
import webEngageTrack from "core/utils/webEngageTrack"
import useFeatureFeedbackViewModel from "../FeatureFeedback/FeatureFeedbackViewModel"
import Loader from "core/components/Loader"

export default function CourseViewModel() {
  const { fetchLastFeedback, getJoiningDate, saveFeedback, shouldShowFeedbackAfter2Months } =
    useFeatureFeedbackViewModel()
  const { auth } = useAuth()
  const { student, setStudent } = useApp()
  const isAccelerator = student?.batch?.includes("Accelerator")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const [courseList, setCourseList] = useLocalStorage<any[]>("course", [])
  const [fullCourse, setFullCourse] = useLocalStorage<any>("full_course", {})
  const [totalLectures, setTotalLectures] = useState<any>([])
  const [coursesWithIds, setCoursesWithIds] = useLocalStorage<any>("course-ids", {})

  const [courseSelectedIndex, setCourseSelectedIndex] = useState<number>(0)
  const [currentCourse, setCurrentCourse] = useState<any>(() =>
    isEmpty(courseList) ? courseList[courseSelectedIndex] : null
  )
  const [activeButtonType, setActiveButtonType] = useState(0)
  const [isFullCourseContentActive, setIsFullCourseContentActive] = useState(false)
  const [hasLeetCodeId, setHasLeetCodeId] = useState(true)
  const [loadingTable, setLoadingTable] = useState(-1)
  const [tableData, setTableData] = useState<TTableData>({} as TTableData)
  const [fullCourseTableData, setFullCourseTableData] = useState<TTableData>({
    header: ["Class Name"],
  } as TTableData)
  const [isCourseFetched, setIsCourseFetched] = useState<boolean>(false)
  const [isAssignmentsFetched, setIsAssignmentsFetched] = useState<boolean>(false)
  const [isLeetcodeFetched, setIsLeetcodeFetched] = useState<boolean>(false)
  const [isLeetcodeChecked, setIsLeetcodeChecked] = useState<boolean>(true)
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState<boolean>(false)
  const [attendanceModalData, setAttendanceModalData] = useState<any>(null)
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)
  const [attendanceSessionLoading, setAttendanceSessionLoading] = useState<number>(-1)
  const [showProjectModule, setShowProjectModule] = useState(false)
  const [weekAttendance, setWeekAttendance] = useState<any>({})
  const [lastWeekAttendance, setLastWeekAttendance] = useState<any>({})
  const [reedemCoins, setReedemCoins] = useState<any>(0)
  const [lastCoins, setLastCoins] = useState<any>(0)
  const [reedemCoinLoading, setReedemCoinLoading] = useState<any>(false)
  const [reedemCompleteModal, setReedemCompleteModal] = useState(false)
  const { assignmentAndAttendance, setAssignmentAndAttendance } = useApp()
  const [attendanceFailure, setAttendanceFailure] = useState(false)
  const [currentClassName, setCurrentClassName] = useState("")
  const [attendanceFailureCount, setAttendanceFailureCount] = useState(() => {
    const storedCount = Number(localStorage.getItem("attendanceFailureCount")) || 0
    return storedCount < 3 ? storedCount : 0 // Reset to 0 if >= 3
  })
  const [attendanceLoader, setAttendanceLoader] = useState(false)

  const [currentClassLink, setCurrentClassLink] = useState("")
  const [waitingTime, setWaitingTime] = useState(10)
  const [currentSessionId, setCurrentSessionId] = useState<any>({})

  const navigate = useNavigate()

  const getCoursesUseCase = new GetCourses(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getFullCourseContentUseCase = new GetFullCourseContent(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const markAttendanceUseCase = new MarkAttendance(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const reedemCoinUseCase = new ReedemCoin(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const fetchLeetCodeDetailsUseCase = new FetchLeetCodeDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const setLeetCodeIdUseCase = new SetLeetCodeId(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const toggleRecordedCompleteStatusUseCase = new ToggleRecordedComplete(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getMasterClassUseCase = new GetMasterClass(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getAttendanceAndAssignmentsUseCase = new GetAttendanceAndAssignments(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const submitFeedbackUseCase = new SubmitFeedback(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getSessionDetailsUseCase = new GetSessionDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const markAttendanceManuallyUseCase = new MarkAttendanceManually(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getDsmlBeginnerModuleSessionsUseCase = new GetDSMLBEGINNERMODULESESSION(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  useEffect(() => {
    const newTotalLectures = Object.values(courseList).flatMap((obj: any) => obj.recorded_lectures || [])
    setTotalLectures(newTotalLectures)
  }, [courseList])

  const defaultindex = () => {
    let latest_timestamp = 1e18
    let ind = -1
    for (let i = 0; i < courseList.length; i++) {
      if (
        courseList[i]["name"] !== "Master Class" &&
        courseList[i]["name"] != "DSML Beginner" &&
        courseList[i]["upcoming_lectures"].length > 0 &&
        courseList[i]["upcoming_lectures"][0]["timestamp"] < latest_timestamp
      ) {
        ind = i
        latest_timestamp = courseList[i]["upcoming_lectures"][0]["timestamp"]
      }
    }
    if (ind === -1) {
      latest_timestamp = 0
      for (let i = 0; i < courseList.length; i++) {
        if (
          courseList[i]["name"] !== "Master Class" &&
          courseList[i]["name"] != "DSML Beginner" &&
          courseList[i]["recorded_lectures"].length > 0 &&
          latest_timestamp < courseList[i]["recorded_lectures"][0]["timestamp"]
        ) {
          ind = i
          latest_timestamp = courseList[i]["recorded_lectures"][0]["timestamp"]
        }
      }
    }

    setCourseSelectedIndex(ind === -1 ? 0 : ind)
  }

  async function fetchCourse() {
    defaultindex()

    const response = await getCoursesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch courses"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any = []
    const finalResponse = response?.data

    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
          upcoming_lectures: resData[key]?.upcoming_lectures,
          module_progress: {
            completed: resData[key]?.completed_chapters,
            total: resData[key]?.total_chapters,
          },
          assignment_progress: {
            completed: resData[key]?.solved_module_assignments,
            total: resData[key]?.total_module_assignments,
          },
        },
      }
    })

    const newCourseList = courseList
    const tempCourseList = []
    let newCoursesWithIds = {}
    const courseKeys = Object.keys(modifiedResponse)

    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "master_class" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }

    defaultindex()
    if (isAccelerator) {
      setActiveButtonType(1)
    }
    setCoursesWithIds(newCoursesWithIds)
    setCourseList([...newCourseList])

    setIsCourseFetched(true)
  }

  const fetchMasterClass = async () => {
    const response = await getMasterClassUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch master class"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any[] = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
        },
      }
    })

    const newCourseList: any[] = courseList
    const tempCourseList: any[] = []
    let newCoursesWithIds: any = coursesWithIds
    const courseKeys: any[] = Object.keys(modifiedResponse)

    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "master_class" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }
    if (
      student?.batch?.includes("DSML") &&
      student?.tracks?.includes("dsml_beginner") &&
      !newCoursesWithIds.hasOwnProperty("DSML Beginner")
    ) {
      newCoursesWithIds["DSML Beginner"] = "dsml_beginner"
    }
    setCoursesWithIds(newCoursesWithIds)
    setCourseList([...newCourseList])
  }

  const fetchAttendanceAndAssignments = async () => {
    setIsAssignmentsFetched(false)

    let data = assignmentAndAttendance
    let response = { success: false, data }

    if (data) {
      response.success = true
    } else {
      const response1 = await getAttendanceAndAssignmentsUseCase.invoke(auth)
      response.data = response1.data
      setAssignmentAndAttendance(response1.data)
      response.success = response1.success
    }

    setIsAssignmentsFetched(true)

    if (!response?.success) {
      changeToastDetails(
        STR_FAILURE,
        genError(response, genError(response, "Failed to fetch attendance and assignments"))
      )
      changeToastVisibility(true)
      return
    }

    const moduleKeys = Object.keys(response?.data)
    const newCourseList: any[] = courseList

    moduleKeys?.forEach((key: any) => {
      if (key === "assignmentSolved") {
        return
      }
      const moduleIndex = newCourseList.findIndex((item: any) => item?.id === key)
      const recordedLectures = newCourseList[moduleIndex]?.recorded_lectures
      const upcomingLectures = newCourseList[moduleIndex]?.upcoming_lectures

      const moduleData = response?.data[key]
      moduleData?.forEach((data: any) => {
        let index = -1
        if (!isEmpty(recordedLectures)) {
          index = recordedLectures.findIndex((item: any) => item?.session_id === data?.session_id)
          if (index !== -1) recordedLectures[index] = { ...data, ...recordedLectures[index] }
        }
        if (!isEmpty(upcomingLectures)) {
          index = upcomingLectures.findIndex((item: any) => item?.session_id === data?.session_id)
          if (index !== -1) upcomingLectures[index] = { ...data, ...upcomingLectures[index] }
        }
      })

      newCourseList[moduleIndex] = {
        ...newCourseList[moduleIndex],
        recorded_lectures: recordedLectures,
        upcoming_lectures: upcomingLectures,
        assignment_progress: {
          completed: response?.data[key]?.reduce((total: number, item: any) => total + item?.num_assignment_solved, 0),
          total: response?.data[key]?.reduce((total: number, item: any) => total + item?.num_total_assignments, 0),
        },
      }
    })

    setCourseList([...newCourseList])
  }

  function changeCourseSelected(course: any, index: number) {
    setCourseSelectedIndex(index)
    setCurrentCourse(course)
    if (isFullCourseContentActive) {
      setIsFullCourseContentActive(false)
      setFullCourseTableData((t) => ({ ...t, rows: [] }))
    }
    if (course?.name === "Master Class") changeActiveButtonType(0)

    setShowProjectModule(course?.id === "project_module")
  }

  function changeActiveButtonType(index: number) {
    if (activeButtonType !== index) setActiveButtonType(index)
  }

  async function handleTableClick(i: number, lecture: any) {
    setLoadingTable(i)
    await toggleRecordedComplete(lecture?.session_id, !lecture?.is_completed)
    setLoadingTable(-1)
  }

  function getWeeksUpToCurrent() {
    const lectures = totalLectures
    if (!lectures || lectures.length === 0) return []

    const minTimestamp = Math.min(...lectures.map((lecture: any) => lecture.timestamp))

    let startOfFirstWeek = new Date(minTimestamp * 1000)
    const dayOfWeek = startOfFirstWeek.getDay()
    const daysToMonday = (dayOfWeek + 6) % 7

    startOfFirstWeek.setDate(startOfFirstWeek.getDate() - daysToMonday)
    startOfFirstWeek.setHours(0, 0, 0, 0)

    const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const weeks = []
    const currentDate = new Date()

    let currentWeekStart = new Date(startOfFirstWeek)
    while (currentWeekStart <= currentDate) {
      const weekDays = []

      for (let i = 0; i < 7; i++) {
        const dayStart = new Date(currentWeekStart)
        dayStart.setDate(currentWeekStart.getDate() + i)
        dayStart.setHours(0, 0, 0, 0)
        const dayEnd = new Date(dayStart)
        dayEnd.setHours(23, 59, 59, 999)

        const date = dayStart.getDate()
        const month = dayStart.toLocaleString("default", { month: "long" })

        weekDays.push({
          startTimestamp: Math.floor(dayStart.getTime() / 1000),
          endTimestamp: Math.floor(dayEnd.getTime() / 1000),
          day: dayNames[i],
          is_class: false,
          attended: false,
          no_of_class: 0,
          date: date,
          month: month,
        })
      }

      weeks.push(weekDays)

      currentWeekStart.setDate(currentWeekStart.getDate() + 7)
    }

    weeks.pop()

    return weeks
  }

  function getLastWeekStartAndEndTimestamps() {
    const now = new Date()
    const dayOfWeek = now.getDay()

    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

    const lastWeekStart = new Date(now)
    lastWeekStart.setDate(now.getDate() - ((dayOfWeek + 6) % 7) - 7)
    lastWeekStart.setHours(0, 0, 0, 0)

    const days = []
    for (let i = 0; i < 7; i++) {
      const dayStart = new Date(lastWeekStart)
      dayStart.setDate(lastWeekStart.getDate() + i)
      dayStart.setHours(0, 0, 0, 0)
      const dayEnd = new Date(dayStart)
      dayEnd.setHours(23, 59, 59, 999)

      const date = dayStart.getDate()
      const month = dayStart.toLocaleString("default", { month: "long" })

      days.push({
        startTimestamp: Math.floor(dayStart.getTime() / 1000),
        endTimestamp: Math.floor(dayEnd.getTime() / 1000),
        day: dayNames[i],
        is_class: false,
        attended: false,
        no_of_class: 0,
        date,
        month,
      })
    }
    return days
  }

  function getWeekStartAndEndTimestamps() {
    const now = new Date()
    const dayOfWeek = now.getDay()

    const dayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

    const weekStart = new Date(now)
    weekStart.setDate(now.getDate() - ((dayOfWeek + 6) % 7))
    weekStart.setHours(0, 0, 0, 0)

    const days = []
    for (let i = 0; i < 7; i++) {
      const dayStart = new Date(weekStart)
      dayStart.setDate(weekStart.getDate() + i)
      dayStart.setHours(0, 0, 0, 0)
      const dayEnd = new Date(dayStart)
      dayEnd.setHours(23, 59, 59, 999)

      days.push({
        startTimestamp: Math.floor(dayStart.getTime() / 1000),
        endTimestamp: Math.floor(dayEnd.getTime() / 1000),
        day: dayNames[i],
        is_class: false,
        attended: false,
        no_of_class: 0,
      })
    }
    return days
  }

  function checkAllWeekAttendance() {
    const weeks = getWeeksUpToCurrent()

    const lectures = totalLectures

    let allWeekAttendence = weeks.map((weekDays) => {
      let totalWeekClass = 0
      weekDays?.forEach((day) => {
        const lecturesOfDay = lectures?.filter(
          (lecture: any) => lecture.timestamp >= day.startTimestamp && lecture.timestamp <= day.endTimestamp
        )

        day.is_class = lecturesOfDay?.length > 0
        day.no_of_class = lecturesOfDay?.length
        totalWeekClass += day.no_of_class

        day.attended = lecturesOfDay?.length > 0 && lecturesOfDay?.every((lecture: any) => lecture.is_attended === true)
      })

      const hasClasses = weekDays.some((day) => day.is_class)
      const allAttended = weekDays.filter((day) => day.is_class).every((day) => day.attended)

      const isWeekAttended = hasClasses && allAttended

      return {
        weekAttendanceStatus: isWeekAttended,
        totalWeekClass: totalWeekClass,
        weekStartTimestamp: weekDays[0].startTimestamp,
        weekEndTimestamp: weekDays[6].endTimestamp,
        dailyAttendance: weekDays.map((day) => ({
          day: day.day,
          is_class: day.is_class,
          attended: day.attended,
          no_of_class: day.no_of_class,
          date: day.date,
          month: day.month,
        })),
      }
    })

    allWeekAttendence = allWeekAttendence.reverse()

    let newReedemCoins = 0
    let totalWeekAttended = 0
    if (student?.coin_redeem_timestamp) {
      for (let i = 0; i < allWeekAttendence.length; i++) {
        if (allWeekAttendence[i].weekEndTimestamp < student?.coin_redeem_timestamp / 1000) break
        if (allWeekAttendence[i].weekAttendanceStatus) {
          totalWeekAttended += 1
          const classes = allWeekAttendence[i]?.totalWeekClass
          if (classes == 1) {
            newReedemCoins += 15
          } else if (classes == 2) {
            newReedemCoins += 25
          } else if (classes == 3) {
            newReedemCoins += 35
          } else if (classes >= 4) {
            newReedemCoins += 50
          }
        }
        if (
          allWeekAttendence[i].weekStartTimestamp < student?.coin_redeem_timestamp / 1000 &&
          allWeekAttendence[i].weekEndTimestamp > student?.coin_redeem_timestamp / 1000
        )
          break
      }
    } else {
      for (let i = 0; i < allWeekAttendence.length; i++) {
        if (allWeekAttendence[i].weekAttendanceStatus) {
          totalWeekAttended += 1
          const classes = allWeekAttendence[i]?.totalWeekClass
          if (classes == 1) {
            newReedemCoins += 15
          } else if (classes == 2) {
            newReedemCoins += 25
          } else if (classes == 3) {
            newReedemCoins += 35
          } else if (classes >= 4) {
            newReedemCoins += 50
          }
        }
      }
    }

    setReedemCoins(newReedemCoins)
  }

  function checkWeekAttendance(week: number) {
    const weekDays: any = week ? getWeekStartAndEndTimestamps() : getLastWeekStartAndEndTimestamps()

    const lectures = totalLectures

    let totalClassesInWeek = 0
    let attendedClassesInWeek = 0

    weekDays?.forEach((day: any) => {
      const lecturesOfDay = lectures?.filter(
        (lecture: any) => lecture.timestamp >= day.startTimestamp && lecture.timestamp <= day.endTimestamp
      )
      day.is_class = lecturesOfDay?.length > 0
      day.no_of_class = lecturesOfDay?.length

      day.no_of_attended = lecturesOfDay?.filter((lecture: any) => lecture.is_attended === true).length || 0

      day.attended = lecturesOfDay?.length > 0 && lecturesOfDay?.every((lecture: any) => lecture.is_attended === true)

      totalClassesInWeek += day.no_of_class
      attendedClassesInWeek += day.no_of_attended
    })

    const hasClasses = weekDays.some((day: any) => day.is_class)
    const allAttended = weekDays.filter((day: any) => day.is_class).every((day: any) => day.attended)

    const isWeekAttended = hasClasses && allAttended

    if (week) {
      setWeekAttendance({
        weekAttendanceStatus: isWeekAttended,
        dailyAttendance: weekDays.map((day: any) => ({
          day: day.day,
          is_class: day.is_class,
          attended: day.attended,
          no_of_class: day.no_of_class,
        })),
      })
    } else {
      setLastWeekAttendance({
        weekAttendanceStatus: isWeekAttended,
        weekStartTimestamp: weekDays[0].startTimestamp,
        weekEndTimestamp: weekDays[6].endTimestamp,
        total_class: totalClassesInWeek,
        attended_class: attendedClassesInWeek,
        dailyAttendance: weekDays.map((day: any) => ({
          day: day.day,
          is_class: day.is_class,
          attended: day.attended,
          no_of_class: day.no_of_class,
          date: day.date,
          month: day.month,
        })),
      })
    }

    return {
      weekAttendanceStatus: isWeekAttended,
      dailyAttendance: weekDays.map((day: any) => ({
        day: day.day,
        is_class: day.is_class,
        attended: day.attended,
        no_of_class: day.no_of_class,
      })),
    }
  }

  function handleRecordedSessionTable() {
    if (!isAccelerator) {
      const lectures = currentCourse?.recorded_lectures

      const header = [
        <CheckboxSuccess className="h-4 w-4 text-new-solid-white" />,
        "Class Name",
        "Date",
        "Status",
        "P.S",
        "Class Recording",
        "Assignments",
      ]

      const rows = lectures?.map((lecture: any, i: number) => [
        <button
          className="flex h-6 w-6 items-center rounded border-[0.5px] border-[#d3d3d3] p-1"
          onClick={() => handleTableClick(i, lecture)}
        >
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckboxSuccess className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <Checkbox className="h-4 w-4" />
          )}
        </button>,
        <Link to={lecture?.session_id}>
          <span className="block w-full max-w-[130px] truncate" title={lecture?.chapter_name}>
            {lecture?.chapter_name}
          </span>
        </Link>,
        toDate(lecture?.timestamp * 1000),
        isAssignmentsFetched ? (
          <div className="inline-flex items-center gap-2">
            {lecture?.is_attended ? (
              <span className="text-success">Present</span>
            ) : (
              <span className="text-failure">Absent</span>
            )}
          </div>
        ) : (
          <Spinner xs />
        ),
        isAssignmentsFetched ? `${lecture?.num_assignment_solved}/${lecture?.num_total_assignments}` : <Spinner xs />,
        <TableButton
          outlined
          onClick={() => {
            webEngageTrack("Recorded Video Play", lecture?.chapter_name)
            navigate(`${lecture?.session_id}?tab=0`)
          }}
        >
          Play Video
        </TableButton>,
        <TableButton
          outlined
          onClick={() => {
            webEngageTrack("Assignment Open ", lecture?.chapter_name)
            navigate(`${lecture?.session_id}?tab=1`)
          }}
        >
          Assignment
        </TableButton>,
      ])

      setTableData({ header, rows })
    } else {
      const lectures = currentCourse?.recorded_lectures

      const header = ["Class Name", "Problem Solved", "Status", "Video Link", "Assignments"]

      const rows = lectures?.map((lecture: any, i: number) => [
        lecture?.view_allowed ? (
          <Link to={lecture?.session_id}>
            <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
              {lecture?.chapter_name}
            </span>
          </Link>
        ) : (
          <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
            {lecture?.chapter_name}
          </span>
        ),
        isAssignmentsFetched ? (
          <div className="inline-flex items-center gap-1">
            {lecture?.view_allowed == false ? <LockIcon /> : <UnlockIcon />}
          </div>
        ) : (
          <Spinner xs />
        ),
        isAssignmentsFetched ? `${lecture?.num_assignment_solved}/${lecture?.num_total_assignments}` : <Spinner xs />,
        lecture?.view_allowed ? (
          <TableButton outlined onClick={() => navigate(lecture?.session_id)}>
            Play Video
          </TableButton>
        ) : (
          <Tooltip title="Complete your Full Fee Payment to Unlock this Chapter">
            <span>
              <Button
                small
                outlined
                disabled={lecture?.view_allowed == true ? false : true}
                onClick={() => navigate(lecture?.session_id)}
              >
                Play Video
              </Button>
            </span>
          </Tooltip>
        ),
        lecture?.view_allowed ? (
          <TableButton outlined onClick={() => navigate(lecture?.session_id)}>
            Assignment
          </TableButton>
        ) : (
          <Tooltip title="Complete your Full Fee Payment to Unlock this Chapter">
            <span>
              <Button
                small
                outlined
                disabled={lecture?.view_allowed == true ? false : true}
                onClick={() => navigate(lecture?.session_id)}
              >
                Assignment
              </Button>
            </span>
          </Tooltip>
        ),
      ])

      setTableData({ header, rows })
    }
  }

  const reedemCoinsHandle = async () => {
    setReedemCoinLoading(true)
    setLastCoins(reedemCoins)
    const response = await reedemCoinUseCase.invoke(auth, { coins: reedemCoins })
    setReedemCoinLoading(false)
    if (response?.data?.error) {
      changeToastDetails(STR_FAILURE, response?.data?.error)
      changeToastVisibility(true)
      return
    }
    setReedemCompleteModal(true)
    setStudent({ ...student, ...response?.data })
  }

  const handleJoinNow = async (lecture: any) => {
    setAttendanceFailure(true)
    setWaitingTime(10)

    setCurrentSessionId(lecture)
    setAttendanceLoader(true)
    const session_id = lecture?.session_id
    setCurrentClassLink(lecture?.class_link)

    if (!session_id) {
      changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
      changeToastVisibility(true)
      return
    }

    const response = await markAttendanceUseCase.invoke(auth, session_id)

    if (!response?.success) {
      // ✅ Use functional updater to ensure latest state
      setAttendanceFailureCount((prevCount) => {
        const newCount = prevCount + 1
        localStorage.setItem("attendanceFailureCount", newCount.toString()) // Keep localStorage in sync
        return newCount
      })
    } else {
      window.open(response?.data?.link)
      setAttendanceFailureCount(0) // Reset failure count on success
      localStorage.setItem("attendanceFailureCount", "0")
    }
  }

  function isLinkActive(timestamp: number) {
    const diff = minsDiff(Date.now(), timestamp)
    return diff <= 30 && diff >= -90
  }

  function handleUpcomingSessionTable() {
    const lectures = currentCourse?.upcoming_lectures
    const header = ["Class Name", "Date", "Time", "Instructor", "Level", "Link to join"]

    const rows = lectures?.map((lecture: any) => {
      const timestamp = parseInt(lecture?.timestamp) * 1000
      const isLinkDisabled = !isLinkActive(timestamp)

      return [
        <span className="bloc k w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        toDate(timestamp),
        toTime(timestamp),
        lecture?.instructor_name,
        lecture?.level,
        <Tooltip title={isLinkDisabled ? "Link will be active half an hour before the class timing." : ""}>
          <span>
            <TableButton
              outlined
              onClick={() => {
                setCurrentClassName(lecture?.chapter_name)
                webEngageTrack("Join Live Lecture", lecture?.chapter_name)
                handleJoinNow(lecture)
              }}
              disabled={isLinkDisabled}
            >
              {attendanceLoader ? <Loader /> : "Join now"}
            </TableButton>
          </span>
        </Tooltip>,
      ]
    })

    setTableData({ header, rows })
  }

  function handleMasterClassTable() {
    const lectures = currentCourse?.recorded_lectures
    const header = [
      <CheckSuccessIcon className="h-4 w-4 text-new-solid-white" />,
      "Class Name",
      "Instructor",
      "Date",
      "Level",
      "Link to video",
    ]
    if (currentCourse?.name === STR_DSML_BEGINNER) {
      const rows = lectures?.map((lecture: any, i: number) => [
        <button className="flex h-6 w-6 items-center" onClick={() => handleTableClick(i, lecture)}>
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        lecture?.instructor_name,
        toDate(lecture?.timestamp * 1000),
        lecture?.level,
        <TableButton outlined onClick={() => navigate(`${lecture?.session_id}`)}>
          View
        </TableButton>,
      ])
      setTableData({ header, rows })
    } else if (activeButtonType == 1) {
      const filtered_lectures = lectures.filter((lecture: any) => {
        return lecture.type === "Software Engineering" || lecture.type == "both"
      })
      const rows = filtered_lectures?.map((lecture: any, i: number) => [
        <button className="flex h-6 w-6 items-center" onClick={() => handleTableClick(i, lecture)}>
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        lecture?.instructor_name,
        toDate(lecture?.timestamp * 1000),
        lecture?.level,
        <TableButton outlined onClick={() => navigate(`${lecture?.session_id}`)}>
          View
        </TableButton>,
      ])
      setTableData({ header, rows })
    } else {
      const filtered_lectures = lectures.filter((lecture: any) => {
        return lecture.type === "Data Science" || lecture.type == "both"
      })
      const rows = filtered_lectures?.map((lecture: any, i: number) => [
        <button className="flex h-6 w-6 items-center" onClick={() => handleTableClick(i, lecture)}>
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        lecture?.instructor_name,
        toDate(lecture?.timestamp * 1000),
        lecture?.level,
        <TableButton outlined onClick={() => navigate(`${lecture?.session_id}`)}>
          View
        </TableButton>,
      ])
      setTableData({ header, rows })
    }
  }
  const attendanceFailureClose = () => setAttendanceFailure(!attendanceFailure)

  async function toggleRecordedComplete(session_id: string, status: boolean) {
    const response = await toggleRecordedCompleteStatusUseCase.invoke(auth, session_id, status)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Failed to update status")
      changeToastVisibility(true)
      return
    }

    const updatedCourseList = courseList.map((course: any) => ({
      ...course,
      recorded_lectures: course.recorded_lectures.map((lecture: any) =>
        lecture.session_id === session_id ? { ...lecture, is_completed: status } : lecture
      ),
    }))

    setCourseList([...updatedCourseList])

    setCurrentCourse(updatedCourseList[courseSelectedIndex])
  }

  async function showFullCourseContent() {
    const module_id = coursesWithIds[currentCourse.name]
    if (!module_id) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Failed: module_id = undefined")
      return
    }

    const response = await getFullCourseContentUseCase.invoke(auth, module_id)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      return
    }

    const newFullCourse = { ...fullCourse, [module_id]: response?.data }

    const courses = newFullCourse[module_id]
    const rows = courses?.map((topic: any) => [topic?.name])
    setFullCourseTableData((t) => ({ ...t, rows }))
  }

  async function handleLeetCodeId(leetCodeId: string) {
    setIsLeetcodeChecked(false)

    const response = await setLeetCodeIdUseCase.invoke(auth, leetCodeId)

    setIsLeetcodeChecked(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "LeetCode username saved successfully")

    setHasLeetCodeId(true)
  }

  async function checkLeetCodeProfile() {
    setIsLeetcodeFetched(false)

    const response = await fetchLeetCodeDetailsUseCase.invoke(auth)

    setIsLeetcodeFetched(true)

    if (!response?.success) {
      setHasLeetCodeId(false)
      return
    }

    const isLeetcodeVerified = response?.data?.leetcode_verified
    setHasLeetCodeId(isLeetcodeVerified)
  }

  async function submitFeedback(ratings: number[], suggestion?: string) {
    const isValid = ratings?.every((rating) => rating !== 0)

    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please rate all the questions")
      changeToastVisibility(true)
      return
    }

    const data: any = {
      session_id: attendanceModalData?.session_id,
      live_class_suggestion: suggestion,
    }
    for (let i = 0; i < ratings.length; ++i) {
      data[`${i + 1}`] = ratings[i]
    }

    const response: any = await submitFeedbackUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    await markAttendanceManually(attendanceModalData?.session_id, true)

    setFeedbackOpen(false)
  }

  const handleOpenAttendanceModal = (lecture: any) => {
    setIsAttendanceModalOpen(true)
    setAttendanceModalData(lecture)
  }

  const handleCloseAttendanceModal = () => {
    if (feedbackOpen) {
      setFeedbackOpen(false)
      return
    }
    setIsAttendanceModalOpen(false)
    setAttendanceModalData(null)
  }

  const markAttendanceManually = async (session_id: string, status: boolean) => {
    const response = await markAttendanceManuallyUseCase.invoke(auth, session_id, status)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Attendance updated successfully")
    changeToastVisibility(true)

    const recorded_lectures = currentCourse?.recorded_lectures?.map((c: any) =>
      c?.session_id === attendanceModalData?.session_id ? { ...c, is_attended: status } : c
    )
    setCurrentCourse((c: any) => ({ ...c, recorded_lectures }))

    setIsAttendanceModalOpen(false)
  }

  const handleAttendanceChange = async (status: boolean) => {
    setAttendanceSessionLoading(status ? 1 : 0)

    const response: any = await getSessionDetailsUseCase.invoke(auth, attendanceModalData?.session_id)

    setAttendanceSessionLoading(-1)

    const feedbackShowStatus = response?.module_name !== "master_class" && !response?.is_feedback_submitted

    if (feedbackShowStatus && status) {
      setFeedbackOpen(true)
    } else {
      await markAttendanceManually(attendanceModalData?.session_id, status)
    }
  }

  const fetchDsmlBeginnerModuleSessions = async () => {
    const response = await getDsmlBeginnerModuleSessionsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch master class"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any[] = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
        },
      }
    })

    const newCourseList: any[] = courseList
    const tempCourseList: any[] = []
    let newCoursesWithIds: any = coursesWithIds
    const courseKeys: any[] = Object.keys(modifiedResponse)

    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "dsml_beginner" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }
    if (student?.tracks?.includes("master_class") && !newCoursesWithIds.hasOwnProperty("Master Class")) {
      newCoursesWithIds["Master Class"] = "master_class"
    }

    setCoursesWithIds(newCoursesWithIds)
    setCourseList([...newCourseList])
  }

  return {
    toast,
    student,
    courseList,
    currentCourse,
    activeButtonType,
    tableData,
    isFullCourseContentActive,
    coursesWithIds,
    fullCourseTableData,
    hasLeetCodeId,
    courseSelectedIndex,
    loadingTable,
    isCourseFetched,
    isAssignmentsFetched,
    isLeetcodeFetched,
    isLeetcodeChecked,
    auth,
    isAttendanceModalOpen,
    attendanceModalData,
    feedbackOpen,
    attendanceSessionLoading,
    showProjectModule,
    weekAttendance,
    lastWeekAttendance,
    reedemCoins,
    lastCoins,
    reedemCoinLoading,
    totalLectures,
    reedemCompleteModal,
    checkLeetCodeProfile,
    fetchMasterClass,
    fetchAttendanceAndAssignments,
    fetchDsmlBeginnerModuleSessions,
    fetchCourse,
    changeCourseSelected,
    changeActiveButtonType,
    showFullCourseContent,
    handleLeetCodeId,
    attendanceFailureCount,
    setAttendanceFailureCount,
    changeToastVisibility,
    toggleRecordedComplete,
    handleRecordedSessionTable,
    handleUpcomingSessionTable,
    handleMasterClassTable,
    handleCloseAttendanceModal,
    handleAttendanceChange,
    submitFeedback,
    setCurrentCourse,
    attendanceFailure,
    setAttendanceFailure,
    setIsFullCourseContentActive,
    fetchLastFeedback,
    getJoiningDate,
    saveFeedback,
    shouldShowFeedbackAfter2Months,
    checkWeekAttendance,
    attendanceFailureClose,
    checkAllWeekAttendance,
    reedemCoinsHandle,
    currentClassLink,
    currentClassName,
    setReedemCompleteModal,
    waitingTime,
    setWaitingTime,
    handleJoinNow,
    attendanceLoader,
    currentSessionId,
    setAttendanceLoader,
  }
}
