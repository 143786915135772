import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetClassFeedbackByInstructorUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class GetClassFeedbackByInstructor implements GetClassFeedbackByInstructorUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.getClassFeedbackByInstructor(auth, data)
  }
}
