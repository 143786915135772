export default function Information({ className }: any) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33325 8.50016C1.33325 12.1822 4.31792 15.1668 7.99992 15.1668C11.6819 15.1668 14.6666 12.1822 14.6666 8.50016C14.6666 4.81816 11.6819 1.8335 7.99992 1.8335C4.31792 1.8335 1.33325 4.81816 1.33325 8.50016ZM13.3333 8.50016C13.3333 11.4457 10.9454 13.8335 7.99992 13.8335C5.0544 13.8335 2.66659 11.4457 2.66659 8.50016C2.66659 5.55464 5.0544 3.16683 7.99992 3.16683C10.9454 3.16683 13.3333 5.55464 13.3333 8.50016ZM8.66659 5.16683V6.50016H7.33325V5.16683H8.66659ZM8.66659 11.8335V7.8335H7.33325V11.8335H8.66659Z"
        fill="white"
      />
    </svg>
  )
}
