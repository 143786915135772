import { Auth } from "domain/model/Auth"
import { ViewBatchData } from "domain/model/ViewBatchTimestampData"
import HomeRepository from "domain/repository/Admin/HomeRepository"

export interface ViewBatchTimestampDataUseCase {
  invoke(auth: Auth, data: ViewBatchData): Promise<any>
}

export class ViewBatchTimestampData implements ViewBatchTimestampDataUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: ViewBatchData) {
    return this.repository.viewBatchTimestampData(auth, data)
  }
}
