import CopyButton from "core/components/Button"
import RedeemButton from "core/components/new/Button"
import { CoinIcon, CopyIcon } from "core/constants/svgs"
import { format } from "date-fns"
import React from "react"
import amazon_vocher from "assets/images/amazon_voucher.png"
import extra_mentor_session from "assets/images/extra_mentor_session.png"

export default function MentorSessionCouponCard(order: any) {
  return (
    <div className="flex h-[162px] overflow-hidden border-[#DEDEDE] border-[0.5px] rounded-[8px] p-[16px] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.05)]">
      <div className="h-full w-full max-w-[240px]">
        <img className="h-full rounded-[8px]" src={extra_mentor_session} alt="" />
      </div>
      <div className="flex w-full justify-between pl-6">
        <div className="flex flex-1 flex-col justify-between gap-2 text-xs font-medium">
          <div className="flex justify-between my-[4px]">
            <div className="space-y-[4px]">
              <p className="text-[#646464] text-[14px] font-[400]">Order ID : #{order?.order?.order_id}</p>
              <p className="text-[#0C0C0C] text-[18px] font-[600]">Mentor Session</p>
              <div className="flex gap-x-1">
                <span className="text-[#646464] text-[12px] font-[600]">Date of Booking : </span>
                <span className="text-[#646464] text-[12px] font-[600]">
                  {format(order?.order?.order_date * 1000, "do MMM, yyyy")}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 self-start">
              <span>
                <CoinIcon className="h-6 w-6" />
              </span>
              <div className="px-[8px] py-[4px] bg-[#FEFFE4]">
                <span className="text-[14px] font-[600] text-[#FBBF24]">100000</span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="w-[283px] text-[#646464] text-[10px] font-[600]">
              The team will schedule a mentor session and update you. If you don't hear back within 2 working days,
              contact us at help@bosscoderacademy.com.
            </span>
            <p className="text-[#07B42D] font-[600] text-[18px]">Redeem Successful</p>
          </div>
        </div>
      </div>
    </div>
  )
}
