import ExtraMentorSessionDataSource from "data/dataSource/Admin/MentorSessionDataSource"
import { Auth } from "domain/model/Auth"
import { MentorSession } from "domain/model/MentorSession"
import MentorSessionRepository from "domain/repository/Admin/MentorSessionRepository"

export class MentorSessionRepositoryImpl implements MentorSessionRepository {
  private dataSource: ExtraMentorSessionDataSource

  constructor(dataSource: ExtraMentorSessionDataSource) {
    this.dataSource = dataSource
  }

  async createMentorSession(auth: Auth, session: MentorSession): Promise<any> {
    return this.dataSource.createExtraMentorSession(auth, session)
  }
}
