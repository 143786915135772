import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Student/ContestRepository"

export interface GetUpcomingExamsUseCase {
  invoke(auth: Auth, page: number, limit: number): Promise<any>
}

export default class GetUpcomingExams implements GetUpcomingExamsUseCase {
  private repository: ContestRepository
  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, page: number, limit: number): Promise<any> {
    return await this.repository.getUpcomingExams(auth, page, limit)
  }
}
