import { useRef, useState } from "react"
import { useAuth } from "core/context/auth"
import { ContestApproveRepositoryImpl } from "data/repository/Admin/ContestApproveRepositoryImpl"
import { GetTop100ApproveRequests } from "domain/useCase/Admin/ContestApprove/GetTop100ApproveRequests"
import { GetApprovalByEmailRequests } from "domain/useCase/Admin/ContestApprove/GetApprovalByEmailRequests"
import { GetApprovalByDateRequests } from "domain/useCase/Admin/ContestApprove/GetApproveByDateRequests"
import { ContestApproveAPIDataSourceImpl } from "data/API/Admin/ContestApproveAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import { TDisableStudentReason } from "core/constants/types"
import { approveData } from "domain/model/ContestApprove"
import { ApproveToDisapproveRequests } from "domain/useCase/Admin/ContestApprove/ApproveToDisapprove"

export default function ContestApproveViewModel() {
  const tableHeaders = ["General Info", "Batch", "Exam Details", "Reason", "Approve"]

  const [studentContests, setStudentContests] = useState<any>([]) // contestDetails
  const [mailSendLoading, setMailSendLoading] = useState<number>(-1)
  const [nextStudentsLoading, setNextStudentsLoading] = useState(false)
  const [lastStudentId, setLastStudentId] = useState<string>("")
  const [searchByEmail, setSearchByEmail] = useState<string>("") // search by email
  const [dateValue, setDateValue] = useState<string>("") // search by contest date

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const emailInputRef = useRef<HTMLInputElement>(null)

  const getTop100ApprovalUseCase = new GetTop100ApproveRequests(
    new ContestApproveRepositoryImpl(new ContestApproveAPIDataSourceImpl())
  )

  const approveToDisapprove = new ApproveToDisapproveRequests(
    new ContestApproveRepositoryImpl(new ContestApproveAPIDataSourceImpl())
  )

  const getApprovalByEmail = new GetApprovalByEmailRequests(
    new ContestApproveRepositoryImpl(new ContestApproveAPIDataSourceImpl())
  )

  const getApproveByDateUseCase = new GetApprovalByDateRequests(
    new ContestApproveRepositoryImpl(new ContestApproveAPIDataSourceImpl())
  )

  const handleDateChange = (e: any) => {
    const time = e.target.value
    setDateValue(time)
  }
  const fetchApprovalByDate = async () => {
    setNextStudentsLoading(true)
    const getData = setTimeout(async () => {
      if (dateValue) {
        const response = await getApproveByDateUseCase.invoke(auth, { date: dateValue })

        if (!response?.success) {
          changeToastDetails(STR_FAILURE, response?.error)
          changeToastVisibility(true)
          return
        }

        if (!isEmpty(response?.data)) setStudentContests(response?.data)
        else setStudentContests([])
        setNextStudentsLoading(false)
      } else {
        getTop100ApproveRequests()
      }
    }, 800)

    return () => clearTimeout(getData)
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) {
      setSearchByEmail(e.target.value)
    } else setSearchByEmail("")
  }

  const fetchApprovalByEmail = async (email: string) => {
    setNextStudentsLoading(true)
    const response = await getApprovalByEmail.invoke(auth, { email })
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    if (!isEmpty(response?.data)) {
      setStudentContests(response?.data)
    } else setStudentContests([])
    setNextStudentsLoading(false)
  }

  async function getTop100ApproveRequests() {
    setNextStudentsLoading(true)
    const response = await getTop100ApprovalUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setNextStudentsLoading(false)
      return
    }

    if (response?.success) setStudentContests(response?.data)

    setNextStudentsLoading(false)
  }

  async function approveForContest(data: approveData) {
    const response = await approveToDisapprove.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setNextStudentsLoading(false)
      return
    }

    const newStudentContests = studentContests.map((contest: any) => {
      if (
        (contest?.contest_id === data?.contest_id || contest?.exam_id === data?.contest_id) &&
        contest?.email === data?.email
      ) {
        return {
          ...contest,
          is_approved: !contest.is_approved,
        }
      }
      return contest
    })

    setStudentContests(newStudentContests)
  }

  return {
    tableHeaders,
    studentContests,
    approveForContest,
    setStudentContests,
    nextStudentsLoading,
    fetchApprovalByEmail,
    handleSearchByEmail,
    handleDateChange,
    dateValue,
    fetchApprovalByDate,
    lastStudentId,
    searchByEmail,
    toast,
    changeToastVisibility,
    changeToastDetails,

    emailInputRef,
    getTop100ApproveRequests,
  }
}
