import {
  API_V4_ADMIN,
  API_V4_BATCH_SEARCH,
  API_V4_CREATE_BATCH,
  API_V4_END_BATCH,
  API_V4_GET_ALL_ACTIVE_BATCHES,
  API_V4_GET_ALL_MENTORSHIP_TEMPLATES,
  API_V4_UPDATE_BATCH_DETAILS,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import BatchDataSource from "data/dataSource/Admin/BatchDataSource"
import { Auth } from "domain/model/Auth"
import { Batch } from "domain/model/Batch"
import { EndBatchParams } from "domain/model/EndBatch"

export class BatchAPIDataSourceImpl implements BatchDataSource {
  async createBatch(createBatch: Batch): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_CREATE_BATCH), createBatch)
      return response
    } catch (error) {
      return error
    }
  }

  async getBatchDetails(auth: Auth, batch_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_BATCH_SEARCH), {
        id_token: auth.id_token,
        batch_name: batch_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async updateBatchDetails(updateBatch: Batch): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_UPDATE_BATCH_DETAILS), updateBatch)
      return response
    } catch (error) {
      return error
    }
  }
  async endBatch(params: EndBatchParams): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_END_BATCH), params)
      return response
    } catch (error) {
      return error
    }
  }
  async getAllActiveBatches(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_ACTIVE_BATCHES), auth)
      return response
    } catch (error) {
      return error
    }
  }
  async getAllMentorshipTemplates(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_MENTORSHIP_TEMPLATES), auth)
      return response
    } catch (error) {
      return error
    }
  }
}
