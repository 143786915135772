import { useEffect, useState } from "react"
import GenerateInvoiceViewModel from "./GenerateInvoiceViewModel"
import { isEmpty } from "core/utils/misc"
import InstructorSheet from "./components/InstructorSheet"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"

export default function GenerateInvoiceView() {
  const {
    auth,
    toast,
    allInstructorDetails,
    instructorList,
    allOriginalInstructor,
    setAllInstructorDetails,
    getInstructorsList,
    changeToastVisibility,
    fetchAllInstructorDetails,
  } = GenerateInvoiceViewModel()

  const [name, setName] = useState("")
  useEffect(() => {
    // if ("role" in auth) {
    fetchAllInstructorDetails()
    getInstructorsList()
    // }
  }, [])

  async function handleInstructorSearch(name: string) {
    const filter_data = (allOriginalInstructor?.all_instructors || []).filter(
      (item: any, key: number) => name === "" || item.email === name
    )
    setAllInstructorDetails({ all_instructors: filter_data })
  }

  async function handleInstructorName(e: any) {
    setName(e.target.value)
    await handleInstructorSearch(e.target.value)
  }

  return (
    <div>
      <div className="space-y-6 p-6">
        <div className="">
          <h2>INSTRUCTORS SHEET</h2>
        </div>
        <select className="h-9 w-[209px]" onChange={handleInstructorName} value={name}>
          <option value="">Instructor</option>
          {instructorList?.map((instructor: any, i: number) => (
            <option value={instructor?.email} key={i}>
              {instructor?.name}
            </option>
          ))}
        </select>
        {!isEmpty(allInstructorDetails) ? (
          <div className="mx-4 flex flex-col space-y-6">
            <div className="space-y-6">
              {!isEmpty(allInstructorDetails?.all_instructors) ? (
                allInstructorDetails?.all_instructors?.map((data: any, i: number) => (
                  <InstructorSheet key={i} data={data} />
                ))
              ) : (
                <div>
                  <h4>No Data Found</h4>
                </div>
              )}{" "}
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
