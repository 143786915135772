export default function MoreOptionsIcon({ className }: any) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.66699H14V4.00033H2V2.66699ZM2 7.33366H14V8.66699H2V7.33366ZM14 12.0003H2V13.3337H14V12.0003Z"
        fill="#646464"
      />
    </svg>
  )
}
