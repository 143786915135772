import { Button } from "core/components/v2/Button"
import React, { useState } from "react"
import PlacementDetailsForm from "./PlacementDetailsForm"

type CourseCompletionCardProps = {
  student: any
}

const CourseCompletionCard: React.FC<CourseCompletionCardProps> = ({ student }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="relative p-6">
      <div className={`relative w-full overflow-hidden bg-[#FFEDED] rounded-lg border-[0.5px] px-6 py-4 color-[#333]`}>
        <div className="flex items-center  justify-between">
          <div className="max-w-[600px] flex-col gap-[5px]">
            <div className="color-[#333333] font-inter text-[16px] font-semibold flex">Placement Exam Update:</div>
            <div className="color-[#333333] flex font-inter mt-[5px] text-[12px] font-semibold">
              Congratulations on qualifying for the placement cohort exam. Please complete the attached form to proceed
              with the next steps.
            </div>
          </div>
          <div>
            <Button
              onClick={handleOpenModal}
              className="px-[16px] py-[9px] text-white rounded-md border border-white bg-[#162456] hover:bg-white hover:text-[#333] hover:border-new-solid-black"
            >
              Click Here
            </Button>
            {isOpen && <PlacementDetailsForm onClose={handleCloseModal} docId={student?.email} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCompletionCard
