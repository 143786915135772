import React from "react"
import { FeedbackRepositoryImpl } from "data/repository/Student/NewFeedbackRepositoryImpls"
import { FeedbackAPIDataSourceImpl } from "data/API/Student/NewFeedbackAPIDataSourceImpls"
import { GetLastFeedback } from "domain/useCase/Student/NewFeedback/GetLastFeedback"
import { GetJoiningDate } from "domain/useCase/Student/NewFeedback/GetJoiningDate"
import { SaveFeedback } from "domain/useCase/Student/NewFeedback/SaveFeedback"
import { FeedbackPage } from "domain/model/NewFeedback"
import { useAuth } from "core/context/auth"

export default function FeatureFeedbackViewModel() {
  const { auth } = useAuth()
  const getLastFeedbackUseCase = new GetLastFeedback(new FeedbackRepositoryImpl(new FeedbackAPIDataSourceImpl()))
  const getJoiningDateUseCase = new GetJoiningDate(new FeedbackRepositoryImpl(new FeedbackAPIDataSourceImpl()))
  const saveFeedbackUseCase = new SaveFeedback(new FeedbackRepositoryImpl(new FeedbackAPIDataSourceImpl()))

  const fetchLastFeedback = async (page: string): Promise<string> => {
    try {
      const response = await getLastFeedbackUseCase.invoke(auth, page)
      if (response) {
        return response
      } else {
        return ""
      }
    } catch (error) {
      console.error("Error fetching last feedback date:", error)
      return ""
    }
  }

  const getJoiningDate = async (): Promise<string> => {
    try {
      const response = await getJoiningDateUseCase.invoke(auth)
      if (response) {
        return response
      } else {
        return ""
      }
    } catch (error) {
      console.error("Error fetching joining date:", error)
      return ""
    }
  }

  const saveFeedback = async (pages: Record<string, FeedbackPage[]>): Promise<void> => {
    try {
      await saveFeedbackUseCase.invoke(auth, pages)
    } catch (error) {
      console.error("Error saving feedback:", error)
    }
  }

  function shouldShowFeedbackAfter2Months(joiningDate: string, lastFeedbackDate: string | null): boolean {
    const currentDate = new Date()
    const joinDate = new Date(
      parseInt(joiningDate.substring(0, 4)),
      parseInt(joiningDate.substring(4, 6)) - 1,
      parseInt(joiningDate.substring(6, 8))
    )

    const lastFeedback = lastFeedbackDate ? new Date(lastFeedbackDate) : null

    if (!lastFeedback) {
      const twoMonthsAfterJoining = new Date(joinDate)
      twoMonthsAfterJoining.setMonth(twoMonthsAfterJoining.getMonth() + 2)

      return currentDate >= twoMonthsAfterJoining
    }

    const twoMonthsAfterLastFeedback = new Date(lastFeedback)
    twoMonthsAfterLastFeedback.setMonth(twoMonthsAfterLastFeedback.getMonth() + 2)

    return currentDate >= twoMonthsAfterLastFeedback
  }

  function shouldShowFeedbackAfter5Months(joiningDate: string, lastFeedbackDate: string | null): boolean {
    const currentDate = new Date()

    const joinDate = new Date(
      parseInt(joiningDate.substring(0, 4)),
      parseInt(joiningDate.substring(4, 6)) - 1,
      parseInt(joiningDate.substring(6, 8))
    )

    if (!lastFeedbackDate) {
      const fiveMonthsAfterJoining = new Date(joinDate)
      fiveMonthsAfterJoining.setMonth(fiveMonthsAfterJoining.getMonth() + 5)
      return currentDate >= fiveMonthsAfterJoining
    }

    const lastFeedback = new Date(lastFeedbackDate)
    const twoMonthsAfterLastFeedback = new Date(lastFeedback)
    twoMonthsAfterLastFeedback.setMonth(twoMonthsAfterLastFeedback.getMonth() + 2)

    return currentDate >= twoMonthsAfterLastFeedback
  }

  return {
    fetchLastFeedback,
    getJoiningDate,
    saveFeedback,
    shouldShowFeedbackAfter2Months,
    shouldShowFeedbackAfter5Months,
  }
}
