import React, { useEffect, useState } from "react"
import { differenceInMinutes, format } from "date-fns"
import { MentorGlobeIllustration } from "core/constants/svgs"
import StandardSelect from "core/components/v2/StandardSelect"
import Select from "core/components/new/Select"
import { Button } from "core/components/v2/Button"
import Spinner from "core/components/Spinner"
import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import { STR_FAILURE } from "core/constants/strings"
import useMentorViewModel from "../MentorViewModel"
import { GetFreezeSlotDetails } from "domain/useCase/Student/Mentor/GetFreezeSlotDetails"
import { MentorRepositoryImpl } from "data/repository/Student/MentorRepositoryImpl"
import { MentorAPIDataSourceImpl } from "data/API/Student/MentorAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { Tooltip } from "@mui/material"
export default function NewMentorshipCard({
  index,
  freezeData,
  mentorSessions,
  selectedSession,
  session,
  mentor,
  timer,
  setTimer,
  isTimerRunning,
  setIsTimerRunning,
  isTemp,
  mentorSessionDetails,
  setBookingDetails,
  freezeMentorSlot,
  setConfirmSessionIndex,
  setSelectedSession,
  setConfirmModal,
}: any) {
  const [freezeDetails, setFreezeDetails] = useState<any>({})

  const getFreezeSlotUseCase = new GetFreezeSlotDetails(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const { auth, user } = useAuth()

  const [isBookingLoading, setIsBookingLoading] = React.useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [remainingTime, setRemainingTime] = useState(300)
  async function getFreezeSlotDetails(data: any) {
    const response = await getFreezeSlotUseCase.invoke(auth, data)
    setFreezeDetails(response?.data)

    setRemainingTime(Math.min(300, response?.data?.details?.freeze_timestamp - Math.floor(Date.now() / 1000)))
  }

  const formatDateTime = (decimalTime: any) => {
    const hours = Math.floor(decimalTime)
    const minutes = (decimalTime % 1) * 60
    const isPM = hours >= 12
    const displayHours = hours % 12 === 0 ? 12 : hours % 12
    const displayMinutes = minutes === 0 ? "00" : "30"
    const period = isPM ? "PM" : "AM"
    return `${displayHours}:${displayMinutes} ${period}`
  }
  const formattedDate = new Date(freezeDetails?.details?.date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  })
  const formattedTime = formatDateTime(parseFloat(freezeDetails?.details?.start))
  const startTimestamp = new Date(session?.start_timestamp)
  const endTimestamp = new Date(session?.end_timestamp)
  if (mentorSessionDetails?.status === "booked") {
  }
  const durationInMinutes = differenceInMinutes(endTimestamp, startTimestamp)

  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState("")

  // useEffect(() => {
  //   if (remainingTime > 0) {
  //     const timer = setInterval(() => {
  //       setRemainingTime(prevTime => Math.max(0, prevTime - 1)); // Decrease timer every second
  //     }, 1000);

  //     return () => clearInterval(timer); // Cleanup on unmount
  //   }
  // }, [remainingTime]);
  useEffect(() => {
    let interval: any
    if (isTimerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev: any) => prev - 1)
      }, 1000)
    }

    if (timer === 0) {
      clearInterval(interval)
      setIsTimerRunning(false) // Hide the timer when it reaches zero
    }

    return () => clearInterval(interval) // Cleanup interval on unmount
  }, [timer, isTimerRunning])
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`
  }
  useEffect(() => {
    const opt: any = [
      //   {
      //     label: "HH: MM",
      //     value: -1,
      //   },
    ]

    const sessions = mentorSessions.map((session: any, index: number) => {
      return {
        ...session,
        index,
      }
    })
    const sortedSessions = sessions.sort(
      (a: any, b: any) => new Date(a.start_timestamp).getTime() - new Date(b.start_timestamp).getTime()
    )

    sortedSessions.map((session: any) => {
      opt.push({
        label: format(new Date(session?.start_timestamp), "h:mm a"),
        value: session.index,
      })
    })

    setOptions(opt)
    setSelectedValue("")
  }, [mentorSessions])
  return (
    <div className="">
      <h4 className="text-[#333] text-[18px] font-[600] my-[6px]">Mentorship Session</h4>
      <Select
        className="w-full my-[10px]"
        options={options}
        defaultOption="HH:MM"
        value={selectedValue}
        onChange={(event) => {
          const selectedIndex = event.target.value
          const sessionIndex = Number(selectedIndex)
          setSelectedSession({
            ...mentorSessions[sessionIndex],
            mentor_id: mentor?.email,
          })
          setSelectedValue(selectedIndex)
          getFreezeSlotDetails({
            ...mentorSessions?.[sessionIndex],
            mentor_id: mentor?.email,
          })
        }}
      />
      <div className="absolute w-full px-[16px] py-[16px] left-0 bottom-0 flex items-center justify-between gap-8">
        <div>
          <h5 className="text-[#646464] text-[12px] font-[500]">{mentor?.name || mentor}</h5>
          <h5 className="text-[#646464] text-[12px] font-[500]">{durationInMinutes} Min</h5>
        </div>
        <Tooltip
          title={
            freezeDetails?.message === "Already frozen session by same student"
              ? `You already have a frozen session on ${formattedDate} at ${formattedTime}`
              : freezeDetails?.message === "Session frozen by someone"
                ? "Session not available. Try booking some other session"
                : ""
          }
        >
          <Button
            onClick={async () => {
              setBookingDetails(selectedSession)

              setConfirmModal(isTemp ? mentor?.email : true)
              getFreezeSlotDetails(selectedSession)
              freezeMentorSlot(selectedSession)

              setTimer(remainingTime)
              setIsTimerRunning(true)
            }}
            variant={"primary"}
            size="sm"
            disabled={
              isBookingLoading ||
              mentorSessionDetails?.status === "booked" ||
              !selectedSession ||
              freezeDetails?.message !== "Session eligible for booking"
            }
            //   loading={isBookingLoading}
          >
            {isBookingLoading ? <Spinner small={true} /> : "Book Session"}
          </Button>
        </Tooltip>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
