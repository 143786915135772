import IconButton from "core/components/IconButton"
import Spinner from "core/components/Spinner"
import Toggle from "core/components/Toggle"
import { STR_PAID } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import React from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import GenerateInvoiceViewModel from "../GenerateInvoiceViewModel"
import Button from "core/components/new/Button"
import { stringToAmount } from "core/utils/stringToAmount"
import MonthDataColoumn from "./MonthDataColoumn"
import pdfMake from "core/lib/pdf"

export default function MonthlySessionTable(props: any) {
  const {
    yearMonth,
    Loading,
    confirmStatus,
    fetchUpdatedInstructorDetails,
    setLoading,
    fetchConfirmedInvoiceOfMentor,
    fetchAllSessionMonthyear,
  } = GenerateInvoiceViewModel()

  const MonthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const { auth } = useAuth()

  const [searchYear, setSearchYear] = React.useState<string>(new Date().getFullYear().toString())
  const [apiData, setApiData] = React.useState<any[]>([])
  const [payableAmount, setPayableAmount] = React.useState(0)
  const [year, setYear] = React.useState<string>(new Date().getFullYear().toString())
  const { id } = useParams()
  const navigate = useNavigate()
  function handleSubmit() {
    setLoading(true)
    setYear(searchYear)
    setLoading(false)
  }
  const redirectToSessions = (id: string, index: number) => {
    const monthString = (index + 1).toString().padStart(2, "0")
    const time_given = `${year}${monthString}`
    navigate(`/admin/generate-invoice/${id}/${time_given}`)
  }

  const fetchData = async (i: number) => {
    const monthString = i.toString().padStart(2, "0")
    const time_given = `${year}${monthString}`
    const data = await fetchUpdatedInstructorDetails(auth, id as string, time_given)
    if (typeof data == "string") {
      return data
    }
    const addData = await fetchConfirmedInvoiceOfMentor(auth, id as string, time_given)
    const dataWithAdditionalField = {
      ...data,
      invoice_amount:
        data.monthly_data.payable_amount != 0 ? data.monthly_data.payable_amount : addData?.total_amount || 0,
      invoice_sessions: addData?.total_sessions,
      invoice_hours: addData?.total_hours,
      month_index: i,
    }
    setPayableAmount(data.monthly_data.payable_amount)
    return dataWithAdditionalField
  }

  const [counter, setCounter] = React.useState(12)
  React.useEffect(() => {
    setCounter(12)
    setApiData([])
    fetchConfirmedInvoiceOfMentor(auth, id as string, yearMonth)
  }, [year, id as string, props.reloadData])

  React.useEffect(() => {
    async function getData() {
      const data = await fetchData(counter)
      if (typeof data !== "string") {
        setApiData([...apiData, data])
      }
      setCounter(counter - 1)
    }
    if (counter > 0) {
      getData()
    }
  }, [counter])

  function convertTimestampToDate(timestamp: any) {
    const date = new Date(timestamp * 1000)
    const options: any = { year: "numeric", month: "long", day: "numeric" }
    return date.toLocaleDateString(undefined, options)
  }

  const formatCurrency = (amount: any) => `Rs.${parseInt(amount, 10).toLocaleString()}`

  const getFiscalYear = (monthName: any, year: any) => {
    const currentYear = year
    const fiscalYearStartMonth = 5 // May
    const monthNumber = new Date(`${monthName} 1`).getMonth() + 1
    if (monthNumber >= fiscalYearStartMonth) {
      return `${currentYear}-${currentYear + 1}`
    }
    return `${currentYear - 1}-${currentYear}`
  }

  // Helper to get month range
  const getMonthRange = (month: string, data: any) => {
    const yearMonth = data.monthly_data.year_month
    const year = parseInt(yearMonth.slice(0, 4), 10)
    let startDate = `1st ${capitalizeFirstLetter(month)}`
    let endDate = ""
    const monthsWith30Days = ["april", "june", "september", "november"]
    const monthsWith31Days = ["january", "march", "may", "july", "august", "october", "december"]
    if (month.toLowerCase() === "february") {
      endDate = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? `29th February` : `28th February`
    } else if (monthsWith30Days.includes(month.toLowerCase())) {
      endDate = `30th ${capitalizeFirstLetter(month)}`
    } else if (monthsWith31Days.includes(month.toLowerCase())) {
      endDate = `31st ${capitalizeFirstLetter(month)}`
    }
    return `${startDate} to ${endDate} (FY ${getFiscalYear(month, year)})`
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const generatePDFInvoice = (data: any, monthName: any) => {
    monthName = monthName.toLowerCase()
    const yearMonth = data.monthly_data.year_month
    const year = parseInt(yearMonth.slice(0, 4), 10)
    const month = parseInt(yearMonth.substring(4), 10)
    let nextMonth = month + 1
    let nextYear = year

    if (nextMonth > 12) {
      nextMonth = 1
      nextYear += 1
    }

    const formattedDate = `01/${String(nextMonth).padStart(2, "0")}/${nextYear}`

    const accountDetails = {
      IFSC: props.invoiceDetails?.IFSC || "Your IFSC Code",
      account_number: props.invoiceDetails?.account_number || "Your Account Number",
      beneficiary_name: props.invoiceDetails?.beneficiary_name || "Your Beneficiary Name",
    }

    const docDefinition: any = {
      content: [
        {
          canvas: [{ type: "rect", x: 0, y: 0, w: 595, h: 110, color: "#1F2937" }],
          absolutePosition: { x: 0, y: 0 },
        },
        { text: "Invoice", style: "header", margin: [0, 10, 0, 10] },
        {
          columns: [
            [
              { text: props.invoiceDetails?.beneficiary_name, style: "mentorHeader", margin: [0, 20, 0, 0] },
              { text: props.invoiceDetails?.address || "Address along with Pincode", style: "subText" },
              { text: props.data.email, style: "subText" },
            ],
            [
              {
                text: `Invoice ID: ${data.monthly_data.invoice_id}`,
                style: "invoiceDetails",
                alignment: "right",
                margin: [0, 20, 0, 0],
              },
              { text: `Date: ${formattedDate}`, style: "invoiceDetails", alignment: "right" },
            ],
          ],
          margin: [0, 20, 0, 20],
        },

        { text: "To :", style: "subheader", margin: [0, 30, 0, 0] },
        { text: "Bosscoder Software Services Private Limited", style: "receiverDetails" },
        { text: "E-401, Dasnac, The Jewel,", style: "receiverDetails" },
        { text: "Sec-75, Noida", style: "receiverDetails", margin: [0, 0, 0, 20] },

        {
          table: {
            headerRows: 1,
            widths: ["auto", "*", "auto", "auto"],
            body: [
              [
                { text: "Sr.", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
                { text: "No. of hours", style: "tableHeader" },
                { text: "Value in INR", style: "tableHeader" },
              ],
              [
                { text: "1", style: "tableBody" },
                {
                  text: [
                    `Instructor session Professional Fee \n`,
                    { text: getMonthRange(monthName, data), fontSize: 12, color: "#4B5563" },
                  ],
                  style: "tableBody",
                },
                { text: data.invoice_hours.toString(), style: "tableBody" },
                { text: formatCurrency(payableAmount != 0 ? payableAmount : data.invoice_amount), style: "tableBody" },
              ],
              [
                { text: "", style: "tableBody" },
                { text: "Total", style: "tableTotal", alignment: "right" },
                { text: "", style: "tableBody" },
                {
                  text: formatCurrency(payableAmount != 0 ? payableAmount : data.invoice_amount),
                  style: "tableTotal",
                  alignment: "right",
                  color: "black",
                },
              ],
            ],
          },
          layout: {
            fillColor: (rowIndex: any) => (rowIndex === 0 ? "#E5E7EB" : null),
          },
          margin: [0, 20, 0, 20],
        },

        {
          text: "Please transfer the invoice amount to the following bank account:",
          fontSize: 16,
          margin: [0, 30, 0, 20],
        },
        {
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            fillColor: "#F3F4F6",
            borderColor: "#D1D5DB",
          },
          table: {
            widths: ["auto", "*"],
            body: [
              [
                { text: "Account holder name:", bold: true, margin: [0, 2, 0, 2] },
                { text: accountDetails.beneficiary_name, margin: [0, 2, 0, 2] },
              ],
              [
                { text: "IFSC:", bold: true, margin: [0, 2, 0, 2] },
                { text: accountDetails.IFSC, margin: [0, 2, 0, 2] },
              ],
              [
                { text: "Account Number:", bold: true, margin: [0, 2, 0, 2] },
                { text: accountDetails.account_number, margin: [0, 2, 0, 2] },
              ],
            ],
          },
          margin: [0, 0, 0, 30],
        },
      ],
      styles: {
        header: { fontSize: 32, bold: true, color: "#F9FAFB", alignment: "center" },
        mentorHeader: { fontSize: 18, bold: true, color: "#1F2937" },
        subheader: { fontSize: 16, bold: true, color: "#374151" },
        invoiceDetails: { fontSize: 14, color: "#6B7280" },
        subText: { fontSize: 14, color: "#4B5563" },
        receiverDetails: { fontSize: 14, color: "#4B5563" },
        tableHeader: { bold: true, fontSize: 14, color: "#1F2937", fillColor: "#F3F4F6" },
        tableBody: { fontSize: 14, color: "#1F2937" },
        tableTotal: { bold: true, fontSize: 18, color: "#000000" },
      },
    }

    pdfMake.createPdf(docDefinition).open()
  }

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex gap-x-10">
        <input
          type="text"
          placeholder="Search by year..."
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
        <Button loading={Loading} className="w-fit" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <div className="relative overflow-x-auto rounded-md shadow-md">
        <table className="w-full text-left text-sm text-new-neutral-dark">
          <thead className="bg-new-neutral-dark text-new-solid-white">
            <tr>
              {props.TableHeader?.map((title: any, index: any) => (
                <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {apiData?.map((data: any, index: any) => (
              <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                <td className="px-6 py-4 ">
                  <p>{MonthName[data.month_index - 1]}</p>
                </td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.invoice_sessions}</td>
                <td
                  className={`px-6 py-4 text-[16px] font-semibold ${
                    data.monthly_data.average_rating <= 4.4
                      ? data.average_rating <= 3
                        ? "text-red-500"
                        : "text-yellow-300"
                      : "text-green-500"
                  }`}
                >
                  {data.monthly_data.average_rating !== -1
                    ? `${data.monthly_data.average_rating} out of 5`
                    : "Not Rated"}
                </td>
                <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                  {stringToAmount(
                    parseInt(
                      payableAmount != 0
                        ? payableAmount
                        : data.invoice_amount
                          ? payableAmount != 0
                            ? payableAmount
                            : data.invoice_amount
                          : 0
                    )
                  )}
                </td>
                <td className="px-6 py-4 text-[16px]">
                  {" "}
                  <Button onClick={() => redirectToSessions(id as string, data.month_index - 1)}>View Sessions</Button>
                </td>
                <td className="px-6 py-4 text-[16px]">
                  {" "}
                  <Button
                    onClick={() => generatePDFInvoice(data, MonthName[data.month_index - 1])}
                    disabled={!data.monthly_data.invoice_id}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
