import { getStudentDetails } from "domain/model/StudentDashboard"
import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface GetClassCoinsTransactionUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}
export class GetClassCoinsTransaction implements GetClassCoinsTransactionUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.getClassCoinsTransaction(auth, data)
  }
}
