import Input from "core/components/new/Input"
import useCreateBatchViewModel from "presentation/Admin/PlacementCreateBatch/CreateBatchViewModel"
import usePlacementConstant from "presentation/Admin/PlacementDashboard/components/PlacementConstants"
import { useEffect } from "react"

export default function GeneralInfoSection({ studentDetails, setStudentDetails }: any) {
  const { getBatches, selectBatch } = useCreateBatchViewModel()

  useEffect(() => {
    getBatches()
  }, [])

  const { programs } = usePlacementConstant()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: string) => {
    const updatedValue = e.target.value
    setStudentDetails({ ...studentDetails, [key]: updatedValue })
  }

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.warn("Email cannot be changed")
  }

  const handleNoticePeriodChange = (value: any, type: any) => {
    const noticePeriod = typeof studentDetails.notice_period === "string" ? studentDetails.notice_period : ""

    let [days, negotiable] = noticePeriod.split(" ")

    if (type === "days") {
      days = value || ""
    } else if (type === "Negotiable") {
      negotiable = value ? "Negotiable" : ""
    }

    setStudentDetails({
      ...studentDetails,
      notice_period: `${days} ${negotiable}`.trim(),
    })
  }

  return (
    <div className="rounded-[2px] w-full border-[0.4px] p-6 flex justify-between items-center">
      <div className="w-full">
        <p className="text-[#312E81] font-semibold">General Information</p>
        <hr className="w-full border-t-2 border-dashed border-gray-300 my-4"></hr>
        <div className="grid grid-cols-3 gap-x-10 gap-y-6">
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Student Name</p>
            <Input
              placeholder="Student Name"
              value={studentDetails.name}
              onChange={(e) => handleChange(e, "name")}
              id="name"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Dream Company</p>
            <Input
              placeholder="Dream Company"
              value={studentDetails.dream_company}
              onChange={(e) => handleChange(e, "dream_company")}
              id="dream_company"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Phone Number</p>
            <Input
              placeholder="Phone Number"
              value={studentDetails.phone}
              onChange={(e) => handleChange(e, "phone")}
              id="phone"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Email Address</p>
            <Input value={studentDetails.email} onChange={handleEmail} id="email" />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Current Company</p>
            <Input
              placeholder="Current Company"
              value={studentDetails.current_company}
              onChange={(e) => handleChange(e, "current_company")}
              id="current_company"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Year of Graduation</p>
            <Input
              placeholder="Year of Graduation"
              value={studentDetails.college_passing_year}
              onChange={(e) => handleChange(e, "college_passing_year")}
              id="year_of_graduation"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Current Job Profile</p>
            <Input
              placeholder="Current Job Profile"
              value={studentDetails.current_job_profile}
              onChange={(e) => handleChange(e, "current_job_profile")}
              id="current_job_profile"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Gap Years</p>
            <Input
              placeholder="Gap Years"
              value={studentDetails.gap_years}
              onChange={(e) => handleChange(e, "gap_years")}
              id="gap_years"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Dream Profile</p>
            <Input
              placeholder="Dream Profile"
              value={studentDetails.dream_profile}
              onChange={(e) => handleChange(e, "dream_profile")}
              id="dream_profile"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Current CTC</p>
            <Input
              placeholder="Current CTC"
              value={studentDetails.current_ctc}
              onChange={(e) => handleChange(e, "current_ctc")}
              id="current_ctc"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Desired Location</p>
            <Input
              placeholder="Desired Location"
              value={studentDetails.desired_location}
              onChange={(e) => handleChange(e, "desired_location")}
              id="desired_location"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141] pr-6">Notice Period</p>
            <div className="flex gap-4">
              <Input
                type="number"
                placeholder="Notice Period (in days)"
                value={studentDetails?.notice_period?.replace(/\D/g, "") || ""}
                onChange={(e) => handleNoticePeriodChange(e.target.value, "days")}
                id="notice_period_days"
              />
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="h-4 w-4"
                  checked={studentDetails?.notice_period?.includes("Negotiable")}
                  onChange={(e) => handleNoticePeriodChange(e.target.checked, "Negotiable")}
                  id="is_negotiable"
                />
                <label htmlFor="is_negotiable">Negotiable</label>
              </div>
            </div>
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">YOE</p>
            <Input
              type="number"
              placeholder="YOE"
              value={studentDetails.yoe}
              onChange={(e) => handleChange(e, "yoe")}
              id="yoe"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Placement Batch</p>
            <Input
              placeholder="Placement Batch"
              value={studentDetails.placement_batch}
              onChange={(e) => console.warn("Placement Batch cannot be changed")}
              id="placement_batch"
            />
          </div>
          <div className="">
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Student Batch</p>
            <select
              className="w-full rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              value={studentDetails.batch || "Select Batch"}
              onChange={(e) => handleChange(e, "batch")}
            >
              {selectBatch.map((option: any, index: number) => (
                <option key={index} value={option} disabled={option === "Select Batch"}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">BCA Rating</p>
            <Input
              type="number"
              placeholder="BCA Rating"
              value={studentDetails.bca_rating}
              onChange={(e) => handleChange(e, "bca_rating")}
              id="bca_rating"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Good- Preff Rating</p>
            <Input
              type="number"
              placeholder="Good- Preff Rating"
              value={studentDetails.placement_preferred_rating}
              onChange={(e) => handleChange(e, "placement_preferred_rating")}
              id="placement_preferred_rating"
            />
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Program</p>
            <select
              className="w-full rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              value={studentDetails.program || "Select Program"}
              onChange={(e) => handleChange(e, "program")}
            >
              {programs.map((option, index) => (
                <option key={index} value={option} disabled={option === "Select Program"}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="text-[16px] font-semibold pb-2 text-[#414141]">Skills</p>
            <Input
              placeholder="Skills"
              value={studentDetails.skills}
              onChange={(e) => handleChange(e, "skills")}
              id="skills"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
