import React from "react"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import AdminDocDataSourceImpl from "data/API/Admin/DocDataSourceImpl"
import { DocRepositoryImpl } from "data/repository/Admin/DocRepositoyImpl"
import { UploadImage } from "domain/useCase/Admin/Document/UploadImage"
import { CreateOnboardingManagers } from "domain/useCase/Admin/CreateOnboardingManger/CreateOnboardingManager"
import { CreateOnboardingManagerRepositoryImpl } from "data/repository/Admin/CreateOnboardingManagerRepositoryImpl"
import { CreateOnboardingManagerAPIDataSourceImpl } from "data/API/Admin/CreateOnboardingManagerAPIDataSourceImpl"
import { isEmail } from "core/utils/validator"

export default function CreateOnboardingManagerViewModel() {
  const [email, setEmail] = React.useState<string>("")
  const [linkedin, setLinkedin] = React.useState<any>("")
  const [name, setName] = React.useState<string>("")
  const [phone, setPhone] = React.useState<string>("")
  const [profile_pic, setProfile_pic] = React.useState<FormData>(new FormData())
  const [imgSrc, setImgSrc] = React.useState<any>("")
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false)
  const [isLead, setIsLead] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(false)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  function handlePhoneChange(event: any) {
    setPhone(event ?? "")
  }

  const handleNameChange = (event: any) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const handleLinkedinChange = (event: any) => {
    setLinkedin(event.target.value)
  }

  const handleProfile_picChange = (event: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (evt) => {
      setImgSrc(evt?.target?.result)
    }
    const form = new FormData()
    form.append("img", event.target.files[0])
    setProfile_pic(form)
  }

  const handleIsLeadChange = (event: any) => {
    const newValue = event.target.value === "true"
    setIsLead(newValue)
  }

  const handleIsDisabledChange = (event: any) => {
    const newValue = event.target.value === "true"
    setIsDisabled(newValue)
  }

  const createOnboardingManagerUseCase = new CreateOnboardingManagers(
    new CreateOnboardingManagerRepositoryImpl(new CreateOnboardingManagerAPIDataSourceImpl())
  )

  const OnboardingMangerImageUploadUseCase = new UploadImage(new DocRepositoryImpl(new AdminDocDataSourceImpl()))

  async function createOnboardingManagers() {
    setLoading(true)

    if (!email || !linkedin || !name || !phone || !profile_pic) {
      changeToastDetails(STR_FAILURE, "Please Fill all the fields!")
      changeToastVisibility(true)
      setLoading(false)
      return
    }

    const isValid = isEmail(email)
    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please enter a valid email!")
      changeToastVisibility(true)
      setLoading(false)
      return
    }

    const profile_pic_url = await OnboardingMangerImageUploadUseCase.invoke(auth, profile_pic)

    if (!profile_pic_url?.success) {
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      changeToastVisibility(true)
      return
    }

    const response = await createOnboardingManagerUseCase.invoke({
      id_token: auth.id_token.trim(),
      email: email,
      isDisabled: isDisabled,
      isLead: isLead,
      linkedin: linkedin,
      name: name,
      phone: phone,
      profile_pic: profile_pic_url.data,
    })

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      changeToastVisibility(true)
      return
    }

    setEmail("")
    setPhone("")
    setName("")
    setLinkedin("")
    setProfile_pic(new FormData())
    setIsLead(false)
    setIsDisabled(false)

    changeToastDetails(STR_SUCCESS, "Onboarding Manager created successfully!")
    changeToastVisibility(true)
  }

  return {
    email,
    phone,
    linkedin,
    profile_pic,
    isLead,
    isDisabled,
    name,
    toast,
    loading,

    imgSrc,
    handlePhoneChange,
    handleNameChange,
    handleEmailChange,
    handleLinkedinChange,
    handleProfile_picChange,
    handleIsLeadChange,
    handleIsDisabledChange,
    changeToastVisibility,
    createOnboardingManagers,
  }
}
