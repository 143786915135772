import { ScheduleClassAPIDataSource } from "data/API/Admin/ScheduledClassAPIDataSource"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { ScheduleClassRepositoryImpl } from "data/repository/Admin/ScheduledClassRepositoryImpl"
import { GetAllInstructors } from "domain/useCase/Admin/CreateClass/GetAllInstructors"
import { CancelSchduledClass } from "domain/useCase/Admin/ScheduledClasses/CancelScheduledClasses"
import { ScheduleClasses } from "domain/useCase/Admin/ScheduledClasses/ScheduledClasses"
import React, { useEffect } from "react"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { GetAllModules } from "domain/useCase/Admin/CreateClass/GetAllModules"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import useLocalStorage from "core/hooks/useLocalStorage"
import { useAuth } from "core/context/auth"

export default function ScheduledClassesViewModel() {
  const [batch, setBatch] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [classData, setClassData] = React.useState<any>([])
  const [cancelLoading, setCancelLoading] = React.useState(false)

  const [getBatchList, setBatchList] = React.useState<any>([])
  const [instructorList, setInstructorList] = React.useState<any>([])
  const [getModulesList, setModulesList] = React.useState<any>([])
  const [getTracksList, setTracksList] = React.useState<any>([])

  const [selectAssigmentsQuestions] = React.useState<any>([])
  const [currentSelectAssignmentsQuestions] = React.useState("")

  const [selectHomeworkQuestions] = React.useState<any>([])
  const [currentSelectHomeworkQuestions] = React.useState("")

  const [selectPracticalQuestions] = React.useState<any>([])
  const [currentSelectPracticalQuestions] = React.useState("")

  const [selectWarmupQuestions] = React.useState<any>([])
  const [currentSelectWarmupQuestions] = React.useState("")

  const [currentSelectBatch] = React.useState("")
  const [chapterNames] = React.useState("")
  const [classLink] = React.useState("")
  const [selectInstructor] = React.useState("")
  const [selectModuleId] = React.useState("")
  const [activeButtonType, setActiveButtonType] = React.useState(0)

  const [selectSessionTimestamp] = React.useState<any>("")
  const [track] = React.useState([])
  const [selectTrack] = React.useState("")
  const [videoId] = React.useState("")
  const [videoSecret] = React.useState("")
  const [notesLink] = React.useState("")
  const [notesSummaryLink] = React.useState("")
  const [tabsData, setTabsData] = React.useState<any>([])

  const { auth } = useAuth()

  const ScheduledClassUseCase = new ScheduleClasses(new ScheduleClassRepositoryImpl(new ScheduleClassAPIDataSource()))

  const CancelScheduledClassUseCase = new CancelSchduledClass(
    new ScheduleClassRepositoryImpl(new ScheduleClassAPIDataSource())
  )
  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllQuestonsUseCase = new GetAllInstructors(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllModuleIdUseCase = new GetAllModules(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllTracksUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  async function getScheduledClasses(e: any) {
    e.preventDefault()
    setLoading(true)
    const response = await ScheduledClassUseCase.invoke({
      id_token: auth.id_token,
      batch,
    })
    if (response?.success) {
      setClassData(response.data)
      setTabsData(response.data.upcoming)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  async function getScheduledClassesUtil() {
    const response = await ScheduledClassUseCase.invoke({
      id_token: auth.id_token,
      batch,
    })
    if (response.success) setClassData(response?.data?.response)
  }

  async function deleteScheduledClass(session_id: string) {
    const response = await CancelScheduledClassUseCase.invoke({
      id_token: auth.id_token,
      session_id,
    })

    if (response.success) {
      getScheduledClassesUtil()
    }
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }

  async function getInstructorsList() {
    const response = await getAllQuestonsUseCase.invoke(auth)

    if (response.success) {
      setInstructorList(response.data)
    }
  }

  async function getAllModules() {
    const response = await getAllModuleIdUseCase.invoke(auth)

    if (response.success) {
      setModulesList(response.data)
    }
  }

  async function getAllTracks() {
    const response = await getAllTracksUseCase.invoke(auth)
    setTracksList(response.data)
  }

  function changeActiveButtonType(index: number) {
    if (index === 0) {
      setTabsData(classData?.upcoming)
    } else {
      setTabsData(classData?.recorded)
    }

    setActiveButtonType(index)
  }

  const handleBatchChange = (e: any) => {
    setBatch(e.target.value)
  }

  return {
    auth,
    tabsData,
    activeButtonType,
    batch,
    loading,
    classData,
    cancelLoading,
    getBatchList,
    instructorList,
    getModulesList,
    getTracksList,
    selectAssigmentsQuestions,
    selectHomeworkQuestions,
    selectPracticalQuestions,
    selectWarmupQuestions,
    currentSelectAssignmentsQuestions,
    currentSelectHomeworkQuestions,
    currentSelectPracticalQuestions,
    currentSelectWarmupQuestions,
    currentSelectBatch,
    chapterNames,
    classLink,
    selectInstructor,
    selectModuleId,
    selectSessionTimestamp,
    track,
    selectTrack,
    videoId,
    videoSecret,
    notesLink,
    notesSummaryLink,
    setCancelLoading,
    handleBatchChange,
    getScheduledClasses,
    deleteScheduledClass,
    changeActiveButtonType,
    getBatches,
    getAllModules,
    getAllTracks,
    getInstructorsList,
  }
}
