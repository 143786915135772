import useToast from "core/hooks/useToast"
import { STR_COPIED, STR_SUCCESS } from "core/constants/strings"
import { useApp } from "core/context/app"

export default function HomeViewModel() {
  const { mentor } = useApp()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const referralCode = mentor?.mentor?.referralCode

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralCode)
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, STR_COPIED)
  }

  return {
    toast,
    referralCode,
    mentor,
    changeToastDetails,
    changeToastVisibility,
    handleCopyClick,
  }
}
