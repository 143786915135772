import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface GetContestResultUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export default class GetContestResult implements GetContestResultUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return await this.repository.getContestResult(auth, data)
  }
}
