import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface GetGoalSettingSessionAmountUpdateUseCase {
  invoke(auth: Auth, mentor_id: string, amount: number): Promise<any>
}

export default class GetGoalSettingSessionAmountUpdate implements GetGoalSettingSessionAmountUpdateUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string, amount: number): Promise<any> {
    return await this.repository.getGoalSettingSessionAmountUpdate(auth, mentor_id, amount)
  }
}
