import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import { useEffect } from "react"
import Avatar from "react-avatar"
import GeneralInfoSection from "./components/GeneralInfoSection"
import JourneySection from "./components/JourneySection"
import PlacementInfoSection from "./components/PlacementInfoSection"
import ReviewsSection from "./components/ReviewsSection"
import AddPlacementFormViewModel from "./EditStudentProfileViewModel"
export default function AddPlacementForm() {
  const {
    toast,
    changeToastVisibility,
    handleSubmit,
    handleLog,
    handleBack,
    fetchStudentDetails,
    name_,
    phone_,
    studentDetails,
    setStudentDetails,
    docId,
  } = AddPlacementFormViewModel()

  useEffect(() => {
    fetchStudentDetails()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="flex justify-between gap-6 items-start">
          <div>
            <h2 className="text-new-accent text-2xl font-bold">Student Profile : {name_}</h2>
            <p>You have access to {name_} profile.</p>
          </div>
          <div className="flex gap-2">
            <button
              className="px-4 flex items-center justify-center rounded-sm bg-gradient-to-r from-[#1900BE] to-[#4385FF] text-new-solid-white group hover:from-new-accent hover:to-new-accent"
              onClick={handleSubmit}
            >
              Save Changes
            </button>
            <Button className="h-fit w-fit" failure outlined onClick={() => handleBack()}>
              Go Back
            </Button>
          </div>
        </div>

        <div className="rounded-[2px] w-full shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)] p-6 flex justify-between items-center">
          <div className="flex gap-3">
            <Avatar
              className="shrink-0 rounded-sm object-cover"
              src={studentDetails.profilePicture}
              name={studentDetails.name}
              size="67"
            />
            <div className="text-new-neutral-dark flex flex-col justify-between">
              <p className="text-[18px] font-semibold">{name_}</p>
              <p className="text-[12px] font-medium">student</p>
              <p className="text-[#ADADAD] flex items-end justify-between text-[14px] font-normal">
                {studentDetails.email} | {phone_}
              </p>
            </div>
          </div>
          <div className="text-[#00C22B] pr-12">Batch : {studentDetails.placement_batch}</div>
        </div>

        <div className="flex flex-col gap-8 py-4">
          <GeneralInfoSection studentDetails={studentDetails} setStudentDetails={setStudentDetails} />
          <PlacementInfoSection studentDetails={studentDetails} setStudentDetails={setStudentDetails} />
          <ReviewsSection docId={docId} />
          <JourneySection docId={docId} />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
