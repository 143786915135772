import React from "react"
import MentorsViewModel from "./MentorsViewModel"
import { useParams } from "react-router-dom"
import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import MentorSessionCard from "./components/MentorSessionCard"
import Loader from "core/components/Loader"
import ButtonGroup from "core/components/ButtonGroup"
import GroupMentorSessionCard from "./components/GroupMentorSessionCard"
import CreateGroupSessionForm from "./components/CreateGroupSessionForm"
import Model from "core/components/Model"

export default function MentorSessionsView() {
  const {
    mentorSessions,
    loading,
    batch,
    month,
    getBatchList,
    groupMentorSession,
    toast,
    year,
    handleSearchByYear,
    setLoading,
    changeToastDetails,
    changeToastVisibility,
    getBatches,
    handleSearchByBatch,
    handleSearchByMonth,
    fetchMentorSessions,
    fetchGroupMentorSession,
  } = MentorsViewModel()

  const { id } = useParams()
  const [activeButtonType, setActiveButtonType] = React.useState<number>(0)
  const [updateCount, setUpdateCount] = React.useState(false)
  const [isCreateGroupSessionOpen, setIsCreateGroupSessionOpen] = React.useState<boolean>(false)
  function handleCreateGroupSession() {
    setIsCreateGroupSessionOpen(!isCreateGroupSessionOpen)
  }
  async function handleSearchSession() {
    setLoading(true)
    await fetchMentorSessions(id as string)
    await fetchGroupMentorSession(id as string)
    setLoading(false)
  }
  const mentor_key = id
  const MONTHARRAY: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const startYear = new Date().getFullYear()
  const endYear = 2018

  const YEARARRAY: string[] = []
  for (let year = startYear; year >= endYear; year--) {
    YEARARRAY.push(year.toString())
  }
  async function changeActiveButtonType(index: number) {
    const i = index
    setActiveButtonType(index)
  }

  React.useEffect(() => {
    fetchMentorSessions(id as string)
    getBatches()
    fetchGroupMentorSession(id as string)
  }, [id, updateCount])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="flex items-end gap-4">
          <StandaredSelect
            required
            id="Batch"
            className="w-[209px]"
            options={getBatchList}
            placeholder="Select Batch"
            onChange={handleSearchByBatch}
            value={batch}
          />
          <StandaredSelect
            required
            id="Month"
            className="w-[209px]"
            options={MONTHARRAY}
            placeholder="Select Month"
            onChange={handleSearchByMonth}
            value={month}
          />

          <StandaredSelect
            required
            id="Year"
            className="w-[209px]"
            options={YEARARRAY}
            placeholder="Select Year"
            onChange={handleSearchByYear}
            value={year}
          />
          <Button loading={loading} className="h-[37px] w-fit" onClick={handleSearchSession}>
            Search
          </Button>
        </div>
        <div className="mt-10 px-5">
          <ButtonGroup
            buttons={["Mentor Sessions", "Group Mentor Sessions"]}
            active={activeButtonType}
            onChange={changeActiveButtonType}
          />
        </div>
        {activeButtonType === 0 ? (
          isEmpty(mentorSessions) ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mx-4 my-4 flex flex-col space-y-4">
              <div className="space-y-4">
                {!isEmpty(mentorSessions?.mentor_sessions) ? (
                  mentorSessions?.mentor_sessions?.map((data: any, i: number) => (
                    <MentorSessionCard
                      changeToastVisibility={changeToastVisibility}
                      changeToastDetails={changeToastDetails}
                      updateCount={updateCount}
                      setUpdateCount={setUpdateCount}
                      key={i}
                      data={data}
                    />
                  ))
                ) : (
                  <div>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
            </div>
          )
        ) : activeButtonType === 1 ? (
          isEmpty(groupMentorSession) ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mx-4 my-4 flex flex-col space-y-4">
              <div className="space-y-4">
                <Button className="h-[37px] w-fit" onClick={handleCreateGroupSession}>
                  Add Group Session
                </Button>
                {!isEmpty(groupMentorSession?.group_mentor_sessions) ? (
                  groupMentorSession?.group_mentor_sessions?.map((data: any, i: number) => (
                    <GroupMentorSessionCard
                      changeToastVisibility={changeToastVisibility}
                      changeToastDetails={changeToastDetails}
                      updateCount={updateCount}
                      setUpdateCount={setUpdateCount}
                      key={i}
                      data={data}
                    />
                  ))
                ) : (
                  <div>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      <Model isOpen={isCreateGroupSessionOpen} onClose={handleCreateGroupSession}>
        <CreateGroupSessionForm updateCount={updateCount} setUpdateCount={setUpdateCount} />
      </Model>
    </DashboardLayout>
  )
}
