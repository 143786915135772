import { Auth } from "domain/model/Auth"
import { FeedbackPage } from "domain/model/NewFeedback"
import { FeedbackRepository } from "domain/repository/Student/NewFeedbackRepository"

export interface SaveFeedbackUseCase {
  invoke(auth: Auth, pages: Record<string, FeedbackPage[]>): Promise<void>
}

export class SaveFeedback implements SaveFeedbackUseCase {
  private readonly feedbackRepository: FeedbackRepository

  constructor(feedbackRepository: FeedbackRepository) {
    this.feedbackRepository = feedbackRepository
  }

  async invoke(auth: Auth, pages: Record<string, FeedbackPage[]>): Promise<void> {
    await this.feedbackRepository.saveFeedback(auth, pages)
  }
}
