import { Auth } from "domain/model/Auth"
import { CourseRepository } from "domain/repository/Student/CourseRepository"

export interface SubmitClassFeedbackNewUseCase {
  invoke(auth: Auth, data: any): Promise<boolean>
}

export class SubmitClassFeedbackNew implements SubmitClassFeedbackNewUseCase {
  private repository: CourseRepository

  constructor(repository: CourseRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any) {
    return this.repository.submitClassFeedbackNew(auth, data)
  }
}
