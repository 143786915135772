import DashboardLayout from "core/layouts/DashboardLayout"
import ReferEarnTable from "./components/ReferEarnTable"
import ReferandEarnViewModel from "./ReferandEarnViewModel"
import { useEffect } from "react"
import Toast from "core/components/Toast"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { Button } from "core/components/v2/Button"

const ReferandEarnView = () => {
  const {
    courseOptions,
    toast,
    changeToastVisibility,
    tableHeaders,
    referralData,
    nextStudentsLoading,
    getTop100ReferandEarn,
    updateReferandEarn,
    getFilterReferandEarn,
    fromDateValue,
    toDateValue,
    handleFromDateChange,
    handleToDateChange,
    DownloadCSV,
  } = ReferandEarnViewModel()

  useEffect(() => {
    if (true) getTop100ReferandEarn()
  }, [])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="flex gap-4">
          <Input
            id="search-name"
            placeholder="Referee Email"
            onChange={(e: any) => {
              getFilterReferandEarn({
                refereeEmail: e.target.value,
              })
            }}
            containerClass="w-60"
            search
          />
          <Input
            id="search-name"
            placeholder="Referrer Email"
            onChange={(e: any) => {
              getFilterReferandEarn({
                referrerEmail: e.target.value,
              })
            }}
            containerClass="w-60"
            search
          />
          <div className="w-60">
            <StandaredSelect
              id="search-batch"
              containerClass="w-60"
              options={courseOptions}
              placeholder="Select Referee Course"
              onChange={(e: any) => {
                getFilterReferandEarn({
                  courseType: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <Input
            type="date"
            placeholder="Refer From Date"
            onChange={handleFromDateChange}
            // value={fromDateValue}
            className=""
          />
          <Input
            type="date"
            placeholder="Refer To Date"
            onChange={handleToDateChange}
            // value={toDateValue}
            className=""
          />
          <Button
            className="w-[100px] h-9"
            onClick={() => {
              if (fromDateValue && toDateValue)
                getFilterReferandEarn({
                  fromDate: fromDateValue,
                  toDate: toDateValue,
                })
            }}
          >
            Set Date
          </Button>
          <Button
            className="w-[150px] h-9"
            onClick={() => {
              DownloadCSV()
            }}
          >
            Download CSV
          </Button>
        </div>
        <ReferEarnTable
          tableHeaders={tableHeaders}
          referralData={referralData}
          updateReferandEarn={updateReferandEarn}
          loading={nextStudentsLoading}
        />
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}

export default ReferandEarnView
