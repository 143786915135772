import CloseIcon from "assets/svgs/placement/Close"
import { EyeIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { UploadResume } from "domain/useCase/Admin/Placement/UploadResume"
import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"

interface EditResumeCardProps {
  resumeStudentData: any
  onCancel: () => void
  handleResumeSave: (updatedResume: any, email: string) => void
}

const EditResumeCard: React.FC<EditResumeCardProps> = ({ resumeStudentData, onCancel, handleResumeSave }) => {
  const [resumeFile, setResumeFile] = useState<File | null>(null)
  const [docId, setDocId] = useState<string>("")
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const { auth } = useAuth()

  const UploadResumeUsecase = new UploadResume(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  const handleSave = async () => {
    if (!docId || !resumeFile) {
      alert("Unable to Upload Resume.")
      return
    }

    try {
      const response = await UploadResumeUsecase.invoke(auth, docId, resumeFile)
      if (response?.data) {
        handleResumeSave(response?.data, resumeStudentData?.general_information?.email)
        setIsSaved(true)
      }
    } catch (error) {
      console.error("Error in Uploading Resume", error)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    const pdfFile = acceptedFiles.find((file) => file.type === "application/pdf")
    if (pdfFile) {
      setResumeFile(pdfFile)
    } else {
      alert("Please upload a valid PDF file.")
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
  })

  useEffect(() => {
    setDocId(resumeStudentData?.general_information?.email)
  }, [resumeStudentData])

  return (
    <div className="bg-white shadow-md rounded-lg p-[24px] h-auto w-[400px] relative">
      <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onCancel}>
        <CloseIcon className="h-6 w-6" />
      </button>
      <p className="text-[16px] font-semibold text-[#333333]">
        {resumeStudentData?.resume_link ? "Update " : "Upload "} Resume
      </p>
      <p className="pt-1 text-[14px] text-[#646464]">Profile : {resumeStudentData?.general_information?.name}</p>
      <div className="py-6 flex flex-col gap-4">
        {resumeStudentData?.resume_link && (
          <label className="flex flex-col gap-1">
            <span className="font-semibold text-[#333333]">Current Resume URL</span>
            <div className="w-full flex items-center justify-between gap-3 pr-3">
              <input
                type="url"
                name="url"
                value={resumeStudentData?.resume_link}
                readOnly
                className="w-full border-[0.5px] border-[#D3D3D3] rounded-md p-2 text-[#646464]"
                placeholder="Type here"
              />
              <a href={resumeStudentData.resume_link} target="_blank" rel="noopener noreferrer">
                <EyeIcon className="h-6 w-6" />
              </a>
            </div>
          </label>
        )}
        <label className="block mt-4 text-[#333333] font-semibold">Upload Resume</label>
        <div
          {...getRootProps()}
          className={`border-dashed border-2 ${
            isDragActive ? "border-green-500 bg-green-100" : "border-gray-300"
          } p-4 rounded flex flex-col items-center justify-center cursor-pointer`}
        >
          <input {...getInputProps()} />
          {resumeFile ? (
            <div className="text-green-500 text-sm font-semibold">
              <p>✔ Resume Uploaded</p>
              <p>
                {resumeFile.name} or{" "}
                <span className="text-blue-500 underline cursor-pointer" onClick={() => setResumeFile(null)}>
                  Upload new PDF file
                </span>
              </p>
            </div>
          ) : (
            <div className="text-gray-500 text-sm text-center">
              <p>{isDragActive ? "Drop your PDF here..." : "Drag and drop a PDF file or"}</p>
              <span className="text-blue-500 underline mt-1">Browse PDF files</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-end gap-4 mt-16">
        <button className="border-[0.5px] border-[#D3D3D3] text-[#333333] rounded-md px-4 py-2" onClick={onCancel}>
          Cancel
        </button>
        <button className="bg-[#001665] text-white rounded-md px-4 py-2" onClick={handleSave}>
          {isSaved ? "Saved" : "Save"}
        </button>
      </div>
    </div>
  )
}

export default EditResumeCard
