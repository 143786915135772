import React from "react"
import { UserSignUpAPIDataSourceImpl } from "data/API/Admin/UserSignUpAPIDataSourceImpl"
import { UserSignUpRepositoryImpl } from "data/repository/Admin/UserSignUpRepositoryImpl"
import { Signup } from "domain/useCase/Admin/UserSignup/Signup"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS, STR_USER_CREATED } from "core/constants/strings"
import { Country, State } from "country-state-city"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { GetOnboardingManager } from "domain/useCase/Admin/StudentDashboard/GetOnboardingManagerList"
import { useAuth } from "core/context/auth"
import { capitalize, genError } from "core/utils/string"
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"
import { isEmpty } from "core/utils/misc"
import { MentorDashboardAPIDataSourceImpl } from "data/API/Admin/MentorDashboardAPIDataSourceImpl"
import { GetMentorsDetailList } from "domain/useCase/Admin/MentorDashboard/GetMentorsDetailList"
import { MentorDashboardRepositoryImpl } from "data/repository/Admin/MentorDashboardRepositoryImpl"

export default function AddStudentViewModel() {
  const [courseType, setCourseType] = React.useState<string>("")
  const [programType, setProgramType] = React.useState<string>("")
  const [email, setEmail] = React.useState<string>("")
  const [batch, setBatch] = React.useState<any>("")
  const [role, setRole] = React.useState<string>("")
  const [fullName, setFullName] = React.useState<string>("")
  const [phoneNumber, setPhoneNumber] = React.useState<string>("")
  const [yearsOfExperience, setYearsOfExperience] = React.useState<string>("")
  const [bandwidth, setBandwidth] = React.useState<string>("0")
  const [fees, setFees] = React.useState<string>("")
  const [standardDiscount, setStandardDiscount] = React.useState<string>("")
  const [seat_booking_amount, setSeat_booking_amount] = React.useState<string>("")
  const [seat_booking_received_date, setSeat_booking_received_date] = React.useState<any>("")
  const [paymentId_of_seat_booking, setPaymentId_of_seat_booking] = React.useState<string>("")
  const [payment_submethod, setPayment_submethod] = React.useState<string>("")
  const [loading, setLoading] = React.useState<boolean>(false)
  const [batches, setBatches] = React.useState<any>([])
  const [walkThroughDate, setWalkThroughDate] = React.useState<any>()
  const [timeValue, setTimeValue] = React.useState<any>()
  const [timeValue1, setTimeValue1] = React.useState<any>()
  const [isAlumni, setIsAlumni] = React.useState<any>()
  const [isMentor, setIsMentor] = React.useState<any>()
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false)
  const [onboardingManagersList, setOnboardingManagersList] = React.useState<any>([])
  const [mentorList, setMentorList] = React.useState<any>([])
  const [onboardingManagerId, setOnboardingManagerId] = React.useState<any>("")
  const [milestoneSessionMentor, setMilestoneSessionMentor] = React.useState<any>("")
  const [country, setCountry] = React.useState("")
  const [state, setState] = React.useState("")
  const [stateOptions, setStateOptions] = React.useState<any[]>([])
  const [team, setTeam] = React.useState("")

  const { auth, user } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const PAYMENT_SUBMETHOD_OPTIONS = ["Razorpay", "CC Avenue", "Bank Transfer"]

  const countryOptions = Country.getAllCountries().map((country: any) => ({
    value: country.name + " " + country.isoCode,
    label: country.name,
  }))

  let programOptions = [
    "Accelerator",
    "Transformer",
    "Evolve",
    "Data Science",
    "Data Analytics",
    "Data Engineering",
  ].map((item) => ({
    value: item,
    label: item,
  }))

  let teamOptions = ["Product Tech", "Marketing Tech"].map((item) => ({
    value: item,
    label: item,
  }))
  let filterOptions

  if (role === "mentor") {
    filterOptions = ["SDE", "DSML", "Evolve", "SDE+DSML"].map((item) => ({
      value: item,
      label: item,
    }))
  } else {
    filterOptions = ["student", "working professional"].map((item) => ({
      value: item,
      label: capitalize(item),
    }))
  }

  const roleOptions = ["user", "mentor", "developer", ...(user.is_superadmin ? ["admin"] : [])].map((item) => ({
    value: item,
    label: capitalize(item),
  }))

  const createSignUp = new Signup(new UserSignUpRepositoryImpl(new UserSignUpAPIDataSourceImpl()))
  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const getOnboardingManagerListUseCase = new GetOnboardingManager(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const getMentorsDetailListUseCase = new GetMentorsDetailList(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const fetchBatches = async () => {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response.success) {
      return
    }

    setBatches(response?.data?.map((b: any) => ({ value: b, label: b })))
  }

  async function getOnboardingManagerList() {
    try {
      const response = await getOnboardingManagerListUseCase.invoke(auth)
      if (!response) {
        changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch onboarding managers"))
        changeToastVisibility(true)
        return []
      }

      const onboardingManagers = response.onboarding_team_list
      type Manager = {
        name: string
        designation: string
        linkedin: string
        phone: string
        profile_pic: string
        isLead: boolean
        isDisabled: boolean
      }

      const onboardingManagersList: { value: string; label: string }[] = Object.entries(onboardingManagers).reduce(
        (result, [email, manager]) => {
          const m = manager as Manager
          if (!m.isDisabled) {
            result.push({ value: email, label: m.name })

            if (m.isLead && !result.some((entry) => entry.label === "Other")) {
              result.push({ value: email, label: "Other" })
            }
          }

          return result
        },
        [] as { value: string; label: string }[]
      )
      setOnboardingManagersList(onboardingManagersList)
      return onboardingManagersList
    } catch (error) {
      changeToastDetails(STR_FAILURE, "An error occurred while fetching onboarding managers")
      changeToastVisibility(true)
      return []
    }
  }

  async function getMentorsList(id: string) {
    const response = await getMentorsDetailListUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch all mentors"))
      changeToastVisibility(true)
      return
    }
    let mentors = response?.data
    let mentorList = mentors?.map((m: any) => ({
      value: m?.email,
      label: m?.name,
    }))
    setMentorList(mentorList)
  }

  const validSignupData = () => {
    if (!isValidPhoneNumber(phoneNumber) || isEmpty(role) || isEmpty(email)) {
      return false
    }

    return true
  }

  async function signup(e: React.FormEvent<HTMLFormElement>) {
    console.log(team, "team hai")

    e.preventDefault()

    if (!validSignupData()) {
      changeToastDetails(STR_FAILURE, "Please fill all the fields with correct value.")
      changeToastVisibility(true)
      return
    }

    if (!email.endsWith("@bosscoderacademy.com") && role === "admin") {
      changeToastDetails(STR_FAILURE, "Please enter a valid email address of bosscoder.")
      changeToastVisibility(true)
      return
    }

    const parsedPhone = parsePhoneNumber(phoneNumber)
    const countryCode = "+" + (parsedPhone?.countryCallingCode ?? "")
    const phoneWithoutCode = parsedPhone?.nationalNumber ?? ""
    setLoading(true)
    const response = await createSignUp.invoke(
      {
        courseType,
        programType,
        email,
        batch,
        onboardingManagerId,
        milestoneSessionMentor,
        role,
        fullName,
        phoneNumber: phoneWithoutCode,
        countryCode,
        country: country,
        state,
        yearsOfExperience,
        fees,
        walkThroughDate,
        isMentor,
        isAlumni,
        bandwidth,
        standardDiscount,
        seat_booking_amount,
        paymentId_of_seat_booking,
        seat_booking_received_date,
        payment_submethod,
        team,
        isSuperAdmin,
      },
      auth
    )

    setLoading(false)
    console.log("idhar aaya")
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to create user"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, STR_USER_CREATED)
    changeToastVisibility(true)
  }

  function emailChange(e: any) {
    setEmail(e.target.value)
  }
  function standardDiscountChange(e: any) {
    setStandardDiscount(e.target.value)
  }
  function seatBookingAmountChange(e: any) {
    setSeat_booking_amount(e.target.value)
  }
  function payment_submethodChange(e: any) {
    setPayment_submethod(e.target.value)
  }

  function fullNameChange(e: any) {
    setFullName(e.target.value)
  }
  function paymentId_of_seat_bookingChange(e: any) {
    setPaymentId_of_seat_booking(e.target.value)
  }
  function Seat_booking_received_dateChange(e: any) {
    const date = e.target.value
    const timestamp = new Date(date).getTime() / 1000 // Convert date string to Unix timestamp
    setSeat_booking_received_date(timestamp)
    setTimeValue1(date)
  }

  function walkThroughDateChange(e: any) {
    const time = e.target.value as string
    const timestamp = new Date(time).getTime() / 1000
    setTimeValue(e.target.value)
    setWalkThroughDate(timestamp)
  }

  function coursetypeOnChange(e: any) {
    setCourseType(e.target.value)
    if (e.target.value === "student") {
      setYearsOfExperience("0")
    }
  }

  function teamOnChange(e: any) {
    setTeam(e.target.value)
  }
  function programtypeOnChange(e: any) {
    setProgramType(e.target.value)
  }

  function alumniOnChange(e: any) {
    setIsAlumni(e.target.value === "true")
  }

  function mentorOnChange(e: any) {
    setIsMentor(e.target.value === "true")
  }

  function yearsOfExperienceChange(e: any) {
    const newValue = parseInt(e.target.value, 10)
    if (!isNaN(newValue) && newValue >= 0) {
      setYearsOfExperience(newValue.toString())
    }
  }
  function bandwidthChange(e: any) {
    const newValue = parseInt(e.target.value, 10)
    // if (!isNaN(newValue) && newValue >= 0) {
    if (e.target.value < 0) {
      setBandwidth("")
    } else {
      setBandwidth(newValue.toString())
    }
    // }
  }

  function handlePhoneNumber(e: any) {
    setPhoneNumber(e ?? "")
  }

  function feesChange(e: any) {
    setFees(e.target.value)
  }

  function batchChange(e: any) {
    setBatch(e.target.value)
  }

  function roleChange(e: any) {
    setRole(e.target.value)
  }
  function onSelectChangeOnboardingManager(e: any) {
    setOnboardingManagerId(e.target.value)
  }

  function isSuperAdminOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setIsSuperAdmin(e.target.value === "true")
  }
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(e.target.value)
    setState("")

    const newSoptions = State.getStatesOfCountry(e.target.value.slice(-2)).map((state: any) => ({
      value: state.name,
      label: state.name,
    }))

    setStateOptions(newSoptions)
  }

  function handlestateChange(e: any) {
    setState(e.target.value)
  }
  function onSelectMilestoneSessionMentor(e: any) {
    setMilestoneSessionMentor(e.target.value)
  }

  return {
    // id,
    courseType,
    programType,
    batch,
    role,
    email,
    onboardingManagerId,
    milestoneSessionMentor,
    fullName,
    phoneNumber,
    yearsOfExperience,
    fees,
    filterOptions,
    programOptions,
    roleOptions,
    batches,
    onboardingManagersList,
    mentorList,
    loading,
    toast,
    timeValue,
    walkThroughDate,
    bandwidth,
    standardDiscount,
    seat_booking_amount,
    paymentId_of_seat_booking,
    timeValue1,

    isAlumni,
    seat_booking_received_date,
    payment_submethod,
    isMentor,
    country,
    state,
    countryOptions,
    stateOptions,
    PAYMENT_SUBMETHOD_OPTIONS,
    team,
    teamOptions,
    teamOnChange,
    onSelectMilestoneSessionMentor,
    paymentId_of_seat_bookingChange,
    emailChange,
    Seat_booking_received_dateChange,
    batchChange,
    roleChange,
    walkThroughDateChange,
    fullNameChange,
    onSelectChangeOnboardingManager,
    handlePhoneNumber,
    yearsOfExperienceChange,
    feesChange,
    coursetypeOnChange,
    programtypeOnChange,
    signup,
    changeToastVisibility,
    fetchBatches,
    getOnboardingManagerList,
    getMentorsList,
    alumniOnChange,
    bandwidthChange,
    mentorOnChange,
    standardDiscountChange,
    seatBookingAmountChange,
    setSeat_booking_received_date,
    payment_submethodChange,
    handleCountryChange,
    handlestateChange,
    isSuperAdminOnChange,
  }
}
