import { Auth } from "domain/model/Auth"
import SheetsRepository from "domain/repository/Admin/SheetsRepository"

export interface GetInstructorInvoicesUseCase {
  invoke(auth: Auth, year: string, month: string): Promise<any>
}

export class GetInstructorInvoices implements GetInstructorInvoicesUseCase {
  private repository: SheetsRepository

  constructor(repository: SheetsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, year: string, month: string): Promise<any> {
    return this.repository.getInstructorInvoices(auth, year, month)
  }
}
