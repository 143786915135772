import { useEffect } from "react"
import { STR_CALENDAR_SUBTITLE, STR_SCHEDULE } from "core/constants/strings"
import useScheduleViewModel from "./ScheduleViewModel"
import Toast from "core/components/Toast"
import ScheduleCalendar from "./components/ScheduleCalendar"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { cn } from "core/lib/utils"

export default function ScheduleView() {
  const { student, toast, weeklySessions, epochTimestamp, maxSessions, fetchSessionsDetails, changeToastVisibility } =
    useScheduleViewModel()

  useEffect(() => {
    fetchSessionsDetails()
  }, [epochTimestamp])

  return (
    <DashboardLayoutv2 isLoading={!weeklySessions || Object.keys(weeklySessions).length === 0}>
      <div className={cn("space-y-4 p-6 text-new-neutral", student?.is_batch_paused && "opacity-20")}>
        <div>
          <h2 className="text-new-accent">{STR_SCHEDULE}</h2>
          <p>{STR_CALENDAR_SUBTITLE}</p>
        </div>
        <ScheduleCalendar
          redeem_coins={student?.redeem_coins}
          weeklySessions={weeklySessions}
          maxSessions={maxSessions}
        />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
