import ActiveBatchDataSource from "data/dataSource/Admin/ActiveBatchDataSource"
import { ActiveBatchRepository } from "domain/repository/Admin/ActiveBatchRepository"
import { Auth } from "domain/model/Auth"

export class ActiveBatchRepositoryImpl implements ActiveBatchRepository {
  private dataSource: ActiveBatchDataSource

  constructor(dataSource: ActiveBatchDataSource) {
    this.dataSource = dataSource
  }

  async getActiveBatches(auth: Auth): Promise<any> {
    return this.dataSource.getActiveBatches(auth)
  }
}
