// studentplacementdashboard
import {
  API_GET_JOURNEYS_BY_ID,
  API_GET_PLACEMENT_STUDENT_LIST,
  API_GET_REVIEWS_BY_ID,
  API_GET_STUDENT_DETAILS,
  API_PUT_PLACEMENT_STATUS,
  API_PUT_STUDENT_DETAILS,
  API_UPDATE_REVIEWS_BY_ID,
  API_UPLOAD_RESUME,
  API_V3_ADMIN,
  STUDENT_DASHBOARD,
} from "core/constants/strings"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import PlacementDashboardDataSource from "data/dataSource/Admin/PlacementDashboardDataSource"
import { Auth } from "domain/model/Auth"
import { AllStudentsReqType } from "domain/model/PlacementDashboard"

const server = new Server()

export class PlacementDashboardAPIDataSourceImpl implements PlacementDashboardDataSource {
  async getStudentList(auth: Auth, data: AllStudentsReqType): Promise<any> {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_GET_PLACEMENT_STUDENT_LIST),
        {
          // id_token: auth?.id_token,
          queryTab: data?.queryTab,
          queryCohortPlacementTest: data?.queryCohortPlacementTest,
          queryEmail: data?.queryEmail,
          querySkills: data?.querySkills,
          queryBatch: data?.queryBatch,
          queryProgram: data?.queryProgram,
          queryYoe: data?.queryYoe,
          queryRating: data?.queryRating,
          queryNoticePeriod: data?.queryNoticePeriod,
          queryStatus: data?.queryStatus,
          queryMockStatus: data?.queryMockStatus,
          queryIsPlacementDone: data?.queryIsPlacementDone,
          queryResumeStatus: data?.queryResumeStatus,
          queryLinkedInStatus: data.queryLinkedInStatus,
          pageIndex: data?.pageIndex,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentDetails(auth: Auth, docId: String): Promise<any> {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_GET_STUDENT_DETAILS),
        {
          docId: docId,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getReviewsById(auth: Auth, docId: string): Promise<any> {
    try {
      const response = await server.post(pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_GET_REVIEWS_BY_ID), docId, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async editReview(auth: Auth, docId: string, updatedReview: any): Promise<any> {
    try {
      const response = await server.put(
        pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_UPDATE_REVIEWS_BY_ID),
        { docId, updatedReview: updatedReview },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getJourneysById(auth: Auth, docId: string): Promise<any> {
    try {
      const response = await server.post(pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_GET_JOURNEYS_BY_ID), docId, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async editStudentDetails(auth: Auth, docId: string, updatedDetails: any): Promise<any> {
    try {
      const response = await server.put(
        pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_PUT_STUDENT_DETAILS),
        { docId, updatedDetails: updatedDetails },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async uploadResume(auth: Auth, docId: string, resumeFile: any): Promise<any> {
    try {
      const formData = new FormData()
      formData.append("resumeFile", resumeFile)
      formData.append("docId", docId)
      const response = await server.postFile(pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_UPLOAD_RESUME), formData, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async editPlacementStatus(auth: Auth, docId: string, statusType: string, newStatus: string | boolean): Promise<any> {
    try {
      console.log("PlacementDashboardAPIDataSourceImpl", docId, statusType, newStatus)
      const response = await server.put(
        pn(API_V3_ADMIN, STUDENT_DASHBOARD, API_PUT_PLACEMENT_STATUS),
        { docId, statusType, newStatus },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
