import { Auth } from "domain/model/Auth"
import { FeedbackRepository } from "domain/repository/Student/NewFeedbackRepository"

export interface GetJoiningDateUseCase {
  invoke(auth: Auth): Promise<string>
}

export class GetJoiningDate implements GetJoiningDateUseCase {
  private readonly feedbackRepository: FeedbackRepository

  constructor(feedbackRepository: FeedbackRepository) {
    this.feedbackRepository = feedbackRepository
  }

  async invoke(auth: Auth): Promise<string> {
    const studentDetails = await this.feedbackRepository.getJoiningDate(auth)

    if (!studentDetails || !studentDetails.joining_date) {
      return ""
    }

    return studentDetails.joining_date
  }
}
