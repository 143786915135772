import { Auth } from "domain/model/Auth"
import { ResetPassword } from "domain/model/ResetPassword"
import { AuthRepository } from "domain/repository/Admin/AuthRepository"

export interface ResetPasswordByStudentUseCase {
  invoke(auth: Auth, user: ResetPassword): Promise<any>
}

export class ResetPasswordByStudent implements ResetPasswordByStudentUseCase {
  private repository: AuthRepository

  constructor(repository: AuthRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, user: ResetPassword) {
    return this.repository.resetPasswordByStudent(auth, user)
  }
}
