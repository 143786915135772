import PageLoader from "core/components/PageLoader"
import { useAuth } from "core/context/auth"
import { pathList, redirectPath } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

export default function ProtectedRoute() {
  const { pathname } = useLocation()

  const { user, loading } = useAuth()

  useEffect(() => {
    if (!isEmpty(user) && ["admin", "mentor"].includes(user.role)) {
      const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
      if (launcherFrame) {
        launcherFrame.style.display = "none"
      }
    }
  }, [user])

  if (isEmpty(user)) {
    if (loading) return <PageLoader />
    return <Navigate to="/login" />
  }

  const paths = pathList(pathname)

  if (paths.length === 0) {
    return <Navigate to={redirectPath(user.role)} />
  }

  const rootPath = paths[0]
  const subRootPath = paths[1]

  const studentRedirect = user.role === "user" && (rootPath === "admin" || (rootPath === "mentor" && paths.length > 1))
  const adminRedirect = user.role === "admin" && user.is_superadmin === false && rootPath !== "admin"
  const mentorRedirect = user.role === "mentor" && rootPath !== "mentor"
  const superAdminRedirect =
    user.role === "admin" && user.is_superadmin === true && rootPath !== "admin" && subRootPath !== "super"

  if (studentRedirect || adminRedirect || mentorRedirect || superAdminRedirect) {
    return <Navigate to={redirectPath(user.role)} />
  }

  return <Outlet />
}
