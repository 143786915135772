import { Auth } from "domain/model/Auth"
import ContestResultRepository from "domain/repository/Student/ContestResultRepository"

export interface ExamResultUseCase {
  invoke(auth: Auth, contestId: any): Promise<any>
}

export default class ExamResult implements ExamResultUseCase {
  private repository: ContestResultRepository
  constructor(repository: ContestResultRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contest_id: string): Promise<any> {
    return await this.repository.getExamResult(auth, contest_id)
  }
}
