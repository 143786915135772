import React, { useState, useRef } from "react"
import { useAuth } from "core/context/auth"
import { PaymentInvoiceAPIDataSourceImpl } from "data/API/Admin/PaymentInvoiceAPIDataSourceImpl"
import { PaymentInvoicesImpl } from "data/repository/Admin/PaymentInvoiceImpl"
import { GetInvoices } from "domain/useCase/Admin/PaymentInvoices/getInvoices"
import { UpdateInvoice } from "domain/useCase/Admin/PaymentInvoices/updateInvoice"
import { PaymentInvoice } from "domain/model/PaymentInvoice"
import { GetInvoiceByFilterSearch } from "domain/useCase/Admin/PaymentInvoices/getInvoiceByFilterSearch"
import useToast from "core/hooks/useToast"
import { isEmpty } from "core/utils/misc"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function PaymentInvoiceViewModel() {
  const [showPopup, setShowPopup] = useState(false)
  const [paymentInvoices, setPaymentInvoices] = useState<PaymentInvoice[]>([])
  const [editable, setEditable] = useState(false)
  const [updateData, setUpdateData] = useState<PaymentInvoice>({} as PaymentInvoice)
  const [viewIdx, setViewIdx] = useState<number>(0)
  const [searchByEmail, setSearchByEmail] = useState<string>("")
  const [statusType, setStatusType] = useState<string>("")
  const [fromDate, setFromDate] = useState<string>("")
  const [toDate, setToDate] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [invoicesPerPage, setInvoicesPerPage] = useState<number>(20)
  const [totalInvoices, setTotalInvoices] = useState<number>(0)
  const [previousApi, setPreviousApi] = useState("")
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { auth } = useAuth()
  const emailInputRef = React.useRef<HTMLInputElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [filtering, setFiltering] = useState(false)

  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [emailId, setEmailId] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState(false)
  // Variable mapping section
  const tableHead = [
    "Sr. No.",
    "Student Id",
    "Batch",
    "Inst. Name",
    "Status",
    "Amount",
    "Date of Payment",
    "Invoice ID",
    "Details",
    "Verified By Ops",
    "Received date (Bank)",
    "Payment_method",
    "Verified by Accounts",
  ]
  const Keys = {
    student_name: "Student Name",
    email_id: "Email Id",
    phone_number: "Phone Number",
    amount: "Amount",
    status: "Status",
    id: "Installment ID",
    invoice_id: "Invoice ID",
    refNo: "Ref No.",
    date_time: "Date of Payment",
    due_date: "Due Date",
    payment_method: "Payment Method",
    payment_submethod: "Payment Sub-Method",
    state: "Party State",
    GST: "GST Number",
    address: "Address",
  }
  const getMonth: any = {
    "1": "January",
    "2": "Febuary",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  }
  const disabledKeys = [
    "id",
    "email_id",
    "phone_number",
    "refNo",
    "due_date",
    "student_name",
    "phone_number",
    "status",
    "transaction_id",
    "payment_method",
    "date_time",
    "payment_type",
    "amount",
    "invoice_id",
  ]
  const SelectList = ["Created", "Paid", "Refund"]

  // UseCase  Initialization

  const getInvoicesUseCase = new GetInvoices(new PaymentInvoicesImpl(new PaymentInvoiceAPIDataSourceImpl()))

  const updateInvoiceUseCase = new UpdateInvoice(new PaymentInvoicesImpl(new PaymentInvoiceAPIDataSourceImpl()))

  const getInvoiceByFilterSearchUseCase = new GetInvoiceByFilterSearch(
    new PaymentInvoicesImpl(new PaymentInvoiceAPIDataSourceImpl())
  )

  // Function event handlers
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleInvoiceLimitChange = (e: any) => {
    setInvoicesPerPage(parseInt(e.target.value))
  }

  const handleStatusTypeChange = (e: any) => {
    if (e.target.value === "Status") {
      setStatusType("")
    } else {
      setStatusType(e.target.value)
      setFiltering(true)
    }
    setCurrentPage(1)
  }

  const handleFromDateChange = (e: any) => {
    setFromDate(e.target.value)
    setCurrentPage(1)
    setInvoicesPerPage(20)
    setFiltering(true)
  }

  const handleToDateChange = (e: any) => {
    setToDate(e.target.value)
    setCurrentPage(1)
    setInvoicesPerPage(20)
    setFiltering(true)
  }

  const handleViewBtnClick = (index: any) => {
    setShowPopup((prevState) => !prevState)
    setViewIdx(index)
  }

  const handleCloseBtnClick = () => {
    setShowPopup((prevState) => !prevState)
    setEditable(false)
  }

  const handleUpdateClick = () => {
    setEditable((prev) => !prev)
  }

  const handleChange = () => {
    setEditable(!editable)
  }

  const handleSearchByEmail = (e: any) => {
    if (e.target.value) {
      setSearchByEmail(e.target.value)
      setCurrentPage(1)
      setInvoicesPerPage(20)
      setFiltering(true)
    } else setSearchByEmail("")
  }

  const handleClearFilter = () => {
    setSearchByEmail("")
    setStatusType("")
    setFromDate("")
    setToDate("")
    setFiltering(false)
    setInvoicesPerPage(20)
  }

  const handleCheckboxChange = async (invoiceId: string) => {
    const invoiceIndex = paymentInvoices.findIndex((invoice) => invoice.invoice_id === invoiceId)

    if (invoiceIndex === -1) {
      console.error("Invoice not found")
      return
    }

    const updatedInvoices = [...paymentInvoices]
    const newVerifiedByOpsState = !updatedInvoices[invoiceIndex].verified_by_ops
    const updatedInvoice: PaymentInvoice = {
      ...updatedInvoices[invoiceIndex],
      verified_by_ops: newVerifiedByOpsState,
    }
    setPaymentInvoices((prevData) => {
      const newData = [...prevData]
      newData[invoiceIndex] = updatedInvoice
      return newData
    })

    changeToastDetails(STR_SUCCESS, "Invoice updated successfully.")
    changeToastVisibility(true)

    const updateInvoiceUseCase = new UpdateInvoice(new PaymentInvoicesImpl(new PaymentInvoiceAPIDataSourceImpl()))

    try {
      await updateInvoiceUseCase.invoke(auth, updatedInvoice, updatedInvoices[invoiceIndex])
    } catch (error) {
      console.error("Error updating invoice:", error)
      setPaymentInvoices((prevData) => {
        const revertedInvoices = [...prevData]
        revertedInvoices[invoiceIndex] = {
          ...revertedInvoices[invoiceIndex],
          verified_by_ops: !newVerifiedByOpsState,
        }
        return revertedInvoices
      })

      changeToastDetails(STR_FAILURE, "Error updating invoice.")
      changeToastVisibility(true)
    }
  }

  const handleDateChange = async (invoiceId: string, selectedDate: string) => {
    const invoiceIndex = paymentInvoices.findIndex((invoice) => invoice.invoice_id === invoiceId)

    if (invoiceIndex === -1) {
      console.error("Invoice not found")
      return
    }

    setPaymentInvoices((prevInvoices) => {
      const updatedInvoices = [...prevInvoices]
      updatedInvoices[invoiceIndex] = {
        ...updatedInvoices[invoiceIndex],
        date_field_bank: selectedDate,
      }
      return updatedInvoices
    })

    changeToastDetails(STR_SUCCESS, "Invoice date updated successfully.")
    changeToastVisibility(true)

    const updateInvoiceUseCase = new UpdateInvoice(new PaymentInvoicesImpl(new PaymentInvoiceAPIDataSourceImpl()))

    try {
      const updatedInvoice = {
        ...paymentInvoices[invoiceIndex],
        date_field_bank: selectedDate,
      }
      await updateInvoiceUseCase.invoke(auth, updatedInvoice, updatedInvoice)
    } catch (error) {
      console.error("Error updating invoice date:", error)
      setPaymentInvoices((prevInvoices) => {
        const revertedInvoices = [...prevInvoices]
        revertedInvoices[invoiceIndex] = {
          ...revertedInvoices[invoiceIndex],
          date_field_bank: paymentInvoices[invoiceIndex].date_field_bank,
        }
        return revertedInvoices
      })
      changeToastDetails(STR_FAILURE, "Error updating invoice date.")
      changeToastVisibility(true)
    }
  }

  const handlePaymentMethodChange = (invoiceId: string, paymentMethod: string) => {
    setPaymentInvoices((prevInvoices) =>
      prevInvoices.map((invoice) =>
        invoice.invoice_id === invoiceId ? { ...invoice, new_payment_method_type: paymentMethod } : invoice
      )
    )
  }

  const handleSavePaymentMethod = async (invoiceId: string) => {
    const invoiceIndex = paymentInvoices.findIndex((invoice) => invoice.invoice_id === invoiceId)

    if (invoiceIndex === -1) {
      console.error("Invoice not found")
      return
    }

    const updatedInvoice = {
      ...paymentInvoices[invoiceIndex],
      new_payment_method_type: paymentInvoices[invoiceIndex].new_payment_method_type || "",
    }

    setPaymentInvoices((prevInvoices) => {
      const updatedInvoices = [...prevInvoices]
      updatedInvoices[invoiceIndex] = updatedInvoice
      return updatedInvoices
    })

    changeToastDetails(STR_SUCCESS, "Payment method updated successfully.")
    changeToastVisibility(true)

    try {
      await updateInvoiceUseCase.invoke(auth, updatedInvoice, paymentInvoices[invoiceIndex])
    } catch (error) {
      console.error("Error updating payment method:", error)
      setPaymentInvoices((prevInvoices) => {
        const revertedInvoices = [...prevInvoices]
        revertedInvoices[invoiceIndex] = {
          ...revertedInvoices[invoiceIndex],
          new_payment_method_type: paymentInvoices[invoiceIndex].new_payment_method_type,
        }
        return revertedInvoices
      })
      changeToastDetails(STR_FAILURE, "Error updating payment method.")
      changeToastVisibility(true)
    }
  }

  const handleVerificationChange = (invoiceId: string, status: string) => {
    setPaymentInvoices((prevInvoices) =>
      prevInvoices.map((invoice) =>
        invoice.invoice_id === invoiceId ? { ...invoice, new_verified_by_accounts: status } : invoice
      )
    )
  }

  const handleSaveVerificationStatus = async (invoiceId: string) => {
    const invoiceIndex = paymentInvoices.findIndex((invoice) => invoice.invoice_id === invoiceId)

    if (invoiceIndex === -1) {
      console.error("Invoice not found")
      return
    }

    const updatedInvoice = paymentInvoices[invoiceIndex]

    changeToastDetails(STR_SUCCESS, "Verification status updated successfully.")
    changeToastVisibility(true)

    try {
      const response = await updateInvoiceUseCase.invoke(auth, updatedInvoice, updatedInvoice)
    } catch (error) {
      console.error("Error updating verification status:", error)
      setPaymentInvoices((prevInvoices) =>
        prevInvoices.map((inv) =>
          inv.invoice_id === invoiceId
            ? { ...inv, new_verified_by_accounts: updatedInvoice.new_verified_by_accounts }
            : inv
        )
      )
      changeToastDetails(STR_FAILURE, "Error updating verification status.")
      changeToastVisibility(true)
    }
  }

  const handleDownloadClick = async (e: any) => {
    e.preventDefault()
    if (!fromDate || !toDate) {
      showToast(STR_FAILURE, "Please select both From and To dates")
      return
    }

    const fromTimestamp = new Date(fromDate).getTime()
    const toTimestamp = new Date(toDate).getTime()
    const currTimestamp = Date.now()
    const statustype = statusType

    if (fromTimestamp > toTimestamp) {
      showToast(STR_FAILURE, "From date cannot be greater than to date")
      return
    }

    if (fromTimestamp > currTimestamp || toTimestamp > currTimestamp) {
      showToast(STR_FAILURE, "Enter a valid date")
      return
    }

    try {
      setIsLoading(true)
      setIsDownloading(true)
      showToast(STR_SUCCESS, "CSV downloading in background. It will take time....")

      const response = await getInvoiceByFilterSearchUseCase.invoke({
        id_token: auth.id_token,
        from_date: fromDate,
        to_date: toDate,
        download_csv: true,
        status_type: statustype,
      })

      await downloadCSVFile(response, fromDate, toDate, statustype)
      removeToast()
      showToast(STR_SUCCESS, "CSV downloaded successfully")
    } catch (error) {
      removeToast()
      showToast(STR_FAILURE, "Error downloading file")
    } finally {
      setIsLoading(false)
      setIsDownloading(false)
    }
  }

  const downloadCSVFile = async (data: any, fromDate: string, toDate: string, statustype: string) => {
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `Filtered_Payment_Invoices_${fromDate}_to_${toDate}_${statustype}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const showToast = (status: string, message: string) => {
    changeToastVisibility(true)
    changeToastDetails(status, message)
  }

  const removeToast = () => {
    setTimeout(() => {
      changeToastVisibility(false)
    }, 2000)
  }

  async function getPaymentInvoices(currentPage: number, invoicesPerPage: number) {
    try {
      const paymentInvoicesData = await getInvoicesUseCase.invoke({
        id_token: auth.id_token,
        page: currentPage,
        pageSize: invoicesPerPage,
        email_id: searchByEmail,
        from_date: fromDate,
        to_date: toDate,
        status_type: statusType,
      })
      setPaymentInvoices(paymentInvoicesData?.data?.invoices)
      setTotalInvoices(paymentInvoicesData.data.total_records)
    } catch (error) {}
  }

  async function fetchStudentByFiltering(searchByEmail: string, fromDate: string, toDate: string, statusType: string) {
    const response = await getInvoiceByFilterSearchUseCase.invoke({
      id_token: auth.id_token,
      page: currentPage,
      pageSize: invoicesPerPage,
      email_id: searchByEmail,
      from_date: fromDate,
      to_date: toDate,
      status_type: statusType,
    })
    if (!response?.success) {
      setPaymentInvoices([])
      setTotalInvoices(0)
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    if (!isEmpty(response?.data?.invoices)) {
      setPaymentInvoices(response?.data?.invoices)
      setTotalInvoices(response?.data?.total_filtered_records)
    } else setPaymentInvoices([])
  }

  // handlers
  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    changeToastDetails(STR_SUCCESS, "Copied to clipboard")
    changeToastVisibility(true)
  }

  function searchingforEmail(searchByEmail: string) {
    return function (x: any) {
      return x.email.toLowerCase().includes(searchByEmail.toLowerCase()) || !searchByEmail
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdateData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSaveChanges = async () => {
    try {
      const keysToUpdate = Object.keys(updateData).filter((key) => updateData[key] !== paymentInvoices[viewIdx][key])

      if (keysToUpdate.length === 0) {
        return
      }
      updateData["id"] = paymentInvoices[viewIdx].id

      await updateInvoiceUseCase.invoke(auth, updateData, paymentInvoices[viewIdx])

      const updatedInvoice = { ...paymentInvoices[viewIdx] }

      keysToUpdate.forEach((key) => {
        updatedInvoice[key] = updateData[key]
      })

      const formattedDateTime = new Date().toISOString().slice(0, 16).replace("T", " ")
      updatedInvoice.previous_record = {
        ...updatedInvoice.previous_record,
        [formattedDateTime]: paymentInvoices[viewIdx],
      }

      setPaymentInvoices((prevData) => {
        const newData = [...prevData]
        newData[viewIdx] = updatedInvoice
        return newData
      })
      changeToastDetails(STR_SUCCESS, "Invoice updated successfully.")
      changeToastVisibility(true)
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Error updating invoice:")
      changeToastVisibility(true)
    }

    setEditable(() => !editable)
  }

  // utils

  const getDate = (date: string) => {
    const DateObj = new Date(date)
    const month = getMonth[(DateObj.getMonth() + 1).toString()]
    return `${DateObj.getDate()} ${month} ${DateObj.getFullYear()}`
  }

  function formatDateTime(inputDateTime: string | number): string {
    // Convert inputDateTime to Date object
    const parsedDateTime: Date = typeof inputDateTime === "number" ? new Date(inputDateTime) : new Date(inputDateTime)

    // Extract day, month, and year components
    const day: string = parsedDateTime.getDate().toString().padStart(2, "0")
    const month: string = parsedDateTime.toLocaleString("en-US", {
      month: "short",
    })
    const year: string = parsedDateTime.getFullYear().toString().slice(-2)

    // Format the date
    const formattedDateTime: string = `${day} ${month} ${year}`

    return formattedDateTime
  }

  return {
    handleSearchByEmail,
    viewIdx,
    handleChange,
    handleUpdateClick,
    editable,
    setEditable,
    handleInputChange,
    handleSaveChanges,
    isLoading,
    setIsLoading,
    showPopup,
    isDownloading,
    setIsDownloading,
    setShowPopup,
    handleViewBtnClick,
    handleCloseBtnClick,
    getPaymentInvoices,
    paymentInvoices,
    setPaymentInvoices,

    tableHead,
    Keys,
    getMonth,
    getDate,
    disabledKeys,
    searchByEmail,
    emailInputRef,
    toast,
    changeToastVisibility,
    fetchStudentByFiltering,
    searchingforEmail,
    formatDateTime,
    handleCopyToClipboard,
    SelectList,
    handleStatusTypeChange,
    handleFromDateChange,
    handleToDateChange,
    handleCheckboxChange,
    handleDateChange,
    handlePaymentMethodChange,
    handleSavePaymentMethod,
    handleVerificationChange,
    handleSaveVerificationStatus,
    handleDownloadClick,
    fromDate,
    toDate,
    statusType,
    handleClearFilter,
    paginate,
    currentPage,
    invoicesPerPage,
    totalInvoices,
    handleInvoiceLimitChange,
    previousApi,
    setPreviousApi,
    timeoutRef,
    filtering,
    scrollContainerRef,
  }
}
