export const DropDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.00001 7.21883L4.70001 10.5188L3.75734 9.57616L8.00001 5.3335L12.2427 9.57616L11.3 10.5188L8.00001 7.21883Z"
        fill="#414141"
      />
    </svg>
  )
}
