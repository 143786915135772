import { getStudentDetails } from "domain/model/StudentDashboard"
import { Auth } from "domain/model/Auth"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export interface GetTop100StudentsByCoinsUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}
export class GetTop100StudentsByCoins implements GetTop100StudentsByCoinsUseCase {
  private repository: StoreRepository

  constructor(repository: StoreRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.getTop100StudentsByCoins(auth, data)
  }
}
