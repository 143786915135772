import { useEffect, useState } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import ContestAPIDataSourceImpl from "data/API/Student/ContestAPIDataSourceImpl"
import ContestRepositoryImpl from "data/repository/Student/ContestRepositoryImpl"
import GetAllContest from "domain/useCase/Student/Assessment/GetAllContest"
import { STR_FAILURE } from "core/constants/strings"
import { SuccessCheckboxIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import GetAllExams from "domain/useCase/Student/Assessment/GetAllExams"
import { useApp } from "core/context/app"
import { isEmpty } from "core/utils/misc"
import useFeatureFeedbackViewModel from "../FeatureFeedback/FeatureFeedbackViewModel"

import GetUpcomingExams from "domain/useCase/Student/Assessment/GetUpcomingExams"
import { AppContext } from "core/context/app/AppContext"

export default function ContestViewModel() {
  const { fetchLastFeedback, getJoiningDate, saveFeedback, shouldShowFeedbackAfter2Months } =
    useFeatureFeedbackViewModel()
  const [tableData, setTableData] = useState<any>({
    header: [<SuccessCheckboxIcon className="h-4 w-4 text-[#FAFAFA]" />, "Name", "Date", "Time", "Link", "Exam Result"],
    rows: [],
  })
  const [activeButtonType, setActiveButtonType] = useState(0)
  const [allContest, setAllContest] = useState<any>({})
  const [totalContest, setTotalContest] = useLocalStorage<any>("contest", {})
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const [totalDataList, setTotalDataList] = useState(0)
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const GetUpcomingExamsUseCase = new GetUpcomingExams(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const GetAllExamsUseCase = new GetAllExams(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  async function getAllContest(page: number, limit: number = 20) {
    setLoading(true)
    if (isEmpty(totalContest)) {
      const response = await GetAllExamsUseCase.invoke(auth, page, limit)
      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response))
        changeToastVisibility(true)
        return
      }
      setAllContest(response?.data[page])
      setTotalDataList(response?.data?.[page]?.total)
      setTotalContest(response?.data)
      const tabType = ["upcoming", "recorded"][activeButtonType]
      if (tabType == "upcoming") {
        setTableData({ ...tableData, rows: response?.data[page][tabType] })
      } else {
        setTableData({ ...tableData, rows: response?.data[page][tabType] })
      }
    } else {
      const upcomingResponse = await GetUpcomingExamsUseCase.invoke(auth, page, limit)

      if (!upcomingResponse?.success) {
        changeToastDetails(STR_FAILURE, genError(upcomingResponse))
        changeToastVisibility(true)
        setLoading(false)
        return
      }

      const updatedPageData = {
        ...totalContest[page],
        upcoming: upcomingResponse?.data?.upcoming,
      }
      setTotalContest((prev: any) => ({
        ...prev,
        [page]: updatedPageData,
      }))

      const tabType = ["upcoming", "recorded"][activeButtonType]
      setTableData({ ...tableData, rows: updatedPageData[tabType] })
    }
    setLoading(false)
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("contest")
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  async function changeActiveButtonType(index: number) {
    const tabType = ["upcoming", "recorded"][index]
    if (totalContest?.[page]) {
      setTableData({ ...tableData, rows: totalContest?.[page][tabType] })
      setActiveButtonType(index)
      localStorage.removeItem("contest")
      const response = await GetAllExamsUseCase.invoke(auth, page, 20)
      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response))
        changeToastVisibility(true)
        return
      }
      setAllContest(response?.data[page])
      setTotalDataList(response?.data?.[page]?.total)
      setTotalContest(response?.data)
      // const tabType = ["upcoming", "recorded"][activeButtonType]
      if (tabType == "upcoming") {
        setTableData({ ...tableData, rows: response?.data[page][tabType] })
      } else {
        setTableData({ ...tableData, rows: response?.data[page][tabType] })
      }
    } else {
      setTableData({ ...tableData, rows: allContest[page][tabType] })
    }
  }

  return {
    tableData,
    allContest,
    loading,
    activeButtonType,
    toast,
    page,
    totalDataList,
    changeActiveButtonType,
    getAllContest,
    changeToastVisibility,
    setActiveButtonType,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    saveFeedback,
  }
}
