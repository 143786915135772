export default function CheckboxFilled({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM6.76 11.757L11.003 16L18.074 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757Z"
        fill="currentColor"
      />
    </svg>
  )
}
