import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import MentorSessionsAPIDataSourceImpl from "data/API/Admin/MentorSessionsAPIDataSourceImpl"
import { MentorSessionsRepositoryImpl } from "data/repository/Admin/MentorSessionsRepositoryImpl"
import React, { useState } from "react"
import GetMentorList from "domain/useCase/Admin/MentorSessions/GetMentorList"
import GetMentorRating from "domain/useCase/Admin/MentorSessions/GetMentorRating"
import GetMentorSessions from "domain/useCase/Admin/MentorSessions/GetMentorSessions"
import UpdateMentorSession from "domain/useCase/Admin/MentorSessions/UpdateMentorSession"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import GetAllMentorsList from "domain/useCase/Admin/GenerateInvoice/GetAllMentorsList"
import GetGroupMentorSession from "domain/useCase/Admin/MentorSessions/GetGroupMentorSession"
import UpdateGroupMentorSession from "domain/useCase/Admin/MentorSessions/UpdateGroupMentorSession"
import DeleteGroupMentorSession from "domain/useCase/Admin/MentorSessions/DeleteGroupMentorSession"
import { GroupMentorSession } from "domain/model/GroupMentorSession"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function MentorsViewModel() {
  const { auth } = useAuth()
  const [batch, setBatch] = React.useState<string>("")
  const [mentorEmail, setMentorEmail] = React.useState<string>("")
  const [mentorId, setMentorId] = React.useState<string>("")
  const [month, setMonth] = React.useState<string>("")
  const [year, setYear] = React.useState<string>("")

  const [mentorList, setMentorList] = React.useState<any>({})
  const [class_id, setClassId] = React.useState<any>("")
  const [session_id, setSessionId] = React.useState<any>("")
  const [status, setStatus] = React.useState<any>("")
  const [actual_duration, setActualDuration] = React.useState<any>("")
  const [considered_duration, setConsideredDuration] = React.useState<any>("")
  const [mentorSessions, setMentorSessions] = React.useState<any>({})
  const [mentorRating, setMentorRating] = React.useState<any>({})
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [updateSession, setUpdatedSession] = React.useState<any>({})
  const [getBatchList, setBatchList] = React.useState<any>([])
  const [isModelOpen2, setIsModalOpen2] = React.useState(false)
  const [allMentorsList, setAllMentorsList] = React.useState<any>()
  const [groupMentorSession, setgroupMentorSession] = React.useState<any>()
  const [updateGroupSession, setUpdateGroupSession] = React.useState<any>()
  const [deleteGroupSession, setDeleteGroupSession] = React.useState<any>()

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModal2 = () => {
    setIsModalOpen2(!isModelOpen2)
  }
  async function handleSearch() {
    setLoading(true)
    setLoading(false)
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }

  const GetMentorListUseCase = new GetMentorList(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const GetMentorRatingUseCase = new GetMentorRating(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const GetMentorSessionsUseCase = new GetMentorSessions(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const UpdateMentorSessionUseCase = new UpdateMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const GetAllMentorsListUseCase = new GetAllMentorsList(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )

  const GetGroupMentorSessionUseCase = new GetGroupMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const UpdateGroupMentorSessionUseCase = new UpdateGroupMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  const DeleteGroupMentorSessionUseCase = new DeleteGroupMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  async function handleSearchByMentor(e: any) {
    if (e.target.value) setMentorEmail(e.target.value)
    else setMentorEmail("")
    await fetchMentorList(e.target.value)
  }

  function handleSearchByBatch(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }

  function handleSearchByMonth(e: any) {
    if (e.target.value) setMonth(e.target.value)
    else setMonth("")
  }

  function handleSearchByYear(e: any) {
    if (e.target.value) setYear(e.target.value)
    else setYear("")
  }

  const fetchMentorList = async (mentorEmail: string) => {
    const response = await GetMentorListUseCase.invoke(auth, mentorEmail)

    if (!response?.success) {
      return
    }
    setMentorList(response?.data)
  }

  const fetchMentorSessions = async (mentorId: any) => {
    const response = await GetMentorSessionsUseCase.invoke(auth, mentorId, batch, month, year)
    if (!response?.success) {
      return
    }

    setMentorSessions(response?.data)
  }

  const fetchMentorRating = async (mentorId: string) => {
    const response = await GetMentorRatingUseCase.invoke(auth, mentorId)

    if (!response?.success) {
      return
    }

    setMentorRating(response?.data)
  }

  const fetchUpdatedStatus = async (session_id: string) => {
    const response = await UpdateMentorSessionUseCase.invoke(
      auth,
      session_id,
      status,
      parseInt(actual_duration),
      parseInt(considered_duration)
    )

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Mentor Session Updated")

    setUpdatedSession(response?.data)
  }

  const fetchAllMentorList = async () => {
    const response = await GetAllMentorsListUseCase.invoke(auth)

    if (!response?.success) {
      return
    }
    setAllMentorsList(response?.data)
  }

  const fetchGroupMentorSession = async (mentor_id: any) => {
    console.log("year hai", year, month, batch)
    const response = await GetGroupMentorSessionUseCase.invoke(auth, mentor_id, batch, month, year)

    if (!response?.success) {
      return
    }

    setgroupMentorSession(response?.data)
  }

  const deleteGroupMentorSession = async (session_id: string) => {
    const response = await DeleteGroupMentorSessionUseCase.invoke(auth, session_id)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Group Session Deleted")
    deleteGroupSession(response?.data)
  }

  const updateGroupMentorSession = async (session_id: string, group_session: GroupMentorSession) => {
    const response = await UpdateGroupMentorSessionUseCase.invoke(auth, session_id, group_session)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error")
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Group Session Updated")
    updateGroupSession(response?.data)
  }

  return {
    auth,
    batch,
    month,
    session_id,
    class_id,
    status,
    mentorEmail,
    actual_duration,
    considered_duration,
    mentorList,
    mentorRating,
    mentorSessions,
    toast,
    loading,
    isModalOpen,
    getBatchList,
    isModelOpen2,
    allMentorsList,
    groupMentorSession,
    year,
    setStatus,
    setAllMentorsList,
    setActualDuration,
    setConsideredDuration,
    fetchAllMentorList,
    fetchUpdatedStatus,
    getBatches,
    setMentorEmail,
    setMentorId,
    toggleModal,
    toggleModal2,
    setIsModalOpen,
    setIsModalOpen2,
    setClassId,
    setLoading,
    handleSearch,
    handleSearchByMentor,
    handleSearchByBatch,
    handleSearchByMonth,
    updateGroupMentorSession,
    deleteGroupMentorSession,
    fetchGroupMentorSession,
    fetchMentorList,
    fetchMentorRating,
    fetchMentorSessions,
    changeToastVisibility,
    changeToastDetails,
    handleSearchByYear,
  }
}
