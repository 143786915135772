import React from "react"

const Book = ({ className, color }: any) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.33301 2H1.33301V12H5.99967C7.13301 12 7.99967 12.8667 7.99967 14V4.66667C7.99967 3.2 6.79967 2 5.33301 2Z"
        stroke={color ?? "white"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.667 7.99984L12.0003 9.33317L14.667 6.6665"
        stroke={color ?? "white"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6667 4V2H10.6667C9.2 2 8 3.2 8 4.66667V14C8 12.8667 8.86667 12 10 12H14.6667V10.4667"
        stroke={color ?? "white"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Book
