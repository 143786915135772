const FreezeActive = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <path
        d="M17 33.2159C25.9433 33.2159 33.2168 26.7392 33.2168 17.6352C33.2168 8.54691 25.9594 1.17376 17 1.17376C8.04057 1.17376 0.783133 8.54691 0.783133 17.6352C0.783133 26.7392 8.05667 33.2159 17 33.2159Z"
        fill="#359AE1"
        stroke="#075789"
        stroke-width="0.433734"
      />
      <g filter="url(#filter0_ddi_3945_45699)">
        <path
          d="M32.2283 16.5405C32.2283 25.1232 25.4101 31.2384 16.9994 31.2384C8.58871 31.2384 1.77051 25.1232 1.77051 16.5405C1.77051 7.95771 8.58871 1 16.9994 1C25.4101 1 32.2283 7.95771 32.2283 16.5405Z"
          fill="url(#paint0_linear_3945_45699)"
        />
      </g>
      <g filter="url(#filter1_di_3945_45699)">
        <ellipse cx="16.9995" cy="16.1684" rx="11.3735" ry="11.5473" fill="url(#paint1_linear_3945_45699)" />
        <path
          d="M28.3368 16.1684C28.3368 22.5264 23.2604 27.6796 16.9994 27.6796C10.7385 27.6796 5.66212 22.5264 5.66212 16.1684C5.66212 9.81046 10.7385 4.65724 16.9994 4.65724C23.2604 4.65724 28.3368 9.81046 28.3368 16.1684Z"
          stroke="white"
          stroke-width="0.072289"
        />
      </g>
      <path
        d="M21.1493 13.0073L20.6871 15.007H12.0488L12.5175 13.0073H21.1493ZM16.8073 24.0056L12.0067 18.2507L12.0002 16.7333H14.4964C15.0345 16.7333 15.4837 16.6399 15.8439 16.4531C16.2084 16.2617 16.484 15.9952 16.6706 15.6534C16.8616 15.3071 16.9571 14.9016 16.9571 14.4368C16.9571 13.7397 16.7618 13.1792 16.3712 12.7554C15.9806 12.3317 15.3557 12.1198 14.4964 12.1198L12.0455 12.0075L12.5565 10.0078H14.4964C15.59 10.0078 16.4949 10.1855 17.2109 10.5409C17.9313 10.8918 18.4695 11.3885 18.8253 12.0309C19.1855 12.6734 19.3656 13.4252 19.3656 14.2864C19.3656 15.0474 19.2333 15.7263 18.9685 16.3232C18.7038 16.9201 18.2872 17.41 17.7187 17.7927C17.1502 18.1755 16.4059 18.4307 15.4859 18.5582L15.526 18.7086L19.6401 24.0056H19.5218H16.8073ZM21.1623 10.0078L20.6871 11.8259L13.6827 11.7985L14.1579 10.0078H21.1623Z"
        fill="#074C6C"
      />
      <path
        d="M21.8402 13.0073L21.378 15.007H12.7397L13.2084 13.0073H21.8402ZM17.4982 24.0056L12.6977 18.2507L12.6912 16.7333H15.1873C15.7255 16.7333 16.1746 16.6399 16.5348 16.4531C16.8994 16.2617 17.1749 15.9952 17.3615 15.6534C17.5525 15.3071 17.648 14.9016 17.648 14.4368C17.648 13.7397 17.4527 13.1792 17.0621 12.7554C16.6715 12.3317 16.0466 12.1198 15.1873 12.1198L12.7364 12.0075L13.2475 10.0078H15.1873C16.2809 10.0078 17.1858 10.1855 17.9018 10.5409C18.6222 10.8918 19.1604 11.3885 19.5162 12.0309C19.8764 12.6734 20.0565 13.4252 20.0565 14.2864C20.0565 15.0474 19.9242 15.7263 19.6594 16.3232C19.3947 16.9201 18.9781 17.41 18.4096 17.7927C17.8411 18.1755 17.0968 18.4307 16.1768 18.5582L16.2169 18.7086L20.331 24.0056H20.2128H17.4982ZM21.8532 10.0078L21.378 11.8259L14.3736 11.7985L14.8488 10.0078H21.8532Z"
        fill="#25C5F6"
      />
      <path
        d="M21.2254 10.3205H13.1895L13.2804 10H21.8548L21.387 11.8021H19.4141L19.4337 11.8381C19.634 12.2054 19.7977 12.5916 19.9223 12.991H21.8548L21.387 15.0203C20.9627 15.0203 20.0732 15.0945 20.0364 15.0945C20.067 15.8895 19.8475 16.4297 19.2612 17.0536C18.8023 17.5418 18.0587 18.3229 16.2273 18.5651L20.3608 24.0056H19.4708L15.2693 18.4133C19.0806 17.6794 19.6205 15.4418 19.4141 14.4148H20.7481L20.9863 13.4504H19.4141L18.6518 11.31H20.9863L21.2254 10.3205Z"
        fill="white"
      />
      <path
        d="M7.84321 23.2133C8.43301 23.0329 9.05332 23.3661 9.22872 23.9573L9.50599 24.892C8.91619 25.0723 8.29588 24.7392 8.12048 24.1479L7.84321 23.2133Z"
        fill="white"
      />
      <path
        d="M10.0221 29.6702C10.7308 29.0344 11.8244 29.0911 12.4648 29.7969L13.4497 30.8824C12.7411 31.5181 11.6475 31.4614 11.0071 30.7557L10.0221 29.6702Z"
        fill="white"
      />
      <mask
        id="mask0_3945_45699"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="34"
        height="34"
      >
        <path
          d="M17 33.2169C25.9459 33.2169 33.2169 26.6585 33.2169 17.4458C33.2169 8.24893 25.9619 0.783133 17 0.783133C8.03809 0.783133 0.783133 8.24893 0.783133 17.4458C0.783133 26.6585 8.05409 33.2169 17 33.2169Z"
          fill="#BE2C01"
          stroke="#BB2900"
          stroke-width="0.433734"
        />
      </mask>
      <g mask="url(#mask0_3945_45699)">
        <g style={{ mixBlendMode: "soft-light" }}>
          <ellipse cx="30.6865" cy="9.51425" rx="17.9277" ry="18.2018" fill="white" fill-opacity="0.4" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddi_3945_45699"
          x="1.77051"
          y="1"
          width="30.458"
          height="31.178"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.939757" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0235294 0 0 0 0 0.50719 0 0 0 0 0.807843 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3945_45699" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.216867" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3945_45699" result="effect2_dropShadow_3945_45699" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3945_45699" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.216867" dy="0.216867" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_3945_45699" />
        </filter>
        <filter
          id="filter1_di_3945_45699"
          x="5.55369"
          y="4.62109"
          width="22.8194"
          height="23.3829"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.072289" dy="0.289156" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.644902 0 0 0 0 0.996078 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3945_45699" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3945_45699" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.433734" dy="0.72289" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0791146 0 0 0 0 0.587897 0 0 0 0 0.904167 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3945_45699" />
        </filter>
        <linearGradient
          id="paint0_linear_3945_45699"
          x1="22.7825"
          y1="-0.0275192"
          x2="10.3634"
          y2="31.5518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#49CEFF" />
          <stop offset="1" stop-color="#3A6DA7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3945_45699"
          x1="20.8067"
          y1="4.6211"
          x2="11.3027"
          y2="30.4123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#00D6FC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default FreezeActive
