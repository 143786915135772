import Avatar from "react-avatar"
import { Tooltip } from "@mui/material"
import Button from "core/components/new/Button"
import { minsDiff } from "core/utils/date"
import { isEmpty } from "core/utils/misc"
import { format } from "date-fns"
import React from "react"
import useLiveClassesViewModel from "../LiveClassViewModel"
import { CopyIcon } from "lucide-react"
import useToast from "core/hooks/useToast"
import { STR_ACCEPTED } from "core/constants/strings"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"

export default function SessionCard({ isRecorded, session, getDetailedRating }: any) {
  const [linkDisable, setLinkDisable] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(false)
  const { classReminderScheduling } = useLiveClassesViewModel()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const handleClick = async () => {
    try {
      setLoading(true)
      await getDetailedRating(session?.session_id)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const handleOnclick = async () => {
    window.open(session?.class_link)
    window.open(session?.class_link)
    // classReminderScheduling(session?.id)
  }
  const copyToClipboard = async (link: any) => {
    if (!link) {
      return
    }

    try {
      await navigator.clipboard.writeText(link)
      changeToastDetails(STR_ACCEPTED, "Link Copied")
      changeToastVisibility(true)
    } catch (err) {
      alert("Failed to copy link. Please try again.")
    }
  }
  const feedbackLink = session?.id ? `https://platform.bosscoderacademy.com/class-feedback/${session.id}` : ""

  React.useEffect(() => {
    const isLinkActive = () => {
      const timestamp = parseInt(session?.session_timestamp) * 1000
      const diff = minsDiff(Date.now(), timestamp)
      return diff <= 30 && diff > -120
    }

    const checkLinkStatus = () => {
      setLinkDisable(!isLinkActive())
    }

    checkLinkStatus()

    const interval = setInterval(checkLinkStatus, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex gap-4 rounded-sm p-6 shadow">
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />

      <div className="grid aspect-square h-[120px] place-items-center">
        <Avatar size="100%" className="object-cover" src={session?.instructor} name={session?.chapter_name} />
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-new-accent">{session?.chapter_name}</h3>
        <p>Batches: {session?.batches?.join(", ")}</p>
        <p>Course Applied: {session?.track}</p>
        <h3 className="mt-4 text-new-failure">
          {format(new Date(session?.session_timestamp * 1000), "dd MMMM yyyy | h:mm a")}
        </h3>
      </div>
      {!isRecorded && (
        <div className="flex flex-row gap-4 ml-auto self-end">
          <Tooltip title={linkDisable ? "Link will be active half an hour before the class timing." : ""}>
            <span>
              <Button small outlined disabled={linkDisable} onClick={handleOnclick} className="!w-32">
                Join meet
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={"Class Feedback Link"}>
            <span>
              <Button small outlined onClick={() => copyToClipboard(feedbackLink)} className="!w-32">
                Feedback <CopyIcon className="h-[15px]" />
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
      {isRecorded && (
        <div className="flex flex-col gap-8 items-center ml-auto justify-between">
          {session?.class_rating ? (
            <div className="flex">
              {" "}
              <h3 className="text-new-success">
                {Number.isInteger(session?.class_rating) ? session?.class_rating : session?.class_rating?.toFixed(2)}/5
                Rating
                <span className="text-slate-500 px-1">({session?.num_rating})</span>
              </h3>
            </div>
          ) : (
            <></>
          )}
          <Tooltip title={"Get detailed reviews for the class"}>
            <span>
              <Button
                disabled={!session?.class_rating || session?.num_rating === 0}
                small
                outlined
                onClick={handleClick}
                className="!w-32 max-h-[40px]"
              >
                {loading ? <Loader></Loader> : "Detailed Rating"}
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  )
}
