import { Auth } from "domain/model/Auth"
import { CreateClassRepository } from "domain/repository/Admin/CreateClassRepository"

export interface GetAllBatchesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllBatches implements GetAllBatchesUseCase {
  private repository: CreateClassRepository

  constructor(repository: CreateClassRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth) {
    return await this.repository.getAllBatchesList(auth)
  }
}
