import { Auth } from "domain/model/Auth"
// import { AllStudentsReqType } from "domain/model/PlacementDashboard"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface EditStudentDetailsUsecase {
  invoke(auth: Auth, docId: any, updatedDetails: any): Promise<any>
}

export class EditStudentDetails implements EditStudentDetailsUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: any, updatedDetails: any): Promise<any> {
    return await this.repository.editStudentDetails(auth, docId, updatedDetails)
  }
}
