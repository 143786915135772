import { UserSignUp } from "domain/model/UserSignup"
import { UserSignUpRepository } from "domain/repository/Admin/UserSignUpRepository"
import UserSignUpDataSource from "data/dataSource/Admin/UserSignUpDataSourse"
import { Auth } from "domain/model/Auth"

export class UserSignUpRepositoryImpl implements UserSignUpRepository {
  private Datasource: UserSignUpDataSource

  constructor(Datasource: UserSignUpDataSource) {
    this.Datasource = Datasource
  }

  async signup(userSignup: UserSignUp, auth: Auth) {
    return await this.Datasource.signup(userSignup, auth)
  }
}
