import { Auth } from "domain/model/Auth"
import { postAPI } from "core/utils/axios"
import { API_V4_ADMIN, API_V4_GET_ALL_ACTIVE_BATCHES } from "core/constants/strings"
import ActiveBatchDataSource from "data/dataSource/Admin/ActiveBatchDataSource"
import pn from "core/utils/pn"

export class BatchAPIDataSources implements ActiveBatchDataSource {
  async getActiveBatches(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_ACTIVE_BATCHES), {
        id_token: auth?.id_token,
      })
      return response?.data || []
    } catch (error) {
      throw error
    }
  }
}
