import DashboardLayout from "core/layouts//DashboardLayout"
import Input from "core/components/new/Input"
import SuperAdminDashboardViewModel from "../SuperAdminDashboard/SuperAdminDashboardViewModel"
import { useEffect } from "react"
import SuperAdminDashboardTable from "../SuperAdminDashboard/Components/SuperAdminDashboardTable"

const SuperAdminDashboardView = () => {
  const {
    searchByEmail,
    handleSearchByEmail,
    searchByName,
    handleSearchByName,
    getAllAdmin,
    superAdminTableHeaders,
    updateRoleAdminHandle,
    deleteAdminHandle,
    admins,
    refresh,
    deleteModal,
    deleteEmail,
    setDeleteModal,
    setDeleteEmail,
    searchByNameAndEmailHandle,
  } = SuperAdminDashboardViewModel()

  useEffect(() => {
    getAllAdmin()
  }, [refresh])

  useEffect(() => {
    searchByNameAndEmailHandle()
  }, [searchByName, searchByEmail])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Super Admin Dashboard</h2>
          <p>View, find, manage & update Admin details</p>
        </div>

        <div className="flex w-full flex-wrap gap-4">
          <Input
            id="search-email"
            type="email"
            placeholder="Search with email"
            value={searchByEmail}
            onChange={handleSearchByEmail}
            containerClass="w-60"
            search
          />

          <Input
            id="search-name"
            placeholder="Search with name"
            value={searchByName}
            onChange={handleSearchByName}
            containerClass="w-60"
            search
          />
        </div>
        <SuperAdminDashboardTable
          tableHeaders={superAdminTableHeaders}
          updateRoleAdminHandle={updateRoleAdminHandle}
          deleteAdminHandle={deleteAdminHandle}
          admins={admins}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteEmail={deleteEmail}
          setDeleteEmail={setDeleteEmail}
        />
      </div>
    </DashboardLayout>
  )
}

export default SuperAdminDashboardView
