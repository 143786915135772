import { Auth } from "domain/model/Auth"
import { BatchTimestampData } from "domain/model/BatchTimestampData"
import HomeRepository from "domain/repository/Admin/HomeRepository"

export interface GetBatchTimestampDataUseCase {
  invoke(auth: Auth, data: BatchTimestampData): Promise<any>
}

export class GetBatchTimestampData implements GetBatchTimestampDataUseCase {
  private repository: HomeRepository

  constructor(repository: HomeRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: BatchTimestampData) {
    return this.repository.getBatchTimestampData(auth, data)
  }
}
