import Button from "core/components/new/Button"
import Toggle from "core/components/Toggle"
import { EyeIcon } from "core/constants/svgs"
import { useState } from "react"
import CustomModel from "./CustomModel"
import { cn } from "core/lib/utils"
export default function ApproveTable(props: any) {
  const [model, setModel] = useState(false)
  const [reason, setReason] = useState(false)
  const [approveModal, setApproveModal] = useState<any>("")
  const handleModalClose = () => setModel(!model)
  const getId = (data: any) => {
    return data.contest_id || data.exam_id
  }
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <CustomModel isOpen={model} heading={"Reason"} onClose={handleModalClose}>
        <p>{reason}</p>
      </CustomModel>
      <CustomModel isOpen={approveModal} heading={"Approve"} onClose={() => setApproveModal(false)}>
        <p className="text-wrap"> You are about to send approval mail </p>
        <div className="flex gap-4 w-full justify-end mt-4">
          <Button
            outlined
            className="h-9 w-[134px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
            onClick={() => setApproveModal(false)}
          >
            No
          </Button>
          <Button
            outlined
            className="h-9 w-[134px] whitespace-nowrap rounded-sm bg-transparent px-4 py-2"
            onClick={() => {
              props.approveForContest({
                email: approveModal.student.email,
                contest_id: getId(approveModal),
              })
              setApproveModal(false)
            }}
          >
            Yes
          </Button>
        </div>
      </CustomModel>
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {!props.studentContests?.length && !props.loading && <div className="m-2">No data found</div>}
        <tbody>
          {props.studentContests?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4 ">
                <p>{data.student.name}</p>
                <p className="font-medium">{data.student.email}</p>
                <p> {data.student.phone}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.student.batch}</td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">{data.name}</td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setReason(data?.approval_reason)
                    setModel(true)
                  }}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
              <td className="px-6 py-4">
                {/* <Toggle
                  checked={data.is_approved}
                  onChange={() => {
                    props.approveForContest({
                      email: data.student.email,
                      contest_id: getId(data),
                    })
                  }}
                /> */}

                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    className="peer sr-only"
                    checked={data.is_approved}
                    onChange={() => {
                      if (!data.is_approved) {
                        setApproveModal(data)
                      } else {
                        props.approveForContest({
                          email: data.student.email,
                          contest_id: getId(data),
                        })
                      }
                    }}
                  />
                  <div
                    className={cn(
                      "peer h-6 w-11 rounded-full bg-gray-200 dark:bg-gray-700 peer-focus:ring-4 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-new-solid-white after:transition-all dark:border-gray-600",
                      data.is_approved
                        ? "peer-checked:after:translate-x-full peer-checked:bg-green-600"
                        : "peer-checked:after:translate-x-0"
                    )}
                  />
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
