import CheckboxEmpty from "assets/svgs/CheckboxEmpty"
import CheckboxFilled from "assets/svgs/CheckboxFilled"
import { STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { BatchStudentsAPIDataSourceImpl } from "data/API/Admin/BatchStudentsAPIDataSourceImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import HomeAPIDataSourceImpl from "data/API/Admin/HomeAPIDataSourceImpl"
import { SheetsAPIDataSourceImpl } from "data/API/Admin/SheetsAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import { GetAllActiveStudentsOfBatchRepositoryImpl } from "data/repository/Admin/GetAllActiveStudentsOfBatchRepositoryImpl"
import { HomeRepositoryImpl } from "data/repository/Admin/HomeRepositoryImpl"
import { SheetsRepositoryImpl } from "data/repository/Admin/SheetsRepositoryImpl"
import { GetAllActiveStudentInBatch } from "domain/useCase/Admin/Batch/GetAllActiveStudentInBatch"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import GetAllInstructorDetails from "domain/useCase/Admin/GenerateInvoice/GetAllInstructorDetails"
import GetAllMentors from "domain/useCase/Admin/Home/GetAllMentors"
import { GetBatchTimestampData } from "domain/useCase/Admin/Home/GetBatchTimestampData"
import GetFilteredData from "domain/useCase/Admin/Home/GetFilteredData"
import { GetProductAnalytics } from "domain/useCase/Admin/Home/GetProductAnalytics"
import { ViewBatchTimestampData } from "domain/useCase/Admin/Home/ViewBatchTimestampData"
import { GetClassFeedbackByInstructor } from "domain/useCase/Admin/Sheets/GetClassFeedbackByInstructor"
import { ChangeEvent, useState } from "react"

export default function HomeViewModel() {
  const [isOpen, setIsOpen] = useState<any>(false)
  const [reason, setReason] = useState<any>("")
  const [index, setIndex] = useState<number>(0)
  const [params, setParams] = useState<any>()
  const [email, setEmail] = useState<any>("")
  const [prevBatch, setPrevBatch] = useState("")
  const [pausedBatch, setPausedBatch] = useState("")
  const [prevMentor, setPrevMentor] = useState("")
  const [mentor, setMentor] = useState("")
  const [autoTask, setAutoTask] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const [data, setData] = useState<any>({
    batchChangeDetails: {
      header: ["Sr. No.", "Date", "Name", "Email", "Prev Batch", "Curr Batch", "Discord", "Reason"],
      rows: [],
    },
    coursePauseDetails: {
      header: ["Sr. No.", "Date of Pause", "Name", "Email", "Paused Batch", "Reason"],
      rows: [],
    },
    courseResumeDetails: {
      header: [
        "Sr. No.",
        "Course Resume Date",
        "Name",
        "Email",
        "Prev Batch",
        "Curr Batch",
        "Discord",
        "Course Pause Date",
        "Reason",
      ],
      rows: [],
    },
    mentorChangeDetails: {
      header: ["Sr. No.", "Date", "Name", "Email", "Prev Mentor", "Curr Mentor", "Reason"],
      rows: [],
    },
    studentDoubtDetails: {
      header: ["Sr. No.", "Date", "Name", "Contact", "Email", "Helpful", "Not Helpful", "Resolved"],
      rows: [],
    },
  })
  const [productSheet, setProductSheet] = useState<boolean>(false)

  const autoTasks = ["Doubt Support", "Batch Change", "Course Pause", "Course Resume", "Mentor Change"]

  const [batchChangeDetails, setBatchChangeDetails] = useState<any>({
    header: ["Sr. No.", "Date", "Name", "Email", "Prev Batch", "Curr Batch", "Discord", "Reason"],
    rows: [],
  })

  const [coursePauseDetails, setCoursePauseDetails] = useState<any>({
    header: ["Sr. No.", "Date of Pause", "Name", "Email", "Paused Batch", "Reason"],
    rows: [],
  })

  const [courseResumeDetails, setCourseResumeDetails] = useState<any>({
    header: [
      "Sr. No.",
      "Course Resume Date",
      "Name",
      "Email",
      "Prev Batch",
      "Curr Batch",
      "Discord",
      "Course Pause Date",
      "Reason",
    ],
    rows: [],
  })

  const [mentorChangeDetails, setMentorChangeDetails] = useState<any>({
    header: ["Sr. No.", "Date", "Name", "Email", "Prev Mentor", "Curr Mentor", "Reason"],
    rows: [],
  })

  const [studentDoubtDetails, setStudentDoubtDetails] = useState<any>({
    header: ["Sr. No.", "Date", "Name", "Contact", "Email", "Helpful", "Not Helpful", "Resolved"],
    rows: [],
  })

  const [pageNumber, setPageNumber] = useState<any>(0)
  const [numberOfPages, setNumberOfPages] = useState<any>()
  const [itemPerPage, setItemPerPage] = useState<any>(10)
  const [emailInput, setEmailInput] = useState<any>("")
  const [allBatches, setAllBatches] = useState<any>([])
  const [allMentors, setAllMentors] = useState<any>([])
  const [fromDate, setFromDate] = useState<any>("")
  const [toDate, setToDate] = useState<any>("")
  const [fromTimestamp, setFromTimestamp] = useState<any>()
  const [toTimestamp, setToTimestamp] = useState<any>()
  const [batch1, setBatch1] = useState<any>("")
  const [batch2, setBatch2] = useState<any>("")
  const [batch, setBatch] = useState<any>("")
  const [status, setStatus] = useState<any>("")
  const [productAnalytics, setProductAnalytics] = useState<any>()
  const [startDate, setStartDate] = useState<any>("")
  const [endDate, setEndDate] = useState<any>("")
  const [productAnalysisLoading, setProductAnalysisLoading] = useState<boolean>(false)
  const [productAnalysisCheck, setProductAnalysisCheck] = useState<boolean>(false)
  const [batchData, setBatchData] = useState<boolean>(false)
  const [batchStudentData, setBatchStudentData] = useState("")
  const [batchTimestampDetails, setBatchTimestampDetails] = useState<Boolean>(false)
  const [instructorTimeStampDetails, setInstructorTimestampDetails] = useState<Boolean>(false)
  const [instructorId, setInstructorId] = useState("")
  const [year, setYear] = useState<any>({})
  const [month, setMonth] = useState<any>("")
  const [monthList, setMonthList] = useState<any>([])
  const [blurSelect, setBlurSelect] = useState<boolean>(false)
  const [downloadData, setDownloadData] = useState(false)
  const [dataButtonLoading, setDataButtonLoading] = useState(false)
  const [allBatches3, setAllBatches3] = useState([])
  const [instructors, setInstructors] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const [batch3, setBatch3] = useState<any>("")
  const [batch4, setBatch4] = useState<any>("")
  const [nameToEmailMap, setNameToEmailMap] = useState<Record<string, string>>({})
  const [liveDataRequired, setLiveDataRequired] = useState(true)

  const { auth } = useAuth()

  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [formmatedData, setFormmatedData] = useState<{}>({})
  const getFilteredDataUseCase = new GetFilteredData(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))
  const GetAllInstructorDetailsUseCase = new GetAllInstructorDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )
  const getStudentListDataUseCase = new GetAllActiveStudentInBatch(
    new GetAllActiveStudentsOfBatchRepositoryImpl(new BatchStudentsAPIDataSourceImpl())
  )

  const getAllMentorsUseCase = new GetAllMentors(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  const getProductAnalysisUseCase = new GetProductAnalytics(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  const getBatchTimestampDataUseCase = new GetBatchTimestampData(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  const viewBatchDataUseCase = new ViewBatchTimestampData(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  const GetClassFeedbackByInstructorUseCase = new GetClassFeedbackByInstructor(
    new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl())
  )

  function handleItemPerPage(e: any) {
    setItemPerPage(parseInt(e.target.value))
  }

  function hanldeProductSheets(e: any) {
    setProductSheet(true)
  }

  const handleCheckboxChange = (event: any) => {
    setLiveDataRequired(event.target.checked)
  }

  function handleBatchData(e: any) {
    setBatchData(true)
  }

  const handleBatchStudentChange = (e: any) => {
    setBatchStudentData(e.target.value)
  }

  const handleBatchTimestampData = (e: any) => {
    setBatchTimestampDetails(true)
  }

  const handleInstructorTimeStampDetails = (e: any) => {
    setInstructorTimestampDetails(true)
  }

  function getDate(timestamp: any) {
    const date = new Date(timestamp * 1000)

    return date.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  }
  function setDataForType(type: string, header: any, rows: any) {
    setData((prevData: any) => ({
      ...prevData,
      [type]: { header, rows },
    }))
  }

  function batchChangeDetailRow(response: any, header: any) {
    const rows = response?.data?.filter_data?.map((details: any, i: number) => ({
      serial: pageNumber * itemPerPage + (i + 1),
      timestamp: getDate(details["batch_change_timestamp"]),
      name: details["name"],
      email: details["email"],
      prev: details["prev_batch"],
      curr: details["curr_batch"],
      discord: details["discord_username"],
      reason: (
        <button
          className="border-1 flex cursor-pointer items-center justify-center border border-[#312E81] p-1 text-sm font-medium text-[#312E81] hover:bg-[#312E81] hover:text-white"
          onClick={() => {
            setIsOpen(true)
            setReason(details["reason"])
            setEmail(details["name"])
          }}
        >
          View Details
        </button>
      ),
    }))
    setDataForType("batchChangeDetails", header, rows)
  }

  function coursePauseDetailRow(response: any, header: any) {
    const rows = response?.data?.filter_data?.map((details: any, i: number) => ({
      serial: pageNumber * itemPerPage + (i + 1),
      timestamp: getDate(details["course_pause_timestamp"]),
      name: details["name"],
      email: details["email"],
      curr: details["curr_batch"],
      reason: (
        <button
          className="border-1 flex cursor-pointer items-center justify-center border border-[#312E81] p-1 text-sm font-medium text-[#312E81] hover:bg-[#312E81] hover:text-white"
          onClick={() => {
            setIsOpen(true)
            setReason(details["reason"])
            setEmail(details["name"])
          }}
        >
          View Details
        </button>
      ),
    }))
    setDataForType("coursePauseDetails", header, rows)
  }

  function courseResumeDetailRow(response: any, header: any) {
    const rows = response?.data?.filter_data?.map((details: any, i: number) => ({
      serial: pageNumber * itemPerPage + (i + 1),
      course_resume_data: getDate(details["course_resume_timestamp"]),
      name: details["name"],
      email: details["email"],
      prev: details["prev_batch"],
      curr: details["curr_batch"],
      discord: details["discord_username"],
      course_pause_data: getDate(details["course_pause_timestamp"]),
      reason: (
        <button
          className="border-1 flex cursor-pointer items-center justify-center border border-[#312E81] p-1 text-sm font-medium text-[#312E81] hover:bg-[#312E81] hover:text-white"
          onClick={() => {
            setIsOpen(true)
            setReason(details["reason"])
            setEmail(details["name"])
          }}
        >
          View Details
        </button>
      ),
    }))
    setDataForType("courseResumeDetails", header, rows)
  }

  function mentorChangeDetailRow(response: any, header: any) {
    const rows = response?.data?.filter_data?.map((details: any, i: number) => ({
      serial: pageNumber * itemPerPage + (i + 1),
      date: getDate(details["mentor_change_timestamp"]),
      name: details["name"],
      email: details["email"],
      prev: details["prev_mentor"],
      curr: details["curr_mentor"],
      reason: (
        <button
          className="border-1 flex cursor-pointer items-center justify-center border border-[#312E81] p-1 text-sm font-medium text-[#312E81] hover:bg-[#312E81] hover:text-white"
          onClick={() => {
            setIsOpen(true)
            setReason(details["reason"])
            setEmail(details["name"])
          }}
        >
          View Details
        </button>
      ),
    }))

    setDataForType("mentorChangeDetails", header, rows)
  }

  function studentDoubtDetailRow(response: any, header: any) {
    const rows = response?.data?.filter_data?.map((details: any, i: number) => ({
      serial: pageNumber * itemPerPage + (i + 1),
      date: getDate(details["Date"]),
      name: details["Name"],
      contact: details["Contact"],
      email: details["Email"],
      helpful: details["Helpful"] ? (
        <CheckboxFilled className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
      ) : (
        <CheckboxEmpty className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
      ),
      notHelpful: details["Not Helpful"] ? (
        <CheckboxFilled className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
      ) : (
        <CheckboxEmpty className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
      ),
      resolved: details["Resolved"] ? (
        <div className="text-[#00C22B]">Resolved</div>
      ) : (
        <div className="text-red-500">Not Applicable</div>
      ),
    }))

    setDataForType("studentDoubtDetails", header, rows)
  }

  const fetchProductAnalytics = async () => {
    setProductAnalysisLoading(true)
    const response = await getProductAnalysisUseCase.invoke({
      auth,
      startDate,
      endDate,
    })
    setProductAnalysisLoading(false)
    if (!response?.success) {
      return
    }
    setProductAnalysisCheck(true)
    setProductAnalytics(response.response)
    setStartDate("")
    setEndDate("")
  }
  const batchWiseStudentList = async (e: any) => {
    e.preventDefault()
    if (!batchStudentData) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please Fill the Batch")
      return
    }

    try {
      setIsLoading(true)
      const response = await getStudentListDataUseCase.invoke(auth, batchStudentData)

      const csvContent = `data:text/csv;charset=utf-8,${response}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${batchStudentData}.csv`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }
    setIsLoading(false)
  }

  const handleEmailChange = async (e: any) => {
    setEmailInput(e.target.value.trim())
  }

  const fetchAllBatches = async () => {
    const response = await getAllBatchesUseCase.invoke(auth)
    setAllBatches(response?.data)
  }

  const fetchAllMentors = async () => {
    const response = await getAllMentorsUseCase.invoke(auth)
    setAllMentors(response?.data)
  }

  const handleBatchChange1 = async (batch: any, params: any) => {
    setBatch(batch)
    setBatch1(batch)
    setParams(params)
  }

  const handleBatchChange2 = async (batch: any, params: any) => {
    setBatch(batch)
    setBatch2(batch)
    setParams(params)
  }

  const handleStatusChange = async (e: any) => {
    setStatus(e.target.value)
  }

  const renderFilterData = async () => {
    const response = await getFilteredDataUseCase.invoke(auth, {
      task: autoTasks[index],
      itemPerPage,
      email: emailInput,
      batch,
      pageNumber,
      prevBatch,
      prevMentor,
      mentor,
      fromDate: fromTimestamp,
      toDate: toTimestamp,
      pausedBatch,
      status,
    })

    setNumberOfPages(
      response?.data?.length % itemPerPage === 0
        ? response?.data?.length / itemPerPage
        : Math.floor(response?.data?.length / itemPerPage) + 1
    )

    if (autoTasks[index] == "Doubt Support") {
      const header = studentDoubtDetails.header
      studentDoubtDetailRow(response, header)
    } else if (autoTasks[index] == "Batch Change") {
      const header = batchChangeDetails.header
      batchChangeDetailRow(response, header)
    } else if (autoTasks[index] == "Course Pause") {
      const header = coursePauseDetails.header
      coursePauseDetailRow(response, header)
    } else if (autoTasks[index] == "Course Resume") {
      const header = courseResumeDetails.header
      courseResumeDetailRow(response, header)
    } else {
      const header = mentorChangeDetails.header
      mentorChangeDetailRow(response, header)
    }
  }

  const handleFromDateChange = async (e: any) => {
    const date = new Date(e.target.value)
    setFromTimestamp(Math.floor(date.getTime() / 1000))
    setFromDate(e.target.value)
  }

  const handleStartDate = async (e: any) => {
    const date = new Date(e.target.value)
    setStartDate(e.target.value)
  }

  const handleEndDate = async (e: any) => {
    const date = new Date(e.target.value)
    setEndDate(e.target.value)
  }

  const handleToDateChange = async (e: any) => {
    const date = new Date(e.target.value)
    setToTimestamp(Math.floor(date.getTime() / 1000))
    setToDate(e.target.value)
  }

  const fetchbatch3 = async () => {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error fetching batches"))
      return
    }
    setAllBatches3(response?.data)
  }

  const fetchbatch4 = async () => {
    const response = await GetAllInstructorDetailsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error fetching batches"))
      return
    }

    const instructorsData = response?.data?.all_instructors || []

    const instructorNames = instructorsData.map((instructor: any) => instructor?.name)

    const nameToEmailMap = instructorsData.reduce((acc: Record<string, string>, instructor: any) => {
      acc[instructor.name] = instructor.email
      return acc
    }, {})

    setInstructors(instructorNames)
    setNameToEmailMap(nameToEmailMap)
  }

  const handleFormSubmitTimestamp = async (e: any) => {
    e.preventDefault()
    if (downloadData == false) return
    setDownloadData(!downloadData)
    if (!batch3 || !fromDate || !toDate) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      return
    }

    try {
      setIsLoading(true)
      const response = await getBatchTimestampDataUseCase.invoke(auth, {
        batch: batch3,
        fromDate: fromTimestamp,
        toDate: toTimestamp,
      })

      const csvContent = `data:text/csv;charset=utf-8,${response}`
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${batch3}.csv`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }
    setIsLoading(false)
  }

  const handleInstructorFormSubmitTimestamp = async (e: any) => {
    e.preventDefault()

    setDownloadData(!downloadData)

    try {
      setIsLoading(true)

      const response = await GetClassFeedbackByInstructorUseCase.invoke(auth, {
        instructorId,
        fromDate,
        toDate,
        liveDataRequired,
      })

      const data = response.data

      const desiredOrder = [
        "review_timestamp",
        "student_id",
        "session_timestamp",
        "batch",
        "session_name",
        "rating",
        "avg_rate",
        "comment",
      ]

      const headers = desiredOrder.filter((key) => key !== "rating" && data[0].hasOwnProperty(key))

      data.forEach((item: any) => {
        if (item.rating && Array.isArray(item.rating)) {
          item.rating.forEach((rating: any) => {
            if (!headers.includes(rating.title)) {
              headers.push(rating.title)
            }
          })
        }
      })

      const rows = data.map((item: any) => {
        const row: { [key: string]: any } = {}

        Object.keys(item).forEach((key) => {
          if (key !== "rating") {
            if (key.includes("timestamp") && typeof item[key] === "string") {
              row[key] = `"${item[key]}"`
            } else {
              row[key] = item[key]
            }
          }
        })

        if (item.rating && Array.isArray(item.rating)) {
          item.rating.forEach((rating: any) => {
            row[rating.title] = rating.star
          })
        }

        return row
      })

      const csvContent = [
        headers.join(","),
        ...rows.map((row: any) => headers.map((header) => row[header] || "").join(",")),
      ].join("\n")

      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${batch3}.csv`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }

    setIsLoading(false)
  }

  const handleViewBatchData = async (e: any) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      setButtonDisabled(false)
      const response = await viewBatchDataUseCase.invoke(auth, {
        batch: batch3,
        fromDate: fromTimestamp,
        toDate: toTimestamp,
      })
      setFormmatedData(response)
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }

    setIsLoading(false)
  }
  function formatData(inputData: any) {
    const headers: string[] = []
    const rows: { [key: string]: any }[] = []

    if (inputData && inputData.length > 0) {
      const firstItem = inputData[0]

      const baseHeaders = Object.keys(firstItem).filter((key) => key !== "rating")
      headers.push(...baseHeaders)

      if (firstItem.rating && Array.isArray(firstItem.rating)) {
        firstItem.rating.forEach((rating: any) => {
          headers.push(rating.title)
        })
      }
    }

    inputData.forEach((item: any) => {
      const row: { [key: string]: any } = {}

      Object.keys(item).forEach((key) => {
        if (key !== "rating") {
          row[key] = item[key]
        }
      })

      if (item.rating && Array.isArray(item.rating)) {
        item.rating.forEach((rating: any) => {
          row[rating.title] = rating.star
        })
      }

      rows.push(row)
    })

    return {
      header: headers,
      rows: rows,
    }
  }

  const handleViewInstructorData = async (e: any) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      setButtonDisabled(false)

      const response = await GetClassFeedbackByInstructorUseCase.invoke(auth, {
        instructorId,
        fromDate,
        toDate,
        liveDataRequired,
      })
      setFormmatedData(formatData(response?.data))
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }

    setIsLoading(false)
  }

  const handleBatch3Change = (e: any) => {
    setBatch3(e.target.value)
  }

  const handleBatch4Change = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedName = event.target.value
    setBatch4(selectedName)

    const instructorEmail = nameToEmailMap[selectedName]
    if (instructorEmail) {
      setInstructorId(instructorEmail)
    } else {
      console.error(`No email found for the selected instructor: ${selectedName}`)
    }
  }

  function submitForm() {
    setDownloadData(!downloadData)
  }

  return {
    batchChangeDetails,
    data,
    isOpen,
    itemPerPage,
    numberOfPages,
    pageNumber,
    mentorChangeDetails,
    coursePauseDetails,
    courseResumeDetails,
    studentDoubtDetails,
    reason,
    emailInput,
    allBatches,
    allMentors,
    fromDate,
    toDate,
    autoTasks,
    index,
    params,
    batch1,
    batch2,
    batch,
    handleInstructorFormSubmitTimestamp,
    fromTimestamp,
    toTimestamp,
    status,
    email,
    productSheet,
    startDate,
    endDate,
    productAnalysisLoading,
    productAnalysisCheck,
    handleInstructorTimeStampDetails,
    productAnalytics,
    handleStartDate,
    handleEndDate,
    setBatchChangeDetails,
    setMentorChangeDetails,
    setCoursePauseDetails,
    setCourseResumeDetails,
    setStudentDoubtDetails,
    hanldeProductSheets,
    setBatchData,
    batchData,
    handleBatchStudentChange,
    batchStudentData,
    setBatchStudentData,
    handleBatchData,
    handleStatusChange,
    setStatus,
    setFromDate,
    setToDate,
    setBatch1,
    setBatch2,
    setBatch,
    setIndex,
    setEmailInput,
    fetchbatch4,
    setPageNumber,
    handleItemPerPage,
    setIsOpen,
    fetchProductAnalytics,
    handleEmailChange,
    fetchAllBatches,
    handleBatchChange1,
    handleBatchChange2,
    fetchAllMentors,
    handleFromDateChange,
    handleToDateChange,
    setItemPerPage,
    setToTimestamp,
    setFromTimestamp,
    renderFilterData,
    autoTask,
    setAutoTask,
    mentor,
    handleViewInstructorData,
    setMentor,
    prevMentor,
    setPrevMentor,
    pausedBatch,
    setPausedBatch,
    prevBatch,
    setPrevBatch,
    batchWiseStudentList,
    changeToastVisibility,
    changeToastDetails,
    isLoading,
    setIsLoading,
    batchTimestampDetails,
    handleBatchTimestampData,
    handleCheckboxChange,
    liveDataRequired,
    setLiveDataRequired,
    fetchbatch3,
    blurSelect,
    allBatches3,
    handleBatch3Change,
    batch3,
    batch4,
    setBatch4,
    submitForm,
    buttonDisabled,
    handleFormSubmitTimestamp,
    handleViewBatchData,
    formmatedData,
    toast,
    instructorTimeStampDetails,
    setInstructorTimestampDetails,
    instructors,
    handleBatch4Change,
    instructorId,
  }
}
