import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { postAPI, Server } from "core/utils/axios"
import { SessionDataSource } from "data/dataSource/Mentor/SessionDataSource"
import {
  API_CANCEL_SESSION,
  API_FETCH_MONTHLY_MENTOR_SESSION,
  API_GET_MENTOR_SESSIONS,
  API_GET_GROUP_MENTOR_SESSIONS,
  API_MENTOR,
  API_MENTORS,
  API_WRITE_STUDENT_FEEDBACK,
  INSTRUCTOR_SESSIONS,
  MENTOR_SESSION,
  MENTOR_GOAL_SETTING_SESSION,
  GOAL_SETTING_SESSION_AMOUNT_UPDATE,
  API_V3_ADMIN,
  EXTRA_GOAL_SETTING_SESSION_AMOUNT_UPDATE,
  GOAL_SETTING_STATUS_UPDATE,
} from "core/constants/strings"
import axios from "axios"

const server = new Server()

export default class SessionAPIDataSourceImpl implements SessionDataSource {
  async getAllSessions(auth: Auth, month: number, role: string): Promise<any> {
    // const url = `http://localhost:8000/mentor/sessions?month=${month}`

    try {
      const res = await server.get(pn(API_MENTOR, MENTOR_SESSION), {
        headers: {
          Authorization: `Bearer ${auth.id_token}`,
        },
      })
      const data = await res.data
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async getMentorSessions(auth: Auth) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_GET_MENTOR_SESSIONS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async cancelSession(auth: Auth, date: string, start: string, end: string) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_CANCEL_SESSION), {
        id_token: auth.id_token,
        date,
        start,
        end,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async writeStudentFeedback(room_id: string, rating: any, comment: any) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_WRITE_STUDENT_FEEDBACK), {
        room_id,
        rating,
        comment,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async fetchMonthlyMentorSession(auth: Auth, start: string, end: string) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_FETCH_MONTHLY_MENTOR_SESSION), {
        id_token: auth.id_token,
        start,
        end,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getInstructorSessions(auth: Auth, month: number, batch: string, module: string) {
    try {
      const response = await server.post(
        pn(API_MENTOR, INSTRUCTOR_SESSIONS),
        {
          month,
          batch,
          module,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorSession(auth: Auth, month: number, batch: string, module: string) {
    try {
      const response = await server.post(
        pn(API_MENTOR, MENTOR_SESSION),
        {
          month,
          batch,
          module,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getGoalSettingSession(auth: Auth, mentor_id: string, month: number) {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, MENTOR_GOAL_SETTING_SESSION),
        {
          mentor_id,
          month,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getGoalSettingSessionMentor(auth: Auth, mentor_id: string, month: number) {
    try {
      const response = await server.post(
        pn(API_MENTOR, MENTOR_GOAL_SETTING_SESSION),
        {
          mentor_id,
          month,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getGoalSettingSessionAmountUpdate(auth: Auth, mentor_id: string, amount: number) {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, GOAL_SETTING_SESSION_AMOUNT_UPDATE),
        {
          mentor_id,
          amount,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getExtraGoalSettingSessionAmountUpdate(auth: Auth, mentor_id: string, extra_amount: number) {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, EXTRA_GOAL_SETTING_SESSION_AMOUNT_UPDATE),
        {
          mentor_id,
          extra_amount,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getGoalSettingStatusUpdate(auth: Auth, id: string, status: string) {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, GOAL_SETTING_STATUS_UPDATE),
        {
          id,
          status,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
