import Spinner from "core/components/Spinner"
import { Button } from "core/components/v2/Button"
import { CrossIcon } from "core/constants/svgs"

export default function ConfirmCouponModal(props: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
          <div className="m-auto w-[320px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="relative flex">
              <div className="text-lg font-bold text-[#312E81]">Redeem Coins</div>
              <button onClick={props.handleRedeemCoinsModal} className="absolute right-0">
                <CrossIcon className="h-6 w-6 text-[#6B6B6B]" />
              </button>
            </div>

            <div className="mt-[6px] text-sm font-normal">Are you sure you want to redeem your coins</div>
            <div className="text-ms mt-[6px] font-bold text-[#312E81]">Amazon Coupon Worth {props.amount} Rupees</div>
            {props.activeAmount ? (
              <div className="text-ms mt-[6px] font-bold text-[#1C3474]">Coins : {props.amount}</div>
            ) : (
              <div className="text-ms mt-[6px] font-bold text-[#FBBF24]">Points : {props.coins}</div>
            )}
            <div className="mt-3 flex gap-x-2">
              <Button
                variant={"cancel"}
                border={"thin"}
                onClick={props.handleRedeemCoinsModal}
                className="flex h-[36px] w-[134px] cursor-pointer items-center justify-center"
              >
                DISCARD
              </Button>
              <Button
                variant={"primary"}
                border={"thin"}
                onClick={props.RedeemCoupon}
                disabled={props.loader}
                className="flex h-[36px] w-[134px] cursor-pointer items-center justify-center "
              >
                {props.loader ? <Spinner small={true} /> : "CONFIRM"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
