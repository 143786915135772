import { CrossIcon } from "core/constants/svgs"

export default function ConfirmationPopUp(props: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setIsOpen(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
          <div className="m-auto w-[350px] transform rounded border border-new-neutral-light bg-new-editor-dark-500 p-6 font-medium">
            {/* content goes here */}
            <div className="relative flex">
              <div className="text-md font-semibold text-new-neutral-light">
                Do you really want to see the solution? Your marks will not be counted.
              </div>
              <button
                onClick={() => {
                  props?.activeResponseTab !== null
                    ? props.denyViewSolution(props.activeResponseTab)
                    : props.denyViewSolution()
                }}
                className="absolute right-0 mt-[3px]"
              >
                <CrossIcon className="h-[24px] w-[24px] text-[#646464]" />
              </button>
            </div>

            <div className="mt-[6px] text-sm font-normal">{props.heading}</div>
            <div className="mt-3 flex gap-x-1">
              <button
                onClick={() => {
                  props?.activeResponseTab !== null
                    ? props.confirmViewSoluton(props.activeResponseTab)
                    : props.confirmViewSoluton()
                }}
                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded bg-new-editor-dark-600 text-white hover:bg-new-editor-dark-400"
              >
                YES
              </button>
              <button
                onClick={() => {
                  props?.activeResponseTab !== null
                    ? props.denyViewSolution(props.activeResponseTab)
                    : props.denyViewSolution()
                }}
                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border border-[#EF4444] text-[#EF4444] hover:bg-red-200"
              >
                NO
              </button>
            </div>
            {/* ends here */}
          </div>
        </div>
      </div>
    </div>
  )
}
