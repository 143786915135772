import React from "react"

const FreezeDisable = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M9.5 17.2935C14.0627 17.2935 17.7934 13.9833 17.7934 9.3186C17.7934 4.67517 14.0845 0.903336 9.5 0.903336C4.91549 0.903336 1.20656 4.67517 1.20656 9.3186C1.20656 13.9833 4.93727 17.2935 9.5 17.2935Z"
        fill="#3E3E3E"
        stroke="#848484"
        stroke-width="0.586883"
      />
      <g filter="url(#filter0_ddi_3798_58060)">
        <path
          d="M17.1142 8.76979C17.1142 13.0609 13.7051 16.1183 9.49971 16.1183C5.29436 16.1183 1.88525 13.0609 1.88525 8.76979C1.88525 4.47865 5.29436 1 9.49971 1C13.7051 1 17.1142 4.47865 17.1142 8.76979Z"
          fill="url(#paint0_linear_3798_58060)"
        />
      </g>
      <g filter="url(#filter1_di_3798_58060)">
        <ellipse cx="9.50071" cy="8.58438" rx="5.68675" ry="5.77334" fill="url(#paint1_linear_3798_58060)" />
        <path
          d="M15.1385 8.58438C15.1385 11.7466 12.6137 14.3088 9.50071 14.3088C6.38771 14.3088 3.86287 11.7466 3.86287 8.58438C3.86287 5.42216 6.38771 2.85994 9.50071 2.85994C12.6137 2.85994 15.1385 5.42216 15.1385 8.58438Z"
          stroke="#626262"
          stroke-width="0.0978139"
        />
      </g>
      <path
        d="M11.5746 7.00367L11.3435 8.00352H7.0244L7.25874 7.00367H11.5746ZM9.40366 12.5028L7.00337 9.62533L7.00012 8.86666H8.2482C8.51727 8.86666 8.74185 8.81996 8.92195 8.72655C9.10422 8.63086 9.24201 8.49758 9.33531 8.32671C9.43079 8.15356 9.47853 7.95079 9.47853 7.71841C9.47853 7.36983 9.38088 7.0896 9.18559 6.87772C8.9903 6.66583 8.67784 6.55989 8.2482 6.55989L7.02274 6.50375L7.27827 5.50391H8.2482C8.79501 5.50391 9.24743 5.59276 9.60546 5.77047C9.96566 5.9459 10.2347 6.19423 10.4127 6.51547C10.5928 6.83671 10.6828 7.21262 10.6828 7.64322C10.6828 8.0237 10.6166 8.36316 10.4843 8.66162C10.3519 8.96007 10.1436 9.20499 9.85934 9.39637C9.57509 9.58774 9.20295 9.71533 8.74294 9.77912L8.76298 9.8543L10.82 12.5028H10.7609H9.40366ZM11.5811 5.50391L11.3435 6.41294L7.84135 6.39927L8.07895 5.50391H11.5811Z"
        fill="#343434"
      />
      <path
        d="M11.9203 7.00367L11.6892 8.00352H7.3701L7.60444 7.00367H11.9203ZM9.74937 12.5028L7.34907 9.62533L7.34582 8.86666H8.59391C8.86297 8.86666 9.08755 8.81996 9.26765 8.72655C9.44992 8.63086 9.58771 8.49758 9.68102 8.32671C9.77649 8.15356 9.82423 7.95079 9.82423 7.71841C9.82423 7.36983 9.72658 7.0896 9.5313 6.87772C9.33601 6.66583 9.02354 6.55989 8.59391 6.55989L7.36845 6.50375L7.62397 5.50391H8.59391C9.14072 5.50391 9.59314 5.59276 9.95117 5.77047C10.3114 5.9459 10.5804 6.19423 10.7584 6.51547C10.9385 6.83671 11.0285 7.21262 11.0285 7.64322C11.0285 8.0237 10.9623 8.36316 10.83 8.66162C10.6976 8.96007 10.4893 9.20499 10.205 9.39637C9.92079 9.58774 9.54865 9.71533 9.08864 9.77912L9.10869 9.8543L11.1657 12.5028H11.1066H9.74937ZM11.9268 5.50391L11.6892 6.41294L8.18706 6.39927L8.42466 5.50391H11.9268Z"
        fill="#6D6D6D"
      />
      <path
        d="M11.6127 5.66024H7.59473L7.64021 5.5H11.9274L11.6935 6.40107H10.707L10.7169 6.41907C10.817 6.60271 10.8988 6.79579 10.9611 6.99548H11.9274L11.6935 8.01013C11.4813 8.01013 11.0366 8.04724 11.0182 8.04724C11.0335 8.44477 10.9237 8.71486 10.6306 9.02679C10.4012 9.2709 10.0293 9.66146 9.11364 9.78254L11.1804 12.5028H10.7354L8.63463 9.70663C10.5403 9.33971 10.8103 8.22091 10.707 7.70738H11.374L11.4931 7.2252H10.707L10.3259 6.15501H11.4931L11.6127 5.66024Z"
        fill="white"
      />
      <path
        d="M4.92211 12.1061C5.217 12.016 5.52715 12.1825 5.61485 12.4782L5.75337 12.9451C5.45848 13.0352 5.14833 12.8687 5.06063 12.5731L4.92211 12.1061Z"
        fill="white"
      />
      <path
        d="M6.01057 15.3333C6.36489 15.0155 6.9117 15.0439 7.23189 15.3966L7.72438 15.9391C7.37006 16.2568 6.82325 16.2285 6.50306 15.8758L6.01057 15.3333Z"
        fill="white"
      />
      <mask
        id="mask0_3798_58060"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="18"
      >
        <path
          d="M9.5 17.2921C14.0627 17.2921 17.7934 13.9818 17.7934 9.31713C17.7934 4.67371 14.0845 0.901871 9.5 0.901871C4.91549 0.901871 1.20656 4.67371 1.20656 9.31713C1.20656 13.9818 4.93727 17.2921 9.5 17.2921Z"
          fill="#BE2C01"
          stroke="#BB2900"
          stroke-width="0.586883"
        />
      </mask>
      <g mask="url(#mask0_3798_58060)">
        <g style={{ mixBlendMode: "soft-light" }}>
          <ellipse cx="16.3442" cy="5.25783" rx="8.96386" ry="9.10036" fill="white" fill-opacity="0.4" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddi_3798_58060"
          x="1.88525"
          y="1"
          width="15.229"
          height="15.6184"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.791667 0 0 0 0 0.791667 0 0 0 0 0.791667 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3798_58060" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.293442" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.966667 0 0 0 0 0.966667 0 0 0 0 0.966667 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3798_58060" result="effect2_dropShadow_3798_58060" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3798_58060" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.293442" dy="0.293442" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_3798_58060" />
        </filter>
        <filter
          id="filter1_di_3798_58060"
          x="3.71615"
          y="2.81104"
          width="11.4713"
          height="11.9379"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.0978139" dy="0.391256" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.829167 0 0 0 0 0.829167 0 0 0 0 0.829167 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3798_58060" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3798_58060" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.586883" dy="0.978139" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3798_58060" />
        </filter>
        <linearGradient
          id="paint0_linear_3798_58060"
          x1="12.3913"
          y1="0.48627"
          x2="6.18234"
          y2="16.2753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C2C2C2" />
          <stop offset="1" stop-color="#9B9B9B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3798_58060"
          x1="11.4043"
          y1="2.81104"
          x2="6.65281"
          y2="15.7061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E2E2E2" />
          <stop offset="1" stop-color="#9D9D9D" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default FreezeDisable
