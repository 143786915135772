import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import ApproveTable from "./components/ApproveTable"
import useContestApproveViewModel from "./ContestApproveViewModel"
import { useEffect } from "react"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import useCreateClassViewModel from "../CreateClass/CreateClassViewModel"
import Spinner from "core/components/Spinner"

export default function ContestApproveView() {
  const {
    tableHeaders,
    studentContests,
    approveForContest,
    changeToastVisibility,
    fetchApprovalByEmail,
    handleSearchByEmail,
    fetchApprovalByDate,
    nextStudentsLoading,
    searchByEmail,
    handleDateChange,
    dateValue,
    toast,

    emailInputRef,
    getTop100ApproveRequests,
  } = useContestApproveViewModel()

  const { batch, getBatches } = useCreateClassViewModel()

  useEffect(() => {
    if (true) {
      getTop100ApproveRequests()
      emailInputRef.current?.focus()
    }
  }, [])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByEmail) {
        fetchApprovalByEmail(searchByEmail)
      } else {
        getTop100ApproveRequests()
      }
    }, 800)
    return () => clearTimeout(getData)
  }, [searchByEmail])
  useEffect(() => {
    if (true) {
      getBatches()
    }
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Past Exams Approval Requests</h2>
          <p>Approve students' requests for past exams</p>
        </div>

        <div className="flex w-full flex-wrap gap-4">
          <Input
            id="search-email"
            ref={emailInputRef}
            type="email"
            placeholder="Search with email"
            value={searchByEmail}
            onChange={handleSearchByEmail}
            containerClass="w-60"
            search
          />
          <Input type="date" placeholder="Constest Date" onChange={handleDateChange} value={dateValue} className="" />
          <Button className="w-1/6 h-9" onClick={() => fetchApprovalByDate()}>
            Set Date
          </Button>
          {nextStudentsLoading && (
            <div className="flex items-center">
              <Spinner small={true} />
            </div>
          )}
        </div>

        <ApproveTable
          tableHeaders={tableHeaders}
          studentContests={studentContests}
          approveForContest={approveForContest}
          searchByEmail={searchByEmail}
          loading={nextStudentsLoading}
        />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
