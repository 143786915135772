import { Auth } from "domain/model/Auth"
import DoubtSupportRepository from "domain/repository/Admin/DoubtSupportRepository"

export interface GetAllDevelopersUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllDevelopers implements GetAllDevelopersUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllDevelopers(auth)
  }
}
