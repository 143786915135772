import LockClose from "assets/svgs/LockClose"
import LockOpen from "assets/svgs/LockOpen"
import { Button } from "core/components/v2/Button"
import { AwardIcon, CheckBlankIcon, LinkedInIcon, StarIcon } from "core/constants/svgs"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { act, useEffect, useState } from "react"
import BookSlotModal from "./components/BookSlotModal"
import useMentorViewModel from "./MentorViewModel"
import { cn } from "core/lib/utils"
import NewConfirmPopUp from "./components/NewConfirmPopUp"
import { isEmpty } from "core/utils/misc"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "core/components/v2/ui/dropdown-menu"
import PopUp from "./components/PopUp"
import MarkSessionCompletedPopup from "./components/MarkSessionCompletedPopup"

import ConfirmationPopUpMentorRemoval from "./components/MentorAbsentPopup"
import Loader from "core/components/Loader"
import MentorReport from "./components/MentorReport"
import Table from "./components/MentorSessionTable"
import Toast from "core/components/Toast"
import MentorSessionCard from "./components/MentorSessionCard"
import MentorBeginSelectionCard from "./components/MentorBeginSelectionCard"
import MentorCard from "./components/MentorCard"
import NewMentorBeginSelectionCard from "./components/NewMentorBeginSelectionCard"
import MentorListModal from "./components/MentorListModal"
import AwardEmpty from "assets/svgs/AwardEmpty"
import FeedBackModal from "./components/FeedBackModal"
import CancelReasonModal from "./components/CancelReasonModal"
import StarRating from "core/components/StarRating"
import MeetingWaitingModal from "./components/MeetingWaitingModal"
import MentorModal from "./components/MentorModal"
import MentorSchedule from "./components/MentorSchedule"
import Spinner from "core/components/Spinner"
import Avatar from "react-avatar"
import TempMentorCard from "./components/TempMentorCard"
import CheckBlank from "assets/svgs/CheckBlank"
import CheckBox from "assets/svgs/CheckBox"
import CheckboxEmpty from "assets/svgs/CheckboxEmpty"
import CheckboxFilled from "assets/svgs/CheckboxFilled"
import Information from "core/components/Information"
import NewFeedbackModal from "core/components/NewFeedbackModal"
import useLocalStorage from "core/hooks/useLocalStorage"
import { useAuth } from "core/context/auth"

const NewMentorView = () => {
  const {
    auth,
    student,
    tableData,
    limitReached,
    isTableLoading,
    fetchAllMentorSessions,
    fetchBookedSessions,
    mentorSessionData,
    newMentorSessionData,
    isAvailabilityLoading,
    mentorAvailability,
    realMentor,
    allotedMentor,
    milestoneSessionMentor,
    fetchTempMentorAvailability,
    tempMentorAvailability,
    markSessionCompletedPopup,
    setMarkSessionCompletedPopup,
    bookMentorSession,
    setBookingDetails,
    setConfirmSessionIndex,
    bookingDetails,
    isRescheduleEnabled,
    setIsRescheduleEnabled,
    cancelMentorSession,
    isBookingAvailable,
    lastCompletedSessionId,
    handleGetMentorSessionDetails,
    setLastCompletedSessionId,
    LightTooltip,
    setIsMilestoneSession,
    setRealMentor,
    setSessionId,
    fetchMentorAvailability,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    isMilestoneSession,
    sessionId,
    cancellationInProgress,
    setIndexToBeMarkedAbsent,
    indexToBeMarkedAbsent,
    handleMarkMentorAbsent,
    mentorSessions,
    getMentorNameFromId,
    setMentorSessions,
    setIsMentorSessionBooked,
    handleMentorSessionsTable,
    msid,
    setMsid,
    setMilestoneSessionMentorId,
    bookingIndex,
    rescheduleSessionIndex,
    confirmSessionIndex,
    meetLink,
    fetchAllMentors,
    fetchAllTempMentors,
    setMentorId,
    setNewMentorshipFlow,
    BATCHES_TO_IGNORE_NMF,
    BATCHES_TO_IGNORE_MENTOR_ALLOTMENT,
    setShowMentorPage,
    isMentorAlloted,
    disableMentorCard,
    freezeMentorSlot,
    freezeData,
    setDisableMentorCard,
    getMentorDetailsByStudent,
    isMentorSessionBooked,
    reviewIndex,
    rating,
    setRating,
    setComment,
    isSelectionStarted,
    setIsSelectionStarted,
    setIsMentorAlloted,
    showMentorPage,
    isBookingInProgress,
    isAlloting,
    mentorsList,
    allSelectedMentors,
    handleMentorAllotment,
    handleMentorSelection,
    newMentorshipFlow,
    mentor,
    mentorId,
    mentorDetails,
    tempMentorsList,
    showTempMentor,
    startSelectionProcess,
    getFreezeSlotDetails,
    setReviewIndex,
    markTakenElsewhere,
    handleSubmit,
    feedbackLoading,
    cancelReason,
    setCancelReason,
    getMentorJoinDuration,
    closeSelectedFeedbackDialog,
    comment,
    feedbackIndex,
    toast,
    changeToastDetails,
    changeToastVisibility,
    saveFeedback,
  } = useMentorViewModel()

  const { user } = useAuth()

  const [bookSlotModal, setBookSlotModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [cancelPopUpModal, setCancelPopUpModal] = useState(false)
  const [cancelReasonPopUpModal, setCancelReasonPopUpModal] = useState(false)
  const [reschedulePopUpModal, setReschedulePopUpModal] = useState(false)
  const [mentorAbsentModal, setMentorAbsentModal] = useState(false)
  const [studentFeedbackModal, setStudentFeedbackModal] = useState(false)
  const [meetingWaiting, setMeetingWaiting] = useState(false)
  const [activeSessionList, setActiveSessionList] = useState<any>([])
  const [activeSessionIndex, setActiveSessionIndex] = useState<any>(0)
  const [activeSession, setActiveSession] = useState<any>(null)
  const [activeAttemptIndex, setActiveAttemptIndex] = useState<any>(0)
  const [waitingTime, setWaitingTime] = useState<number>(0)
  const [intervalId, setIntervalId] = useState<any>(null)
  const [showModal, setShowModal] = useState(false)
  const [timer, setTimer] = useState(300)
  const [isTimerRunning, setIsTimerRunning] = useState(false)

  function formatFractionalTime(hour: string | number): string {
    const hour24 = parseFloat(hour.toString())
    const hours = Math.floor(hour24)
    const minutes = Math.round((hour24 % 1) * 60)
    const formattedHours = hours.toString().padStart(2, "0")
    const formattedMinutes = minutes.toString().padStart(2, "0")
    return `${formattedHours}:${formattedMinutes}`
  }

  useEffect(() => {
    setTimer(300)
  }, [])

  useEffect(() => {
    if (!isEmpty(activeSession)) {
      setIsMilestoneSession(activeSession?.session_id === "msid1")

      setSessionId(activeSession?.id)
    }
  }, [activeSessionIndex])

  const handleJoinMeeting = (activeSession: any) => {
    const currentDate = new Date()
    const sessionStartTime = activeSession?.session_start_time || ""
    const sessionStartTimeFormatted = formatFractionalTime(sessionStartTime)

    const sessionDateTime = new Date(`${activeSession?.session_date}T${sessionStartTimeFormatted}:00`)

    const timeDifference = sessionDateTime.getTime() + 15 * 60 * 1000 - currentDate.getTime()

    const initialWaitingTime = timeDifference / 1000
    setWaitingTime(initialWaitingTime)

    if (intervalId) {
      clearInterval(intervalId)
    }

    let isRoomJoined = false

    const checkMentorJoin = async () => {
      const dur: any = await getMentorJoinDuration(activeSession?.room_id)
      if (dur > 0) {
        clearInterval(joinInterval)
        clearInterval(waitingInterval)
        setMeetingWaiting(false)
        isRoomJoined = true
        window.location.href = activeSession?.meet_link
      }
    }

    checkMentorJoin()

    const joinInterval = setInterval(async () => {
      if (!isRoomJoined) {
        await checkMentorJoin()
      }
    }, 3000)

    const waitingInterval = setInterval(() => {
      setWaitingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(waitingInterval)
          clearInterval(joinInterval)
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    setIntervalId(joinInterval)
  }

  useEffect(() => {
    fetchAllMentorSessions()
    fetchAllMentors()
    fetchAllTempMentors()
    setMentorId(student?.mentor_id)

    fetchBookedSessions(true)
  }, [])

  useEffect(() => {
    if (mentorSessions?.booked?.length > 0) {
      getMentorNameFromId(mentorSessions.booked[0].mentor_id)
    }
  }, [isBookingAvailable])

  useEffect(() => {
    const isBookedSessionCalled = localStorage.getItem("isBookedSessionCalled")

    if (isBookedSessionCalled !== "true") {
      fetchBookedSessions()
    } else {
      const cachedSessions = localStorage.getItem("mentorSessions")
      if (cachedSessions) {
        const sessions = JSON.parse(cachedSessions)
        setMentorSessions(sessions)
        setIsMentorSessionBooked(false)
        handleMentorSessionsTable(sessions)
      }
    }
  }, [])

  useEffect(() => {
    if (lastCompletedSessionId !== "") {
      handleGetMentorSessionDetails(lastCompletedSessionId)
    }
  }, [lastCompletedSessionId])

  useEffect(() => {
    if (true) {
      setMsid("msid" + String(bookingIndex + 1))
      setMilestoneSessionMentorId(student?.milestone_session_mentor_id)
    }
  }, [
    bookingIndex,
    isMilestoneSession,
    rescheduleSessionIndex,
    confirmSessionIndex,
    msid,
    meetLink,
    lastCompletedSessionId,
  ])

  useEffect(() => {
    if (student?.batch_start_date >= 20240701 && !BATCHES_TO_IGNORE_NMF.includes(student?.batch)) {
      setNewMentorshipFlow(true)
    } else {
      setNewMentorshipFlow(false)
    }
  }, [student])

  useEffect(() => {
    if (
      !isEmpty(student) &&
      (isMentorAlloted ||
        (!student?.is_batch_over == true && !BATCHES_TO_IGNORE_MENTOR_ALLOTMENT.includes(student?.batch))) &&
      (!student?.batch_end_timestamp || student?.batch_end_timestamp > Date.now())
    ) {
      setShowMentorPage(true)
    }
  }, [student, isMentorAlloted])

  useEffect(() => {
    if (student && student["batch"] === "KCE" && !disableMentorCard) {
      setDisableMentorCard(true)
    }
  }, [student])

  useEffect(() => {
    if (isMentorSessionBooked) {
    }
    if (reviewIndex !== -1 && mentorSessionData?.[reviewIndex]?.student_review !== undefined) {
      setRating(mentorSessionData?.[reviewIndex]?.student_review?.rating)
      setComment(mentorSessionData?.[reviewIndex]?.student_review?.comment)
    }
  }, [
    isMentorSessionBooked,
    bookingIndex,
    rescheduleSessionIndex,
    reviewIndex,
    isSelectionStarted,
    confirmSessionIndex,
  ])

  useEffect(() => {
    fetchMentorAvailability(isMilestoneSession, sessionId)
  }, [sessionId, isMilestoneSession])

  useEffect(() => {
    if (student?.mentor_id === "" || student?.mentor_id === null) {
      setIsMentorAlloted(false)
    }
  }, [student])
  useEffect(() => {
    if (
      !isEmpty(student) &&
      (isMentorAlloted || (!student?.is_batch_over && !BATCHES_TO_IGNORE_MENTOR_ALLOTMENT.includes(student?.batch))) &&
      (!student?.batch_end_timestamp || student?.batch_end_timestamp > Date.now())
    ) {
      setShowMentorPage(true)
    }
  }, [student, isMentorAlloted])

  useEffect(() => {
    mentorSessionData.map((item: any) => {
      if (item?.session_status === "Completed") {
        setLastCompletedSessionId(item?.id)
      }
    })
  }, [mentorSessionData])

  useEffect(() => {
    if (newMentorSessionData.length) {
      setActiveSessionList(newMentorSessionData[activeSessionIndex])
      setActiveSession(newMentorSessionData[activeSessionIndex][activeAttemptIndex])
      setStudentFeedbackModal(false)
    }
  }, [newMentorSessionData])

  useEffect(() => {
    const loadFeedback = async () => {
      const lastFeedbackDate = await fetchLastFeedback("Mentor")
      const joining_date = await getJoiningDate()
      setShowModal(shouldShowFeedbackAfter2Months(joining_date, lastFeedbackDate))
    }

    loadFeedback()
  }, [])

  function formatDate(inputDate: any) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    if (!dateRegex.test(inputDate)) return inputDate

    const dateObject = new Date(inputDate)

    const dayOfMonth = dateObject.getDate()
    const monthName = dateObject.toLocaleString("default", { month: "short" })

    const getOrdinal = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"]
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }

    const dayWithOrdinal = getOrdinal(dayOfMonth)

    const formattedDate = `${dayWithOrdinal} ${monthName}`

    return formattedDate
  }

  function formatTime(hour24: any) {
    hour24 = parseFloat(hour24)

    const hours = Math.floor(hour24)
    const minutes = (hour24 % 1) * 60

    const period = hours < 12 ? "AM" : "PM"

    let hour12 = hours % 12
    hour12 = hour12 === 0 ? 12 : hour12

    return `${hour12}:${minutes.toString().padStart(2, "0")} ${period}`
  }

  function getStatusColorClass(status: any) {
    switch (status) {
      case "Pending":
        return "text-yellow-500"
      case "Not Booked":
        return "text-#ADADAD"
      case "not_booked":
        return "text-#ADADAD"
      case "Completed":
        return "text-green-500"
      case "Taken Elsewhere":
        return "text-green-500"
      case "":
        return "text-#ADADAD"
      case "Booked":
        return "text-yellow-500"
      default:
        return "text-red-500"
    }
  }

  return (
    <>
      {!showMentorPage && student?.batch_end_timestamp <= Date.now() ? (
        <DashboardLayoutv2>
          <div className="flex h-[500px] w-full items-center justify-center text-center">
            <p className="max-w-[800px]">
              Your course has concluded, and access to the mentorship program is no longer available.
              <br></br>
              If you have an upcoming interview and require assistance, we can help arrange a session for you. Please
              share your interview details with us at{" "}
              <a className="font-medium text-new-accent" href="mailto:help@bosscoderacademy.com">
                help@bosscoderacademy.com
              </a>
              <br></br>
              We're here to support you in your interview preparation. Best of luck!
            </p>
          </div>
        </DashboardLayoutv2>
      ) : !showMentorPage ? (
        <DashboardLayoutv2>
          <div className="flex h-[500px] w-full items-center justify-center text-center">
            <p className="max-w-md">
              You don't have access to mentorship program. For any assistance please mail us at{" "}
              <a className="font-medium text-new-accent" href="mailto:help@bosscoderacademy.com">
                help@bosscoderacademy.com
              </a>
            </p>
          </div>
        </DashboardLayoutv2>
      ) : disableMentorCard ? (
        <DashboardLayoutv2>
          <div className="flex h-[500px] w-full items-center justify-center text-center">
            <p className="max-w-md font-medium">
              For assistance with the mentorship program, please reach out to your
              <span className="font-bold"> Student Success Manager</span>.
            </p>
          </div>
        </DashboardLayoutv2>
      ) : isBookingInProgress ? (
        <DashboardLayoutv2>
          <div className="flex flex-col gap-4 justify-center items-center">
            <div>
              <Loader />
            </div>
            <div className="text accent-blue text-lg">Please wait! Your session is being booked</div>
          </div>
        </DashboardLayoutv2>
      ) : (
        <DashboardLayoutv2>
          <>
            <BookSlotModal
              freezeData={freezeData}
              timer={timer}
              setTimer={setTimer}
              isTimerRunning={isTimerRunning}
              setIsTimerRunning={setIsTimerRunning}
              freezeSession={freezeMentorSlot}
              isOpen={bookSlotModal}
              onClose={() => setBookSlotModal(false)}
              loading={isAvailabilityLoading}
              availability={mentorAvailability}
              mentor={
                typeof bookSlotModal === "object"
                  ? bookSlotModal
                  : activeSession?.session_id !== "msid1"
                    ? allotedMentor
                    : milestoneSessionMentor || {
                        name: activeSession?.mentor_name,
                        email: activeSession?.mentor_id,
                      }
              }
              isTemp={typeof bookSlotModal === "object" ? true : false}
              setConfirmModal={setConfirmModal}
              mentorSessionDetails={activeSession}
              setBookingDetails={setBookingDetails}
              setConfirmSessionIndex={setConfirmSessionIndex}
            />
            {confirmModal && (
              <NewConfirmPopUp
                timer={timer}
                isTimerRunning={isTimerRunning}
                session={bookingDetails}
                setBookSlotModal={setBookSlotModal}
                confirmModal={confirmModal}
                onClose={() => {
                  setConfirmModal(false)
                  setIsSelectionStarted(false)
                }}
                mentorSessionDetails={activeSession}
                onBooking={bookMentorSession}
                type="confirm"
                setConfirmSessionIndex={setConfirmSessionIndex}
                isRescheduleEnabled={isRescheduleEnabled}
                setIsRescheduleEnabled={setIsRescheduleEnabled}
                onCancel={cancelMentorSession}
                isCancellationInProgress={cancellationInProgress}
              />
            )}

            {cancelReasonPopUpModal && (
              <CancelReasonModal
                cancelReason={cancelReason}
                setCancelReason={setCancelReason}
                isOpen={cancelReasonPopUpModal}
                onClose={() => setCancelReasonPopUpModal(false)}
                setCancelPopUpModal={setCancelPopUpModal}
                changeToastDetails={changeToastDetails}
                changeToastVisibility={changeToastVisibility}
                activeSessionIndex={activeSessionIndex}
              />
            )}

            {cancelPopUpModal && (
              <PopUp
                session={activeSession}
                onClose={() => setCancelPopUpModal(false)}
                setCancelReasonPopUpModal={setCancelReasonPopUpModal}
                type="cancel"
                formatDate={formatDate}
                onCancel={cancelMentorSession}
                setIsRescheduleEnabled={setIsRescheduleEnabled}
                isCancellationInProgress={cancellationInProgress}
              />
            )}

            {reschedulePopUpModal && (
              <PopUp
                session={activeSession}
                onClose={() => setReschedulePopUpModal(false)}
                type="reschedule"
                formatDate={formatDate}
                onCancel={cancelMentorSession}
                setIsRescheduleEnabled={setIsRescheduleEnabled}
                isCancellationInProgress={cancellationInProgress}
                setBookSlotModal={setBookSlotModal}
              />
            )}
            {markSessionCompletedPopup && (
              <MarkSessionCompletedPopup
                session={activeSession}
                onClose={() => setMarkSessionCompletedPopup(false)}
                type="mark session completed"
                formatDate={formatDate}
                onCancel={markTakenElsewhere}
              />
            )}

            {mentorAbsentModal && (
              <ConfirmationPopUpMentorRemoval
                isOpen={mentorAbsentModal}
                setIsOpen={setMentorAbsentModal}
                updateStudentsMentor={async () => await handleMarkMentorAbsent(indexToBeMarkedAbsent)}
                heading="Are you sure you want to mark mentor absent for this session?"
              />
            )}

            {reviewIndex !== -1 && !newMentorshipFlow && (
              <MentorModal
                index={reviewIndex}
                sessions={newMentorshipFlow ? mentorSessionData?.[reviewIndex] : mentorSessions?.all_sessions}
                onClose={() => closeSelectedFeedbackDialog("review")}
                rating={rating}
                setRating={setRating}
                comment={comment}
                setComment={setComment}
                onSubmit={() => {
                  handleSubmit(rating, comment)
                }}
                type="review"
                newMentorshipFlow={newMentorshipFlow}
                openedFrom="student"
              />
            )}

            {feedbackIndex !== -1 && (
              <MentorReport
                index={feedbackIndex}
                sessions={
                  newMentorshipFlow ? mentorSessionData?.[feedbackIndex] : mentorSessions?.all_sessions?.[feedbackIndex]
                }
                onClose={() => closeSelectedFeedbackDialog("feedback")}
                rating={rating}
                setRating={setRating}
                comment={comment}
                setComment={setComment}
                onSubmit={handleSubmit}
                newMentorshipFlow={newMentorshipFlow}
                type="feedback"
              />
            )}

            {studentFeedbackModal && (
              <FeedBackModal
                mentorDetails={
                  activeSession?.session_id !== "msid1"
                    ? allotedMentor
                    : milestoneSessionMentor || {
                        name: activeSession?.mentor_name,
                        email: activeSession?.mentor_id,
                      }
                }
                oldRating={rating}
                activeSessionIndex={activeSessionIndex}
                setOldRating={setRating}
                onSubmit={handleSubmit}
                newMentorshipFlow={newMentorshipFlow}
                isOpen={studentFeedbackModal}
                loading={feedbackLoading}
                onClose={() => setStudentFeedbackModal(false)}
                session={activeSession}
                setMentorAbsentModal={setMentorAbsentModal}
                setIndexToBeMarkedAbsent={setIndexToBeMarkedAbsent}
              />
            )}

            <MeetingWaitingModal
              isOpen={meetingWaiting}
              onClose={() => setMeetingWaiting(false)}
              waitingTime={waitingTime}
              activeSessionIndex={activeSessionIndex}
              mentorDetails={
                !isEmpty(activeSession?.temp_mentor)
                  ? activeSession?.temp_mentor
                  : activeSession?.session_id !== "msid1"
                    ? allotedMentor
                    : milestoneSessionMentor || {
                        name: activeSession?.mentor_name,
                        email: activeSession?.mentor_id,
                      }
              }
            />

            <MentorListModal
              isAlloting={isAlloting}
              mentorsList={isSelectionStarted === "temp" ? tempMentorsList : mentorsList}
              isTemp={isSelectionStarted === "temp" ? true : false}
              selectedMentors={allSelectedMentors}
              alloted={isMentorAlloted}
              handleMentorAllotment={handleMentorAllotment}
              handleMentorSelection={handleMentorSelection}
              isOpen={isSelectionStarted}
              onClose={() => setIsSelectionStarted(false)}
              fetchTempMentorAvailability={fetchTempMentorAvailability}
              session_id={activeSession}
              setBookSlotModal={setBookSlotModal}
            />

            <div className="p-[24px]">
              <p className="text-[16px] font-[600] text-[#333]">Schedule Mentorship</p>
              <p className="text-[#646464] text-[14px] font-[400]">Book all your mentor sessions hassle free!</p>

              {mentor &&
                mentorSessions?.booked?.length > 0 &&
                Date.now() <= mentorSessions.booked[0].end_timestamp * 1000 && (
                  <div className="my-[16px]">
                    <MentorSessionCard
                      onJoin={(session: any) => {
                        setMeetingWaiting(true)
                        handleJoinMeeting(session)
                      }}
                      mentor={mentorId}
                      session={mentorSessions?.booked[0]}
                    />
                  </div>
                )}
              {!isMentorAlloted ? (
                <div>
                  <NewMentorBeginSelectionCard onClick={startSelectionProcess} />
                </div>
              ) : (
                <div className="mt-[24px]">
                  <MentorCard mentor={mentorDetails} alloted={isMentorAlloted} />
                </div>
              )}
            </div>

            {!newMentorshipFlow &&
              (limitReached ? (
                <div className="flex h-[100px] w-full items-center justify-center text-center">
                  <p className="max-w-md">
                    You have reached the maximum limit for mentor sessions. For any assistance please mail us at{" "}
                    <a className="font-medium text-new-accent" href="mailto:help@bosscoderacademy.com">
                      help@bosscoderacademy.com
                    </a>
                  </p>
                </div>
              ) : (
                <MentorSchedule
                  availability={mentorAvailability}
                  mentor={allotedMentor}
                  onBooking={bookMentorSession}
                />
              ))}

            {newMentorshipFlow ? (
              <div className="px-[24px]">
                {mentorSessionData?.length === 0 ? (
                  <div className="flex w-full justify-center">
                    {isTableLoading ? <Spinner /> : "No Mentor Session Found."}
                  </div>
                ) : (
                  <div className="flex gap-[10px]">
                    <div className="w-1/4 p-[16px] bg-[#F9F9F9] rounded-[8px]">
                      {newMentorSessionData.map((list: any, index: number) => {
                        const item = list[list.length - 1]

                        const currentDate = new Date()
                        const isBookNowEnabled =
                          item?.session_status === "not_booked" &&
                          item?.startDate &&
                          item?.endDate &&
                          new Date(item.startDate).getTime() - 15 * 24 * 60 * 60 * 1000 <= currentDate.getTime() &&
                          currentDate.getTime() <= new Date(item.endDate).getTime() &&
                          (isBookingAvailable === true || lastCompletedSessionId === "")

                        const currTimeGreaterThanSessionStartTime =
                          activeSession?.startDate &&
                          new Date() <= new Date(new Date(activeSession.startDate).getTime() - 15 * 24 * 60 * 60 * 1000)

                        return (
                          <div>
                            <div className="flex items-center gap-[10px] w-[115px] justify-center h-[25px] rounded-[4px] py-[4px] px-[8px] bg-[#333]">
                              <p className="text-white text-[14px] font-[500]">Session {index + 1}</p>

                              {currTimeGreaterThanSessionStartTime || item?.startDate?.charAt(0) !== "2" ? (
                                <LockClose />
                              ) : (
                                <LockOpen />
                              )}
                            </div>

                            <div
                              className={cn(
                                "my-[11px] w-full h-[69px] px-[16px] py-[6px]  rounded-[4px] cursor-pointer border-[#D3D3D3] border-[0.5px]",
                                index === activeSessionIndex && "shadow-[3px_3px_0px_0px_rgba(0,0,0,0.30)]",
                                currTimeGreaterThanSessionStartTime || item?.startDate?.charAt(0) !== "2"
                                  ? "bg-[#EBEBEB] border-none"
                                  : "bg-white"
                              )}
                              onClick={() => {
                                setActiveSessionList(list)
                                setActiveSessionIndex(index)
                                setActiveAttemptIndex(0)
                                setActiveSession(list[0])
                              }}
                            >
                              <div className="flex justify-between">
                                <p className="text-[14px] font-[500] w-[140px]">{item?.name}</p>
                                {item?.session_status === "Completed" ? (
                                  <CheckboxFilled className="size-[16px]" />
                                ) : (
                                  <CheckboxEmpty className="size-[16px]" />
                                )}
                              </div>
                              {item?.session_date && item?.session_start_time ? (
                                <p
                                  className={cn(
                                    item?.session_status != "not_booked" &&
                                      item?.session_status != "Completed" &&
                                      item?.session_status != "Booked"
                                      ? "text-red-500"
                                      : item?.status === "Completed"
                                        ? ""
                                        : "text-green-500",
                                    "text-[12px] font-[500]"
                                  )}
                                >
                                  {formatDate(item?.session_date)} | {formatTime(item?.session_start_time)}
                                </p>
                              ) : item?.startDate?.charAt(0) !== "2" ? (
                                <p className="text-[12px] font-[500]">To be notified</p>
                              ) : isBookNowEnabled ? (
                                <p className="text-green-500 text-[12px] font-[500]">
                                  {formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}
                                </p>
                              ) : (
                                <p className="text-[12px] font-[500]">
                                  {formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}
                                </p>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="w-3/4 p-[16px] bg-[#F9F9F9] rounded-[8px]">
                      <p className="text-[14px] font-[600] text-[#000]">Session {activeSessionIndex + 1}</p>

                      <div className="flex flex-wrap gap-[8px] my-[16px]">
                        {Array.from({ length: activeSessionList.length }).map((_, index: number) => (
                          <Button
                            className={cn(
                              "h-[27px] w-[95px] focus:bg-new-accent-dark",
                              activeAttemptIndex === index &&
                                "bg-new-accent-dark hover:bg-new-accent-dark text-white hover:text-white"
                            )}
                            variant={"ternary"}
                            size={"sm"}
                            key={index}
                            onClick={() => {
                              setActiveAttemptIndex(index)
                              setActiveSession(activeSessionList[index])
                            }}
                            border="thin"
                          >
                            <span className="text-[12px] font-[500]">Attempt {index + 1}</span>
                          </Button>
                        ))}
                      </div>

                      {activeSession &&
                        (() => {
                          const currentDate = new Date()
                          const isDateRangeDefined = activeSession?.startDate && activeSession?.endDate
                          const isPaymentDone = user?.payment_status !== "NOT_PAID"
                          const isMilestoneSession = activeSession?.session_id === "msid1"
                          const sessionWithinRange =
                            activeSession?.session_status === "not_booked" &&
                            activeSession?.startDate &&
                            activeSession?.endDate &&
                            new Date(activeSession.startDate).getTime() - 15 * 24 * 60 * 60 * 1000 <=
                              currentDate.getTime() &&
                            currentDate.getTime() <= new Date(activeSession.endDate).getTime()
                          const sessionTimeGreaterThanRange =
                            activeSession?.endDate && new Date() > new Date(activeSession.endDate)
                          const isBookNowEnabled =
                            activeSession?.session_status === "not_booked" &&
                            activeSession?.startDate &&
                            activeSession?.endDate &&
                            new Date(activeSession.startDate).getTime() - 15 * 24 * 60 * 60 * 1000 <=
                              currentDate.getTime() &&
                            currentDate.getTime() <= new Date(activeSession.endDate).getTime() &&
                            (isBookingAvailable === true || lastCompletedSessionId === "")

                          const sessionStartTime: string = activeSession?.session_start_time || ""
                          const sessionEndTime: string = activeSession?.session_end_time || ""
                          const sessionStartTimeFormatted: string = formatFractionalTime(sessionStartTime)
                          const sessionEndTimeFormatted: string = formatFractionalTime(sessionEndTime)

                          const sessionDateTime: Date = new Date(
                            `${activeSession?.session_date}T${sessionStartTimeFormatted}:00`
                          )
                          const sessionEndDateTime: Date = new Date(
                            `${activeSession?.session_date}T${sessionEndTimeFormatted}:00`
                          )

                          const thirtyMinutesInMillis = 30 * 60 * 1000
                          const joinStartTime = sessionDateTime.getTime() - thirtyMinutesInMillis
                          const joinEndTime = sessionEndDateTime.getTime() + thirtyMinutesInMillis

                          const statusTimeDifference =
                            sessionDateTime.getTime() + 15 * 60 * 1000 - currentDate.getTime()

                          const isGreaterThan15Min = currentDate.getTime() - sessionDateTime.getTime() > 15 * 60 * 1000
                          const timeDifference: number = sessionDateTime.getTime() - currentDate.getTime()
                          const timeDifferenceEndTime: number = currentDate.getTime() - sessionEndDateTime.getTime()
                          const isGreaterThan24Hours: boolean = timeDifference > 24 * 60 * 60 * 1000
                          const currTimeGreaterThanSessionStartTime =
                            activeSession?.startDate &&
                            new Date() <=
                              new Date(new Date(activeSession.startDate).getTime() - 15 * 24 * 60 * 60 * 1000)

                          const isGreaterThan12Hours: boolean = timeDifference > (24 * 60 * 60 * 1000) / 2
                          const isRescheduleEnabled: boolean =
                            isGreaterThan12Hours && activeSession?.session_status === "Booked"
                          const isMarkMentorAbsentEnabled: boolean =
                            activeSession?.session_status === "Booked" && isGreaterThan15Min
                          const isJoinNowEnabled: boolean =
                            currentDate.getTime() >= joinStartTime &&
                            joinEndTime >= currentDate.getTime() &&
                            (activeSession?.session_status === "Booked" ||
                              activeSession?.status === "Booked" ||
                              activeSession?.session_status === "Completed" ||
                              activeSession?.status === "Completed")

                          const isMentorReportVisible: boolean =
                            activeSession?.session_status === "Completed" &&
                            activeSession?.student_review !== undefined &&
                            !isEmpty(activeSession?.student_review)

                          const hasMentorGivenFeedback: boolean =
                            activeSession?.mentor_feedback !== undefined && activeSession?.mentor_feedback !== ""

                          function formatTime(hour24: any) {
                            hour24 = parseFloat(hour24)

                            const hours = Math.floor(hour24)
                            const minutes = (hour24 % 1) * 60

                            const period = hours < 12 ? "AM" : "PM"

                            let hour12 = hours % 12
                            hour12 = hour12 === 0 ? 12 : hour12

                            return `${hour12}:${minutes.toString().padStart(2, "0")} ${period}`
                          }
                          return (
                            <>
                              {activeSession?.temp_mentor &&
                                !isEmpty(activeSession?.temp_mentor) &&
                                activeSession?.session_status !== "not_booked" && (
                                  <TempMentorCard temp_mentor={activeSession?.temp_mentor} />
                                )}
                              <div className="relative h-[113px] w-full bg-[#FFF] rounded-[8px] p-[12px] border-[0.5px] border-[#D3D3D3] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]">
                                <div className="absolute right-[8px] top-[8px]">
                                  <DropdownMenu>
                                    <DropdownMenuTrigger>
                                      <button className="hover:bg-[#ebebeb] size-[24px] rounded-[2px] ">⁝</button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="bg-white w-[200px]">
                                      <DropdownMenuItem>
                                        <Button
                                          variant={"ternary"}
                                          className="w-full flex justify-start"
                                          onClick={() => {
                                            isRescheduleEnabled && setCancelReasonPopUpModal(true)
                                          }}
                                          disabled={!isRescheduleEnabled}
                                        >
                                          <span
                                            className={cn(
                                              "text-[12px] text-new-failure",
                                              !isRescheduleEnabled && "text-v2-gray-400"
                                            )}
                                          >
                                            Cancel Session
                                          </span>
                                        </Button>
                                      </DropdownMenuItem>
                                      <DropdownMenuItem>
                                        <Button
                                          variant={"ternary"}
                                          className="w-full flex justify-start"
                                          onClick={
                                            isMarkMentorAbsentEnabled
                                              ? () => {
                                                  setMentorAbsentModal(true)
                                                  setIndexToBeMarkedAbsent(activeSession?.id)
                                                }
                                              : undefined
                                          }
                                          disabled={!isMarkMentorAbsentEnabled}
                                        >
                                          <span className="text-[12px]">Mentor Absent</span>
                                        </Button>
                                      </DropdownMenuItem>
                                      <DropdownMenuItem>
                                        <Button
                                          variant={"ternary"}
                                          className="w-full flex justify-start"
                                          onClick={() => {
                                            fetchMentorAvailability(
                                              activeSession?.session_id === "msid1",
                                              activeSession?.id
                                            )
                                            isRescheduleEnabled && setReschedulePopUpModal(true)
                                          }}
                                          disabled={!isRescheduleEnabled || !isEmpty(activeSession?.temp_mentor)}
                                        >
                                          <span className="text-[12px]">Re-schedule Session</span>
                                        </Button>
                                      </DropdownMenuItem>
                                      <DropdownMenuItem>
                                        <Button
                                          variant={"ternary"}
                                          className="w-full flex justify-start"
                                          onClick={() => {
                                            setMarkSessionCompletedPopup(true)
                                          }}
                                          disabled={activeSession?.session_status !== "Booked"}
                                        >
                                          <span className="text-[12px]">Mark session completed</span>
                                        </Button>
                                      </DropdownMenuItem>
                                      <DropdownMenuItem>
                                        <Button
                                          onClick={() => (window.location.href = "mailto:help@bosscoderacademy.com")}
                                          variant={"ternary"}
                                          className="w-full flex justify-start"
                                        >
                                          <span className="text-[12px]">More Help</span>
                                        </Button>
                                      </DropdownMenuItem>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </div>
                                <div className="flex items-center gap-[6px]">
                                  <p className="text-[14px] font-[600] text-[#333]">{activeSession?.name}</p>
                                  <LightTooltip title={activeSession?.agenda}>
                                    <button>
                                      <Information className="size-[16px]" />
                                    </button>
                                  </LightTooltip>
                                </div>

                                {activeSession?.session_date && activeSession?.session_start_time ? (
                                  <p
                                    className={cn(
                                      activeSession?.session_status != "not_booked" &&
                                        activeSession?.session_status != "Completed" &&
                                        activeSession?.session_status != "Booked"
                                        ? "text-red-500"
                                        : activeSession?.status === "Completed"
                                          ? ""
                                          : "text-green-500",
                                      "text-[12px] font-[500]"
                                    )}
                                  >
                                    {formatDate(activeSession?.session_date)} |{" "}
                                    {formatTime(activeSession?.session_start_time)}
                                  </p>
                                ) : activeSession?.startDate?.charAt(0) !== "2" ? (
                                  <p className="text-[12px] font-[500]">To be notified</p>
                                ) : isBookNowEnabled ? (
                                  <p className="text-green-500 text-[12px] font-[500]">
                                    {formatDate(activeSession?.startDate) + " - " + formatDate(activeSession?.endDate)}
                                  </p>
                                ) : (
                                  <p className="text-[12px] font-[500]">
                                    {formatDate(activeSession?.startDate) + " - " + formatDate(activeSession?.endDate)}
                                  </p>
                                )}
                                <p className="text-[#646464] text-[10px] font-[500] leading-4">
                                  Mentor :{" "}
                                  {activeSession?.temp_mentor?.name
                                    ? activeSession?.temp_mentor?.name
                                    : activeSession?.mentor_name === "not_booked"
                                      ? "Not Selected"
                                      : activeSession?.mentor_name || "Not Selected"}
                                </p>

                                <div className="flex justify-between items-end">
                                  <p className="text-[12px] font-[500]">
                                    <span
                                      className={getStatusColorClass(
                                        activeSession?.session_status !== "not_booked"
                                          ? activeSession?.session_status
                                          : new Date(activeSession?.endDate) < new Date()
                                            ? "Expired"
                                            : currTimeGreaterThanSessionStartTime
                                              ? "Locked"
                                              : activeSession?.session_status === "not_booked"
                                                ? "Not Booked"
                                                : activeSession?.session_status || "N/A"
                                      )}
                                    >
                                      {activeSession?.session_status !== "not_booked"
                                        ? activeSession?.session_status
                                        : new Date(activeSession?.endDate) < new Date()
                                          ? "Expired"
                                          : currTimeGreaterThanSessionStartTime
                                            ? "Locked"
                                            : activeSession?.session_status === "not_booked"
                                              ? "Not Booked"
                                              : activeSession?.session_status || "N/A"}{" "}
                                      {activeSession?.duration ? (
                                        <>| {activeSession?.duration} Mins</>
                                      ) : activeSession?.session_status === "Taken Elsewhere" ? (
                                        <>| 40Mins</>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </p>
                                  {activeSession?.mentor_joinat ? (
                                    <p className="text-[12px] font-[500]">
                                      Mentor Joined At: {activeSession?.mentor_joinat} IST
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <div className="flex gap-[6px]">
                                    <LightTooltip
                                      title={
                                        isJoinNowEnabled
                                          ? "Join Now"
                                          : activeSession?.session_status === "not_booked"
                                            ? "Not Booked"
                                            : activeSession?.session_status !== "Completed" &&
                                                activeSession?.session_status !== "Booked"
                                              ? activeSession?.session_status
                                              : activeSession?.session_status === "Completed"
                                                ? "Your session has already been completed"
                                                : "The link will be active 30 mins before the join time."
                                      }
                                      placement="bottom"
                                    >
                                      <Button
                                        onClick={() => {
                                          setMeetingWaiting(true)
                                          handleJoinMeeting(activeSession)
                                        }}
                                        disabled={!isJoinNowEnabled}
                                        className="px-[16px] py-[6px]"
                                        size={"sm"}
                                        variant={"ternary"}
                                        border={"thin"}
                                      >
                                        Join Meeting
                                      </Button>
                                    </LightTooltip>

                                    <LightTooltip
                                      title={
                                        activeSession?.session_date
                                          ? activeSession?.session_status
                                          : activeSession?.session_status === "Booked"
                                            ? "You have already booked the session"
                                            : activeSession?.session_status === "Completed"
                                              ? "You have already completed the session"
                                              : sessionTimeGreaterThanRange
                                                ? "Session Expired"
                                                : currTimeGreaterThanSessionStartTime
                                                  ? "Session Locked"
                                                  : !sessionWithinRange
                                                    ? "You have not booked session in the given date range"
                                                    : activeSession?.session_status === "Completed"
                                                      ? "Your session has already been completed"
                                                      : !isBookingAvailable && lastCompletedSessionId !== ""
                                                        ? "You need to give rating for the last completed session first"
                                                        : !isPaymentDone
                                                          ? "You have not completed payment yet"
                                                          : activeSession?.session_id === "msid1" && !isBookNowEnabled
                                                            ? "You have already booked the session"
                                                            : activeSession?.session_id === "msid1"
                                                              ? "Book Now"
                                                              : !student?.mentor_id
                                                                ? "You have not selected a mentor"
                                                                : isBookNowEnabled
                                                                  ? "Book Now"
                                                                  : activeSession?.session_status === "not_booked"
                                                                    ? isDateRangeDefined
                                                                      ? "You have not booked session in the given date range"
                                                                      : "Dates have not been confirmed by Admin yet!"
                                                                    : "You have already booked the session"
                                      }
                                      placement="bottom"
                                    >
                                      <span>
                                        <Button
                                          className="px-[16px] py-[6px]"
                                          size={"sm"}
                                          variant={"primary"}
                                          onClick={() => {
                                            setBookSlotModal(true)
                                            fetchMentorAvailability(
                                              activeSession?.session_id === "msid1",
                                              activeSession?.id
                                            )
                                          }}
                                          disabled={
                                            !isPaymentDone
                                              ? true
                                              : activeSession?.session_id === "msid1" && isBookNowEnabled
                                                ? false
                                                : activeSessionIndex === 0 &&
                                                    activeAttemptIndex === 0 &&
                                                    !isBookNowEnabled
                                                  ? true
                                                  : activeSessionIndex === 0 &&
                                                      activeAttemptIndex === 0 &&
                                                      !isPaymentDone
                                                    ? true
                                                    : activeSessionIndex === 0 && activeAttemptIndex === 0
                                                      ? false
                                                      : !isBookNowEnabled || !isPaymentDone || student?.mentor_id === ""
                                          }
                                        >
                                          Book Now
                                        </Button>
                                      </span>
                                    </LightTooltip>
                                  </div>
                                </div>
                              </div>
                              {showTempMentor &&
                                !isAvailabilityLoading &&
                                activeSession.session_id != "msid1" &&
                                !(!isPaymentDone
                                  ? true
                                  : activeSession?.session_id === "msid1" && isBookNowEnabled
                                    ? false
                                    : activeSessionIndex === 0 && activeAttemptIndex === 0 && !isBookNowEnabled
                                      ? true
                                      : activeSessionIndex === 0 && activeAttemptIndex === 0 && !isPaymentDone
                                        ? true
                                        : activeSessionIndex === 0 && activeAttemptIndex === 0
                                          ? false
                                          : !isBookNowEnabled || !isPaymentDone || student?.mentor_id === "") &&
                                activeSession.session_status === "not_booked" && (
                                  <div className=" w-full p-[12px] border-[0.5px] border-[#DC2626] rounded-[8px] my-[14px] bg-[#FFEDED]">
                                    <p className="text-[#DC2626] text-[14px] font-[600]">Select Temporary Mentor</p>
                                    <div className="flex items-end justify-between">
                                      <p className="w-[280px] text-[12px] font-[500]">
                                        There is no slot available for your current mentor, if you want a session on
                                        urgent basis you can select a temporary mentor for this session.
                                      </p>
                                      <Button
                                        variant={"primary"}
                                        disabled={
                                          !isPaymentDone
                                            ? true
                                            : activeSession?.session_id === "msid1" && isBookNowEnabled
                                              ? false
                                              : activeSessionIndex === 0 &&
                                                  activeAttemptIndex === 0 &&
                                                  !isBookNowEnabled
                                                ? true
                                                : activeSessionIndex === 0 && activeAttemptIndex === 0 && !isPaymentDone
                                                  ? true
                                                  : activeSessionIndex === 0 && activeAttemptIndex === 0
                                                    ? false
                                                    : !isBookNowEnabled || !isPaymentDone || student?.mentor_id === ""
                                        }
                                        onClick={() => setIsSelectionStarted("temp")}
                                      >
                                        Select Temp. Mentor
                                      </Button>
                                    </div>
                                  </div>
                                )}

                              {(((activeSession?.session_status === "Completed" ||
                                activeSession?.session_status === "Taken Elsewhere") &&
                                isEmpty(activeSession?.student_review)) ||
                                (activeSession?.session_status === "Booked" &&
                                  statusTimeDifference <= 0 &&
                                  !activeSession?.mentor_absent)) && (
                                <div className="border-[0.5px] border-[#DC2626] p-[12px] rounded-[8px] my-[14px]">
                                  <p className="text-[#DC2626] text-[14px] font-[600]">Mentor Session Confirmation!</p>
                                  <div className="flex justify-between">
                                    <p className="text-[#646464] text-[12px] font-[500] w-[400px]">
                                      It is mandatory for each student to provide mentor session status in order to
                                      activate next mentor session.
                                    </p>
                                    <Button
                                      variant={"primary"}
                                      border="thin"
                                      onClick={() => {
                                        setReviewIndex(activeSession?.id)
                                        setStudentFeedbackModal(true)
                                      }}
                                    >
                                      Update Staus
                                    </Button>
                                  </div>
                                </div>
                              )}

                              {activeSession?.cancel_reason && (
                                <div className="h-[153px] w-full p-[12px] bg-white my-[16px] rounded-[8px]">
                                  <p className="text-[#DC2626] text-[14px] font-[600]">Mentee Cancellation Reason</p>
                                  <div className="p-[10px] my-[4px] overflow-y-auto bg-[#F9F9F9] rounded-[8px] h-[104px] text-[14px] font-[400]">
                                    {activeSession?.cancel_reason}
                                  </div>
                                </div>
                              )}
                              {activeSession?.mentor_cancel_reason && (
                                <div className="h-[153px] w-full p-[12px] bg-white my-[16px] rounded-[8px]">
                                  <p className="text-[#DC2626] text-[14px] font-[600]">Mentor Cancellation Reason</p>
                                  <div className="p-[10px] my-[4px] overflow-y-auto bg-[#F9F9F9] rounded-[8px] h-[104px] text-[14px] font-[400]">
                                    {activeSession?.mentor_cancel_reason}
                                  </div>
                                </div>
                              )}

                              {activeSession.session_status !== "Cancelled by Mentee" &&
                                activeSession.session_status !== "Cancelled by Mentor" && (
                                  <>
                                    <div className="bg-white my-[14px] rounded-[4px] p-[12px] border-[0.5px] border-[#D3D3D3] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]">
                                      <div className="flex justify-between">
                                        <p className="text-[#000] text-[14px] font-[600]">Student Feedback</p>
                                      </div>

                                      {isEmpty(activeSession?.student_review) ? (
                                        <div className="h-[104px] flex justify-center items-center ">
                                          <p className="w-[350px] text-[12px] font-[400] text-[#646464] text-center">
                                            Feedback will be visible once you have given your feedback for the current
                                            session.
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="my-[16px] text-[#333] bg-[#F9F9F9] py-[16px] px-[24px] rounded-[8px]">
                                          <p className="text-[14px] font-[600]">
                                            Student Feedback For Mentor <span className="text-[#DC2626]">*</span>
                                          </p>

                                          <div>
                                            {Array.isArray(activeSession?.student_review?.rating) && (
                                              <>
                                                <p className="text-[14px] font-[400]">
                                                  Rate between 1 to 5 (with 1 = poor; 5= excellent)
                                                </p>
                                                {activeSession?.student_review?.rating.map((item: any, i: number) => (
                                                  <div className="flex justify-between my-[16px] items-center">
                                                    <li>{item.title}</li>

                                                    <div className="flex gap-[8px] mt-[8px]">
                                                      {Array.from({ length: 5 }, (_, index) => (
                                                        <div key={index}>
                                                          {index < item.star ? (
                                                            <AwardIcon fill="#FBBF24" className="size-[16px]" />
                                                          ) : (
                                                            <AwardEmpty className="size-[16px]" />
                                                          )}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                ))}
                                              </>
                                            )}
                                            {activeSession?.student_review?.comment && (
                                              <>
                                                <p className="text-[14px] font-[600]">Custom Feedback</p>
                                                <textarea
                                                  className="text-[#646464] my-1 h-[195px] w-full border-[#D3D3D3] focus:border-[#D3D3D3] rounded-[4px]"
                                                  placeholder="Write your feedback..."
                                                  value={activeSession?.student_review?.comment}
                                                  disabled
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="bg-white my-[14px] rounded-[4px] p-[12px] border-[0.5px] border-[#D3D3D3] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]">
                                      <div className="flex justify-between">
                                        <p className="text-[#000] text-[14px] font-[600]">Mentor Feedback</p>
                                      </div>

                                      {isEmpty(activeSession?.mentor_feedback) ? (
                                        <div className="h-[104px] flex justify-center items-center ">
                                          <p className="w-[350px] text-[12px] font-[400] text-[#646464] text-center">
                                            Feedback will be visible once you have given your feedback for the current
                                            session.
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="my-[16px] text-[#333] bg-[#F9F9F9] py-[16px] px-[24px] rounded-[8px]">
                                          <p className="text-[14px] font-[600]">
                                            Mentor Feedback For Student <span className="text-[#DC2626]">*</span>
                                          </p>
                                          {(activeSession?.mentor_feedback?.remarks ||
                                            activeSession?.mentor_feedback?.feedback) &&
                                            (activeSession?.mentor_feedback?.actions ||
                                              activeSession?.mentor_feedback?.action_items) && (
                                              <>
                                                <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
                                                  <div className="m-auto flex w-[850px] transform rounded border border-new-accent bg-new-solid-white font-medium">
                                                    <div className="h-auto w-[238px] flex-col items-start gap-4 border-r-[1px] border-r-[#E9E9E9] p-6">
                                                      <h3 className="gap-2 text-new-accent">Mentor Session</h3>
                                                      <p className="text-neutral flex items-center">
                                                        {" "}
                                                        #{activeSession?.name}
                                                      </p>
                                                    </div>
                                                    <div className="w-[612px] p-6">
                                                      <div className="relative flex w-full">
                                                        <h4 className="flex-1 text-[#414141]">
                                                          Mentor Session Feedback
                                                        </h4>
                                                      </div>
                                                      <div className="flex flex-col gap-2">
                                                        <div className="flex ">
                                                          <div className="w-[180px]">
                                                            <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">
                                                              Status
                                                            </p>
                                                          </div>
                                                          <div className="flex-1 items-center text-new-success">
                                                            Present
                                                          </div>
                                                        </div>

                                                        <div className="flex ">
                                                          <div className="w-[180px]">
                                                            <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">
                                                              Date / Time :
                                                            </p>
                                                          </div>
                                                          <div className="flex-1 items-center">
                                                            {!newMentorshipFlow
                                                              ? activeSession?.date
                                                              : activeSession?.session_date}{" "}
                                                          </div>
                                                        </div>
                                                        {newMentorshipFlow ? (
                                                          <div className="flex ">
                                                            <div className="w-[180px]">
                                                              <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">
                                                                Your Rating :
                                                              </p>
                                                            </div>
                                                            <div className="flex-1 items-center text-[#FBBF24]">
                                                              <div className="flex gap-2">
                                                                {Array(5)
                                                                  .fill(0)
                                                                  .map((_, i) =>
                                                                    !isEmpty(activeSession?.mentor_feedback) ? (
                                                                      <span key={i}>
                                                                        <StarIcon
                                                                          className="h-6 w-6 text-[]"
                                                                          pathClassName={
                                                                            activeSession?.mentor_feedback?.rating >=
                                                                            i + 1
                                                                              ? "fill-[#FBBF24]"
                                                                              : "fill-border"
                                                                          }
                                                                        />
                                                                      </span>
                                                                    ) : null
                                                                  )}
                                                                {activeSession?.mentor_feedback?.rating}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <div className="flex ">
                                                          <div className="w-[180px]">
                                                            <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">
                                                              Remark from mentor :
                                                            </p>
                                                          </div>
                                                          <div className="h-full flex-1 items-center rounded-sm bg-[#F7F7F7] p-2 pl-2">
                                                            <p>
                                                              {activeSession?.mentor_feedback?.remarks ||
                                                                activeSession?.mentor_feedback?.feedback}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="flex ">
                                                          <div className="w-[180px]">
                                                            <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">
                                                              Work Action for student :{" "}
                                                            </p>
                                                          </div>
                                                          <div className="flex-1 items-center">
                                                            <div className="h-full flex-1 items-center rounded-sm bg-[#F7F7F7] p-2 pl-2 ">
                                                              <p>
                                                                {activeSession?.mentor_feedback?.actions ||
                                                                  activeSession?.mentor_feedback?.action_items}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          <div>
                                            {Array.isArray(activeSession?.mentor_feedback?.rating) && (
                                              <>
                                                <p className="text-[14px] font-[400]">
                                                  Rate between 1 to 5 (with 1 = poor; 5= excellent)
                                                </p>
                                                {activeSession?.mentor_feedback?.rating.map((item: any, i: number) => (
                                                  <div className="flex justify-between my-[16px] items-center">
                                                    <li>{item.title}</li>

                                                    <div className="flex gap-[8px] mt-[8px]">
                                                      {Array.from({ length: 5 }, (_, index) => (
                                                        <div key={index}>
                                                          {index < item.star ? (
                                                            <AwardIcon fill="#FBBF24" className="size-[16px]" />
                                                          ) : (
                                                            <AwardEmpty className="size-[16px]" />
                                                          )}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                ))}
                                              </>
                                            )}
                                            {activeSession?.mentor_feedback?.comment && (
                                              <>
                                                <p className="text-[14px] font-[600]">Custom Feedback</p>
                                                <textarea
                                                  className="text-[#646464] my-1 h-[195px] w-full border-[#D3D3D3] focus:border-[#D3D3D3] rounded-[4px]"
                                                  placeholder="Write your feedback..."
                                                  value={activeSession?.mentor_feedback?.comment}
                                                  disabled
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                            </>
                          )
                        })()}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="mx-2">
                <Table data={tableData} />
              </div>
            )}
          </>
          <Toast data={toast} onClick={() => changeToastVisibility(false)} />
        </DashboardLayoutv2>
      )}
      {showModal && <NewFeedbackModal saveFeedback={saveFeedback} message="Mentor" />}
    </>
  )
}

export default NewMentorView
