import Loader from "core/components/Loader"
import { ChevronDownIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import React, { useState, useEffect, useRef } from "react"
import listenForOutsideClick from "./listen-for-outside-clicks"

export default function DropDownMenu(props: any) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const menuRef = useRef(null)
  const [listening, setListening] = useState(false)
  useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen))

  const handleOptionClick = (option: any) => {
    props.id == 2 ? props.setTopic(option) : props.id == 3 ? props.setQuestion(option) : props.setModule(option)
    setIsOpen(false)
  }

  return (
    <div
      className={cn(
        "border-1 relative flex h-[33px] w-[320px] cursor-pointer rounded-[2px] border border-gray-400 px-4 py-2",
        (props.index == 1 && props.id == 2 && props.module == "") ||
          (props.id == 3 && (props.module == "" || props.topic == "")) ||
          (props.index == 2 && props.id == 3 && props.topic == "")
          ? "pointer-events-none opacity-50"
          : ""
      )}
      ref={menuRef}
      onClick={toggleDropdown}
    >
      <div className="relative flex h-[20px] w-[287px] items-center justify-between">
        <div className="truncate text-sm font-normal">
          {props.id == 2
            ? props.topic == ""
              ? props.module == "Operational Issue"
                ? "Select an Issue"
                : "Select a Chapter"
              : props.topic
            : props.id == 3
              ? props.question == ""
                ? "Select a Question"
                : props.question
              : props.module == ""
                ? "Select a Query"
                : props.module}
        </div>
        <div className="absolute bottom-0 right-0 ml-auto items-center text-new-accent">
          <ChevronDownIcon className={cn("h-6 w-6", isOpen && "rotate-180")} />
        </div>
      </div>
      {isOpen && (
        <ul
          className={cn(
            "border-1 absolute top-[35px] left-0 flex max-h-[160px] w-full list-none flex-col overflow-y-auto border bg-white px-6 py-4",
            props.zindex
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {isEmpty(props.options) ? (
            <div className="flex min-h-[22px] w-[220px] items-center justify-center">
              <Loader />
            </div>
          ) : (
            props.options.map((option: any) => (
              <li
                ref={option === props.topic ? props.ref : null}
                className="min-h-[30px] w-[220px] cursor-pointer truncate py-[5px] font-semibold"
                key={option}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  )
}
