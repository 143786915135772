import { cn } from "core/lib/utils"
import { ChevronDownIcon } from "lucide-react"
import { useState } from "react"
export default function MoreFilterSection({ filters, setFilters }: { filters: any; setFilters: (value: any) => void }) {
  const [isOpen, setIsOpen] = useState(false)
  const linkedinOptions = ["LinkedIn Status", "Approved", "Not Approved", "Not Uploaded"]
  const resumeOptions = ["Resume Status", "Approved", "Not Approved", "Not Uploaded"]
  const handelFiltersChange = (value: string | number | boolean, key: string) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [key]: value,
    }))
  }
  return (
    <div className="flex flex-col gap-6">
      <div className="border rounded-md p-4">
        <div className="flex items-center justify-between" onClick={() => setIsOpen(!isOpen)}>
          <div className="text-[16px] font-medium text-[#000]">More filters</div>
          <span className="ml-auto">
            <ChevronDownIcon className={cn("size-5 transition-transform", isOpen && "rotate-180")} />
          </span>
        </div>
        {isOpen && (
          <div className="pt-4 flex flex-col gap-4">
            <div className="flex gap-4">
              <button
                onClick={() => {
                  handelFiltersChange("", "queryMockStatus")
                  handelFiltersChange(false, "queryIsPlacementDone")
                }}
                className={cn(
                  "px-4 py-[9px] rounded-[4px] border bg-[#F8F8F8] text-[#646464]",
                  filters.queryMockStatus === "" && !filters.queryIsPlacementDone && "bg-new-accent text-white"
                )}
              >
                All Students
              </button>

              <div className="flex gap-6 rounded-md border px-4 bg-[#F8F8F8]">
                <div className="flex gap-2 items-center justify-center">
                  <input
                    className="checked:bg-[#162456]"
                    type="radio"
                    id="mock_cleared"
                    name="mock_status"
                    value="Cleared"
                    checked={filters.queryMockStatus === "Cleared"}
                    onChange={() => handelFiltersChange("Cleared", "queryMockStatus")}
                  />
                  <label
                    className={cn("text-[#646464]", filters.queryMockStatus === "Cleared" && "text-[#333]")}
                    htmlFor="mock_cleared"
                  >
                    Mock Cleared
                  </label>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <input
                    className="checked:bg-[#162456]"
                    type="radio"
                    id="bridge_course"
                    name="mock_status"
                    value="Not Cleared"
                    checked={filters.queryMockStatus === "Not Cleared"}
                    onChange={() => handelFiltersChange("Not Cleared", "queryMockStatus")}
                  />
                  <label
                    className={cn("text-[#646464]", filters.queryMockStatus === "Not Cleared" && "text-[#333]")}
                    htmlFor="bridge_course"
                  >
                    Bridge Course
                  </label>
                </div>
              </div>

              <div className="flex gap-6 rounded-md border px-4 bg-[#F8F8F8]">
                <div className="flex gap-2 items-center justify-center">
                  <input
                    className="checked:bg-[#162456]"
                    type="radio"
                    id="placed"
                    name="is_placement_done"
                    value="Placed"
                    checked={filters.queryIsPlacementDone}
                    onChange={() => handelFiltersChange(true, "queryIsPlacementDone")}
                  />
                  <label
                    className={cn("text-[#646464]", filters.queryIsPlacementDone === true && "text-[#333]")}
                    htmlFor="is_placement_done"
                  >
                    Placed
                  </label>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <input
                    className="checked:bg-[#162456]"
                    type="radio"
                    id="not_placed"
                    name="is_placement_done"
                    value="Not - Placed"
                    checked={!filters.queryIsPlacementDone}
                    onChange={() => handelFiltersChange(false, "queryIsPlacementDone")}
                  />
                  <label
                    className={cn("text-[#646464]", filters.queryIsPlacementDone === false && "text-[#333]")}
                    htmlFor="is_placement_done"
                  >
                    Not - Placed
                  </label>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <select
                className="w-[164px] rounded-sm border border-[#D3D3D3] px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                value={filters.queryResumeStatus || "Select Resume"}
                onChange={(e) =>
                  handelFiltersChange(e.target.value === "Select Resume" ? "" : e.target.value, "queryResumeStatus")
                }
              >
                {resumeOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                className="w-[164px] rounded-sm border border-[#D3D3D3] px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                value={filters.queryLinkedInStatus || "Select LinkedIn"}
                onChange={(e) =>
                  handelFiltersChange(e.target.value === "Select LinkedIn" ? "" : e.target.value, "queryLinkedInStatus")
                }
              >
                {linkedinOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <button
                onClick={() => {
                  handelFiltersChange("Cleared", "queryMockStatus")
                  handelFiltersChange("Approved", "queryResumeStatus")
                  handelFiltersChange("Approved", "queryLinkedInStatus")
                }}
                className={cn(
                  "px-4 rounded-[4px] border bg-[#F8F8F8] text-[#646464]",
                  filters.queryMockStatus === "Cleared" &&
                    filters.queryResumeStatus === "Approved" &&
                    filters.queryLinkedInStatus === "Approved" &&
                    !filters.queryIsPlacementDone &&
                    "bg-new-accent text-white"
                )}
              >
                Placement Ready Students
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
