import { Auth } from "domain/model/Auth"
import {
  STR_GET_UPCOMING_CLASSES,
  API_V4_STUDENT,
  API_GET_NEXT_SCHEDULE_CLASS_TIMESTAMP,
  STUDENT,
  UPCOMING_CLASSES,
} from "core/constants/strings"
import SessionDataSource from "data/dataSource/Student/SessionDataSource"
import { format } from "date-fns"
import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"

const server = new Server()
export default class SessionAPIDataSourceImpl implements SessionDataSource {
  async getAllSessions(auth: Auth, timestamp: number): Promise<any> {
    let result: any = {}
    // let timestmp=Math.ceil(timestamp/1000)
    const { data: response } = await server.post(
      pn(STUDENT, UPCOMING_CLASSES),
      { timestamp },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    result = {
      data: [],
      success: response?.success,
    }

    const startDate = new Date(timestamp * 1000)
    const year = startDate.getFullYear()
    const month = startDate.getMonth()

    const daysInMonth = new Date(year, month + 1, 0).getDate()
    for (let i = 0; i < daysInMonth; i++) {
      const ts = new Date(year, month, i + 1).getTime()
      result.data[i] = {
        date: new Date(ts).getDate(),
        day: format(new Date(ts), "EEE"),
        timestamp: ts,
        sessions: [],
      }

      const sessionsForDay = Array.isArray(response?.data?.[i]) ? response.data[i] : []
      console.log("date:", new Date(ts).getDate(), "sessions:", sessionsForDay)
      sessionsForDay.forEach((session: any) => {
        result.data[i].sessions.push({
          module_name: session?.module_name,
          chapter_name: session?.chapter_name,
          is_attended: session?.is_attended,
        })
      })
    }

    return result
  }

  async getNextClassTimestamp(auth: Auth): Promise<any> {
    const { data: response } = await postAPI(pn(API_V4_STUDENT, API_GET_NEXT_SCHEDULE_CLASS_TIMESTAMP), {
      id_token: auth.id_token,
    })

    return response
  }
}
