import { Auth } from "domain/model/Auth"
// import { AllStudentsReqType } from "domain/model/PlacementDashboard"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface EditPlacementStatusUsecase {
  invoke(auth: Auth, docId: any, statusType: string, newStatus: string | boolean): Promise<any>
}

export class EditPlacementStatus implements EditPlacementStatusUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: any, statusType: string, newStatus: string | boolean): Promise<any> {
    return await this.repository.editPlacementStatus(auth, docId, statusType, newStatus)
  }
}
