import { Auth } from "domain/model/Auth"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface GetReviewsByIdUsecase {
  invoke(auth: Auth, docId: String): Promise<any>
  edit(auth: Auth, docId: String, updatedReview: any): Promise<any>
}

export class GetReviewsById implements GetReviewsByIdUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: string): Promise<any> {
    return await this.repository.getReviewsById(auth, docId)
  }

  async edit(auth: Auth, docId: string, updatedReview: any): Promise<any> {
    return await this.repository.editReview(auth, docId, updatedReview)
  }
}
