import { clsx, type ClassValue } from "clsx"
import { Role } from "core/constants/types"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function redirectPath(role: string) {
  if (role.length === 0) {
    return ""
  }
  const routes = {
    user: "/home",
    admin: "/admin/home",
    mentor: "/mentor/home",
    developer: "/developer/home",
  }
  return routes[role as Role]
}

export function pathList(pathname: string) {
  return pathname.split("#")[0].split("?")[0].split("/").filter(Boolean)
}
