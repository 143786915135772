import StandardSelect from "core/components/v2/StandardSelect"
import usePlacementDetailsFormModel from "presentation/Student/Placement/components/PlacementDetailsFormModel"
import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"

interface PlacementDetailsForm {
  onClose: () => void
  docId: any
}

const PlacementDetailsForm: React.FC<PlacementDetailsForm> = ({ onClose, docId }) => {
  const {
    courseTypeOptions,
    fetchStudentDetails,
    formData,
    setFormData,
    handleSubmit,
    setId,
    resumeFile,
    setResumeFile,
  } = usePlacementDetailsFormModel()

  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    setId(docId)
    fetchStudentDetails(docId)
  }, [])

  const onDrop = (acceptedFiles: File[]) => {
    const pdfFile = acceptedFiles.find((file) => file.type === "application/pdf")
    if (pdfFile) {
      setResumeFile(pdfFile)
    } else {
      alert("Please upload a valid PDF file.")
    }
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSelect = (value: string, key: string) => {
    setFormData({ ...formData, [key]: value })
  }

  const handleNoticePeriod = (value: string, key: string) => {
    setFormData({
      ...formData,
      [key]: value === "Yes" ? "Negotiable" : "",
    })
  }

  const handleNext = () => {
    if (step < 4) {
      if (validateStep()) {
        setStep(step + 1)
      }
    }
  }

  const handlePrevious = () => {
    if (step > 1) setStep(step - 1)
  }

  const validateStep = () => {
    const stepErrors: Record<string, string> = {}
    switch (step) {
      case 1:
        if (!formData.name) stepErrors.name = "Name is required"
        if (!formData.linkedin) stepErrors.linkedin = "LinkedIn URL is required"
        if (!formData.contact) stepErrors.contact = "Contact Number is required"
        break
      case 2:
        if (!formData.working) stepErrors.working = "This field is required"
        if (!formData.yearOfGraduation) stepErrors.yearOfGraduation = "Year of Graduation is required"
        if (!formData.collegeName) stepErrors.collegeName = "College Name is required"
        // if (!formData.currentCompany) stepErrors.currentCompany = "Current Company Name is required"
        if (!formData.dreamCompany) stepErrors.dreamCompany = "Dream Company is required"
        break
      case 3:
        if (!formData.jobProfileAspiration) stepErrors.jobProfileAspiration = "Job Profile aspiration is required"
        if (!formData.currentSalary) stepErrors.currentSalary = "Current Salary is required"
        if (!formData.desiredLocation) stepErrors.desiredLocation = "Desired Location is required"
        if (!formData.noticePeriod) stepErrors.noticePeriod = "Notice Period is required"
        break
      case 4:
        if (!formData.yearsOfExperience) stepErrors.yearsOfExperience = "Years of Experience is required"
        if (!formData.skills) stepErrors.skills = "Skills/Tech Stack is required"
        if (!resumeFile) stepErrors.resume = "Resume is required"
        break
      default:
        break
    }
    setErrors(stepErrors)
    return Object.keys(stepErrors).length === 0
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="flex flex-col gap-4">
              <div>
                <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                  Name of the student <span className="text-[#DC2626]">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                    errors.name ? "border-red-500" : "border-[#D3D3D3]"
                  } rounded`}
                  placeholder="Type here"
                  required
                />
              </div>
              <div>
                <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold ">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={() => console.warn("Email cannot be changed")}
                  className="w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm  border border-[#D3D3D3] rounded"
                  required
                />
              </div>
              <div>
                <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                  LinkedIn URL <span className="text-[#DC2626]">*</span>
                </label>
                <input
                  type="url"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                    errors.linkedin ? "border-red-500" : "border-[#D3D3D3]"
                  } rounded`}
                  placeholder="Type here"
                  required
                />
              </div>
              <div>
                <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                  Contact Number <span className="text-[#DC2626]">*</span>
                </label>
                <input
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                    errors.contact ? "border-red-500" : "border-[#D3D3D3]"
                  } rounded`}
                  placeholder="Type here"
                  required
                />
              </div>
              <div>
                <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                  Student Batch <span className="text-[#DC2626]">*</span>
                </label>
                <input
                  type="text"
                  name="batch"
                  value={formData.batch}
                  className="w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm  border border-[#D3D3D3] rounded"
                  onChange={() => console.warn("Batch cannot be changed")}
                  placeholder="Type here"
                  required
                />
              </div>
            </div>
          </>
        )
      case 2:
        return (
          <div className="flex flex-col gap-4">
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Working or Fresher? <span className="text-[#DC2626]">*</span>
              </label>

              <StandardSelect
                type="text"
                name="workingOrFresher"
                options={courseTypeOptions}
                value={formData.working}
                onChange={(value: string) => handleSelect(value, "working")}
                className={`border ${errors.working ? "border-red-500" : "border-[#D3D3D3]"} rounded`}
                placeholder="Type here"
              />
              {errors.working && <p className="text-red-500 text-xs">{errors.working}</p>}
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Year of Graduation? <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="number"
                name="yearOfGraduation"
                value={formData.yearOfGraduation}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.yearOfGraduation ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                College Name <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="text"
                name="collegeName"
                value={formData.collegeName}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.collegeName ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">Current Company Name</label>
              <input
                type="text"
                name="currentCompany"
                value={formData.currentCompany}
                onChange={handleChange}
                className="w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm  border border-[#D3D3D3] rounded"
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Dream Company <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="text"
                name="dreamCompany"
                value={formData.dreamCompany}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.dreamCompany ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
          </div>
        )
      case 3:
        return (
          <div className="flex flex-col gap-4">
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Job Profile aspiration? <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="text"
                name="jobProfileAspiration"
                value={formData.jobProfileAspiration}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.jobProfileAspiration ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Currently Salary (Freshers write N/A) <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="text"
                name="currentSalary"
                value={formData.currentSalary}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.currentSalary ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Desired Location <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="text"
                name="desiredLocation"
                value={formData.desiredLocation}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.currentSalary ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Notice period In Days <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="number"
                name="noticePeriod"
                value={formData.noticePeriod}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.noticePeriod ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Notice period Negotiable ?<span className="text-[#DC2626]">*</span>
              </label>

              <StandardSelect
                type="text"
                name="negotiable"
                options={["Yes", "No"]}
                value={formData?.negotiable ? "Yes" : "No"}
                onChange={(value: string) => handleNoticePeriod(value, "negotiable")}
                className=" border border-[#D3D3D3] rounded"
                placeholder="Type here"
              />
            </div>
          </div>
        )
      case 4:
        return (
          <div className="flex flex-col gap-4">
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Years of experience <span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="number"
                name="yearsOfExperience"
                value={formData.yearsOfExperience}
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.yearsOfExperience ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>
            <div>
              <label className="block mt-2 mb-1 text-[14px] leading-[17px] font-semibold">
                Skills / Tech Stack (React,Node,..)<span className="text-[#DC2626]">*</span>
              </label>
              <input
                type="text"
                name="skills"
                value={formData.skills} // Adjust value based on your formData
                onChange={handleChange}
                className={`w-subtitle h-8 pt-2 pr-4 pb-2 pl-4 text-sm border ${
                  errors.skills ? "border-red-500" : "border-[#D3D3D3]"
                } rounded`}
                placeholder="Type here"
              />
            </div>

            {/* Drag-and-Drop File Upload Section */}
            <div>
              <label className="block mt-4 mb-1 text-[14px] leading-[17px] font-semibold">
                Upload Resume <span className="text-[#DC2626]">*</span>
              </label>
              {errors.resume && <p className="text-red-500 text-xs">{errors.resume}</p>}
              <div
                {...getRootProps()}
                className={`border-dashed border-2 ${
                  isDragActive ? "border-green-500 bg-green-100" : "border-gray-300"
                } p-4 rounded flex flex-col items-center justify-center cursor-pointer`}
              >
                <input {...getInputProps()} />
                {resumeFile ? (
                  <div className="text-green-500 text-sm font-semibold">
                    <p>✔ Resume Uploaded</p>
                    <p>
                      {resumeFile.name} or{" "}
                      <span className="text-blue-500 underline cursor-pointer" onClick={() => setResumeFile(null)}>
                        Upload new PDF file
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="text-gray-500 text-sm text-center">
                    <p>{isDragActive ? "Drop your PDF here..." : "Drag and drop a PDF file or"}</p>
                    <span className="text-blue-500 underline mt-1">Browse PDF files</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      {/* Form Container */}
      <div className="bg-white w-[347px] h-[580px] p-5 rounded shadow-lg relative">
        <h2 className=" text-blue-900 font-semibold text-[14px] leading-5 mb-4">Placement Detail</h2>

        {/* Progress Indicator */}
        <div className="flex text-[#646464] justify-start mb-6 items-center">
          {[1, 2, 3, 4].map((num) => (
            <div key={num} className="flex text-xs items-center">
              <div
                className={`w-[18px] h-[19px] flex items-center justify-center rounded  font-bold ${
                  num <= step ? "bg-[#07B42D] text-white" : ""
                }`}
              >
                {num}
              </div>
              {/* Dashed line */}
              {num < 4 && (
                <div
                  className={`w-8 md:w-8 border-t-2 ${
                    num < step ? "border-[#646464]" : "border-[#646464]"
                  } border-dashed `}
                />
              )}
            </div>
          ))}
        </div>

        {renderStep()}

        {/* Navigation buttons */}
        <div className="mt-10 flex gap-[14px] justify-end">
          <button
            onClick={handlePrevious}
            disabled={step === 1}
            className={` text-[14px] leading-[17px] w-[82px] h-[35px] border-[1px] rounded ${
              step === 1 ? "border-[#D3D3D3] bg-white text-[#DEDEDE]" : "bg-white border-[#D3D3D3] text-gray-500"
            }`}
          >
            Previous
          </button>
          {step < 4 ? (
            <button
              onClick={handleNext}
              className=" bg-[#001665] text-white  text-[14px] leading-[17px] w-16 h-[35px] rounded "
            >
              Next
            </button>
          ) : (
            <button
              onClick={(event) => {
                if (validateStep()) {
                  handleSubmit(event)
                  onClose()
                }
              }}
              className=" bg-[#001665] text-white text-[14px] leading-[17px] w-[80px] h-[35px] rounded "
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PlacementDetailsForm
