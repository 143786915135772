export default function HyperLinkIcon({ className }: any) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_5154_8816)">
        <rect width="16" height="16" rx="2" fill="white" />
        <path
          d="M12.2428 10.3568L11.3002 9.41284L12.2428 8.47017C12.5546 8.16114 12.8022 7.79357 12.9716 7.38857C13.1409 6.98357 13.2285 6.54911 13.2295 6.11015C13.2304 5.67118 13.1447 5.23635 12.9772 4.83061C12.8096 4.42487 12.5636 4.05622 12.2532 3.74583C11.9428 3.43543 11.5741 3.1894 11.1684 3.02185C10.7627 2.85431 10.3278 2.76856 9.88886 2.76952C9.44989 2.77048 9.01544 2.85814 8.61044 3.02746C8.20544 3.19677 7.83787 3.44442 7.52884 3.75617L6.58617 4.6995L5.64284 3.75684L6.58684 2.81417C7.46205 1.93896 8.6491 1.44727 9.88684 1.44727C11.1246 1.44727 12.3116 1.93896 13.1868 2.81417C14.0621 3.68938 14.5537 4.87643 14.5537 6.11417C14.5537 7.35191 14.0621 8.53896 13.1868 9.41417L12.2435 10.3568H12.2428ZM10.3575 12.2422L9.41417 13.1848C8.53896 14.0601 7.35191 14.5517 6.11417 14.5517C4.87643 14.5517 3.68938 14.0601 2.81417 13.1848C1.93896 12.3096 1.44727 11.1226 1.44727 9.88484C1.44727 8.6471 1.93896 7.46005 2.81417 6.58484L3.7575 5.64217L4.70017 6.58617L3.7575 7.52884C3.44575 7.83787 3.19811 8.20544 3.02879 8.61044C2.85947 9.01544 2.77182 9.44989 2.77086 9.88886C2.76989 10.3278 2.85565 10.7627 3.02319 11.1684C3.19073 11.5741 3.43676 11.9428 3.74716 12.2532C4.05756 12.5636 4.42621 12.8096 4.83194 12.9772C5.23768 13.1447 5.67251 13.2304 6.11148 13.2295C6.55045 13.2285 6.9849 13.1409 7.3899 12.9716C7.7949 12.8022 8.16247 12.5546 8.4715 12.2428L9.41417 11.3002L10.3575 12.2428V12.2422ZM9.8855 5.17084L10.8288 6.11417L6.11484 10.8275L5.1715 9.88484L9.8855 5.1715V5.17084Z"
          fill="#001018"
        />
      </g>
      <defs>
        <clipPath id="clip0_5154_8816">
          <rect width="16" height="16" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
