import { getAPI } from "core/utils/axios"
import {
  API_V4_ADMIN,
  API_GET_ICPSTUDENTS_FROM_ACTIVE_BATCHES,
  API_GET_ICPSTUDENTS_FROM_NON_ACTIVE_BATCHES,
  API_GET_ICPSTUDENTS_FROM_BATCHES,
} from "core/constants/strings"
import { Auth } from "domain/model/Auth"
import ICPStudentDataSource from "data/dataSource/Admin/ICPStudentDataSource"
import pn, { query } from "core/utils/pn"

export class ICPStudentAPIDataSource implements ICPStudentDataSource {
  async getICPStudentsOfBatch(auth: Auth): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_ICPSTUDENTS_FROM_ACTIVE_BATCHES), {
          id_token: auth?.id_token,
        })
      )
      return response
    } catch (error) {
      console.error("Error in ICPStudentAPIDataSource:", error)
      throw error
    }
  }

  async getICPStudentsOfNonactiveBatch(auth: Auth): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_ICPSTUDENTS_FROM_NON_ACTIVE_BATCHES), {
          id_token: auth?.id_token,
        })
      )
      return response
    } catch (error) {
      console.error("Error in ICPStudentAPIDataSource:", error)
      throw error
    }
  }

  async getICPStudentsOfAllBatch(auth: Auth): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_ICPSTUDENTS_FROM_BATCHES), {
          id_token: auth?.id_token,
        })
      )
      return response
    } catch (error) {
      console.error("Error in ICPStudentAPIDataSource:", error)
      throw error
    }
  }
}
