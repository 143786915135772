import { Auth } from "domain/model/Auth"
import { PlacementRepository } from "domain/repository/Student/PlacementRepository"

export interface GetStudentDetailsuseCase {
  invoke(auth: Auth, docId: string): Promise<any>
}

export class GetStudentDetails implements GetStudentDetailsuseCase {
  private repository: PlacementRepository

  constructor(repository: PlacementRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, docId: string): Promise<any> {
    return await this.repository.getStudentDetails(auth, docId)
  }
}
