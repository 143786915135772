import pn from "core/utils/pn"
import { postAPI, Server } from "core/utils/axios"
import {
  API_CHECK_MONTHLY_FEEDBACK,
  API_GET_MONTHLY_FEEDBACK,
  API_GET_MONTHLY_FEEDBACK_ARCHIVED_DATA,
  API_SUBMIT_MONTHLY_FEEDBACK,
  API_V4_ADMIN,
  API_V4_STUDENT,
  API_STUDENT,
} from "core/constants/strings"
import MonthlyFeedbackDataSource from "data/dataSource/Student/MonthlyFeedbackDataSource"
import { Auth } from "domain/model/Auth"

const server = new Server()

export default class MonthlyFeedbackAPIDataSourceImpl implements MonthlyFeedbackDataSource {
  async submitMonthlyFeedback(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_MONTHLY_FEEDBACK), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async checkMonthlyFeedback(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(API_STUDENT, API_CHECK_MONTHLY_FEEDBACK), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMonthlyFeedback(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MONTHLY_FEEDBACK), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getMonthlyFeedbackArchived(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MONTHLY_FEEDBACK_ARCHIVED_DATA), {
        id_token: auth.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
