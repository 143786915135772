import Model from "core/components/Model"
import Button from "core/components/new/Button"
import React from "react"
import MentorsViewModel from "../MentorsViewModel"
import { isEmpty } from "core/utils/misc"
import { format } from "date-fns"
import UpdateSession from "./UpdateSession"
import DetailsSession from "./DetailsSession"

export default function MentorSessionCard({ data, updateCount, setUpdateCount }: any) {
  const { isModalOpen, isModelOpen2, toggleModal, toggleModal2, changeToastDetails, changeToastVisibility } =
    MentorsViewModel()

  const formatDate = (date: any) => {
    if (!date) return "Invalid Date"
    const parsedDate = new Date(date)
    if (isNaN(parsedDate.getTime())) return "Invalid Date"
    return parsedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const renderFeedbackStatus = () => {
    if (!isEmpty(data.mentor_feedback)) {
      // Feedback Provided
      return (
        <div className="space-y-2">
          <p className="text-green-500 font-semibold">Feedback Provided</p>
        </div>
      )
    } else {
      return <p className="text-red-500 font-semibold">Feedback Not Provided</p>
    }
  }

  return (
    <div className="">
      <div className="flex justify-between px-6 py-4 text-new-neutral-dark shadow">
        <div className="flex items-end gap-x-6">
          <div className="space-y-1">
            <h4 className="mb-1">{data.name}</h4>
            <div className="flex gap-x-4">
              {!isEmpty(data?.student_id) ? (
                <p>{data.student_id !== "" ? data.student_id : "No Student Found"}</p>
              ) : (
                <p className="italic">No Student Found</p>
              )}
              <p>{data.batch}</p>
            </div>
            <p>{formatDate(data.date)}</p>
            {data?.is_goal_setting_session === true ? <p className="text-green-500">Goal Setting Session</p> : null}
          </div>
          <div className="inline-flex items-baseline gap-x-1"></div>
        </div>
        <div className="flex gap-x-12">
          <div className="flex w-[200px] flex-col gap-y-5">
            {renderFeedbackStatus()}
            <p>{data.status ? data.status : "Session Taken"}</p>
          </div>

          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p>
              <strong>AD : </strong>
              {data?.actual_duration
                ? `${Math.floor(data.actual_duration / 60)} hours ${data.actual_duration % 60} min`
                : data?.considered_duration
                  ? `${Math.floor(data.considered_duration / 60)} hours ${data.considered_duration % 60} min`
                  : "0 hours 0 min"}
            </p>

            <Button outlined className="w-fit" onClick={toggleModal2}>
              Detailed Rating
            </Button>
          </div>
          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p>
              <strong>CD : </strong>
              {data.considered_duration
                ? `${Math.floor(data.considered_duration / 60)} hours ${data.considered_duration % 60} min`
                : "0 hours 0 min"}
            </p>
            <Button outlined className="w-fit" onClick={toggleModal}>
              Update
            </Button>
          </div>
        </div>
      </div>
      <Model isOpen={isModalOpen} onClose={toggleModal} width={"max-w-3xl"}>
        <UpdateSession
          updateCount={updateCount}
          setUpdateCount={setUpdateCount}
          changeToastVisibility={changeToastVisibility}
          changeToastDetails={changeToastDetails}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          data={data}
          date={formatDate(data.date)}
        />
      </Model>
      <Model isOpen={isModelOpen2} onClose={toggleModal2} width={"max-w-3xl"}>
        <DetailsSession
          changeToastVisibility={changeToastVisibility}
          changeToastDetails={changeToastDetails}
          isModalOpen={isModelOpen2}
          toggleModal={toggleModal2}
          data={data}
          date={formatDate(data.date)}
        />
      </Model>
    </div>
  )
}
