import { useEffect } from "react"
import LoginLayout from "core/layouts/LoginLayout"
import {
  STR_EMAIL,
  STR_EMAIL_FIELD,
  STR_NEW_PASSWORD_FIELD,
  STR_NEXT,
  STR_OLD_PASSWORD_FIELD,
  STR_PASSWORD,
  STR_PASSWORD_FIELD,
  STR_REGISTERED_EMAIL_ID,
  STR_RESET_PASSWORD,
  STR_RESET_SUBTITLE,
  STR_RESET_TITLE,
  STR_RETYPE_PASSWORD_FIELD,
} from "core/constants/strings"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import useLoginResetViewModel from "./LoginResetViewModel"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import { cn } from "core/lib/utils"

export default function LoginResetView() {
  const {
    toast,
    isResetting,
    checkUserExist,
    changeToastVisibility,
    auth,
    user,
    loading,
    emailValidity,
    inputRef,
    handleInputChange,
    handleResetPassword,
    passwordPageView,
    handleReTypeNewPasswordBlur,
    handleNewPasswordBlur,
    reTypePasswordValidity,
    newPasswordValidity,
    checkNewPasswordValidity,
    checkReTypePasswordValidity,
  } = useLoginResetViewModel()

  useEffect(() => {
    if (user?.newPassword.length > 0) {
      checkNewPasswordValidity()
    }
  }, [user?.newPassword])

  useEffect(() => {
    if (user?.newPassword.length > 0 && user?.reTypePassword.length > 0) {
      checkReTypePasswordValidity()
    }
  }, [user?.newPassword, user?.reTypePassword])

  return (
    <>
      <div className="fixed top-0 left-0 translate-x-[-50%] translate-y-[-50%] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>

        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="absolute flex justify-center left-[60%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} className="w-[370px]" />

      {passwordPageView ? (
        <LoginLayout className="bg-new-solid-white z-10 gap-0" height="474px">
          <form className="flex w-full flex-col gap-8" onSubmit={handleResetPassword}>
            <div className="w-full">
              <h1 className="text-[#162456] text-[24px]">{STR_RESET_TITLE}</h1>
              <p className="text-[14px] text-[#414141] top-0">{STR_RESET_SUBTITLE}</p>
            </div>
            <Input
              ref={inputRef}
              type={STR_PASSWORD_FIELD}
              name={STR_OLD_PASSWORD_FIELD}
              value={user.oldPassword}
              onChange={handleInputChange}
              placeholder={STR_PASSWORD}
              label="Old Password"
              id="old-password"
              className="text-[#333333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
              containerClass="text-sm gap-0 text-[#333333]"
            />
            <div className="relative">
              <Input
                ref={inputRef}
                type={STR_PASSWORD_FIELD}
                name={STR_NEW_PASSWORD_FIELD}
                value={user.newPassword}
                onChange={handleInputChange}
                placeholder={STR_PASSWORD}
                onBlur={handleNewPasswordBlur}
                label="New Password"
                id="new-password"
                className="text-[#333333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
                containerClass="text-sm gap-0 text-[#333333]"
              />
              <span
                className={cn(
                  "absolute top-[calc(100%+4px)] text-xs",
                  newPasswordValidity.status ? "text-new-success" : "text-new-failure"
                )}
              >
                {newPasswordValidity.message}
              </span>
            </div>
            <div className="relative">
              <Input
                ref={inputRef}
                type={STR_PASSWORD_FIELD}
                name={STR_RETYPE_PASSWORD_FIELD}
                value={user.reTypePassword}
                onChange={handleInputChange}
                placeholder={STR_PASSWORD}
                onBlur={handleReTypeNewPasswordBlur}
                label="Re-enter New Password"
                id="re-enter-new-password"
                className="text-[#333333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
                containerClass="text-sm gap-0 text-[#333333]"
              />
              <span
                className={cn(
                  "absolute top-[calc(100%+4px)] text-xs",
                  reTypePasswordValidity.status ? "text-new-success" : "text-new-failure"
                )}
              >
                {reTypePasswordValidity.message}
              </span>
            </div>
            <div className="flex flex-col">
              {user?.newPassword &&
              user?.oldPassword &&
              user?.reTypePassword &&
              newPasswordValidity?.status &&
              reTypePasswordValidity?.status ? (
                <Button loading={loading} className="rounded-md text-sm bg-[#1369EB] mb-[8px]">
                  {STR_RESET_PASSWORD}
                </Button>
              ) : (
                <Button
                  loading={loading}
                  className="rounded-md text-sm bg-[#d3d3d3] mb-[8px] pointer-events-none cursor-not-allowed"
                >
                  {STR_RESET_PASSWORD}
                </Button>
              )}
            </div>
          </form>
        </LoginLayout>
      ) : (
        <LoginLayout height="280px">
          <form className="flex w-full flex-col gap-8" onSubmit={checkUserExist}>
            <div className="w-full space-y-2">
              <h1 className="text-new-accent">{STR_RESET_TITLE}</h1>
              <p>{STR_REGISTERED_EMAIL_ID}</p>
            </div>
            <div className="relative">
              <Input
                ref={inputRef}
                type={STR_EMAIL_FIELD}
                name={STR_EMAIL_FIELD}
                value={user.email}
                placeholder={STR_EMAIL}
                label="Email"
                id="reset-email"
                className="text-[#333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px] cursor-pointer bg-[#EBEBEB] pointer-events-none"
                containerClass="text-sm gap-0 text-[#333]"
              />
              <span
                className={cn(
                  "absolute top-[calc(100%+4px)] text-xs",
                  emailValidity.status ? "text-new-success" : "text-new-failure"
                )}
              >
                {emailValidity.message}
              </span>
            </div>
            <Button loading={isResetting} className="rounded-md text-sm bg-[#1369EB] mb-[8px]">
              {STR_NEXT}
            </Button>
          </form>
        </LoginLayout>
      )}
      <div className="flex justify-center absolute right-[60%] transform scale-y-[-1] scale-x-[-1]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 translate-x-[50%] translate-y-[-50%] scale-y-[-1] scale-x-[-1] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
    </>
  )
}
