import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { GetClassName } from "domain/useCase/Student/Course/GetClassName"
import { SubmitClassFeedbackExternal } from "domain/useCase/Student/Course/SubmitClassFeedbackExternal"
import { useState } from "react"

export default function ClassFeedbackViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const pathname = window.location.pathname
  const sessionId = pathname.substring(pathname.lastIndexOf("/") + 1)
  const [classDetails, setClassDetails] = useState([])

  const submitClassFeddbackExternalUseCase = new SubmitClassFeedbackExternal(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const getClassNameUseCase = new GetClassName(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  async function submitClassFeedbackExternal(feedbackData: any) {
    feedbackData["session_id"] = sessionId
    const response: any = await submitClassFeddbackExternalUseCase.invoke(feedbackData)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Please use your registered email ID only!"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, genError(response, "Feedback submitted successfully!"))
    changeToastVisibility(true)
  }

  async function getClassName(data: any) {
    const response: any = await getClassNameUseCase.invoke(data)

    if (!response?.success) {
      return
    }

    setClassDetails(response?.data)
  }

  return {
    toast,
    changeToastVisibility,
    classDetails,
    submitClassFeedbackExternal,
    getClassName,
    sessionId,
  }
}
