import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"

export interface GetInstructorSessionsUseCase {
  invoke(auth: Auth, month: number, batch: string, module: string): Promise<any>
}

export default class GetInstructorSessions implements GetInstructorSessionsUseCase {
  private repository: SessionRepository

  constructor(repository: SessionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, month: number, batch: string, module: string): Promise<any> {
    return await this.repository.getInstructorSessions(auth, month, batch, module)
  }
}
