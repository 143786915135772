import React from "react"

const BarGraph = ({ className, color }: any) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M2 2V14H14" stroke={color ?? "white"} stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.66699 6.66699H5.33366C4.96547 6.66699 4.66699 6.96547 4.66699 7.33366V10.667C4.66699 11.0352 4.96547 11.3337 5.33366 11.3337H6.66699C7.03518 11.3337 7.33366 11.0352 7.33366 10.667V7.33366C7.33366 6.96547 7.03518 6.66699 6.66699 6.66699Z"
        stroke={color ?? "white"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 3.3335H10.6667C10.2985 3.3335 10 3.63197 10 4.00016V10.6668C10 11.035 10.2985 11.3335 10.6667 11.3335H12C12.3682 11.3335 12.6667 11.035 12.6667 10.6668V4.00016C12.6667 3.63197 12.3682 3.3335 12 3.3335Z"
        stroke={color ?? "white"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default BarGraph
