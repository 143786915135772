import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { EditPlacementStatus } from "domain/useCase/Admin/Placement/EditPlacementStatus"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function DashboardViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [rows, setRows] = useState<any[]>([])
  const [mockStatus, setMockStatus] = useState<{ [email: string]: string }>({})
  const [resumeStatus, setResumeStatus] = useState<{ [email: string]: string }>({})
  const [linkedinStatus, setLinkedinStatus] = useState<{ [email: string]: string }>({})
  const [uploadResumeCardPopup, setUploadResumeCardPopup] = useState<boolean>(false)
  const [resumeStudentData, setResumeStudentData] = useState<any>("")

  const EditPlacementStatusUsecase = new EditPlacementStatus(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  useEffect(() => {
    if (Array.isArray(rows)) {
      setMockStatus(
        rows.reduce((acc, row) => ({ ...acc, [row.general_information.email]: row.mock_status ?? "Select Status" }), {})
      )

      setResumeStatus(
        rows.reduce(
          (acc, row) => ({ ...acc, [row.general_information.email]: row.resume_status ?? "Select Status" }),
          {}
        )
      )

      setLinkedinStatus(
        rows.reduce(
          (acc, row) => ({ ...acc, [row.general_information.email]: row.linkedin_status ?? "Select Status" }),
          {}
        )
      )
    }
  }, [rows])

  const redirectToPlacement = (id: string) => {
    navigate(`/admin/placement/${id}`)
  }

  const handleCheckboxChange = (email: string) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(email)
        ? prevSelectedIds.filter((selectedId) => selectedId !== email)
        : [...prevSelectedIds, email]
    )
  }

  async function handleMockStatusChange(email: string, newStatus: string) {
    try {
      const response = await EditPlacementStatusUsecase.invoke(auth, email, "mock_status", newStatus)

      if (response.success) {
        setMockStatus((prevStatus) => ({
          ...prevStatus,
          [email]: newStatus,
        }))
      }
    } catch (e) {
      alert("Error in updating mock status")
      console.error(e)
    }
  }

  async function handleResumeStatusChange(email: string, newStatus: string) {
    try {
      const response = await EditPlacementStatusUsecase.invoke(auth, email, "resume_status", newStatus)

      if (response.success) {
        setResumeStatus((prevStatus) => ({
          ...prevStatus,
          [email]: newStatus,
        }))
      }
    } catch (e) {
      alert("Error in updating Resume status")
      console.error(e)
    }
  }

  async function handleLinkedinStatusChange(email: string, newStatus: string) {
    try {
      const response = await EditPlacementStatusUsecase.invoke(auth, email, "linkedin_status", newStatus)

      if (response.success) {
        setLinkedinStatus((prevStatus) => ({
          ...prevStatus,
          [email]: newStatus,
        }))
      }
    } catch (e) {
      alert("Error in updating LinkedIn status")
      console.error(e)
    }
  }

  async function handlePlacedToggle(email: string) {
    try {
      const currentRow = rows.find((row) => row.general_information.email === email)

      if (!currentRow) {
        throw new Error("Row not found for the given email.")
      }

      const newStatus = !currentRow["is_placement_done"]

      const response = await EditPlacementStatusUsecase.invoke(auth, email, "is_placement_done", newStatus)

      if (response.success) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.general_information.email === email ? { ...row, ["is_placement_done"]: newStatus } : row
          )
        )
      }
    } catch (e) {
      alert(`Error in toggling is_placement_done field for ${email}`)
      console.error(e)
    }
  }

  const handleUploadResume = (data: any) => {
    setResumeStudentData(data)
    setUploadResumeCardPopup(true)
    console.log("UploadResume button is Clicked")
  }

  const handleResumeSave = (newResumeLink: any, email: string) => {
    setResumeStudentData((prevData: any) => ({
      ...prevData,
      resume_link: newResumeLink,
    }))

    setRows((prevRows) =>
      prevRows.map((row) => (row.general_information.email === email ? { ...row, resume_link: newResumeLink } : row))
    )
  }

  const handleEdit = (id: string) => {
    navigate(`/admin/placement-dashboard/edit/${id}`)
  }

  const handleMoreOptions = () => {
    console.log("More Options button is Clicked")
  }

  return {
    toast,
    redirectToPlacement,
    changeToastDetails,
    changeToastVisibility,
    handleCheckboxChange,
    selectedIds,
    handleMockStatusChange,
    handleResumeStatusChange,
    handleLinkedinStatusChange,
    handleUploadResume,
    handlePlacedToggle,
    handleEdit,
    handleMoreOptions,
    mockStatus,
    setMockStatus,
    resumeStatus,
    setResumeStatus,
    linkedinStatus,
    setLinkedinStatus,
    rows,
    setRows,
    uploadResumeCardPopup,
    setUploadResumeCardPopup,
    resumeStudentData,
    setResumeStudentData,
    handleResumeSave,
  }
}
