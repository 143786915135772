import { Auth } from "domain/model/Auth"
import { UserSignUp } from "domain/model/UserSignup"
import { UserSignUpRepository } from "domain/repository/Admin/UserSignUpRepository"

export interface SignupUseCase {
  invoke(UserSignup: UserSignUp, auth: Auth): Promise<any>
}

export class Signup implements SignupUseCase {
  private repository: UserSignUpRepository

  constructor(repository: UserSignUpRepository) {
    this.repository = repository
  }

  async invoke(userSignup: UserSignUp, auth: Auth) {
    return await this.repository.signup(userSignup, auth)
  }
}
