import { SubmitExam } from "domain/model/Exams"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface CreateExamCodeSubmissionUseCase {
  invoke(submitExam: SubmitExam): Promise<any>
}

export class CreateExamCodeSubmission implements CreateExamCodeSubmissionUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(submitExam: SubmitExam) {
    return this.repository.createExamCodeSubmission(submitExam)
  }
}
