import { FeedbackPage, Feedback } from "domain/model/NewFeedback"
import { useState } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"

interface NewFeedbackModalProps {
  message: string
  saveFeedback: (pages: Record<string, FeedbackPage[]>) => Promise<void>
}

function NewFeedBackModal({ message, saveFeedback }: NewFeedbackModalProps) {
  const [showTextArea, setShowTextArea] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [student, setStudent] = useLocalStorage<any>("student", {})
  const [feedbackText, setFeedbackText] = useState<string>("")
  const [selectedFeedback, setSelectedFeedback] = useState<string>("")

  const feedbackData: Feedback = {
    name: student.name,
    email: student.email,
    batch: student.batch,
    pages: {},
  }

  const feedbackRatingMapping = {
    "Extremely useful": "5",
    "Very useful": "4",
    "Moderately useful": "3",
    "Slightly useful": "2",
    "Not at all useful": "1",
  }

  if (message) {
    feedbackData.pages[message] = feedbackData.pages[message] || []
    feedbackData.pages[message].push({
      last_feedback_time: new Date().toISOString().slice(0, 10),
      feedback_text: feedbackText || "No feedback provided",
      feedback: feedbackRatingMapping[selectedFeedback as keyof typeof feedbackRatingMapping] || "No feedback selected",
    })
  }

  const options = ["Not at all useful", "Slightly useful", "Moderately useful", "Very useful", "Extremely useful"]
  const handleFeedbackClick = (feedback: string) => {
    setSelectedFeedback(feedback)
    setShowTextArea(true)
  }

  const handleSubmitClick = () => {
    saveFeedback(feedbackData.pages)
    setIsModalOpen(false)
  }

  const handlePreviousClick = () => {
    setShowTextArea(false)
  }

  const handleCloseClick = () => {
    setIsModalOpen(false)
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(event.target.value)
  }

  if (!isModalOpen) return null
  return (
    <>
      <div className="fixed bottom-6 right-6 rounded-[8px] border border-[#D3D3D3] bg-white p-4 style={{ boxShadow: '-1px 1px 4px 0px rgba(34, 41, 48, 0.20)' }} w-[290px] h-[332px] z-[2147483647]">
        <div className="bg-white    w-[258px] h-[34px] mb-4">
          <p className=" font-inter text-[0.865rem] font-semibold leading-normal [color:var(--Black-and-white-Gray-600,#333)]">
            Overall, how was your experience with {message} feature?{" "}
          </p>
        </div>
        {!showTextArea ? (
          <div className="flex flex-col space-y-1 w-[258px] h-[191px]  ">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleFeedbackClick(option)}
                className="p-2.5 rounded-md border-transparent  hover:bg-[#F5F5F5] cursor-pointer active:bg-[#1C3474] group text-left  "
              >
                <p className="[color:var(--Black-and-white-Gray-600,#333)] font-inter text-xs font-normal leading-normal group-active:text-white">
                  {option}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="">
              <p className="text-[#646464] font-[Inter] text-[12px]  mt-4 font-semibold leading-normal">
                Any custom feedback or suggestions?
              </p>
            </div>
            <div className="mt-[2px]">
              <textarea
                className=" rounded-[4px] border border-[#D3D3D3] border-[0.5px] text-sm w-[258px] h-[169.201px]"
                placeholder="Type your message here!"
                onChange={handleTextChange}
              ></textarea>
            </div>
          </>
        )}

        {!showTextArea ? (
          <>
            <div className="inline-flex gap-[81px] mt-6 ">
              <div
                className="bg-white py-2.5 px-4 min-w-[80px] w-[113px] h-[35px] flex justify-center items-center rounded-md hover:bg-[#F5F5F5] cursor-pointer active:bg-[#1C3474] group"
                onClick={handleCloseClick}
              >
                <p className="text-[0.868rem] [color:var(--Black-and-white-Gray-600,#333)] font-inter  font-medium leading-normal w-[81px] h-auto group-active:text-white">
                  I'll do it later
                </p>
              </div>

              {/* <div
                                onClick={handleNextClick}
                                className="bg-white py-2.5 px-4 w-[64px] h-[35px] rounded-[4px] flex justify-center items-center border border-[#D3D3D3] hover:bg-[#F5F5F5] cursor-pointer active:bg-[#1C3474] group"
                            >
                                <p className="text-[0.868rem] [color:var(--Black-and-white-Gray-600,#333)] font-inter  font-medium leading-normal w-[81px] h-auto group-active:text-white">
                                    Next
                                </p>
                            </div> */}
            </div>
          </>
        ) : (
          <div className="flex justify-end space-x-4 mt-[18px] mb-[16px]">
            <div
              onClick={handlePreviousClick}
              className="bg-white py-2.5 px-4 w-[90px] h-[35px] rounded-[4px] flex justify-center items-center border border-[#D3D3D3] hover:bg-[#F5F5F5] cursor-pointer active:bg-[#1C3474] group"
            >
              <p className="text-[0.868rem] [color:var(--Black-and-white-Gray-600,#333)] font-inter  font-medium leading-normal w-[81px] h-auto group-active:text-white">
                Previous
              </p>
            </div>

            <div
              onClick={handleSubmitClick}
              className="text-white  py-2.5 px-4   w-[90px] h-[35px] rounded-[4px] flex justify-center items-center bg-[#162456] hover:cursor-pointer hover:bg-[#1C3474] active:bg-[#333]"
            >
              Submit
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default NewFeedBackModal
