import FreezeActive from "assets/svgs/FreezeActive"
import coin from "../../../assets/images/coinv2.png"

interface FreezeCardProps {
  coins: number
}

const FreezeCard: React.FC<FreezeCardProps> = ({ coins }) => {
  const formattedCoins = new Intl.NumberFormat().format(Number(coins))

  return (
    <div
      className="border-2 border-[#237CA7] w-[72%] h-[87px] px-4 py-3 rounded-[4px]"
      style={{ boxShadow: "5px 5px 0px 0px #237CA7" }}
    >
      <div className="flex items-center justify-center text-center mb-[10px]">
        <FreezeActive className="size-[32px]" />
      </div>

      <p className="font-semibold text-[16px] text-[#237CA7]">{formattedCoins}</p>
    </div>
  )
}

export default FreezeCard
