import { useEffect, useState } from "react"

export default function PartnerCompanyList() {
  const [companyImages, setCompanyImages] = useState<string[]>([])

  useEffect(() => {
    const fetchCompanyImages = async () => {
      const images = []
      for (let i = 1; i <= 15; i++) {
        const image = await import(`../assets/company${i}.png`)
        images.push(image.default)
      }
      setCompanyImages(images)
    }

    fetchCompanyImages()
  }, [])

  return (
    <div className="w-full bg-white  ">
      <div className=" flex  my-[21px]   justify-start text-[#333333]  text-[16px] leading-normal text-center font-bold">
        Partner Company List
      </div>
      <div className=" w-full flex justify-center items-center">
        <div className="flex justify-center items-center w-full">
          <div className="grid grid-cols-5 gap-2 w-[100%]">
            {companyImages.map((image, index) => (
              <div key={index} className="flex w-[149px] h-[54px] items-center justify-center px-[36px] py-2">
                <img key={index + 1} src={image} alt={`Company ${index + 1}`} className="w-[100px] h-auto" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
