import TableButton from "core/components/v2/TableButton"
import { useNavigate } from "react-router-dom"
import useNewViewModel from "../../NewViewModel"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function HomeVideoCard({ data, index, type, selected }: any) {
  const { handleHomeCardVideoStatus } = useNewViewModel()
  const navigate = useNavigate()

  const minutes = Math.floor(parseInt(data?.video_len) / 60)
  const extraSeconds = parseInt(data?.video_len) % 60

  const handleClick = () => {
    navigate(`/home/${type}/${data?.video_id}?h=${data?.video_secret || "default"}`)
  }

  const [isChecked, setIsChecked] = useLocalStorage<boolean>(`home-card-video-${data?.id}`, data?.view_status || false)

  const handleCheckboxChange = () => {
    const newStatus = !isChecked
    setIsChecked(newStatus)
    handleHomeCardVideoStatus(data?.category, data?.id, newStatus)
  }

  const handlestatus = () => {
    if (data.view_status) data.view_status = false
    else data.view_status = true
  }

  return (
    <div
      className={`flex h-[134px] min-h-[134px] w-[205px] min-w-[205px] flex-col gap-2 rounded-lg border-[0.5px] bg-white p-3 shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.0.05)]`}
    >
      <div className="flex justify-between">
        <h3 className="w-4/5 text-sm font-semibold text-[#333]" title={data?.name}>
          {data?.name?.slice(0, 20)}...
        </h3>
        <input
          checked={isChecked}
          onChange={handleCheckboxChange}
          onClick={handlestatus}
          type="checkbox"
          className="rounded border-[#D3D3D3] checked:bg-[#00C22B]"
        />
      </div>
      <p className="text-[11px] font-normal leading-[14.3px] text-[#646464]">
        Duration: {minutes}:{extraSeconds < 10 ? "0" + extraSeconds : extraSeconds} mins
      </p>
      <div className="flex-grow"></div>
      <div className="mt-auto flex">
        <TableButton outlined onClick={handleClick} className={selected && "border-[#07B42D] text-[#07B42D]"}>
          {selected ? "Selected" : "Play Video"}
        </TableButton>
      </div>
    </div>
  )
}
