import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import React from "react"

export default function CodingProblemView(props: any) {
  React.useEffect(() => {
    if (props.activeCodingProblemIndex >= 0 && props.codingProblems.length > 0) {
      if (
        !isEmpty(props.studentContestDetails) &&
        props?.studentContestDetails?.flowType &&
        props?.studentContestDetails?.flowType === "newFlow"
      ) {
        const currentProblem = props.codingProblems[props.activeCodingProblemIndex]
        if (currentProblem) {
          props.setQuestion(currentProblem)
        }
      }
    }
  }, [props.activeCodingProblemIndex, props.codingProblems])
  return (
    <div className="flex gap-4">
      {props?.codingProblems?.map((_: any, i: any) => (
        <button
          key={i}
          className={cn(
            "flex w-[56px] items-center justify-center rounded-sm border bg-new-editor-dark-500 px-3 py-2",
            false
              ? "border-new-success text-new-success"
              : props?.activeCodingProblemIndex === i
                ? "border-new-neutral-light text-new-editor-dark-50"
                : "border-transparent text-new-editor-dark-50"
          )}
          onClick={() => {
            if (
              !isEmpty(props?.studentContestDetails) &&
              props?.studentContestDetails?.flowType &&
              props?.studentContestDetails?.flowType === "newFlow"
            ) {
              props?.handleCodingProblemChange(i)
              // setQuestion(questionsLocally?.[i])
            } else {
              props?.handleCodingProblemChange(i)
              props?.setQuestion(props?.questionsLocally?.[i])
            }
          }}
        >
          <h4>{i + 1}</h4>
        </button>
      ))}
    </div>
  )
}
