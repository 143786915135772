import React, { createContext, ReactNode, useState, Dispatch, SetStateAction, useContext } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import { GetNotificationDetails } from "domain/useCase/Student/Notification/GetNotificationDetails"
import { NotificationRepositoryImpl } from "data/repository/Student/NotificationRepository"
import NotificationAPIDataSourceImpl from "data/API/Student/NotificationAPIDataSourceImpl"
import { GetRightPanelDetails } from "domain/useCase/Student/Home/GetRightPanelDetails"
import { HomeRepositoryImpl } from "data/repository/Student/HomeRepositoryImpl"
import HomeAPIDataSourceImpl from "data/API/Student/HomeAPIDataSourceImpl"
import { GetNotificationToastDetails } from "domain/useCase/Student/Home/GetNotificationToastDetails"
import { Auth } from "domain/model/Auth"
import { GetAttendanceAndAssignments } from "domain/useCase/Student/Course/GetAttendanceAndAssignments"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"

type AppContextType = {
  student: any
  homeDetails: any
  mentor: any
  contestList: any
  notificationDetails: any
  notificationBubble: boolean
  rightPanelDetails: any
  assignmentAndAttendance: any
  remainder: any
  notificationToastDetails: any
  activeButton: string
  isDSML: boolean
  pendingAPI: Set<string>
  setIsDSML: Dispatch<SetStateAction<boolean>>
  setActiveButton: Dispatch<SetStateAction<string>>
  setNotificationToastDetails: Dispatch<SetStateAction<any>>
  setRemainder: Dispatch<SetStateAction<any>>
  setRightPanelDetails: Dispatch<SetStateAction<any>>
  setNotificationBubble: Dispatch<SetStateAction<boolean>>
  setNotificationDetails: Dispatch<SetStateAction<any>>
  setStudent: Dispatch<SetStateAction<any>>
  setHomeDetails: Dispatch<SetStateAction<any>>
  setMentor: Dispatch<SetStateAction<any>>
  setAssignmentAndAttendance: Dispatch<SetStateAction<any>>
  setPendingAPI: Dispatch<SetStateAction<Set<string>>>
  fetchNotificationDetails: (auth: Auth) => Promise<void>
  fetchRightPanelDetails: (auth: Auth) => Promise<void>
  fetchNotificationToast: (auth: Auth) => Promise<void>
}

export const AppContext = createContext<AppContextType | null>(null)

export const AppProvider = (props: { children: ReactNode }) => {
  const [student, setStudent] = useLocalStorage("student", {})
  const [rightPanelDetails, setRightPanelDetails] = useLocalStorage("right-panel", {})
  const [homeDetails, setHomeDetails] = useLocalStorage<any>("home-details", {})
  const [mentor, setMentor] = useLocalStorage("mentor", {})
  const [contestList, setContestList] = useLocalStorage("contest", {})
  const [notificationDetails, setNotificationDetails] = useState<any>([])
  const [notificationBubble, setNotificationBubble] = useState<boolean>(false)
  const [remainder, setRemainder] = useState<any>({})
  const [notificationToastDetails, setNotificationToastDetails] = useState<any>({})
  const [activeButton, setActiveButton] = useState("Home")
  const [pendingAPI, setPendingAPI] = useState<Set<string>>(new Set())
  const [isDSML, setIsDSML] = useState(false)
  const [assignmentAndAttendance, setAssignmentAndAttendance] = useLocalStorage("assignmentAndAttendance", {})
  const notificationDetailsUseCase = new GetNotificationDetails(
    new NotificationRepositoryImpl(new NotificationAPIDataSourceImpl())
  )

  const getRightPanelDetailsUseCase = new GetRightPanelDetails(new HomeRepositoryImpl(new HomeAPIDataSourceImpl()))

  const getNotificationToastDetailsUseCase = new GetNotificationToastDetails(
    new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  )

  const getAttendanceAndAssignmentsUseCase = new GetAttendanceAndAssignments(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const fetchRightPanelDetails = async (auth: Auth) => {
    const response = await getRightPanelDetailsUseCase.invoke(auth)
    const rightPanelData = response?.data
    const response1 = await getAttendanceAndAssignmentsUseCase.invoke(auth)
    setAssignmentAndAttendance(response1?.data)
    const combinedData = {
      ...rightPanelData,
      assignmentProblems: response1?.data?.assignmentSolved,
    }
    setRightPanelDetails(combinedData)
  }

  const fetchNotificationDetails = async (auth: Auth) => {
    let isAllRead = true
    const response = await notificationDetailsUseCase.invoke(auth)

    const notificationData: any[] = []
    Object.keys(response.data).forEach((key) => {
      if ("isRead" in response.data[key] && !response.data[key].isRead) {
        isAllRead = false
      }
      if (Object.keys(response.data[key]).length > 0) {
        response.data[key].type = key
        notificationData.push(response.data[key])
      }
    })
    notificationData.sort((a, b) => a.isRead - b.isRead)
    setNotificationBubble(!isAllRead)
    setNotificationDetails(notificationData)
  }

  const fetchNotificationToast = async (auth: Auth) => {
    const response = await getNotificationToastDetailsUseCase.invoke(auth)
    setNotificationToastDetails(response?.data)
  }

  const appContextValue: AppContextType = {
    student,
    homeDetails,
    mentor,
    contestList,
    notificationDetails,
    notificationBubble,
    remainder,
    notificationToastDetails,
    activeButton,
    isDSML,
    pendingAPI,
    rightPanelDetails,
    setIsDSML,
    setActiveButton,
    setNotificationToastDetails,
    setRemainder,
    fetchRightPanelDetails,
    setRightPanelDetails,
    setNotificationBubble,
    setNotificationDetails,
    setStudent,
    setHomeDetails,
    setMentor,
    setPendingAPI,
    fetchNotificationDetails,
    fetchNotificationToast,
    assignmentAndAttendance,
    setAssignmentAndAttendance,
  }

  return <AppContext.Provider value={appContextValue}>{props.children}</AppContext.Provider>
}
