import React from "react"

const PointIcon = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      className={className}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M13.0011 24.7906C19.7714 24.7906 25.2913 19.8834 25.2913 12.9775C25.2913 6.09263 19.7929 0.503812 13.0011 0.503812C6.20925 0.503812 0.710843 6.09263 0.710843 12.9775C0.710843 19.8834 6.23071 24.7906 13.0011 24.7906Z"
        fill="#BE2C01"
        stroke="#BB2900"
        stroke-width="0.578313"
      />
      <g filter="url(#filter0_ddi_3798_62848)">
        <path
          d="M24.4236 12.1564C24.4236 18.5941 19.3094 23.1809 13.0008 23.1809C6.69225 23.1809 1.57812 18.5941 1.57812 12.1564C1.57812 5.71876 6.69225 0.5 13.0008 0.5C19.3094 0.5 24.4236 5.71876 24.4236 12.1564Z"
          fill="url(#paint0_linear_3798_62848)"
        />
      </g>
      <g filter="url(#filter1_di_3798_62848)">
        <ellipse cx="13.0006" cy="11.8742" rx="8.53089" ry="8.6613" fill="url(#paint1_linear_3798_62848)" />
        <path
          d="M21.4833 11.8742C21.4833 16.6318 17.6848 20.4873 13.0006 20.4873C8.31644 20.4873 4.51792 16.6318 4.51792 11.8742C4.51792 7.11661 8.31644 3.26108 13.0006 3.26108C17.6848 3.26108 21.4833 7.11661 21.4833 11.8742Z"
          stroke="#D98C00"
          stroke-width="0.0963855"
        />
      </g>
      <g filter="url(#filter2_di_3798_62848)">
        <path
          d="M15.6235 15.581L15.5806 15.722L15.4911 16.0174C15.4557 16.1356 15.3847 16.2389 15.2885 16.3123C15.1923 16.3857 15.0759 16.4253 14.9564 16.4254L14.6961 16.1637L14.0764 15.5442L13.3625 14.828C13.2689 14.7348 13.144 14.6828 13.0141 14.6828C12.8841 14.6828 12.7593 14.7348 12.6656 14.828L11.9501 15.5431L11.3304 16.1625L11.0695 16.4242C10.9501 16.4236 10.834 16.3837 10.7379 16.3104C10.6418 16.2371 10.5707 16.1341 10.5349 16.0163L10.4481 15.722L10.4053 15.581C10.4655 15.5792 10.5249 15.566 10.5805 15.5419C10.636 15.5176 10.6866 15.4829 10.7298 15.4393L11.2368 14.9312L12.1867 13.9784C12.2943 13.8706 12.4382 13.8104 12.5879 13.8106H13.513C13.618 13.8099 13.7191 13.8517 13.7947 13.9271L13.9226 14.0553L14.7947 14.9294L15.3018 15.4376C15.3447 15.4814 15.3954 15.5162 15.4511 15.5402C15.5066 15.5642 15.566 15.5775 15.6263 15.5792L15.6235 15.581Z"
          fill="#FFF100"
        />
        <path
          d="M13.9186 14.0553C13.6069 14.0552 13.3075 14.1806 13.0837 14.405L13.0144 14.4743L12.6673 14.824L11.9501 15.5431L11.3304 16.1625L11.0695 16.4242C10.9501 16.4236 10.834 16.3837 10.7379 16.3104C10.6418 16.2371 10.5707 16.1341 10.5349 16.0163L10.4481 15.722L10.4053 15.581C10.4655 15.5792 10.5249 15.566 10.5805 15.5419C10.636 15.5176 10.6866 15.4829 10.7298 15.4393L11.2368 14.9312L12.1867 13.9784C12.2943 13.8706 12.4382 13.8104 12.5879 13.8106H13.513C13.618 13.8099 13.7191 13.8517 13.7947 13.9271L13.9186 14.0553Z"
          fill="#FFF100"
        />
        <path
          d="M11.9879 12.284L11.5766 13.0591C11.5336 13.1398 11.4705 13.2072 11.3938 13.2541C11.3171 13.3011 11.2296 13.3259 11.1405 13.326H10.2064C10.0548 13.326 9.90542 13.2876 9.77146 13.2141L8.31789 12.4157C8.30017 12.4065 8.27969 12.4046 8.2607 12.4106C8.24171 12.4166 8.22567 12.4299 8.21592 12.4477C8.20928 12.4598 8.20579 12.4735 8.20577 12.4874C8.20635 12.5005 8.21005 12.5132 8.21654 12.5245C8.22302 12.5358 8.2321 12.5453 8.24296 12.5521L8.90157 12.9868C9.48208 13.3708 9.91317 13.9541 10.1191 14.6342L10.4053 15.5812L10.4481 15.7222L10.5371 16.0177C10.5729 16.1355 10.644 16.2385 10.7401 16.3118C10.8362 16.3852 10.9524 16.425 11.0718 16.4256C10.9936 16.5057 10.8882 16.5509 10.7782 16.5515H9.27848C9.17193 16.5514 9.06818 16.5162 8.98235 16.4509C8.89652 16.3856 8.83308 16.2936 8.80129 16.1884L7.63336 12.3265C7.62296 12.293 7.62038 12.2574 7.62583 12.2226C7.63128 12.1879 7.6446 12.1549 7.66471 12.1265C7.68482 12.0981 7.71115 12.075 7.74154 12.0591C7.77193 12.0433 7.80552 12.035 7.83957 12.0352H11.8487C11.8769 12.035 11.9047 12.0425 11.9292 12.0571C11.9537 12.0716 11.974 12.0925 11.9881 12.1178C12.0023 12.1431 12.0097 12.1717 12.0096 12.2009C12.0096 12.2301 12.0021 12.2588 11.9879 12.284Z"
          fill="#FFF100"
        />
        <path
          d="M18.3939 12.3289L17.226 16.1908C17.1941 16.2959 17.1306 16.3877 17.0448 16.4529C16.9589 16.5181 16.8552 16.5532 16.7488 16.5533H15.2518C15.1405 16.5531 15.0338 16.5075 14.9549 16.4262C15.0744 16.4262 15.1908 16.3865 15.287 16.3131C15.3832 16.2397 15.4542 16.1364 15.4896 16.0183L15.5791 15.7228L15.622 15.5818L15.8693 14.763C16.1002 13.9997 16.5842 13.3453 17.2361 12.9151L17.7854 12.5527C17.7963 12.5455 17.8053 12.5356 17.8115 12.5239C17.8177 12.5122 17.8209 12.499 17.8209 12.4857C17.8212 12.4718 17.818 12.4581 17.8116 12.446C17.8052 12.4338 17.7958 12.4236 17.7844 12.4164C17.773 12.4091 17.76 12.4051 17.7466 12.4047C17.7333 12.4043 17.72 12.4075 17.7082 12.414L16.2552 13.2123C16.1212 13.2858 15.9719 13.3242 15.8203 13.3242H14.8867C14.7976 13.3242 14.7101 13.2995 14.6334 13.2525C14.5567 13.2056 14.4936 13.1382 14.4507 13.0573L14.0394 12.2864C14.026 12.2611 14.0191 12.2328 14.0196 12.204C14.02 12.1752 14.0277 12.147 14.0418 12.1223C14.056 12.0975 14.0761 12.077 14.1003 12.0627C14.1245 12.0485 14.1518 12.041 14.1797 12.041H18.1877C18.2213 12.0412 18.2544 12.0495 18.2844 12.0652C18.3144 12.0809 18.3404 12.1036 18.3604 12.1316C18.3804 12.1595 18.3939 12.1919 18.3997 12.2261C18.4055 12.2604 18.4035 12.2956 18.3939 12.3289Z"
          fill="#FFF100"
        />
        <path
          d="M15.4912 10.9319H14.0776C14.0408 10.9318 14.0045 10.9234 13.9712 10.9072C13.9378 10.891 13.9084 10.8674 13.8849 10.838L13.2241 10.0082C13.2006 9.97868 13.1717 9.95436 13.139 9.93668C13.1062 9.919 13.0704 9.90832 13.0336 9.90527C12.9968 9.90222 12.9598 9.90688 12.9247 9.91895C12.8897 9.93103 12.8574 9.95028 12.8297 9.97556C12.8187 9.98557 12.8085 9.99648 12.7993 10.0082L12.1384 10.838C12.115 10.8674 12.0855 10.891 12.0522 10.9072C12.0189 10.9234 11.9825 10.9318 11.9457 10.9319H10.5372C10.4907 10.9318 10.4452 10.918 10.4059 10.8922C10.3666 10.8664 10.3353 10.8296 10.3154 10.7861C10.2956 10.7426 10.2881 10.6941 10.2939 10.6463C10.2996 10.5986 10.3184 10.5535 10.3479 10.5164L11.9902 8.45166L12.5694 7.72381C12.6218 7.65787 12.6872 7.6043 12.7612 7.56671C12.8353 7.52911 12.9163 7.50836 12.9987 7.50586H13.032C13.1143 7.50841 13.1952 7.52918 13.2692 7.56677C13.3431 7.60437 13.4084 7.65792 13.4607 7.72381L13.5103 7.78616L14.0399 8.45166L14.4067 8.91262L15.6799 10.5164C15.7095 10.5535 15.7282 10.5985 15.734 10.6462C15.7397 10.6939 15.7323 10.7423 15.7126 10.7858C15.6928 10.8293 15.6615 10.8661 15.6223 10.892C15.5831 10.9178 15.5377 10.9317 15.4912 10.9319Z"
          fill="#FFF100"
        />
        <path
          d="M14.4044 8.91262C14.1762 8.91306 13.9508 8.9655 13.7444 9.06622C13.5379 9.16694 13.3555 9.31346 13.21 9.49537L13.0168 9.7378L12.8303 9.9709C12.8193 9.9809 12.8091 9.99182 12.7998 10.0035L12.1384 10.838C12.115 10.8674 12.0855 10.891 12.0522 10.9072C12.0189 10.9234 11.9825 10.9318 11.9457 10.9319H10.5372C10.4907 10.9318 10.4452 10.918 10.4059 10.8922C10.3666 10.8664 10.3353 10.8296 10.3154 10.7861C10.2956 10.7426 10.2881 10.6941 10.2939 10.6463C10.2996 10.5986 10.3184 10.5535 10.3479 10.5164L11.9902 8.45166L12.5694 7.72381C12.6218 7.65787 12.6872 7.6043 12.7612 7.56671C12.8353 7.52911 12.9163 7.50836 12.9987 7.50586H13.032C13.1143 7.50841 13.1952 7.52918 13.2692 7.56677C13.3431 7.60437 13.4084 7.65792 13.4607 7.72381L13.5103 7.78616L14.0399 8.45166L14.4044 8.91262Z"
          fill="#FFF100"
        />
        <path d="M14.4049 8.91017L14.0381 8.44922L14.4049 8.91017Z" fill="#FFF100" />
      </g>
      <path
        d="M6.13258 17.159C6.57497 17.0237 7.04025 17.2736 7.17181 17.7171L7.37977 18.4182C6.93739 18.5534 6.47211 18.3036 6.34055 17.8601L6.13258 17.159Z"
        fill="#FFF73A"
      />
      <path
        d="M7.76682 22.0017C8.29836 21.5248 9.11864 21.5674 9.59897 22.0967L10.3378 22.9109C9.80623 23.3878 8.98594 23.3452 8.50561 22.8159L7.76682 22.0017Z"
        fill="#EEA50A"
      />
      <mask
        id="mask0_3798_62848"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <path
          d="M13.0011 24.7828C19.7714 24.7828 25.2913 19.8755 25.2913 12.9697C25.2913 6.08482 19.7929 0.496 13.0011 0.496C6.20925 0.496 0.710843 6.08482 0.710843 12.9697C0.710843 19.8755 6.23071 24.7828 13.0011 24.7828Z"
          fill="#BE2C01"
          stroke="#BB2900"
          stroke-width="0.578313"
        />
      </mask>
      <g mask="url(#mask0_3798_62848)">
        <g style={{ mixBlendMode: "soft-light" }}>
          <ellipse cx="23.2673" cy="6.87913" rx="13.447" ry="13.6526" fill="white" fill-opacity="0.4" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddi_3798_62848"
          x="1.57812"
          y="0.5"
          width="22.8457"
          height="23.9347"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.25301" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.807843 0 0 0 0 0.372549 0 0 0 0 0.0235294 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3798_62848" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.289157" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.933333 0 0 0 0 0.647059 0 0 0 0 0.0392157 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3798_62848" result="effect2_dropShadow_3798_62848" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3798_62848" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.289157" dy="0.289157" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_3798_62848" />
        </filter>
        <filter
          id="filter1_di_3798_62848"
          x="4.37334"
          y="3.21289"
          width="17.1579"
          height="17.7078"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.0963855" dy="0.385542" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.960784 0 0 0 0 0.215686 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3798_62848" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3798_62848" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.578313" dy="0.963855" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.904167 0 0 0 0 0.48414 0 0 0 0 0.0791146 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3798_62848" />
        </filter>
        <filter
          id="filter2_di_3798_62848"
          x="7.43028"
          y="7.50586"
          width="10.973"
          height="9.72157"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.192771" dy="0.674699" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.478431 0 0 0 0 0.027451 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3798_62848" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3798_62848" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.289157" dy="0.289157" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3798_62848" />
        </filter>
        <linearGradient
          id="paint0_linear_3798_62848"
          x1="17.3386"
          y1="-0.27071"
          x2="8.02338"
          y2="23.416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF649" />
          <stop offset="1" stop-color="#FDC101" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3798_62848"
          x1="15.8563"
          y1="3.21289"
          x2="8.72765"
          y2="22.558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FECB02" />
          <stop offset="1" stop-color="#FC9700" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PointIcon
