import Sidebar from "core/components/Sidebar"
import ProfileBar from "core/components/ProfileBar"
import Header from "core/components/Header"
import Loader from "core/components/Loader"
import { useAuth } from "core/context/auth"
import { ReactNode } from "react"
import { useLocation } from "react-router-dom"
import CoursePausedBanner from "core/components/CoursePausedBanner"
import CoursePaymentPending from "core/components/CoursePaymentPending"
import { useApp } from "core/context/app"
type TProps = {
  children: ReactNode
  isLoading?: boolean
  rightBar?: ReactNode | string
  header?: ReactNode | string
}
export default function DashboardLayout({ children, isLoading, rightBar, header }: TProps) {
  const { auth, user } = useAuth()
  const pathname = useLocation().pathname
  const { student } = useApp()
  return (
    <div className="flex h-screen ">
      <div className="w-[15.2%]">
        <Sidebar />
      </div>
      <div className="w-[85%] flex-1">
        {header || <Header />}
        <div className="flex h-[calc(100vh-49px)]">
          <div className="flex-1 overflow-y-auto text-new-neutral" id="dashboard">
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                {student?.is_batch_paused === true && user?.role == "user" && <CoursePausedBanner />}
                {!student?.is_payment_done && user?.role === "user" && student?.instalmentsLength < 2 && <div></div>}
                {children}
              </div>
            )}
          </div>
          {rightBar !== "empty" &&
            user?.role === "user" &&
            (rightBar ?? (
              <div className="w-[calc(max(15%,186px))]">
                <ProfileBar />
              </div>
            ))}
          {rightBar !== "empty" &&
            user?.role === "admin" &&
            pathname.slice(-16) === "progress-roadmap" &&
            (rightBar ?? (
              <div className="w-[calc(max(15%,186px))]">
                <ProfileBar />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
