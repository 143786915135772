import { Auth } from "domain/model/Auth"
import { Feedback } from "domain/model/NewFeedback"
import FeatureFeedbackDataSource from "data/dataSource/Admin/FeatureFeedbackDataSource"
import { FeatureFeedbackRepository } from "domain/repository/Admin/FeatureFeedback"

export class FeatureFeedbackRepositoryImpl implements FeatureFeedbackRepository {
  private dataSource: FeatureFeedbackDataSource

  constructor(dataSource: FeatureFeedbackDataSource) {
    this.dataSource = dataSource
  }

  async getAllFeedback(auth: Auth): Promise<Feedback[]> {
    return this.dataSource.getAllFeedback(auth)
  }
}
