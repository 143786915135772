import ButtonGroup from "core/components/ButtonGroup"
import React from "react"
import AssessmentTable from "./components/ContestTable"
import useContestViewModel from "./ContestViewModel"
import Toast from "core/components/Toast"
import {
  STR_COMPLETED_TEST,
  STR_ONGOING_TEST,
  STR_WEEKLY_TEST_SERIES,
  STR_WEEKLY_TEST_SERIES_SUBTITLE,
} from "core/constants/strings"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import Loader from "core/components/Loader"
import Pagination from "presentation/Admin/Contest/components/Pagination"
import NewfeedbackModal from "core/components/NewFeedbackModal"
import useLocalStorage from "core/hooks/useLocalStorage"
export default function ContestView() {
  const {
    loading,
    activeButtonType,
    tableData,
    toast,

    changeActiveButtonType,
    setActiveButtonType,
    getAllContest,
    totalDataList,
    changeToastVisibility,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    saveFeedback,
  } = useContestViewModel()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [studentInfo, setStudentInfo] = useLocalStorage<any>("student", {})
  const [showModal, setShowModal] = React.useState(false)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    getAllContest(page)
  }
  React.useEffect(() => {
    if (true) {
      localStorage.removeItem("allQuestions")
      getAllContest(currentPage)
    }
  }, [])

  React.useEffect(() => {
    const calculateTotalPages = () => {
      const pageSize = 20
      const totalPages = Math.ceil(totalDataList / pageSize)
      setTotalPages(totalPages)
    }

    calculateTotalPages()
  }, [tableData])

  React.useEffect(() => {
    const loadFeedback = async () => {
      const lastFeedbackDate = await fetchLastFeedback("Exam")
      const joining_date = await getJoiningDate()
      setShowModal(shouldShowFeedbackAfter2Months(joining_date, lastFeedbackDate))
    }
    loadFeedback()
  }, [])

  const startIndex = (currentPage - 1) * 20
  const endIndex = startIndex + 20
  return (
    <DashboardLayoutv2>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h1 className="text-xl font-bold leading-[29px] text-new-accent">{STR_WEEKLY_TEST_SERIES}</h1>
          <p>{STR_WEEKLY_TEST_SERIES_SUBTITLE}</p>
        </div>
        <ButtonGroup
          buttons={[STR_ONGOING_TEST, STR_COMPLETED_TEST]}
          active={activeButtonType}
          onChange={changeActiveButtonType}
        />
        {loading ? (
          <Loader></Loader>
        ) : (
          <div>
            {/* <AssessmentTable data={{ rows: paginatedData, header: tableData.header }} buttonType={activeButtonType} /> */}
            <AssessmentTable
              data={{ rows: tableData.rows, header: tableData.header }}
              page={currentPage}
              buttonType={activeButtonType}
            />
            {activeButtonType == 1 && (
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
          </div>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      {showModal && <NewfeedbackModal saveFeedback={saveFeedback} message="Exam" />}
    </DashboardLayoutv2>
  )
}
