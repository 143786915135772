import AddIcon from "assets/svgs/placement/AddIcon"
import HyperLinkIcon from "assets/svgs/placement/HyperLink"
import { DeleteIcon, EditIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { GetReviewsById } from "domain/useCase/Admin/Placement/GetReviewsById"
import { useEffect, useState } from "react"
import EditReviewCard from "./popUpCards/EditReviewCard"

export default function ReviewsSection(docId: any) {
  const { auth } = useAuth()
  const [name, setName] = useState("")
  const [reviews, setReviews] = useState<any>([])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedReview, setSelectedReview] = useState<any>(null)

  const GetReviewsByIdUsecase = new GetReviewsById(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  useEffect(() => {
    fetchReviewsById()
  }, [])

  async function fetchReviewsById() {
    if (!docId) {
      console.error("docId is undefined")
      return
    }
    const response = await GetReviewsByIdUsecase.invoke(auth, docId)
    if (response?.success) {
      const data = response?.data
      setName(data?.name || "")
      setReviews(data?.reviews || [])
    }
  }

  const handleEdit = (index: number) => {
    const reviewToEdit = reviews[index]
    setSelectedReview(reviewToEdit)
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    setSelectedReview(null)
  }

  const handleSave = async (updatedReview: any) => {
    try {
      const userConfirmed = window.confirm(
        `Are you sure you want to ${reviews.includes(selectedReview) ? "save changes to" : "add"} this review?`
      )
      if (!userConfirmed) return

      let updatedReviews
      if (reviews.includes(selectedReview)) {
        updatedReviews = reviews.map((rev: any) => (rev === selectedReview ? updatedReview : rev))
      } else {
        updatedReviews = [...reviews, updatedReview]
      }

      closePopup()

      const response = await GetReviewsByIdUsecase.edit(auth, docId, updatedReviews)

      if (!response?.success) {
        console.error("Failed to save review:", response?.message || "Unknown error")
        setReviews(reviews)
        alert("Failed to save the review. Please try again.")
      } else {
        setReviews(updatedReviews)
      }
    } catch (error) {
      console.error("An error occurred while saving the review:", error)
      setReviews(reviews)
      alert("An unexpected error occurred. Please try again.")
    }
  }

  const handleDelete = async (index: number) => {
    try {
      const userConfirmed = window.confirm("Are you sure you want to delete this review?")
      if (!userConfirmed) return

      const updatedReviews = reviews.filter((_: any, i: number) => i !== index)

      const response = await GetReviewsByIdUsecase.edit(auth, docId, updatedReviews)

      if (!response?.success) {
        console.error("Failed to delete review:", response?.message || "Unknown error")
        alert("Failed to delete the review. Please try again.")
      } else {
        setReviews(updatedReviews)
      }
    } catch (error) {
      console.error("An error occurred while deleting the review:", error)
      alert("An unexpected error occurred. Please try again.")
    }
  }

  const handleAdd = () => {
    const newReview = {
      platform: "",
      review: "",
      url: "",
      date: new Date().toISOString().split("T")[0],
    }
    setSelectedReview(newReview)
    setIsPopupOpen(true)
  }

  return (
    <div className="rounded-[2px] w-full border-[0.4px] p-6 flex justify-between items-center">
      <div className="w-full">
        <div className="flex items-center justify-between">
          <p className="text-[#312E81] font-semibold">Reviews</p>
          <button
            className="flex gap-1 items-center justify-center border-[0.5px] border-[#D3D3D3] rounded-md py-2 pl-2 pr-3"
            onClick={handleAdd}
          >
            <AddIcon className="w-[22px]" />
            <p className="text-[#333333]">Add Review</p>
          </button>
        </div>
        <hr className="w-full border-t-2 border-dashed border-gray-300 my-4" />
        {reviews.length > 0 ? (
          <div className="pt-4 flex flex-col gap-4">
            {reviews.map((review: any, index: number) => (
              <div key={index} className="flex justify-between items-center">
                <div className="flex items-center pr-6">
                  <p className="min-w-32 text-[18px] font-semibold font-[#414141]">{review.platform}</p>
                  <p>
                    {review.review.split(" ").length > 25
                      ? review.review.split(" ").slice(0, 25).join(" ") + "..."
                      : review.review}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <a
                    href={
                      review.url.startsWith("http://") || review.url.startsWith("https://")
                        ? review.url
                        : `https://${review.url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border rounded-md p-2"
                  >
                    <HyperLinkIcon className="w-6" />
                  </a>
                  <button onClick={() => handleEdit(index)} className="border rounded-md p-2">
                    <EditIcon className="w-6" />
                  </button>
                  <button onClick={() => handleDelete(index)} className="border rounded-md p-2">
                    <DeleteIcon className="w-6 text-red-500" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No reviews available.</p>
        )}
      </div>

      {isPopupOpen && selectedReview && (
        <div className="fixed inset-0 bg-[rgba(0,0,0,0.6)] flex items-center justify-center z-50">
          <div className="rounded-lg shadow-lg">
            <EditReviewCard name={name} review={selectedReview} onSave={handleSave} onCancel={closePopup} />
          </div>
        </div>
      )}
    </div>
  )
}
