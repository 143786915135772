import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import useResumeCreateViewModel from "./ResumeCreateViewModel"
import ResumeTemplate from "./components/ResumeTemplate"
import { AddIcon, CrossIcon } from "core/constants/svgs"
import Input from "core/components/new/Input"

export default function ResumeCreateView() {
  const {
    toast,
    resumeData,
    language,
    technology,
    isUploading,
    setLanguage,
    setTechnology,
    handleTextInputChange,
    changeToastVisibility,
    handleAddMoreEducation,
    handleAddMoreExperience,
    handleAddMoreProject,
    handleRemoveEducation,
    handleRemoveExperience,
    handleRemoveProject,
    handleAddSkill,
    handleRemoveSkill,
    handleEducationChange,
    handleExperienceChange,
    handleProjectChange,
    handleSubmitResumeDocument,
    handleCancelResumeUpdate,
  } = useResumeCreateViewModel()

  return (
    <DashboardLayout rightBar="empty">
      <div className="p-6">
        <div className="flex gap-6">
          <div className="flex w-full max-w-[50%] flex-1 flex-col gap-8 overflow-y-auto p-6 shadow-[0px_4px_16px_rgba(134,134,134,0.2)]">
            <div className="flex flex-col gap-8">
              <h3 className="text-new-accent">General Information</h3>
              <Input
                required
                type="text"
                label="Full Name"
                placeholder='e.g. "John Doe"'
                value={resumeData.name}
                onChange={handleTextInputChange}
                name="name"
                id="name"
              />
              <Input
                required
                type="text"
                name="email"
                id="email"
                label="Email"
                placeholder='e.g. "johndoe@mail.com"'
                value={resumeData.email}
                onChange={handleTextInputChange}
              />
              <Input
                required
                type="text"
                name="mobile"
                id="mobile"
                label="Mobile No."
                placeholder='e.g. "9876543210"'
                value={resumeData.mobile}
                onChange={handleTextInputChange}
              />
              <Input
                required
                type="text"
                name="linkedin"
                id="linkedin"
                label="LinkedIn Profile"
                placeholder='e.g. "https://www.linkedin.com/in/john-doe/"'
                value={resumeData.linkedin}
                onChange={handleTextInputChange}
              />
            </div>
            <div className="flex flex-col gap-8">
              <h3 className="text-new-accent">Education Details</h3>
              <div className="flex flex-col gap-8">
                {resumeData.educations.map((education, educationIndex) => (
                  <div
                    key={`education${educationIndex}`}
                    className="relative flex flex-col gap-8 border-b border-new-neutral-light pb-8"
                  >
                    {educationIndex > 0 && (
                      <button
                        className="absolute top-0 right-0"
                        title="Remove"
                        onClick={() => handleRemoveEducation(educationIndex)}
                      >
                        <CrossIcon className="h-6 w-6" />
                      </button>
                    )}
                    <Input
                      required
                      type="text"
                      name="institution"
                      id={`institution${educationIndex}`}
                      label="College/School Name"
                      placeholder='e.g. "IIT Bombay"'
                      value={education.institution}
                      onChange={(e) => handleEducationChange(e, educationIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="place"
                      id={`place${educationIndex}`}
                      label="Place"
                      placeholder='e.g. "Mumbai, Maharashtra"'
                      value={education.place}
                      onChange={(e) => handleEducationChange(e, educationIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="degree"
                      id={`degree${educationIndex}`}
                      label="Degree"
                      placeholder='e.g. "B.Tech"'
                      value={education.degree}
                      onChange={(e) => handleEducationChange(e, educationIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="gpa"
                      id={`gpa${educationIndex}`}
                      label="GPA"
                      placeholder='e.g. "9.5/10"'
                      value={education.gpa}
                      onChange={(e) => handleEducationChange(e, educationIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="yearStart"
                      id={`yearStart${educationIndex}`}
                      label="Start Year"
                      placeholder='e.g. "2018"'
                      value={education.yearStart}
                      onChange={(e) => handleEducationChange(e, educationIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="yearEnd"
                      id={`yearEnd${educationIndex}`}
                      label="End Year"
                      placeholder='e.g. "2022"'
                      value={education.yearEnd}
                      onChange={(e) => handleEducationChange(e, educationIndex)}
                    />
                  </div>
                ))}
                <button
                  className="w-full rounded-sm border border-new-neutral-light bg-new-solid-white p-4 text-center text-new-neutral-dark"
                  onClick={handleAddMoreEducation}
                >
                  + Add more
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <h3 className="text-new-accent">Experience Details</h3>
              <div className="flex flex-col gap-8">
                {resumeData.experiences.map((experience, experienceIndex) => (
                  <div
                    key={`experience${experienceIndex}`}
                    className="relative flex flex-col gap-8 border-b border-new-neutral-light pb-8"
                  >
                    {experienceIndex > 0 && (
                      <button
                        className="absolute top-0 right-0"
                        title="Remove"
                        onClick={() => handleRemoveExperience(experienceIndex)}
                      >
                        <CrossIcon className="h-6 w-6" />
                      </button>
                    )}
                    <Input
                      required
                      type="text"
                      name="company"
                      id={`company${experienceIndex}`}
                      label="Company Name"
                      placeholder='e.g. "Amazon"'
                      value={experience.company}
                      onChange={(e) => handleExperienceChange(e, experienceIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="designation"
                      id={`designation${experienceIndex}`}
                      label="Designation"
                      placeholder='e.g. "Software Engineer"'
                      value={experience.designation}
                      onChange={(e) => handleExperienceChange(e, experienceIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="place"
                      id={`place${experienceIndex}`}
                      label="Place"
                      placeholder='e.g. "Mumbai, Maharashtra"'
                      value={experience.place}
                      onChange={(e) => handleExperienceChange(e, experienceIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="start"
                      id={`start${experienceIndex}`}
                      label="Start Date"
                      placeholder='e.g. "Oct 2019"'
                      value={experience.start}
                      onChange={(e) => handleExperienceChange(e, experienceIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="end"
                      id={`end${experienceIndex}`}
                      label="End Date"
                      placeholder='e.g. "Present"'
                      value={experience.end}
                      onChange={(e) => handleExperienceChange(e, experienceIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="description"
                      id={`description${experienceIndex}`}
                      label="Work Description"
                      placeholder='e.g. "Worked on the backend of the website"'
                      value={experience.description}
                      onChange={(e) => handleExperienceChange(e, experienceIndex)}
                    />
                  </div>
                ))}
                <button
                  className="w-full rounded-sm border border-new-neutral-light p-4 text-center text-new-neutral"
                  onClick={handleAddMoreExperience}
                >
                  + Add more
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <h3 className="text-new-accent">Projects</h3>
              <div className="flex flex-col gap-8">
                {resumeData.projects.map((project, projectIndex) => (
                  <div
                    key={`project${projectIndex}`}
                    className="relative flex flex-col gap-8 border-b border-new-neutral-light pb-8"
                  >
                    {projectIndex > 0 && (
                      <button
                        className="absolute top-0 right-0"
                        title="Remove"
                        onClick={() => handleRemoveProject(projectIndex)}
                      >
                        <CrossIcon className="h-6 w-6" />
                      </button>
                    )}
                    <Input
                      required
                      type="text"
                      name="title"
                      id={`projectTitle${projectIndex}`}
                      label="Title"
                      placeholder='e.g. "Todo App"'
                      value={project.title}
                      onChange={(e) => handleProjectChange(e, projectIndex)}
                    />
                    <Input
                      required
                      type="text"
                      name="description"
                      id={`projectDescription${projectIndex}`}
                      label="Description"
                      placeholder='e.g. "A todo app built using React.js"'
                      value={project.description}
                      onChange={(e) => handleProjectChange(e, projectIndex)}
                    />
                  </div>
                ))}
                <button
                  className="w-full rounded-sm border border-new-neutral-light p-4 text-center text-new-neutral"
                  onClick={handleAddMoreProject}
                >
                  + Add more
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <h3 className="text-new-accent">Programming Skills</h3>
              <div className="flex flex-col gap-4">
                <form onSubmit={(e) => handleAddSkill(e, "languages")} className="flex flex-col gap-2">
                  <label
                    htmlFor="language"
                    className="after-star text-lg font-semibold leading-[22px] text-new-neutral-dark"
                  >
                    Add languages
                  </label>
                  <div className="flex gap-4">
                    <input
                      type="text"
                      name="language"
                      id="language"
                      placeholder='e.g. "JavaScript"'
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                      className="flex-1 rounded-sm border border-new-neutral p-2"
                    />
                    <Button small className="w-fit">
                      <AddIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </form>
                <div className="flex h-full min-h-[100px] flex-wrap gap-2 border-2 border-dashed border-new-neutral-light p-2">
                  {resumeData.skills.languages.length > 0 ? (
                    resumeData.skills.languages.map((skill, skillIndex) => (
                      <div
                        key={`skill${skillIndex}`}
                        className="flex h-fit w-fit border border-new-accent text-sm font-medium text-new-accent"
                      >
                        <div className="border-r border-new-accent px-2">{skill}</div>
                        <button className="px-2" onClick={() => handleRemoveSkill(skillIndex, "languages")}>
                          X
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="flex h-full w-full items-center justify-center text-center text-new-neutral">
                      No skills added
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <form onSubmit={(e) => handleAddSkill(e, "technologies")} className="flex flex-col gap-2">
                  <label
                    htmlFor="technology"
                    className="after-star text-lg font-semibold leading-[22px] text-new-neutral-dark"
                  >
                    Add technologies
                  </label>
                  <div className="flex gap-4">
                    <input
                      type="text"
                      name="technology"
                      id="technology"
                      placeholder='e.g. "AWS"'
                      value={technology}
                      onChange={(e) => setTechnology(e.target.value)}
                      className="flex-1 rounded-sm border border-new-neutral p-2"
                    />
                    <Button small className="w-fit">
                      <AddIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </form>
                <div className="flex h-full min-h-[100px] flex-wrap gap-2 border-2 border-dashed border-new-neutral-light p-2">
                  {resumeData.skills.technologies.length > 0 ? (
                    resumeData.skills.technologies.map((skill, skillIndex) => (
                      <div
                        key={`skill${skillIndex}`}
                        className="flex h-fit w-fit border border-new-accent text-sm font-medium text-new-accent"
                      >
                        <div className="border-r border-new-accent px-2">{skill}</div>
                        <button className="px-2" onClick={() => handleRemoveSkill(skillIndex, "technologies")}>
                          X
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="flex h-full w-full items-center justify-center text-center text-new-neutral">
                      No skills added
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 space-y-6">
            <div className="space-y-1">
              <h3 className="text-new-accent">Resume Generator</h3>
              <p>Your resume will be visible after the completion of the whole process.</p>
            </div>
            <PDFViewer showToolbar={false} className="aspect-[210/296] w-full">
              <ResumeTemplate data={resumeData} />
            </PDFViewer>
          </div>
        </div>
        <div className="mt-8 flex items-center justify-center gap-4">
          <Button onClick={handleCancelResumeUpdate} outlined className="w-40">
            Cancel
          </Button>
          <Button small className="w-40" onClick={handleSubmitResumeDocument} loading={isUploading}>
            Save & Proceed
          </Button>
          <PDFDownloadLink
            className="font-medium text-new-accent hover:underline"
            document={<ResumeTemplate data={resumeData} />}
            fileName="Resume.pdf"
          >
            Download Resume
          </PDFDownloadLink>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
