import { useAuth } from "core/context/auth"
import GetAllClassesAPIDataSourceImpl from "data/API/Admin/GetAllClassesAPIDataSourceImpl"
import { GetAllClassesRepositoryImpl } from "data/repository/Admin/GetAllClassesRepositoryImpl"
import UpdateAllClassDetails from "domain/useCase/Admin/AllClasses/UpdateAllClassDetails"
import React from "react"
import GenerateInvoiceViewModel from "../GenerateInvoiceViewModel"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { genError } from "core/utils/string"
import Button from "core/components/new/Button"
import { useParams } from "react-router-dom"

export default function UpdateSession({
  isModalOpen,
  data,
  updateClassBool,
  setUpdateClassBool,
  toggleModal,
  session_id,
  instructor_name,
  rating,
  class_name,
  session_date,
  toast,
  changeToastDetails,
  changeToastVisibility,
}: any) {
  // const {

  //   fetchAllSessionMonthyear,
  // } = GenerateInvoiceViewModel();
  const [classTiming, setClassTiming] = React.useState("")
  const UpdateAllClassDetailsUseCase = new UpdateAllClassDetails(
    new GetAllClassesRepositoryImpl(new GetAllClassesAPIDataSourceImpl())
  )
  const { id, yearMonth } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [actual_duration, setActualDuration] = React.useState("")
  const [considered_duration, setConsideredDuration] = React.useState("")
  const [status, setStatus] = React.useState("")
  const { auth } = useAuth()
  const STATUSARRAY: string[] = [
    "Scheduled Class",
    "Class Taken",
    "Cancelled On Time",
    "Substituted Class",
    "Cancelled Post Time",
    "Cancelled Last Min",
  ]
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  React.useEffect(() => {
    setActualDuration(data.actual_duration ? convertTimeToMinutes(data.actual_duration) : "0")
    setConsideredDuration(data.considered_duration ? convertTimeToMinutes(data.considered_duration) : "0")
    setStatus(data.status ? data.status : "Scheduled Class")
  }, [id, yearMonth])
  const UpdateSessionTiming = async () => {
    setLoading(true)
    const response = await UpdateAllClassDetailsUseCase.invoke(
      auth,
      session_id,
      status,
      parseInt(actual_duration),
      parseInt(considered_duration)
    )
    if (!response?.success) {
      setLoading(false)
      changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      changeToastVisibility(true)
      return
    }
    setUpdateClassBool(!updateClassBool)
    setLoading(false)
    changeToastDetails(STR_SUCCESS, "Class Updated!")
    changeToastVisibility(true)
    toggleModal()
    setClassTiming(response?.data)
  }

  function convertTimeToMinutes(timeString: string) {
    const [hours, minutes] = timeString
      .toLowerCase()
      .replace("hr", "")
      .replace("min", "")
      .trim()
      .split(" ")
      .map((time) => parseInt(time, 10))

    return (hours * 60 + minutes).toString()
  }

  function toggleDropdown() {
    const dropdown = document.getElementById("dropdown")
    dropdown?.classList.toggle("hidden")
  }

  function handleActualDuration(e: any) {
    if (e.target.value) setActualDuration(e.target.value)
    else setActualDuration("")
  }
  function handleConsideredDuration(e: any) {
    if (e.target.value) setConsideredDuration(e.target.value)
    else setConsideredDuration("")
  }
  function handleStatus(e: any) {
    if (e.target.value) setStatus(e.target.value)
    else setStatus("")
  }
  return (
    <div className="space-y-4">
      <div className="flex gap-[32px]">
        <h3 className="max-w-[200px]">{class_name}</h3>
        <div className="flexcol gap-[16px]">
          <p className="">{instructor_name}</p>
          <p className="">{session_date}</p>
        </div>
        <h3>{rating} rating</h3>
      </div>

      <div className="flex gap-x-4">
        <select value={status} onChange={handleStatus} className="py-1">
          <option value="">Select any status</option>
          {STATUSARRAY.map((item: string, index: number) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>

        <input
          id="Actual Time"
          type="text"
          placeholder="Actual time in minutes"
          value={actual_duration}
          onChange={handleActualDuration}
        />
        <input
          id="Considered time"
          type="text"
          placeholder="Considered time in minutes"
          value={considered_duration}
          onChange={handleConsideredDuration}
        />
      </div>
      <Button loading={loading} outlined className="ml-auto w-fit" onClick={UpdateSessionTiming}>
        Submit Changes
      </Button>
    </div>
  )
}
