import { StoreDataSource } from "data/dataSource/Admin/StoreDataSource"
import { Auth } from "domain/model/Auth"
import { getStudentDetails, loadNextData } from "domain/model/StudentDashboard"
import { StoreRepository } from "domain/repository/Admin/StoreRepository"

export class StoreRepositoryImpl implements StoreRepository {
  private dataSource: StoreDataSource

  constructor(dataSource: StoreDataSource) {
    this.dataSource = dataSource
  }

  async addStoreItem(auth: Auth, item: any): Promise<any> {
    return await this.dataSource.addStoreItem(auth, item)
  }

  async updateStoreItem(auth: Auth, item: any, item_id: any): Promise<any> {
    return await this.dataSource.updateStoreItem(auth, item, item_id)
  }

  async deleteStoreItem(auth: Auth, item_id: any): Promise<any> {
    return await this.dataSource.deleteStoreItem(auth, item_id)
  }

  async getStoreItems(auth: Auth): Promise<any> {
    return await this.dataSource.getStoreItems(auth)
  }

  async getStoreOrders(auth: Auth): Promise<any> {
    return await this.dataSource.getStoreOrders(auth)
  }

  async getStoreOrderStatus(auth: Auth, order_id: any): Promise<any> {
    return await this.dataSource.getStoreOrderStatus(auth, order_id)
  }
  async getTop100StudentsByCoins(auth: Auth, data: any) {
    return this.dataSource.getTop100StudentsByCoins(auth, data)
  }
  async getNext30StudentsByCoins(auth: Auth, data: any, coinType: any): Promise<any> {
    return this.dataSource.getNext30StudentsByCoins(auth, data, coinType)
  }
  async updateStoreOrderStatus(auth: Auth, order_id: any, status: any): Promise<any> {
    return await this.dataSource.updateStoreOrderStatus(auth, order_id, status)
  }
  async getOrderHistory(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getOrderHistory(auth, data)
  }
  async getMentorSessionCoupon(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorSessionCoupon(auth, data)
  }

  async getClassCoinsTransaction(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getClassCoinsTransaction(auth, data)
  }
}
