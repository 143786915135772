import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import useLocalStorage from "core/hooks/useLocalStorage"
import DashboardLayout from "core/layouts/DashboardLayout"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import ScheduledClassesViewModel from "../ViewScheduledClasses/ScheduledClassesViewModel"
import Input from "core/components/Input"
import useMentorshipViewModel from "./MentorshipViewModel"

export default function MentorshipView() {
  const {
    genericBatchTypes,
    genericStudentTypes,
    genericBatchType,
    genericStudentType,
    studentType,
    studentEmail,
    isStudentTypeDisabled,
    batch,
    handleGenericBatchChangeType,
    handleGenericStudentChangeType,
    handleBatchChange,
    handleStudentEmailChange,
    handleStudentChangeType,
    navigateToEditGeneric,
    navigateToEdit,
    navigateToEditByStudent,
    getBatchList,
    getBatches,
  } = useMentorshipViewModel()

  useEffect(() => {
    getBatches()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">Create and update generic templates here</h2>
          <p className="">You have access to Mentorship Flow Templates here.</p>
        </div>
        <StandaredSelect
          className="mb-4"
          options={genericBatchTypes}
          placeholder="Batch types"
          onChange={handleGenericBatchChangeType}
          value={genericBatchType}
        />
        <StandaredSelect
          className="mb-4"
          options={genericStudentTypes}
          placeholder="Student types"
          onChange={handleGenericStudentChangeType}
          value={genericStudentType}
          disabled={isStudentTypeDisabled}
        />
        <Button onClick={navigateToEditGeneric}>Edit Batch Template</Button>
        <div className="space-y-1">
          <h2 className="text-new-accent">Update templates for a particular batch</h2>
          <p className="">You have access to Mentorship Flow Templates here.</p>
        </div>
        <StandaredSelect
          required
          id="Batch"
          options={getBatchList}
          placeholder="Select Batch"
          value={batch}
          onChange={handleBatchChange}
        />
        <StandaredSelect
          className="mb-4"
          options={genericStudentTypes}
          placeholder="Student types"
          onChange={handleStudentChangeType}
          value={studentType}
        />
        <Button onClick={navigateToEdit}>Edit Batch Template</Button>

        <div className="space-y-1">
          <h2 className="text-new-accent">Update templates for a particular student</h2>
          <p className="">You have access to Mentorship Flow Templates here.</p>
        </div>
        <Input className="" type="text" onChange={handleStudentEmailChange} placeholder="Student email" />

        <Button onClick={navigateToEditByStudent}>Edit Mentor Sessions</Button>
      </div>
    </DashboardLayout>
  )
}
