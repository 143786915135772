import { Modal } from "@mui/material"
import celebrationImage from "assets/images/celebration.png"
import { CrossIcon } from "core/constants/svgs"

const CourseCompletionCelebrationModal = ({ student, onClose }: any) => {
  if (!student.course_completed) {
    return null
  }
  return (
    <Modal
      open={true}
      onClose={() => {
        onClose()
      }}
      className="flex justify-center items-center"
    >
      <div
        className="bg-white shadow-lg mt-2 rounded-xl text-center"
        style={{
          width: "445px",
          maxWidth: "90%",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          outline: "none",
        }}
      >
        <div
          className="flex flex-col items-center justify-center gap-4 rounded-xl"
          style={{
            width: "100%",
            height: "70%",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <div className="p-4 pr-2 flex items-center flex-col justify-center">
            <div className="relative flex flex-col items-center">
              <button onClick={onClose} className="absolute top-2 right-[-4.5rem]">
                <CrossIcon className="h-6 w-6" />
              </button>
              <h2 className="text-[24px] mt-1 font-bold text-green-500">🎉 Congratulations! 🎉</h2>
            </div>
            <p className={`text-[16px] text-gray-600 p-2`}>
              We have generated your{" "}
              <b>
                <i>Course Completion Certificate! </i>
              </b>
              Share it from{" "}
              <b>
                <i>Home Page</i>
              </b>
            </p>
            <img src={celebrationImage} alt="Course Certificate" className={`rounded-lg max-w-[80%] h-auto`} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CourseCompletionCelebrationModal
