import { Auth } from "domain/model/Auth"
import { HomeRepository } from "domain/repository/Student/HomeRepository"
import HomeDataSource from "data/dataSource/Student/HomeDataSource"

export class HomeRepositoryImpl implements HomeRepository {
  private dataSource: HomeDataSource

  constructor(dataSource: HomeDataSource) {
    this.dataSource = dataSource
  }

  async getHomeDetails(auth: Auth): Promise<Auth> {
    return this.dataSource.getHomeDetails(auth)
  }

  async takeSurvey(auth: Auth, data: any): Promise<any> {
    return this.dataSource.takeSurvey(auth, data)
  }

  async toggleChecklistStatus(auth: Auth, data: any): Promise<any> {
    return this.dataSource.toggleChecklistStatus(auth, data)
  }

  async toggleHomepageListStatus(auth: Auth, data: any): Promise<any> {
    return this.dataSource.toggleHomepageListStatus(auth, data)
  }

  async getOnboardingTeamDetails(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getOnboardingTeamDetails(auth, data)
  }

  async updateHomeCardVideoStatus(auth: Auth, data: any): Promise<any> {
    return this.dataSource.updateHomeCardVideoStatus(auth, data)
  }

  async getMeetAndGreetDetails(auth: Auth): Promise<any> {
    return this.dataSource.getMeetAndGreetDetails(auth)
  }

  async getPrerequisiteDetails(auth: Auth): Promise<any> {
    return this.dataSource.getPrerequisiteDetails(auth)
  }

  async getRemainderDetails(auth: Auth, type: string): Promise<any> {
    return this.dataSource.getRemainderDetails(auth, type)
  }

  async getMentorSessionRemainder(auth: Auth): Promise<any> {
    return this.dataSource.getMentorSessionRemainder(auth)
  }

  async getContestRemainder(auth: Auth): Promise<any> {
    return this.dataSource.getContestRemainder(auth)
  }

  async getRoadmapDetails(auth: Auth): Promise<any> {
    return this.dataSource.getRoadmapDetails(auth)
  }

  async getProgressMeetDetails(auth: Auth): Promise<any> {
    return this.dataSource.getProgressMeetDetails(auth)
  }
  async getOnboardingMeetingDetails(auth: Auth): Promise<any> {
    return this.dataSource.getOnboardingMeetingDetails(auth)
  }
  async updateOnboardingMeetingDetails(auth: Auth, data: any): Promise<any> {
    return this.dataSource.updateOnboardingMeetingDetails(auth, data)
  }

  async getPlacementCohortDetails(auth: Auth): Promise<any> {
    return this.dataSource.getPlacementCohortDetails(auth)
  }
  async getRightPanelDetails(auth: Auth): Promise<any> {
    return this.dataSource.getRightPanelDetails(auth)
  }
  async getNotficationToastDetails(auth: Auth): Promise<any> {
    return this.dataSource.getNotficationToastDetails(auth)
  }
  async createPledgeResponse(auth: Auth, email: string): Promise<any> {
    return this.dataSource.createPledgeResponse(auth, email)
  }
}
