import { useEffect } from "react"
import LoginLayout from "core/layouts/LoginLayout"
import {
  STR_EMAIL,
  STR_EMAIL_FIELD,
  STR_FORGOT_PASSWORD_HEADING,
  STR_FORGOT_PASSWORD_SUBHEADING,
  STR_KNOW_YOUR_PASSWORD,
  STR_NEXT,
} from "core/constants/strings"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import useForgotPasswordViewModel from "./ForgotPasswordViewModel"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import { cn } from "core/lib/utils"
import { useNavigate } from "react-router-dom"

export default function ForgotPasswordView() {
  const {
    email,
    toast,
    isResetting,
    forgotPassword,
    handleEmailChange,
    changeToastVisibility,
    emailValidity,
    inputRef,
    handleEmailBlur,
    checkEmailValidity,
  } = useForgotPasswordViewModel()
  const navigate = useNavigate()
  useEffect(() => {
    if (email.length > 0) {
      checkEmailValidity()
    }
  }, [email])
  return (
    <>
      <div className="fixed top-0 left-0 translate-x-[-50%] translate-y-[-50%] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>

        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="absolute flex justify-center left-[60%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} className="w-[370px]" />

      <LoginLayout height="300px">
        <form className="flex w-full flex-col gap-8" onSubmit={forgotPassword}>
          <div className="w-full ">
            <h1 className="text-[#162456] text-[24px]">{STR_FORGOT_PASSWORD_HEADING}</h1>
            <p className="text-[14px] text-[#414141] top-0">{STR_FORGOT_PASSWORD_SUBHEADING}</p>
          </div>
          <div className="relative">
            <Input
              ref={inputRef}
              type={STR_EMAIL_FIELD}
              name={STR_EMAIL_FIELD}
              value={email}
              onChange={handleEmailChange}
              placeholder={STR_EMAIL}
              label="Email"
              id="forgot-password"
              onBlur={handleEmailBlur}
              className="text-[#333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px] "
              containerClass="text-sm gap-0 text-[#333]"
            />
            <span
              className={cn(
                "absolute top-[calc(100%+4px)] text-xs",
                emailValidity.status ? "text-new-success" : "text-new-failure"
              )}
            >
              {emailValidity.message}
            </span>
          </div>
          <div className="flex flex-col">
            {email && emailValidity?.status ? (
              <Button loading={isResetting} className="rounded-md text-sm bg-[#1369EB] mb-[8px]">
                {STR_NEXT}
              </Button>
            ) : (
              <Button
                loading={isResetting}
                className="rounded-md text-sm bg-[#d3d3d3] mb-[8px] pointer-events-none cursor-not-allowed"
              >
                {STR_NEXT}
              </Button>
            )}

            <div className="flex flex-row justify-between">
              <div>{STR_KNOW_YOUR_PASSWORD}</div>
              <button onClick={() => navigate("/login")} className="text-sm text-new-accent ">
                Login!
              </button>
            </div>
          </div>
        </form>
      </LoginLayout>
      <div className="flex justify-center absolute right-[60%] transform scale-y-[-1] scale-x-[-1]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 translate-x-[50%] translate-y-[-50%] scale-y-[-1] scale-x-[-1] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
    </>
  )
}
