import React from "react"

export default function InfoButton({ className, ...props }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.3335 8.00065C1.3335 11.6827 4.31816 14.6673 8.00016 14.6673C11.6822 14.6673 14.6668 11.6827 14.6668 8.00065C14.6668 4.31865 11.6822 1.33398 8.00016 1.33398C4.31816 1.33398 1.3335 4.31865 1.3335 8.00065ZM13.3335 8.00065C13.3335 10.9462 10.9457 13.334 8.00016 13.334C5.05464 13.334 2.66683 10.9462 2.66683 8.00065C2.66683 5.05513 5.05464 2.66732 8.00016 2.66732C10.9457 2.66732 13.3335 5.05513 13.3335 8.00065ZM8.66683 4.66732V6.00065H7.3335V4.66732H8.66683ZM8.66683 11.334V7.33398H7.3335V11.334H8.66683Z"
        fill="#646464"
      />
    </svg>
  )
}
