import { Auth } from "domain/model/Auth"
import AdminDocDataSource from "data/dataSource/Admin/DocDataSource"
import DocRepository from "domain/repository/Admin/DocRepository"

export class DocRepositoryImpl implements DocRepository {
  private dataSource: AdminDocDataSource

  constructor(dataSource: AdminDocDataSource) {
    this.dataSource = dataSource
  }

  async upload_image(auth: Auth, data: any): Promise<any> {
    return this.dataSource.upload_image(auth, data)
  }

  async upload_doc(auth: Auth, data: any): Promise<any> {
    return this.dataSource.upload_doc(auth, data)
  }

  async uploadImageMCQ(auth: Auth, data: any): Promise<any> {
    return this.dataSource.uploadImageMCQ(auth, data)
  }
}
