import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI } from "core/utils/axios"
import { API_CREATE_GOAL_SETTING_SESSION, API_V4_ADMIN } from "core/constants/strings"
import GoalSettingDataSource from "data/dataSource/Admin/GoalSettingDataSourse"
import { GoalSettingSession } from "domain/model/GoalSettingSession"

export default class GoalSettingDataSourceImpl implements GoalSettingDataSource {
  async createGoalSettingSession(auth: Auth, goal_setting_session: GoalSettingSession): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_GOAL_SETTING_SESSION), {
        id_token: auth?.id_token,
        goal_setting_session,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
