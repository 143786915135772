import React from "react"
import McqAPIDataSourceImpl from "data/API/Admin/McqAPIDataSourceImpl"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import GetQuestionList from "domain/useCase/Admin/Contest/GetQuestionList"
import GetCaseStudiesQuestionList from "domain/useCase/Admin/Contest/GetCaseStudiesQuestionList"
import GetSubjectiveQuestionList from "domain/useCase/Admin/Contest/GetSubjectiveQuestionsList"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import GetContestDetails from "domain/useCase/User/Contest/GetContestDetails"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import { McqRepositoryImpl } from "data/repository/Admin/McqRepositoryImpl"
import { CreateContest } from "domain/useCase/Admin/Contest/CreateContest"
import { GetMcqQuestions } from "domain/useCase/Admin/Mcq/GetMcqQuestions"
import { useNavigate, useParams } from "react-router-dom"
import { UpdateContest } from "domain/useCase/Admin/Contest/UpdateContest"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import ContestAPIAdminDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"

import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import ContestRepositoryAdminImpl from "data/repository/Admin/ContestRepositoryImpl"

import { format } from "date-fns"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import GetMcqNameFromId from "domain/useCase/Admin/Contest/GetMcqNameFromId"
import { CreateExam } from "domain/useCase/Admin/Contest/CreateExam"
import { UpdateExam } from "domain/useCase/Admin/Contest/UpdateExam"
import GetExamDetails from "domain/useCase/Admin/Contest/GetExamDetails"

export default function ContestEditViewModel() {
  const [name, setName] = React.useState<any>("")
  const [batches, setBatches] = React.useState<any>([])
  const [duration, setDuration] = React.useState<any>("")
  const [startTime, setStartTime] = React.useState<any>("")
  const [isICPContest, setIsICPContest] = React.useState<any>("")
  const [questions, setQuestions] = React.useState<any[]>([])
  const [mcqs, setMcqs] = React.useState<any[]>([])
  const [question, setQuestion] = React.useState<any>("")
  const [mcq, setMcq] = React.useState<any>("")
  const [questionScore, setQuestionScore] = React.useState<any>("")
  const [problemName, setProblemName] = React.useState<any>("")
  const [mcqData, setMcqData] = React.useState([])
  const [mcqScore, setMcqScore] = React.useState<any>("")
  const proctoringOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]

  const [subjectiveQuestions, setSubjectiveQuestions] = React.useState<any[]>([])
  const [subjectiveQuestion, setSubjectiveQuestion] = React.useState<any>("")
  const [subjectiveQuestionScore, setSubjectiveQuestionScore] = React.useState<any>("")
  const [casestudyQuestions, setCasestudyQuestions] = React.useState<any[]>([])
  const [casestudyQuestion, setCasestudyQuestion] = React.useState<any>("")
  const [casestudyQuestionScore, setCasestudyQuestionScore] = React.useState<any>("")
  const [casestudyQuestinonList, setCasestudyQuestionList] = React.useState<any[]>([])
  const [subjectiveQuestionList, setSubjectiveQuestionList] = React.useState<any[]>([])

  const [questionList, setQuestionList] = React.useState<any[]>([])
  const [mcqList, setMcqList] = React.useState<any[]>([])
  const [type, setType] = React.useState<any>("")
  const [generalInfo, setGeneralInfo] = React.useState<any>("")
  const [loading, setLoading] = React.useState(false)
  const [allFieldsValid, setAllFieldsValid] = React.useState<boolean>(false)
  const [batchList, setBatchList] = React.useState<any>([])
  const [batch, setBatch] = React.useState<string>("")
  const [mcqName, setMcqName] = React.useState<string>("")
  const [flowType, setFlowType] = React.useState<string>("newFlow")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [isProctored, setIsProctored] = React.useState("Yes")
  const { id } = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()

  const getMcqQuestionsUseCase = new GetMcqQuestions(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const getQuestionListUseCase = new GetQuestionList(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const getCaseStudiesQuestionListUseCase = new GetCaseStudiesQuestionList(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const getSubjectiveQuestionListUseCase = new GetSubjectiveQuestionList(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const createContestUseCase = new CreateContest(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const createExamUseCase = new CreateExam(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const updateContestUseCase = new UpdateContest(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const updateExamUseCase = new UpdateExam(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))

  const getContestDetailsUseCase = new GetContestDetails(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getExamDetailsUseCase = new GetExamDetails(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getMcqNameFromIdUseCase = new GetMcqNameFromId(
    new ContestRepositoryAdminImpl(new ContestAPIAdminDataSourceImpl())
  )

  const handleIsICPContestChange = (e: any) => {
    setIsICPContest(e.target.value)
  }

  async function fetchBatchList() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error while fetching all batches"))
      changeToastVisibility(true)
      return
    }

    const batches = response?.data
    setBatchList(batches?.map((b: any) => ({ value: b, label: b })))
  }

  const fetchMcqName = async (mcq_id: any) => {
    const response = await getMcqNameFromIdUseCase.invoke(auth, mcq_id)
    if (!response?.success) {
      // changeToastDetails(
      //   STR_FAILURE,
      //   genError(response, "Unable to fetch contest details")
      // );
      // changeToastVisibility(true);
      setMcqName(response.data)
    }
    return response?.data
  }
  const updateMcqObj = async (mcq: any, mcqScore: any) => {
    const mcq_id = mcq.split("/").pop()
    const response = await fetchMcqName(mcq_id)

    const mcq_name = response || mcq // fallback to the original name if response.data is undefined

    const mcqObj = {
      key: mcq_id,
      score: mcqScore,
      // name: mcq,
      mcq_name: mcq_name,
    }

    return mcqObj
  }
  const handleProctoringChange = (selectedOption: any) => {
    setIsProctored(selectedOption.target.value)
  }
  const fetchContestDetails = async () => {
    const response = await getExamDetailsUseCase.invoke(auth, id as string)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch contest details"))
      changeToastVisibility(true)
      return
    }

    const contest = response?.data
    const timestamp = parseInt(contest?.start_time) * 1000

    setName(contest?.name)
    if (!isEmpty(contest?.batches)) setBatches(contest?.batches)
    setDuration(contest?.duration)
    setStartTime(format(timestamp, "yyyy-MM-dd'T'HH:mm"))
    setType(contest?.type)
    setGeneralInfo(contest?.general_information)
    setQuestions(contest?.question_lis)
    setMcqs(contest?.mcq_lis)
    setSubjectiveQuestions(contest?.subjective_lis ?? [])
    setCasestudyQuestions(contest?.casestudy_lis ?? [])
    setIsProctored(contest?.isProctored)
    const isICPContestValue = contest.isICPContest ? "true" : "false"
    setIsICPContest(isICPContestValue)
    setFlowType(contest?.flowType)
  }

  const fetchMcqQuestionList = async () => {
    setLoading(true)

    const response = await getMcqQuestionsUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch mcq questions"))
      changeToastVisibility(true)
      return
    }
    setMcqList(response?.data)
  }

  const fetchQuestionList = async () => {
    setLoading(true)

    const response = await getQuestionListUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch mcq questions"))
      changeToastVisibility(true)
      return
    }

    setQuestionList(response?.data)
  }

  const fetchCaseStudiesQuestion = async () => {
    setLoading(true)

    const response = await getCaseStudiesQuestionListUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch case studies questions"))
      changeToastVisibility(true)
      return
    }

    setCasestudyQuestionList(response?.data)
  }

  const fetchSubjectiveQuestion = async () => {
    setLoading(true)

    const response = await getSubjectiveQuestionListUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch subjective questions"))
      changeToastVisibility(true)
      return
    }

    setSubjectiveQuestionList(response?.data)
  }

  const handleNameChange = (event: any) => {
    setName(event.target.value)
  }

  function handleBatchChange(e: any) {
    setBatch(e.target.value)
    setBatches([...batches, e.target.value])
  }

  const handleDurationChange = (event: any) => {
    setDuration(event.target.value)
  }

  const handleStartTimeChange = (event: any) => {
    setStartTime(event.target.value)
  }

  const handleTypeChange = (event: any) => {
    setType(event.target.value)
  }

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value)
  }

  const handleSubjectiveQuestionChange = (e: any) => {
    setSubjectiveQuestion(e.target.value)
    const question_value = e.target.value
    const mainQuestionId = e.target.value.split("/").pop()
    setSubjectiveQuestion(e.target.value)
    const selectedSubjectiveQuestion = subjectiveQuestionList.find((item) => item.id === mainQuestionId)

    if (selectedSubjectiveQuestion && selectedSubjectiveQuestion.coins) {
      const subjectiveScore = parseInt(selectedSubjectiveQuestion.coins) / 5
      setSubjectiveQuestionScore(subjectiveScore)
    } else {
      setSubjectiveQuestionScore(0)
    }
  }

  const handleCaseStudyQuestionChange = (e: any) => {
    const question_value = e.target.value
    const mainQuestionId = e.target.value.split("/").pop()
    setCasestudyQuestion(e.target.value)
    const selectedCaseStudy = casestudyQuestinonList.find((item) => item.id === mainQuestionId)

    if (selectedCaseStudy && selectedCaseStudy.coins) {
      const casestudyScore = parseInt(selectedCaseStudy.coins) / 5
      setCasestudyQuestionScore(casestudyScore)
    } else {
      setCasestudyQuestionScore(0)
    }
  }

  const handleMcqChange = (e: any) => {
    setMcq(e.target.value)
  }

  const handleQuestionScoreChange = (e: any) => {
    setQuestionScore(e.target.value)
  }

  const handleCaseStudyQuestionScoreChange = (e: any) => {
    setCasestudyQuestionScore(e.target.value)
  }

  const handleSubjectiveQuestionScoreChange = (e: any) => {
    setSubjectiveQuestionScore(e.target.value)
  }

  const handleMcqScoreChange = (e: any) => {
    setMcqScore(e.target.value)
  }

  const handleGeneralInfoChange = (e: any) => {
    setGeneralInfo(e.target.value)
  }

  const redirectToContests = () => {
    navigate("/admin/contests")
  }

  const handleAddQuestion = (e: any, type: string) => {
    e.preventDefault()

    const validateFields = (question: any, score: any) => {
      if (question === "" || score === "") {
        changeToastDetails(STR_FAILURE, "Please fill all the fields")
        changeToastVisibility(true)
        return false
      }

      if (score < 0) {
        changeToastDetails(STR_FAILURE, "Score cannot be negative")
        changeToastVisibility(true)
        return false
      }

      if (score > 100) {
        changeToastDetails(STR_FAILURE, "Score cannot be greater than 100")
        changeToastVisibility(true)
        return false
      }

      return true
    }

    if (type === "question") {
      if (!validateFields(question, questionScore)) return

      let isPresent = questions.some((questionObj) => questionObj.key === question.split("/").pop())

      if (isPresent) {
        changeToastDetails(STR_FAILURE, "Question already added")
        changeToastVisibility(true)
        return
      }

      const questionObj = {
        key: question.split("/").pop(),
        score: questionScore,
        type: "coding",
        problem_name: question,
      }

      setQuestions((prevQuestions) => [...prevQuestions, questionObj])
      setQuestion("")
      setQuestionScore("")
    } else if (type === "caseStudy") {
      if (!validateFields(casestudyQuestion, casestudyQuestionScore)) return

      let isPresent = casestudyQuestions.some((questionObj) => questionObj.key === casestudyQuestion.split("/").pop())

      if (isPresent) {
        changeToastDetails(STR_FAILURE, "Question already added")
        changeToastVisibility(true)
        return
      }

      const questionObj = {
        key: casestudyQuestion.split("/").pop(),
        score: casestudyQuestionScore,
        type: "caseStudy",
        problem_name: casestudyQuestion, // Use problem_name for consistency
      }

      setCasestudyQuestions((prevCasestudyQuestions) => [...prevCasestudyQuestions, questionObj])
      setCasestudyQuestion("")
      setCasestudyQuestionScore("")
    } else {
      if (!validateFields(subjectiveQuestion, subjectiveQuestionScore)) return

      let isPresent = subjectiveQuestions.some((questionObj) => questionObj.key === subjectiveQuestion.split("/").pop())

      if (isPresent) {
        changeToastDetails(STR_FAILURE, "Question already added")
        changeToastVisibility(true)
        return
      }

      const questionObj = {
        key: subjectiveQuestion.split("/").pop(),
        score: subjectiveQuestionScore,
        type: "subjective",
        problem_name: subjectiveQuestion, // Use problem_name for consistency
      }

      setSubjectiveQuestions((prevSubjectiveQuestions) => [...prevSubjectiveQuestions, questionObj])
      setSubjectiveQuestion("")
      setSubjectiveQuestionScore("")
    }
  }

  const handleAddMcq = async (e: any) => {
    e.preventDefault()

    if (mcq === "" || mcqScore === "") {
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      changeToastVisibility(true)
      return
    }

    if (mcqScore < 0) {
      changeToastDetails(STR_FAILURE, "Score cannot be negative")
      changeToastVisibility(true)
      return
    }

    if (mcqScore > 100) {
      changeToastDetails(STR_FAILURE, "Score cannot be greater than 100")
      changeToastVisibility(true)
      return
    }

    let isPresent = false

    mcqs.forEach((mcqObj) => {
      if (mcqObj?.key === mcq.split("/").pop()) {
        isPresent = true
      }
    })

    if (isPresent) {
      changeToastDetails(STR_FAILURE, "Question already added")
      changeToastVisibility(true)
      return
    }

    const mcq_id = mcq.split("/").pop()
    const response = await fetchMcqName(mcq_id)

    const mcq_name = response // Fallback to the original name if response.data is undefined

    const mcqObj = {
      key: mcq_id,
      score: mcqScore,
      // name: mcq,
      mcq_name: mcq_name,
    }

    setMcqs([...mcqs, mcqObj])
    setMcq("")
    setMcqScore("")
  }

  const handleRemoveMcq = (id: any) => {
    const newMcqs = mcqs.filter((mcq) => mcq?.key !== id)
    setMcqs(newMcqs)
  }

  const handleRemoveQuestion = (id: any, type: string) => {
    if (type === "question") {
      const newQuestions = questions.filter((question) => question?.key !== id)
      setQuestions(newQuestions)
    } else if (type === "casestudy") {
      const newQuestions = casestudyQuestions.filter((question) => question?.key !== id)
      setCasestudyQuestions(newQuestions)
    } else if (type === "subjective") {
      const newQuestions = subjectiveQuestions.filter((question) => question?.key !== id)

      setSubjectiveQuestions(newQuestions)
    }
    return
  }

  const checkIfAllFieldsAreFilled = () => {
    if (
      name === "" ||
      isEmpty(batches) ||
      duration === "" ||
      startTime === "" ||
      type === "" ||
      generalInfo === ""
      // (questions.length === 0 && mcqs.length === 0)
    ) {
      setAllFieldsValid(false)
      return
    }

    setAllFieldsValid(true)
  }

  const segregateContestData = () => {
    const contestData = {
      name,
      batches,
      duration: parseInt(duration),
      start_time: Math.floor(new Date(startTime).getTime() / 1000),
      type,
      general_information: generalInfo,
      question_lis: questions?.map((question) => ({
        ...question,
        score: parseInt(question?.score),
      })),
      isICPContest: isICPContest === "true",
      mcq_lis: mcqs?.map((mcq) => ({
        ...mcq,
        score: parseInt(mcq?.score),
      })),
      subjective_lis: subjectiveQuestions?.map((question) => ({
        ...question,
        score: parseInt(question?.score),
      })),
      casestudy_lis: casestudyQuestions?.map((question) => ({
        ...question,
        score: parseInt(question?.score),
      })),
      isProctored: isProctored,

      flowType,
    }

    return contestData
  }
  const resetAllValues = () => {
    setName("")
    setBatches([])
    setDuration("")
    setStartTime("")
    setType("")
    setGeneralInfo("")
    setQuestions([])
    setMcqs([])
    setQuestion("")
    setMcq("")
    setQuestionScore("")
    setMcqScore("")
    setSubjectiveQuestions([])
    setCasestudyQuestions([])
    setCasestudyQuestionScore("")
    setSubjectiveQuestionScore("")
    setCasestudyQuestion("")
    setSubjectiveQuestion("")
    // setFlowType("newFlow")
  }

  const handleCreateContest = async () => {
    const contestData = segregateContestData()

    setLoading(true)

    const response = await createExamUseCase.invoke(auth, contestData)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to create exam"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Exam created successfully")
    changeToastVisibility(true)

    resetAllValues()
  }

  const handleUpdateContest = async () => {
    let contestData: any = segregateContestData()
    contestData = {
      ...contestData,
      contest_id: id,
    }

    setLoading(true)

    const response = await updateExamUseCase.invoke(auth, contestData)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to update exam"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Updated Exam successfully")
    changeToastVisibility(true)
  }

  const handleDuplicateContest = async () => {
    let contestData: any = segregateContestData()

    setLoading(true)

    if (contestData.flowType) {
      const response = await createExamUseCase.invoke(auth, contestData)
      setLoading(false)

      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response, "Unable to duplicate exam"))
        changeToastVisibility(true)
        return
      }

      changeToastDetails(STR_SUCCESS, "Duplicate Exam Created successfully")
      changeToastVisibility(true)
    } else {
      const response = await createContestUseCase.invoke(auth, contestData)
      setLoading(false)

      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response, "Unable to duplicate contest"))
        changeToastVisibility(true)
        return
      }

      changeToastDetails(STR_SUCCESS, "Duplicate Contest Created successfully")
      changeToastVisibility(true)
    }
  }

  const handleRemoveBatch = (index: number) => {
    setBatches(removeAtIndex(batches, index))
  }
  return {
    id,
    toast,
    loading,
    name,
    duration,
    startTime,
    type,
    generalInfo,
    questionList,
    subjectiveQuestionList,
    casestudyQuestinonList,
    mcqList,
    questions,
    mcqs,
    question,
    mcq,
    allFieldsValid,
    questionScore,
    casestudyQuestions,
    subjectiveQuestions,
    casestudyQuestion,
    subjectiveQuestion,
    casestudyQuestionScore,
    subjectiveQuestionScore,
    mcqScore,

    batchList,
    batches,
    batch,
    mcqName,
    fetchContestDetails,
    checkIfAllFieldsAreFilled,
    handleRemoveQuestion,
    handleRemoveMcq,
    handleGeneralInfoChange,
    handleAddQuestion,
    handleAddMcq,
    handleQuestionScoreChange,
    handleCaseStudyQuestionScoreChange,
    handleSubjectiveQuestionScoreChange,
    handleMcqScoreChange,
    handleDuplicateContest,
    handleQuestionChange,
    handleCaseStudyQuestionChange,
    handleSubjectiveQuestionChange,
    handleMcqChange,
    handleNameChange,
    handleDurationChange,
    handleStartTimeChange,
    handleTypeChange,
    handleUpdateContest,
    handleCreateContest,
    fetchMcqQuestionList,
    fetchQuestionList,
    fetchCaseStudiesQuestion,
    fetchSubjectiveQuestion,
    setSubjectiveQuestion,
    setSubjectiveQuestionScore,
    redirectToContests,
    changeToastVisibility,
    fetchBatchList,
    handleBatchChange,
    handleRemoveBatch,
    setIsICPContest,
    isICPContest,
    handleIsICPContestChange,
    isProctored,
    handleProctoringChange,
    proctoringOptions,
    fetchMcqName,
    setMcqData,
    setCasestudyQuestionScore,
  }
}
