import React, { useState, useEffect } from "react"
import CertificateGradient from "core/components/new/CertificateGradient"
import { Button } from "core/components/v2/Button"
import lockImage from "assets/images/lock.png"
import CertificateImage from "assets/images/JohnDoe.jpg"
import celebrationImage from "assets/images/celebration.png"

interface CourseCompletionCardProps {
  courseCompleted: boolean
  course_name: string
  certificate_url: string
  onFeedbackClick: () => void
}

const CourseCompletionCard: React.FC<CourseCompletionCardProps> = ({
  courseCompleted,
  certificate_url,
  course_name,
  onFeedbackClick,
}) => {
  const openPDFInNewWindow = () => {
    const pdfUrl =
      "https://firebasestorage.googleapis.com/v0/b/bosscoderplatformindia.appspot.com/o/Bosscoder%20Placement%20Policy.pdf?alt=media&token=7d2a1aac-c915-4035-b473-1b1ff7b91cdf"
    window.open(pdfUrl, "_blank")
  }

  return (
    <div className="relative">
      <div
        className={`relative  w-full overflow-hidden rounded-md border border-red-600 bg-[#FFEDED] px-6 py-4 color-[#333]`}
      >
        <div className="flex items-center justify-between">
          <div className=" w-full flex-col gap-[5px]">
            <div className="text-black font-inter text-sm font-semibold leading-normal">User Class Feedback</div>
            <div
              className="text-gray-500 font-inter text-[10px] font-normal leading-normal
"
            >
              Provide your valuable feedback to increase class quality.
            </div>
          </div>
          <div>
            <Button
              className="rounded border border-gray-400 bg-white
"
              variant={"ternary"}
              onClick={onFeedbackClick}
              // className=" w-[108px] h-[35px] py-[4px] text-white rounded-md border border-white bg-[#333333] text-xs hover:bg-white hover:text-[#333]"
            >
              Feedback
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCompletionCard
