import axios from "axios" // Import axios if not globally available
import { Auth } from "domain/model/Auth"
import { CompensationRepository } from "domain/repository/Mentor/CompensationRepository"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_MENTOR, DETAILS } from "core/constants/strings"

const server = new Server()

export class CompensationDetailsImpl implements CompensationRepository {
  async getMentorCompensationDetails(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(API_MENTOR, DETAILS), {
        headers: {
          Authorization: `Bearer ${auth.id_token}`,
        },
      })
      const responseData = await response.data
      return responseData
    } catch (error) {
      console.error("Error fetching mentor details:", error)
      throw error
    }
  }
}
