import React, { useState, useEffect } from "react"
import useProgressRoadmapViewModel from "./BatchProgressRoadmapViewModel"
import Pattern from "assets/svgs/Pattern"
import RightBar from "./ProgressRoadmap/layout/RightBar"
import Header from "./ProgressRoadmap/layout/Header"
// import ProgressRoadmapLayout from "./layout/ProgressRoadmapLayout";
import DashboardLayout from "core/layouts/DashboardLayout"
import ClassSchedule from "assets/svgs/ClassSchedule"
import MentorSession from "assets/svgs/MentorSession"
import PlacementReadiness from "assets/svgs/PlacementReadiness"
import ProblemSolving from "assets/svgs/ProblemSolving"
import WeeklyTests from "assets/svgs/WeeklyTests"
import DailyReport from "./ProgressRoadmap/components/DailyReport/DailyReport"
import MonthlyReport from "./ProgressRoadmap/components/MonthlyReport/MonthlyReport"
import OverallReport from "./ProgressRoadmap/components/OverallReport/OverallReportView"
import WeeklyReport from "./ProgressRoadmap/components/WeeklyReport/WeeklyReport"
import OptionField from "./OptionField"
import Search from "assets/svgs/Search"
import { Select, SelectItem } from "core/components/v2/DropdownInput"

export default function ProgressRoadmapView() {
  const {
    id,
    student,
    getUserData,
    navigate,
    batchList,
    getBatches,
    weekData,
    getWeeklyReport,
    selectedField,
    years,
    months,
    handleOptionClick,
    handleSelectBatch,
    handleSelectMonth,
    handleSelectYear,
    active,
    setActive,
    selectedBatch,
    setSelectedBatch,
    month,
    setMonth,
    year,
    setYear,
    setSelectedField,
    currentDate,
    currentMonth,
    currentYear,
  } = useProgressRoadmapViewModel()

  useEffect(() => {
    getBatches()
  }, [])

  useEffect(() => {
    if (true) {
      getUserData(id)
    }
  }, [navigate, id])

  return (
    <div className="">
      <DashboardLayout
        rightBar={<RightBar student={student} />}
        header={<Header points={student.points || student.total_points} />}
      >
        <div className="font-montserrat flex-row space-y-6 p-6 overflow-hidden">
          <div className="bg-[#F9F9F9] p-3">
            <div className="relative w-[300px] flex items-center">
              <input
                type="text"
                className="w-full h-[33px] pl-10 pr-3 bg-white border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-500 transition ease-out duration-100"
                placeholder="Search Batch"
              />
              <Search classes="right-[14px] top-1/2 absolute transform -translate-y-1/2 w-4 h-4 text-gray-400" />
            </div>
          </div>

          <div>
            <div className="mb-[2px] text-[24px] font-bold text-[#333333]">Batch Progress Roadmap</div>
            {/* <div>{joiningDate}</div> */}
            <div className="text-[14px]  leading-6 text-[#737373]">Track and maintain all your daily progress.</div>
          </div>

          <div className="flex items-center gap-2">
            <Select placeholder={"B23A"} onChange={handleSelectBatch} defaultValue={"B23A"}>
              {batchList.map((val: any) => (
                <SelectItem value={val}>{val}</SelectItem>
              ))}
            </Select>
            <Select placeholder={months[currentMonth]} onChange={handleSelectMonth} defaultValue={months[currentMonth]}>
              {months.map((val: any) => (
                <SelectItem value={val}>{val}</SelectItem>
              ))}
            </Select>
            <Select placeholder={currentYear} onChange={handleSelectYear} defaultValue={currentYear}>
              {years.map((val: any) => (
                <SelectItem value={val}>{val}</SelectItem>
              ))}
            </Select>
          </div>

          <div className="flex space-x-4 w-[600px] h-[43px] p-[4px_6px] gap-[4px] rounded-tl-[4px] border-t border-l border-b border-none bg-[#EBEBEB] border-[#EBEBEB]">
            <button
              className={`h-[35px] rounded-sm border-[0.5px] px-4 text-[12px] font-medium text-[#6B6B6B] hover:border-[#FFFFFF] hover:bg-[#FFFFFF]  ${
                active === 1 ? "border-[#FFFFFF]] bg-[#FFFFFF] text-black" : ""
              } hover:text-black font-semibold text-[14px]`}
              onClick={() => setActive(1)}
            >
              Overall Report
            </button>

            <button
              className={`h-[35px] rounded-sm border-[0.5px] px-4 text-[12px] font-medium text-[#6B6B6B] hover:border-[#FFFFFF] hover:bg-[#FFFFFF]  ${
                active === 2 ? "border-[#FFFFFF]] bg-[#FFFFFF] text-black" : ""
              } hover:text-black font-semibold text-[14px]`}
              onClick={() => setActive(2)}
            >
              Daily Report
            </button>
            <button
              className={`h-[35px] rounded-sm border-[0.5px] px-4 text-[12px] font-medium text-[#6B6B6B] hover:border-[#FFFFFF] hover:bg-[#FFFFFF]  ${
                active === 3 ? "border-[#FFFFFF]] bg-[#FFFFFF] text-black" : ""
              } hover:text-black font-semibold text-[14px]`}
              onClick={() => setActive(3)}
            >
              Weekly Report
            </button>
            <button
              className={`h-[35px] rounded-sm border-[0.5px] px-4 text-[12px] font-medium text-[#6B6B6B] hover:border-[#FFFFFF] hover:bg-[#FFFFFF]  ${
                active === 4 ? "border-[#FFFFFF]] bg-[#FFFFFF] text-black" : ""
              } hover:text-black font-semibold text-[14px]`}
              onClick={() => setActive(4)}
            >
              Monthly Report
            </button>
          </div>

          <div className="flex items-center">
            <OptionField
              icon={ClassSchedule}
              text={"Classes Schedule"}
              selected={selectedField === "Classes Schedule"}
              onClick={() => handleOptionClick("Classes Schedule")}
            />
            <OptionField
              icon={MentorSession}
              text={"Mentor Sessions"}
              selected={selectedField === "Mentor Sessions"}
              onClick={() => handleOptionClick("Mentor Sessions")}
            />
            <OptionField
              icon={WeeklyTests}
              text={"Weekly Test"}
              selected={selectedField === "Weekly Test"}
              onClick={() => handleOptionClick("Weekly Test")}
            />
            <OptionField
              icon={ProblemSolving}
              text={"Problem Solving skills"}
              selected={selectedField === "Problem Solving skills"}
              onClick={() => handleOptionClick("Problem Solving skills")}
            />
            <OptionField
              icon={PlacementReadiness}
              text={"Placement Readiness"}
              selected={selectedField === "Placement Readiness"}
              onClick={() => handleOptionClick("Placement Readiness")}
            />
          </div>
          {active === 1 && (
            <div>
              <OverallReport />
            </div>
          )}
          {active === 2 && (
            <>
              <DailyReport
                id={id}
                // joiningDate={joiningDate}
                // fetchthing={getDailyReportData}
              />
            </>
          )}
          {active === 3 && (
            <div>
              <WeeklyReport
                selectedBatch={selectedBatch}
                selectedMonth={month}
                selectedYear={year}
                weekData={weekData}
                getWeeklyReport={getWeeklyReport}
                selectedFieldType={selectedField}
              />
            </div>
          )}
          {active === 4 && <div>{/* <MonthlyReport /> */}</div>}
        </div>
      </DashboardLayout>
    </div>
  )
}
