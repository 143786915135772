import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import AdminDocDataSource from "data/dataSource/Admin/DocDataSource"
import {
  API_V4_ADMIN,
  API_GET_ALL_DEVELOPERS,
  API_GET_DOUBT_SUPPORT_SCHEDULE,
  API_UPDATE_DOUBT_SUPPORT_SCHEDULE,
} from "core/constants/strings"
import DoubtSupportDataSource from "data/dataSource/Admin/DoubtSupportDataSource"
import { postAPI } from "core/utils/axios"

export default class DoubtSupportDataSourceImpl implements DoubtSupportDataSource {
  async getAllDevelopers(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_DEVELOPERS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getDoubtSupportSchedule(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_DOUBT_SUPPORT_SCHEDULE), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateDoubtSupportSchedule(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_DOUBT_SUPPORT_SCHEDULE), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
