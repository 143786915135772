import { API_ADMIN, API_SUPER_ADMIN } from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { SuperAdminDashboardDataSource } from "data/dataSource/SuperAdmin/SuperAdminDashboardDataSource"
import { Auth } from "domain/model/Auth"
import { deleteAdminDetails, getAdminDetails, updateRoleAdminDetails } from "domain/model/SuperAdminDashboard"
const server = new Server()
export class SuperAdminDashboardAPIDataSourceImpl implements SuperAdminDashboardDataSource {
  async getAllAdmins(details: getAdminDetails, auth: Auth): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "super", "get_admins"),
        {
          details: details,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      return response
    } catch (error) {
      return error
    }
  }
  async updateRoleAdmin(details: updateRoleAdminDetails, auth: Auth): Promise<any> {
    try {
      const response = await server.put(
        pn(API_ADMIN, "super", "update_role"),
        {
          details: details,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      return response
    } catch (error) {
      return error
    }
  }

  async deleteAdmin(details: deleteAdminDetails, auth: Auth): Promise<any> {
    try {
      const response = await server.delete(
        pn(API_ADMIN, "super", "delete_admin"),
        {
          details: details,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
