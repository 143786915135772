import React from "react"

export default function ConfirmPaymentPopup2({ onCancel, onConfirm, months }: any) {
  return (
    <React.Fragment>
      <div className="fixed inset-0 bg-black opacity-80 z-10"></div>
      <div className="rounded flex flex-col items-center fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 h-[280px] w-[390px] bg-white p-6 text-black">
        <div className="flex justify-between w-full items-center">
          <div className="text-center font-[500] text-[16px]">EMI Confirmation</div>
          <button onClick={onCancel} className="">
            <svg className="w-6 h-6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z"
                fill="#414141"
              />
            </svg>
          </button>
        </div>
        <div className="mt-4 text-[14px] text-[#414141] leading-[22.4px] font-[400]">
          You have selected {months} Month EMI Payment as your preferred payment option.
        </div>
        <div className="w-full my-2 text-sm text-[#414141] leading-[22.4px] text-[15px] font-[600]">
          {months} Months EMI
        </div>
        <div className="w-full text-[#DC2626] text-[12px] pb-4 font-[400]">
          Note ** Once selected you cannot change the opted EMI option.
        </div>
        <div className="w-full flex justify-end items-center mt-auto gap-[11px]">
          <button
            className="font-[500] leading-normal cursor-pointer text-[14px] gap-1 text-[#DC2626] border px-[16px] py-[9px] rounded bg-white hover:bg-gray-100"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="font-[500] leading-normal cursor-pointer text-[14px] gap-1 text-white border px-[16px] py-[9px] rounded bg-[#162456] hover:bg-new-accent hover:text-new-solid-white"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}
