import DoubtSupportDataSource from "data/dataSource/Admin/DoubtSupportDataSource"
import { Auth } from "domain/model/Auth"
import DoubtSupportRepository from "domain/repository/Admin/DoubtSupportRepository"

export class DoubtSupportRepositoryImpl implements DoubtSupportRepository {
  private Datasource: DoubtSupportDataSource

  constructor(Datasource: DoubtSupportDataSource) {
    this.Datasource = Datasource
  }

  async getAllDevelopers(auth: Auth): Promise<any> {
    return await this.Datasource.getAllDevelopers(auth)
  }

  async getDoubtSupportSchedule(auth: Auth): Promise<any> {
    return await this.Datasource.getDoubtSupportSchedule(auth)
  }

  async updateDoubtSupportSchedule(auth: Auth, data: any): Promise<any> {
    return await this.Datasource.updateDoubtSupportSchedule(auth, data)
  }
}
