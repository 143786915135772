import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import CopyIcon from "assets/svgs/Copy"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import FeatureFeedbackViewModel from "./FeatureFeedbackViewModel"
import FeedbackCard from "../MonthlyFeedback/FeedbackCard"
import FeatureFeedbackCard from "./FeatureFeedbackCard"
import SmartPeople from "assets/svgs/SmartPeople"

export default function FeatureFeedbackTable() {
  const {
    batch,
    getBatches,
    handleBatchChange,
    studentBatch,
    fetchAllFeedback,
    featureFeedback,
    firstFiltered100,
    handleChange,
    searchByEmail,
    setSearchByEmail,
    setStudentBatch,
    setMaxRating,
    setMinRating,
    minRating,
    maxRating,
    handleDateChange,
    fromDate,
    toDate,
    appendNext100,
    first100,
    setFromDate,
    setToDate,
    curIdx,
    selectedPageTab,
    setSelectedPageTab,
    allFeatureFeedback,
    setfirst100,
    setfirstFiltered100,
    setCurIdx,
    filteredData,
    promoters,
    detractors,
    npsScore,
    totalresponses,
    DownloadCSV,
  } = FeatureFeedbackViewModel()

  const [selectedTab, setSelectedTab] = React.useState<string>("All Students")
  const [loading, setLoading] = React.useState(true)
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [noResultsMessage, setNoResultsMessage] = React.useState<string | null>(null)

  const loaderRef = React.useRef(null)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false)
  const [selectedFeedback, setSelectedFeedback] = React.useState<SelectedFeedback | null>(null)

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const items = ["Curriculum", "Mentorship", "Exam", "Placement", "Doubt Support"]
  const tabs = ["All Students", "Less Than or Equal to 3", "4 Rating", "5 Rating"]

  function clearFilters() {
    setSearchByEmail("")
    setFromDate(null)
    setToDate(null)
    setMinRating(0)
    setStudentBatch("")
    setMaxRating(10)
    setSelectedTab("All Students")
    setSelectedPageTab("Curriculum")
    setfirst100(allFeatureFeedback)
    setfirstFiltered100(allFeatureFeedback.slice(0, 100))
    setCurIdx(100)
  }

  interface SelectedFeedback {
    studentName: string
    pageRating: number
    feedbackText: string
    pageName: string
    Batch: string
  }

  const pageNameMapping = {
    Curriculum: "Curriculum/Classes",
    Mentorship: "Mentor",
    Exam: "Exam",
    "Doubt Support": "Doubt Support",
    Placement: "Placement",
  }

  React.useEffect(() => {
    getBatches()
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await fetchAllFeedback()
      } catch (error) {
        console.error("Error in fetchData:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const copyToClipboard = (email: string) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        changeToastDetails(STR_SUCCESS, `Copied ${email} to clipboard`)
        changeToastVisibility(true)

        setTimeout(() => changeToastVisibility(false), 3000)
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err)
      })
  }

  const handleViewFeedback = (email: string, feedback: any) => {
    setSelectedFeedback({
      studentName: feedback.name,
      pageRating: parseInt(feedback.feedbackEntry.feedback, 10),
      feedbackText: feedback.feedbackEntry.feedback_text || "",
      pageName: selectedPageTab || "Unknown Page",
      Batch: feedback.batch,
    })
    setIsPopupOpen(true)
  }

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab)
    if (tab === "Less Than or Equal to 3") {
      setMinRating(1)
      setMaxRating(3)
    } else if (tab === "4 Rating") {
      setMinRating(4)
      setMaxRating(4)
    } else if (tab === "5 Rating") {
      setMinRating(5)
      setMaxRating(5)
    } else if (tab === "All Students") {
      setMinRating(1)
      setMaxRating(5)
    }
  }
  const handlePageTabClick = (item: string) => {
    setSelectedPageTab(item)
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting && firstFiltered100.length > 0) {
        appendNext100()
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [first100, firstFiltered100])

  return (
    <DashboardLayout>
      {toast && (
        <Toast
          data={toast}
          onClick={() => changeToastVisibility(false)}
          className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50"
        />
      )}
      <div className="flex flex-col gap-6 p-8">
        <div className="flex items-center gap-6">
          <h3 className="">Promoters:{isNaN(promoters) ? "" : promoters}</h3>
          <h3 className="">Detractors: {isNaN(detractors) ? "" : detractors}</h3>
          <h3 className="">Total Responses:{isNaN(totalresponses) ? "" : totalresponses} </h3>

          <h3 className="">NPS:{isNaN(npsScore) ? "" : Math.round(npsScore * 100) / 100} </h3>
        </div>
      </div>
      <div className="flex">
        <div className="w-4/5 p-4 m-2.5 border border-gray-300 rounded-md">
          <div className="mb-4">
            <h2 className="text-2xl font-bold text-gray-600">Feature Feedback</h2>
            <p className="text-[#737373]">Track Feature-specific Students' feedback here.</p>
          </div>
          <div className="flex items-end mb-3">
            <Input
              id="email"
              type="text"
              className="w-[300px]"
              placeholder="Email Id"
              value={searchByEmail}
              onChange={handleChange}
            />
            <div className="flex flex-col gap-2 ml-2">
              <StandaredSelect
                id="batch"
                className="w-[300px]"
                required
                options={batch}
                placeholder="Select Batch"
                onChange={handleBatchChange}
                value={studentBatch}
              />
            </div>
            <button
              className="border-[1px] px-[12px] rounded-md p-1 ml-2 hover:bg-new-accent hover:text-white hover:cursor-pointer"
              onClick={clearFilters}
              id="button"
            >
              Clear All Filters
            </button>
          </div>
          <div className="flex space-x-2 mb-5">
            <div className="space-y-2">
              <label>From Date</label>
              <Input
                type="date"
                placeholder="From Date"
                className="w-[300px]"
                onChange={handleDateChange}
                id="fromDate"
                value={fromDate !== null ? new Date(fromDate * 1000).toISOString().split("T")[0] : ""}
              />
            </div>
            <div className="space-y-2">
              <label>To Date</label>
              <Input
                type="date"
                placeholder="To Date"
                className="w-[300px]"
                onChange={handleDateChange}
                id="toDate"
                value={toDate !== null ? new Date(toDate * 1000).toISOString().split("T")[0] : ""}
              />
            </div>
          </div>
          <div className="flex items-start gap-2 p-[4px_6px] rounded border border-[#EBEBEB] bg-[#EBEBEB] w-fit h-[43px] mt-6">
            {items.map((item, index) => (
              <div
                key={index}
                className={`flex justify-center items-center gap-[13px] p-[9px_16px] rounded  shadow-[1px_1px_2px_-1px_rgba(34,41,48,0.05)] h-[33px] hover:cursor-pointer 
                           ${selectedPageTab === item ? " bg-white" : "hover:bg-white"}`}
                onClick={() => {
                  handlePageTabClick(item)
                }}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="flex space-x-2 mt-6">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`border border-gray-300 p-2 rounded-md transition-colors hover:cursor-pointer
                               ${selectedTab === tab ? "bg-[#162456] text-white" : "hover:bg-[#162456] hover:text-white"}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
            <button
              onClick={DownloadCSV}
              className="border border-gray-300 p-2 rounded-md transition-colors hover:cursor-pointer"
            >
              Download CSV
            </button>
          </div>
          <table className="w-full border border-gray-300 rounded-md mt-6">
            <thead className="bg-gray-800 w-full">
              <tr className="bg-gray-800 ">
                <th className="px-4 py-2 text-left text-white font-normal tracking-wider rounded-tl-md">
                  Student Name
                </th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider"></th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider">Rating</th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider">Batch</th>
                <th className="px-4 py-2 text-left text-white font-normal  tracking-wider">Submitted On</th>
                <th className="px-4 py-2 text-left text-white font-normal tracking-wider rounded-tr-md"></th>
              </tr>
            </thead>
            {loading && (
              <tr>
                <td colSpan={4} className="px-4 py-2 text-center m-auto">
                  <div className="m-auto">
                    <Box sx={{ display: "flex", marginLeft: "25vw" }}>
                      <CircularProgress />
                    </Box>
                  </div>
                </td>
              </tr>
            )}
            <tbody>
              {featureFeedback?.length > 0 ? (
                firstFiltered100
                  .map((f: any) => ({
                    ...f,
                    feedbackForPage: f.pages[pageNameMapping[selectedPageTab as keyof typeof pageNameMapping]] || [],
                  }))
                  .filter((f: any) => f.feedbackForPage.length > 0)
                  .flatMap((f: any) =>
                    f.feedbackForPage.map((pageFeedback: any) => ({
                      ...f,
                      feedbackEntry: pageFeedback,
                    }))
                  )
                  .sort((a: any, b: any) => {
                    const dateA = new Date(a.feedbackEntry.last_feedback_time)
                    const dateB = new Date(b.feedbackEntry.last_feedback_time)
                    return dateB.getTime() - dateA.getTime()
                  })
                  .map((f: any, index: number) => {
                    const feedbackRating = parseInt(f.feedbackEntry.feedback, 10)
                    if (feedbackRating >= minRating && feedbackRating <= maxRating) {
                      return (
                        <tr key={index} className="hello p-1 rounded-md hover:bg-gray-200 duration-500">
                          <td className="pl-4 py-2">
                            <div className="flex items-center">
                              <span>{f["name"]}</span>
                            </div>
                          </td>
                          <td className="relative group py-2">
                            <button onClick={() => copyToClipboard(f.email)} className="relative z-10">
                              <CopyIcon />
                            </button>
                          </td>
                          <td className="px-4 py-2 relative group">
                            <span
                              className={`font-bold ${
                                feedbackRating === 5
                                  ? "text-green-500"
                                  : feedbackRating === 4
                                    ? "text-yellow-500"
                                    : "text-red-500"
                              }`}
                            >
                              {`${f.feedbackEntry.feedback}/5`}
                            </span>
                          </td>
                          <td className="px-4 py-2">{f["batch"]}</td>
                          <td className="px-4 py-2">
                            {new Date(f.feedbackEntry.last_feedback_time)
                              .toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              })
                              .replace(",", "")}
                          </td>
                          <td className="px-1 py-2 relative group items-center pl-10">
                            <button
                              className="bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 hover:text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:border-gray-600"
                              type="button"
                              onClick={() => handleViewFeedback(f.email, f)}
                            >
                              View Feedback
                            </button>
                          </td>
                        </tr>
                      )
                    } else {
                      return null
                    }
                  })
              ) : (
                <tr>
                  <td colSpan={6} className="px-4 py-2 text-center">
                    {noResultsMessage ? <>{noResultsMessage}</> : <></>}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="w-1/5 p-4 border border-gray-300 m-2.5 rounded-md h-[100vh] flex flex-col justify-between">
          <div className="flex justify-center mb-4">
            <img src="https://source.unsplash.com/random/200x200" alt="Profile Picture" className="rounded-full" />
          </div>
          <div className="text-center flex-grow">
            <h4 className="text-l font-bold">
              {selectedFeedback && (
                <div>
                  <div className="flex-wrap font-montserrat text-[18px] font-semibold">
                    {selectedFeedback.studentName}
                  </div>
                  <div className="border-[1px] w-1/2 rounded-lg m-auto p-2 mt-3 text-gray-600">
                    Batch: {selectedFeedback.Batch}
                  </div>
                </div>
              )}
            </h4>
          </div>
          <div className="mt-auto flex justify-center">
            <SmartPeople />
          </div>
        </div>
      </div>
      {isPopupOpen && selectedFeedback && (
        <div
          className="fixed inset-0 bg-[rgba(12,12,12,0.85)] flex items-center justify-center"
          style={{ zIndex: 1000 }}
        >
          <div className="rounded-lg shadow-lg relative">
            <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={closePopup}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="popup-content flex flex-col space-y-6">
              <FeatureFeedbackCard
                studentName={selectedFeedback.studentName}
                pageRating={selectedFeedback.pageRating}
                feedbackText={selectedFeedback.feedbackText}
                pageName={selectedFeedback.pageName}
              />
            </div>
          </div>
        </div>
      )}
      {curIdx < first100.length && <div ref={loaderRef}></div>}
    </DashboardLayout>
  )
}
