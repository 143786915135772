import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import CopyIcon from "assets/svgs/CopyIcon"
import { STR_SUCCESS } from "core/constants/strings"

export default function ViewDataTable(props: any) {
  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    props.changeToastVisibility(true)
    props.changeToastDetails(STR_SUCCESS, "Copied to clipboard")
  }
  console.log(props.data, "yahan")

  return (
    <div
      className={cn(
        "relative overflow-x-auto", // Removed max height for full-screen view
        props.outlined ? "border border-new-neutral-light" : "shadow-[0px_4px_16px_rgba(134,134,134,0.2)]"
      )}
      style={{ height: "100vh" }} // Ensures the table occupies the full screen
    >
      <table className="w-full text-left text-sm text-new-neutral-dark">
        {/* Sticky Header */}
        <thead className="bg-new-editor-dark-500 text-new-solid-white">
          <tr>
            {props.data?.header?.map((title: any, i: any) => (
              <th
                key={i}
                className={cn(
                  "sticky top-0 z-10 px-6 py-2 text-sm font-semibold leading-[17px] bg-new-editor-dark-500",
                  i > 0 && i === props.data.header.length - 1 && "text-right"
                )}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {/* Table Body */}
        <tbody>
          {!isEmpty(props.data?.rows) ? (
            props.data?.rows?.map((row: any, ind: any) => (
              <tr className="group relative bg-new-solid-white" key={ind}>
                {Object.keys(row).map((key: any, i: any) => {
                  const cellValue = row[key]
                  const isEmail = typeof cellValue === "string" && cellValue.includes("@")

                  return (
                    <td
                      key={key}
                      className={cn(
                        "relative whitespace-nowrap px-6 py-4 font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark",
                        i > 0 && i === Object.keys(row).length - 1 && "text-right"
                      )}
                    >
                      <div className="flex items-center">
                        {isEmail && !props.isInstructorTable ? (
                          <button
                            className="flex items-center justify-center cursor-pointer"
                            title={cellValue} // cursor displays email address
                            onClick={() => handleCopyToClipboard(cellValue)}
                          >
                            <CopyIcon />
                          </button>
                        ) : (
                          <p className="px-1">{cellValue}</p>
                        )}
                      </div>
                    </td>
                  )
                })}
              </tr>
            ))
          ) : (
            <tr className="h-[50px] w-full bg-new-misc-gray">
              <td
                colSpan={props.data?.header?.length}
                className="py-2 text-center font-medium leading-6 text-new-neutral-light"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
