import { initializeApp } from "firebase/app"
import { getAuth, GoogleAuthProvider } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAf-BvdyLfMNpms_0ahJAdQQrWsrdCMt9A",
  authDomain: "bosscoderplatformindia.firebaseapp.com",
  projectId: "bosscoderplatformindia",
  storageBucket: "bosscoderplatformindia.appspot.com",
  messagingSenderId: "620375467743",
  appId: "1:620375467743:web:9db91274abcc2d68e3ab6a",
  measurementId: "G-J7W7ML6HLX",
}

const app = initializeApp(firebaseConfig)
export const provider = new GoogleAuthProvider()
export const auth = getAuth(app)
