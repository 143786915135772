import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { TestSeriesDashboardAPIDataSourceImpl } from "data/API/Admin/TestSeriesDashboardAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { TestSeriesDataSourceRepositoryImpl } from "data/repository/Admin/TestSeriesDataSourceRepositoryImpl"
import { format } from "date-fns"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { createWeeklyTest } from "domain/useCase/Admin/TestSeriesDashboard/CreateWeeklyTest"
import { deleteScheduleWeeklyTest } from "domain/useCase/Admin/TestSeriesDashboard/DeleteScheduleTest"
import { deleteWeeklyTest } from "domain/useCase/Admin/TestSeriesDashboard/DeleteWeeklyTest"
import { getScheduleWeeklyTest } from "domain/useCase/Admin/TestSeriesDashboard/GetWeeklyScheduleTest"
import { getAllWeeklyTest } from "domain/useCase/Admin/TestSeriesDashboard/GetWeeklyTest"
import { scheduleWeeklyTest } from "domain/useCase/Admin/TestSeriesDashboard/SchedultWeeklyTest"
import { updateScheduleTests } from "domain/useCase/Admin/TestSeriesDashboard/UpdateScheduleWeeklyTest"
// import { updateWeeklyTest } from 'domain/useCase/TestSeriesDashboard/UpdateWeeklyTest';
import React from "react"

export default function TestDashboardViewModel() {
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [weeklyTest, setWeeklyTest] = React.useState([])
  const [scheduleWeeklyTests, setScheduleWeeklyTests] = React.useState([])
  const [createTest, setCreateTest] = React.useState({
    testName: "",
    testUrl: "",
  })
  const [scheduleTest, setScheduleTest] = React.useState({
    timestamp: "",
  })
  const [selectTestId, setSelectTestId] = React.useState("")
  const [scheduleTestId, setScheduleTestId] = React.useState("")
  const [selectBatch, setSelectBatch] = React.useState<any>([])
  const [batch, setBatch] = React.useState<any>("")
  const [batchList, setBatchList] = React.useState([])
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(true)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getAllWeeklyTestUseCase = new getAllWeeklyTest(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  const getAllScheduleTestUseCase = new getScheduleWeeklyTest(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  const createWeeklyTestUseCase = new createWeeklyTest(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  // const updateWeeklyTestUseCase = new updateWeeklyTest(
  //   new TestSeriesDataSourceRepositoryImpl(
  //     new TestSeriesDashboardAPIDataSourceImpl()
  //   )
  // );

  const deleteWeeklyTestUseCase = new deleteWeeklyTest(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  const scheduleTestUseCase = new scheduleWeeklyTest(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  const updateScheduleTestUseCase = new updateScheduleTests(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  const deleteScheduleTestUseCase = new deleteScheduleWeeklyTest(
    new TestSeriesDataSourceRepositoryImpl(new TestSeriesDashboardAPIDataSourceImpl())
  )

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  async function getAllTest() {
    setLoading(true)
    const response = await getAllWeeklyTestUseCase.invoke(auth)
    setLoading(false)
    try {
      if (response.success) {
        setWeeklyTest(response.data)
      }
    } catch (err) {}
    // return response.data;
  }

  async function getAllTestUtils() {
    const response = await getAllWeeklyTestUseCase.invoke(auth)
    try {
      if (response.success) {
        setWeeklyTest(response.data)
      }
    } catch (err) {}
    // return response.data;
  }

  async function getScheduleWeeklyTests() {
    const response = await getAllScheduleTestUseCase.invoke(auth)
    try {
      if (response.success) {
        setScheduleWeeklyTests(response.data)
      }
    } catch (err) {}
  }

  async function createWeeklyTests(e: any) {
    e.preventDefault()
    const response = await createWeeklyTestUseCase.invoke({
      id_token: auth.id_token,
      name: createTest.testName?.trim(),
      url: createTest.testUrl?.trim(),
    })

    try {
      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, response.data)
        await getAllTestUtils()
      }
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  async function scheduleTests(e: any) {
    e.preventDefault()
    const response = await scheduleTestUseCase.invoke({
      id_token: auth.id_token,
      test_id: selectTestId?.trim(),
      batch: selectBatch,
      timestamp: Date.parse(scheduleTest.timestamp) / 1000,
    })

    try {
      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, response.data)
        await getScheduleWeeklyTests()
      } else {
      }
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }
  async function updateScheduleTest(e: any) {
    e.preventDefault()
    e.stopPropagation()
    const response = await updateScheduleTestUseCase.invoke({
      id_token: auth.id_token,
      test_id: selectTestId?.trim(),
      batch: selectBatch,
      schedule_test_id: scheduleTestId,
      timestamp: Date.parse(scheduleTest.timestamp) / 1000,
    })
    try {
      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, response.data)
        await getScheduleWeeklyTests()
      }
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  async function deleteScheduleTest(test_id: string) {
    const response = await deleteScheduleTestUseCase.invoke({
      id_token: auth.id_token,
      schedule_test_id: test_id,
    })
    try {
      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, response.data)
        await getScheduleWeeklyTests()
      }
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  async function deleteWeeklyTests(test_id: string) {
    const response = await deleteWeeklyTestUseCase.invoke({
      id_token: auth.id_token,
      test_id: test_id,
    })
    try {
      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, response.data)
        await getAllTest()
      }
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatchList(response.data)
    }
  }

  function changeActiveButtonType(index: number) {
    setActiveButtonType(index)
  }

  function onChangeTestId(e: any) {
    setSelectTestId(e.target.value)
  }
  function handleBatchChange(e: any) {
    setBatch(e.target.value)
  }

  const handleCreateTest = (event: any) => {
    const { name, value } = event.target
    setCreateTest((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const handleScheduleTest = (event: any) => {
    const { name, value } = event.target
    setScheduleTest((prevValues) => ({ ...prevValues, [name]: value }))
  }

  function handleAddBatch(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (batch.length > 0) {
      setSelectBatch([...selectBatch, batch])
    }
    setBatch("")
  }
  function removeItem(array: any[], index: number) {
    return array.filter((item, i) => i !== index)
  }

  function handleOpenModel(test_id: string, batch: any, id: any, timestamp: any) {
    setSelectTestId(test_id)
    setSelectBatch(batch)
    setScheduleTest((prevState) => ({
      ...prevState,
      timestamp: format(timestamp * 1000, "yyyy-MM-dd'T'HH:mm"),
    }))
    setScheduleTestId(id)
    setIsOpen(true)
  }

  const handleCloseModel = () => {
    setIsOpen(false)
    setSelectTestId("")
    setSelectBatch([])
    setScheduleTest((prevState) => ({ ...prevState, timestamp: "" }))
  }

  const openDeleteModel = () => {
    setOpen(true)
  }
  const closeDeleteModel = () => {
    setOpen(false)
  }

  return {
    toast,
    weeklyTest,
    activeButtonType,
    createTest,
    batch,
    scheduleTest,
    selectBatch,
    scheduleWeeklyTests,
    batchList,
    isOpen,

    changeToastVisibility,
    changeActiveButtonType,
    handleCreateTest,
    handleScheduleTest,
    setSelectBatch,
    handleAddBatch,
    handleBatchChange,
    removeItem,
    getScheduleWeeklyTests,
    getAllTest,
    createWeeklyTests,
    getBatches,
    scheduleTests,
    onChangeTestId,
    handleOpenModel,
    handleCloseModel,
    updateScheduleTest,
    deleteScheduleTest,
    deleteWeeklyTests,
    openDeleteModel,
    closeDeleteModel,
    open,
    loading,
  }
}
