import { Auth } from "domain/model/Auth"
import AIFeedbackRepository from "domain/repository/Admin/AIFeedbackRepository"

export interface getTop100FeedbacksUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}
export class GetTop100Feedbacks implements getTop100FeedbacksUseCase {
  private repository: AIFeedbackRepository

  constructor(repository: AIFeedbackRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.getTop100Feedbacks(auth, data)
  }
}
