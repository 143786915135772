import React, { useEffect, useState } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import InvoiceTable from "./components/InvoicingTable"
import CompensationDetailTable from "./components/CompensationDetailTable"
import { useAuth } from "core/context/auth"
import { CompensationDetailsImpl } from "data/API/Mentor/CompensationDetailsImpl"
import { GetInvoiceDetailImpl } from "data/API/Mentor/GetMentorInvoiceDetailsImpl"
import GetMentorCompensationDetails from "domain/useCase/Mentor/compensation/GetMentorCompensationDetails"
import GetInstructorInvoiceDetails from "domain/useCase/Mentor/InvoiceDetail/getInstructorInvoiceDetails"
import axios from "axios"
import InvoiceSessionDataView from "./InvoicingSession/InvoiceSessionDataView"
import { useApp } from "core/context/app"
import { useParams } from "react-router-dom"
import DownArrow from "assets/svgs/Arrow"
import { ArrowUpNarrowWide } from "lucide-react"
import Arrow from "assets/svgs/Arrow"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_MENTOR, API_MENTORS, MODULE_COMPENSATION } from "core/constants/strings"

const server = new Server()

const InvoicingView = () => {
  const { auth } = useAuth()
  const { mentor } = useApp()
  let { page } = useParams()
  const [mentorCompensationDetails, setMentorCompensationDetails] = useState<any>(null)
  const [instructorCompensationDetails, setInstructorCompensationDetails] = useState<any>(null)
  const [mentorCompensationModuleWiseDetails, setMentorCompensationModuleWiseDetails] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)
  const [isModuleWise, setIsModuleWise] = useState<boolean>(false)
  const [showCompensation, setShowCompensation] = useState(false)
  const compensationRepository = new CompensationDetailsImpl()
  const getMentorCompensationDetailsUseCase = new GetMentorCompensationDetails(compensationRepository)
  const invoiceRepository = new GetInvoiceDetailImpl()

  const fetchMentorCompensationDetails = async () => {
    if (!auth || !auth.id_token) {
      setError("Authentication token is missing")
      return
    }

    try {
      const res = await getMentorCompensationDetailsUseCase.invoke(auth)
      const details = await res.data
      const compensationDetails = [details.compensation, details.mentor_session_compensation]
      setMentorCompensationDetails(compensationDetails)
    } catch (error) {
      setError("Error fetching mentor details")
      console.error("Error fetching mentor details:", error)
    }
  }

  // TODO: create IMPL for it
  const fetchMentorCompensationModuleWise = async () => {
    try {
      const response = await server.get(pn(API_MENTOR, MODULE_COMPENSATION), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      const responseData = await response.data
      const moduleWise = responseData.data !== "No module wise sessions found for the given instructor"
      setIsModuleWise(moduleWise)
      setMentorCompensationModuleWiseDetails(response.data.data)
      return responseData
    } catch (error) {
      console.error("Error fetching mentor details:", error)
      throw error
    }
  }

  useEffect(() => {
    console.log("mentor inside hook", mentor)
    const compensationDetails = [mentor.mentor.compensation]
    setMentorCompensationDetails(mentor.mentor.mentor_session_compensation)
    setInstructorCompensationDetails(mentor.mentor.compensation)
    fetchMentorCompensationModuleWise()
  }, [])

  const headers = ["Serial no.", "Compensation", "Applicable Date"]
  const headers1 = ["Serial no.", "Compensation", "Applicable Date", "module"]
  const rows = [
    { Name: "John Doe", "Total Sessions": 5, Status: "Active" },
    { Name: "Jane Smith", "Total Sessions": 3, Status: "Inactive" },
    { Name: "Jane Smith", "Total Sessions": 3, Status: "Inactive", hello: "hello" },
  ]
  const [month, setMonth] = useState<string>("")
  const handleClickForMonthlySessions = () => {
    setMonth("202405")
  }

  const toggleCompensationTable = () => {
    setShowCompensation(!showCompensation)
  }

  return (
    <DashboardLayout>
      {month ? (
        <InvoiceSessionDataView isInstructor={true} />
      ) : (
        <div className="flex flex-col m-6 gap-6 font-montserrat ">
          <div className="flex-row items-end">
            <h1 className=" text-[24px] font-semibold text-[#312E81]">All Sessions Invoicing </h1>
            <p className="text-[#525252] font-montserrat text-base font-normal leading-160 text-[14px]">
              You have access to all your sessions invoicing data.
            </p>
          </div>
          <div className="">
            <InvoiceTable role={page === "instructor-invoice" ? "instructor" : "mentor"} />
            <div className="mt-5 border-[0.4px] border-[#ADADAD] rounded-sm p-6">
              <div className="flex items-center cursor-pointer justify-between mb-5" onClick={toggleCompensationTable}>
                <h1
                  className={`text-[#312E81] font-montserrat text-[18px] font-medium leading-normal ${showCompensation ? "flex items-center" : ""}`}
                >
                  Compensation Details
                </h1>
                <div className={`${!showCompensation ? "rotate-180 transition-transform" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8.00001 7.21883L4.70001 10.5188L3.75734 9.57616L8.00001 5.3335L12.2427 9.57616L11.3 10.5188L8.00001 7.21883Z"
                      fill="#414141"
                    />
                  </svg>
                </div>
              </div>

              {showCompensation && (
                <>
                  <div className=" w-full mb-5 p-0">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="1200" height="2" viewBox="0 0 1000 2" fill="none">
                      <path d="M0 1L1000 1.00009" stroke="#ADADAD" stroke-dasharray="4 4"/>
                    </svg> */}
                  </div>
                  <div className="w-full mb-5 p-0 border-t border-dashed border-gray-400"></div>
                  {page === "mentor-invoice" ? (
                    <>
                      <CompensationDetailTable
                        tableHeader={headers}
                        tableRow={rows}
                        title={"Compensation Amount per mentor session"}
                        isModuleWise={false}
                        compensationDetails={mentorCompensationDetails}
                      />
                      <div className="text-[#414141] text-[22px] mt-6">
                        Goal Setting Session Compensation Amount:{" "}
                        {<span className="text-[#00C22B]">Rs. {mentor.mentor.goal_setting_session_compensation} </span>}
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col gap-4">
                      <CompensationDetailTable
                        tableHeader={headers}
                        tableRow={rows}
                        title={"Hourly compensation amount"}
                        isModuleWise={false}
                        compensationDetails={instructorCompensationDetails}
                      />
                      {isModuleWise && (
                        <CompensationDetailTable
                          tableHeader={headers1}
                          tableRow={rows}
                          title={"Module based compensation amount: "}
                          isModuleWise={isModuleWise}
                          compensationDetails={mentorCompensationModuleWiseDetails}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  )
}

export default InvoicingView
