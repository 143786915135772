import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { OnboardingManagerName } from "domain/model/OnboardingManagerName"
import OnboardingManagerNameDataSource from "data/dataSource/Admin/OnboardingManagerNameDataSource"
import { Auth } from "domain/model/Auth"
const server = new Server()

export class OnboardingManagerNameAPIDataSourceImpl implements OnboardingManagerNameDataSource {
  async onboardingManagerName(auth: Auth, student_email: OnboardingManagerName): Promise<any> {
    try {
      const response = await server.get(pn(`admin/onboarding/manager?email=${student_email}`), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response.data?.name
    } catch (e) {
      console.error(e)
    }
  }
}
