import Button from "core/components/new/Button"

export default function McqNavigation({ isMcqSubmitted, handleModalAction }: any) {
  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
        <form
          className="z-50 flex w-[450px] transform flex-col gap-4 rounded-lg bg-new-solid-white p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {isMcqSubmitted ? (
            <div>
              <h3 className="text-lg font-bold text-new-neutral-dark z-auto">Question Not Attempted</h3>
              <p className="text-sm">
                You have not submitted this question. Would you like to proceed to the next question without submitting
                this one?{" "}
              </p>
              <div className="flex gap-4 mt-4 ml-auto">
                <Button small className="w-fit" onClick={() => handleModalAction("yes")}>
                  Yes
                </Button>
                <Button small className="w-fit" onClick={() => handleModalAction("save")}>
                  No
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  )
}
