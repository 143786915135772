import { Auth } from "domain/model/Auth"
import { FilterData } from "domain/model/FilterData"
import pn, { query } from "core/utils/pn"
import { getAPI, postAPI, Server } from "core/utils/axios"
import {
  API_V4_GET_ALL_BATCHES,
  API_GET_ALL_MENTORS,
  API_GET_FILTERED_DATA,
  API_V4_ADMIN,
  API_GET_PRODUCT_ANALYTICS,
  API_V4_GET_ALL_ACTIVE_BATCHES,
  API_UPDATE_BATCH_DATA,
  API_BATCH_TIMESTAMP_DATA,
  API_VIEW_BATCH_TIMESTAMP_DATA,
} from "core/constants/strings"
import HomeDataSource from "data/dataSource/Admin/HomeDataSource"
import { BatchTimestampData } from "domain/model/BatchTimestampData"
import { ViewBatchData } from "domain/model/ViewBatchTimestampData"

const server = new Server()

export default class HomeAPIDataSourceImpl implements HomeDataSource {
  async getFilteredData(auth: Auth, data: FilterData): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_FILTERED_DATA), {
        id_token: auth?.id_token,
        task: data?.task,
        prevBatch: data?.prevBatch,
        email: data?.email,
        pageNumber: data?.pageNumber,
        itemPerPage: data?.itemPerPage,
        prevMentor: data?.prevMentor,
        mentor: data?.mentor,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
        batch: data?.batch,
        pausedBatch: data?.pausedBatch,
        status: data?.status,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllBatches(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_ALL_BATCHES), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllMentors(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_MENTORS), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getProductAnalytics(data: any): Promise<any> {
    try {
      const response = await getAPI(
        query(pn(API_V4_ADMIN, API_GET_PRODUCT_ANALYTICS), {
          id_token: data.auth?.id_token,
          start_date: data.startDate,
          end_date: data.endDate,
        })
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getBatchTimestampData(auth: Auth, data: BatchTimestampData): Promise<any> {
    try {
      const response = await server.get(
        query(pn(API_BATCH_TIMESTAMP_DATA), {
          batch: data?.batch,
          fromDate: data?.fromDate,
          toDate: data?.toDate,
        }),
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      // console.log("download_data",response.data)
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }

  async viewBatchTimestampData(auth: Auth, data: ViewBatchData): Promise<any> {
    try {
      const response = await server.get(
        query(pn(API_VIEW_BATCH_TIMESTAMP_DATA), {
          batch: data?.batch,
          fromDate: data?.fromDate,
          toDate: data?.toDate,
        }),
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )

      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
