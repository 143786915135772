import React from "react"

const Invoicing = ({ props }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.9987 2H13.9987C14.3669 2 14.6654 2.29848 14.6654 2.66667V13.3333C14.6654 13.7015 14.3669 14 13.9987 14H1.9987C1.63051 14 1.33203 13.7015 1.33203 13.3333V2.66667C1.33203 2.29848 1.63051 2 1.9987 2ZM2.66536 3.33333V12.6667H13.332V3.33333H2.66536ZM9.33203 9.33333H5.66536V10.6667H7.33203V12H8.66536V10.6667H9.33203C10.2525 10.6667 10.9987 9.92048 10.9987 9C10.9987 8.07953 10.2525 7.33333 9.33203 7.33333H6.66536C6.48127 7.33333 6.33203 7.18409 6.33203 7C6.33203 6.81591 6.48127 6.66667 6.66536 6.66667H10.332V5.33333H8.66536V4H7.33203V5.33333H6.66536C5.74489 5.33333 4.9987 6.07953 4.9987 7C4.9987 7.92047 5.74489 8.66667 6.66536 8.66667H9.33203C9.51613 8.66667 9.66536 8.81591 9.66536 9C9.66536 9.18409 9.51613 9.33333 9.33203 9.33333Z"
        fill="white"
      />
    </svg>
  )
}

export default Invoicing
