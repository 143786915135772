import React from "react"

export default function RemoveFilePopup2({ onCancel, onConfirm, paymentDocuments, index }: any) {
  return (
    <React.Fragment>
      <div className="fixed inset-0 bg-black opacity-80 z-10"></div>
      <div className="flex flex-col rounded items-center fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 h-[258px] w-[346px] bg-white p-4 text-black flex-1">
        <div className="flex justify-between w-full items-center mb-4">
          <div className="text-center text-[#DC2626] font-[500] text-lg">Delete Document</div>
          <button onClick={onCancel} className="">
            <svg className="w-6 h-6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z"
                fill="#414141"
              />
            </svg>
          </button>
        </div>
        <div className="w-3/4 mb-1 self-start text-sm text-[#414141] leading-[22.4px]">
          You are about to delete uploaded document!!
        </div>
        <div className="w-3/4 self-start text-sm text-[#414141] leading-[22.4px] font-semibold text-[14px]">
          {paymentDocuments[index].filename}
        </div>
        <div className="w-full flex justify-end items-center mt-auto gap-[11px]">
          <button
            className="font-[500] leading-normal cursor-pointer text-[14px] gap-1 text-[#646464] border px-[16px] py-[9px] rounded bg-white hover:bg-gray-100"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="font-[500] leading-normal cursor-pointer text-[14px] gap-1 text-[#D41B1B] border px-[16px] py-[9px] rounded bg-white hover:bg-gray-100"
            onClick={onConfirm}
          >
            Delete Document
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}
