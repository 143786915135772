import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { EditStudentDetails } from "domain/useCase/Admin/Placement/EditStudentsDetails"
import { GetStudentDetails } from "domain/useCase/Admin/Placement/GetStudentDetails"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export default function AddPlacementFormViewModel() {
  const [name_, setName_] = useState("")
  const [phone_, setPhone_] = useState("")

  const { auth } = useAuth()
  const { id } = useParams()
  const docId = id

  const [studentDetails, setStudentDetails] = useState({
    profilePicture: "",
    name: "",
    email: "",
    current_job_profile: "",
    current_ctc: "",
    yoe: "",
    bca_rating: "",
    skills: "",

    // working_or_fresher: "",  -> conditional field
    dream_company: "",
    current_company: "",
    gap_years: "",
    desired_location: "",
    placement_batch: "",
    placement_preferred_rating: "",

    phone: "",
    college_passing_year: "",
    dream_profile: "",
    notice_period: "",
    batch: "",
    program: "",

    mock_status: "",
    linkedin_profile: "",
    linkedin_status: "",
    resume_status: "",
    resume_link: "",
    // review: "",
    // placed: "",
  })

  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<any>(false)

  const GetStudentDetailsUsecase = new GetStudentDetails(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  const EditStudentDetailsUsecase = new EditStudentDetails(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  async function fetchStudentDetails() {
    if (!docId) {
      console.error("docId is undefined")
      return
    }
    const response = await GetStudentDetailsUsecase.invoke(auth, docId)
    if (response?.success) {
      const data = response?.data
      setName_(data.name || "")
      setPhone_(data.phone || "")
      setStudentDetails((prevDetails) => ({
        ...prevDetails,
        profilePicture: data?.imageURL || "",
        name: data?.name || "",
        email: data?.email || "",
        current_job_profile: data?.current_job_profile || "",
        current_ctc: data?.current_ctc || "",
        yoe: data?.yoe || "",
        bca_rating: data?.bca_rating || 0,
        skills: data?.skills || "",
        dream_company: data?.dream_company || "",
        current_company: data?.current_company || "",
        gap_years: data?.gap_years || "",
        desired_location: data?.desired_location || "",
        placement_batch: data?.placement_batch || "",
        placement_preferred_rating: data?.placement_preferred_rating || 0,
        phone: data?.phone || "",
        college_passing_year: data?.college_passing_year || "",
        dream_profile: data?.dream_profile || "",
        notice_period: data?.notice_period || "",
        batch: data?.batch || "",
        program: data?.program || "",
        mock_status: data?.mock_status || "",
        linkedin_profile: data?.linkedin_profile || "",
        linkedin_status: data?.linkedin_status || "",
        resume_status: data?.resume_status || "",
        resume_link: data?.resume_link || "",
      }))
    }
  }

  const skillsArray = studentDetails.skills
    ? studentDetails.skills
        .split(",")
        .map((skill) => skill.trim())
        .filter((skill) => skill.length > 0)
    : []

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    const updatedDetails = {
      name: studentDetails.name,
      current_job_profile: studentDetails.current_job_profile,
      current_ctc: studentDetails.current_ctc,
      experience: studentDetails.yoe,
      rating: studentDetails.bca_rating,
      skills: skillsArray,
      dream_company: studentDetails.dream_company,
      current_company: studentDetails.current_company,
      gap_years: studentDetails.gap_years,
      desired_location: studentDetails.desired_location,
      // placement_batch: studentDetails.placement_batch,
      placement_preferred_rating: studentDetails.placement_preferred_rating,
      phone: studentDetails.phone,
      college_passing_year: studentDetails.college_passing_year,
      dream_profile: studentDetails.dream_profile,
      notice_period: studentDetails.notice_period,
      batch: studentDetails.batch,
      program_type: studentDetails.program,
    }

    if (!docId) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Document ID is missing. Please try again.")
      return
    }

    try {
      setLoading(true)
      const response = await EditStudentDetailsUsecase.invoke(auth, docId, updatedDetails)
      console.log("EditStudentDetails Response:", response)

      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Student's Details Edited Successfully")
      }
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown Error Occured")
      console.error("Error Adding Placement:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleLog = () => {
    console.log("LOG button is Clicked")
  }

  const handleBack = () => {
    try {
      navigate(-1)
    } catch (error) {
      console.error("Navigation error:", error)
    }
  }

  return {
    toast,
    changeToastVisibility,
    handleSubmit,
    handleLog,
    fetchStudentDetails,
    name_,
    phone_,
    studentDetails,
    setStudentDetails,
    docId,
    handleBack,
  }
}
