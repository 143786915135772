import DashboardLayout from "core/layouts/DashboardLayout"
import { useEffect } from "react"
import useGetAllClassesViewModel from "./AllClassesViewModel"
import NewClassCard from "./components/NewClassCard"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import { STR_RECORDED_CLASSES, STR_UPCOMING_CLASSES } from "core/constants/strings"
import ButtonGroup from "core/components/ButtonGroup"
import NewScheduledClassCard from "./components/NewScheduledClassCard"
import useScheduledClassesViewModel from "./ScheduledClassesViewModel"
import useUpdateClassViewModel from "./components/UpdateClassViewModel"

export default function AllClassesView() {
  const {
    batch,
    month,
    instructor,
    toast,
    loading,
    AllClasses,
    activeButtonType,
    upcomingClasses,
    updateClassBool,
    upcomingClassBool,
    duplicateClassBool,
    moduleSearch,
    MONTHARRAY,
    MODULEARRAY,
    handleSearchByModule,
    setUpcomingClassBool,
    setUpdateClassBool,
    setDulplicateClassBool,
    handleSearch,
    changeToastVisibility,
    changeToastDetails,
    fetchAllClassesDetails,
    fetchUpcomingCLasses,
    handleSearchByBatch,
    handleSearchByInstructor,
    changeActiveButtonType,
    handleSearchByMonth,
  } = useGetAllClassesViewModel()

  const {
    cancelLoading,
    getBatchList,
    instructorList,
    getModulesList,
    getTracksList,
    selectAssigmentsQuestions,
    selectHomeworkQuestions,
    selectPracticalQuestions,
    selectWarmupQuestions,
    currentSelectAssignmentsQuestions,
    setCancelLoading,
    deleteScheduledClass,
    getBatches,
    getInstructorsList,
  } = useScheduledClassesViewModel()

  const { reloadClass } = useUpdateClassViewModel()

  useEffect(() => {
    fetchAllClassesDetails(batch, month, instructor)
    fetchUpcomingCLasses(batch, month, instructor)
  }, [duplicateClassBool, upcomingClassBool, updateClassBool, reloadClass])

  useEffect(() => {
    getBatches()
    getInstructorsList()
  }, [])

  if (isEmpty(AllClasses)) {
    return (
      <DashboardLayout>
        <Loader />
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="flex items-end gap-4">
          <select className="h-9 w-[209px] py-1 rounded" onChange={handleSearchByInstructor} value={instructor}>
            <option value="">Instructor</option>
            {instructorList?.map((instructor: any, i: number) => (
              <option value={instructor?.email} key={i}>
                {instructor?.name}
              </option>
            ))}
          </select>
          <select className="h-9 w-[209px] py-1 rounded" onChange={handleSearchByBatch} value={batch}>
            <option value="">Batch</option>
            {getBatchList?.map((bname: any, i: number) => (
              <option value={bname} key={i}>
                {bname}
              </option>
            ))}
          </select>
          <select className="h-9 w-[209px] py-1 rounded" onChange={handleSearchByMonth} value={month}>
            <option value="">Month</option>
            {MONTHARRAY?.map((mname: any, i: number) => (
              <option value={mname} key={i}>
                {mname}
              </option>
            ))}
          </select>
          <select className="h-9 w-[209px] py-1 rounded" onChange={handleSearchByModule} value={moduleSearch}>
            <option value="">Module</option>
            {MODULEARRAY?.map((modName: any, i: number) => (
              <option value={modName} key={i}>
                {modName}
              </option>
            ))}
          </select>
          <Button loading={loading} className="h-[37px] w-fit" onClick={() => handleSearch(batch, month, instructor)}>
            Search
          </Button>
        </div>
        <ButtonGroup
          buttons={[STR_UPCOMING_CLASSES, STR_RECORDED_CLASSES]}
          active={activeButtonType}
          onChange={changeActiveButtonType}
        />
        {activeButtonType === 1 && !isEmpty(AllClasses?.v4_session_classes) ? (
          AllClasses?.v4_session_classes?.map((data: any, i: number) => (
            <NewClassCard
              key={i}
              data={data}
              loading={loading}
              updateClassBool={updateClassBool}
              setUpdateClassBool={setUpdateClassBool}
              changeToastVisibility={changeToastVisibility}
              changeToastDetails={changeToastDetails}
              fetchAllClassesDetails={fetchAllClassesDetails}
              getBatchList={getBatchList}
              instructorList={instructorList}
            />
          ))
        ) : activeButtonType === 0 && !isEmpty(upcomingClasses?.v4_session_classes) ? (
          upcomingClasses?.v4_session_classes?.map((classSchedule: any, i: number) => {
            const sessionDate = new Date(classSchedule.session_timestamp * 1000)
            const formattedDate = sessionDate.toLocaleDateString()
            const formattedTime = sessionDate.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            return (
              <NewScheduledClassCard
                key={i}
                duplicateClassBool={duplicateClassBool}
                setDulplicateClassBool={setDulplicateClassBool}
                upcomingClassBool={upcomingClassBool}
                setUpcomingClassBool={setUpcomingClassBool}
                chapter_name={classSchedule.chapter_name}
                session_id={classSchedule.session_id}
                getBatchList={getBatchList}
                instructorList={instructorList}
                getModulesList={getModulesList}
                getTracksList={getTracksList}
                oldClassData={classSchedule}
                cancelLoading={cancelLoading}
                formattedDate={formattedDate}
                formattedTime={formattedTime}
                setCancelLoading={setCancelLoading}
                deleteScheduleClass={deleteScheduledClass}
                selectAssigmentsQuestions={selectAssigmentsQuestions}
                selectHomeworkQuestions={selectHomeworkQuestions}
                selectPracticalQuestions={selectPracticalQuestions}
                selectWarmupQuestions={selectWarmupQuestions}
                changeToastVisibility={changeToastVisibility}
                changeToastDetails={changeToastDetails}
                fetchAllClassesDetails={fetchAllClassesDetails}
                currentSelectAssignmentsQuestions={currentSelectAssignmentsQuestions}
              />
            )
          })
        ) : (
          <h4>No data found</h4>
        )}
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
