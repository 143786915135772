import AdminLoginDataSource from "data/dataSource/Admin/AdminLoginDataSource"

import { AdminLoginRepository } from "domain/repository/Admin/AdminLoginRepository"

export class AdminLoginRepositoryImpl implements AdminLoginRepository {
  private dataSource: AdminLoginDataSource

  constructor(dataSource: AdminLoginDataSource) {
    this.dataSource = dataSource
  }

  getAdminLogin(email: string) {
    return this.dataSource.getAdminLogin(email)
  }
}
