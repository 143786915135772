import BosscoderLogoDoted from "assets/svgs/BosscoderLogoDoted"
import BosscoderReferLogo from "assets/svgs/BosscoderReferLogo"
import LineBottomRot from "assets/svgs/LineBottomRot"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import ClassFeedback from "./components/ClassFeedback"
import useClassFeedbackViewModel from "./ClassFeedbackViewModel"
import Toast from "core/components/Toast"
import { useEffect } from "react"

export default function ClassFeedbackView() {
  const { sessionId, classDetails, toast, submitClassFeedbackExternal, changeToastVisibility, getClassName } =
    useClassFeedbackViewModel()

  useEffect(() => {
    getClassName(sessionId)
  }, [])

  return (
    <>
      <div className="flex justify-center items-center min-h-screen ">
        <div className="flex w-full bg-white h-screen">
          <div className="relative w-1/3 h-full bg-[#333]">
            <div className="absolute top-[36px] left-[36px]">
              <BosscoderReferLogo />
            </div>
            <div className="absolute top-[20%] -right-[50%] min-[1920px]:-right-[40%]">
              <BosscoderLogoDoted />
            </div>
            <div className="absolute bottom-0">
              <LineBottomRot />
            </div>
            <div className="absolute bottom-0 -left-[80px]">
              <LineBottomRot />
            </div>
          </div>
          <div className="relative w-2/3 bg-white overflow-y-auto h-screen">
            <div className="relative flex justify-center overflow-y-auto min-h-screen items-center p-10">
              <div className="absolute top-0 left-0 overflow-hidden h-full w-full">
                <div className="absolute top-0 right-0 z-[-1] pointer-events-none">
                  <LoginPageGradient className={"#C9E5F5"} />
                </div>

                <div className="absolute top-0 -right-[60px] z-[-1] pointer-events-none">
                  <LoginPageGradient className={"#C9E5F5"} />
                </div>

                <div className="absolute bottom-0 left-0 scale-y-[-1] scale-x-[-1] w-[42%]">
                  <div className="absolute">
                    <LoginPageGradient className={"#C9E5F5"} />
                  </div>
                  <div className="absolute left-[60px]">
                    <LoginPageGradient className={"#C9E5F5"} />
                  </div>
                </div>
              </div>
              <ClassFeedback
                className="z-10 rounded-[8px] overflow-hidden border border-[#D3D3D3] shadow-sm"
                isExternal={true}
                classDetails={classDetails}
                onSubmit={submitClassFeedbackExternal}
                isRecordedFeedbackVisible={true}
                feedbackGiven={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </>
  )
}
