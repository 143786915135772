import { UpdateClass } from "domain/model/ScheduledClass"
import { ScheduledClassRepository } from "domain/repository/Admin/ScheduledClassRepository"

export interface UpdateScheduledClassWithoutIcsUseCase {
  invoke(updateClass: UpdateClass): Promise<any>
}

export class UpdateScheduledClassWithoutIcs implements UpdateScheduledClassWithoutIcsUseCase {
  private repository: ScheduledClassRepository

  constructor(repository: ScheduledClassRepository) {
    this.repository = repository
  }

  async invoke(updateClass: UpdateClass) {
    return await this.repository.updateScheduledClassWithoutIcs(updateClass)
  }
}
