import { Auth } from "domain/model/Auth"
import SubmissionRepository from "domain/repository/Student/SubmissionRepository"

export interface GetAIFeedbackInterface {
  invoke(auth: Auth, data: any): Promise<any>
}

export default class GetAIFeedback implements GetAIFeedbackInterface {
  private repository: SubmissionRepository

  constructor(repository: SubmissionRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return this.repository.getAIFeedback(auth, data)
  }
}
